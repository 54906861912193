import { AppCheckbox } from '../../../../../components/AppCheckox';
import { FormItem } from '../../../../../components/FormItem';
import { FormItemName } from '../../../../../lib/models/Form';
import { t } from 'i18next';

const NAME = FormItemName.ACTIVATED;

const deactivated = 'pages.products.demandArea.formItems.deactivated';

export const DeactivatedFormItem = () => {
  return (
    <FormItem
      valuePropName="checked"
      name={NAME}
      hasFeedback={true}
      style={{
        marginBottom: '-30px',
      }}
    >
      <AppCheckbox checked={false} label={t(deactivated)} />
    </FormItem>
  );
};
