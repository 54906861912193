import { FC, useEffect } from 'react';
import { Table } from '../../../../components/Table';
import { useUserNameColumn } from '../../hooks/table/columns/useUserNameColumn';
import { useUserPersonNameColumn } from '../../hooks/table/columns/useUserPersonNameColumn';
import { GetUsersPayload, useGetUsersQuery } from '../../../../lib/store/api/usersManagement';
import { useUserEmailColumn } from '../../hooks/table/columns/useUserEmailColumn';
import { assignedTenantsColumn } from '../../hooks/table/columns/assignedTenantsColumn';
import { normalizePageForRequest, normalizePageForTable } from '../../../../lib/utils/helpers';
import { TablePagination } from '../../../../lib/types/pagination';
import { rightsColumn } from '../../hooks/table/columns/rightsColumn';
import { useUserCreationDateColumn } from '../../hooks/table/columns/useUserCreationDateColumn';
import { useUserLastEditedDateColumn } from '../../hooks/table/columns/useUserLastEditedDateColumn';
import { useUserStatusColumn } from '../../hooks/table/columns/useUserStatusColumn';
import { useUserActionsColumn } from '../../hooks/table/columns/useUserActionsColumn';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { setPage } from '../../../../lib/store/slices/userManagementSlice';

export const UsersTable: FC = () => {
  const page = useAppSelector(({ userManagement }) => userManagement.page);

  const dispatch = useAppDispatch();

  const filters = useAppSelector(({ userManagement }) => {
    const arrangedId = userManagement.arrangeId;
    return { arrangedId, ...userManagement.filtering, ...userManagement.sorting };
  });

  const {
    data: usersData,
    isLoading,
    isFetching,
  } = useGetUsersQuery(
    {
      page: normalizePageForRequest(page || 1),
      ...filters,
    } as GetUsersPayload,
    { refetchOnMountOrArgChange: true },
  );

  const responsePagination: TablePagination = {
    pageSize: usersData?.pageable.pageSize,
    current: normalizePageForTable(usersData?.pageable?.pageNumber || 0),
    total: usersData?.totalElements,
  };

  const dataSource = usersData?.content || [];

  const columns = [
    useUserNameColumn(),
    useUserPersonNameColumn(),
    useUserEmailColumn(),
    assignedTenantsColumn,
    rightsColumn,
    useUserCreationDateColumn(),
    useUserLastEditedDateColumn(),
    useUserStatusColumn(),
    useUserActionsColumn(),
  ];

  useEffect(() => {
    if (usersData?.numberOfElements === 0 && page !== 1) {
      dispatch(setPage(page - 1));
    }
  }, [usersData?.numberOfElements]);

  return (
    <Table
      columns={columns}
      rowKey="id"
      dataSource={dataSource}
      pagination={responsePagination}
      loading={isLoading || isFetching}
      scroll={{ x: 'max-content' }}
      onChange={(tablePagination) => {
        dispatch(setPage(tablePagination.current as number));
      }}
    />
  );
};
