import { FC, useEffect } from 'react';
import { Form, message } from 'antd';
import { useResetFormOnCloseModal } from '../../../../lib/hooks/useResetFormOnCloseModal';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../components/Modal';
import { ProductCategoriesForm } from './ProductCategoriesForm';
import { useEditProductCategoryMutation } from '../../../../lib/store/api/productCategories';
import { useParams } from 'react-router-dom';
import { ProductCategoryPayloadDataT } from '../../../../lib/types/productCategories';
import { ProductCategoryT } from '../../../../lib/types/entities';
import { getFieldsData } from '../../helpers';
import { EXCEPTION_CODE } from '../../../../lib/utils/constants';

type Props = {
  isOpen: boolean;
  selectedCategory: ProductCategoryT | null;
  close: () => void;
};

const name = 'pages.products.productCategories.errors.name';
const editModal = 'pages.products.productCategories.editModal';

export const ProductCategoriesEditModal: FC<Props> = ({ close, isOpen, selectedCategory }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const { id: tenantId } = useParams();

  const [editProductCategory, { isLoading }] = useEditProductCategoryMutation();

  useEffect(() => {
    if (selectedCategory) {
      form.setFields(getFieldsData(selectedCategory));
    }
  }, [selectedCategory, form]);

  const onSubmit = async (data: Omit<ProductCategoryPayloadDataT, 'projectId'>) => {
    try {
      await editProductCategory({
        data: { ...selectedCategory, ...data } as ProductCategoryPayloadDataT,
        tenantId: tenantId as string,
      }).unwrap();
      messageApi.success(t(`${editModal}.success`));
    } catch (err: any) {
      const isSameNameError = err?.data?.exceptionCode === EXCEPTION_CODE.ERROR_007;
      messageApi.error(isSameNameError ? t(name) : t(`${editModal}.error`));
    }
  };

  useResetFormOnCloseModal({ form, isOpen });

  return (
    <>
      {contextHolder}
      <Modal
        open={isOpen}
        titleProps={{ title: t(`${editModal}.title`) }}
        cancelButtonProps={{ onClick: close }}
        actionButtonProps={{ onClick: form.submit, loading: isLoading }}
      >
        {isOpen && (
          <ProductCategoriesForm form={form} onSubmit={onSubmit} selectedCategory={selectedCategory} />
        )}
      </Modal>
    </>
  );
};
