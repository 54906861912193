import React from 'react';
import { FormItemName } from '../../../../../lib/models/Form';
import { FormInput } from '../../../../../components/FormInput';
import { FormItem } from '../../../../../components/FormItem';
import { useTranslation } from 'react-i18next';

const projectName = 'pages.projects.formItem.projectName';

export const NameFormItem = () => {
  const { t } = useTranslation();

  return (
    <FormItem name={FormItemName.NAME} hasFeedback={true} rules={[{ required: true }, { max: 128 }]}>
      <FormInput label={t(projectName)} />
    </FormItem>
  );
};

export default NameFormItem;
