import { Header } from 'antd/es/layout/layout';
import { ReactComponent as AuthImage } from './../../../../assets/images/AuthImage.svg';
import { ReactComponent as PreviewIcon } from './../../../../assets/icons/preview.svg';
import { ProfilePopup } from '../../../../components/ProfilePopup';
import { Typography } from 'antd';
import styles from './index.module.scss';
import { useHeaderData } from '../hooks/useHeaderData';
import { Button } from '../../../../components/Button';
import { useTranslation } from 'react-i18next';
import { ROUTER_PATHS } from '../../../../lib/utils/router-paths';
import { useParams } from 'react-router';
import { useAppDispatch } from '../../../../lib/store/hooks';
import { setGuestMode } from '../../../../lib/store/slices/endUserSlice';
import { useGetTenantByIdQuery } from '../../../../lib/store/api/tenants';
import { useGetProject } from '../../../../lib/hooks/useGetProjects';
import { ToggleLanguage } from '../../../../components/ToggleLanguage';

const { Text } = Typography;

const preview = 'platform.header.preview';

export const PlatformHeader = () => {
  const headerData = useHeaderData();
  const { t } = useTranslation();
  const { id: tenantId, modelId } = useParams();
  const dispatch = useAppDispatch();

  const { data: tenantData } = useGetTenantByIdQuery(Number(tenantId), {
    refetchOnMountOrArgChange: true,
    skip: !tenantId,
  });

  const { data: modelData } = useGetProject();

  const tenantName = tenantData?.name;
  const modelName = modelData?.name;

  const handlePreviewClick = () => {
    dispatch(setGuestMode(true));
    window.open(
      `/${ROUTER_PATHS.home}?tenantId=${tenantId}&modelId=${modelId}`,
      '_blank',
      'noopener,noreferrer',
    );
  };

  return (
    <>
      <Header className={styles.header}>
        <div className={styles.header_wrap}>
          <div className={styles.header_logo_wrap}>
            <AuthImage className={styles.header_logo_image} />

            <Text className={`white_color uppercase ${styles.header_text}`}>SK Loyalty</Text>

            <Text className={`${styles.header_text} ${styles.header_text__thin} white_color uppercase`}>
              platform
            </Text>

            <svg
              className={styles.header_decor}
              xmlns="http://www.w3.org/2000/svg"
              width="2"
              height="25"
              viewBox="0 0 2 25"
              fill="none"
            >
              <rect x="0.761719" y="0.796875" width="1" height="24" fill="#C4C4C4" />
            </svg>

            <Text className={`${styles.header_text__title} ${styles.header_text}`}>
              {headerData?.title || ''}
            </Text>
            {headerData?.subtitle && (
              <Text
                className={`${styles.header_text} ${styles.header_text__title} ${styles.header_text__thin}`}
              >
                {headerData.subtitle}
              </Text>
            )}

            {tenantId && (
              <Text
                style={{ marginLeft: 24 }}
                className={`${styles.header_text__title} ${styles.header_text}`}
              >
                {tenantName}
              </Text>
            )}
            {modelId && (
              <Text
                className={`${styles.header_text} ${styles.header_text__title} ${styles.header_text__thin}`}
              >
                / {modelName}
              </Text>
            )}
          </div>

          {headerData?.isPreviewButtonEnabled && (
            <Button
              size="small"
              type="default"
              icon={<PreviewIcon />}
              className={styles.header_previewBtn}
              onClick={handlePreviewClick}
            >
              {t(preview)}
            </Button>
          )}
          <div className={styles.profile}>
            <ToggleLanguage />
            <ProfilePopup />
          </div>
        </div>
      </Header>
    </>
  );
};
