import { UserRolesT } from '../../../../lib/types/auth';
import { Role } from '../../../../lib/utils/roles';
import { Typography } from 'antd';

const { Text } = Typography;

type Props = {
  role: UserRolesT;
};

export function RightsBodyCell({ role }: Props) {
  if (role === Role.SUPER_ADMIN) {
    return <Text>{'shared.roles.roles.superAdmin'}</Text>;
  }

  if (role === Role.ADMIN) {
    return <Text>{'shared.roles.roles.admin'}</Text>;
  }

  if (role === Role.EDITOR) {
    return <Text>{'shared.roles.roles.editor'}</Text>;
  }

  return <></>;
}
