import cx from 'classnames';
import { AmountBodyCell } from '../../../features/table/components/AmountBodyCell';
import { TenantNumberHeaderCell } from '../components/TenantsTable/TenantNumberHeaderCell';

import tableStyles from '../../../components/Table/index.module.scss';

type Props = any;

export const useTenantNumberColumn = (props: Props) => {
  const { columnProps, ...rest } = props;
  const { className, ...restColumnProps } = columnProps;

  return {
    ...restColumnProps,
    dataIndex: props.arrangedId,
    className: cx(tableStyles.textAlignRight, className),
    title: <TenantNumberHeaderCell {...rest} />,
    render: (amount: number) => <AmountBodyCell amount={amount} />,
  };
};
