import { ReactNode } from 'react';
import { BonusCatalogHeaderCell } from './BonusCatalogHeaderCell';
import { BONUS_CATALOG_COLUMN } from '../../../../lib/types/bonusCatalog';

type useBonusCatalogDataColumnProps = {
  title: string | ReactNode;
  arrangedId: BONUS_CATALOG_COLUMN;
  columnProps: {
    width: number;
    render?: (params?: any) => ReactNode;
  };
};

export const useBonusCatalogDataColumn = (props: useBonusCatalogDataColumnProps) => {
  const { columnProps, ...rest } = props;

  return {
    ...columnProps,
    dataIndex: props.arrangedId,
    title: <BonusCatalogHeaderCell {...rest} />,
  };
};
