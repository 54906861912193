import { useEffect } from 'react';
import { setWishListRuleBonuses } from '../../store/slices/endUserSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useGetBonusesFromRulesQuery } from '../../store/api/endUser';

export const useFetchBonusesFromWishlist = () => {
  const dispatch = useAppDispatch();

  const { statusLevels, isGuestMode } = useAppSelector(({ endUser }) => endUser);

  const { data } = useGetBonusesFromRulesQuery(
    {},
    {
      skip: isGuestMode,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    if (data) {
      const bonusesIds = data?.content?.map((item) => item?.bonusId);

      dispatch(setWishListRuleBonuses(bonusesIds));
    }
  }, [statusLevels, data, dispatch]);
};
