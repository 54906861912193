import { CreateDemandAreaModal } from '../Modals/CreateDemandAreaModal';
import styles from './index.module.scss';
import { EditDemandAreaModal } from '../Modals/EditDemandAreaModal';
import { DeleteAreaModal } from '../Modals/DeleteDemandAreaModal';
import { DemandAreasTable } from '../DemandAreasTable';

type Props = {
  tenantId: string;
  modelId: string;
};

export const DemandAreas = ({ modelId, tenantId }: Props) => {
  return (
    <div className={styles.root}>
      <DemandAreasTable modelId={modelId} tenantId={tenantId} />
      <CreateDemandAreaModal tenantId={tenantId} modelId={modelId} />
      <EditDemandAreaModal tenantId={tenantId} modelId={modelId} />
      <DeleteAreaModal tenantId={tenantId} />
    </div>
  );
};
