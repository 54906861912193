import { FC, useEffect } from 'react';
import { Form } from 'antd';
import styles from './styles.module.scss';
import { StatusLevelTitleCard } from './components/StatusLevelTitleCard';
import { StatusLevelActionsButton } from './components/StatusLevelActionsButton';
import { StatusLevelSublevels } from './components/StatusLevelSublevels';
import { useStatusLevelConfigurator } from './hooks/useStatusLevelConfigurator';
import { useParams, useSearchParams } from 'react-router-dom';
import { FormItemName } from '../../lib/models/Form';
import { StatusLevelConfiguratorFormValuesT } from '../../lib/types/statusLevel';
import { QueryParams, Tabs } from '../../lib/types/queryParams';
import { useAppSelector } from '../../lib/store/hooks';
import { getStatusLevelFieldsData } from './helpers';
import { StatusLevelModals } from './components/StatusLevelModals';
import { MessageInstance } from 'antd/es/message/interface';
import { useTranslation } from 'react-i18next';

const messages = 'pages.statusLevel.form.messages';

type Props = {
  messageApi: MessageInstance;
};

export const StatusLevelConfigurator: FC<Props> = ({ messageApi }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { id: tenantId, modelId } = useParams();
  const selectedStatusLevel = useAppSelector(({ statusLevel }) => statusLevel.selectedStatusLevel);

  const { createStatusLevel, updateStatusLevel, isLoading } = useStatusLevelConfigurator();
  const [, setSearchParams] = useSearchParams();

  const onSubmit = async (values: StatusLevelConfiguratorFormValuesT) => {
    const requestBody = {
      name: values[FormItemName.STATUS_LEVEL_TITLE],
      projectId: Number(modelId),
      subLevels: values[FormItemName.SUBLEVELS]?.map((sublevel) => ({
        name: sublevel.sublevelTitle,
        points: sublevel.sublevelPoints,
        bonuses: sublevel.sublevelBonuses,
      })),
    };

    try {
      if (selectedStatusLevel) {
        await updateStatusLevel({
          body: {
            position: selectedStatusLevel.position,
            id: selectedStatusLevel.id,
            activated: selectedStatusLevel.activated,
            ...requestBody,
          },
          tenantId: String(tenantId),
        }).unwrap();
      } else {
        await createStatusLevel({
          body: requestBody,
          tenantId: String(tenantId),
        }).unwrap();
      }

      const text = selectedStatusLevel ? t(`${messages}.successEditing`) : t(`${messages}.successCreation`);
      messageApi.success(text);
      setSearchParams({ [QueryParams.TAB]: Tabs.STATUS_LEVEL });
      //eslint-disable-next-line
    } catch (error: any) {
      const errorMessage =
        error.data.exceptionCode === 'ERROR_007' ? t(`${messages}.errorNameAlreadyExist`) : null;
      const text = selectedStatusLevel ? t(`${messages}.errorEditing`) : t(`${messages}.errorCreation`);

      messageApi.error(errorMessage || text);
    }
  };

  useEffect(() => {
    if (selectedStatusLevel) {
      const formData = getStatusLevelFieldsData(selectedStatusLevel);
      form.setFieldsValue(formData);
    } else {
      form.setFieldsValue({
        [FormItemName.SUBLEVELS]: [
          {
            [FormItemName.SUBLEVEL_TITLE]: '',
            [FormItemName.SUBLEVEL_POINTS]: '0',
            [FormItemName.SUBLEVEL_BONUSES]: [],
          },
        ],
      });
    }
  }, [form, selectedStatusLevel]);

  return (
    <>
      <Form
        form={form}
        onFinish={onSubmit}
        className={styles.form}
        scrollToFirstError={{
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        }}
      >
        <StatusLevelTitleCard />
        <StatusLevelSublevels form={form} />
        <StatusLevelActionsButton disabledSaveButton={isLoading} />
      </Form>
      <StatusLevelModals messageApi={messageApi} />
    </>
  );
};
