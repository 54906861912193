import { FC } from 'react';
import { Segmented } from 'antd';

import './index.scss';

type Props = {
  options: { value: boolean | string | any; label: string }[];
  onChange?: (value: string | number) => void;
  value?: string | number;
};

export const AppSegmented: FC<Props> = ({ options, onChange, value }) => {
  return <Segmented options={options} onChange={onChange} value={value} />;
};
