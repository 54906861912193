import { PayoutOption, PayoutType } from '../../../lib/types/bonusCatalog';
import { BONUS_PROLONGATION, UploadResponseT } from '../../../lib/types/entities';
import { BONUS_CATALOG_FORM_ITEMS } from '../helpers';

export type BonusCatalogFormValues = {
  [BONUS_CATALOG_FORM_ITEMS.NAME]: string;
  [BONUS_CATALOG_FORM_ITEMS.TITLE]: string;
  [BONUS_CATALOG_FORM_ITEMS.DESCRIPTION]: string;
  [BONUS_CATALOG_FORM_ITEMS.PICTURE]: UploadResponseT | null;
  [BONUS_CATALOG_FORM_ITEMS.PAYOUT_OPTION]: PayoutOption;
  [BONUS_CATALOG_FORM_ITEMS.PAYOUT_TYPE]: PayoutType;
};

export type CycleOptionT = {
  value: BONUS_PROLONGATION;
  title: string;
};

export enum CYCLE_OPTIONS_LABELS {
  YEARLY = 'yearly',
  HALF_YEARLY = 'halfYearly',
  QUARTERLY = 'quarterly',
  MONTHLY = 'monthly',
}
