import { useCallback, useState } from 'react';
import { FormInstance } from 'antd';
import lodashDebounce from 'lodash.debounce';
import { ArrangeFormItem } from '../../../lib/models/Arrange';
import { ArrangeListType, MINIMAL_SEARCH_LENGTH } from '../utils/constants';

const DEBOUNCED_DELAY = 600; // ms

type Props = {
  form: FormInstance<any>;
  request: any;
  formatResponse: any;
  minSearchLength?: number;
};

// REFACTOR: rename "formatResponse"
export const useStringArrange = ({
  form,
  request,
  formatResponse,
  minSearchLength = MINIMAL_SEARCH_LENGTH,
}: Props) => {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const clearOptions = () => {
    setOptions([]);
  };

  const handleRequest = async (value: string | any[]) => {
    if (value?.length >= minSearchLength) {
      form.setFieldValue(ArrangeFormItem.SELECTED_ITEMS, []); // on typing remove previous selected items
      const data = await request(value);
      const formattedResponse = formatResponse(data);
      setOptions(formattedResponse);
    } else {
      clearOptions();
    }

    setIsLoading(false);
  };

  const debounced = lodashDebounce(handleRequest, DEBOUNCED_DELAY);

  const onChangeSearchField = useCallback(async (event: { target: { value: any } }) => {
    try {
      const { value } = event.target;
      if (value?.length >= minSearchLength) {
        setIsLoading(true);
      }
      await debounced(value);
    } catch {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    filtering: {
      onChangeSearchField,
      options,
      setOptions,
      clearOptions,
      isLoading,
      type: ArrangeListType.STRING,
    },
  };
};
