import { FC, ReactNode } from 'react';
import { PlatformHeader } from './PlatformHeader';
import { Outlet } from 'react-router-dom';
import styles from './index.module.scss';

type Props = {
  sidebar: ReactNode;
};

export const PlatformLayout: FC<Props> = ({ sidebar }) => {
  return (
    <>
      <PlatformHeader />
      <div className={styles.wrapper}>
        {sidebar}
        <main className={styles.wrapper_children}>
          <Outlet />
        </main>
      </div>
    </>
  );
};
