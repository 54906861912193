import { useParams } from 'react-router';
import { useGetAllBonusesQuery } from '../../../lib/store/api/bonusCatalog';
import { useGetAllStatusLevelsQuery } from '../../../lib/store/api/statusLevel';
import { useGetProductCategoriesForFilteringQuery } from '../../../lib/store/api/productCategories';
import { useMemo } from 'react';
import { useAppSelector } from '../../../lib/store/hooks';
import { ElementSubValues, ElementValues } from '../types/entities';

export const useGetElementOperatorOptions = () => {
  const { id: tenantId, modelId } = useParams();

  const { dictionaries } = useAppSelector(({ ruleBuilder }) => ruleBuilder);

  const elementOptions = dictionaries?.elements
    ? Object.keys(dictionaries?.elements as object)?.map((option) => ({
        value: option,
        title: option,
      }))
    : [];

  const thresholdOptions = dictionaries?.elements?.[ElementValues.StatusLevel]?.[
    ElementSubValues.Threshold
  ]?.map((option) => ({
    value: option,
    title: option,
  }));

  const productSubelementOptions = dictionaries?.elements
    ? Object.keys(dictionaries?.elements?.[ElementValues.Product])?.map((option) => {
        const title = option.replace(/([A-Z])/g, ' $1');
        return {
          value: option,
          title,
        };
      })
    : [];

  const { data: bonusOptions, isLoading: isBonusesLoading } = useGetAllBonusesQuery({
    tenantId: String(tenantId),
    projectId: String(modelId),
  });
  const { data: statusLevelOptions, isLoading: isStatusLoading } = useGetAllStatusLevelsQuery({
    tenantId: String(tenantId),
    projectId: Number(modelId),
  });
  const { data: productCategories, isLoading: isCategoriesLoading } =
    useGetProductCategoriesForFilteringQuery({
      tenantId: String(tenantId),
      projectId: String(modelId),
    });

  const productCategoriesOptions = useMemo(() => {
    return productCategories?.content?.map((item) => ({
      title: item?.name,
      value: item?.id,
      options: item?.products?.map((product) => ({
        value: product?.id,
        title: product?.name,
        categoryId: item?.id,
      })),
    }));
  }, [productCategories]);

  return {
    elementOptions,
    thresholdOptions,
    bonusOptions,
    productSubelementOptions,
    statusLevelOptions,
    productOptions: productCategoriesOptions,
    isLoading: isBonusesLoading || isStatusLoading || isCategoriesLoading,
  };
};
