import { FC } from 'react';
import { Typography } from 'antd';
import { PreloaderIcon } from '../../../../assets/icons/PreloadIcon';
import { ReactComponent as CircleXIcon } from '../../../../assets/icons/cross-in-circle.svg';
import { IconButton } from '../../../../components/IconButton';
import { IconT } from '../../types';

import styles from './index.module.scss';

const { Text } = Typography;

type Props = {
  icon: IconT;
  deleteOnClick: () => void;
};

export const UploadedIconBox: FC<Props> = ({ icon, deleteOnClick }) => {
  return (
    <div className={styles.root}>
      <div className={styles.iconWrapper}>
        {icon?.s3Url ? (
          <img className={styles.iconImage} src={icon.s3Url} alt="bullet icon" />
        ) : (
          <PreloaderIcon className={styles.fileUploader} />
        )}
      </div>
      {icon?.s3Url && (
        <>
          <Text ellipsis className={styles.text}>
            {icon.originalFileName}
          </Text>
          <IconButton
            className={styles.hiddenBox}
            onClick={deleteOnClick}
            icon={<CircleXIcon className={styles.hiddenIcon} />}
          />
        </>
      )}
    </div>
  );
};
