import React, { FC } from 'react';
import { Form, FormInstance, Input, Typography } from 'antd';
import { FormItemName } from '../../../../../lib/models/Form';
import { AppCardBasic } from '../../../../../components/AppCardBasic';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { ButtonLink } from '../../../../../components/ButtonLink';
import { FormItem } from '../../../../../components/FormItem';
import { FormInput } from '../../../../../components/FormInput';
import { MailServerFormSection } from '../MailServerFormSection';

import styles from './styles.module.scss';

const { Title } = Typography;

const testButton = 'pages.settings.mailServer.testButton';
const title = 'pages.settings.mailServer.title';
const formItems = 'pages.settings.formItems';

type Props = {
  form: FormInstance;
};

export const MailServerCard: FC<Props> = ({ form }) => {
  const { t } = useTranslation();

  const switchServerChecked = Form.useWatch(FormItemName.MAIL_SERVER, form) === 'DEFAULT';

  const passwordValue = Form.useWatch(FormItemName.MAIL_SERVER_PASSWORD, form);

  const settingsSMTP = {
    [FormItemName.MAIL_SERVER_HOST]: Form.useWatch(FormItemName.MAIL_SERVER_HOST, form),
    [FormItemName.MAIL_SERVER_PORT]: Form.useWatch(FormItemName.MAIL_SERVER_PORT, form),
    [FormItemName.MAIL_SERVER_USER_NAME]: Form.useWatch(FormItemName.MAIL_SERVER_USER_NAME, form),
    [FormItemName.MAIL_SERVER_PASSWORD]: passwordValue === '********' ? null : passwordValue,
  };

  const onChangeSMTPPort = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    value = value?.toString?.() || '';
    const result = value.replace(/\D/g, '');
    form.setFieldValue(FormItemName.MAIL_SERVER_PORT, result);
  };

  const mailServerHostValue = Form.useWatch(FormItemName.MAIL_SERVER_HOST, form);
  const mailServerPortValue = Form.useWatch(FormItemName.MAIL_SERVER_PORT, form);
  const mailServerUserNameValue = Form.useWatch(FormItemName.MAIL_SERVER_USER_NAME, form);
  const mailServerPasswordValue = Form.useWatch(FormItemName.MAIL_SERVER_PASSWORD, form);

  const checkDisabled = Boolean(
    mailServerHostValue && mailServerPortValue && mailServerUserNameValue && mailServerPasswordValue,
  );

  const testConnection = () => {
    console.log(settingsSMTP);
  };

  return (
    <AppCardBasic>
      <div className={cn(styles.header)}>
        <Title level={4} className={styles.title}>
          {t(title)}
        </Title>
        <ButtonLink
          onClick={testConnection}
          disabled={!checkDisabled}
          buttonStyle={{ height: 19, padding: 0 }}
          size="large"
        >
          {t(testButton)}
        </ButtonLink>
      </div>
      <div>
        <FormItem
          name={FormItemName.MAIL_SERVER_CONTACT_EMAIL}
          help={false}
          hasFeedback={true}
          rules={[{ required: true, type: 'email' }]}
        >
          <FormInput label={t(`${formItems}.contactEmail`)} />
        </FormItem>
      </div>
      <MailServerFormSection />
      <div>
        <div>
          <FormItem
            name={FormItemName.MAIL_SERVER_SENDER}
            help={false}
            style={{ minHeight: 72 }}
            hasFeedback={true}
            rules={[{ required: !switchServerChecked, type: 'email' }]}
          >
            <FormInput disabled={switchServerChecked} label={t(`${formItems}.senderEmail`)} />
          </FormItem>
        </div>
        <div className={styles.row}>
          <FormItem
            style={{ minHeight: 72 }}
            name={FormItemName.MAIL_SERVER_HOST}
            help={false}
            hasFeedback={true}
            rules={[{ required: !switchServerChecked }]}
          >
            <FormInput disabled={switchServerChecked} label={t(`${formItems}.host`)} />
          </FormItem>
          <FormItem
            style={{ minHeight: 72 }}
            name={FormItemName.MAIL_SERVER_PORT}
            help={false}
            hasFeedback={true}
            rules={[{ required: !switchServerChecked }]}
          >
            <FormInput
              onChange={onChangeSMTPPort}
              disabled={switchServerChecked}
              label={t(`${formItems}.port`)}
            />
          </FormItem>
        </div>
        <div className={styles.row}>
          <FormItem
            style={{ minHeight: 72 }}
            name={FormItemName.MAIL_SERVER_USER_NAME}
            help={false}
            hasFeedback={true}
            rules={[{ required: !switchServerChecked }]}
          >
            <FormInput disabled={switchServerChecked} label={t(`${formItems}.mailServerUserName`)} />
          </FormItem>
          <FormItem
            style={{ minHeight: 72 }}
            name={FormItemName.MAIL_SERVER_PASSWORD}
            help={false}
            hasFeedback={true}
            rules={[{ required: !switchServerChecked }]}
          >
            <FormInput
              as={Input.Password}
              disabled={switchServerChecked}
              label={t(`${formItems}.mailServerPassword`)}
            />
          </FormItem>
        </div>
      </div>
    </AppCardBasic>
  );
};
