import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { useGetPublicDomainsQuery } from '../store/api/endUser';
import { setDomains } from '../store/slices/endUserSlice';
import { isProductionBuild } from '../utils/helpers';
import { END_USER_HARDCODED_DOMAIN } from '../utils/constants';
import { setAppLanguage } from '../store/slices/authSlice';
import { useTranslation } from 'react-i18next';
import { LANGUAGE } from '../i18n/types';

const APP_TITLE = 'HVM';

export const useUpdateHeadTag = () => {
  const { generalSettings } = useAppSelector(({ endUser }) => endUser);
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  const { hostname } = window.location;

  const { data: domains, isLoading: isDomainsLoading } = useGetPublicDomainsQuery();

  const isSubdomain =
    domains?.length === 0
      ? false
      : hostname === END_USER_HARDCODED_DOMAIN || domains?.every((item) => item.name !== hostname);

  useEffect(() => {
    if (domains) {
      dispatch(setDomains(domains));
    }

    if (isSubdomain) {
      dispatch(setAppLanguage({ language: LANGUAGE.DE }));
      i18n.changeLanguage(LANGUAGE.DE);
    }
  }, [domains, dispatch, isSubdomain, i18n]);

  useEffect(() => {
    //eslint-disable-next-line
    let link: any = document.querySelector("link[rel~='icon']");
    const metaDescription = generalSettings?.meta?.metaDescription;
    const meta = document?.querySelector("meta[name='description']");

    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].append(link);
    }

    if (!isProductionBuild) {
      link.href = '/favicon.ico?v=2';
      document.title = APP_TITLE;
    }

    if (isProductionBuild) {
      if (!isDomainsLoading && !isSubdomain) {
        link.href = '/favicon.ico?v=2';
        document.title = APP_TITLE;
      }

      if (!isDomainsLoading && isSubdomain) {
        if (generalSettings?.meta?.favicon?.s3Url) {
          link.href = `${generalSettings?.meta?.favicon.s3Url}?v=2`;
        }
        if (generalSettings?.meta?.metTitle) {
          document.title = generalSettings?.meta?.metTitle;
        }
        if (generalSettings?.meta?.metaDescription && meta) {
          meta?.setAttribute('content', metaDescription || 'SK loyalty application');
        }
      }
    }
  }, [generalSettings, isDomainsLoading, isSubdomain]);

  return { isDomainsLoading, isSubdomain };
};
