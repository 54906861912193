import styles from './styles.module.scss';
import { useLoginSection } from '../../../../app/layouts/EndUserFlowLayout/EndUserAuthLayout';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { useAppSelector } from '../../../../lib/store/hooks';
import { EndUserHouse } from '../../../endUserHouseModel/components/EndUserHouse';

export const EndUserLoginHouseModel = () => {
  const { loginSection } = useLoginSection();

  const { demandArea } = useAppSelector(({ endUser }) => endUser);

  const slicedDemandAreas = demandArea?.listDemandArea.slice(0, 4);

  const houseProps = {
    demandAreas: slicedDemandAreas,
    buttonColor: loginSection?.buttonColor,
    buttonText: loginSection?.buttonText,
  };

  const screenHeight = window.screen.height;

  const houseWrapperStyleForSmallDesktops =
    screenHeight >= 800 && screenHeight <= 960
      ? {
          transform: 'scale(0.7)',
          marginTop: -110,
        }
      : undefined;

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <EndUserTypography className={styles.title} component="Title" level={1} type="headlineFirst">
            {loginSection?.headline1}
          </EndUserTypography>
          <EndUserTypography className={styles.subTitle} component="Title" level={3} type="headlineThird">
            {loginSection?.headline2}
          </EndUserTypography>
        </div>

        <div className={styles.houseModelWrapper} style={houseWrapperStyleForSmallDesktops}>
          <EndUserHouse {...houseProps} />
        </div>
      </div>
    </div>
  );
};
