import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Status, StatusType } from '../../../../features/table/components/Status';
import { PROJECT_STATUS } from '../../helpers/constants';

const deactivated = 'shared.status.deactivated';
const notPublished = 'shared.status.notPublished';
const published = 'shared.status.published';

type Props = {
  status?: string | boolean;
};

export const ProjectStatusBodyCell: FC<Props> = ({ status }) => {
  const { t } = useTranslation();

  switch (status) {
    case PROJECT_STATUS.PUBLISHED:
      return <Status textValue={t(published)} type={StatusType.GREEN} />;
    case PROJECT_STATUS.NOT_PUBLISHED:
      return <Status textValue={t(notPublished)} type={StatusType.ORANGE} />;
    case PROJECT_STATUS.DEACTIVATED:
      return <Status textValue={t(deactivated)} type={StatusType.GRAY} />;

    default:
      return <span></span>;
  }
};
