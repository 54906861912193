import React, { FC } from 'react';
import { IconButton } from '../../../../components/IconButton';

import { ReactComponent as CarouselLeftArrow } from './../../../../assets/icons/carousel-left-arrow.svg';
import { ReactComponent as CarouselRightArrow } from './../../../../assets/icons/carousel-right-arrow.svg';

import styles from './styles.module.scss';
import Slider from 'react-slick';

type Props = {
  carouselRef: React.RefObject<Slider>;
  children: React.ReactNode;
};

export const CarouselButtons: FC<Props> = ({ carouselRef, children }) => {
  const onClickNext = () => {
    if (carouselRef.current) {
      carouselRef.current.slickNext();
    }
  };

  const onClickPrev = () => {
    if (carouselRef.current) {
      carouselRef.current.slickPrev();
    }
  };

  return (
    <div className={styles.container}>
      <IconButton onClick={onClickPrev} className={styles.iconButton} icon={<CarouselLeftArrow />} />

      {children}

      <IconButton onClick={onClickNext} className={styles.iconButton} icon={<CarouselRightArrow />} />
    </div>
  );
};
