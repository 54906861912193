import { FC, useEffect, useState } from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Form, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { isLength } from '../../../../lib/utils/helpers';
import { Divider } from '../../../../components/Divider';
import { FormSection } from '../../../../components/FormSection';
import { ArrangeFormItem } from '../../../../lib/models/Arrange';
import { CheckboxGroup } from './CheckboxGroup';

import styles from './index.module.scss';
import { AppCheckbox } from '../../../../components/AppCheckox';

const { Text } = Typography;

function convertOptionsToValues(options: any[]) {
  return options.map((item) => item.value);
}

const generic = 'generic';
const arranges = 'shared.arranges';

type Props = any;

export const CheckboxAllFormSection: FC<Props> = (props) => {
  const {
    rootProps,
    minSearchLength,
    formSectionProps,
    checkboxGroupProps: {
      groupProps: { options = {}, ...restGroupProps } = {},
      rootProps: { className = '', ...restRootProps } = {},
    } = {},
    isLoading,
  } = props;

  const { t } = useTranslation();

  const { name: fieldName } = formSectionProps.formItemProps;

  const [indeterminate, setIndeterminate] = useState(true);
  const form = Form.useFormInstance();
  const selected = Form.useWatch(fieldName, form);
  const searchValue = Form.useWatch(ArrangeFormItem.SEARCH, form);
  const [areAllChecked, setAreAllChecked] = useState(false);
  const areOptions = isLength(options);
  const areNoResults =
    !isLoading && searchValue?.length >= minSearchLength && searchValue?.length >= 1 && !areOptions;

  const setCheckbox = (selectedOptions: string | any[]) => {
    form.setFieldValue(fieldName, selectedOptions);
    setIndeterminate(isLength(selectedOptions) && selectedOptions.length < options.length);
    setAreAllChecked(selectedOptions.length === options.length);
  };

  const toggleAll = (event: CheckboxChangeEvent) => {
    const value = event?.target?.checked;
    const toggledOn = convertOptionsToValues(options);
    form.setFieldValue(fieldName, value ? toggledOn : []);
    setIndeterminate(false);
    setAreAllChecked(value);
  };

  useEffect(() => {
    if (options?.length === selected?.length) {
      setAreAllChecked(true);
    } else {
      setAreAllChecked(false);
    }
  }, [selected, options]);

  if (areNoResults) {
    return (
      <div className={styles.noResults}>
        <Text>{t(`${arranges}.filter.noResultsFound`)}</Text>
      </div>
    );
  }
  if (!areOptions) {
    return null;
  }

  return (
    <div className={styles.root} {...rootProps}>
      <AppCheckbox onChange={toggleAll} checked={areAllChecked} label={t(`${generic}.selectAll`)} />

      <Divider className={styles.divider} />

      <FormSection {...formSectionProps}>
        <CheckboxGroup
          rootProps={{
            className: `${styles.checkbox} ${className}`,
            ...restRootProps,
          }}
          groupProps={{
            onChange: setCheckbox,
            options,
            value: selected,
            ...restGroupProps,
          }}
        />
      </FormSection>
    </div>
  );
};
