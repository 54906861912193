import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalActions } from '../../models/Modal';
import { TableSortOrder } from '../../utils/constants';
import { Filter, FiltersPayload, Sort } from '../../types/filteringAndSort';
import { BONUS_CATALOG_COLUMN, BonusCatalog } from '../../types/bonusCatalog';
import { ProductsFiltersPayload } from './productsSlice';
import { PRODUCTS_COLUMNS } from '../../../modules/products/helpers/consts';

type BonusCatalogSliceStateT = {
  modalType: ModalActions | null;
  isModalOpen: boolean;
  selectedBonusCatalog: BonusCatalog | null;
  page: number;
  sorting: Sort;
  arrangeId: BONUS_CATALOG_COLUMN | null;
  filtering: Filter;
};

const initialState: BonusCatalogSliceStateT = {
  arrangeId: null,
  page: 1,
  isModalOpen: false,
  modalType: null,
  selectedBonusCatalog: null,
  filtering: {
    filterFieldName: null,
    filter: [],
    filtersWithLabels: [],
  },
  sorting: {
    sortOrder: TableSortOrder.NONE,
    sortFieldName: null,
    withSorting: true,
  },
};

export type BonusFiltersPayload = FiltersPayload & {
  arrangeId: BONUS_CATALOG_COLUMN;
};

const bonusCatalogSlice = createSlice({
  name: 'bonusCatalogSlice',
  initialState,
  reducers: {
    setModalOpened: (state, action: PayloadAction<ModalActions>) => {
      state.modalType = action.payload;
      state.isModalOpen = true;
    },
    setModalClosed: (state) => {
      state.selectedBonusCatalog = null;
      state.modalType = null;
      state.isModalOpen = false;
    },
    setCurrentBonusCatalog: (state, action: PayloadAction<BonusCatalog>) => {
      state.selectedBonusCatalog = action.payload;
    },
    setSorting: (state, action: PayloadAction<{ sorting: Sort; arrangeId: BONUS_CATALOG_COLUMN }>) => {
      state.arrangeId = action.payload.arrangeId;
      state.sorting = action.payload.sorting;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setFilters: (state, action: PayloadAction<BonusFiltersPayload>) => {
      state.arrangeId = action.payload.arrangeId;
      state.sorting = action.payload.sorting;
      state.filtering = action.payload.filtering;
    },
  },
});

export const { setModalOpened, setFilters, setModalClosed, setCurrentBonusCatalog, setSorting, setPage } =
  bonusCatalogSlice.actions;
export const bonusCatalog = bonusCatalogSlice.reducer;
