import { useTranslation } from 'react-i18next';
import { useProjectDateColumn } from './useProjectDateColumn';
import { PROJECT_COLUMN } from '../helpers/constants';
import tableStyles from '../../../components/Table/index.module.scss';

const contractStart = 'pages.projects.columns.contractStart';

export const useContractStartColumn = () => {
  const { t } = useTranslation();

  return useProjectDateColumn({
    arrangedId: PROJECT_COLUMN.CONTRACT_START,
    title: t(contractStart),
    filterFromName: 'contractStartFrom',
    filterToName: 'contractStartTo',
    headerCellProps: {
      rootProps: {
        className: tableStyles.hiddenHeaderCell,
      },
    },
  });
};
