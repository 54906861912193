import { FC } from 'react';
import { RuleTypesTable } from '../tables/RuleTypesTable';
import { RuleTypeModal } from '../modals/RuleTypeModal';
import { DeleteModal } from '../modals/DeleteModal';

type Props = {
  tenantId: string;
  modelId: string;
};

export const RuleTypesTab: FC<Props> = ({ tenantId, modelId }) => {
  return (
    <>
      <RuleTypesTable tenantId={tenantId} modelId={modelId} />
      <RuleTypeModal tenantId={tenantId} modelId={modelId} />
      <DeleteModal tenantId={tenantId} />
    </>
  );
};
