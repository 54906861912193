import {useState, useMemo, useRef, FC, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Select} from 'antd';
import Slider from 'react-slick';
import {ReactComponent as ChevronDownIcon} from '../../../../assets/icons/ChevronDownIcon.svg';
import {useAppDispatch, useAppSelector} from '../../../../lib/store/hooks';
import {IconComponent} from '../../../../components/IconComponent';
import {setCurrentCategory} from '../../../../lib/store/slices/endUserSlice';
import {sendDataToAnalytics} from '../../../../lib/utils/sendDataToAnalytics';
import {Category} from '../../../../lib/types/endUser';
import {checkBoughtProductsInCategory, getCoveredCategories} from '../../../../lib/utils/end-user-helpers';
import {parseJSON} from '../../../../lib/utils/helpers';
import {EndUserTypography} from '../../../../components/EndUserTypography';
import {useTranslation} from 'react-i18next';
import cn from 'classnames'

import styles from './index.module.scss';

const translation = 'endUser.shared';

const settings = {
    infinite: false,
    centerMode: false,
    speed: 500,
    slidesToScroll: 1,
    variableWidth: true,
    swipeToSlide: true,
};

type Props = {
    demandAreas: any;
};

const sortCategoriesByPriority = (categories: any[]) => {
    return categories.slice().sort((a, b) => a.priority.number - b.priority.number);
};

export const EndUserDemandAreaMobile: FC<Props> = ({demandAreas}) => {
    const {id} = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {selectedCategory, tenantAndProject} = useAppSelector(({endUser}) => endUser);
    const {t} = useTranslation();
    const filteredDemandArea = demandAreas && demandAreas?.find((area: any) => Number(area.id) === Number(id));

    const [currentDemandArea, setCurrentDemandArea] = useState(filteredDemandArea);
    const carouselRef = useRef<Slider>(null);
    const [selectName, setSelectName] = useState(currentDemandArea?.name);

    useEffect(() => {
        setCurrentDemandArea(filteredDemandArea);
        setSelectName(currentDemandArea?.name);
    }, [filteredDemandArea, demandAreas, currentDemandArea?.name]);

    useEffect(() => {
        setSelectName(currentDemandArea?.name);
    }, [currentDemandArea]);

    const handleChange = (id: string) => {
        const selectedDemandArea = demandAreas.find((area: any) => Number(area.id) === Number(id));

        sendDataToAnalytics(
            'DemandAreasPage',
            'changeDemandArea',
            `Demand area name: ${String(selectedDemandArea.name)}, demand area id: ${selectedDemandArea.id}`,
        );

        const sortedCategories = sortCategoriesByPriority(selectedDemandArea?.categories || []);

        let category: Category | undefined = sortedCategories[0];

        //hardcode below
        if (tenantAndProject?.subDomain === 'energie-schwaben') {
            if (selectedDemandArea.name === 'Energie') {
                const gasCategory = sortedCategories.find((category) => category.name === 'Gas');
                const stromCategory = sortedCategories.find((category) => category.name === 'Strom');

                const isGasCovered = gasCategory && checkBoughtProductsInCategory(gasCategory);
                const isStromCovered = stromCategory && checkBoughtProductsInCategory(stromCategory);

                if (isGasCovered && !isStromCovered) {
                    category = stromCategory;
                } else if (isStromCovered && !isGasCovered) {
                    category = gasCategory;
                } else if (isGasCovered && isStromCovered) {
                    category = gasCategory;
                } else {
                    category = gasCategory;
                }
            }
        }

        dispatch(setCurrentCategory({category}));

        setCurrentDemandArea(selectedDemandArea as any);
        if (selectedDemandArea.id) {
            navigate(`/house-model/${selectedDemandArea.id}`);
        }
    };

    const demandAreaColor = useMemo(() => {
        return parseJSON(currentDemandArea?.color);
    }, [currentDemandArea]);

    const totalCategories = currentDemandArea?.categories.length;
    const coveredCategoriesAmount = getCoveredCategories(currentDemandArea?.categories).length;

    const onClickCategory = (category: Category) => {
        const demandArea = demandAreas.find((area: Category) => area.id === category.demandAreaId);

        sendDataToAnalytics(
            'Aufruf Produktkategorie',
            `Aufruf Produktkategorie im Bedarfsfeld ${demandArea?.name} `,
            `User exploriert die Produktkategorie ${category.name}`,
        );

        dispatch(setCurrentCategory({category}));
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, category: Category) => {
        if (event.key === 'Enter' || event.key === ' ') {
            onClickCategory(category);
        }
    };

    const sortedCategories = useMemo(() => {
        return sortCategoriesByPriority(currentDemandArea?.categories || []);
    }, [currentDemandArea?.categories]);

    const renderRef = useRef<number | null>(null);

    useEffect(() => {
        if (sortedCategories.length > 0 && renderRef.current === null) {
            dispatch(setCurrentCategory({category: sortedCategories[0]}));
            renderRef.current = 1;
        }
    }, [sortedCategories, dispatch]);

    const applyNewHouse =
        (tenantAndProject?.domain === 'dev.hvm.impltech.online' && tenantAndProject?.id === 11486) ||
        (tenantAndProject?.subDomain === 'energie-schwaben' && tenantAndProject?.tenantId === 11002);

    return (
        <div>
            <div
                className={styles.selectWrapper}
                style={{
                    backgroundColor: demandAreaColor?.value,
                }}
            >
                <Select
                    bordered={false}
                    suffixIcon={<ChevronDownIcon style={{marginRight: '16px'}}/>}
                    defaultValue={selectName}
                    onChange={handleChange}
                    options={demandAreas?.map((area: any) => ({
                        value: area.id,
                        label: area.name,
                    }))}
                />
            </div>

            <div
                style={{
                    background: `linear-gradient(0deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%), ${demandAreaColor?.value} `,
                    padding: '16px 0px 0px 16px',
                }}
            >
                <div>
                    <EndUserTypography
                        type="headlineFourth"
                        component="Title"
                        style={{color: '#444', marginBottom: '18px'}}
                    >
                        {currentDemandArea?.name}
                    </EndUserTypography>
                    <EndUserTypography
                        type="headlineEight"
                        component="Title"
                        style={{color: demandAreaColor?.value, marginBottom: '24px'}}
                    >
                        {t(`${translation}.coveredCategories`, {coveredCategoriesAmount, totalCategories})}
                    </EndUserTypography>
                </div>
                <div className={styles.sliderWrapper}>
                    <Slider ref={carouselRef} {...settings}>
                        {sortedCategories.map((category: Category, index: number) => {
                            const hasUserProducts = checkBoughtProductsInCategory(category);

                            return (
                                <div key={index}>
                                    <div
                                        tabIndex={0}
                                        role="button"
                                        onClick={() => onClickCategory(category)}
                                        onKeyDown={(event) => handleKeyDown(event, category)}
                                        className={cn(styles.card, applyNewHouse && styles.card_new)}
                                        style={{
                                            background: hasUserProducts ? demandAreaColor?.value : '#fff',
                                        }}
                                    >
                                        <div>
                                            <IconComponent
                                                className={styles.icon}
                                                iconData={category?.icon}
                                                color={hasUserProducts ? '#fff' : demandAreaColor.value}
                                            />
                                            <EndUserTypography
                                                type="subText"
                                                component="Text"
                                                className={styles.title}
                                                style={{
                                                    minHeight: '40px',
                                                    color: hasUserProducts ? '#fff' : demandAreaColor?.value,
                                                    textAlign: 'center',
                                                    width: 96,
                                                    marginBottom: 0,
                                                }}
                                            >
                                                {category.name}
                                            </EndUserTypography>
                                        </div>
                                    </div>
                                    <div
                                        className={styles.underline}
                                        style={{
                                            background:
                                                sortedCategories.at(index)?.id === selectedCategory?.id
                                                    ? demandAreaColor?.value
                                                    : 'transparent',
                                        }}
                                    ></div>
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            </div>
        </div>
    );
};
