import { FC } from 'react';
import { Modal } from 'antd';
import { ReactComponent as CrossIcon } from '../../../assets/icons/CrossIcon.svg';
import { EndUserTypography } from '../../../components/EndUserTypography';
import { IconButton } from '../../../components/IconButton';
import styles from './styles.module.scss';
import { parseJSON } from '../../../lib/utils/helpers';

type Props = {
  isModalOpen: boolean;
  close: () => void;
  selectedProduct: any;
};
export const EndUserBoughtProductModal: FC<Props> = ({ isModalOpen, close, selectedProduct }) => {
  const additionalInfo = parseJSON(selectedProduct?.additionalInfo);

  return selectedProduct ? (
    <div>
      <Modal
        centered
        open={isModalOpen}
        onCancel={close}
        destroyOnClose={true}
        closeIcon={false}
        bodyStyle={{ margin: '-34px -24px -32px' }}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <IconButton
          onClick={close}
          className={styles.closeButton}
          icon={<CrossIcon width={16} height={16} style={{ color: '#5C5C6E' }} />}
        />
        <div style={{ backgroundColor: '#fff' }}>
          <div className={styles.container}>
            <div className={styles.imageContainer}>
              <img className={styles.image} alt="bonus" src={additionalInfo.picture.s3Url} />
            </div>

            <EndUserTypography type="headlineFourth" component="Title">
              {selectedProduct && (selectedProduct as any).name}
            </EndUserTypography>

            <EndUserTypography className={styles.description} type="subText" component="Paragraph">
              {selectedProduct && (selectedProduct as any).description}
            </EndUserTypography>
          </div>
        </div>
      </Modal>
    </div>
  ) : null;
};
