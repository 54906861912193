import { FC } from 'react';
import { components } from 'react-select';
import { AppCheckbox } from '../../../AppCheckox';
import { GroupPropsT, OnSelectGroupPropsT } from '../../types';

import styles from './index.module.scss';

export const SelectableGroup: FC<any> = (props) => {
  const { onSelectGroup, selectedGroups = [] }: GroupPropsT = props.selectProps.groupProps;
  const currentGroupId = props?.data?.value;
  const currentGroupProducts = props?.data?.options;
  const selectedProducts = props?.selectProps?.value;
  const isGroupSelected = selectedGroups?.includes(currentGroupId);

  const onClick = () => {
    const getNewSelectedValues = () => {
      const productsWithoutCurrentGroupProducts =
        selectedProducts?.filter((item: { categoryId: number }) => props?.data?.value !== item?.categoryId) ||
        [];

      if (isGroupSelected) {
        const productCategories = selectedGroups?.filter((item) => item !== currentGroupId);

        return {
          productCategories,
          products: productsWithoutCurrentGroupProducts,
        };
      } else {
        return {
          productCategories: [...selectedGroups, currentGroupId],
          products: [...productsWithoutCurrentGroupProducts, ...currentGroupProducts],
        };
      }
    };

    const newSelectedValues: OnSelectGroupPropsT = getNewSelectedValues();

    onSelectGroup(newSelectedValues);
  };

  return (
    <div className={styles.group}>
      <AppCheckbox checked={isGroupSelected} label={`All of ${props?.data?.title}`} onChange={onClick} />
      <components.Group {...props} />
    </div>
  );
};
