import { FC, ReactNode, useMemo } from 'react';
import { ArrangePopupWrapper } from './ArrangePopupWrapper';
import { FilterBlockWrapper } from './FilterBlockWrapper';
import { SortBlock } from './SortBlock';
import { Divider } from '../../../../components/Divider';

import styles from './index.module.scss';

type Props = {
  children: ReactNode;
  disableSorting?: boolean;
  disableFiltering?: boolean;
  isDate?: boolean;
};

export const ArrangeSortAndFilterPopupWrapper: FC<Props> = ({
  children,
  disableSorting,
  disableFiltering,
  isDate,
}) => {
  const showDivider = useMemo(() => {
    return !(disableSorting || disableFiltering);
  }, [disableFiltering, disableSorting]);

  return (
    <ArrangePopupWrapper
      sort={disableSorting ? undefined : <SortBlock isDate={isDate} />}
      filter={
        <>
          {showDivider && <Divider className={styles.divider} />}
          {!disableFiltering && <FilterBlockWrapper>{children}</FilterBlockWrapper>}
        </>
      }
    />
  );
};
