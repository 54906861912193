import { FC, useEffect } from 'react';
import { Table } from '../../../../components/Table';
import { useProductCategoryNameColumn } from '../../hooks/useProductCategoryNameColumn';

import styles from './styles.module.scss';
import { useProductCategoriesIconColumn } from '../../hooks/useProductCategoriesIconColumn';
import { useProductCategoriesDescriptionColumn } from '../../hooks/useProductCategoriesDescriptionColumn';
import { useProductCategoriesDemandAreaColumn } from '../../hooks/useProductCategoriesDemandAreaColumn';
import { useProductCategoriesPriorityColumn } from '../../hooks/useProductCategoriesPriorityColumn';
import { useProductCategoriesNumberOfProductsColumn } from '../../hooks/useProductCategoriesNumberOfProductsColumn';
import { useGetProductCategoriesQuery } from '../../../../lib/store/api/productCategories';
import { useParams } from 'react-router-dom';
import { useProductCategoriesStatusColumn } from '../../hooks/useProductCategoriesStatusColumn';
import { useProductCategoriesSelectionTypeColumn } from '../../hooks/useProductCategoriesSelectionTypeColumn';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { normalizePageForRequest, normalizePageForTable } from '../../../../lib/utils/helpers';
import { GetProductCategoriesPayloadT } from '../../../../lib/types/productCategories';
import { TablePagination } from '../../../../lib/types/pagination';

import { resetFields, setPage } from '../../../../lib/store/slices/productCategoriesSlice';
import { useProductCategoriesActionColumn } from '../../hooks/useProductCategoriesActionColumn';

type Props = {
  handleTabChange: (key: string) => void;
};
export const ProductCategoriesTable: FC<Props> = ({ handleTabChange }) => {
  const { id: tenantId, modelId } = useParams();

  const page = useAppSelector(({ productCategories }) => productCategories.page);

  const dispatch = useAppDispatch();

  const filters = useAppSelector(({ productCategories }) => {
    const arrangedId = productCategories.arrangeId;
    return { arrangedId, ...productCategories.filtering, ...productCategories.sorting };
  });

  const { data, isLoading, isFetching } = useGetProductCategoriesQuery(
    {
      tenantId: String(tenantId),
      projectId: modelId,
      page: normalizePageForRequest(page || 1),
      ...filters,
    } as GetProductCategoriesPayloadT,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const responsePagination: TablePagination = {
    pageSize: data?.pageable.pageSize,
    current: normalizePageForTable(data?.pageable?.pageNumber || 0),
    total: data?.totalElements,
  };

  const dataSource = data?.content || [];

  const columns = [
    useProductCategoryNameColumn(),
    useProductCategoriesIconColumn(),
    useProductCategoriesDescriptionColumn(),
    useProductCategoriesDemandAreaColumn(),
    useProductCategoriesPriorityColumn(),
    useProductCategoriesNumberOfProductsColumn({ handleTabChange, dispatch }),
    useProductCategoriesSelectionTypeColumn(),
    useProductCategoriesStatusColumn(),
    useProductCategoriesActionColumn(),
  ];

  useEffect(() => {
    dispatch(resetFields());
  }, [dispatch]);

  useEffect(() => {
    if (data?.numberOfElements === 0 && page !== 1) {
      dispatch(setPage(page - 1));
    }
  }, [data?.numberOfElements]);

  return (
    <Table
      columns={columns}
      rowKey="id"
      className={styles.table}
      dataSource={dataSource}
      pagination={responsePagination}
      loading={isLoading || isFetching}
      scroll={{ x: 'max-content' }}
      onChange={(tablePagination) => {
        dispatch(setPage(tablePagination.current as number));
      }}
    />
  );
};
