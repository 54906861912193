import styles from './index.module.scss';
import cx from 'classnames';
import { Typography } from 'antd';

const { Text } = Typography;

type Props = {
  text: string;
  color: string;
  size?: string;
  hex?: string;
};

export const FontColorTag = ({ text, color, size, hex }: Props) => {
  const isSmall = size === 'small';
  const isLarge = size === 'large';

  return (
    <div className={styles.font_color_wrap}>
      <div>
        <div
          className={cx(styles.font_color_square, {
            [styles.small]: isSmall,
            [styles.large]: isLarge,
          })}
          style={{ backgroundColor: color }}
        />
      </div>
      <Text ellipsis>
        {text} {hex && `(${hex.toUpperCase()})`}
      </Text>
    </div>
  );
};
