import { FC } from 'react';
import { Card as AppCard, Form, message } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { ROUTER_PATHS } from '../../../../lib/utils/router-paths';
import { secToMs } from '../../../../lib/utils/helpers';
import { Button } from '../../../../components/Button';
import { AppTitle } from '../AppTitle';
import { RepeatPasswordFormItem } from '../RepeatPasswordFormItem';
import { SetupPasswordFormItem } from '../SetupPasswordFormItem';
import { FormItemName } from '../../../../lib/models/Form';
import styles from './index.module.scss';
import { useSetUpPasswordMutation } from '../../../../lib/store/api/auth';

const setUpPassword = 'pages.setUpPassword';
const passwordContainPersonalData = `${setUpPassword}.passwordContainPersonalData`;
const tittle = `${setUpPassword}.tittle`;
const successMessage = `${setUpPassword}.successMessage`;
const badLinkErrorMessage = `${setUpPassword}.badLinkErrorMessage`;
const formTranslate = `${setUpPassword}.form`;

const MESSAGE_SHOWING_TIME = 4; // seconds

export const SetUpPasswordCard: FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [setUpPassword, { isLoading }] = useSetUpPasswordMutation();

  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const redirectToLogin = () => navigate(ROUTER_PATHS.logIn, { replace: true });

  const isBothFieldsTouched =
    form.isFieldTouched(FormItemName.PASSWORD) && form.isFieldTouched(FormItemName.REPEAT_PASSWORD);

  const errors = form.getFieldsError();

  const isSaveButtonAvailable =
    isBothFieldsTouched && errors[0].errors.length === 0 && errors[1].errors.length === 0;

  const onFinish = async (formValues: { password: string }) => {
    try {
      await setUpPassword({
        key: token ?? '',
        newPassword: formValues.password,
      }).unwrap();

      messageApi.success(t(successMessage));
      setTimeout(redirectToLogin, secToMs({ sec: MESSAGE_SHOWING_TIME }));
    } catch (err: any) {
      if (err?.data?.detail === 'Password does not comply with security policies') {
        messageApi.error(t(passwordContainPersonalData));
      } else {
        messageApi.error(t(badLinkErrorMessage));
      }
    }
  };

  return (
    <AppCard bodyStyle={{ padding: 0 }} className={styles.wrapper}>
      <AppTitle className={styles.title}>
        <Trans
          className={styles.title}
          i18nKey={tittle}
          components={{
            regularText: <span className={styles.regularTitle} />,
          }}
        />
      </AppTitle>
      {contextHolder}

      <Form form={form} onFinish={onFinish}>
        {
          (() => {
            return (
              <div>
                <SetupPasswordFormItem
                  errors={form.getFieldError(FormItemName.PASSWORD)}
                  isTouched={form.isFieldTouched(FormItemName.PASSWORD)}
                />
                <RepeatPasswordFormItem />

                <Button
                  htmlType="submit"
                  disabled={!isSaveButtonAvailable}
                  loading={isLoading}
                  style={{ marginTop: '35px' }}
                >
                  {t(`${formTranslate}.actionButton`)}
                </Button>
              </div>
            );
          }) as any
        }
      </Form>
    </AppCard>
  );
};
