import { useTranslation } from 'react-i18next';
import { PRODUCTS_COLUMNS } from '../helpers/consts';
import { useProductSearchDataColumn } from './useProductSearchDataColumn';
import { useSearchParams } from 'react-router-dom';
import { Typography } from 'antd';
import { useAppDispatch } from '../../../lib/store/hooks';
import { setCurrentProduct } from '../../../lib/store/slices/productsSlice';
import { ProductT } from '../../../lib/types/product';
import { QueryParams, Tabs } from '../../../lib/types/queryParams';

const product = 'pages.products.products.columns.product';

export const useProductNameColumn = () => {
  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const handleClickName = (row: ProductT) => {
    dispatch(setCurrentProduct(row));
    setSearchParams({ [QueryParams.TAB]: Tabs.PRODUCT_CONFIGURATOR });
  };

  return useProductSearchDataColumn({
    title: t(product),
    arrangedId: PRODUCTS_COLUMNS.NAME,
    searchFieldName: 'searchByName',
    filterFieldName: 'includeNames',
    columnProps: {
      width: 180,
      render: (name: string, row: ProductT) => {
        return (
          <span onClick={() => handleClickName(row)} role="presentation" style={{ cursor: 'pointer' }}>
            <Typography.Text>{name}</Typography.Text>
          </span>
        );
      },
    },
  });
};
