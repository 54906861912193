import { Form, Typography } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useResetFormOnCloseModal } from '../../../../lib/hooks/useResetFormOnCloseModal';
import { AppModal } from '../../../../components/AppModal';
import { useDeleteProductMutation } from '../../../../lib/store/api/products';
import { useParams, useSearchParams } from 'react-router-dom';
import { ProductT } from '../../../../lib/types/product';
import { setCurrentProduct } from '../../../../lib/store/slices/productsSlice';
import { useAppDispatch } from '../../../../lib/store/hooks';
import { MessageInstance } from 'antd/es/message/interface';
import { QueryParams, Tabs } from '../../../../lib/types/queryParams';

const { Paragraph } = Typography;
const messages = 'pages.products.productConfigurator.messages';
const deleteModal = 'pages.products.productConfigurator.deleteModal';

type Props = {
  isOpen: boolean;
  close: () => void;
  selectedProduct: ProductT | null;
  messageApi: MessageInstance;
};

export const DeleteProductModal: FC<Props> = ({ isOpen, close, selectedProduct, messageApi }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const { id: tenantId } = useParams();
  const [, setSearchParams] = useSearchParams();

  const [deleteProduct, { isLoading }] = useDeleteProductMutation();

  useResetFormOnCloseModal({ form, isOpen });

  const onConfirm = () => {
    form.submit();
  };

  const onFinish = async () => {
    try {
      await deleteProduct({
        tenantId: String(tenantId),
        productId: Number(selectedProduct?.id),
      }).unwrap();
      messageApi.success(t(`${messages}.successDeleting`));
      close();
      dispatch(setCurrentProduct(null));
      setSearchParams({ [QueryParams.TAB]: Tabs.PRODUCTS });
    } catch (err: any) {
      messageApi.error(err?.data?.message || t(`${messages}.errorDeleting`));
    }
  };

  return (
    <AppModal
      open={isOpen}
      onCancel={close}
      onOk={onConfirm}
      okText={t('generic.delete')}
      titleText={t(`${deleteModal}.title`)}
      isOkLoading={isLoading}
    >
      {isOpen && (
        <Form form={form} onFinish={onFinish}>
          <Paragraph style={{ marginBottom: '36px' }}>
            {t(`${deleteModal}.text`, { productName: selectedProduct?.name })}
          </Paragraph>
        </Form>
      )}
    </AppModal>
  );
};
