import React, {FC} from 'react';

type Props = {
    fill: string
};

export const BottomLeftElement: FC<Props> = ({ fill  = "#FFF6E8"}) => {
    return (
        <svg width="49" height="36" viewBox="0 0 49 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.804443" width="48" height="36" rx="4" fill={fill}/>
        </svg>

    );
};

