import { useAppDispatch } from '../../../../lib/store/hooks';
import { Space } from 'antd';
import { IconButton } from '../../../../components/IconButton';
import { EditIcon } from '../../../../assets/icons/EditIcon';
import { ActionsDropdown } from '../../../../components/Table/ActionsDropdown/ActionsDropdown';
import { ModalActions } from '../../../../lib/models/Modal';
import { UserDataT } from '../../../../lib/types/auth';
import { setCurrentUsers, setModalOpened } from '../../../../lib/store/slices/userManagementSlice';

export function UserActionsBodyCell({ row }: any) {
  const dispatch = useAppDispatch();

  const onEditUser = (user: UserDataT) => () => {
    dispatch(setCurrentUsers(user));
    dispatch(setModalOpened(ModalActions.EDIT));
  };

  return (
    <Space size="middle">
      <IconButton icon={<EditIcon />} onClick={onEditUser(row)} />
      <ActionsDropdown table="users" row={row} />
    </Space>
  );
}
