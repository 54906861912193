import { FC } from 'react';
import { Form, message } from 'antd';
import { useResetFormOnCloseModal } from '../../../../lib/hooks/useResetFormOnCloseModal';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../components/Modal';
import { AddTenantForm } from './AddTenantForm';
import { useAddTenantMutation } from '../../../../lib/store/api/tenants';

const title = 'pages.tenants.addModal.title';
const additionalInfo = 'pages.tenants.formItems.additionalInfo';

type Props = {
  isOpen: boolean;
  close: () => void;
};

export const AddTenantModal: FC<Props> = ({ close, isOpen }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const [addTenant, { isLoading }] = useAddTenantMutation();

  useResetFormOnCloseModal({ form, isOpen });

  return (
    <>
      {contextHolder}
      <Modal
        open={isOpen}
        titleProps={{ title: t(title) }}
        cancelButtonProps={{ onClick: close }}
        actionButtonProps={{ onClick: form.submit, loading: isLoading }}
      >
        {isOpen && (
          <AddTenantForm
            form={form}
            messageApi={messageApi}
            additionalInfo={t(additionalInfo)}
            onSubmit={addTenant}
          />
        )}
      </Modal>
    </>
  );
};
