import { useState } from 'react';

export const CommentSquare = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <svg
      style={{ transition: '0.2s' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        style={{ transition: '0.2s' }}
        d="M6.678 15.894C6.7818 15.9982 6.86133 16.124 6.91094 16.2625C6.96055 16.4009 6.97901 16.5486 6.965 16.695C6.89472 17.3726 6.76148 18.0421 6.567 18.695C7.962 18.372 8.814 17.998 9.201 17.802C9.4205 17.6908 9.67329 17.6645 9.911 17.728C10.5924 17.9097 11.2948 18.0011 12 18C15.996 18 19 15.193 19 12C19 8.808 15.996 6 12 6C8.004 6 5 8.808 5 12C5 13.468 5.617 14.83 6.678 15.894ZM6.185 19.799C5.94807 19.846 5.71037 19.889 5.472 19.928C5.272 19.96 5.12 19.752 5.199 19.566C5.28779 19.3566 5.36918 19.1441 5.443 18.929L5.446 18.919C5.694 18.199 5.896 17.371 5.97 16.6C4.743 15.37 4 13.76 4 12C4 8.134 7.582 5 12 5C16.418 5 20 8.134 20 12C20 15.866 16.418 19 12 19C11.2077 19.0011 10.4186 18.8982 9.653 18.694C9.133 18.957 8.014 19.436 6.185 19.799Z"
        fill={isHovered ? '#E9769E' : '#191919'}
      />
      <path
        style={{ transition: '0.2s' }}
        d="M8 9.5C8 9.36739 8.05268 9.24021 8.14645 9.14645C8.24021 9.05268 8.36739 9 8.5 9H15.5C15.6326 9 15.7598 9.05268 15.8536 9.14645C15.9473 9.24021 16 9.36739 16 9.5C16 9.63261 15.9473 9.75979 15.8536 9.85355C15.7598 9.94732 15.6326 10 15.5 10H8.5C8.36739 10 8.24021 9.94732 8.14645 9.85355C8.05268 9.75979 8 9.63261 8 9.5ZM8 12C8 11.8674 8.05268 11.7402 8.14645 11.6464C8.24021 11.5527 8.36739 11.5 8.5 11.5H15.5C15.6326 11.5 15.7598 11.5527 15.8536 11.6464C15.9473 11.7402 16 11.8674 16 12C16 12.1326 15.9473 12.2598 15.8536 12.3536C15.7598 12.4473 15.6326 12.5 15.5 12.5H8.5C8.36739 12.5 8.24021 12.4473 8.14645 12.3536C8.05268 12.2598 8 12.1326 8 12ZM8 14.5C8 14.3674 8.05268 14.2402 8.14645 14.1464C8.24021 14.0527 8.36739 14 8.5 14H12.5C12.6326 14 12.7598 14.0527 12.8536 14.1464C12.9473 14.2402 13 14.3674 13 14.5C13 14.6326 12.9473 14.7598 12.8536 14.8536C12.7598 14.9473 12.6326 15 12.5 15H8.5C8.36739 15 8.24021 14.9473 8.14645 14.8536C8.05268 14.7598 8 14.6326 8 14.5Z"
        fill={isHovered ? '#E9769E' : '#191919'}
      />
      <rect
        style={{ transition: '0.2s' }}
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="7.5"
        stroke={isHovered ? '#E9769E' : '#191919'}
      />
    </svg>
  );
};
