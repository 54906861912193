import { Form, FormInstance } from 'antd';
import { SortBlock } from './SortBlock';
import { FC } from 'react';
import { useResetArrange } from '../../helpers/useResetArrange';
import { ArrangePopupWrapper } from './ArrangePopupWrapper';

type Props = {
  form: FormInstance<any>;
  onFinish: () => void;
  initialValues: any;
  isActiveArrange: boolean;
};

export const SortPopup: FC<Props> = ({
  form,
  onFinish,
  initialValues = {},
  isActiveArrange,
}) => {
  useResetArrange({ isActiveArrange, form });

  return (
    <Form form={form} onFinish={onFinish} initialValues={initialValues}>
      <ArrangePopupWrapper sort={<SortBlock />} />
    </Form>
  );
};
