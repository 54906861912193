import { ReactNode, FC } from 'react';
import { ConfigProvider } from 'antd';
import { en_validateMessages } from '../../../lib/i18n/EN/validation';
import { theme } from '../../styles/theme';

type Props = {
  children: ReactNode;
};

export const AntdConfigProvider: FC<Props> = ({ children }) => {
  return (
    <ConfigProvider
      theme={theme}
      form={{ validateMessages: en_validateMessages }}
    >
      {children}
    </ConfigProvider>
  );
};
