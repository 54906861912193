import { useTranslation } from 'react-i18next';
import { TENANT_COLUMN } from '../helpers/constants';
import { useTenantDateColumn } from './useTenantDateColumn';

const creationDate = 'shared.columns.creationDate';

export const useCreationDateColumn = () => {
  const { t } = useTranslation();


  return useTenantDateColumn({
    title: t(creationDate),
    arrangedId: TENANT_COLUMN.CREATION_DATE,
    filterFromName: 'createdDateFrom',
    filterToName: 'createdDateTo',
  });
};
