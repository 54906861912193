import { useEffect, useMemo } from 'react';
import { BrandKitThemeConfiguration } from '../../types/colors&fonts';
import {
  setBrandKit,
  setDemandAreas,
  setDesignSettings,
  setPublicModel,
  setSections,
  setTenantAndProject,
} from '../../store/slices/endUserSlice';
import { DesignSettingsDataT } from '../../../modules/designSettings/types';
import { useAppDispatch } from '../../store/hooks';
import { useSearchParams } from 'react-router-dom';
import { useGetDesignSettingsQuery } from '../../store/api/designSettings';
import { useGetBrandKitDataQuery, useGetFontsQuery } from '../../store/api/brandKit';
import { loadFonts } from '../../utils/load-fonts';
import {
  useGetPublicDemandAreasQuery,
  useGetPublicModelInfoQuery,
  useGetSectionQuery,
} from '../../store/api/endUser';
import { parseJSON } from '../../utils/helpers';
import { mapSections } from '../../utils/end-user-helpers';
import { SectionsSliceT } from '../../types/sections';
import { useFetchAllProducts } from './useFetchAllProducts';
import { useFetchStatusLevels } from './useFetchStatusLevels';

export const useGetEndUserDataForPreview = () => {
  const [params] = useSearchParams();
  const tenantId = params.get('tenantId');
  const modelId = params.get('modelId');

  const dispatch = useAppDispatch();

  const { data: designSettingsData, isLoading: isLoadingDesignSettings } = useGetDesignSettingsQuery(
    { tenantId: tenantId as string, projectId: String(modelId) },
    { skip: !tenantId },
  );

  const { data: brandKitData, isLoading: isLoadingBrandKit } = useGetBrandKitDataQuery(
    { tenantId: tenantId as string, projectId: String(modelId) },
    { skip: !tenantId || !modelId },
  );

  const { data: fontsFromServer, isLoading: isLoadingFonts } = useGetFontsQuery(
    {
      tenantId: String(tenantId),
    },
    { skip: !tenantId },
  );

  const { data: sectionsData, isLoading: isLoadingSections } = useGetSectionQuery(
    {
      tenantId: String(tenantId),
      projectId: String(modelId),
    },
    { skip: !tenantId || !modelId },
  );

  const { data: endUserData, isLoading: isLoadingEndUserData } = useGetPublicDemandAreasQuery(
    { tenantId: Number(tenantId), projectId: Number(modelId) },
    { skip: !tenantId || !modelId },
  );

  const { data: endUserModel, isLoading: isLoadingEndUserModel } = useGetPublicModelInfoQuery(
    { tenantId: Number(tenantId), projectId: Number(modelId) },
    { skip: !tenantId || !modelId },
  );

  const { isLevelsLoading } = useFetchStatusLevels();

  useFetchAllProducts();

  const parsedData = useMemo(() => {
    if (designSettingsData) {
      const designSettings = designSettingsData?.[0];
      const parsedData: DesignSettingsDataT = parseJSON(designSettings?.settings);

      return parsedData;
    }
  }, [designSettingsData]);

  const parsedDataBrandKit = useMemo(() => {
    if (brandKitData) {
      const brandKit = brandKitData?.[0];
      const brandKitParsedData: BrandKitThemeConfiguration = parseJSON(brandKit?.settings);
      return brandKitParsedData;
    }
  }, [brandKitData]);

  useEffect(() => {
    if (parsedData) {
      dispatch(setDesignSettings(parsedData));
    }
  }, [dispatch, parsedData]);

  useEffect(() => {
    if (parsedDataBrandKit) {
      dispatch(setBrandKit(parsedDataBrandKit));
    }
  }, [dispatch, parsedDataBrandKit]);

  useEffect(() => {
    if (fontsFromServer) {
      loadFonts(fontsFromServer);
    }
  }, [fontsFromServer]);

  useEffect(() => {
    if (sectionsData) {
      const sections = mapSections(sectionsData.content);
      dispatch(setSections(sections as SectionsSliceT));
    }
  }, [dispatch, sectionsData]);

  useEffect(() => {
    if (endUserData) {
      dispatch(setDemandAreas(endUserData));
    }
  }, [dispatch, endUserData]);

  useEffect(() => {
    if (endUserModel) {
      dispatch(setPublicModel(endUserModel));
    }
  }, [dispatch, endUserModel]);

  useEffect(() => {
    if (tenantId && modelId) {
      dispatch(
        setTenantAndProject({
          tenantId: Number(tenantId),
          projectId: Number(modelId),
          id: 0,
          domain: '',
          subDomain: '',
        }),
      );
    }
  }, [dispatch, tenantId, modelId]);

  return {
    isLoading:
      isLoadingDesignSettings ||
      isLoadingBrandKit ||
      isLoadingFonts ||
      isLoadingSections ||
      isLoadingEndUserData ||
      isLoadingEndUserModel ||
      isLevelsLoading,
    parsedData,
  };
};
