import React, { FC } from 'react';
import styles from '../EndUserProductCard/index.module.scss';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { IconComponent } from '../../../../components/IconComponent';
import { ProductT } from '../../../../lib/types/product';
import { IconT } from '../../../designSettings/types';
import { BrandKitThemeConfiguration, ColorVariants } from '../../../../lib/types/colors&fonts';

type Props = {
  product: ProductT;
  additionalInfo: any;
  bullet?: {
    icon: IconT;
    color: ColorVariants;
  };
  brandKit: BrandKitThemeConfiguration | null;
  isPriceShown: boolean;
  currency: string | any;
  detailsRef?: React.Ref<HTMLDivElement>;
};

export const EndUserProductDetails: FC<Props> = ({
  product,
  additionalInfo,
  bullet,
  brandKit,
  isPriceShown,
  currency,
  detailsRef,
}) => {
  const showCurrency = product.price?.amount;

  return (
    <div ref={detailsRef} className={styles.details}>
      <EndUserTypography className={styles.description} type="subText" component="Text">
        {product?.description}
      </EndUserTypography>
      <ul className={styles.bulletPoints}>
        {additionalInfo?.bulletPoints.map((bulletPoint: number, index: number) => (
          <li className={styles.bulletItem} key={`key-${index}`}>
            <div>
              {bullet ? (
                <IconComponent
                  style={{ display: 'block' }}
                  iconData={bullet?.icon}
                  color={brandKit?.colors[bullet?.color]}
                  className={styles.bulletIcon}
                />
              ) : null}
            </div>

            <EndUserTypography
              ellipsis={{ rows: 3 }}
              className={styles.bulletText}
              type="subText"
              component="Paragraph"
            >
              {bulletPoint}
            </EndUserTypography>
          </li>
        ))}
      </ul>
      {isPriceShown && (
        <EndUserTypography className={styles.price} type="headlineSixth" component="Paragraph">
          {product.price?.amount} {showCurrency && currency} {product.price?.priceDescription}
        </EndUserTypography>
      )}
    </div>
  );
};
