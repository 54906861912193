import { api, TAGS_KEY } from './index';
import { CreateDemandAreaPayload, DemandArea, GetDemandAreaPayloadT } from '../../types/demandArea';
import { GlobalSettingsItemType } from '../../types/colors&fonts';
import { ResultWithPagination } from '../../types/pagination';
import { TableSortOrder, WITHOUT_PAGINATION_ELEMENTS_SIZE } from '../../utils/constants';

export const demandAreaApi = api.injectEndpoints({
  endpoints: (build) => ({
    positionOptions: build.query<number[], { modelId: string; tenantId: string }>({
      query: ({ modelId, tenantId }) => ({
        url: `demand-area/${modelId}/get-positions`,
        method: 'GET',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      providesTags: [TAGS_KEY.DEMAND_AREA],
    }),

    colorsOptions: build.query<GlobalSettingsItemType[], { modelId: string; tenantId: string }>({
      query: ({ modelId, tenantId }) => ({
        url: `global-settings/${modelId}`,
        method: 'GET',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      providesTags: [TAGS_KEY.DESIGN_SETTINGS, TAGS_KEY.BRAND_KIT],
    }),

    getDemandAreas: build.query<ResultWithPagination<DemandArea[]>, GetDemandAreaPayloadT>({
      query: ({ page, sortFieldName, sortOrder, tenantId, modelId }) => ({
        url: `demand-area/${modelId}/get-demand-areas?size=10&page=${page || 0}${
          sortFieldName && sortOrder && sortOrder !== TableSortOrder.NONE
            ? `&sort=${sortFieldName},${sortOrder}`
            : ''
        }`,
        method: 'POST',
        headers: {
          'X-TENANT': String(tenantId),
        },
        body: {},
      }),
      providesTags: [TAGS_KEY.DEMAND_AREA],
    }),

    createDemandArea: build.mutation<void, CreateDemandAreaPayload>({
      query: (body) => ({
        url: 'demand-area',
        method: 'POST',
        headers: {
          'X-TENANT': String(body.tenantId),
        },
        body: body,
      }),
      invalidatesTags: [TAGS_KEY.DEMAND_AREA],
    }),

    updateDemandArea: build.mutation<void, CreateDemandAreaPayload>({
      query: (body) => ({
        url: 'demand-area',
        method: 'PUT',
        headers: {
          'X-TENANT': String(body.tenantId),
        },
        body: body,
      }),
      invalidatesTags: [TAGS_KEY.DEMAND_AREA],
    }),
    deleteDemandArea: build.mutation<void, { tenantId: string; demandAreaId: number }>({
      query: ({ tenantId, demandAreaId }) => ({
        url: `demand-area/${demandAreaId}/delete`,
        method: 'DELETE',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      invalidatesTags: [TAGS_KEY.DEMAND_AREA],
    }),
    deactivateDemandAreaStatus: build.mutation<void, { tenantId: string; demandAreaId: number }>({
      query: ({ tenantId, demandAreaId }) => ({
        url: `demand-area/${demandAreaId}/deactivate`,
        method: 'PATCH',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      invalidatesTags: [TAGS_KEY.DEMAND_AREA],
    }),

    getDemandAreasForFiltering: build.query<
      ResultWithPagination<DemandArea[]>,
      { searchFieldName: string; search: string; tenantId: string; projectId: string }
    >({
      query: ({ searchFieldName, search, tenantId, projectId }) => ({
        url: `demand-area/${projectId}/get-demand-areas?size=${WITHOUT_PAGINATION_ELEMENTS_SIZE}&page=0`,
        method: 'POST',
        headers: {
          'X-TENANT': String(tenantId),
        },
        body: {
          [searchFieldName]: search,
        },
      }),
      providesTags: [TAGS_KEY.DEMAND_AREA],
    }),
  }),
});

export const {
  usePositionOptionsQuery,
  useCreateDemandAreaMutation,
  useColorsOptionsQuery,
  useUpdateDemandAreaMutation,
  useGetDemandAreasQuery,
  useDeleteDemandAreaMutation,
  useDeactivateDemandAreaStatusMutation,
  useLazyGetDemandAreasForFilteringQuery,
} = demandAreaApi;
