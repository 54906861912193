import React from 'react';
import styles from './index.module.scss';
import './ant-design-styles.scss';
import { ConfigProvider, Menu } from 'antd';
import { useTenantMenuOptions } from '../hooks/useTenantMenuOptions';

const theme = {
  token: {
    colorPrimary: '#5C5C6E',
    borderRadiusLG: 0,
  },
};
export const PlatformUserSidebar = () => {
  const { isTenantRoutesAvailable, items, selectedItems } = useTenantMenuOptions();
  return isTenantRoutesAvailable ? (
    <ConfigProvider theme={theme}>
      <aside className={styles.sidebar}>
        <Menu
          mode="inline"
          theme="dark"
          selectedKeys={selectedItems}
          className={styles.sidebar_menu}
          items={items}
        />
      </aside>
    </ConfigProvider>
  ) : null;
};
