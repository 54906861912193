import { ProjectSearchDataHeaderCell } from '../components/ProjectSearchDataHeaderCell';

export const useProjectSearchDataColumn = (props: any) => {
  const { columnProps, ...rest } = props;

  return {
    ...columnProps,
    dataIndex: props.arrangedId,
    title: <ProjectSearchDataHeaderCell {...rest} />,
  };
};
