import { BonusCatalogTable } from '../BonusCatalogTable';
import { CreateBonusCatalogModal } from '../Modals/CreateBonusCatalogModal';
import { DeleteBonusCatalogModal } from '../Modals/DeleteBonusCatalogModal';
import { EditBonusCatalogModal } from '../Modals/EditBonusCatalogModal';
import styles from './index.module.scss';

type Props = {
  tenantId: string;
  modelId: string;
};

export const BonusCatalog = ({ modelId, tenantId }: Props) => {
  return (
    <div className={styles.root}>
      <BonusCatalogTable modelId={modelId} tenantId={tenantId} />
      <CreateBonusCatalogModal tenantId={tenantId} modelId={modelId} />
      <EditBonusCatalogModal tenantId={tenantId} modelId={modelId} />
      <DeleteBonusCatalogModal tenantId={tenantId} />
    </div>
  );
};
