import { FC } from 'react';
import { FormItem } from '../../../../components/FormItem';
import { FormItemName } from '../../../../lib/models/Form';
import { FormInput } from '../../../../components/FormInput';
import { Form, FormInstance } from 'antd';
import { EMAIL_PATTERN, PHONE_NUMBER_PATTERN } from '../../../../lib/utils/regexp';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';

type Props = {
  form: FormInstance;
  onFinish: (arg: any) => Promise<void>;
};

const contactForm = 'endUser.contactForm';

export const EndUserContactForm: FC<Props> = ({ form, onFinish }) => {
  const { t } = useTranslation();

  return (
    <Form form={form} onFinish={onFinish} className={styles.form}>
      <div className={styles.formInputsRow}>
        <FormItem
          name={FormItemName.FIRST_NAME}
          hasFeedback
          rules={[
            {
              required: true,
              message: t(`${contactForm}.firstName.error`),
            },
            { max: 100 },
          ]}
        >
          <FormInput label={t(`${contactForm}.firstName.label`)} />
        </FormItem>

        <FormItem
          name={FormItemName.LAST_NAME}
          hasFeedback
          rules={[
            {
              required: true,
              message: t(`${contactForm}.lastName.error`),
            },
            { max: 100 },
          ]}
        >
          <FormInput label={t(`${contactForm}.lastName.label`)} />
        </FormItem>
      </div>
      <div className={styles.formInputsRow}>
        <FormItem
          name={FormItemName.EMAIL}
          hasFeedback
          rules={[
            { required: true, message: t(`${contactForm}.email.errorEmpty`) },
            {
              pattern: EMAIL_PATTERN,
              message: t(`${contactForm}.email.errorValid`),
            },
          ]}
        >
          <FormInput label={t(`${contactForm}.email.label`)} />
        </FormItem>

        <FormItem
          name={FormItemName.PHONE_NUMBER}
          hasFeedback
          rules={[
            { required: true, message: t(`${contactForm}.phone.errorEmpty`) },

            {
              pattern: PHONE_NUMBER_PATTERN,
              message: t(`${contactForm}.phone.errorEmpty`),
            },
          ]}
        >
          <FormInput label={t(`${contactForm}.phone.label`)} maxLength={12} />
        </FormItem>
      </div>
    </Form>
  );
};
