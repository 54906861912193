import { FC } from 'react';
import { Card, Divider, FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemTitle } from '../FormItemTitle';
import { FormSection } from '../../../../components/FormSection';
import { FormItemName } from '../../../../lib/models/Form';
import { BulletPointFormList } from '../BulletPointsFormList';
import { PictureFormItem } from '../PictureFormItem';

import styles from './index.module.scss';

const formItems = 'pages.products.productConfigurator.formItems';

type Props = {
  form: FormInstance;
};

export const DetailedInfoCard: FC<Props> = ({ form }) => {
  const { t } = useTranslation();

  return (
    <Card className={styles.root}>
      <div className={styles.section}>
        <FormItemTitle>{t(`${formItems}.name.title`)}</FormItemTitle>
        <FormSection
          formItemProps={{
            hasFeedback: true,
            name: FormItemName.NAME,
            rules: [{ required: true, message: t(`${formItems}.name.errors.empty`) }],
          }}
          textFieldProps={{
            placeholder: t(`${formItems}.name.label`),
          }}
        />

        <FormItemTitle>{t(`${formItems}.subline.title`)}</FormItemTitle>
        <FormSection
          formItemProps={{
            hasFeedback: true,
            name: FormItemName.SUBLINE,
            rules: [{ max: 100, type: 'string', message: t(`${formItems}.subline.error`) }],
          }}
          textFieldProps={{
            placeholder: t(`${formItems}.subline.label`),
          }}
        />

        <FormItemTitle>{t(`${formItems}.description.title`)}</FormItemTitle>
        <FormSection
          formItemProps={{
            hasFeedback: true,
            name: FormItemName.DESCRIPTION,
            rules: [{ max: 175, type: 'string', message: t(`${formItems}.description.error`) }],
          }}
          textFieldProps={{
            placeholder: t(`${formItems}.description.label`),
          }}
        />
      </div>

      <Divider className={styles.divider} />
      <div className={styles.section}>
        <FormItemTitle>{t(`${formItems}.bullets.title`)}</FormItemTitle>
        <BulletPointFormList form={form} />
      </div>

      <Divider className={styles.divider} />

      <div className={styles.section}>
        <FormItemTitle>{t(`${formItems}.picture.title`)}</FormItemTitle>
        <PictureFormItem form={form} />
      </div>
    </Card>
  );
};
