import { useTranslation } from 'react-i18next';

/*
   Here we have two different types of rules because user can set up simple password (like 6 chars),
   but in the future we could add new validation (like at least 10 chars).
   So if we don't have two types of rules, user cannot log in.
 */

export const usePasswordValidation = () => {
  const { t } = useTranslation();

  const settingPasswordRules = [
    {
      pattern: /.{10,}/,
      message: t('pages.setUpPassword.errors.length'),
    },
    {
      pattern: /[A-Z]/,
      message: t('pages.setUpPassword.errors.uppercase'),
    },
    {
      pattern: /[a-z]/,
      message: t('pages.setUpPassword.errors.lowercase'),
    },
    {
      pattern: /\d/,
      message: t('pages.setUpPassword.errors.number'),
    },
    {
      pattern: /[!#$%&()*+@^_]/,
      message: t('pages.setUpPassword.errors.special'),
    },
  ];

  return { settingPasswordRules };
};
