import { FC } from 'react';
import { AddTenantModal } from './AddTenantModal';
import { EditTenantModal } from './EditTenantModal';
import { DeleteTenantModal } from './DeleteTenantModal';
import { useAppSelector, useAppDispatch } from '../../../../lib/store/hooks';
import { ModalActions } from '../../../../lib/models/Modal';
import { closeTenantModal } from '../../../../lib/store/slices/tenantsSlice';

export const TenantsModals: FC = () => {
  const {
    isModalOpen,
    modalType: currentModalType,
    selectedTenant,
  } = useAppSelector(({ tenants }) => tenants);
  const dispatch = useAppDispatch();

  const close = () => dispatch(closeTenantModal());
  const checkIsOpen = (modalType: ModalActions | null) => isModalOpen && modalType === currentModalType;

  const genericProps = { tenantData: selectedTenant, close };

  return (
    <>
      <AddTenantModal {...genericProps} isOpen={checkIsOpen(ModalActions.ADD)} />
      <EditTenantModal {...genericProps} isOpen={checkIsOpen(ModalActions.EDIT)} />
      <DeleteTenantModal {...genericProps} isOpen={checkIsOpen(ModalActions.DELETE)} />
    </>
  );
};
