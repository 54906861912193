import { useAppSelector } from '../../../../lib/store/hooks';
import { SectionName } from '../../../../lib/models/Section';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import { NewHouseModelDesktopWrapper } from '../NewHouseModelDesktopWrapper';
import { NewHouseModelMobileWrapper } from '../NewHouseModelMobileWrapper';

export const NewHouseModel = () => {
  const { sections, demandArea } = useAppSelector(({ endUser }) => endUser);
  const currentSectionContent = sections?.[SectionName.HOUSE_MODEL];

  const { isMobile } = useContainerQuery();

  const slicedDemandAreas = demandArea?.listDemandArea.slice(0, 4) || [];

  return (
    <>
      {isMobile ? (
        <NewHouseModelMobileWrapper demandAreas={slicedDemandAreas} content={currentSectionContent} />
      ) : (
        <NewHouseModelDesktopWrapper demandAreas={slicedDemandAreas} content={currentSectionContent} />
      )}
    </>
  );
};
