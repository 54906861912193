import React, { FC } from 'react';
import { AppCardBasic } from '../../../../components/AppCardBasic';
import { FormItem } from '../../../../components/FormItem';
import { FormItemName } from '../../../../lib/models/Form';
import { FormInput } from '../../../../components/FormInput';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

import styles from '../../styles.module.scss';

const statusLevelTitle = 'pages.statusLevel.form.formItems.statusLevelTitle';
const statusLevel = 'pages.statusLevel.form.labels.statusLevel';

const { Title } = Typography;

export const StatusLevelTitleCard: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.titleContainer}>
      <Title className={styles.title}>{t(statusLevel)}</Title>
      <AppCardBasic>
        <FormItem
          name={FormItemName.STATUS_LEVEL_TITLE}
          hasFeedback={true}
          rules={[{ required: true }, { max: 100 }]}
        >
          <FormInput label={t(statusLevelTitle)} />
        </FormItem>
      </AppCardBasic>
    </div>
  );
};
