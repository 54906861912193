import React, { Dispatch, SetStateAction, useMemo } from 'react';
import styles from '../../components/BrandKit/index.module.scss';
import { AppCardBasic } from '../../../../components/AppCardBasic';
import { useTranslation } from 'react-i18next';
import { Form, Typography } from 'antd';
import { AdditionalChooseColors, FontStyleKeys } from '../../util/constants';
import { BrandKitThemeConfiguration } from '../../../../lib/types/colors&fonts';
import { FontFamilyFormItem } from '../../../../features/form-items/FontFamilyFormItem';
import { FontWeightFormItem } from '../../../../features/form-items/FontWeightFormItem';
import { FontSizeFormItem } from '../../../../features/form-items/FontSizeFormItem';
import { FontColorFormItem } from '../../../../features/form-items/FontColorFormItem';
import { FontLoader } from '../FontLoader';
import { useGetFontsQuery } from '../../../../lib/store/api/brandKit';

const { Text } = Typography;

const formItems = 'pages.designSettings.formItems';

type Props = {
  tenantId: string | number;
  themeConfiguration: BrandKitThemeConfiguration;
  setThemeConfiguration: Dispatch<SetStateAction<BrandKitThemeConfiguration>>;
};

export const BrandKitFonts = ({ themeConfiguration, setThemeConfiguration, tenantId }: Props) => {
  const { t } = useTranslation();

  const fonts = themeConfiguration.fonts;

  const { data: fontsFromServer } = useGetFontsQuery({ tenantId: tenantId as string }, { skip: !tenantId });

  const onChangeField = (value: string, fieldName?: string, field?: string) => {
    if (value) {
      setThemeConfiguration({
        ...themeConfiguration,
        fonts: {
          ...fonts,
          [fieldName as string]: { ...fonts[fieldName as keyof typeof fonts], [field as string]: value },
        },
      });
    }
  };

  const fontForms = FontStyleKeys.map((fieldName: string) => {
    return {
      label: `${formItems}.${fieldName}`,
      fieldName,
      font: fonts[fieldName as keyof typeof fonts].font,
      fontWeight: fonts[fieldName as keyof typeof fonts].fontWeight,
      fontSize: fonts[fieldName as keyof typeof fonts].fontSize,
      colorVariant: fonts[fieldName as keyof typeof fonts].colorVariant,
    };
  });

  const colorOptionsWithAdditionalColors = useMemo(() => {
    const usedAdditionalColorsInUserColors = new Set<string>(Object.values(themeConfiguration.colors));

    return {
      ...themeConfiguration.colors,
      ...Object.fromEntries(
        Object.entries(AdditionalChooseColors).filter(
          ([key, value]) => !usedAdditionalColorsInUserColors.has(value),
        ),
      ),
    };
  }, [themeConfiguration.colors]);

  return (
    <AppCardBasic>
      <>
        <Text className={styles.title}>{t('shared.fonts.fontsTitle')}</Text>
        <div className={styles.fontsWrapper}>
          {fontForms.map(({ fontSize, fontWeight, font, colorVariant, fieldName, label }) => {
            return (
              <Form.Item key={label} style={{ marginBottom: 0, fontWeight: 400 }}>
                <div className={styles.fontLabel}>{t(label)}</div>

                <div className={styles.fontItem}>
                  <FontFamilyFormItem
                    tenantId={tenantId}
                    value={font}
                    onChange={onChangeField}
                    fieldName={fieldName}
                    field="font"
                  />
                  <FontWeightFormItem
                    value={
                      fontsFromServer?.map((fontObj) => fontObj.fontName).includes(font)
                        ? undefined
                        : fontWeight
                    }
                    onChange={onChangeField}
                    fieldName={fieldName}
                    field="fontWeight"
                    disabled={fontsFromServer?.map((fontObj) => fontObj.fontName).includes(font)}
                  />
                  <FontSizeFormItem
                    value={fontSize}
                    onChange={onChangeField}
                    fieldName={fieldName}
                    field="fontSize"
                  />

                  <FontColorFormItem
                    value={colorVariant}
                    onChange={onChangeField}
                    fieldName={fieldName}
                    field="colorVariant"
                    colors={colorOptionsWithAdditionalColors}
                  />
                </div>
              </Form.Item>
            );
          })}
        </div>
        <hr className={styles.hr} />
        <FontLoader tenantId={tenantId} />
      </>
    </AppCardBasic>
  );
};
