import { FormItem } from '../../../../../components/FormItem';
import { AppSelect } from '../../../../../components/AppSelect';
import { useTranslation } from 'react-i18next';
import styles from '../../DemandAreas/index.module.scss';
import { useColorOptions } from '../../../../../lib/hooks/useColorOptions';
import { DEMAND_AREA_FORMITEMS } from '../../../helpers';

const colorTitle = 'pages.products.demandArea.formItems.color';

type Props = {
  tenantId: string;
  modelId: string;
};

export const ColorFormItem = (props: Props) => {
  const { t } = useTranslation();

  const { colorOptions, isLoading } = useColorOptions(props);

  return (
    <div>
      <h3 className={styles.subtitle}>{t(colorTitle)}</h3>
      <FormItem rules={[{ required: true }]} name={DEMAND_AREA_FORMITEMS.COLOR}>
        <AppSelect isLoading={isLoading} options={colorOptions} />
      </FormItem>
    </div>
  );
};
