import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalActions } from '../../models/Modal';
import { TableSortOrder } from '../../utils/constants';
import { Filter, FiltersPayload, Sort } from '../../types/filteringAndSort';
import { PROJECT_COLUMN } from '../../../modules/projects/helpers/constants';
import { ProjectT } from '../../types/entities';

type ProjectsSliceStateT = {
  page: number;
  arrangeId: PROJECT_COLUMN | null;
  sorting: Sort;
  filtering: Filter;

  modalType: ModalActions | null;
  isModalOpen: boolean;
  selectedProject: ProjectT | null;
};

const initialState: ProjectsSliceStateT = {
  page: 1,
  arrangeId: null,
  sorting: {
    sortOrder: TableSortOrder.NONE,
    sortFieldName: null,
    withSorting: true,
  },
  filtering: {
    filterFieldName: null,
    filter: [],
  },
  isModalOpen: false,
  modalType: null,
  selectedProject: null,
};

export type ProjectsFiltersPayload = FiltersPayload & {
  arrangeId: PROJECT_COLUMN;
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setModalOpened: (state, action: PayloadAction<ModalActions>) => {
      state.modalType = action.payload;
      state.isModalOpen = true;
    },
    setModalClosed: (state) => {
      state.selectedProject = null;
      state.modalType = null;
      state.isModalOpen = false;
    },
    setCurrentProject: (state, action: PayloadAction<ProjectT>) => {
      state.selectedProject = action.payload;
    },
    setFilters: (state, action: PayloadAction<ProjectsFiltersPayload>) => {
      state.arrangeId = action.payload.arrangeId;
      state.sorting = action.payload.sorting;
      state.filtering = action.payload.filtering;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    resetFields: (state) => {
      state.arrangeId = null;
      state.sorting = {
        sortOrder: TableSortOrder.NONE,
        sortFieldName: null,
        withSorting: true,
      };
      state.filtering = {
        filterFieldName: null,
        filter: [],
      };
    },
  },
});

export const { setFilters, setPage, setCurrentProject, setModalClosed, setModalOpened, resetFields } =
  projectsSlice.actions;
export const projects = projectsSlice.reducer;
