import { api, TAGS_KEY } from './index';
import { ResultWithPagination } from '../../types/pagination';
import {
  CreateStatusLevelPayloadT,
  GetStatusLevelPayloadT,
  StatusLevelT,
  UpdateStatusLevelPayloadT,
} from '../../types/statusLevel';
import { OptionT } from '../../types/common';
import { isLength } from '../../utils/helpers';
import { WITHOUT_PAGINATION_ELEMENTS_SIZE } from '../../utils/constants';

export const statusLevelApi = api.injectEndpoints({
  endpoints: (build) => ({
    getStatusLevels: build.query<ResultWithPagination<StatusLevelT[]>, GetStatusLevelPayloadT>({
      query: ({ page, projectId, tenantId }) => {
        const url = `status-level/${projectId}/get-status-levels?size=10&page=${page || 0}`;

        return {
          url: url,
          method: 'POST',
          body: {},
          headers: {
            'X-TENANT': tenantId,
          },
        };
      },
      providesTags: [TAGS_KEY.STATUS_LEVEL],
    }),
    getAllStatusLevels: build.query<OptionT[], GetStatusLevelPayloadT>({
      query: ({ projectId, tenantId }) => ({
        url: `status-level/${projectId}/get-status-levels?size=${WITHOUT_PAGINATION_ELEMENTS_SIZE}&page=0`,
        method: 'POST',
        body: {},
        headers: {
          'X-TENANT': tenantId,
        },
      }),
      transformResponse: (response: ResultWithPagination<StatusLevelT[]>) => {
        return isLength(response?.content)
          ? response?.content.map((item) => ({
              value: item.id,
              title: item.name,
            }))
          : [];
      },
      providesTags: [TAGS_KEY.STATUS_LEVEL],
    }),
    createStatusLevel: build.mutation<unknown, CreateStatusLevelPayloadT>({
      query: ({ body, tenantId }) => {
        return {
          url: 'status-level',
          method: 'POST',
          body,
          headers: {
            'X-TENANT': tenantId,
          },
        };
      },
      invalidatesTags: [TAGS_KEY.STATUS_LEVEL],
    }),
    updateStatusLevel: build.mutation<unknown, UpdateStatusLevelPayloadT>({
      query: ({ body, tenantId }) => ({
        url: 'status-level',
        method: 'PUT',
        body,
        headers: {
          'X-TENANT': tenantId,
        },
      }),
      invalidatesTags: [TAGS_KEY.STATUS_LEVEL],
    }),
    deleteStatusLevel: build.mutation<unknown, { statusLevelId: number; tenantId: string }>({
      query: ({ statusLevelId, tenantId }) => ({
        url: `status-level/${statusLevelId}/delete`,
        method: 'DELETE',
        body: {},
        headers: {
          'X-TENANT': tenantId,
        },
      }),
      invalidatesTags: [TAGS_KEY.STATUS_LEVEL],
    }),
  }),
});

export const {
  useGetStatusLevelsQuery,
  useCreateStatusLevelMutation,
  useUpdateStatusLevelMutation,
  useDeleteStatusLevelMutation,
  useGetAllStatusLevelsQuery,
} = statusLevelApi;
