import { FC } from 'react';
import { useAppSelector } from '../../../../lib/store/hooks';
import { useParams } from 'react-router-dom';
import { Form } from 'antd';
import {
  formatArrangedData,
  getArrangesInitialValues,
  getParsedSelectedItems,
  getSelectedItemsLabels,
} from '../../../../lib/utils/arranges-helpers';
import { useStringArrange } from '../../../../features/table/helpers/useStringArrange';
import { TableHeaderCell } from '../../../../components/Table/TableHeaderCell/TableHeaderCell';
import { ArrangeListPopup } from '../../../../features/table/components/popups/ArrangeListPopup';
import { useLazyGetProductCategoriesForFilteringQuery } from '../../../../lib/store/api/productCategories';
import { useProductCategoriesArrangeRequest } from '../../hooks/useProductCategoriesArrangeRequest';
import { useLazyGetDemandAreasForFilteringQuery } from '../../../../lib/store/api/demandArea';
import { PRODUCTS_COLUMNS } from '../../../products/helpers/consts';

type Props = {
  arrangedId: string;
  title: string;
  searchFieldName: string;
  filterFieldName: string;
  // TODO check if needed
  headerCellProps: any;
  isParsedByValue?: boolean;
};

export const ProductCategoriesSearchDataHeaderCell: FC<Props> = ({
  arrangedId,
  title,
  searchFieldName,
  filterFieldName,
  headerCellProps: additionalHeaderCellProps,
  isParsedByValue,
}) => {
  const sortingAndFiltersData = useAppSelector(({ productCategories }) => productCategories);

  const {
    arrangeId,
    sorting,
    filtering: { filter },
  } = sortingAndFiltersData;

  const [findProductCategories] = useLazyGetProductCategoriesForFilteringQuery();
  const [findDemandAreas] = useLazyGetDemandAreasForFilteringQuery();

  const { id: tenantId, modelId } = useParams();

  const isActiveArrange = arrangeId === arrangedId;

  const [form] = Form.useForm();
  const getSelectedItems = () => getSelectedItemsLabels(form);

  const { filtering } = useStringArrange({
    form,
    request: (value: string) => {
      if (arrangedId === PRODUCTS_COLUMNS.DEMAND_AREA) {
        return findDemandAreas({
          search: value,
          searchFieldName: 'searchByName',
          tenantId: String(tenantId),
          projectId: String(modelId),
        }).unwrap();
      }

      return findProductCategories({
        search: value,
        searchFieldName,
        tenantId: String(tenantId),
        projectId: String(modelId),
      }).unwrap();
    },
    formatResponse: (data: { content: any[] }) => {
      if (arrangedId === PRODUCTS_COLUMNS.DEMAND_AREA) {
        return data?.content.map((item) => ({
          label: item.name,
          value: JSON.stringify({
            value: item.id,
            label: item.name,
          }),
        }));
      }

      return formatArrangedData(data, arrangedId);
    },
  });

  const { applyArranges, removeFilter, removeFilterValue } = useProductCategoriesArrangeRequest({
    arrangedId,
    getFilterLabel: () => getSelectedItems().join(', '),
    getFilters: () => ({ [filterFieldName]: getSelectedItems() }),
    form,
    filterFieldName,
    isParsedByValue,
  });

  const headerCellProps = {
    arrangedId,
    title,
    removeFilter,
    filterLabel: Array.isArray(filter) && filter.join(', '),
    sortOrder: sorting.sortOrder,
    isActiveArrange,
    form,
    applyArranges,
    getParsedItems: () => getParsedSelectedItems(form),
    removeFilterValue,
    ...additionalHeaderCellProps,
  };

  return (
    <TableHeaderCell {...headerCellProps}>
      <ArrangeListPopup
        initialValues={getArrangesInitialValues(form)}
        form={form}
        filtering={filtering}
        onFinish={applyArranges}
        isActiveArrange={isActiveArrange}
      />
    </TableHeaderCell>
  );
};
