import { useTranslation } from 'react-i18next';
import { STATUS_LEVEL_COLUMN } from '../helpers/consts';
import { TableHeaderCell } from '../../../components/Table/TableHeaderCell/TableHeaderCell';

const statusLevel = 'pages.statusLevel.columns.statusLevel';

export const useStatusLevelNameColumn = () => {
  const { t } = useTranslation();

  return {
    title: <TableHeaderCell title={t(statusLevel)} withArrange={false} />,
    dataIndex: STATUS_LEVEL_COLUMN.NAME,
    width: 238,
  };
};
