import { AppCardBasic } from '../../../../components/AppCardBasic';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from 'antd';
import { Dispatch } from 'react';
import { ColorPickerFormItem } from '../../../../features/form-items/ColorPickerFormItem';
import colorStyles from '../../../../features/form-items/ColorPickerFormItem/index.module.scss';
import styles from './index.module.scss';
import cx from 'classnames';
import { FormCardButton } from '../FormCardButton';
import { InfoIcon } from '../../../../assets/icons/InfoIcon';
import { Link, useParams } from 'react-router-dom';
import { ROUTER_PATHS } from '../../../../lib/utils/router-paths';
import { AdditionalColorVariants, DesignSettingsDataT } from '../../types';

const { Text } = Typography;

const addColor = 'pages.designSettingsTab.addColor';
const colorTooltip = 'pages.designSettingsTab.colorTooltip';
const colorSubTitle = 'pages.designSettingsTab.colorSubTitle';
const productTable = 'pages.designSettingsTab.productTable';

const colors = 'shared.colors';

type Props = {
  designSettings: DesignSettingsDataT;
  setDesignSettings: Dispatch<DesignSettingsDataT>;
};

export const ColorsCard = ({ designSettings, setDesignSettings }: Props) => {
  const { t } = useTranslation();
  const { id: tenantId, modelId } = useParams();

  const { additionalColors } = designSettings;

  const onChangeField = (fieldName: string, value: string) => {
    setDesignSettings({
      ...designSettings,
      additionalColors: { ...additionalColors, [fieldName]: value },
    });
  };

  const colorPickers = [
    {
      label: t(`${colors}.color`, { index: 1 }),
      fieldName: AdditionalColorVariants.FIRST,
      color: additionalColors.first,
    },
    {
      label: t(`${colors}.color`, { index: 2 }),
      fieldName: AdditionalColorVariants.SECOND,
      color: additionalColors.second,
    },
    {
      label: t(`${colors}.color`, { index: 3 }),
      fieldName: AdditionalColorVariants.THIRD,
      color: additionalColors.third,
    },
    {
      label: t(`${colors}.color`, { index: 4 }),
      fieldName: AdditionalColorVariants.FOURTH,
      color: additionalColors.fourth,
    },
    {
      label: t(`${colors}.color`, { index: 5 }),
      fieldName: AdditionalColorVariants.FIFTH,
      color: additionalColors.fifth,
    },
  ];

  return (
    <AppCardBasic
      className={styles.root}
      title={
        <>
          <div className={styles.titleWrapper}>
            <Text className={styles.title}>{t(`${colors}.additionalColors`)}</Text>
            <Tooltip
              title={t(colorTooltip)}
              overlayInnerStyle={{
                width: 400,
              }}
            >
              <InfoIcon className={styles.tooltipIcon} />
            </Tooltip>
          </div>
          <Text className={styles.text} type="secondary">
            {t(colorSubTitle)}{' '}
            <Link
              to={`/tenants/${tenantId}/models/${modelId}/${ROUTER_PATHS.products}`}
              className={styles.link}
            >
              {t(productTable)}
            </Link>
          </Text>
          <div className={cx(styles.colorPicker, colorStyles.color_picker)}>
            {colorPickers.map(({ color, fieldName, label }) => {
              return (
                <ColorPickerFormItem
                  setColor={onChangeField}
                  key={fieldName}
                  color={color}
                  fieldName={fieldName}
                  label={label}
                  className={styles.formItem}
                />
              );
            })}
          </div>
        </>
      }
    >
      <FormCardButton className={styles.button} disabled>
        + {t(addColor)}
      </FormCardButton>
    </AppCardBasic>
  );
};
