import { FC, MouseEvent } from 'react';
import { components, PlaceholderProps } from 'react-select';
import { SelectPropsT } from '../../types';
import { ListElementValue } from '../ListElementValue';

type Props = {
  selectProps: SelectPropsT;
} & PlaceholderProps<any>;

export const Placeholder: FC<Props> = (props) => {
  const { selectProps } = props;

  const onClickRemoveList = (e: MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
    selectProps?.listProps?.onSelectList?.(false);
  };

  return (
    <>
      {selectProps?.listProps?.isListEnabled ? (
        <ListElementValue onClickRemove={onClickRemoveList} />
      ) : (
        <components.Placeholder {...props} />
      )}
    </>
  );
};
