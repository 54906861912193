import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Status, StatusType } from '../../../../features/table/components/Status';

const STATUSES = {
  ACTIVATED: true,
  DEACTIVATED: false,
};

type Props = {
  status: boolean;
};

const activated = 'shared.status.activated';
const deactivated = 'shared.status.deactivated';

export const ProductCategoriesStatusBodyCell: FC<Props> = ({ status }) => {
  const { t } = useTranslation();

  switch (status) {
    case STATUSES.ACTIVATED:
      return <Status textValue={t(activated)} type={StatusType.GREEN} />;
    case STATUSES.DEACTIVATED:
      return <Status textValue={t(deactivated)} type={StatusType.GRAY} />;

    default:
      return <span></span>;
  }
};
