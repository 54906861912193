import { UploadResponseT } from './entities';
import { PRODUCT_CATEGORIES } from '../../modules/productCategories/helpers/constants';
import { TableSortOrder } from '../utils/constants';

export type BonusCatalog = {
  id: number;
  name: string;
  projectId: string;
  picture: UploadResponseT | null;
  title: string;
  description: string;
  payoutOption: PayoutOption;
  payoutType: PayoutType;
  activated?: boolean;
};

export type GetBonusCatalogPayloadT = {
  page?: number;
  size?: number;
  sort?: string;
  includeIds?: number[];
  tenantId?: string;
  projectId?: string;
  arrangedId?: BONUS_CATALOG_COLUMN;
  sortOrder?: TableSortOrder;
  sortFieldName?: string;
  withSorting?: boolean;
  filterFieldName?: string;
  filter?: string[] | string;
};

export type CreateBonusCatalogPayload = BonusCatalog & {
  tenantId: string;
};

export enum BONUS_CATALOG_COLUMN {
  PICTURE = 'picture',
  BONUS_CATEGORY = 'name',
  BONUS_DESCRIPTION = 'description',
  BONUS_TITLE = 'title',
  COLOR = 'color',
  IS_ACTIVE = 'activated',
}

export enum PayoutType {
  PAYOUT = 'PAYOUT',
  DONATION = 'DONATION',
  PAYOUT_AND_DONATION = 'PAYOUT_AND_DONATION',
}

export enum PayoutOption {
  ONE_TIME = 'ONE_TIME',
  CYCLE = 'CYCLE',
}
