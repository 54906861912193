import { FC, useEffect } from 'react';
import { Table } from '../../../../components/Table';
import { useStatusLevelNameColumn } from '../../hooks/useStatusLevelNameColumn';
import { useStatusLevelSubLevelsColumn } from '../../hooks/useStatusLevelSubLevelsColumn';
import { useStatusLevelPointsColumn } from '../../hooks/useStatusLevelPointsColumn';
import { useStatusLevelBonusesColumn } from '../../hooks/useStatusLevelBonusesColumn';
import { useStatusLevelArrowButtonColumn } from '../../hooks/useStatusLevelArrowButtonColumn';
import { useParams } from 'react-router-dom';
import { useGetStatusLevelsQuery } from '../../../../lib/store/api/statusLevel';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { setCurrentStatusLevel, setPage } from '../../../../lib/store/slices/statusLevelSlice';
import { normalizePageForRequest, normalizePageForTable } from '../../../../lib/utils/helpers';
import { GetStatusLevelPayloadT } from '../../../../lib/types/statusLevel';
import { TablePagination } from '../../../../lib/types/pagination';

export const StatusLevelTable: FC = () => {
  const { id: tenantId, modelId } = useParams();
  const page = useAppSelector(({ statusLevel }) => statusLevel.page);
  const dispatch = useAppDispatch();

  const filters = useAppSelector(({ statusLevel }) => {
    const arrangedId = statusLevel.arrangeId;
    return { arrangedId, ...statusLevel.sorting };
  });

  const { data, isLoading, isFetching } = useGetStatusLevelsQuery(
    {
      tenantId: String(tenantId),
      projectId: modelId,
      page: normalizePageForRequest(page || 1),
      ...filters,
    } as GetStatusLevelPayloadT,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const responsePagination: TablePagination = {
    pageSize: data?.pageable.pageSize,
    current: normalizePageForTable(data?.pageable?.pageNumber || 0),
    total: data?.totalElements,
  };

  useEffect(() => {
    dispatch(setCurrentStatusLevel(null));
  }, [dispatch]);

  const dataSource = data?.content || [];

  const columns = [
    useStatusLevelNameColumn(),
    useStatusLevelSubLevelsColumn(),
    useStatusLevelPointsColumn(),
    useStatusLevelBonusesColumn(),
    useStatusLevelArrowButtonColumn(),
  ];

  useEffect(() => {
    if (data?.numberOfElements === 0 && page !== 1) {
      dispatch(setPage(page - 1));
    }
    //eslint-disable-next-line
  }, [data?.numberOfElements]);

  return (
    <Table
      columns={columns}
      rowKey="id"
      dataSource={dataSource}
      pagination={responsePagination}
      style={{ marginTop: 5 }}
      loading={isLoading || isFetching}
      scroll={{ x: 'max-content' }}
      onChange={(tablePagination) => {
        dispatch(setPage(tablePagination.current as number));
      }}
    />
  );
};
