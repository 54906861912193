import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../lib/store/hooks';
import { Form } from 'antd';
import { PRODUCT_STATUS, PRODUCTS_COLUMNS } from '../../../products/helpers/consts';
import {
  createOptionItem,
  getParsedSelectedItems,
  getSelectedItemsLabels,
  getSelectedItemsValues,
} from '../../../../lib/utils/arranges-helpers';
import { useState } from 'react';
import { useEnumArrange } from '../../../tenants/hooks/useEnumArrange';
import { useStatusColumn } from '../../../../features/table/helpers/useStatusColumn';
import { ActivatedStatusBodyCell } from '../../../../features/table/components/ActivatedStatusBodyCell';
import { BONUS_CATALOG_COLUMN } from '../../../../lib/types/bonusCatalog';
import { useBonusCatalogArrangeRequest } from './useBonusCatalogArrangeRequest';

const activated = 'pages.products.products.filtering.activated';
const deactivated = 'pages.products.products.filtering.deactivated';

export const useBonusCatalogStatusColumn = () => {
  const { t } = useTranslation();

  const { sorting, arrangeId } = useAppSelector(({ bonusCatalog }) => bonusCatalog);

  const [form] = Form.useForm();
  const isActiveArrange = arrangeId === BONUS_CATALOG_COLUMN.IS_ACTIVE;

  const getItemsLabels = () => getSelectedItemsLabels(form);
  const getItemsValues = () => getSelectedItemsValues(form);

  const [filterLabel, setFilterLabel] = useState('');

  const activatedLabel = t(activated);
  const deactivatedLabel = t(deactivated);

  const allOptions = [
    createOptionItem(activatedLabel, PRODUCT_STATUS.ACTIVATED),
    createOptionItem(deactivatedLabel, PRODUCT_STATUS.DEACTIVATED),
  ];

  const { filtering } = useEnumArrange(allOptions);

  const { applyArranges, removeFilter, removeFilterValue } = useBonusCatalogArrangeRequest({
    arrangedId: PRODUCTS_COLUMNS.IS_ACTIVE,
    getFilterLabel: () => getItemsLabels().join(', '),
    getFilters: () => ({ statuses: getItemsValues() }),
    form,
    setFilterLabel,
    filterFieldName: 'activated',
    isParsedByValue: true,
  });

  return useStatusColumn({
    filtering,
    applyArranges,
    removeFilter,
    arrangedId: BONUS_CATALOG_COLUMN.IS_ACTIVE,
    isActiveArrange,
    form,
    filterLabel,
    sortOrder: sorting.sortOrder,
    getParsedItems: () => getParsedSelectedItems(form),
    removeFilterValue,
    disableSorting: true,
    columnProps: {
      width: 134,
      render: (isActive: boolean) => {
        return <ActivatedStatusBodyCell activatedStatus={isActive} />;
      },
    },
  });
};
