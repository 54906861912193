import { Form, FormInstance } from 'antd';
import { FormItemName } from '../../../lib/models/Form';
import { checkIsManager, Role } from '../../../lib/utils/roles';
import { useEffect } from 'react';
import { useTenantNames } from './useTenantNames';
import { useTranslation } from 'react-i18next';

const tenantsTranslate = 'pages.userManagement.formItems.tenants';
const allTenantsSelectedTranslate = 'pages.userManagement.formItems.allTenantsSelected';


export const useUserForms = (form: FormInstance) => {
  const { t } = useTranslation();
  const rights = Form.useWatch(FormItemName.RIGHTS, form)?.value;
  const includeAllCurrentAndFutureTenants = Form.useWatch(
    FormItemName.INCLUDE_ALL_CURRENT_AND_FUTURE_TENANTS,
    form,
  );

  const { tenantNames, isLoading } = useTenantNames();

  const isSuperAdmin = rights === Role.SUPER_ADMIN;
  const isEditor = rights === Role.EDITOR;

  const disabled = isSuperAdmin || includeAllCurrentAndFutureTenants;

  const showIncludeAllCurrentAndFutureTenants = checkIsManager(rights);

  const tenantTextFieldProps = {
    label: disabled ? t(allTenantsSelectedTranslate) : t(tenantsTranslate),
    options: tenantNames,
    isMultiSelect: rights !== Role.EDITOR,
    disabled,
    unselectable: true,
    isLoading,
  };

  const tenantFormItemProps = {
    rules: [{ required: !disabled }],
    disabled,

    ...(showIncludeAllCurrentAndFutureTenants && {
      style: {
        minHeight: 'unset',
        marginBottom: '12px',
      },
    }),
  };

  const onRightsChange = (valueObj: { title: string; value: string }) => {
    form.setFieldValue(FormItemName.TENANT_LIST, []);

    if (isEditor) {
      form.setFieldValue(FormItemName.INCLUDE_ALL_CURRENT_AND_FUTURE_TENANTS, false);
    }

    form.setFieldValue(FormItemName.RIGHTS, valueObj);

    const updatedTenantsValue = valueObj?.value === Role.ADMIN ? tenantNames : undefined;
    form.setFieldValue(FormItemName.TENANT_LIST, updatedTenantsValue);
  };

  useEffect(() => {
    form.setFieldValue(FormItemName.TENANT_LIST, []);
  }, [includeAllCurrentAndFutureTenants]);

  return { onRightsChange, tenantTextFieldProps, tenantFormItemProps };
};
