import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { SmallBonusCard } from '../SmallBonusCard';
import Slider from 'react-slick';
import { useAppSelector } from '../../../../lib/store/hooks';
import { useMediaQuery } from 'react-responsive';
import { useWindowResize } from '../../../../lib/hooks/useWindowResize';
import { SectionName } from '../../../../lib/models/Section';
import { useCalculatePoints } from '../../../../lib/hooks/endUserHooks/useCalculatePoints';
import { selectSmallBonuses } from '../../helpers/selectSmallBonuses';
import { BONUSES_CARDS_WIDTH } from '../../types';
import { getBonusesList } from '../../helpers/getBonusesList';
import { ColorVariants } from '../../../../lib/types/colors&fonts';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import { getUniqueItems } from '../../../../features/endUser/utils/helpers';

import styles from './index.module.scss';
import { useGetEndUserBonusCatalogQuery } from '../../../../lib/store/api/endUser';
import { getDisplayedPointsAccordingToRules } from '../../../endUserStatusLevel/utils/helpers';

const settings = {
  infinite: false,
  centerMode: false,
  speed: 500,
  slidesToScroll: 1,
  variableWidth: true,
  swipeToSlide: true,
  arrows: false,
};

export const SmallBonusSection: FC = () => {
  const {
    sections,
    brandKit,
    userStatusLevel: { wishedPoints, currentPointsInWishlist },
    ruleActionStatusLevel: { maxAvailablePoints, extraPoints },
    statusLevels,
    wishlistRuleBonusesIds,
    tenantAndProject,
    demandArea,
  } = useAppSelector(({ endUser }) => endUser);

  const tenantId = tenantAndProject?.tenantId;
  const modelId = tenantAndProject?.projectId;

  const { data: ruleBonusesCatalog } = useGetEndUserBonusCatalogQuery({
    tenantId: String(tenantId),
    projectId: String(modelId),
  });

  const allBonuses = useMemo(() => {
    if (statusLevels) {
      return getBonusesList(statusLevels);
    }

    return [];
  }, [statusLevels]);

  const { windowWidth } = useWindowResize();

  const [bonusWidth, setBonusWidth] = useState(BONUSES_CARDS_WIDTH.SM);

  const screenWidth = useRef(window.document.body.clientWidth);

  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const { isMobile, isLargerDesktop, isOnlyTablet } = useContainerQuery();

  const currentSectionContent = sections?.[SectionName.WISHLIST];

  const maxPointsPerModel = demandArea?.project?.maxPoints || 0;
  const maxPoints = maxAvailablePoints != null ? maxAvailablePoints : maxPointsPerModel;

  const displayedUserPoints = getDisplayedPointsAccordingToRules({
    userPoints: currentPointsInWishlist,
    maxPoints,
    extraPoints,
  });

  const bonuses = useMemo(() => {
    const bonuses = allBonuses.length
      ? selectSmallBonuses(allBonuses, displayedUserPoints, wishedPoints)
      : [];

    const ruleBonuses =
      ruleBonusesCatalog?.content.filter((bonus) => wishlistRuleBonusesIds?.includes(bonus.id)) || [];

    const ruleUniqueBonuses = getUniqueItems(ruleBonuses);

    return [...bonuses, ...ruleUniqueBonuses];
  }, [allBonuses, displayedUserPoints, ruleBonusesCatalog?.content, wishedPoints, wishlistRuleBonusesIds]);

  useEffect(() => {
    const length = bonuses?.length || 0;
    screenWidth.current = window.document.body.clientWidth;
    if (isOnlyTablet && !isPortrait && length <= 2) {
      setBonusWidth(BONUSES_CARDS_WIDTH.MD);
    }
    if (isOnlyTablet && !isPortrait && length > 2) {
      setBonusWidth((screenWidth.current - 24 * 2 - (length - 1) * 16) / length);
    }
    if (isOnlyTablet && isPortrait && length > 2) {
      setBonusWidth(BONUSES_CARDS_WIDTH.MD);
    }
    if (isLargerDesktop && length <= 2) {
      setBonusWidth(BONUSES_CARDS_WIDTH.LG);
    }
    if (isLargerDesktop && length > 2 && length <= 4) {
      setBonusWidth((screenWidth.current - 88 * 2 - (length - 1) * 16) / length);
    }
    if (isLargerDesktop && length > 4) {
      setBonusWidth((screenWidth.current - 88 * 2 - 4 * 16) / 4);
    }
    if (isMobile) {
      setBonusWidth(BONUSES_CARDS_WIDTH.SM);
    }
  }, [bonuses?.length, isLargerDesktop, isMobile, isPortrait, isOnlyTablet, windowWidth]);

  const withoutScroll =
    bonuses.length <= 4 && (isLargerDesktop || (windowWidth <= 1200 && windowWidth >= 1135));

  return (
    <div className={styles.wrapper} style={{ backgroundColor: brandKit?.colors.backgroundColor }}>
      <EndUserTypography
        type={isLargerDesktop ? 'headlineSecond' : 'headlineFourth'}
        component="Title"
        level={2}
        className={styles.title}
      >
        {currentSectionContent?.headline2Subsection}
      </EndUserTypography>
      {withoutScroll ? (
        <div className={styles.bonusesFullScreen}>
          {bonuses.map((item, index) => (
            <SmallBonusCard
              key={index}
              image={item.picture}
              title={item.title}
              description={item.description}
              width={bonusWidth}
            />
          ))}
        </div>
      ) : (
        <div className={styles.bonuses}>
          <Slider {...settings}>
            {bonuses.map((item) => (
              <SmallBonusCard
                key={item.id}
                image={item.picture}
                title={item.title}
                description={item.description}
                width={bonusWidth}
              />
            ))}
          </Slider>
        </div>
      )}
    </div>
  );
};
