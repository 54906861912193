import { useTranslation } from 'react-i18next';
import { useColorsOptionsQuery } from '../store/api/demandArea';
import { GLOBAL_SETTINGS_NAMES } from '../utils/constants';
import { useMemo } from 'react';
import { FontColorTag } from '../../features/form-items/FontColorFormItem/FontColorTag';
import { DefaultBrandKitColors } from '../../modules/brandKit/util/constants';
import { ColorsList } from '../types/colors&fonts';
import { AdditionalColorsT } from '../../modules/designSettings/types';
import { DefaultColors } from '../../modules/designSettings/helpers/getDesignSettings';
import { parseJSON } from '../utils/helpers';

const primary = 'shared.colors.primary';
const secondary1 = 'shared.colors.secondary1';
const secondary2 = 'shared.colors.secondary2';
const background = 'shared.colors.background';
const color = 'shared.colors.color';

type Props = {
  tenantId: string;
  modelId: string;
};

type ColorsType = AdditionalColorsT & ColorsList;

export const useColorOptions = (props: Props) => {
  const { t } = useTranslation();

  const { data, isLoading } = useColorsOptionsQuery(props);

  const brandKitColors = data?.find((settingsItem) => {
    return settingsItem?.name === GLOBAL_SETTINGS_NAMES.DESIGN_SETTINGS_BRAND_KIT;
  });

  const additionalColors = data?.find((settingsItem) => {
    return settingsItem?.name === GLOBAL_SETTINGS_NAMES.DESIGN_SETTINGS;
  });

  const colors = {
    ...(parseJSON(brandKitColors?.settings)?.colors as ColorsList),
    ...(parseJSON(additionalColors?.settings)?.additionalColors as AdditionalColorsT),
  } as ColorsType;

  const colorOptions = useMemo(() => {
    if (colors) {
      return [
        {
          title: (
            <FontColorTag
              size="large"
              text={t(color, { index: 1 })}
              color={colors?.first || DefaultColors.first}
              hex={colors?.first || DefaultColors.first}
            />
          ),
          value: colors?.first || DefaultColors.first,
        },
        {
          title: (
            <FontColorTag
              size="large"
              text={t(color, { index: 2 })}
              color={colors?.second || DefaultColors.second}
              hex={colors?.second || DefaultColors.second}
            />
          ),
          value: colors?.second || DefaultColors.second,
        },
        {
          title: (
            <FontColorTag
              size="large"
              text={t(color, { index: 3 })}
              color={colors?.third || DefaultColors.third}
              hex={colors?.third || DefaultColors.third}
            />
          ),
          value: colors?.third || DefaultColors.third,
        },
        {
          title: (
            <FontColorTag
              size="large"
              text={t(color, { index: 4 })}
              color={colors?.fourth || DefaultColors.fourth}
              hex={colors?.fourth || DefaultColors.fourth}
            />
          ),
          value: colors?.fourth || DefaultColors.fourth,
        },
        {
          title: (
            <FontColorTag
              size="large"
              text={t(color, { index: 5 })}
              color={colors?.fifth || DefaultColors.fifth}
              hex={colors?.fifth || DefaultColors.fifth}
            />
          ),
          value: colors?.fifth || DefaultColors.fifth,
        },

        {
          title: (
            <FontColorTag
              size="large"
              text={t(primary)}
              color={colors?.primaryColor || DefaultBrandKitColors.primaryColor}
              hex={colors?.primaryColor || DefaultBrandKitColors.primaryColor}
            />
          ),
          value: colors?.primaryColor || DefaultBrandKitColors.primaryColor,
        },
        {
          title: (
            <FontColorTag
              size="large"
              text={t(secondary1)}
              color={colors?.secondaryFirst || DefaultBrandKitColors.secondaryFirst}
              hex={colors?.secondaryFirst || DefaultBrandKitColors.secondaryFirst}
            />
          ),
          value: colors?.secondaryFirst || DefaultBrandKitColors.secondaryFirst,
        },
        {
          title: (
            <FontColorTag
              size="large"
              text={t(secondary2)}
              color={colors?.secondarySecond || DefaultBrandKitColors.secondarySecond}
              hex={colors?.secondarySecond || DefaultBrandKitColors.secondarySecond}
            />
          ),
          value: colors?.secondarySecond || DefaultBrandKitColors.secondarySecond,
        },
        {
          title: (
            <FontColorTag
              size="large"
              text={t(background)}
              color={colors?.backgroundColor || DefaultBrandKitColors.backgroundColor}
              hex={colors?.backgroundColor || DefaultBrandKitColors.backgroundColor}
            />
          ),
          value: colors?.backgroundColor || DefaultBrandKitColors.backgroundColor,
        },
      ];
    }
  }, [colors]);

  return { colorOptions, isLoading };
};
