import { useState, useEffect } from 'react';
import { ProductT } from '../../types/product';
import { calculateUserPoints, calculateUserPointsForGuest } from '../../utils/end-user-helpers';
import { useAppSelector } from '../../store/hooks';

export const useCalculatePoints = (products: ProductT[]) => {
  const { isGuestMode } = useAppSelector(({ endUser }) => endUser);

  const [totalPoints, setTotalPoints] = useState(0);

  useEffect(() => {
    if (isGuestMode) {
      const userPoints = calculateUserPointsForGuest(products);

      setTotalPoints(userPoints);
    } else {
      const userPoints = calculateUserPoints(products);

      setTotalPoints(userPoints);
    }
  }, [isGuestMode, products]);

  return totalPoints;
};
