import { FC } from 'react';
import { Modal } from 'antd';
import { IconButton } from '../../../../components/IconButton';
import styles from './styles.module.scss';

import { ReactComponent as CrossIcon } from './../../../../assets/icons/CrossIcon.svg';
import { BonusT } from '../../../../lib/types/statusLevel';
import { EndUserTypography } from '../../../../components/EndUserTypography';

type Props = {
  isModalOpen: boolean;
  close: () => void;
  selectedBonus: BonusT | null;
};
export const EndUserReceivedBonusModal: FC<Props> = ({ isModalOpen, close, selectedBonus }) => {
  return selectedBonus ? (
    <div>
      <Modal
        centered
        open={isModalOpen}
        onCancel={close}
        destroyOnClose={true}
        closeIcon={false}
        bodyStyle={{ margin: '-34px -24px -32px' }}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <IconButton
          onClick={close}
          className={styles.closeButton}
          icon={<CrossIcon width={16} height={16} style={{ color: '#5C5C6E' }} />}
        />
        <div style={{ backgroundColor: '#fff' }}>
          <div className={styles.container}>
            <div className={styles.imageContainer}>
              <img className={styles.image} alt="bonus" src={selectedBonus.picture.s3Url} />
            </div>

            <EndUserTypography type="headlineFourth" component="Title">
              {selectedBonus.title}
            </EndUserTypography>

            <EndUserTypography className={styles.description} type="subText" component="Paragraph">
              {selectedBonus.description}
            </EndUserTypography>
          </div>
        </div>
      </Modal>
    </div>
  ) : null;
};
