import { FC } from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { Typography } from 'antd';
import { PrevIcon } from './PrevIcon';
import { NextIcon } from './NextIcon';

import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';

import styles from './index.module.scss';
import { format } from 'date-fns';

const { Text } = Typography;

type Props = ReactDatePickerCustomHeaderProps & { locale?: 'en' | 'de' };

export const DatePickerHeader: FC<Props> = (props) => {
  const title = format(new Date(props.date), 'MMMM yyyy', { locale: props.locale === 'de' ? de : en });

  return (
    <div className={styles.root}>
      <button type="button" className={styles.button} onClick={props.decreaseMonth}>
        <PrevIcon type="month" />
      </button>

      <Text className={styles.title}>{title}</Text>

      <button type="button" className={styles.button} onClick={props.increaseMonth}>
        <NextIcon type="month" />
      </button>
    </div>
  );
};
