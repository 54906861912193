import { FC, useEffect } from 'react';
import { checkIsManager } from '../../../lib/utils/roles';
import { useAppDispatch, useAppSelector } from '../../../lib/store/hooks';
import { UserRoutes } from '../UserRoutes';
import { AdminRoutes } from '../AdminRoutes';
import { useLocalStorageItem } from '../../../lib/hooks/useLocalStorageItem';
import { ACCESS_TOKEN_NAME } from '../../../lib/utils/constants';
import { appLogout } from '../../../lib/utils/helpers';

export const CMSRoutes: FC = () => {
  const { role } = useAppSelector(({ auth }) => auth);
  const isManager = checkIsManager(role);
  const dispatch = useAppDispatch();
  const [accessToken] = useLocalStorageItem(ACCESS_TOKEN_NAME);

  useEffect(() => {
    if (!accessToken) {
      // TODO: Discuss about move logout to another place
      appLogout(dispatch);
    }
  }, [accessToken, dispatch]);

  return isManager ? <AdminRoutes /> : <UserRoutes />;
};
