import { FC } from 'react';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/cross-in-circle.svg';
import { useTranslation } from 'react-i18next';
import { IconButton } from '../../../../components/IconButton';
import { FormSection } from '../../../../components/FormSection';

import styles from './index.module.scss';

const errors = 'pages.products.productConfigurator.formItems.region.errors';
const label = 'pages.products.productConfigurator.formItems.region.label';

type Props = {
  name: number;
  remove: (arg: number | number[]) => void;
};

export const RegionRow: FC<Props> = ({ name, remove }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.space}>
      <FormSection
        className={styles.formSection}
        formItemProps={{
          hasFeedback: true,
          name,
          rules: [{ required: true, message: t(`${errors}.empty`) }],
          normalize: (value: string) => (value ? Number(value) : undefined),
        }}
        textFieldProps={{ type: 'number', label: t(label) }}
      />
      <div className={styles.trashButton}>
        <IconButton onClick={() => remove(name)} icon={<TrashIcon className={styles.trashIcon} />} />
      </div>
    </div>
  );
};
