import { useEffect, useState } from 'react';
import { AppLocalStorage, handleLocalStorageValue } from '../utils/helpers';

const isValue = (value: any) => ![null, undefined].includes(value);

const getValue = (key: string, initialValue: unknown) => {
  const storedValue = AppLocalStorage.getItem({ key });
  return isValue(storedValue) ? storedValue : initialValue;
};

export const useLocalStorageItem = (key: string, initialValue?: unknown) => {
  const [value, setValue] = useState(() => getValue(key, initialValue));

  useEffect(() => {
    const handleStorageChange = (event: { key: any; newValue: any }) => {
      if (event.key === key) {
        setValue(
          isValue(event.newValue)
            ? handleLocalStorageValue({ value: event.newValue })
            : initialValue,
        );
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key, initialValue]);

  const setLocalStorageItem = (value: unknown) => {
    setValue(value);
    AppLocalStorage.setItem(key, value);
  };

  return [value, setLocalStorageItem];
};
