import { UserRolesT } from '../types/auth';

export const Role: Record<string, UserRolesT> = {
  USER: 'ROLE_USER',
  ADMIN: 'ROLE_ADMIN',
  SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  EDITOR: 'ROLE_EDITOR',
  END_USER: 'ROLE_END_USER',
};

export const managerRoles = [Role.SUPER_ADMIN, Role.ADMIN];

export const checkIsManager = (role: UserRolesT | null) => role && managerRoles?.includes(role);

export const checkIsUser = (role: UserRolesT | null) => role === Role.USER || role === Role.EDITOR;

export const checkIsSuperAdmin = (role: UserRolesT | null) => role === Role.SUPER_ADMIN;

export const checkIsEndUser = (role: UserRolesT | null) => role === Role.END_USER;
