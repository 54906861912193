import { useOptionsTenantsQuery } from '../../../lib/store/api/usersManagement';
import { isLength } from '../../../lib/utils/helpers';

export function useTenantNames() {
  const { data, isLoading } = useOptionsTenantsQuery();

  const tenantList = data?.content || [];

  const tenantNames = isLength(tenantList)
    ? tenantList.map((item) => ({ value: item.id, title: item.name }))
    : [];

  return {
    tenantNames,
    isLoading,
  };
}
