import { useTranslation } from 'react-i18next';
import { RULES_COLUMN } from '../../../lib/types/ruleBuilder';
import { useRulesDataColumn } from './useRulesDataColumn';
import { Typography } from 'antd';
import { formatDateForRulesTable } from '../../../lib/utils/helpers';

const { Text } = Typography;

const date = 'pages.ruleBuilder.ruleColumns.date';

export const useCreationDateColumn = () => {
  const { t } = useTranslation();

  return useRulesDataColumn({
    title: t(date),
    arrangedId: RULES_COLUMN.CREATED_DATE,
    columnProps: {
      width: 140,
      render: (date: Date | string) => <Text>{date ? formatDateForRulesTable(date) : '-'}</Text>,
    },
  });
};
