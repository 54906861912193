import { useTranslation } from 'react-i18next';
import { PRODUCTS_COLUMNS } from '../helpers/consts';
import { TableHeaderCell } from '../../../components/Table/TableHeaderCell/TableHeaderCell';
import { ProductT } from '../../../lib/types/product';
import { parseJSON } from '../../../lib/utils/helpers';

import tableStyles from '../../../components/Table/index.module.scss';

const picture = 'pages.products.products.columns.picture';

export const useProductPictureColumn = () => {
  const { t } = useTranslation();

  return {
    title: <TableHeaderCell title={t(picture)} withArrange={false} />,
    arrangedId: PRODUCTS_COLUMNS.ADDITIONAL_INFO,
    width: 150,
    render: (data: ProductT) => {
      const parsedData = parseJSON(data.additionalInfo);

      return parsedData?.picture?.s3Url ? (
        <img className={tableStyles.picture} alt="product-pic" src={parsedData.picture.s3Url} />
      ) : null;
    },
  };
};
