import { useLocation } from 'react-router';
import { ROUTER_PATHS } from '../../utils/router-paths';

export const useCheckStatusBarVisibility = () => {
  const location = useLocation();

  const isStatusBarHidden = [`/${ROUTER_PATHS.wishlist}`, `/${ROUTER_PATHS.pointsOverview}`].includes(
    location.pathname,
  );

  return { isStatusBarHidden };
};
