import { Modal } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../lib/store/hooks';

import styles from './index.module.scss';
import {
  setCurrentCategory,
  setCurrentFinder,
  setFinderProducts,
} from '../../../lib/store/slices/endUserSlice';

import { ReactComponent as CrossIcon } from './../../../assets/icons/CrossIcon.svg';
import { IconButton } from '../../../components/IconButton';
import { useContainerQuery } from '../../../lib/hooks/endUserHooks/useContainerQuery';
import { FinderProduct } from '../../../lib/types/entities';
import { SectionName } from '../../../lib/models/Section';
import { useNavigate } from 'react-router-dom';
import { ProductT } from '../../../lib/types/product';
import { useSaveFinderRecommendationProductsMutation } from '../../../lib/store/api/endUser';

export const FinderModal = () => {
  const { currentFinder, tenantAndProject, isGuestMode } = useAppSelector(({ endUser }) => endUser);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [saveFinderRecommendationProducts] = useSaveFinderRecommendationProductsMutation();

  const [products, setProducts] = useState<FinderProduct[]>([]);

  const { isMobile, isDesktop, isLargerDesktop, isOnlyTablet } = useContainerQuery();

  const isModalOpen = currentFinder?.isOpen;

  const handleClose = useCallback(() => {
    dispatch(setCurrentFinder({ ...currentFinder, isOpen: false }));
  }, [currentFinder, dispatch]);

  useEffect(() => {
    onmessage = ({ data }: { data: { messageType: string; products: FinderProduct[] } }) => {
      if (data.messageType === 'hvm') {
        setProducts(data.products.filter((product) => product.score > 0));
      }
    };
  }, [dispatch, handleClose]);

  useEffect(() => {
    const onNavigateToProduct = (product: ProductT) => {
      navigate(`/house-model/${product.demandAreaId}`, {
        state: { scrollTo: SectionName.PRODUCT_DETAILS },
      });

      setTimeout(() => {
        dispatch(setCurrentCategory({ category: product.category }));
      }, 10);
    };

    if (products.length > 0) {
      dispatch(
        setFinderProducts({
          categoryId: currentFinder?.categoryId,
          finderProducts: products,
          onNavigateCallback: onNavigateToProduct,
        }),
      );

      if (!isGuestMode) {
        saveFinderRecommendationProducts({
          tenantId: String(tenantAndProject?.tenantId),
          finderProducts: products.map((product) => ({
            ...product,
            recommendedCategoryId: currentFinder?.categoryId || null,
          })),
        });
      }
    }

    handleClose();
  }, [currentFinder?.categoryId, dispatch, products, isGuestMode]);

  const modalWidth = useMemo(() => {
    if (isMobile) {
      return '100%';
    }

    if (isOnlyTablet) {
      return 'calc(100% - 64px)';
    }

    if (isDesktop || isLargerDesktop) {
      return 'calc(100% - 172px)';
    }
  }, [isDesktop, isLargerDesktop, isMobile, isOnlyTablet]);

  return (
    <div className={styles.wrapper}>
      <Modal
        centered
        open={isModalOpen}
        onCancel={handleClose}
        destroyOnClose={true}
        closeIcon={false}
        width={modalWidth}
        className={styles.root}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <IconButton onClick={handleClose} className={styles.iconButton} icon={<CrossIcon />} />
        <iframe
          title="iframe"
          width="100%"
          style={{ minHeight: '100%', border: 'none', borderRadius: 8 }}
          src={currentFinder?.finderUrl}
        ></iframe>
      </Modal>
    </div>
  );
};
