import { FC } from 'react';
import { Card, Radio } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import { FormItemTitle } from '../FormItemTitle';
import { FormItemName } from '../../../../lib/models/Form';
import { FormItem } from '../../../../components/FormItem';
import { Visibility } from '../../../../lib/types/product';

const visibility = 'pages.products.productConfigurator.formItems.visibility';

export const VisibilityCard: FC = () => {
  const { t } = useTranslation();

  const options = [
    {
      label: t(`${visibility}.option1`),
      value: Visibility.ALWAYS,
    },
    {
      label: t(`${visibility}.option2`),

      value: Visibility.ONLY,
    },
    {
      label: t(`${visibility}.option3`),

      value: Visibility.NEVER,
    },
  ];

  return (
    <Card className={styles.root}>
      <FormItemTitle>{t(`${visibility}.title`)}</FormItemTitle>
      <FormItem
        hasFeedback
        name={FormItemName.VISIBILITY}
        className={styles.formItem}
        initialValue={Visibility.ALWAYS}
      >
        <Radio.Group className={styles.radioGroup} options={options} />
      </FormItem>
    </Card>
  );
};
