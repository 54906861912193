import { useTranslation } from 'react-i18next';
import { useProjectDateColumn } from './useProjectDateColumn';
import { PROJECT_COLUMN } from '../helpers/constants';

const published = 'pages.projects.columns.published';

export const usePublishedColumn = () => {
  const { t } = useTranslation();

  return useProjectDateColumn({
    arrangedId: PROJECT_COLUMN.PUBLISHED,
    title: t(published),
    filterFromName: 'publishingDateFrom',
    filterToName: 'publishingDateTo',
  });
};
