import { TableHeaderCell } from '../../../components/Table/TableHeaderCell/TableHeaderCell';
import { ReactComponent as ChevronRight } from './../../../assets/icons/chevron-right-empty-fill.svg';
import styles from '../../../components/Table/index.module.scss';
import { Tooltip } from 'antd';
import { CommentSquare } from '../../../assets/icons/CommentSquare';
import { IconButton } from '../../../components/IconButton';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../../lib/store/hooks';
import { setCurrentProduct } from '../../../lib/store/slices/productsSlice';
import { ProductT } from '../../../lib/types/product';
import { QueryParams, Tabs } from '../../../lib/types/queryParams';

export const useProductActionsColumn = () => {
  const [, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const onClickChevron = (row: ProductT) => {
    dispatch(setCurrentProduct(row));
    setSearchParams({ [QueryParams.TAB]: Tabs.PRODUCT_CONFIGURATOR });
  };

  return {
    title: <TableHeaderCell withArrange={false} />,
    width: 100,
    render: (data: { statusComment: string }, row: ProductT) => {
      return (
        <div className={styles.productActionsCell}>
          {data.statusComment && !row.activated && (
            <Tooltip
              overlayStyle={{ maxWidth: '400px' }}
              overlayInnerStyle={{ padding: 16 }}
              color="#191919"
              placement="topLeft"
              arrow={false}
              title={data.statusComment}
            >
              <div>
                <CommentSquare />
              </div>
            </Tooltip>
          )}

          <IconButton icon={<ChevronRight fill="red" />} onClick={() => onClickChevron(row)} />
        </div>
      );
    },
  };
};
