import { FC } from 'react';
import { FormItem } from '../../../../components/FormItem';
import { useTranslation } from 'react-i18next';
import { FormItemName } from '../../../../lib/models/Form';
import { AppSelect } from '../../../../components/AppSelect';
import { DemandAreaResponseOption } from '../../../../lib/store/api/productCategories';

const demandArea = 'pages.products.productCategories.formItems.demandArea';

type Props = {
  options?: DemandAreaResponseOption[];
  disabled?: boolean;
};

export const DemandAreaFormItem: FC<Props> = ({ options, disabled }) => {
  const { t } = useTranslation();

  return (
    <FormItem
      hasFeedback
      name={FormItemName.DEMAND_AREA}
      rules={[{ required: !disabled, message: t(`${demandArea}.error`) }]}
    >
      <AppSelect disabled={disabled} options={options} label={t(`${demandArea}.label`)} />
    </FormItem>
  );
};
