import { Typography } from 'antd';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ROUTER_PATHS } from '../../../../lib/utils/router-paths';
import { useAppSelector } from '../../../../lib/store/hooks';
import { checkIsManager } from '../../../../lib/utils/roles';

const { Text } = Typography;

type Props = {
  id: number;
  link: string;
};

export const ProjectNameBodyCell: FC<Props> = ({ id, link }) => {
  const { id: tenantId } = useParams();
  const { role } = useAppSelector(({ auth }) => auth);
  const isManager = checkIsManager(role);

  const route = isManager ? `${ROUTER_PATHS.generalSettings}` : `${ROUTER_PATHS.designSettings}`;

  return (
    <Link to={`/tenants/${tenantId}/models/${id}/${route}`}>
      <Text className="primary_color">{link}</Text>
    </Link>
  );
};
