import React, { FC, useMemo } from 'react';
import cn from 'classnames';
import styles from '../EndUserProductCard/index.module.scss';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { ProductT } from '../../../../lib/types/product';
import { ReactComponent as OutlineCheckIcon } from './../../../../assets/icons/outline-check.svg';
import { useAppSelector } from '../../../../lib/store/hooks';
import { IRRELEVANT_REASONS } from '../../../../components/EndUserIrrelevantSurveyModal';
import { checkIsProductBought, getAmountOfCoveredProducts } from '../../../../lib/utils/end-user-helpers';
import { useTranslation } from 'react-i18next';

const translation = 'endUser.products.covered';

type Props = {
  product: ProductT;
  primaryColor?: string;
  isFilled: string;
};

export const EndUserCountOfBoughtProducts: FC<Props> = ({ product, isFilled, primaryColor }) => {
  const { isGuestMode } = useAppSelector(({ endUser }) => endUser);
  const { t } = useTranslation();

  const isGuestBoughtIrrelevantProduct =
    isGuestMode &&
    product.irrelevantSurveyReason === IRRELEVANT_REASONS.SUPPLIED_BY_THE_CLIENT.value &&
    !checkIsProductBought(product);

  const totalProducts = useMemo(() => {
    return getAmountOfCoveredProducts(product);
  }, [product]);

  return checkIsProductBought(product) || isGuestBoughtIrrelevantProduct ? (
    <div
      style={{ backgroundColor: primaryColor }}
      className={cn(styles.countOfProducts, isFilled && styles.eyeActive)}
    >
      <OutlineCheckIcon />
      <EndUserTypography component="Text" type="headlineEight">
        {t(translation)} ({totalProducts})
      </EndUserTypography>
    </div>
  ) : null;
};
