import { useNavigate } from 'react-router-dom';
import React, { FC } from 'react';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { WishlistPageT } from '../../../../lib/types/sections';
import { Category, DemandArea } from '../../../../lib/types/endUser';

import styles from './index.module.scss';
import { sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';
import { HouseMobile } from '../../../../components/HouseMobile';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { sortCategoriesByPriority } from '../../../../lib/utils/helpers';
import { checkBoughtProductsInCategory } from '../../../../lib/utils/end-user-helpers';
import { setCurrentCategory } from '../../../../lib/store/slices/endUserSlice';
import { NewHouseModelMobile } from '../../../endUserHouseModelNew/components/NewHouseModelMobile';

type Props = {
  content?: WishlistPageT;
  demandAreas?: DemandArea[];
};

export const EndUserWishlistHouseModelMobile: FC<Props> = ({ demandAreas, content }) => {
  const { brandKit, tenantAndProject } = useAppSelector(({ endUser }) => endUser);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const handleAreaClick = (areaId: number, name: string) => {
    sendDataToAnalytics(
      'Wishlist house model',
      `Open demand area`,
      `Demand Area Id: ${areaId}, Demand Area name: ${name}`,
    );

    const demandArea = demandAreas?.find((area) => area.id === areaId);

    const sortedCategories = sortCategoriesByPriority(demandArea?.categories || []);

    let category: Category | undefined = sortedCategories[0];

    //hardcode below
    if (tenantAndProject?.subDomain === 'energie-schwaben') {
      if (demandArea?.name === 'Energie') {
        const gasCategory = sortedCategories.find((category) => category.name === 'Gas');
        const stromCategory = sortedCategories.find((category) => category.name === 'Strom');

        const isGasCovered = gasCategory && checkBoughtProductsInCategory(gasCategory);
        const isStromCovered = stromCategory && checkBoughtProductsInCategory(stromCategory);

        if (isGasCovered && !isStromCovered) {
          category = stromCategory;
        } else if (isStromCovered && !isGasCovered) {
          category = gasCategory;
        } else if (isGasCovered && isStromCovered) {
          category = gasCategory;
        } else {
          category = gasCategory;
        }
      }
    }

    setTimeout(() => {
      dispatch(setCurrentCategory({ category }));
    }, 100);

    navigate(`/house-model/${areaId}`);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, areaId: number, name: string) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleAreaClick(areaId, name);
    }
  };

  const applyNewHouse =
    (tenantAndProject?.domain === 'dev.hvm.impltech.online' && tenantAndProject?.id === 11486) ||
    (tenantAndProject?.subDomain === 'energie-schwaben' && tenantAndProject?.tenantId === 11002);

  return (
    <div
      className={styles.wrapper}
      style={{
        backgroundColor: brandKit?.colors.backgroundColor,
      }}
    >
      <div className={styles.headlineBlock}>
        <EndUserTypography type="headlineSecond" component="Title" style={{ marginBottom: '8px' }}>
          {content?.headline1}
        </EndUserTypography>
        <EndUserTypography type="headlineSixth" component="Title" style={{ marginBottom: 0 }}>
          {content?.subline}
        </EndUserTypography>
      </div>

      {applyNewHouse ? (
        <NewHouseModelMobile
          demandAreas={demandAreas}
          handleAreaClick={handleAreaClick}
          handleKeyDown={handleKeyDown}
        />
      ) : (
        <HouseMobile
          demandAreas={demandAreas}
          handleAreaClick={handleAreaClick}
          handleKeyDown={handleKeyDown}
        />
      )}
    </div>
  );
};
