import { FC } from 'react';

import { Typography } from 'antd';
import { HomeIcon } from '../../../../../assets/icons/HomeIcon';
const { Text } = Typography;

type Props = {
  url: string;
};

export const ProjectUrl: FC<Props> = ({ url }) => {
  return (
    <div style={{ display: 'flex', alignContent: 'center', columnGap: 8 }}>
      <HomeIcon />
      <Text style={{ color: '#5C5C6E' }}>{url}</Text>
    </div>
  );
};
