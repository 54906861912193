import { FC } from 'react';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemTitle } from '../FormItemTitle';
import { FormSection } from '../../../../components/FormSection';
import { FormItemName } from '../../../../lib/models/Form';
import { FormItem } from '../../../../components/FormItem';
import { AppSelect } from '../../../../components/AppSelect';
import { Currency } from '../../types';

import styles from './index.module.scss';

const options = [
  { value: Currency.USD, title: 'USD' },
  { value: Currency.EUR, title: 'EUR' },
  { value: Currency.CAD, title: 'CAD' },
  { value: Currency.CHF, title: 'Franks' },
  { value: Currency.SGD, title: 'Singapore Dollar' },
];

const formItems = 'pages.products.productConfigurator.formItems';

export const PriceCard: FC = () => {
  const { t } = useTranslation();

  return (
    <Card className={styles.root}>
      <FormItemTitle>{t(`${formItems}.price.title`)}</FormItemTitle>
      <div className={styles.section}>
        <FormItem hasFeedback name={[FormItemName.PRICE, FormItemName.CURRENCY]}>
          <AppSelect options={options} placeholder={t(`${formItems}.price.label`)} />
        </FormItem>
        <FormSection
          formItemProps={{
            hasFeedback: true,
            name: [FormItemName.PRICE, FormItemName.AMOUNT],
          }}
          textFieldProps={{
            placeholder: t(`${formItems}.price.amount`),
            type: 'number',
          }}
        />

        <FormSection
          formItemProps={{
            hasFeedback: true,
            name: [FormItemName.PRICE, FormItemName.PRICE_DESCRIPTION],
          }}
          textFieldProps={{
            placeholder: t(`${formItems}.price.description`),
          }}
        />
      </div>
    </Card>
  );
};
