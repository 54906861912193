import { useTranslation } from 'react-i18next';
import { RULES_COLUMN } from '../../../lib/types/ruleBuilder';
import { useRulesDataColumn } from './useRulesDataColumn';

import styles from '../../../components/Table/index.module.scss';

const creator = 'pages.ruleBuilder.ruleColumns.creator';

export const useRuleCreatorColumn = () => {
  const { t } = useTranslation();

  return useRulesDataColumn({
    title: t(creator),
    arrangedId: RULES_COLUMN.CREATED_BY,
    columnProps: {
      width: 200,
      render: (text: string) =>
        text ? (
          <span className={styles.name}>
            <span className={styles.firstLetter}>{text?.[0]}</span> {text}
          </span>
        ) : (
          '-'
        ),
    },
  });
};
