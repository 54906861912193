import { FC } from 'react';
import { Form, Typography, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { useResetFormOnCloseModal } from '../../../../lib/hooks/useResetFormOnCloseModal';
import { AppModal } from '../../../../components/AppModal';
import { useDeleteRuleMutation, useDeleteRuleTypeMutation } from '../../../../lib/store/api/ruleBuilder';
import { ModalActions } from '../../../../lib/models/Modal';
import { setModalClosed } from '../../../../lib/store/slices/ruleBuilder';

const { Paragraph } = Typography;

const deleteModal = 'pages.ruleBuilder.deleteModal';
const deleteText = 'generic.delete';

type Props = {
  tenantId: string;
};

export const DeleteModal: FC<Props> = ({ tenantId }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const { isModalOpen, selectedRuleType, selectedRule, modalType } = useAppSelector(
    ({ ruleBuilder }) => ruleBuilder,
  );

  const isOpen = isModalOpen && modalType === ModalActions.DELETE;

  const [deleteRule, { isLoading: isRuleLoading }] = useDeleteRuleMutation();
  const [deleteRuleType, { isLoading: isRuleTypeLoading }] = useDeleteRuleTypeMutation();

  useResetFormOnCloseModal({ form, isOpen });

  const onConfirm = () => {
    form.submit();
  };

  const onClose = () => {
    dispatch(setModalClosed());
  };

  const onFinish = async () => {
    try {
      if (selectedRule) {
        await deleteRule({
          tenantId: String(tenantId),
          ruleId: Number(selectedRule?.id),
        }).unwrap();
        messageApi.success(t(`${deleteModal}.successDeletingRule`));
      } else if (selectedRuleType) {
        await deleteRuleType({
          tenantId: String(tenantId),
          ruleId: Number(selectedRuleType?.id),
        }).unwrap();
        messageApi.success(t(`${deleteModal}.successDeletingRuleType`));
      }

      onClose();
    } catch (err: any) {
      messageApi.error(err?.data?.message || t(`${deleteModal}.errorDeleting`));
    }
  };

  return (
    <>
      {contextHolder}
      <AppModal
        open={isOpen}
        onCancel={onClose}
        onOk={onConfirm}
        okText={t(deleteText)}
        titleText={t(selectedRule ? `${deleteModal}.titleRule` : `${deleteModal}.titleRuleType`)}
        isOkLoading={isRuleLoading || isRuleTypeLoading}
      >
        {isOpen && (
          <Form form={form} onFinish={onFinish}>
            <Paragraph style={{ marginBottom: '36px' }}>
              {t(selectedRule ? `${deleteModal}.textRule` : `${deleteModal}.textRuleType`, {
                rule: selectedRule ? selectedRule?.name : selectedRuleType?.name,
              })}
            </Paragraph>
          </Form>
        )}
      </AppModal>
    </>
  );
};
