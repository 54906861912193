import { FC, useMemo } from 'react';
import { Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { IconT } from '../../../designSettings/types';
import { EndUserButton } from '../../../../components/EndUserButton';
import { useAppSelector } from '../../../../lib/store/hooks';
import { ColorVariants } from '../../../../lib/types/colors&fonts';
import { SectionName } from '../../../../lib/models/Section';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import { sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';
import { useTranslation } from 'react-i18next';
import { IconComponent } from '../../../../components/IconComponent';

import styles from './index.module.scss';

const { Text } = Typography;

const translation = 'endUser.homeStatusLevel';

type Props = {
  remainingPointsToNextLevel: number;
  userPoints: number;
  maxPoints: number;
  levels: any;
  icon: IconT | undefined;
  displayedUserPoints?: number;
};

export const CurrentStatusLevelCard: FC<Props> = ({
  remainingPointsToNextLevel,
  userPoints,
  maxPoints,
  levels,
  icon,
  displayedUserPoints,
}) => {
  const navigate = useNavigate();
  const { brandKit, demandArea, sections } = useAppSelector(({ endUser }) => endUser);
  const { t } = useTranslation();

  const { isLargerTablet, isLargerDesktop, isMobile } = useContainerQuery();

  const nextLevel = levels.find((subLevel: any) => subLevel.points > Number(displayedUserPoints));

  const currentSectionContent = sections?.[SectionName.STATUS];

  const sortedLevels = [...levels].sort((a: any, b: any) => b.points - a.points);
  const userCoveredLevels = sortedLevels.filter((level: any) => Number(displayedUserPoints) >= level.points);
  const currentLevel =
    userCoveredLevels.length > 0 ? userCoveredLevels[0] : sortedLevels[sortedLevels.length - 1];

  const handleClickButton = () => {
    sendDataToAnalytics(
      'Interaktion Bonus',
      "Button 'Punkte sammeln' geklickt",
      'User ruft die Produktübersicht auf, um Punkte zu sammeln',
    );

    navigate(`/${currentSectionContent?.buttonRedirectTo}`);
  };

  const hardcodedStatusText = useMemo(() => {
    const modelName = demandArea?.project.name;

    if (modelName === 'mein PlusPortal') {
      return 'Ihr Vorteilsstatus';
    }

    return currentSectionContent?.headline2;
  }, [currentSectionContent?.headline2, demandArea?.project.name]);

  const hardcodedHeadline3 = useMemo(() => {
    const modelName = demandArea?.project.name;

    if (modelName === 'mein PlusPortal') {
      return '';
    }

    return currentSectionContent?.headline3;
  }, [currentSectionContent?.headline3, demandArea?.project.name]);

  return (
    <>
      {isMobile && (
        <EndUserTypography component="Title" level={4} type="headlineFourth" className={styles.mainTitle}>
          {hardcodedHeadline3}
        </EndUserTypography>
      )}

      <div className={styles.wrapper} style={{ color: brandKit?.colors[ColorVariants.PRIMARY_COLOR] }}>
        <>
          {isLargerTablet || isMobile ? (
            <>
              <EndUserTypography component="Text" type="headlineFifth" className={styles.title}>
                {hardcodedStatusText}
              </EndUserTypography>{' '}
              <EndUserTypography
                type={isLargerDesktop ? 'headlineThird' : 'headlineFifth'}
                component="Text"
                className={styles.statusName}
              >
                {currentLevel?.statusLevelName}
              </EndUserTypography>
              <div className={styles.pointsSection}>
                <Text className={styles.currentPoints}>
                  {userPoints}
                  <img src={icon?.s3Url} alt="icon" className={styles.starIcon} />
                  <span className={styles.divider}>/</span>
                </Text>
                <EndUserTypography className={styles.points} type={'headlineFifth'} component="Text">
                  {maxPoints}
                  <IconComponent
                    iconData={icon}
                    color="#858899"
                    style={{
                      width: isMobile ? '14px' : '20px',
                      height: isMobile ? '14px' : '20px',
                    }}
                  />
                </EndUserTypography>
              </div>
              <EndUserTypography className={styles.description} type={'headlineFifth'} component="Text">
                <b>
                  {remainingPointsToNextLevel}{' '}
                  <img src={icon?.s3Url} alt="icon" style={{ width: '14px', height: '14px' }} />
                </b>{' '}
                <span>{t(`${translation}.linkingWord`)}</span>
                <b>
                  {nextLevel?.statusLevelName || sortedLevels?.at(0)?.statusLevelName}{' '}
                  {t(`${translation}.status`)}
                </b>
              </EndUserTypography>
              <EndUserButton type="primary" onClick={handleClickButton} className={styles.button}>
                {currentSectionContent?.buttonText}
              </EndUserButton>
            </>
          ) : (
            <div className={styles.tabletWrapper}>
              <div>
                <div className={styles.header}>
                  <p className={styles.badge}>{hardcodedStatusText}</p>
                  <EndUserTypography type="headlineSixth" component="Text" className={styles.statusName}>
                    {currentLevel?.statusLevelName}
                  </EndUserTypography>
                </div>

                <div>
                  <p className={styles.badge}>{t(`${translation}.pointsText`)}</p>
                  <div className={styles.pointsSection}>
                    <p className={styles.currentPoints}>
                      {userPoints}
                      <img src={icon?.s3Url} alt="icon" style={{ width: '14px', height: '14px' }} />
                      <span className={styles.divider}>/</span>
                    </p>
                    <p className={styles.points}>
                      {maxPoints}
                      <IconComponent
                        iconData={icon}
                        color="#858899"
                        style={{ width: '14px', height: '14px', display: 'inline-block' }}
                      />
                    </p>
                  </div>
                </div>

                <div>
                  <p className={styles.badge}>{t(`${translation}.nextStatusText`)}</p>

                  <p className={styles.description}>
                    <span className={styles.bold}>
                      {remainingPointsToNextLevel}
                      <img src={icon?.s3Url} alt="icon" style={{ width: '14px', height: '14px' }} />{' '}
                    </span>
                    {t(`${translation}.linkingWord`)}
                    <span className={styles.bold}>
                      {' '}
                      {nextLevel?.statusLevelName || sortedLevels?.at(0)?.statusLevelName}{' '}
                      {t(`${translation}.status`)}
                    </span>
                  </p>
                </div>
              </div>

              <EndUserButton type="primary" onClick={handleClickButton} className={styles.button}>
                {currentSectionContent?.buttonText}
              </EndUserButton>
            </div>
          )}
        </>
      </div>
    </>
  );
};
