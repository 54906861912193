import { FC } from 'react';
import { getCoveredCategories } from '../../lib/utils/end-user-helpers';
import { parseJSON } from '../../lib/utils/helpers';
import { EndUserTypography } from '../EndUserTypography';
import { IconComponent } from '../IconComponent';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

const translation = 'endUser.shared';

type Props = {
  demandAreas?: any;
  handleAreaClick?: any;
  handleKeyDown?: any;
};

export const HouseMobile: FC<Props> = ({ demandAreas, handleAreaClick, handleKeyDown }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.mobileHouse}>
      <div className={styles.mobilePentagon}>
        {demandAreas?.slice(0, 2).map((area: any, index: any) => {
          const areaColor = parseJSON(area.color);
          const totalCategories = area?.categories.length;
          const coveredCategoriesAmount = getCoveredCategories(area?.categories)?.length;

          return (
            <div
              onKeyDown={(event) => handleKeyDown(event, area.id, area.name)}
              tabIndex={0}
              role="button"
              onClick={() => handleAreaClick(area.id, area.name)}
              key={index}
              className={styles.mobilePentagonSector}
              style={{ backgroundColor: areaColor.value }}
            >
              <IconComponent className={styles.icon} color="#fff" iconData={area?.icon} />
              <EndUserTypography
                type="headlineSixth"
                component="Title"
                className={styles.title}
                style={{ minHeight: 20, marginBottom: 0 }}
              >
                {area.name}
              </EndUserTypography>
              <EndUserTypography
                type="subText"
                component="Text"
                style={{ color: '#fff', textAlign: 'center' }}
              >
                {t(`${translation}.coveredCategoriesAmount`, { coveredCategoriesAmount, totalCategories })}
              </EndUserTypography>
            </div>
          );
        })}
      </div>
      <div className={styles.mobileRectangle}>
        {demandAreas?.slice(2).map((area: any, index: any) => {
          const areaColor = parseJSON(area.color);
          const totalCategories = area?.categories.length;
          const coveredCategoriesAmount = getCoveredCategories(area?.categories)?.length;

          return (
            <div
              onKeyDown={(event) => handleKeyDown(event, area.id, area.name)}
              tabIndex={0}
              role="button"
              onClick={() => handleAreaClick(area.id, area.name)}
              key={index}
              className={styles.mobileRectangleSector}
              style={{ backgroundColor: areaColor.value }}
            >
              <IconComponent className={styles.icon} color="#fff" iconData={area?.icon} />
              <EndUserTypography
                type="headlineSixth"
                component="Title"
                className={styles.title}
                style={{ minHeight: 20 }}
              >
                {area.name}
              </EndUserTypography>
              <EndUserTypography
                type="subText"
                component="Text"
                style={{ color: '#fff', textAlign: 'center', marginBottom: 10 }}
              >
                {t(`${translation}.coveredCategoriesAmount`, { coveredCategoriesAmount, totalCategories })}
              </EndUserTypography>
            </div>
          );
        })}
      </div>
    </div>
  );
};
