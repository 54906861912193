import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAppSelector } from '../../../lib/store/hooks';
import { ROUTER_PATHS } from '../../../lib/utils/router-paths';
import { PlatformLayout } from '../../layouts/PlatformLayout';
import { DesignSettingsPage } from '../../../pages/DesignSettingsPage';
import { ProjectsPage } from '../../../pages/ProjectsPage';
import { ProductsPage } from '../../../pages/ProductsPage';
import { PlatformSubHeader } from '../../layouts/PlatformLayout/PlatformSubHeader';
import { PlatformUserSidebar } from '../../layouts/PlatformLayout/PlatformSidebar';
import { StatusLevelPage } from '../../../pages/StatusLevelPage';

export const UserRoutes: FC = () => {
  const { tenantId } = useAppSelector(({ auth }) => auth);

  return (
    <Routes>
      <Route element={<PlatformLayout sidebar={<PlatformUserSidebar />} />}>
        <Route
          path={ROUTER_PATHS.noMatch}
          element={<Navigate to={`tenants/${tenantId}/${ROUTER_PATHS.projects}`} />}
        />

        <Route path={`tenants/:id/${ROUTER_PATHS.projects}`} element={<ProjectsPage />} />

        <Route element={<PlatformSubHeader />}>
          <Route
            path={`tenants/:id/models/:modelId/${ROUTER_PATHS.designSettings}`}
            element={<DesignSettingsPage />}
          />
          <Route path={`tenants/:id/models/:modelId/${ROUTER_PATHS.products}`} element={<ProductsPage />} />
          <Route
            path={`tenants/:id/models/:modelId/${ROUTER_PATHS.statusLevel}`}
            element={<StatusLevelPage />}
          />
          <Route
            path={`tenants/:id/models/:modelId/${ROUTER_PATHS.statusLevel}`}
            element={<div>status level</div>}
          />
          <Route path={`tenants/:id/models/:modelId/${ROUTER_PATHS.forms}`} element={<div>forms</div>} />
          <Route path={`tenants/:id/models/:modelId/${ROUTER_PATHS.header}`} element={<div>header</div>} />
          <Route path={`tenants/:id/models/:modelId/${ROUTER_PATHS.footer}`} element={<div>footer</div>} />
          <Route path={`tenants/:id/models/:modelId/${ROUTER_PATHS.model}`} element={<div>model</div>} />
          <Route
            path={`tenants/:id/models/:modelId/${ROUTER_PATHS.productCategories}`}
            element={<div>Product categories</div>}
          />
          <Route
            path={`tenants/:id/models/:modelId/${ROUTER_PATHS.productDetails}`}
            element={<div>product details</div>}
          />
          <Route
            path={`tenants/:id/models/:modelId/${ROUTER_PATHS.statusBar}`}
            element={<div>status bar</div>}
          />
          <Route
            path={`tenants/:id/models/:modelId/${ROUTER_PATHS.statusOffers}`}
            element={<div>status offers</div>}
          />
          <Route
            path={`tenants/:id/models/:modelId/${ROUTER_PATHS.companyBenefits}`}
            element={<div>company benefits</div>}
          />
          <Route
            path={`tenants/:id/models/:modelId/${ROUTER_PATHS.regionalOffers}`}
            element={<div>regional offers</div>}
          />
          <Route
            path={`tenants/:id/models/:modelId/${ROUTER_PATHS.productRecommended}`}
            element={<div>product reccomend.</div>}
          />

          <Route path={`tenants/:id/models/:modelId/${ROUTER_PATHS.friends}`} element={<div>friend</div>} />
        </Route>
      </Route>
    </Routes>
  );
};
