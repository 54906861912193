import { FC, useState } from 'react';
import { Header } from 'antd/es/layout/layout';
import { ReactComponent as LeftArrowIcon } from '../../../../assets/icons/left-arrow.svg';
import { Radio, RadioChangeEvent, Typography } from 'antd';
import styles from './index.module.scss';
import { Link, useSearchParams } from 'react-router-dom';
import { ROUTER_PATHS } from '../../../../lib/utils/router-paths';
import { ReactComponent as DesktopIcon } from '../../../../assets/icons/desktop-outline.svg';
import { ReactComponent as TabletIcon } from '../../../../assets/icons/tablet-outline.svg';
import { ReactComponent as MobileIcon } from '../../../../assets/icons/mobile-outline.svg';
import { useTranslation } from 'react-i18next';
import { DeviceType } from '../types';

const { Text } = Typography;

type Props = {
  deviceTypeValue: DeviceType;
  onChangeDeviceType: (value: RadioChangeEvent) => void;
};

const header = 'platform.header';

export const PlatformHeader: FC<Props> = ({ deviceTypeValue, onChangeDeviceType }) => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const [modelData] = useState({ tenantId: params.get('tenantId'), modelId: params.get('modelId') });

  const options = [
    {
      label: (
        <>
          <DesktopIcon /> {t(`${header}.desktop`)}
        </>
      ),
      value: DeviceType.DESKTOP,
    },
    {
      label: (
        <>
          <TabletIcon /> {t(`${header}.tablet`)}
        </>
      ),
      value: DeviceType.TABLET,
    },
    {
      label: (
        <>
          <MobileIcon /> {t(`${header}.mobile`)}
        </>
      ),
      value: DeviceType.MOBILE,
    },
  ];

  return (
    <>
      <Header className={styles.header}>
        <Link
          className={styles.link}
          to={`tenants/${modelData.tenantId}/models/${modelData.modelId}/${ROUTER_PATHS.designSettings}`}
        >
          <LeftArrowIcon className={styles.linkIcon} />
          <Text className={styles.linkText}>{t(`${header}.backTo`)}</Text>
        </Link>

        <Radio.Group
          value={deviceTypeValue}
          className={styles.radioGroup}
          options={options}
          buttonStyle="solid"
          optionType="button"
          onChange={onChangeDeviceType}
          size="small"
        />
      </Header>
    </>
  );
};
