import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PlatformSubHeader as PlatformSubHeaderComponent } from './PlatformSubHeader';
import { useParams, useSearchParams } from 'react-router-dom';
import { Tabs, QueryParams } from '../../../../lib/types/queryParams';
import { ROUTER_PATHS } from '../../../../lib/utils/router-paths';
import { useAppSelector } from '../../../../lib/store/hooks';
import { checkIsUser } from '../../../../lib/utils/roles';

export const PlatformSubHeader: FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const queryTab = searchParams.get(QueryParams.TAB);
  const { id: tenantId } = useParams();
  const { role } = useAppSelector(({ auth }) => auth);
  const isUser = checkIsUser(role);

  const { modelId } = useParams();

  const text = useMemo(() => {
    if (queryTab === Tabs.PRODUCT_CONFIGURATOR) {
      return t('generic.backToProducts');
    }

    if (queryTab === Tabs.STATUS_LEVEL_CONFIGURATOR) {
      return t('generic.backToStatusLevel');
    }

    if (queryTab === Tabs.RULE_CONFIGURATOR) {
      return t('generic.backToRules');
    }

    if (isUser) {
      return t('generic.backToModels');
    }

    if (modelId) {
      return t('generic.backToModels');
    }

    return t('generic.backToTenants');
  }, [isUser, modelId, queryTab, t]);

  const link = useMemo(() => {
    if (queryTab === Tabs.PRODUCT_CONFIGURATOR) {
      return `?${QueryParams.TAB}=${Tabs.PRODUCTS}`;
    }

    if (queryTab === Tabs.STATUS_LEVEL_CONFIGURATOR) {
      return `?${QueryParams.TAB}=${Tabs.STATUS_LEVEL}`;
    }

    if (queryTab === Tabs.RULE_CONFIGURATOR) {
      return `?${QueryParams.TAB}=`;
    }

    if (isUser) {
      return `tenants/${tenantId}/${ROUTER_PATHS.projects}`;
    }

    if (modelId) {
      return `tenants/${tenantId}/models`;
    }

    return '/tenants';
  }, [isUser, modelId, queryTab, tenantId]);

  return <PlatformSubHeaderComponent text={text} link={link} />;
};
