import { Dispatch, FC, useState } from 'react';
import { Space, Card, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadedMediaBox } from '../UploadedMediaBox';
import { FileUploader } from '../../../../components/FileUploader';
import { MIMEType, TargetType, UploadMediaResponseT } from '../../../../lib/types/media';
import { UploadLabel } from '../../../../components/UploadLabel';
import { DesignSettingsDataT } from '../../types';
import { useDeleteMedia } from '../../../../lib/hooks/useDeleteMedia';

import styles from './index.module.scss';

const GAP_SIZE = 16;
const MAX_MEDIA_FILE_SIZE = 10;
const designSettingsTab = 'pages.designSettingsTab';
const { Text } = Typography;

type Props = {
  designSettings: DesignSettingsDataT;
  setDesignSettings: Dispatch<DesignSettingsDataT>;
};

export const MediaCard: FC<Props> = ({ designSettings, setDesignSettings }) => {
  const [uploading, setUploading] = useState(false);
  const { t } = useTranslation();

  const { mediaFiles } = designSettings;
  const deleteMedia = useDeleteMedia();

  const updateMediaFiles = (file: UploadMediaResponseT) => {
    setDesignSettings({ ...designSettings, mediaFiles: [...mediaFiles, file] });
  };

  const deleteMediaFile = (id: number) => {
    const filteredMedia = mediaFiles.filter((file) => file.id !== id);
    setDesignSettings({ ...designSettings, mediaFiles: [...filteredMedia] });
    deleteMedia({ id });
  };

  const mediaOptions = uploading ? [...mediaFiles, { id: 'uploading' }] : mediaFiles;

  return (
    <Card
      className={styles.root}
      title={
        <>
          <div className={styles.titleWrapper}>
            <Text className={styles.title}>{t(`${designSettingsTab}.pictures`)}</Text>
          </div>
          <Space size={[GAP_SIZE, GAP_SIZE]} wrap>
            {mediaOptions?.map((media: any) => (
              <UploadedMediaBox
                key={media.id}
                media={media}
                deleteOnClick={() => deleteMediaFile(media.id)}
              />
            ))}
          </Space>
        </>
      }
    >
      <div>
        <FileUploader
          targetType={TargetType.PICTURES}
          accept={MIMEType.PICTURE}
          afterUploadAction={updateMediaFiles}
          errorText={t(`${designSettingsTab}.beforeUploadMediaError`)}
          maxFileSize={MAX_MEDIA_FILE_SIZE}
          disabled={uploading}
          setUploading={setUploading}
          uploadLabel={() => <UploadLabel text={t(`${designSettingsTab}.uploadPictureButton`)} />}
        />
      </div>
    </Card>
  );
};
