import { useEffect } from 'react';
import {
  useGetEndUserStatusLevelQuery,
  useGetEndUserGuestStatusLevelQuery,
  useGetEndUserRuleActionStatusLevelQuery,
} from '../../store/api/endUser';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setStatusLevels } from '../../store/slices/endUserSlice';
import { StatusLevelT } from '../../types/statusLevel';
import { useGetRuleActionStatusLevel } from './useGetRuleActionStatusLevel';

export const useFetchStatusLevels = () => {
  const { tenantAndProject, isGuestMode } = useAppSelector(({ endUser }) => endUser);
  const dispatch = useAppDispatch();

  const tenantId = tenantAndProject?.tenantId;
  const projectId = tenantAndProject?.projectId;
  const { updateRuleActionStatusLevel } = useGetRuleActionStatusLevel();

  const { data: guestLevels, isLoading: isUserLevelsLoading } = useGetEndUserGuestStatusLevelQuery(
    {
      tenantId: String(tenantId),
      projectId: String(projectId),
    },
    { skip: !isGuestMode },
  );

  const { data: userLevels, isLoading: isGuestLevelsLoading } = useGetEndUserStatusLevelQuery(
    {
      tenantId: String(tenantId),
      projectId: String(projectId),
    },
    { skip: isGuestMode },
  );

  const { data: userRuleActionStatusLevelData } = useGetEndUserRuleActionStatusLevelQuery(
    {
      tenantId: String(tenantId),
      projectId: String(projectId),
    },
    { skip: isGuestMode },
  );

  useEffect(() => {
    if (guestLevels || userLevels) {
      const levels = isGuestMode
        ? guestLevels?.statusLevelDtoPage?.content
        : userLevels?.statusLevelDtoPage?.content;

      dispatch(setStatusLevels(levels as StatusLevelT[]));
    }
  }, [dispatch, guestLevels, isGuestMode, userLevels]);

  useEffect(() => {
    if (!isGuestMode && userRuleActionStatusLevelData?.length && userLevels) {
      updateRuleActionStatusLevel(userRuleActionStatusLevelData);
    }
  }, [isGuestMode, updateRuleActionStatusLevel, userLevels, userRuleActionStatusLevelData]);

  return { isLevelsLoading: isUserLevelsLoading || isGuestLevelsLoading };
};
