import styles from './index.module.scss';
import { Button, Modal, ModalProps } from 'antd';
import { useTranslation } from 'react-i18next';

const APP_MODAL_DEFAULT_WIDTH = 612;
export const AppModal = (
  props: ModalProps & {
    subTitle?: string;
    titleText?: string;
    isOkLoading?: boolean;
  },
) => {
  const { t } = useTranslation();
  const { titleText, subTitle, isOkLoading, children, ...others } = props;

  return (
    <Modal
      width={APP_MODAL_DEFAULT_WIDTH}
      {...props}
      footer={
        <div className={styles.footer}>
          <Button onClick={props.onCancel} className={styles.footer_button}>
            {props.cancelText || t('generic.cancel')}
          </Button>
          <Button onClick={props.onOk} className={styles.footer_button} type="primary" loading={isOkLoading}>
            {props.okText || t('generic.save')}
          </Button>
        </div>
      }
      centered
      {...others}
    >
      {titleText && <div className={styles.title}>{titleText}</div>}
      {subTitle && <div>{subTitle}</div>}

      <div className={styles.content}>{children}</div>
    </Modal>
  );
};
