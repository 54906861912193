import { FC, ReactNode } from 'react';
import { Typography } from 'antd';

import styles from './index.module.scss';

const { Text } = Typography;

type Props = {
  children: ReactNode;
};

export const FormItemTitle: FC<Props> = ({ children }) => {
  return <Text className={styles.text}>{children}</Text>;
};
