import React, { FC, useCallback, useMemo } from 'react';
import { AppSelect } from '../../../../../components/AppSelect';
import cn from 'classnames';
import styles from './index.module.scss';
import { appDayJs } from '../../../../../lib/models/Time';
import { ValuesSubValues, ValuesT } from '../../../types/entities';
import { UpdateRuleBuilderPayload } from '../../CustomRuleBuilder';
import { FormInput } from '../../../../../components/FormInput';
import { booleanOptions, dateOptions } from '../../../helpers';
import { DatePicker } from '../../../../../components/DatePicker';
import { geStartOfTheUTCDayByDate } from '../../../../../features/table/components/popups/ArrangeDatePopup';
import { useAppSelector } from '../../../../../lib/store/hooks';

const valueOptions = Object.values(ValuesT)?.map((option) => ({
  value: option,
  title: option,
}));

type Props = {
  value: string;
  subValue?: string | null;
  subValue2?: string | null;
  ruleId: string;
  handleChange: (values: UpdateRuleBuilderPayload) => void;
  disabled: boolean;
};

export const ValueOperator: FC<Props> = ({ value, subValue, subValue2, handleChange, ruleId, disabled }) => {
  const { appLang } = useAppSelector(({ auth }) => auth);

  const onChange = (values: { value: string }) => {
    handleChange({ value: values.value, ruleId });
  };

  const onChangeFirstAdditionalField = useCallback(
    (newValue: string) => {
      handleChange({
        ruleId,
        subValue: newValue,
        value,
      });
    },
    [handleChange, ruleId, value],
  );

  const isAnyOptionsFromFirstSelectIsSelected = !!value;
  const isDayOrDayDigitSelected = subValue === 'Day' || subValue === 'Day Digit';

  const onDateChange = useCallback(
    (date: Date) => {
      handleChange({
        ruleId,
        value,
        subValue: String(subValue),
        subValue2: date ? appDayJs(geStartOfTheUTCDayByDate(date)).toISOString() : undefined,
      });
    },
    [handleChange, ruleId, subValue, value],
  );

  const onChangeSecondInputField = useCallback(
    (newSubValue2: string) => {
      handleChange({ value, ruleId, subValue: String(subValue), subValue2: newSubValue2 });
    },
    [handleChange, ruleId, subValue, value],
  );

  const FirstAdditionalComponent = useMemo(() => {
    if (value === ValuesT.Percent || value === ValuesT.Digit) {
      return (
        <FormInput
          disabled={disabled}
          onChange={(e) => onChangeFirstAdditionalField(e.target.value)}
          rootClassname={cn(styles.inputInner, disabled ? styles.disabled : '')}
          className={styles.ruleInput}
          value={subValue ? String(subValue) : ''}
          label="Number"
          type="number"
        />
      );
    } else if (value === ValuesT.Date) {
      return (
        <AppSelect
          disabled={disabled}
          onChange={(values: { value: string }) => onChangeFirstAdditionalField(values.value)}
          className={cn(
            styles.select,
            styles.asSecondSelect,
            isDayOrDayDigitSelected && styles.dayOrNumberSelected,
          )}
          options={dateOptions}
          value={subValue ? String(subValue) : ''}
          label="Date Type"
        />
      );
    } else if (value === ValuesT.Boolean) {
      return (
        <AppSelect
          disabled={disabled}
          onChange={(values: { value: string }) => onChangeFirstAdditionalField(values.value)}
          className={cn(styles.select, styles.asSecondSelect)}
          options={booleanOptions}
          value={subValue ? String(subValue) : ''}
          label="Boolean type"
        />
      );
    }
  }, [disabled, isDayOrDayDigitSelected, onChangeFirstAdditionalField, subValue, value]);

  const SecondAdditionalComponent = useMemo(() => {
    switch (subValue) {
      case ValuesSubValues.Day:
        return (
          <DatePicker
            disabled={disabled}
            isClearable
            size={'small'}
            selected={subValue2 ? new Date(subValue2) : undefined}
            onChange={onDateChange}
            style={{ overflow: 'visible', height: '100%' }}
            locale={appLang}
            placeholderText="Day"
            hasFeedback={false}
            inputClassName={styles.datePickerInput}
            inputRootClassName={cn(styles.inputInner, disabled ? styles.disabled : '')}
            showCalendarIcon={true}
            iconClassName={styles.calendarIcon}
            clearButtonClassName={styles.calendarClearButtonClassName}
          />
        );
      case ValuesSubValues.DayDigit:
        return (
          <FormInput
            disabled={disabled}
            onChange={(e) => onChangeSecondInputField(e.target.value)}
            rootClassname={cn(styles.inputInner, disabled ? styles.disabled : '')}
            className={styles.ruleInput}
            value={subValue2 ? String(subValue2) : ''}
            label="Number"
            type="number"
          />
        );
      default:
        return null;
    }
  }, [onChangeSecondInputField, onDateChange, subValue, subValue2, disabled]);

  return (
    <div className={styles.container}>
      <AppSelect
        disabled={disabled}
        className={cn(styles.select, isAnyOptionsFromFirstSelectIsSelected && styles.secondOptionOpened)}
        onChange={onChange}
        options={valueOptions}
        value={value}
        label="Value"
      />
      {FirstAdditionalComponent}
      {SecondAdditionalComponent}
    </div>
  );
};
