import React, { FC } from 'react';
import { AppModal } from '../../../../components/AppModal';
import { useTranslation } from 'react-i18next';
import { Form, message, MessageArgsProps } from 'antd';
import { useResetFormOnCloseModal } from '../../../../lib/hooks/useResetFormOnCloseModal';
import styles from './../EditUserModal/index.module.scss';
import { FullNameFormItem } from '../FormItems/FullNameFormItem';
import { UsernameFormItem } from '../FormItems/UsernameFormItem';
import { EmailFormItem } from '../FormItems/EmailFormItem';
import { RightsFormItem } from '../FormItems/RightsFormItem';
import { LanguagesFormItem } from '../FormItems/LanguagesFormItem';
import { TenantsFormItem } from '../FormItems/TenantsFormItem';
import { AddUserPayload, useAddUserMutation } from '../../../../lib/store/api/usersManagement';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { ModalActions } from '../../../../lib/models/Modal';
import { setModalClosed } from '../../../../lib/store/slices/userManagementSlice';
import { transformUserManagementFormValuesToServerPayload } from '../../helpers/transformUserManagementFormValuesToServerPayload';
import { IncludeFutureTenants } from '../IncludeFutureTenants';
import { useUserForms } from '../../hooks/useUserForms';

const MESSAGE_SHOWING_TIME = 3; // seconds

const userManagement = 'pages.userManagement';

export type optionPayload = { title: string; value: string };

export type AddUserFormValue = {
  allAdminTenants: boolean;
  email: string;
  fullName: string;
  language: optionPayload;
  rights: optionPayload;
  tenants: optionPayload[];
  username: string;
};

export const CreateUserModal: FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useAppDispatch();

  const [createUser, { isLoading }] = useAddUserMutation();

  const isModalOpen = useAppSelector((state) => state.userManagement.isModalOpen);

  const modalType = useAppSelector((state) => state.userManagement.modalType);
  const isOpen = isModalOpen && modalType === ModalActions.ADD;
  const onClose = () => dispatch(setModalClosed());

  useResetFormOnCloseModal({ form, isOpen });

  const onConfirm = () => {
    form.submit();
  };

  const { onRightsChange, tenantFormItemProps, tenantTextFieldProps } = useUserForms(form);

  const onFinish = async (values: AddUserFormValue) => {
    const sendData: AddUserPayload = transformUserManagementFormValuesToServerPayload(values);

    const messageConfig = {} as MessageArgsProps;

    try {
      await createUser(sendData).unwrap();
      messageConfig.content = t(`${userManagement}.success.createUser`);
      messageConfig.type = 'success';
      onClose();
    } catch (err: any) {
      messageConfig.content = err?.data?.message || t(`${userManagement}.errors.createUser`);
      messageConfig.type = 'error';
    } finally {
      messageApi.open({
        duration: MESSAGE_SHOWING_TIME,
        ...messageConfig,
      } as MessageArgsProps);
    }
  };

  return (
    <AppModal
      open={isOpen}
      onCancel={onClose}
      onOk={onConfirm}
      titleText={t(`${userManagement}.modals.addUserTitle`)}
      subTitle={t(`${userManagement}.modals.addUserDescription`)}
      isOkLoading={isLoading}
    >
      {contextHolder}
      {isOpen && (
        <Form className={styles.form} form={form} onFinish={onFinish}>
          <FullNameFormItem form={form} />
          <UsernameFormItem disabled={true} />
          <EmailFormItem />
          <RightsFormItem onChange={onRightsChange} />
          <TenantsFormItem
            tenantFormItemProps={tenantFormItemProps}
            tenantTextFieldProps={tenantTextFieldProps}
          />
          <IncludeFutureTenants />
          <LanguagesFormItem />
        </Form>
      )}
    </AppModal>
  );
};
