import { FC } from 'react';

import styles from './index.module.scss';
import { UploadMediaResponseT } from '../../../../lib/types/media';
import { EndUserTypography } from '../../../../components/EndUserTypography';

type Props = {
  image: UploadMediaResponseT;
  title: string;
  description: string;
  onClickOffer: () => void;
};

export const RegionalOfferCard: FC<Props> = ({ image, title, description, onClickOffer }) => {
  return (
    <div className={styles.wrapper} onClick={onClickOffer} role="presentation">
      <div className={styles.pictureWrapper}>
        <img src={image.s3Url} alt={image.originalFileName} />
      </div>
      <div className={styles.textWrapper}>
        <EndUserTypography type="headlineFourth" component="Title" level={4} className={styles.title}>
          {title}
        </EndUserTypography>
        <EndUserTypography type="subText" component="Text" className={styles.description}>
          {description}
        </EndUserTypography>
      </div>
    </div>
  );
};
