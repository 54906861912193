import { BenefitsSection } from '../../modules/endUserBenefits/components/BenefitsSection';
import { EndUserDemandArea } from '../../modules/endUserDemandArea/components/EndUserDemandArea';
import { FriendsSection } from '../../modules/endUserFriends/components/FriendsSection';
import { EndUserProductSection } from '../../modules/endUserProductCarousel/components/EndUserProductSection';
import { RegionalOffersSection } from '../../modules/endUserRegionalOffers/components/RegionalOffersSection';

export const EndUserDemandAreaPage = () => {
  return (
    <>
      <EndUserDemandArea />
      <EndUserProductSection />
      <FriendsSection />
      <RegionalOffersSection />
      <BenefitsSection />
    </>
  );
};
