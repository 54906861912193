import { Typography } from 'antd';
import { FormItem } from '../../../../../components/FormItem';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { BONUS_CATALOG_FORM_ITEMS } from '../../../helpers';
import { AppSegmented } from '../../../../../components/AppSegmented';
import { PayoutOption } from '../../../../../lib/types/bonusCatalog';

import styles from '../../BonusCatalog/index.module.scss';

const { Text } = Typography;

const payoutType = 'pages.bonusCatalogTab.formItems.payoutType';

export const PayoutTypes: FC = () => {
  const { t } = useTranslation();

  const options = [
    {
      label: t(`${payoutType}.option1`),
      value: PayoutOption.ONE_TIME,
    },
    {
      label: t(`${payoutType}.option2`),
      value: PayoutOption.CYCLE,
    },
  ];

  return (
    <div className={styles.payoutType}>
      <Text className={styles.title}>{t(`${payoutType}.title`)}</Text>

      <FormItem
        hasFeedback
        name={BONUS_CATALOG_FORM_ITEMS.PAYOUT_OPTION}
        className={styles.radioGroup}
        initialValue={PayoutOption.ONE_TIME}
      >
        <AppSegmented options={options} />
      </FormItem>
    </div>
  );
};
