import { FormItemName } from '../../../../../lib/models/Form';
import { AppSelect } from '../../../../../components/AppSelect';
import { FormItem } from '../../../../../components/FormItem';
import React, { useMemo } from 'react';
import { useGetTemplatesQuery } from '../../../../../lib/store/api/project';
import { useTranslation } from 'react-i18next';

const template = 'pages.projects.formItem.template';

type Props = {
  tenantId: string;
};

export const TemplateFormItem = ({ tenantId }: Props) => {
  const { t } = useTranslation();

  const { data, isLoading } = useGetTemplatesQuery({ tenantId: String(tenantId) });

  const templateOptions = useMemo(() => {
    if (data?.content) {
      return data?.content.map((template) => {
        return { title: template.name, value: template.id };
      });
    }
  }, [data]);

  return (
    <FormItem rules={[{ required: true }]} name={FormItemName.TEMPLATE}>
      <AppSelect isLoading={isLoading} label={t(template)} options={templateOptions} />
    </FormItem>
  );
};
