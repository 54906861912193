import { ColorVariants } from '../../../lib/types/colors&fonts';

export const DefaultBrandKitColors = {
  primaryColor: '#DA1B5E',
  secondaryFirst: '#191919',
  secondarySecond: '#FFFFFF',
  backgroundColor: '#F2F2F3',
};

export const AdditionalChooseColors = {
  white: '#ffffff',
  black: '#000000',
};

export const DefaultFontStyles = {
  headlineFirst: {
    font: 'SimonKucher',
    fontWeight: 700,
    fontSize: 48,
    colorVariant: ColorVariants.SECONDARY_FIRST,
  },
  headlineSecond: {
    font: 'SimonKucher',
    fontWeight: 700,
    fontSize: 40,
    colorVariant: ColorVariants.SECONDARY_FIRST,
  },
  headlineThird: {
    font: 'SimonKucher',
    fontWeight: 700,
    fontSize: 24,
    colorVariant: ColorVariants.SECONDARY_FIRST,
  },
  headlineFourth: {
    font: 'SimonKucher',
    fontWeight: 700,
    fontSize: 20,
    colorVariant: ColorVariants.SECONDARY_FIRST,
  },
  headlineFifth: {
    font: 'SimonKucher',
    fontWeight: 700,
    fontSize: 16,
    colorVariant: ColorVariants.SECONDARY_FIRST,
  },
  headlineSixth: {
    font: 'SimonKucher',
    fontWeight: 700,
    fontSize: 14,
    colorVariant: ColorVariants.SECONDARY_FIRST,
  },
  headlineSeventh: {
    font: 'SimonKucher',
    fontWeight: 700,
    fontSize: 12,
    colorVariant: ColorVariants.SECONDARY_FIRST,
  },
  headlineEight: {
    font: 'SimonKucher',
    fontWeight: 700,
    fontSize: 12,
    colorVariant: ColorVariants.SECONDARY_FIRST,
  },
  body: { font: 'SimonKucher', fontWeight: 400, fontSize: 16, colorVariant: ColorVariants.SECONDARY_FIRST },
  subText: {
    font: 'SimonKucher',
    fontWeight: 400,
    fontSize: 12,
    colorVariant: ColorVariants.SECONDARY_FIRST,
  },
  textLink: {
    font: 'SimonKucher',
    fontWeight: 400,
    fontSize: 12,
    colorVariant: ColorVariants.SECONDARY_FIRST,
  },
};

export const FontStyleKeys = Object.keys(DefaultFontStyles);
