import { FC } from 'react';
import { RulesTable } from '../tables/RulesTable';
import { DeleteModal } from '../modals/DeleteModal';

type Props = {
  tenantId: string;
  modelId: string;
};

export const RulesTab: FC<Props> = ({ tenantId, modelId }) => {
  return (
    <>
      <RulesTable tenantId={tenantId} modelId={modelId} />
      <DeleteModal tenantId={tenantId} />
    </>
  );
};
