import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { auth } from './authSlice';
import { api } from '../api';
import { tenants } from './tenantsSlice';
import { userManagement } from './userManagementSlice';
import { designSettings } from './designSettingsSlice';
import { projects } from './projectsSlice';
import { products } from './productsSlice';
import { demandAreas } from './demandAreasSlice';
import { productCategories } from './productCategoriesSlice';
import { bonusCatalog } from './bonusCatalogSlice';
import { statusLevel } from './statusLevelSlice';
import { endUser } from './endUserSlice';
import { containerQueries } from './containerQueriesSlice';
import { ruleBuilder } from './ruleBuilder';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'endUser'],
};

const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['error', 'loading'],
};

const endUserPersistConfig = {
  key: 'endUser',
  storage,
  blacklist: ['error', 'loading'],
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  tenants,
  userManagement,
  designSettings,
  projects,
  demandAreas,
  productCategories,
  products,
  bonusCatalog,
  statusLevel,
  ruleBuilder,
  endUser: persistReducer(endUserPersistConfig, endUser),
  containerQueries,
  [api.reducerPath]: api.reducer,
});

export const persistedReducer = persistReducer(persistConfig, rootReducer);
