import { User } from '../../../helpers/constants';
import { AssignedTenantsHeaderCell } from '../../../components/UsersTable/AssignedTenantsHeaderCell';
import { Typography } from 'antd';
import { ResponseOptionPayloadT } from '../../../../../lib/types';

const { Text } = Typography;

export const assignedTenantsColumn = {
  dataIndex: User.TENANTS,
  width: 230,
  title: <AssignedTenantsHeaderCell />,
  render: (value: ResponseOptionPayloadT[]) => {
    return <Text>{value?.map((tenant) => tenant.name).join(', ')}</Text>;
  },
};
