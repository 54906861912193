import { api, TAGS_KEY } from './index';
import { ResultWithPagination } from '../../types/pagination';
import {
  GetGlobalSettingsPayloadT,
  GlobalSettingsResponseT,
  ProjectT,
  SaveGlobalSettingsPayloadT,
  TemplateT,
} from '../../types/entities';
import {
  CreateProjectPayload,
  EditProjectT,
  GetProjectsPayloadT,
  UpdateProjectT,
} from '../../types/projects';
import { isLength, parseJSON } from '../../utils/helpers';
import { UserDataT } from '../../types/auth';
import { Role } from '../../utils/roles';
import { TableSortOrder, WITHOUT_PAGINATION_ELEMENTS_SIZE } from '../../utils/constants';

export const projectApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTemplates: build.query<
      ResultWithPagination<TemplateT[]>,
      { tenantId: string; body?: { searchByName: string } }
    >({
      query: ({ tenantId, body }: { tenantId: string; body: { searchByName: string } }) => ({
        url: 'template/get-templates',
        method: 'POST',
        body: body ? body : {},
        params: { size: 10000 },
        headers: {
          'X-TENANT': tenantId,
        },
      }),
    }),
    saveGlobalSettings: build.mutation<void, SaveGlobalSettingsPayloadT>({
      query: (body: SaveGlobalSettingsPayloadT) => ({
        url: 'global-settings',
        method: 'POST',
        body: body,
        headers: {
          'X-TENANT': body.tenantId,
        },
      }),
      invalidatesTags: [TAGS_KEY.GLOBAL_SETTINGS],
    }),
    getGlobalSettings: build.query<GlobalSettingsResponseT, GetGlobalSettingsPayloadT>({
      query: (params: GetGlobalSettingsPayloadT) => ({
        url: `global-settings/${params.projectId}`,
        method: 'GET',
        params: params,
        headers: {
          'X-TENANT': params.tenantId,
        },
      }),
      transformResponse: (response: GlobalSettingsResponseT) => {
        return isLength(response) ? parseJSON(response[0].settings) : null;
      },
      providesTags: [TAGS_KEY.GLOBAL_SETTINGS],
    }),
    getProjects: build.query<ResultWithPagination<ProjectT[]>, GetProjectsPayloadT>({
      query: ({ page, filterFieldName, sortFieldName, filter, sortOrder, tenantId }) => {
        const url = `project/get-projects?size=10&page=${page || 0}${
          sortFieldName && sortOrder && sortOrder !== TableSortOrder.NONE
            ? `&sort=${sortFieldName},${sortOrder}`
            : ''
        }`;

        return {
          url: url,
          method: 'POST',
          body: Array.isArray(filter)
            ? {
                [filterFieldName as string]: filter,
              }
            : filter,
          headers: {
            'X-TENANT': tenantId,
          },
        };
      },
      providesTags: [TAGS_KEY.PROJECTS],
    }),
    getProjectsForFiltering: build.query<
      ResultWithPagination<ProjectT[]>,
      { searchFieldName: string; search: string; tenantId: string }
    >({
      query: ({ searchFieldName, search, tenantId }) => ({
        url: `project/get-projects?size=${WITHOUT_PAGINATION_ELEMENTS_SIZE}&page=0`,
        method: 'POST',
        body: {
          [searchFieldName]: search,
        },
        headers: {
          'X-TENANT': tenantId,
        },
      }),
      providesTags: [TAGS_KEY.PROJECTS],
    }),
    createProject: build.mutation<void, CreateProjectPayload>({
      query: (body) => ({
        url: 'project',
        method: 'POST',
        body: body,
        headers: {
          'X-TENANT': body.tenantId,
        },
      }),
      invalidatesTags: [TAGS_KEY.PROJECTS],
    }),
    updateProject: build.mutation<void, EditProjectT>({
      query: (body: UpdateProjectT) => ({
        url: 'project',
        method: 'PUT',
        body: body,
        headers: {
          'X-TENANT': body.tenantId,
        },
      }),
      invalidatesTags: [TAGS_KEY.PROJECTS],
    }),
    deleteProject: build.mutation<void, { tenantId: string; projectId: string }>({
      query: ({ tenantId, projectId }) => ({
        url: `project/${projectId}/delete`,
        method: 'DELETE',
        headers: {
          'X-TENANT': tenantId,
        },
      }),
      invalidatesTags: [TAGS_KEY.PROJECTS],
    }),
    getAdminUsersForEmailSKP: build.query<ResultWithPagination<UserDataT[]>, void>({
      query: () => ({
        url: `admin/get-users?size=${WITHOUT_PAGINATION_ELEMENTS_SIZE}&page=0`,
        method: 'POST',
        body: {
          authorities: [Role.ADMIN, Role.SUPER_ADMIN],
        },
      }),
    }),
    updateProjectStatus: build.mutation<unknown, { id: number; tenantId: number; status: boolean }>({
      query: ({ id, tenantId, status }) => ({
        url: `project/${id}/set-active?active=${status}`,
        method: 'PATCH',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      invalidatesTags: [TAGS_KEY.PROJECTS],
    }),
    updateProjectPointsLimit: build.mutation<void, EditProjectT>({
      query: (body: UpdateProjectT) => ({
        url: 'project/point-limit',
        method: 'PATCH',
        body: body,
        headers: {
          'X-TENANT': body.tenantId,
        },
      }),
      invalidatesTags: [TAGS_KEY.PROJECTS],
    }),
  }),
});

export const {
  useGetTemplatesQuery,
  useSaveGlobalSettingsMutation,
  useGetGlobalSettingsQuery,
  useGetProjectsQuery,
  useUpdateProjectMutation,
  useLazyGetProjectsForFilteringQuery,
  useGetAdminUsersForEmailSKPQuery,
  useDeleteProjectMutation,
  useCreateProjectMutation,
  useLazyGetTemplatesQuery,
  useUpdateProjectStatusMutation,
  useUpdateProjectPointsLimitMutation,
} = projectApi;
