import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { WishlistPageT } from '../../../../lib/types/sections';
import { DemandArea } from '../../../../lib/types/endUser';
import { ProductT } from '../../../../lib/types/product';
import { useNavigate } from 'react-router-dom';
import { sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';
import styles from './index.module.scss';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { EndUserWishlistProductsList } from '../../../../features/endUser/components/WishlistProductsList';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';

import { ReactComponent as ArrowIcon } from '../../../../assets/icons/new-wishlist-arrow.svg';
import { EndUserSquareTiles } from '../../../EndUserDemandAreaTiles/components/EndUserSquareTiles';
import { useAppSelector } from '../../../../lib/store/hooks';
import { WishlistDesktopFrame } from '../../../../assets/images/WishlistDesktopFrame';

// import { ReactComponent as WishlistDesktopFrame } from './../../../../assets/images/wishlist-desktop-frame.svg';

type ArrowPositionT = {
  top: number | string;
  right: string;
  left?: string;
  transform?: string;
};

type Props = {
  content?: WishlistPageT;
  demandAreas: DemandArea[];
  wishlistData?: ProductT[];
};

export const EndUserWishlistTilesDesktop: FC<Props> = ({ content, wishlistData, demandAreas }) => {
  const navigate = useNavigate();
  const { isLargerDesktop, isLargeTablet, isOnlyTablet, isMoreThanFullHd } = useContainerQuery();
  const { brandKit } = useAppSelector(({ endUser }) => endUser);

  const [arrowPosition, setArrowPosition] = useState<ArrowPositionT>({
    top: 0,
    right: '60%',
  });

  const handleAreaClick = (areaId: number, name: string) => {
    sendDataToAnalytics(
      'Wishlist house model',
      `Open demand area`,
      `Demand Area Id: ${areaId}, Demand Area name: ${name}`,
    );

    navigate(`/house-model/${areaId}`);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, areaId: number, name: string) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleAreaClick(areaId, name);
    }
  };

  const setupArrowPosition = useCallback(
    (countOfDemandAreas: number | undefined) => {
      if (!countOfDemandAreas) {
        return null;
      }

      let top: number | string = 0;
      let right = '';
      let transform = '';

      if (isLargeTablet) {
        top = '110%';
        right = '72%';
        transform = 'rotateY(358deg) rotateZ(202deg) scale(1.2)';
      } else {
        switch (countOfDemandAreas) {
          case 1:
            top = 0;
            right = '83%';
            transform = 'rotateZ(342deg)';
            break;
          case 2:
            top = 0;
            right = '83%';
            transform = 'rotateZ(342deg)';
            break;
          case 3:
            top = -25;
            right = '70%';
            transform = 'rotateZ(352deg)';
            break;
          case 4:
            top = -35;
            right = '68%';
            transform = 'rotateZ(356deg)';
            break;
          case 5:
            top = -50;
            right = '70%';
        }
      }

      setArrowPosition({ top, right, transform });
    },
    [isLargeTablet],
  );

  const updatedDemandAreas = demandAreas.map((area: DemandArea) => {
    const updatedCategories = area.categories?.map((category: any) => {
      const wishlistCategory = wishlistData?.find(
        (wishlistCategory: any) => wishlistCategory?.category?.id === category?.id,
      );
      const isAddedToWishlist = wishlistCategory ? wishlistCategory.activated : false;

      const updatedProducts = category.products?.map((product: any) => {
        const wishlistProduct = wishlistData?.find(
          (wishlistProduct: any) => wishlistProduct.id === product.id,
        );
        const isAddedToWishlist = wishlistProduct ? wishlistProduct.activated : false;

        return { ...product, isAddedToWishlist };
      });

      return { ...category, products: updatedProducts, isAddedToWishlist };
    });

    return { ...area, categories: updatedCategories };
  });

  const primaryColor = brandKit?.colors.primaryColor;

  useEffect(() => {
    setupArrowPosition(demandAreas?.length);
  }, [demandAreas?.length, setupArrowPosition]);

  const headlineContainerStyle = useMemo(() => {
    return {
      backgroundColor: 'transparent',
    };
  }, []);

  return (
    <div
      className={styles.section}
      style={{
        background: `linear-gradient(270.22deg, rgba(0, 0, 0, 0.24) 1%, rgba(0, 0, 0, 0.06) 99.15%), url(${content?.backgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      {isOnlyTablet && <div className={styles.overlay} />}
      <div className={styles.mainContainer}>
        <EndUserSquareTiles
          demandAreas={updatedDemandAreas}
          handleAreaClick={handleAreaClick}
          handleKeyDown={handleKeyDown}
        />
      </div>

      {isLargerDesktop && (
        <WishlistDesktopFrame
          fillColors={[`${primaryColor}45`, `${primaryColor}59`]}
          primaryColor={primaryColor}
          isBigScreen={isMoreThanFullHd}
        />
      )}

      <div style={headlineContainerStyle} className={styles.headlineBlock}>
        {!content?.withoutArrow !== undefined && !content?.withoutArrow ? (
          <div className={styles.arrowIcon} style={{ ...arrowPosition }}>
            <ArrowIcon />
          </div>
        ) : null}

        <div className={styles.headlineContainer}>
          <EndUserTypography
            className={styles.subTitle}
            type="headlineFirst"
            component="Title"
            style={{ marginBottom: '16px' }}
          >
            {content?.headline1}
          </EndUserTypography>
          <EndUserTypography type="headlineThird" component="Title" style={{ marginTop: 0, marginBottom: 0 }}>
            {content?.subline}
          </EndUserTypography>
        </div>
        {isLargerDesktop && (
          <EndUserWishlistProductsList
            className={styles.productList}
            buttonClassName={styles.productListButton}
            productNameClassName={styles.productName}
            wishlistData={wishlistData}
            demandAreas={demandAreas}
          />
        )}
      </div>
    </div>
  );
};
