import { useTranslation } from 'react-i18next';
import { useProjectDateColumn } from './useProjectDateColumn';
import { PROJECT_COLUMN } from '../helpers/constants';

const lastUpdates = 'pages.projects.columns.lastUpdates';

export const useLastUpdateColumn = () => {
  const { t } = useTranslation();

  return useProjectDateColumn({
    arrangedId: PROJECT_COLUMN.LAST_UPDATES,
    title: t(lastUpdates),
    filterFromName: 'lastModifiedDateFrom',
    filterToName: 'lastModifiedDateTo',
  });
};
