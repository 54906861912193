import { FC } from 'react';
import { TableHeaderCell } from '../../../../components/Table/TableHeaderCell/TableHeaderCell';
import { getArrangesInitialValues } from '../../../../lib/utils/arranges-helpers';
import { useArrangeSubmit } from '../../helpers/useArrangeSubmit';
import { ArrangeDatePopup } from '../popups/ArrangeDatePopup';

type Props = {
  arrangedId?: string;
  title?: string;
  removeFilter?: () => void;
  sortOrder?: any;
  isActiveArrange?: any;
  form?: any;
  applyArranges?: any;
  rootProps?: any;
  filterLabel?: any;
};

export const DateHeaderCell: FC<Props> = ({
  arrangedId,
  title,
  removeFilter,
  sortOrder,
  isActiveArrange,
  form,
  applyArranges,
  rootProps,
  filterLabel,
}) => {
  const { onFinish } = useArrangeSubmit(applyArranges);

  return (
    <TableHeaderCell
      title={title}
      arrangedId={arrangedId}
      filterLabel={filterLabel}
      removeFilter={removeFilter}
      sortOrder={sortOrder}
      isActiveArrange={isActiveArrange}
      form={form}
      rootProps={rootProps}
    >
      <ArrangeDatePopup
        initialValues={getArrangesInitialValues(form)}
        form={form}
        onFinish={onFinish}
        isActiveArrange={isActiveArrange}
      />
    </TableHeaderCell>
  );
};
