import React, {FC} from 'react';

interface Props {
    fill: string
}

const BigHouseItem: FC<Props> = ({fill}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="690" height="626" viewBox="0 0 690 626" fill="none">
        <g filter="url(#filter0_d_89_2557)">
            <path
                d="M650 225.107V550C650 567.673 635.673 582 618 582H72C54.3269 582 40 567.673 40 550V225.107C40 213.631 46.1452 203.034 56.1053 197.334L329.105 41.0965C338.953 35.461 351.047 35.461 360.895 41.0965L633.895 197.334C643.855 203.034 650 213.631 650 225.107Z"
                fill={fill}/>
            <path
                d="M660 550V225.107C660 210.045 651.934 196.136 638.862 188.655L365.862 32.4173C352.937 25.0207 337.063 25.0207 324.138 32.4173L51.1383 188.655C38.0655 196.136 30 210.045 30 225.107V550C30 573.196 48.804 592 72 592H618C641.196 592 660 573.196 660 550Z"
                stroke={fill} strokeWidth="20"/>
        </g>
    </svg>

);

export default BigHouseItem;