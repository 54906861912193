import { useAppSelector } from '../../../lib/store/hooks';
import { Form } from 'antd';
import { useState } from 'react';
import { useDateArrange } from '../../userManagement/hooks/table/useDateArrange';
import { useDateColumn } from '../../../features/table/helpers/useDateColumn';
import { PROJECT_COLUMN } from '../helpers/constants';
import { useProjectArrangeRequest } from './useProjectArrangeRequest';

type Props = {
  title: string;
  arrangedId: PROJECT_COLUMN;
  filterFromName: string;
  filterToName: string;
  headerCellProps?: any;
};

export const useProjectDateColumn = ({
  title,
  arrangedId,
  filterFromName,
  filterToName,
  headerCellProps,
}: Props) => {
  const { arrangeId, sorting } = useAppSelector(({ projects }) => projects);
  const { appLang } = useAppSelector(({ auth }) => auth);

  const isActiveArrange = arrangeId === arrangedId;
  const [form] = Form.useForm();
  const [filterLabel, setFilterLabel] = useState('');

  const { getFilterLabel, formattedFromDate, formattedToDate } = useDateArrange(form, appLang);

  const getFilters = () => ({
    [filterFromName]: formattedFromDate,
    [filterToName]: formattedToDate,
  });

  const { applyArranges, removeFilter } = useProjectArrangeRequest({
    arrangedId,
    getFilterLabel,
    getFilters,
    form,
    setFilterLabel,
    isDate: true,
  });

  return useDateColumn({
    title,
    applyArranges,
    removeFilter,
    arrangedId,
    isActiveArrange,
    form,
    sortOrder: sorting.sortOrder,
    filterLabel,
    ...headerCellProps,
  });
};
