import React, {FC} from 'react';

interface Props {
    fill: string
}

const TopLeftItem: FC<Props> = ({fill}) => (

    <svg width="298" height="321" viewBox="0 0 298 321" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M297.767 0.417297V304.532C297.767 313.369 290.604 320.532 281.767 320.532H16.7671C7.93053 320.532 0.76709 313.369 0.76709 304.532V187.462C0.829975 176.054 6.96304 165.537 16.8719 159.866L289.872 3.62851C292.382 2.19187 295.039 1.12146 297.767 0.417297Z"
              fill={fill}/>
    </svg>
);

export default TopLeftItem;