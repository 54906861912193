import { FC, ReactNode } from 'react';
import { Modal as AntModal, ButtonProps, ModalProps, Typography } from 'antd';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcon.svg';
import { Button } from '../Button';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';

const APP_MODAL_DEFAULT_WIDTH = 612; // px

const { Title } = Typography;

type Props = {
  open: boolean;
  titleProps: { title: string; subTitle?: string; className?: string };
  actionButtonProps: ButtonProps;
  children: ReactNode;
} & ModalProps;

export const Modal: FC<Props> = (props) => {
  const { t } = useTranslation();
  const {
    children,
    titleProps: { title, subTitle, className },
    cancelButtonProps,
    actionButtonProps,
    ...rest
  } = props;

  const titleComponent = (
    <Title level={3} className={className}>
      {title}
    </Title>
  );
  const closeIcon = <CrossIcon className="secondary_color" />;
  const footer = (
    <div className={styles.footer}>
      <Button type="default" {...cancelButtonProps}>
        {t('generic.cancel')}
      </Button>
      <Button {...actionButtonProps}>{props.okText || t('generic.save')}</Button>
    </div>
  );

  return (
    <AntModal
      className={styles.root}
      centered
      destroyOnClose
      title={titleComponent}
      footer={footer}
      closeIcon={closeIcon}
      width={APP_MODAL_DEFAULT_WIDTH}
      onCancel={cancelButtonProps?.onClick}
      {...rest}
    >
      {subTitle && <div>{subTitle}</div>}
      {children}
    </AntModal>
  );
};
