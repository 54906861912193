import { FC, useMemo } from 'react';
import { Card, Form, FormInstance, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemTitle } from '../FormItemTitle';
import { FormSection } from '../../../../components/FormSection';
import { FormItemName } from '../../../../lib/models/Form';
import { FormItem } from '../../../../components/FormItem';
import { AppSelect } from '../../../../components/AppSelect';
import { EMAIL_PATTERN } from '../../../../lib/utils/regexp';
import {
  useGetAllProductCategoriesQuery,
  useGetProductsForFilteringQuery,
  useGetProductsPriorityQuery,
} from '../../../../lib/store/api/products';
import { useParams } from 'react-router-dom';
import { ProductStatus } from '../../types';
import { InfoIcon } from '../../../../assets/icons/InfoIcon';
import { Status, StatusType } from '../../../../features/table/components/Status';
import { useAppSelector } from '../../../../lib/store/hooks';

import styles from './index.module.scss';
import { Visibility } from '../../../../lib/types/product';

const formItems = 'pages.products.productConfigurator.formItems';
const statusT = 'shared.status';

type Props = {
  form: FormInstance;
};

export const GeneralInfoCard: FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const { id: tenantId, modelId: projectId } = useParams();
  const selectedProduct = useAppSelector(({ products }) => products.selectedProduct);

  const category = Form.useWatch(FormItemName.CATEGORY, form);
  const status = Form.useWatch(FormItemName.ACTIVATED, form);

  const { data: categories } = useGetAllProductCategoriesQuery({
    tenantId: String(tenantId),
    projectId: String(projectId),
  });
  const { data: products } = useGetProductsForFilteringQuery({
    tenantId: String(tenantId),
    projectId: String(projectId),
    search: '',
    searchFieldName: '',
  });

  const { data: priorities } = useGetProductsPriorityQuery(
    {
      tenantId: String(tenantId),
      categoryId: String(category?.value),
    },
    { skip: !category, refetchOnMountOrArgChange: true },
  );

  const handleChangeCategory = () => {
    form.resetFields([FormItemName.PRIORITY]);
  };

  const handleChangeStatus = ({ value }: { value: ProductStatus }) => {
    if (value === ProductStatus.DEACTIVATED) {
      form.resetFields([FormItemName.PRIORITY]);
    }
  };

  const categoryOptions = categories?.map((item) => ({
    title: item.name,
    value: item.id,
  }));

  const prioritiesOptions = useMemo(() => {
    if (priorities) {
      let options = [...priorities];

      if (selectedProduct?.categoryId === category?.value && selectedProduct?.priority && priorities) {
        options = [...options, selectedProduct.priority].sort();
      }

      return options?.map((item) => ({
        title: item,
        value: item,
      }));
    }
  }, [priorities, selectedProduct, category]);

  const statusOptions = [
    {
      value: ProductStatus.ACTIVATED,
      title: <Status textValue={t(`${statusT}.activated`)} type={StatusType.GREEN} />,
    },
    {
      value: ProductStatus.DEACTIVATED,
      title: <Status textValue={t(`${statusT}.deactivated`)} type={StatusType.GRAY} />,
    },
  ];

  const productsOptions = useMemo(() => {
    return products?.content?.map((item) => ({
      title: item.name,
      value: item.id,
    }));
  }, [products]);

  const demandAreaName = useMemo(() => {
    if (categories && category) {
      return categories?.find((item) => item?.id === category?.value)?.demandArea?.name;
    }
  }, [categories, category]);

  const isCategoryInvisible = useMemo(() => {
    return categories?.find((item) => item?.id === category?.value)?.visibility === Visibility.NEVER;
  }, [category, categories]);

  return (
    <Card className={styles.root}>
      <div className={styles.section}>
        <div>
          <FormItemTitle>{t(`${formItems}.productId.title`)}</FormItemTitle>
          <FormSection
            formItemProps={{
              hasFeedback: true,
              name: FormItemName.PRODUCT_ID,
              rules: [
                { required: true, message: t(`${formItems}.productId.errors.empty`) },
                { max: 40, type: 'string', message: t(`${formItems}.productId.errors.length`) },
              ],
            }}
            textFieldProps={{
              placeholder: t(`${formItems}.productId.label`),
            }}
          />
        </div>
        <div>
          <FormItemTitle>{t(`${formItems}.status.title`)}</FormItemTitle>
          <FormItem
            hasFeedback
            name={FormItemName.ACTIVATED}
            rules={[{ required: true }]}
            initialValue={statusOptions[0]}
          >
            <AppSelect options={statusOptions} onChange={handleChangeStatus} />
          </FormItem>
        </div>
      </div>
      <FormItemTitle>
        <>
          {t(`${formItems}.statusComment.title`)}
          <Tooltip
            title={t(`${formItems}.statusComment.tooltip`)}
            overlayInnerStyle={{
              width: 400,
            }}
          >
            <InfoIcon className={styles.tooltipIcon} />
          </Tooltip>
        </>
      </FormItemTitle>

      <FormSection
        formItemProps={{
          hasFeedback: true,
          name: FormItemName.STATUS_COMMENT,
          rules: [{ max: 1000, type: 'string', message: t(`${formItems}.statusComment.error`) }],
        }}
        textFieldProps={{
          placeholder: t(`${formItems}.statusComment.label`),
        }}
      />

      <div className={styles.section}>
        <div>
          <FormItemTitle>{t(`${formItems}.demandArea.title`)}</FormItemTitle>
          <FormSection
            formItemProps={{
              hasFeedback: true,
            }}
            textFieldProps={{
              placeholder: t(`${formItems}.demandArea.label`),
              disabled: true,
              value: demandAreaName,
            }}
          />
        </div>
        <div>
          <FormItemTitle>{t(`${formItems}.productCategory.title`)}</FormItemTitle>
          <FormItem
            hasFeedback
            name={FormItemName.CATEGORY}
            rules={[{ required: true, message: t(`${formItems}.productCategory.error`) }]}
          >
            <AppSelect
              options={categoryOptions}
              placeholder={t(`${formItems}.productCategory.label`)}
              onChange={handleChangeCategory}
            />
          </FormItem>
        </div>
        <div>
          <FormItemTitle>{t(`${formItems}.priority.title`)}</FormItemTitle>
          <FormItem
            hasFeedback
            name={FormItemName.PRIORITY}
            rules={[
              {
                required: status?.value === ProductStatus.ACTIVATED,
                message: t(`${formItems}.priority.errors.empty`),
              },
            ]}
          >
            <AppSelect
              disabled={!category || status.value === ProductStatus.DEACTIVATED}
              options={prioritiesOptions}
              placeholder={t(`${formItems}.priority.label`)}
            />
          </FormItem>
        </div>
      </div>

      <div className={styles.section}>
        <div>
          <FormItemTitle>{t(`${formItems}.email.title`)}</FormItemTitle>
          <FormSection
            formItemProps={{
              hasFeedback: true,
              name: FormItemName.EMAIL,
              rules: [
                {
                  pattern: EMAIL_PATTERN,
                  message: t(`${formItems}.email.error`),
                },
              ],
            }}
            textFieldProps={{
              placeholder: t(`${formItems}.email.label`),
            }}
          />
        </div>
        <div>
          <FormItemTitle>{t(`${formItems}.link.title`)}</FormItemTitle>
          <FormSection
            formItemProps={{
              hasFeedback: true,
              name: FormItemName.LINK,
              rules: [{ type: 'url', message: t(`${formItems}.link.error`) }],
            }}
            textFieldProps={{
              placeholder: t(`${formItems}.link.label`),
            }}
          />
        </div>
      </div>

      {isCategoryInvisible && (
        <div className={styles.section}>
          <div>
            <FormItemTitle>{t(`${formItems}.associatedProduct.title`)}</FormItemTitle>
            <FormItem
              hasFeedback
              name={FormItemName.ASSOCIATED_PRODUCT}
              normalize={(val: { value: any }) => val?.value}
            >
              <AppSelect options={productsOptions} placeholder={`${formItems}.associatedProduct.label`} />
            </FormItem>
          </div>
        </div>
      )}
    </Card>
  );
};
