export enum QueryParams {
  TAB = 'tab',
}
export enum Tabs {
  DESIGN_SETTINGS = 'designSettings',
  BRAND_KIT = 'brandKit',
  DEMAND_AREAS = 'demandAreas',
  PRODUCT_CATEGORIES = 'productCategories',
  PRODUCTS = 'products',
  PRODUCT_CONFIGURATOR = 'configurator',
  STATUS_LEVEL_CONFIGURATOR = 'statusLevelConfigurator',
  STATUS_LEVEL = 'statusLevel',
  BONUS_CATALOG = 'bonusCatalog',
  BADGES = 'badges',
  RULE_CONFIGURATOR = 'ruleConfigurator',
  RULES = 'rules',
  RULE_TYPES = 'ruleTypes',
}
