import { useTranslation } from 'react-i18next';
import { TENANT_COLUMN } from '../helpers/constants';
import { useTenantSearchDataColumn } from './useTenantSearchDataColumn';
import { Typography } from 'antd';

import tableStyles from '../../../components/Table/index.module.scss';

const { Text } = Typography;

const genericCommentColumnProps = {
  width: 150,
  render: (text: string) => <Text>{text ?? '-'}</Text>,
};

const phone = 'pages.tenants.columns.phone';

export const useTenantPhoneColumn = () => {
  const { t } = useTranslation();

  return useTenantSearchDataColumn({
    title: t(phone),
    arrangedId: TENANT_COLUMN.PHONE,
    searchFieldName: 'searchByClientPhone',
    filterFieldName: 'includeClientPhones',
    columnProps: genericCommentColumnProps,
    headerCellProps: {
      rootProps: { className: tableStyles.hiddenHeaderCell },
    },
  });
};
