import { api, TAGS_KEY } from './';
import { GLOBAL_SETTINGS_NAMES } from '../../utils/constants';
import { ServerFontItem } from '../../types/colors&fonts';

type GetBrandKitPayload = {
  projectId: string | number;
  tenantId: string | number;
};

type BrandKitPayload = GetBrandKitPayload & {
  settings: string;
};

type BrandKitResponse = { name: GLOBAL_SETTINGS_NAMES; projectId: number; settings: string }[];

const brandKitApi = api.injectEndpoints({
  endpoints: (build) => ({
    brandKit: build.mutation<void, BrandKitPayload>({
      query: ({ projectId, settings, tenantId }) => ({
        url: 'global-settings',
        method: 'POST',
        body: {
          projectId,
          name: GLOBAL_SETTINGS_NAMES.DESIGN_SETTINGS_BRAND_KIT,
          settings,
        },
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      invalidatesTags: [TAGS_KEY.BRAND_KIT],
    }),

    getBrandKitData: build.query<BrandKitResponse, GetBrandKitPayload>({
      query: ({ projectId, tenantId }) => ({
        url: `global-settings/${projectId}?settingsName=${GLOBAL_SETTINGS_NAMES.DESIGN_SETTINGS_BRAND_KIT}`,
        method: 'GET',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),

      providesTags: [TAGS_KEY.BRAND_KIT],
    }),

    getFonts: build.query<ServerFontItem[], { tenantId: string | number }>({
      query: ({ tenantId }) => ({
        url: `font/listFonts`,
        method: 'GET',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),

      providesTags: [TAGS_KEY.BRAND_KIT_FONTS],
    }),
    sendFont: build.mutation<ServerFontItem, { tenantId: string | number; body: FormData }>({
      query: ({ tenantId, body }) => ({
        url: 'font',
        method: 'POST',
        body,
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      invalidatesTags: [TAGS_KEY.BRAND_KIT_FONTS],
    }),
  }),
});

export const { useBrandKitMutation, useGetBrandKitDataQuery, useGetFontsQuery, useSendFontMutation } =
  brandKitApi;
