import { CAROUSEL_MAIN_SETTINGS } from '../helpers/utils';

type Props = {
  windowWidth: number;
  preSelectedCardIndex: number;
};

export function useGetCarouselSettings({ preSelectedCardIndex = 0 }: Props) {
  return {
    ...CAROUSEL_MAIN_SETTINGS,
    initialSlide: preSelectedCardIndex,
  };
}
