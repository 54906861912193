import {
  DEFAULT_SIZE,
  PRODUCT_SIZE_DESKTOP,
  PRODUCT_SIZE_TABLET,
  PRODUCT_SIZE_TABLET_LANDSCAPE,
} from '../types';
import { ProductRecommendationPriorities, ProductT } from '../../../lib/types/product';
import { StatusLevelT } from '../../../lib/types/statusLevel';
import { calculatePointsForProduct } from '../../../lib/utils/end-user-helpers';
import { getProductPoints } from '../../../features/endUser/utils/helpers';

type Params = {
  productsLength: number;
  isLandscape: boolean;
  isTablet: boolean;
  isDesktopOrLaptop: boolean;
};

export const getProductSize = ({ productsLength, isLandscape, isTablet, isDesktopOrLaptop }: Params) => {
  switch (productsLength) {
    case 2:
      if (isDesktopOrLaptop) {
        return PRODUCT_SIZE_DESKTOP.LARGE;
      }

      if (isLandscape && isTablet) {
        return PRODUCT_SIZE_TABLET_LANDSCAPE.LARGE;
      }

      if (isTablet) {
        return PRODUCT_SIZE_TABLET.LARGE;
      }

      return DEFAULT_SIZE;
    case 3:
      if (isDesktopOrLaptop) {
        return PRODUCT_SIZE_DESKTOP.MEDIUM;
      }
      if (isLandscape && isTablet) {
        return PRODUCT_SIZE_TABLET_LANDSCAPE.MEDIUM;
      }

      if (isTablet) {
        return PRODUCT_SIZE_TABLET.MEDIUM;
      }

      return DEFAULT_SIZE;
    case 4:
      if (isDesktopOrLaptop) {
        return PRODUCT_SIZE_DESKTOP.SMALL;
      }
      if (isLandscape && isTablet) {
        return PRODUCT_SIZE_TABLET_LANDSCAPE.SMALL;
      }

      if (isTablet) {
        return PRODUCT_SIZE_TABLET.SMALL;
      }

      return DEFAULT_SIZE;
    default:
      return DEFAULT_SIZE;
  }
};

export const getCurrentStatusLevel = (userPoints: number, statusLevels: StatusLevelT[]) => {
  const subLevelsWithStatusLevel = statusLevels
    .flatMap((statusLevel: StatusLevelT) => {
      const firstSubLevel = statusLevel.subLevels[0];
      return firstSubLevel
        ? { ...firstSubLevel, statusLevelName: statusLevel.name, statusLevelId: statusLevel.id }
        : null;
    })
    .filter(Boolean);

  const sortedLevels = [...subLevelsWithStatusLevel.sort((a: any, b: any) => b.points - a.points)];

  const userCoveredLevels = sortedLevels.filter((level: any) => userPoints >= level.points);
  const currentLevel =
    userCoveredLevels.length > 0 ? userCoveredLevels[0] : sortedLevels[sortedLevels.length - 1];

  return currentLevel;
};

export const findNextStatusLevel = (statusLevels: StatusLevelT[], userPoints: number, product: ProductT) => {
  const productPoints = getProductPoints(product);

  const totalPoints = userPoints + productPoints;
  const maxAvailablePoints =
    statusLevels?.[statusLevels?.length - 1]?.subLevels?.[
      statusLevels?.[statusLevels?.length - 1]?.subLevels?.length - 1
    ]?.points;

  const currentStatusLevel = getCurrentStatusLevel(Number(userPoints), statusLevels);

  const levels = statusLevels.filter((level) => {
    const minValue = level.subLevels[0].points;

    return currentStatusLevel?.statusLevelId !== level.id && totalPoints >= minValue;
  });

  const lastLevelIndex = levels?.length - 1;
  const lastSubLevelIndex = levels?.[lastLevelIndex]?.subLevels?.length - 1;
  const maxValue = levels?.[lastLevelIndex]?.subLevels?.[lastSubLevelIndex]?.points;
  const realTotalPoints = totalPoints >= maxAvailablePoints ? maxAvailablePoints : totalPoints;

  if (!levels?.length || maxValue < realTotalPoints) {
    return null;
  }

  return levels?.[levels?.length - 1];
};

const findMaxPriorityProduct = (array: ProductT[]) => {
  if (array.length === 0) {
    return null;
  }
  return array.reduce((prev, current) => (prev.priority > current.priority ? prev : current));
};

const findMinPriorityProduct = (array: ProductT[]) => {
  if (array.length === 0) {
    return null;
  }

  return array.reduce((prev, current) => (prev.priority < current.priority ? prev : current));
};

const findHighestProductPoints = (array: ProductT[]) => {
  if (array.length === 0) {
    return null;
  }

  const highestPointsProduct = array.reduce((prevProduct: ProductT, currentProduct: ProductT) => {
    let countPrevProducts = Number(prevProduct.countUserProducts);
    let countCurrentProducts = Number(currentProduct.countUserProducts);

    if (prevProduct.countSpecialProducts !== null && prevProduct.countSpecialProducts !== undefined) {
      countPrevProducts += Number(prevProduct.countSpecialProducts);
    }

    if (currentProduct.countSpecialProducts !== null && currentProduct.countSpecialProducts !== undefined) {
      countCurrentProducts += Number(currentProduct.countSpecialProducts);
    }

    const prevProductPoints: number = calculatePointsForProduct(prevProduct, countPrevProducts);
    const currentProductPoints: number = calculatePointsForProduct(currentProduct, countCurrentProducts);

    if (currentProductPoints > prevProductPoints) {
      return currentProduct;
    }
    return prevProduct;
  });

  return highestPointsProduct;
};

const findLowestProductPoints = (array: ProductT[]) => {
  if (array.length === 0) {
    return null;
  }

  const lowestPointsProduct = array.reduce((prevProduct: ProductT, currentProduct: ProductT) => {
    let countPrevProducts = Number(prevProduct.countUserProducts);
    let countCurrentProducts = Number(currentProduct.countUserProducts);

    if (prevProduct.countSpecialProducts !== null && prevProduct.countSpecialProducts !== undefined) {
      countPrevProducts += Number(prevProduct.countSpecialProducts);
    }

    if (currentProduct.countSpecialProducts !== null && currentProduct.countSpecialProducts !== undefined) {
      countCurrentProducts += Number(currentProduct.countSpecialProducts);
    }

    const prevProductPoints: number = calculatePointsForProduct(prevProduct, countPrevProducts);
    const currentProductPoints: number = calculatePointsForProduct(currentProduct, countCurrentProducts);

    if (currentProductPoints < prevProductPoints) {
      return currentProduct;
    }
    return prevProduct;
  });

  return lowestPointsProduct;
};

export const findProductRecommendationByPriority = (
  products: ProductT[],
  priority?: ProductRecommendationPriorities,
) => {
  switch (priority) {
    case ProductRecommendationPriorities.HighestToLowestPriority:
      return findMaxPriorityProduct(products);
    case ProductRecommendationPriorities.LowestToHighestPriority:
      return findMinPriorityProduct(products);
    case ProductRecommendationPriorities.HighestToLowestProductPoints:
      return findHighestProductPoints(products);
    case ProductRecommendationPriorities.LowestToHighestProductPoints:
      return findLowestProductPoints(products);
  }

  return null;
};
