import { FC } from 'react';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import styles from './index.module.scss';

type Props = {
  name: string;
  text: string;
  title: string;
  picture: any;
};

export const BonusCard: FC<Props> = ({ text, title, picture }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.imgWrapper}>
        <img className={styles.img} src={picture?.s3Url} alt="bonus-bg" />
      </div>
      <div className={styles.textWrapper}>
        <div style={{ marginBottom: 8 }}>
          <EndUserTypography type="headlineFourth" component="Text" className={styles.title}>
            {title}
          </EndUserTypography>
        </div>
        <EndUserTypography
          ellipsis={{ rows: 5 }}
          className={styles.paragraph}
          type="subText"
          component="Paragraph"
        >
          {text}
        </EndUserTypography>
      </div>
    </div>
  );
};
