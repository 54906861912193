import { ChangeEvent, FC } from 'react';
import { FormItemName } from '../../../../../lib/models/Form';
import { FormInput } from '../../../../../components/FormInput';
import { FormItem } from '../../../../../components/FormItem';
import { FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';

const userManagement = 'pages.userManagement';

type Props = {
  form: FormInstance;
};

export const FullNameFormItem: FC<Props> = ({ form }) => {
  const { t } = useTranslation();

  const generateUsernameFromFullName = (fullName: string) => {
    const words = fullName.split(' ');
    const firstName = words[0];
    const initials = words.slice(1).map((word) => word.charAt(0).toUpperCase());

    return [firstName, ...initials].join('.');
  };

  const onFullNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    form.setFieldValue(FormItemName.USERNAME, generateUsernameFromFullName(email));
  };

  return (
    <FormItem name={FormItemName.FULL_NAME} hasFeedback={true} rules={[{ required: true }, { max: 50 }]}>
      <FormInput onChange={onFullNameChange} label={t(`${userManagement}.formItems.fullName`)} />
    </FormItem>
  );
};
