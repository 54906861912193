import { Typography, Tooltip } from 'antd';
import styles from './../styles.module.scss';
import { useTranslation } from 'react-i18next';
import { STATUS_LEVEL_COLUMN } from '../helpers/consts';
import { BonusT } from '../../../lib/types/statusLevel';
import { TableHeaderCell } from '../../../components/Table/TableHeaderCell/TableHeaderCell';

const { Text } = Typography;

const bonuses = 'pages.statusLevel.columns.bonuses';

export const useStatusLevelBonusesColumn = () => {
  const { t } = useTranslation();

  return {
    title: <TableHeaderCell withArrange={false} title={t(bonuses)} />,
    dataIndex: STATUS_LEVEL_COLUMN.SUBLEVELS,
    render: (data: { id: number; bonuses: BonusT[] }[]) => {
      return (
        <div className={styles.subLevelsContainer}>
          {data.map((item) => {
            const allBonuses = item.bonuses.map((bonus, index, array) => {
              const isLast = index === array.length - 1;

              return `${bonus.title}${!isLast ? ', ' : ''}`;
            });

            return (
              <div style={{ width: 700 }} className={styles.subLevelItem} key={item.id}>
                <Tooltip
                  overlayInnerStyle={{
                    width: 400,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  title={allBonuses}
                  placement="topLeft"
                >
                  <Text ellipsis={true}>{allBonuses}</Text>
                </Tooltip>
              </div>
            );
          })}
        </div>
      );
    },
  };
};
