import { FC } from 'react';
import { FormItem } from '../../../../components/FormItem';
import { useTranslation } from 'react-i18next';
import { FormItemName } from '../../../../lib/models/Form';
import { AppSelect } from '../../../../components/AppSelect';
import { useGetUsersForTenantsQuery } from '../../../../lib/store/api/tenants';

const label = 'unique.formItems.user.label';

export const UsersFormItem: FC = () => {
  const { t } = useTranslation();

  const { data } = useGetUsersForTenantsQuery({});

  return (
    <FormItem hasFeedback={true} name={FormItemName.USERS} rules={[{ required: false }]}>
      <AppSelect isMultiSelect options={data} label={t(label)} />
    </FormItem>
  );
};
