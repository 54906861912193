import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { Table } from '../../../../components/Table';
import { TablePagination } from '../../../../lib/types/pagination';
import { normalizePageForRequest, normalizePageForTable } from '../../../../lib/utils/helpers';
import { useBonusDescriptionColumn } from './useBonusDescriptionColumn';
import { useBonusTitleColumn } from './useBonusTitleColumn';
import { useBonusCatalogActionsColumn } from './useBonusCatalogActionsColumn';
import { useGetBonusCatalogQuery } from '../../../../lib/store/api/bonusCatalog';
import { GetBonusCatalogPayloadT } from '../../../../lib/types/bonusCatalog';
import { useBonusCatalogColumn } from './useBonusCatalogColumn';
import { useBonusCatalogPictureColumn } from './useBonusCatalogPictureColumn';
import { setPage } from '../../../../lib/store/slices/bonusCatalogSlice';
import { useBonusCatalogStatusColumn } from './useBonusCatalogStatusColumn';

type Props = {
  tenantId: string;
  modelId: string;
};

export const BonusCatalogTable = (props: Props) => {
  const dispatch = useAppDispatch();

  const page = useAppSelector(({ bonusCatalog }) => bonusCatalog.page);

  const filters = useAppSelector(({ bonusCatalog }) => {
    const arrangedId = bonusCatalog.arrangeId;
    return { arrangedId, ...bonusCatalog.filtering, ...bonusCatalog.sorting };
  });

  const { data, isLoading, isFetching } = useGetBonusCatalogQuery(
    {
      tenantId: String(props.tenantId),
      projectId: String(props.modelId),
      page: normalizePageForRequest(page || 1),
      ...filters,
    } as GetBonusCatalogPayloadT,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const dataSource = data?.content || [];

  const responsePagination: TablePagination = {
    pageSize: data?.pageable.pageSize,
    current: normalizePageForTable(data?.pageable?.pageNumber || 0),
    total: data?.totalElements,
  };

  const columns = [
    useBonusCatalogColumn(),
    useBonusTitleColumn(),
    useBonusDescriptionColumn(),
    useBonusCatalogStatusColumn(),
    useBonusCatalogPictureColumn(),
    useBonusCatalogActionsColumn(),
  ];

  useEffect(() => {
    if (data?.numberOfElements === 0 && page !== 1) {
      dispatch(setPage(page - 1));
    }
  }, [data?.numberOfElements]);

  return (
    <Table
      columns={columns}
      rowKey="id"
      dataSource={dataSource}
      pagination={responsePagination}
      loading={isLoading || isFetching}
      scroll={{ x: 'max-content' }}
      onChange={(tablePagination) => {
        dispatch(setPage(tablePagination.current as number));
      }}
    />
  );
};
