import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { normalizePageForRequest, normalizePageForTable } from '../../../../lib/utils/helpers';
import { TablePagination } from '../../../../lib/types/pagination';
import { Table } from '../../../../components/Table';
import { useProductNameColumn } from '../../hooks/useProductNameColumn';
import { useProductIdColumn } from '../../hooks/useProductIdColumn';
import { useProductPointsColumn } from '../../hooks/useProductPointsColumn';
import { useProductCategoryColumn } from '../../hooks/useProductCategoryColumn';
import { useProductPriorityColumn } from '../../hooks/useProductPriorityColumn';
import { useProductDemandAreaColumn } from '../../hooks/useProductDemandAreaColumn';
import { useProductPictureColumn } from '../../hooks/useProductPictureColumn';
import { useProductStatusColumn } from '../../hooks/useProductStatusColumn';
import { useProductActionsColumn } from '../../hooks/useProductActionsColumn';

import styles from '../../../productCategories/components/ProductCategoriesTable/styles.module.scss';
import { useGetProductsQuery } from '../../../../lib/store/api/products';
import { GetProductsPayloadT } from '../../../../lib/types/product';
import { setCurrentProduct, setPage } from '../../../../lib/store/slices/productsSlice';

export const ProductsTable: FC = () => {
  const { id: tenantId, modelId } = useParams();

  const page = useAppSelector(({ products }) => products.page);

  const dispatch = useAppDispatch();

  const filters = useAppSelector(({ products }) => {
    const arrangedId = products.arrangeId;
    return { arrangedId, ...products.filtering, ...products.sorting };
  });

  useEffect(() => {
    dispatch(setCurrentProduct(null));
  }, [dispatch]);

  const { data, isLoading, isFetching } = useGetProductsQuery(
    {
      tenantId: String(tenantId),
      projectId: modelId,
      page: normalizePageForRequest(page || 1),
      ...filters,
    } as GetProductsPayloadT,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const responsePagination: TablePagination = {
    pageSize: data?.pageable.pageSize,
    current: normalizePageForTable(data?.pageable?.pageNumber || 0),
    total: data?.totalElements,
  };

  const dataSource = data?.content || [];

  const columns = [
    useProductNameColumn(),
    useProductIdColumn(),
    useProductPointsColumn(),
    useProductCategoryColumn(),
    useProductPriorityColumn(),
    useProductDemandAreaColumn(),
    useProductPictureColumn(),
    useProductStatusColumn(),
    useProductActionsColumn(),
  ];

  useEffect(() => {
    if (data?.numberOfElements === 0 && page !== 1) {
      dispatch(setPage(page - 1));
    }
  }, [data?.numberOfElements]);

  return (
    <>
      <Table
        columns={columns}
        rowKey="id"
        className={styles.table}
        dataSource={dataSource}
        pagination={responsePagination}
        loading={isLoading || isFetching}
        scroll={{ x: 'max-content' }}
        onChange={(tablePagination) => {
          dispatch(setPage(tablePagination.current as number));
        }}
      />
    </>
  );
};
