import { FC, ReactNode } from 'react';
import { Popover } from 'antd';
import { SortIcon } from './SortIcon';

import styles from './index.module.scss';

type Props = {
  isActiveArrange: boolean;
  children: ReactNode;
  sortOrder: any;
  arrangedId?: string;
};

export const ArrangeButtonWrapper: FC<Props> = ({ isActiveArrange, sortOrder, children, arrangedId }) => {
  return (
    <Popover
      trigger="click"
      content={children}
      placement="bottomRight"
      overlayStyle={popoverStyles.card}
      overlayInnerStyle={popoverStyles.inner}
      destroyTooltipOnHide
    >
      <button id={arrangedId} className={styles.sortBtn}>
        <SortIcon isActiveArrange={isActiveArrange} sortOrder={sortOrder} />
      </button>
    </Popover>
  );
};

const popoverStyles = {
  card: {
    width: '240px',
  },
  inner: {
    padding: 16,
  },
}; // replace with className when UI be ready
