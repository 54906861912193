import { useEffect, useState, useCallback, MutableRefObject } from 'react';
import { FontItemT, FontStyles } from '../../types/colors&fonts';
import { useAppSelector } from '../../store/hooks';

export const useTextLineCount = (
  titles: string[],
  containerWidth: number,
  type: keyof FontStyles,
  hiddenContainerRef: MutableRefObject<HTMLDivElement | null>,
): number => {
  const [maxLineCount, setMaxLineCount] = useState(0);

  const { brandKit } = useAppSelector(({ endUser }) => endUser);

  const getFontStyling = useCallback((font?: FontItemT) => {
    if (!font) {
      return null;
    }

    const fileName = font?.font?.split('.')?.[0];

    return {
      fontFamily: fileName?.includes(',') ? fileName : `'${fileName}'`,
      fontSize: font.fontSize,
      fontWeight: font.fontWeight,
    };
  }, []);

  useEffect(() => {
    if (hiddenContainerRef.current) {
      const style = getFontStyling(brandKit?.fonts[type]);

      if (style) {
        hiddenContainerRef.current.style.width = `${containerWidth}px`;
        hiddenContainerRef.current.style.fontFamily = style.fontFamily;
        hiddenContainerRef.current.style.fontSize = `${style.fontSize}px`;
        hiddenContainerRef.current.style.fontWeight = `${style.fontWeight}`;

        const counts = titles.map((title) => {
          hiddenContainerRef.current!.textContent = title;
          const containerHeight = hiddenContainerRef.current!.offsetHeight;
          const singleLineHeight = parseFloat(getComputedStyle(hiddenContainerRef.current!).lineHeight);

          return Math.floor(containerHeight / singleLineHeight);
        });

        setMaxLineCount(Math.max(...counts));
      }
    }
  }, [titles, containerWidth, getFontStyling, brandKit?.fonts, type, hiddenContainerRef]);

  return maxLineCount > 1 ? 2 : 1;
};
