import React, { FC } from 'react';
import { FormInstance, Typography } from 'antd';

import styles from './styles.module.scss';
import { AppCardBasic } from '../../../../../components/AppCardBasic';

import { FormItem } from '../../../../../components/FormItem';
import { FormItemName } from '../../../../../lib/models/Form';
import { FormInput } from '../../../../../components/FormInput';
import { useTranslation } from 'react-i18next';
import { AppSelect } from '../../../../../components/AppSelect';
import { useGetTemplatesQuery } from '../../../../../lib/store/api/project';
import { useParams } from 'react-router-dom';

const { Title } = Typography;

const formItems = 'pages.settings.formItems';
const title = 'pages.settings.basic.title';
const modelNameEmptyError = 'pages.settings.errors.modelNameEmptyError';

type Props = {
  form: FormInstance;
};

export const BasicCardData: FC<Props> = () => {
  const { t } = useTranslation();

  const { id: tenantId } = useParams();

  const { data } = useGetTemplatesQuery({ tenantId: String(tenantId) });

  const templateOptions = data?.content.map((template) => ({ title: template.name, value: template.id }));

  return (
    <AppCardBasic>
      <div className={styles.header}>
        <Title className={styles.title} level={3}>
          {t(title)}
        </Title>
      </div>
      <div className={styles.row}>
        <FormItem
          style={{ width: 471, minHeight: 72 }}
          name={FormItemName.PROJECT_NAME}
          hasFeedback={true}
          rules={[{ required: true, message: t(modelNameEmptyError) }, { max: 128 }]}
        >
          <FormInput label={t(`${formItems}.projectName`)} />
        </FormItem>

        <FormItem
          style={{ width: 400, minHeight: 72 }}
          rules={[{ required: true }]}
          name={FormItemName.SELECTED_TEMPLATE}
          hasFeedback={true}
        >
          <AppSelect label={t(`${formItems}.selectedTemplate`)} options={templateOptions} />
        </FormItem>
      </div>
      <FormItem name={FormItemName.CREATION_DATE} hasFeedback={true}>
        <FormInput disabled={true} label={t(`${formItems}.creationDate`)} />
      </FormItem>
    </AppCardBasic>
  );
};
