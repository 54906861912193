import { FC } from 'react';
import { Button } from '../../../../components/Button';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { setModalOpened } from '../../../../lib/store/slices/productsSlice';
import { ModalActions } from '../../../../lib/models/Modal';

import styles from './index.module.scss';

type Props = {
  disabledSaveButton: boolean;
};

export const ActionButtons: FC<Props> = ({ disabledSaveButton }) => {
  const { t } = useTranslation();
  const selectedProduct = useAppSelector(({ products }) => products.selectedProduct);
  const dispatch = useAppDispatch();

  const handleDeleteClick = async () => {
    dispatch(setModalOpened(ModalActions.DELETE));
  };

  return (
    <div className={styles.wrapper}>
      {selectedProduct && (
        <Button
          className={styles.deleteBtn}
          type="default"
          size="small"
          disabled={disabledSaveButton}
          onClick={handleDeleteClick}
        >
          {t('generic.delete')}
        </Button>
      )}
      <Button htmlType="submit" className={styles.saveBtn} size="small" disabled={disabledSaveButton}>
        {t('generic.save')}
      </Button>
    </div>
  );
};
