import { useAppDispatch } from '../../../lib/store/hooks';
import { ModalActions } from '../../../lib/models/Modal';
import { setCurrentRule, setModalOpened } from '../../../lib/store/slices/ruleBuilder';
import { RuleT } from '../../../lib/types/ruleBuilder';
import { useParams, useSearchParams } from 'react-router-dom';
import { QueryParams, Tabs } from '../../../lib/types/queryParams';
import { useUpdateRuleMutation } from '../../../lib/store/api/ruleBuilder';
import { MessageInstance } from 'antd/es/message/interface';
import { useTranslation } from 'react-i18next';

const activate = 'pages.ruleBuilder.messages.status.activate';
const deactivate = 'pages.ruleBuilder.messages.status.deactivate';
const errorText = 'pages.ruleBuilder.messages.status.error';

export const useRulesActionsRequest = (messageApi?: MessageInstance) => {
  const dispatch = useAppDispatch();
  const [, setSearchParams] = useSearchParams();
  const [updateRuleStatus] = useUpdateRuleMutation();
  const { t } = useTranslation();
  const { id: tenantId, modelId } = useParams();

  const onDeleteRule = (rule: RuleT) => {
    dispatch(setCurrentRule(rule));
    dispatch(setModalOpened(ModalActions.DELETE));
  };
  const onUpdateRule = (rule: RuleT) => {
    dispatch(setCurrentRule(rule));
    setSearchParams({ [QueryParams.TAB]: Tabs.RULE_CONFIGURATOR });
  };

  const onUpdateRuleStatus = async (rule: RuleT) => {
    const rulePayload = {
      ...rule,
      projectId: Number(modelId),
      tenantId: String(tenantId),
      ruleId: rule?.id || undefined,
      activated: !rule?.activated,
    };

    try {
      await updateRuleStatus(rulePayload).unwrap();
      messageApi?.success(t(rule?.activated ? deactivate : activate));
    } catch (error) {
      messageApi?.error(t(errorText));
    }
  };

  return { onUpdateRule, onDeleteRule, onUpdateRuleStatus };
};
