import { FormItemName } from '../../../lib/models/Form';
import { UploadMediaResponseT } from '../../../lib/types/media';
import {
  CoverageTypeT,
  Operation,
  RecurringPointsT,
  ScoringRules,
  Visibility,
} from '../../../lib/types/product';

export enum ProductStatus {
  ACTIVATED = 'activated',
  DEACTIVATED = 'deactivated',
}

export enum PointsOperator {
  MORE_EQUAL = '>=',
  EQUALS = '==',
}

export enum Currency {
  USD = 'usd',
  CAD = 'cad',
  CHF = 'chf',
  EUR = 'eur',
  SGD = 'sgd',
}

export type FormButtonT = {
  isButtonEnabled?: boolean;
  text?: string;
  link?: string;
  form?: unknown; // TODO add correct type when it is ready
  operation?: Operation;
};

export type FormValuesButtonT = {
  isButtonEnabled?: boolean;
  text?: string;
  link?: string;
  form?: { value: unknown }; // TODO add correct type when it is ready
  operation?: { value: Operation };
};

export type AgeRangeT = {
  ageRangeCoverage: CoverageTypeT;
  ranges: { from?: number; to?: number }[];
};

export type RegionT = {
  regionCoverage: CoverageTypeT;
  regions: number[];
};

export type ProductConfiguratorFormValuesT = {
  [FormItemName.PRODUCT_ID]: string;
  [FormItemName.ACTIVATED]: { value: ProductStatus; title: string };
  [FormItemName.STATUS_COMMENT]?: string;
  [FormItemName.CATEGORY]: { value: number; title: string };
  [FormItemName.PRIORITY]: { value: number; title: string };
  [FormItemName.EMAIL]?: string;
  [FormItemName.LINK]?: string;
  [FormItemName.BADGE_ID]?: number;
  [FormItemName.ASSOCIATED_PRODUCT]?: number;

  [FormItemName.VISIBILITY]: Visibility;

  [FormItemName.NAME]: string;
  [FormItemName.SUBLINE]?: string;
  [FormItemName.DESCRIPTION]?: string;

  [FormItemName.SCORING_RANGES]: ScoringRules;
  [FormItemName.POINTS]: number;
  [FormItemName.RECURRING_POINTS]?: (Omit<RecurringPointsT, 'operator'> & {
    operator: { title: PointsOperator; value: PointsOperator };
  })[];

  [FormItemName.PRICE]: {
    [FormItemName.AMOUNT]?: number;
    [FormItemName.CURRENCY]?: { value: Currency; title: string };
    [FormItemName.PRICE_DESCRIPTION]?: string;
  };

  // types for additionalInfo (JSON)
  [FormItemName.PICTURE]: UploadMediaResponseT;
  [FormItemName.PRIMARY_BUTTON]: FormValuesButtonT;
  [FormItemName.SECONDARY_BUTTON]: FormValuesButtonT;
  [FormItemName.BULLET_POINTS]: string[];
  [FormItemName.AGE_RANGE]: AgeRangeT;
  [FormItemName.REGION]: RegionT;
};
