import { FC } from 'react';
import { FormItem } from '../../../../components/FormItem';
import { useTranslation } from 'react-i18next';
import { AppSelect } from '../../../../components/AppSelect';
import { ProductCategoryT } from '../../../../lib/types/entities';

const assignedProducts = 'pages.products.productCategories.formItems.assignedProducts';

type Props = {
  products?: ProductCategoryT['products'];
};

export const AssignedProductsFormItem: FC<Props> = ({ products }) => {
  const { t } = useTranslation();

  const values = products?.map((item) => ({ title: item.name, value: item.id }));

  return (
    <FormItem>
      <AppSelect disabled isMultiSelect label={t(`${assignedProducts}.label`)} value={values} />
    </FormItem>
  );
};
