import { useTranslation } from 'react-i18next';
import { ColorBodyCell } from '../../../../features/table/components/ColorBodyCell';
import { DEMAND_AREAS_COLUMN } from '../../../../lib/types/demandArea';
import { TableHeaderCell } from '../../../../components/Table/TableHeaderCell/TableHeaderCell';
import { parseJSON } from '../../../../lib/utils/helpers';

const color = 'pages.products.demandArea.tableItems.color';

export const useDemandsColorColumn = () => {
  const { t } = useTranslation();

  return {
    dataIndex: DEMAND_AREAS_COLUMN.COLOR,
    title: <TableHeaderCell withArrange={false} title={t(color)} />,
    width: 122,
    render: (JsonColor: string) => {
      const color: { name: string; value: string } = parseJSON(JsonColor);
      return <ColorBodyCell color={color.value} />;
    },
  };
};
