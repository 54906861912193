import React, { FC, useCallback } from 'react';
import { Typography } from 'antd';
import cn from 'classnames';
import { nanoid } from '@reduxjs/toolkit';
import { ConditionsArray, SubValue2T, SubValueT } from '../../types';
import { EmptyPlaceholder } from '../EmptyPlaceholder';
import { LogicalOperator } from '../operators/LogicalOperator';
import { OperatorTypes } from '../../types/constants';
import { ComparisonOperator } from '../operators/ComparisonOperator';
import { ArithmeticOperator } from '../operators/ArithmeticOperator';
import { QuantifierOperator } from '../operators/QuantifierOperator';
import { ValueOperator } from '../operators/ValueOperator';
import { ReactComponent as MoveToTopIcon } from './../../../../assets/icons/arrow-move-icon.svg';
import { ReactComponent as CrossOutlineIcon } from './../../../../assets/icons/new-cross-outline.svg';
import { IconButton } from '../../../../components/IconButton';
import { Button } from '../../../../components/Button';
import { ElementOperator } from '../operators/ElementOperator';
import { StateOperator } from '../operators/StateOperator';
import { moveElement } from '../../helpers';

import styles from './index.module.scss';

const { Text } = Typography;

export type UpdateRuleBuilderPayload = {
  value: string;
  ruleId: string;
  subValue?: SubValueT;
  subValue2?: SubValue2T;
};

type Props = {
  query: ConditionsArray;
  setQuery: React.Dispatch<React.SetStateAction<ConditionsArray>>;
  isEditModeEnabled: boolean;
};

export const CustomRuleBuilder: FC<Props> = ({ query, setQuery, isEditModeEnabled }) => {
  const isRuleBuilderDisabled = !isEditModeEnabled;

  const isThereOnlyOneConditionWithoutRules =
    query.length === 1 && query.some((condition) => condition.rules.length === 0);

  const handleChange = useCallback(
    (values: UpdateRuleBuilderPayload) => {
      const { ruleId, value, subValue, subValue2 } = values;
      setQuery((prevState) => {
        return prevState.map((condition) => ({
          ...condition,
          rules: condition.rules.map((rule) => {
            return rule.id === ruleId ? { ...rule, value, subValue: subValue, subValue2: subValue2 } : rule;
          }),
        }));
      });
    },
    [setQuery],
  );

  const handleDeleteOperator = (operatorId: string) => {
    setQuery((prevState) => {
      return prevState.map((condition) => ({
        ...condition,
        rules: condition.rules.filter((rule) => {
          return rule.id !== operatorId;
        }),
      }));
    });
  };

  const handleDuplicateConditionClick = (conditionId: string) => {
    const copyOfTheChosenCondition = query.find((condition) => condition.id === conditionId);
    const modifiedCopyCondition = {
      ...copyOfTheChosenCondition,
      id: nanoid(),
      conditionName: `${query.length + 1} Condition`,
      rules:
        copyOfTheChosenCondition?.rules.map((rule) => ({
          ...rule,
          id: nanoid(),
        })) || [],
    };

    setQuery((prevState) => [...prevState, modifiedCopyCondition]);
  };

  const handleDeleteConditionClick = (conditionId: string) => {
    setQuery((prevState) => {
      return prevState
        .filter((condition) => condition.id !== conditionId)
        .map((item, index) => ({ ...item, conditionName: `${index + 1} Condition` }));
    });
  };

  const handleClickMoveToTop = (conditionId: string) => {
    const sortedArray = moveElement(query, conditionId, 'up');
    setQuery(sortedArray);
  };

  const handleClickMoveToBottom = (conditionId: string) => {
    const sortedArray = moveElement(query, conditionId, 'down');
    setQuery(sortedArray);
  };

  const getOperatorByType = useCallback(
    (
      type: OperatorTypes,
      value: string,
      ruleId: string,
      subValue?: SubValueT,
      subValue2?: SubValue2T,
      index?: number,
    ) => {
      switch (type) {
        case OperatorTypes.LogicalOperator:
          return <LogicalOperator disabled={isRuleBuilderDisabled} name={value} />;
        case OperatorTypes.ComparisonOperator:
          return (
            <ComparisonOperator
              disabled={isRuleBuilderDisabled}
              name={value}
              ruleId={ruleId}
              handleChange={handleChange}
            />
          );
        case OperatorTypes.ArithmeticOperator:
          return (
            <ArithmeticOperator
              disabled={isRuleBuilderDisabled}
              name={value}
              ruleId={ruleId}
              handleChange={handleChange}
            />
          );
        case OperatorTypes.Quantifier:
          return (
            <QuantifierOperator
              disabled={isRuleBuilderDisabled}
              value={value}
              subValue={subValue as string}
              ruleId={ruleId}
              handleChange={handleChange}
            />
          );
        case OperatorTypes.Value:
          return (
            <ValueOperator
              disabled={isRuleBuilderDisabled}
              value={value}
              ruleId={ruleId}
              handleChange={handleChange}
              subValue={subValue as string}
              subValue2={subValue2 as string}
            />
          );
        case OperatorTypes.Element:
          return (
            <ElementOperator
              disabled={isRuleBuilderDisabled}
              value={value}
              ruleId={ruleId}
              handleChange={handleChange}
              subValue={subValue}
              subValue2={subValue2}
              index={index}
            />
          );
        case OperatorTypes.Status:
          return (
            <StateOperator
              disabled={isRuleBuilderDisabled}
              value={value}
              ruleId={ruleId}
              handleChange={handleChange}
              subValue={subValue as string}
            />
          );
      }
    },
    [handleChange, isRuleBuilderDisabled],
  );

  return (
    <div className={styles.container}>
      {query.map((condition, index) => (
        <div className={styles.conditionContainer} key={condition.id}>
          <div className={styles.header}>
            <Text className={styles.conditionTitle}>{condition.conditionName}</Text>

            <div className={styles.rightButtons}>
              <Button
                disabled={isRuleBuilderDisabled}
                onClick={() => handleDuplicateConditionClick(condition.id)}
                type="default"
                className={cn(styles.button)}
              >
                Duplicate
              </Button>

              <Button
                disabled={isRuleBuilderDisabled}
                onClick={() => handleDeleteConditionClick(condition.id)}
                type="default"
                className={cn(styles.button)}
              >
                Delete condition
              </Button>

              {query.length !== 1 && (
                <>
                  <IconButton
                    onClick={() => handleClickMoveToTop(condition.id)}
                    disabled={index === 0 || !isEditModeEnabled}
                    icon={<MoveToTopIcon fill={index === 0 ? '#CFCFD4' : '#5C5C6E'} />}
                  />
                  <IconButton
                    onClick={() => handleClickMoveToBottom(condition.id)}
                    disabled={index === query.length - 1 || !isEditModeEnabled}
                    className={styles.iconButtonSecond}
                    icon={<MoveToTopIcon fill={index === query.length - 1 ? '#CFCFD4' : '#5C5C6E'} />}
                  />
                </>
              )}
            </div>
          </div>
          <div className={styles.ruleContainer}>
            {isThereOnlyOneConditionWithoutRules && <EmptyPlaceholder />}
            {condition.rules.map((operator, index, array) => (
              <React.Fragment key={operator.id}>
                <div>
                  {getOperatorByType(
                    operator.type,
                    operator.value,
                    operator.id,
                    operator.subValue,
                    operator.subValue2,
                    index,
                  )}
                </div>
                {condition.rules.length > 0 && index === array.length - 1 && (
                  <IconButton
                    disabled={isRuleBuilderDisabled}
                    onClick={() => handleDeleteOperator(operator.id)}
                    className={styles.deleteButton}
                    icon={<CrossOutlineIcon />}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
