import { useLoginSection } from '../../app/layouts/EndUserFlowLayout/EndUserAuthLayout';
import { useAppSelector } from '../../lib/store/hooks';
import styles from './index.module.scss';
import { EndUserTypography } from '../../components/EndUserTypography';
import { EndUserSquareTiles } from '../EndUserDemandAreaTiles/components/EndUserSquareTiles';

export const EndUserLoginSquareTiles = () => {
  const { loginSection } = useLoginSection();

  const { demandArea } = useAppSelector(({ endUser }) => endUser);

  const demandAreas = demandArea?.listDemandArea || [];

  const screenHeight = window.screen.height;

  const handleAreaClick = () => {
    //
  };

  const handleKeyDown = () => {
    //
  };

  const houseWrapperStyleForSmallDesktops =
    (screenHeight >= 800 && screenHeight <= 960) || demandAreas.length === 5
      ? {
          transform: 'scale(0.7) translateX(-21.3%)',
          marginTop: -130,
        }
      : undefined;

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <EndUserTypography className={styles.title} component="Title" level={1} type="headlineFirst">
            {loginSection?.headline1}
          </EndUserTypography>
          <EndUserTypography className={styles.subTitle} component="Title" level={3} type="headlineThird">
            {loginSection?.headline2}
          </EndUserTypography>
        </div>

        <div className={styles.houseModelWrapper} style={houseWrapperStyleForSmallDesktops}>
          <EndUserSquareTiles
            handleAreaClick={handleAreaClick}
            handleKeyDown={handleKeyDown}
            demandAreas={demandAreas}
          />
        </div>
      </div>
    </div>
  );
};
