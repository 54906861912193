import { FC } from 'react';
import { useAppSelector } from '../../../../lib/store/hooks';
import { SectionName } from '../../../../lib/models/Section';
import { EndUserModal } from '../../../../components/EndUserModal';
import { EndUserTypography } from '../../../../components/EndUserTypography';

import styles from './index.module.scss';

type Props = {
  isOpen: boolean;
  userEmail: string;
  onClose: () => void;
};

export const SuccessStateModal: FC<Props> = ({ isOpen, userEmail, onClose }) => {
  const { sections } = useAppSelector(({ endUser }) => endUser);
  const successModalSectionContent = sections?.[SectionName.SUCCESS_MODAL];

  const sublineRowsText = successModalSectionContent?.subline?.split('.');

  return (
    <EndUserModal
      open={isOpen}
      onCancel={onClose}
      destroyOnClose={true}
      isFooterHidden
      titleProps={{}}
      className={styles.root}
    >
      <EndUserTypography type="headlineFourth" component="Text">
        {successModalSectionContent?.title}
      </EndUserTypography>
      <img
        src={successModalSectionContent?.icon.s3Url}
        alt={successModalSectionContent?.icon.originalFileName}
        className={styles.icon}
        style={{ color: '#662B57' }}
      />
      <EndUserTypography type="headlineFourth" component="Text">
        {sublineRowsText?.[0]}. <br /> {sublineRowsText?.slice(1)?.[0]}.
      </EndUserTypography>
      <EndUserTypography type="body" component="Text">
        {successModalSectionContent?.description} {userEmail}
      </EndUserTypography>
    </EndUserModal>
  );
};
