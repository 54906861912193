import React, { FC } from 'react';
import { Category, DemandArea } from '../../../../lib/types/endUser';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { IconComponent } from '../../../../components/IconComponent';
import { parseJSON, sortCategoriesByPriority } from '../../../../lib/utils/helpers';
import {
  checkBoughtProductsInCategory,
  getCoveredProductsInCategory,
} from '../../../../lib/utils/end-user-helpers';

import styles from './index.module.scss';
import cn from 'classnames';

type Props = {
  demandAreas?: DemandArea[];
  handleAreaClick: (areaId: number, name: string) => void;
  handleKeyDown: (event: React.KeyboardEvent<HTMLDivElement>, areaId: number, name: string) => void;
  handleCategoryClick?: (areaId: number, category: Category) => void;
  fillAllCategories?: boolean;
};

export const EndUserSquareTiles: FC<Props> = ({
  demandAreas,
  fillAllCategories,
  handleCategoryClick,
  handleAreaClick,
  handleKeyDown,
}) => {
  if (!demandAreas) {
    return null;
  }

  return (
    <div className={styles.container}>
      {demandAreas.map((area, index, array) => {
        const areaColor = parseJSON(area.color);

        const sortedCategories = sortCategoriesByPriority(area.categories);

        const backgroundColor = `color-mix(in srgb, ${areaColor.value} 50%, #000000 5%)`;

        const isLastElement = index === array.length - 1;
        const isOddArea = (index + 1) % 2 !== 0;
        const isLastOddArea = isLastElement && isOddArea;

        return (
          <div
            role="presentation"
            onKeyDown={(event) => handleKeyDown(event, area.id, area.name)}
            onClick={() => handleAreaClick(area.id, area.name)}
            key={area.id}
            className={cn(styles.blurContainer, isLastOddArea && styles.blurLastOddArea)}
          >
            <div
              className={cn(styles.areaContainer, isLastOddArea && styles.oddArea)}
              key={area.id}
              style={{ backgroundColor: backgroundColor }}
            >
              <EndUserTypography
                type="headlineFourth"
                component="Title"
                className={styles.title}
                style={{
                  margin: 0,
                  whiteSpace: 'normal',
                  overflow: 'hidden',
                  color: '#fff',
                }}
              >
                {area.name}
              </EndUserTypography>

              <div className={styles.categoriesContainer}>
                {sortedCategories.map((category) => {
                  const hasUserProducts = fillAllCategories
                    ? fillAllCategories
                    : checkBoughtProductsInCategory(category);

                  const coveredProductsAmount = getCoveredProductsInCategory(category).length;

                  return (
                    <div
                      role="presentation"
                      key={category.id}
                      onClick={() => handleCategoryClick && handleCategoryClick(area.id, category)}
                      className={cn(
                        styles.categoryItem,
                        hasUserProducts && styles.isCovered,
                        category.isAddedToWishlist && styles.addedToWishlist,
                      )}
                      style={{
                        background: hasUserProducts
                          ? areaColor.value
                          : category.isAddedToWishlist
                            ? `repeating-linear-gradient(-45deg, ${areaColor.value}, ${areaColor.value} 5px, rgba(255, 255, 255, 0.25) 5px, rgba(255, 255, 255, 0.25) 10px), ${areaColor.value}`
                            : 'transparent',
                      }}
                    >
                      <IconComponent className={styles.icon} iconData={category?.icon} color="#fff" />
                      <EndUserTypography
                        type="subText"
                        component="Text"
                        className={styles.title}
                        style={{
                          color: '#fff',
                        }}
                      >
                        {category.name}
                      </EndUserTypography>

                      <EndUserTypography
                        type="subText"
                        component="Text"
                        className={styles.coveredCategoriesAmount}
                        style={{ color: '#fff', textAlign: 'center' }}
                      >
                        {coveredProductsAmount}/{category.products.length}
                      </EndUserTypography>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
