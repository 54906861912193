import { useTranslation } from 'react-i18next';
import { STATUS_LEVEL_COLUMN } from '../helpers/consts';
import { Tooltip, Typography } from 'antd';
import styles from './../styles.module.scss';
import { TableHeaderCell } from '../../../components/Table/TableHeaderCell/TableHeaderCell';

const { Text } = Typography;

const subLevels = 'pages.statusLevel.columns.subLevels';

export const useStatusLevelSubLevelsColumn = () => {
  const { t } = useTranslation();

  return {
    title: <TableHeaderCell title={t(subLevels)} withArrange={false} />,
    dataIndex: STATUS_LEVEL_COLUMN.SUBLEVELS,
    width: 250,
    render: (data: { name: string; id: number }[]) => {
      return (
        <div className={styles.subLevelsContainer}>
          {data.map((item) => (
            <div style={{ width: 250 }} className={styles.subLevelItem} key={item.id}>
              <Tooltip
                key={item.id}
                overlayInnerStyle={{
                  width: 400,
                  display: 'flex',
                  alignItems: 'center',
                }}
                title={item.name}
                placement="topLeft"
              >
                <Text ellipsis={true}>{item.name}</Text>
              </Tooltip>
            </div>
          ))}
        </div>
      );
    },
  };
};
