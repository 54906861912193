import { useState } from 'react';
import { Form } from 'antd';
import {
  clearListFilters,
  createOptionItem,
  getParsedSelectedItems,
  getSelectedItemsLabels,
  getSelectedItemsValues,
} from '../../../lib/utils/arranges-helpers';
import { TENANT_COLUMN, TenantStatus } from '../helpers/constants';
import { useStatusColumn } from '../../../features/table/helpers/useStatusColumn';
import { useTenantArrangeRequests } from './useTenantArrangeRequests';
import { useTranslation } from 'react-i18next';
import { useEnumArrange } from './useEnumArrange';
import { TenantStatusBodyCell } from '../components/TenantsTable/TenantStatusBodyCell';
import { useAppSelector } from '../../../lib/store/hooks';

const arrangedId = TENANT_COLUMN.IS_ACTIVE;
const filterFieldName = 'activated';

/*
 Empty object here simulates "all statuses are selected" feature:
 as BE (back end) accepts only boolean value for "status" column,
 - one way to simulate ""all statuses are selected" - pass empty object to the filters :D
 */
const pseudoAllFilters = {};

const status = 'shared.status';

export const useTenantStatusColumn = () => {
  const { t } = useTranslation();

  const { arrangeId, sorting } = useAppSelector(({ tenants }) => tenants);
  const [form] = Form.useForm();
  const isActiveArrange = arrangeId === arrangedId;
  const getItemsValues = () => getSelectedItemsValues(form);
  const getItemsLabels = () => getSelectedItemsLabels(form);
  const [filterLabel, setFilterLabel] = useState('');

  const activatedLabel = t(`${status}.activated`);
  const deactivatedLabel = t(`${status}.deactivated`);

  const allOptions = [
    createOptionItem(activatedLabel, TenantStatus.ACTIVATED),
    createOptionItem(deactivatedLabel, TenantStatus.DEACTIVATED),
  ];

  const getFilters = () => {
    const itemsValues = getItemsValues();
    const areAllFiltersSelected = allOptions.length === itemsValues.length;
    return areAllFiltersSelected ? pseudoAllFilters : { activated: itemsValues?.[0] };
  };

  const { filtering } = useEnumArrange(allOptions);

  const { applyArranges, removeFilter, removeFilterValue } = useTenantArrangeRequests({
    arrangedId,
    clearFilters: () => clearListFilters(form),
    getFilterLabel: () => getItemsLabels().join(', '),
    getFilters,
    form,
    setFilterLabel,
    filterFieldName,
    isNotArrayFilterValues: true,
  });

  return useStatusColumn({
    filtering,
    applyArranges,
    removeFilter,
    arrangedId,
    isActiveArrange,
    form,
    filterLabel,
    sortOrder: sorting.sortOrder,
    getParsedItems: () => getParsedSelectedItems(form),
    removeFilterValue,
    columnProps: {
      render: (status: boolean | undefined) => <TenantStatusBodyCell status={status} />,
    },
  });
};
