import { useParams } from 'react-router-dom';
import { useGetProjectsQuery } from '../store/api/project';

export const useGetProject = () => {
  const { id: tenantId, modelId } = useParams();

  const { data: projectData, isLoading } = useGetProjectsQuery(
    {
      size: 100,
      tenantId: String(tenantId),
      filter: [String(modelId)],
      filterFieldName: 'includeIds',
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !tenantId || !modelId,
    },
  );

  return { data: projectData?.content[0], isProjectLoading: isLoading };
};
