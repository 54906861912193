import { Tooltip } from 'antd';
import { InfoIcon } from '../../assets/icons/InfoIcon';
import { ReactComponent as UploadIcon } from './../../assets/icons/upload-icon.svg';
import styles from './index.module.scss';
import { ElementType } from 'react';
import cx from 'classnames';

type Props = {
  text: string;
  infoText?: string;
  icon?: ElementType;
  textStyles?: any;
  disabled?: boolean;
};

export const UploadLabel = ({
  text,
  infoText,
  icon: Icon,
  textStyles = { fontSize: '12px' },
  disabled,
}: Props) => {
  const RenderedIcon = Icon || UploadIcon;

  return (
    <div className={cx(styles.upload_box, { [styles.disabled]: disabled })}>
      <RenderedIcon fill="#da1b5e" />
      <div style={textStyles} className="primary_color bold">
        {text}
      </div>
      {infoText && (
        <Tooltip title={infoText}>
          <div className={styles.upload_box_info_block}>
            <InfoIcon className="primary_color" />
          </div>
        </Tooltip>
      )}
    </div>
  );
};
