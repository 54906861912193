import { FC, useMemo } from 'react';
import { Form, FormInstance, Radio, RadioChangeEvent } from 'antd';
import { trimForm } from '../../../../lib/utils/helpers';
import { NameFormItem } from '../FormItems/NameFormItem';
import { DescriptionFormItem } from '../FormItems/DescriptionFormItem';
import { DemandAreaFormItem } from '../FormItems/DemandAreaFormItem';
import { PriorityFormItem } from '../FormItems/PriorityFormItem';
import { SelectionTypeFormItem } from '../FormItems/SelectionTypeFormItem';
import { FormItemName } from '../../../../lib/models/Form';
import { IconFormItem } from '../FormItems/IconFormItem';
import { ProductCategoriesModalFormValuesT } from '../../types';
import { ProductCategoryPayloadDataT } from '../../../../lib/types/productCategories';
import { prepareProductCategoryForm } from '../../helpers';
import { useGetIconOptions } from '../../hooks/useGetIconOptions';
import {
  useGetDemandAreasForOptionQuery,
  useGetCategoryPrioritiesQuery,
} from '../../../../lib/store/api/productCategories';
import { useParams } from 'react-router-dom';
import { ProductCategoryT } from '../../../../lib/types/entities';
import { AssignedProductsFormItem } from '../FormItems/AssignedProductsFormItem';

import styles from './index.module.scss';
import { FormItem } from '../../../../components/FormItem';
import { Visibility } from '../../../../lib/types/product';
import { useTranslation } from 'react-i18next';

import { FinderActivationFormItem } from '../FormItems/FinderActivationFormItem';
import { FinderButtonNameFormItem } from '../FormItems/FinderButtonNameFormItem';
import { FinderUrlFormItem } from '../FormItems/FinderUrlFormItem';

const visible = 'pages.products.productCategories.addModal.visible';
const notVisible = 'pages.products.productCategories.addModal.notVisible';

type Props = {
  form: FormInstance;
  selectedCategory?: ProductCategoryT | null;
  onSubmit: (values: Omit<ProductCategoryPayloadDataT, 'projectId'>) => Promise<void>;
};

export const ProductCategoriesForm: FC<Props> = ({ form, selectedCategory, onSubmit }) => {
  const demandArea = Form.useWatch(FormItemName.DEMAND_AREA, form);
  const visibility = Form.useWatch(FormItemName.VISIBILITY, form);

  const { t } = useTranslation();

  const { id: tenantId, modelId } = useParams();

  const visibilityOptions = [
    {
      label: t(visible),
      value: Visibility.ALWAYS,
    },
    {
      label: t(notVisible),
      value: Visibility.NEVER,
    },
  ];

  const { options, iconsList } = useGetIconOptions();

  const { data: priorities } = useGetCategoryPrioritiesQuery(
    {
      tenantId: String(tenantId),
      demandAreaId: Number(demandArea?.value),
    },
    {
      skip: !demandArea,
      refetchOnMountOrArgChange: true,
    },
  );

  const { data: demandAreaOptions } = useGetDemandAreasForOptionQuery(
    {
      tenantId: String(tenantId),
      modelId: String(modelId),
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const onFinish = async (values: ProductCategoriesModalFormValuesT) => {
    const trimmedValues = trimForm(values);
    const preparedForm = prepareProductCategoryForm(trimmedValues, iconsList);
    await onSubmit(preparedForm);
  };

  const onValuesChange = (changedValues: any) => {
    if (changedValues[FormItemName.DEMAND_AREA]?.value) {
      form.resetFields([FormItemName.PRIORITY]);
    }
  };

  const onChangeVisibility = (e: RadioChangeEvent) => {
    const { value } = e.target;

    if (value === Visibility.NEVER) {
      form.setFieldsValue({
        [FormItemName.DEMAND_AREA]: '',
        [FormItemName.PRIORITY]: '',
      });
    }
  };

  const prioritiesOptions = useMemo(() => {
    if (
      selectedCategory?.demandAreaId === demandArea?.value &&
      selectedCategory?.priority?.number &&
      priorities
    ) {
      const options = [...priorities, selectedCategory?.priority.number].sort();
      return options;
    }
    return priorities;
  }, [demandArea?.value, priorities, selectedCategory?.demandAreaId, selectedCategory?.priority?.number]);

  const isFinderEnabled = Form.useWatch(FormItemName.FINDER_ENABLED, form);

  return (
    <Form form={form} onFinish={onFinish} className={styles.form} onValuesChange={onValuesChange}>
      <FormItem
        hasFeedback
        name={FormItemName.VISIBILITY}
        className={styles.formItem}
        initialValue={Visibility.ALWAYS}
      >
        <Radio.Group
          onChange={onChangeVisibility}
          className={styles.radioGroup}
          options={visibilityOptions}
        />
      </FormItem>
      <NameFormItem />
      <DemandAreaFormItem disabled={visibility === Visibility.NEVER} options={demandAreaOptions} />
      <PriorityFormItem disabled={visibility === Visibility.NEVER} priorities={prioritiesOptions} />
      {selectedCategory && <AssignedProductsFormItem products={selectedCategory.products} />}
      <DescriptionFormItem />
      <SelectionTypeFormItem />
      <IconFormItem disabled={visibility === Visibility.NEVER} options={options} />
      <FinderActivationFormItem form={form} />
      {isFinderEnabled && (
        <>
          <FinderButtonNameFormItem />
          <FinderUrlFormItem />
        </>
      )}
    </Form>
  );
};
