import { ChangeEvent, FC, useMemo } from 'react';
import { AppCheckbox } from '../../../AppCheckox';
import { Divider } from 'antd';
import { MenuListProps, components, GroupBase } from 'react-select';
import { SelectPropsT } from '../../types';
import { useTranslation } from 'react-i18next';

type Props = {
  selectProps: SelectPropsT;
} & MenuListProps<any, boolean, GroupBase<any>>;

export const MenuListWithSelectAll: FC<Props> = (props) => {
  const { selectProps, options } = props;
  const hasOptions = Boolean(selectProps.options?.length);
  const { t } = useTranslation();

  const allOptions = useMemo(() => {
    return options?.map((item: { options: any }) => item?.options).flat();
  }, [options]);

  const areAllSelected = useMemo(() => {
    if (selectProps.value && hasOptions && !selectProps?.withSelectableGroups) {
      return selectProps.value?.length === selectProps.options?.length;
    } else if (hasOptions && selectProps?.withSelectableGroups) {
      const { selectedGroups } = selectProps.groupProps;

      return selectProps.value?.length === allOptions?.length && options?.length === selectedGroups?.length;
    }
  }, [allOptions, hasOptions, options, selectProps]);

  const toggle = async () => {
    if (selectProps?.withSelectableGroups) {
      const { onSelectGroup } = selectProps.groupProps;
      const allGroupOptionsIds = options?.map((item: { value: number }) => item?.value);
      onSelectGroup({
        productCategories: areAllSelected ? [] : allGroupOptionsIds,
        products: areAllSelected ? [] : allOptions,
      });
    } else {
      selectProps.onChange(
        areAllSelected ? [] : selectProps?.withSelectableGroups ? allOptions : selectProps.options,
        {} as any,
      );
    }
  };

  const toggleList = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event?.target?.checked;
    selectProps?.listProps?.onSelectList?.(checked);
  };

  return (
    <div>
      {selectProps.isMulti && hasOptions && (
        <>
          <AppCheckbox
            label={t('generic.selectAll')}
            onChange={toggle}
            checked={areAllSelected}
            style={{ marginTop: '16px' }}
          />
          {selectProps?.listProps && (
            <AppCheckbox
              label={t('generic.listOption')}
              onChange={toggleList}
              checked={selectProps?.listProps?.isListEnabled}
              style={{ marginTop: '16px' }}
            />
          )}
          <Divider style={{ margin: '10px 10px 0 0' }} />
        </>
      )}

      <components.MenuList {...props} />
    </div>
  );
};
