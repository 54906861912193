import { FC, useEffect, useState } from 'react';
import { Table } from '../../../../components/Table';
import { useTenantActionsColumn } from '../../hooks/useTenantActionsColumns';
import { useTenantNameColumn } from '../../hooks/useTenantNameColumn';
import { useExpandColumn } from '../../../../features/table/helpers/useExpandColumn';
import { useProjectsAmountColumn } from '../../hooks/useProjectsAmountColumn';
import { useBrandKitColumn } from '../../hooks/useBrandKitColumn';
import { useCreationDateColumn } from '../../hooks/useCreationDateColumn';
import { useTenantCreatorColumn } from '../../hooks/useTenantCreatorColumn';
import { useTenantAssignedUsersColumn } from '../../hooks/useTenantAssignedUsersColumn';
import { useTenantCommentColumn } from '../../hooks/useTenantCommentColumn';
import { useTenantStatusColumn } from '../../hooks/useTenantStatusColumn';
import { GetTenantsPayload, useGetTenantsQuery } from '../../../../lib/store/api/tenants';
import { useTenantAddressColumn } from '../../hooks/useTenantAddressColumn';
import { useTenantEmailColumn } from '../../hooks/useTenantEmailColumn';
import { useTenantPhoneColumn } from '../../hooks/useTenantPhoneColumn';
import { insertAtIndex, normalizePageForRequest, normalizePageForTable } from '../../../../lib/utils/helpers';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { TablePagination } from '../../../../lib/types/pagination';
import { resetTenantTable, setTenantTablePage } from '../../../../lib/store/slices/tenantsSlice';
import { checkIsSuperAdmin } from '../../../../lib/utils/roles';
import { TableColumn } from '../../../../lib/models/Table';

const COLUMN_INSERT_INDEX = 1;

export const TenantsTable: FC = () => {
  const [areColumnsExpanded, setAreColumnsExpanded] = useState(false);
  const dispatch = useAppDispatch();
  const { role } = useAppSelector(({ auth }) => auth);

  const { page, ...filters } = useAppSelector(({ tenants }) => {
    const arrangedId = tenants.arrangeId;
    return { arrangedId, ...tenants.filtering, ...tenants.sorting, page: tenants.page };
  });

  const { data, isLoading, isFetching } = useGetTenantsQuery(
    {
      page: normalizePageForRequest(page || 1),
      ...filters,
    } as GetTenantsPayload,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    dispatch(resetTenantTable());
  }, [dispatch]);

  const responsePagination: TablePagination = {
    pageSize: data?.pageable.pageSize,
    current: normalizePageForTable(data?.pageable?.pageNumber || 0),
    total: data?.totalElements,
  };

  const visibleColumns = [
    useTenantNameColumn(),
    useExpandColumn({ areColumnsExpanded, setAreColumnsExpanded }),
    useProjectsAmountColumn(),
    useBrandKitColumn(),
    useCreationDateColumn(),
    useTenantAssignedUsersColumn(),
    useTenantCommentColumn(),
    useTenantStatusColumn(),
    useTenantActionsColumn(),
  ];

  const hiddenColumns = [
    useTenantCreatorColumn(),
    useTenantAddressColumn(),
    useTenantEmailColumn(),
    useTenantPhoneColumn(),
  ];

  const allColumns = insertAtIndex(visibleColumns, hiddenColumns, COLUMN_INSERT_INDEX);
  const displayedColumns = areColumnsExpanded ? allColumns : visibleColumns;
  const filteredColumns = checkIsSuperAdmin(role)
    ? displayedColumns
    : displayedColumns.filter((column: { dataIndex: string }) => column.dataIndex !== TableColumn.ACTIONS);

  return (
    <Table
      columns={filteredColumns}
      rowKey="id"
      dataSource={data?.content}
      pagination={responsePagination}
      loading={isLoading || isFetching}
      scroll={{ x: 'max-content' }}
      onChange={(tablePagination) => {
        dispatch(setTenantTablePage(tablePagination.current as number));
      }}
    />
  );
};
