import { Form } from 'antd';
import { FC } from 'react';
import { TableHeaderCell } from '../../../../components/Table/TableHeaderCell/TableHeaderCell';
import { useAppSelector } from '../../../../lib/store/hooks';
import {
  formatArrangedData,
  getArrangesInitialValues,
  getParsedSelectedItems,
  getSelectedItemsLabels,
} from '../../../../lib/utils/arranges-helpers';
import { useStringArrange } from '../../../../features/table/helpers/useStringArrange';
import { ArrangeListPopup } from '../../../../features/table/components/popups/ArrangeListPopup';
import { useUserArrangeRequests } from '../../hooks/table/useUserArrangeRequests';
import { useLazyGetUsersForFilteringQuery } from '../../../../lib/store/api/usersManagement';

type Props = {
  arrangedId: string;
  title: string;
  searchFieldName: string;
  filterFieldName: string;
  // TODO check if needed
  headerCellProps: any;
};

export const UserSearchDataHeaderCell: FC<Props> = ({
  arrangedId,
  title,
  searchFieldName,
  filterFieldName,
  headerCellProps: additionalHeaderCellProps,
}) => {
  const sortingAndFiltersData = useAppSelector(({ userManagement }) => userManagement);

  const {
    arrangeId,
    sorting,
    filtering: { filter },
  } = sortingAndFiltersData;

  const [findUsers] = useLazyGetUsersForFilteringQuery();

  const isActiveArrange = arrangeId === arrangedId;

  const [form] = Form.useForm();
  const getSelectedItems = () => getSelectedItemsLabels(form);

  const { filtering } = useStringArrange({
    form,
    request: (value: string) => {
      return findUsers({ search: value, searchFieldName }).unwrap();
    },
    formatResponse: (data: { content: any[] }) => formatArrangedData(data, arrangedId),
  });

  const { applyArranges, removeFilter, removeFilterValue } = useUserArrangeRequests({
    arrangedId,
    getFilterLabel: () => getSelectedItems().join(', '),
    getFilters: () => ({ [filterFieldName]: getSelectedItems() }),
    form,
    filterFieldName,
  });

  const headerCellProps = {
    arrangedId,
    title,
    removeFilter,
    filterLabel: Array.isArray(filter) && filter.join(', '),
    sortOrder: sorting.sortOrder,
    isActiveArrange,
    form,
    applyArranges,
    getParsedItems: () => getParsedSelectedItems(form),
    removeFilterValue,
    ...additionalHeaderCellProps,
  };

  return (
    <TableHeaderCell {...headerCellProps}>
      <ArrangeListPopup
        initialValues={getArrangesInitialValues(form)}
        form={form}
        filtering={filtering}
        onFinish={applyArranges}
        isActiveArrange={isActiveArrange}
      />
    </TableHeaderCell>
  );
};
