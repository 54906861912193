import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormItemName } from '../../../../lib/models/Form';
import { FormItem } from '../../../../components/FormItem';
import { FormInput } from '../../../../components/FormInput';

const tenantName = 'unique.formItems.tenantName';

export const TenantNameFormItem: FC = () => {
  const { t } = useTranslation();

  return (
    <FormItem
      name={FormItemName.TENANT_NAME}
      hasFeedback={true}
      rules={[
        {
          required: true,
        },
        {
          pattern: /^[A-Za-zÄÖÜßäöü]*$/,
          message: t(`${tenantName}.errors.invalid`),
        },
        { max: 100 },
      ]}
    >
      <FormInput label={t(`${tenantName}.label`)} />
    </FormItem>
  );
};
