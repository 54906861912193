import { FC, ReactNode } from 'react';
import { Upload } from 'antd';
import { useBeforeUploadError } from '../../lib/hooks/useBeforeUploadError';
import imageCompression from 'browser-image-compression';
import { RcFile } from 'antd/es/upload/interface';
import { useUploadMediaMutation } from '../../lib/store/api/media';
import { UploadResponseT } from '../../lib/types/entities';
import { DeleteMediaProps, MIMEType } from '../../lib/types/media';
import { useParams } from 'react-router-dom';
import { useDeleteMedia } from '../../lib/hooks/useDeleteMedia';

type Props = {
  uploadLabel: (arg: { onDeleteMedia: (props: DeleteMediaProps) => void }) => ReactNode;
  maxFileSize: number;
  errorText: string;
  disabled: boolean;
  excludedTypes?: string[];
  accept?: string;
  setUploading: (isUploaded: boolean) => void;
  afterUploadAction: (fileData: UploadResponseT) => void;
  targetType: string;
  receivedTenantId?: string;
};

export const FileUploader: FC<Props> = ({
  uploadLabel,
  accept = MIMEType.ALL_IMAGES,
  maxFileSize,
  errorText,
  disabled,
  setUploading,
  afterUploadAction,
  targetType,
  receivedTenantId,
}) => {
  const [uploadMedia] = useUploadMediaMutation();
  const onDeleteMedia = useDeleteMedia();

  const { id: tenantId } = useParams();

  const { beforeUpload, contextHolder } = useBeforeUploadError(maxFileSize, errorText);

  const customUploadRequest = async (file: RcFile | File | any) => {
    let fileForUpload = file;

    try {
      if (accept.includes('image') && typeof file !== 'string' && !file.type.includes('svg')) {
        fileForUpload = await imageCompression(file, { maxSizeMB: 10 });
      }

      setUploading(true);

      const result = await uploadMedia({
        file: fileForUpload,
        targetType,
        targetId: tenantId ? tenantId : receivedTenantId || '1',
        originalFileName: file.name,
        tenantId: tenantId ? tenantId : receivedTenantId || '1', // value 1 used for cases where there is no tenant
      });

      if ('data' in result && afterUploadAction) {
        afterUploadAction(result.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <Upload
        accept={accept}
        beforeUpload={beforeUpload}
        customRequest={(options) => customUploadRequest(options.file)}
        showUploadList={false}
        disabled={disabled}
      >
        {uploadLabel({ onDeleteMedia })}
      </Upload>
    </>
  );
};
