import { FormSection } from '../../../components/FormSection';
import { AppSelect } from '../../../components/AppSelect';

const defaultFontsOptions = [
  { value: 700, title: 'Bold' },
  { value: 400, title: 'Regular' },
];

const fontsWhenDisabled = [{ value: undefined, title: 'No options' }];

type Props = { value?: number; onChange: any; fieldName?: string; field: string; disabled?: boolean };

export const FontWeightFormItem = ({ field, fieldName, onChange, value, disabled }: Props) => {
  return (
    <FormSection
      formItemProps={{
        hasFeedback: true,
      }}
    >
      <AppSelect
        disabled={disabled}
        value={{ value }}
        onChange={(selected: { value: string; title: string }) => onChange(selected?.value, fieldName, field)}
        options={disabled && value === undefined ? fontsWhenDisabled : defaultFontsOptions}
      />
    </FormSection>
  );
};
