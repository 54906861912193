import { FC } from 'react';
import styles from './index.module.scss';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { UploadMediaResponseT } from '../../../../lib/types/media';
import cx from 'classnames';

type Props = {
  image?: UploadMediaResponseT;
  header?: string;
  title?: string;
};

export const HeaderSection: FC<Props> = ({ image, header, title }) => {
  return (
    <div
      className={styles.wrapper}
      style={{
        backgroundImage: `linear-gradient(76deg, rgba(0, 0, 0, 0.51) 5.37%, rgba(0, 0, 0, 0.46) 27.78%, rgba(0, 0, 0, 0.00) 90.96%), url(${image?.s3Url})`,
      }}
    >
      <div className={styles.headlineBlock}>
        <EndUserTypography
          type={'headlineFirst'}
          component="Title"
          className={cx(styles.heading, styles.white)}
        >
          {header}
        </EndUserTypography>
        <EndUserTypography
          type={'headlineThird'}
          component="Title"
          className={cx(styles.title, styles.white)}
        >
          {title}
        </EndUserTypography>
      </div>
    </div>
  );
};
