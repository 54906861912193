import { ModalActions } from '../../models/Modal';
import { Sort } from '../../types/filteringAndSort';
import { TableSortOrder } from '../../utils/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { STATUS_LEVEL_COLUMN } from '../../../modules/statusLevel/helpers/consts';
import { StatusLevelT } from '../../types/statusLevel';

type StatusLevelSliceStateT = {
  modalType: ModalActions | null;
  isModalOpen: boolean;
  selectedStatusLevel: StatusLevelT | null;
  page: number;
  sorting: Sort;
  arrangeId: STATUS_LEVEL_COLUMN | null;
};

const initialState: StatusLevelSliceStateT = {
  arrangeId: null,
  page: 1,
  isModalOpen: false,
  modalType: null,
  selectedStatusLevel: null,

  sorting: {
    sortOrder: TableSortOrder.NONE,
    sortFieldName: null,
    withSorting: true,
  },
};

const statusLevelSlice = createSlice({
  name: 'statusLevelSlice',
  initialState,
  reducers: {
    setModalOpened: (state, action: PayloadAction<ModalActions>) => {
      state.modalType = action.payload;
      state.isModalOpen = true;
    },
    setModalClosed: (state) => {
      state.modalType = null;
      state.isModalOpen = false;
    },
    setCurrentStatusLevel: (state, action: PayloadAction<StatusLevelT | null>) => {
      state.selectedStatusLevel = action.payload;
    },
    setSorting: (state, action: PayloadAction<{ sorting: Sort; arrangeId: STATUS_LEVEL_COLUMN }>) => {
      state.arrangeId = action.payload.arrangeId;
      state.sorting = action.payload.sorting;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
});

export const { setModalOpened, setModalClosed, setCurrentStatusLevel, setSorting, setPage } =
  statusLevelSlice.actions;
export const statusLevel = statusLevelSlice.reducer;
