import { FC, ReactNode, useMemo } from 'react';
import cn from 'classnames';
import { ButtonProps } from 'antd';
import { Button } from '../Button';
import { useAppSelector } from '../../lib/store/hooks';
import { ButtonShape } from '../../modules/designSettings/types';

import styles from './index.module.scss';

type Props = {
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
  type?: 'primary' | 'secondary';
  htmlType?: string;
  disabled?: boolean;
} & Omit<ButtonProps, 'type'>;

export const EndUserButton: FC<Props> = ({ className, children, onClick, type, htmlType, disabled }) => {
  const { designSettings, brandKit } = useAppSelector(({ endUser }) => endUser);

  const getButtonShape = (shape: ButtonShape) => {
    switch (shape) {
      case ButtonShape.ANGULAR:
        return 0;
      case ButtonShape.MEDIUM_ROUND:
        return 8;
      case ButtonShape.ROUND:
        return 40;
    }
  };

  const buttonProps = useMemo(() => {
    if (designSettings && brandKit) {
      const { secondaryButton, primaryButton } = designSettings;
      const { colors } = brandKit;
      const button = type === 'primary' ? primaryButton : secondaryButton;

      return {
        style: {
          fontFamily: button.font.font.split('.')?.[0] || button.font.font,
          fontSize: button.font.fontSize,
          fontWeight: button.font.fontWeight,
          color: colors[button.font.colorVariant],
          borderRadius: getButtonShape(button.shape),
          background: button.type.isFilled ? colors[button.type.filledColor] : 'transparent',
          border: button.type.isOutlined ? `1px solid ${colors[button.type.outLinedColor]}` : 'none',
          ['--dynamic-color' as string]: button.type.isFilled
            ? colors[button.type.filledColor]
            : button.type.isOutlined
            ? colors[button.type.outLinedColor]
            : 'transparent',
          overflow: 'hidden',
        },
      };
    }

    return {};
  }, [brandKit, designSettings, type]);

  return (
    <Button
      className={cn(type === 'primary' ? styles.primaryButton : styles.secondaryButton, className)}
      onClick={onClick}
      htmlType={htmlType}
      disabled={disabled}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};
