import { Dispatch, SetStateAction } from 'react';
import { toggleValue } from '../../../lib/utils/helpers';
import { TableColumn } from '../../../lib/models/Table';
import { ToggleExpandButton } from '../components/ToggleExpandButton';

import tableStyles from '../../../components/Table/index.module.scss';

type Props = {
  areColumnsExpanded: boolean;
  setAreColumnsExpanded: Dispatch<SetStateAction<boolean>>;
};

export const useExpandColumn = ({
  areColumnsExpanded,
  setAreColumnsExpanded,
}: Props) => {
  const onClick = () => setAreColumnsExpanded(toggleValue);

  return {
    dataIndex: TableColumn.EXPAND_BUTTON,
    width: 24,
    className: tableStyles.expandBodyCell,
    title: () => (
      <ToggleExpandButton
        areColumnsExpanded={areColumnsExpanded}
        onClick={onClick}
      />
    ),
  };
};
