import { UserRolesT } from '../../../../../lib/types/auth';
import { User } from '../../../helpers/constants';
import { RightsHeaderCell } from '../../../components/UsersTable/RightsHeaderCell';
import { RightsBodyCell } from '../../../components/UsersTable/RightsBodyCell';

export const rightsColumn = {
  dataIndex: User.ROLES,
  title: <RightsHeaderCell />,
  render: (role: UserRolesT) => <RightsBodyCell role={role} />,
};
