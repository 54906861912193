import { useTranslation } from 'react-i18next';
import { useProductCategoriesSearchDataColumn } from './useProductCategoriesSearchDataColumn';
import { PRODUCT_CATEGORIES } from '../helpers/constants';
import { Typography } from 'antd';

const demandArea = 'pages.products.productCategories.columns.demandArea';

export const useProductCategoriesDemandAreaColumn = () => {
  const { t } = useTranslation();

  return useProductCategoriesSearchDataColumn({
    title: t(demandArea),
    arrangedId: PRODUCT_CATEGORIES.DEMAND_AREA,
    filterFieldName: 'includeDemandAreas',
    isParsedByValue: true,
    columnProps: {
      width: 198,
      render: (data: { id: number; name: string }) => {
        return <Typography.Text>{data?.name}</Typography.Text>;
      },
    },
  });
};
