import { FC } from 'react';
import { TableSortOrder } from '../../../lib/utils/constants';
import { SortAscendIcon } from '../../../assets/icons/SortAscendIcon';
import { SortDescendIcon } from '../../../assets/icons/SortDescendIcon';
import { SortDefaultIcon } from '../../../assets/icons/SortDefaultIcon';

type Props = {
  isActiveArrange: boolean;
  sortOrder: any;
};

export const SortIcon: FC<Props> = ({ isActiveArrange, sortOrder }) => {
  switch (true) {
    case isActiveArrange && sortOrder === TableSortOrder.ASC:
      return <SortAscendIcon active={isActiveArrange} />;
    case isActiveArrange && sortOrder === TableSortOrder.DESC:
      return <SortDescendIcon active={isActiveArrange} />;
    default:
      return <SortDefaultIcon />;
  }
};
