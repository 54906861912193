import { useTranslation } from 'react-i18next';
import { TENANT_COLUMN } from '../helpers/constants';
import { useTenantNumberColumn } from './useTenantNumberColumn';

const brandKit = 'pages.tenants.columns.brandKit';

export const useBrandKitColumn = () => {
  const { t } = useTranslation();


  // TODO change column type when back is ready
  return useTenantNumberColumn({
    title: t(brandKit),
    arrangedId: TENANT_COLUMN.BRAND_KIT,
    columnProps: {
      width: 129,
    },
  });
};
