import { EndUserNewPasswordCard } from '../../modules/endUserNewPassword/components/EndUserNewPasswordCard';

import styles from './styles.module.scss';

export const EndUserNewPasswordPage = () => {
  return (
    <div className={styles.container}>
      <EndUserNewPasswordCard />
    </div>
  );
};
