import React, {FC} from 'react';

interface Props {
    fill: string
}

const TopRightItem: FC<Props> = ({fill}) => (
    <svg width="298" height="321" viewBox="0 0 298 321" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M297.433 187.462V304.532C297.433 313.369 290.27 320.532 281.433 320.532H16.4336C7.59704 320.532 0.433594 313.369 0.433594 304.532V0.417297C3.16156 1.12146 5.81793 2.19186 8.32821 3.62849L281.328 159.866C291.237 165.537 297.37 176.054 297.433 187.462Z"
              fill={fill}/>
    </svg>
);

export default TopRightItem;