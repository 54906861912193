import React, { useState } from 'react';
import { Avatar, Dropdown, Tooltip, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from '../../lib/store/hooks';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';
import { appLogout } from '../../lib/utils/helpers';

const profile = 'platform.header.profile';
const signOut = 'platform.header.signOut';

const MAX_DISPLAYED_EMAIL_LENGTH = 20;
const CUT_EMAIL_LENGTH = 5;

const { Text } = Typography;
export const ProfilePopup = () => {
  const [visible, setVisible] = useState(false);
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(({ auth }) => auth);
  const { t } = useTranslation();

  const handleVisibleChange = () => {
    setVisible((prev) => !prev);
  };
  const handleLogoutClick = () => {
    appLogout(dispatch);
  };

  const trimIndex = user?.email.indexOf('@');
  const formattedEmail =
    user && user?.email.length > MAX_DISPLAYED_EMAIL_LENGTH
      ? `${user?.email.slice(0, CUT_EMAIL_LENGTH)}***${user?.email.slice(trimIndex)}`
      : user?.email;

  return (
    <Dropdown
      open={visible}
      trigger={['click']}
      placement="bottomRight"
      onOpenChange={handleVisibleChange}
      overlayClassName={styles.profile_dropdown}
      dropdownRender={() => (
        <div className={styles.profile_dropdown_wrapper}>
          <div className={styles.profile_user_block}>
            <Avatar className={styles.profile_user_avatar} size={40}>
              {user?.fullName?.[0]}
            </Avatar>
            <div className={styles.profile_user_info}>
              <Text className={`${styles.profile_user_text} ${styles.profile_user__bold}`} ellipsis>
                {user?.fullName}
              </Text>
              <Tooltip placement="topLeft" title={user?.email}>
                <Text className={`${styles.profile_user_text} ${styles.profile_user__light}`}>
                  {formattedEmail}
                </Text>
              </Tooltip>
            </div>
          </div>
          <Text className={styles.profile_dropdownItem}>{t(profile)}</Text>
          <hr className={styles.profile_hr} />
          <Text
            className={`${styles.profile_dropdownItem_danger} ${styles.profile_dropdownItem}`}
            onClick={handleLogoutClick}
          >
            {t(signOut)}
          </Text>
        </div>
      )}
    >
      <Avatar
        className={styles.profile_user_avatar}
        style={{
          cursor: 'pointer',
        }}
        size={40}
      >
        {user?.fullName?.[0]}
      </Avatar>
    </Dropdown>
  );
};
