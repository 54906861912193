import { useParams } from 'react-router-dom';

import { useGetRuleStatusQuery } from '../../../../lib/store/api/ruleBuilder';
import { RuleStatusT } from '../../../../lib/types/ruleBuilder';
import { FC, useEffect } from 'react';

type Props = {
  setRuleStatus: (status: RuleStatusT) => void;
};

export const RuleStatusListener: FC<Props> = ({ setRuleStatus }) => {
  const { id: tenantId, modelId } = useParams();

  const { data } = useGetRuleStatusQuery(
    { tenantId: Number(tenantId), projectId: Number(modelId) },
    {
      pollingInterval: 5000,
      refetchOnFocus: true,
    },
  );

  useEffect(() => {
    if (data) {
      setRuleStatus(data.status);
    }
  }, [data, setRuleStatus]);

  return null;
};
