import { useTranslation } from 'react-i18next';
import { BONUS_CATALOG_COLUMN } from '../../../../lib/types/bonusCatalog';
import { useBonusCatalogDataColumn } from './useBonusCatalogDataColumn';

const bonusTitle = 'pages.bonusCatalogTab.columns.bonusTitle';

export const useBonusTitleColumn = () => {
  const { t } = useTranslation();

  return useBonusCatalogDataColumn({
    title: t(bonusTitle),
    arrangedId: BONUS_CATALOG_COLUMN.BONUS_TITLE,
    columnProps: {
      width: 182,
    },
  });
};
