import { useTranslation } from 'react-i18next';
import { useProductSearchDataColumn } from './useProductSearchDataColumn';
import { PRODUCTS_COLUMNS } from '../helpers/consts';

const productId = 'pages.products.products.columns.productId';

export const useProductIdColumn = () => {
  const { t } = useTranslation();

  return useProductSearchDataColumn({
    title: t(productId),
    arrangedId: PRODUCTS_COLUMNS.ID,
    disableFiltering: true,
    columnProps: {
      width: 150,
    },
  });
};
