import React, { FC } from 'react';
import { Typography } from 'antd';

import styles from './index.module.scss';

const { Text } = Typography;

import cn from 'classnames';

type Props = {
  label: string;
  imageUrl: string;
  iconClassName?: string;
  iconWrapperClassName?: string;
};

export const IconOption: FC<Props> = ({ label, imageUrl, iconClassName, iconWrapperClassName }) => {
  return (
    <div className={cn(styles.wrapper, iconWrapperClassName)}>
      <div className={cn(styles.iconWrapper, iconClassName && iconClassName)}>
        <img className={styles.icon} alt="product-category-icon" src={imageUrl} />
      </div>
      <Text ellipsis style={{ width: 500 }}>
        {label}
      </Text>
    </div>
  );
};
