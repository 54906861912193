import { Form, FormInstance } from 'antd';
import { Combine, DraggableId, DraggableLocation, DropReason, TypeId } from 'react-beautiful-dnd';
import { FormItemName } from '../../../lib/models/Form';
import { MAX_BULLET_AMOUNT } from '../helpers/constants';

type Props = {
  form: FormInstance;
};

type DragDropResult = {
  draggableId: DraggableId;
  type: TypeId;
  source: DraggableLocation;
  destination: DraggableLocation | null;
  combine?: Combine;
  reason: DropReason;
};

export const useBulletPoints = ({ form }: Props) => {
  const onDragEnd = (result: DragDropResult) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(form.getFieldValue(FormItemName.BULLET_POINTS));
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    form.setFieldsValue({
      [FormItemName.BULLET_POINTS]: reorderedItems,
    });
  };

  const bullets = Form.useWatch(FormItemName.BULLET_POINTS, form);
  const itemsSize = bullets?.length;

  const onAddNewBullet = () => {
    const length = form.getFieldValue(FormItemName.BULLET_POINTS)?.length;

    if (length === MAX_BULLET_AMOUNT) {
      return;
    }

    form.setFieldsValue({
      [FormItemName.BULLET_POINTS]: [...form.getFieldValue(FormItemName.BULLET_POINTS), ''],
    });
  };

  return { onDragEnd, itemsSize, onAddNewBullet };
};
