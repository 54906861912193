import { FC, useEffect, useState } from 'react';
import { Table } from '../../../../components/Table';
import { useExpandColumn } from '../../../../features/table/helpers/useExpandColumn';
import { insertAtIndex, normalizePageForRequest, normalizePageForTable } from '../../../../lib/utils/helpers';
import { useUsedTemplateColumn } from '../../hooks/useUsedTemplateColumn';
import { useProjectStatusColumn } from '../../hooks/useProjectStatusColumn';
import { useProjectNameColumn } from '../../hooks/useProjectNameColumn';
import { useEditModeColumn } from '../../hooks/useEditModeColumn';
import { useLastUpdateColumn } from '../../hooks/useLastUpdateColumn';
import { useLastEditedFromColumn } from '../../hooks/useLastEditedFromColumn';
import { usePublishedColumn } from '../../hooks/usePublishedColumn';
import { useUrlColumn } from '../../hooks/useUrlColumn';
import { useProjectActionColumn } from '../../hooks/useProjectActionColumn';
import { useContractStartColumn } from '../../hooks/useContractStartColumn';
import { useContractEndColumn } from '../../hooks/useContractEndColumn';
import { useContractSKPColumn } from '../../hooks/useContractSKPColumn';
import { useCreatorColumn } from '../../hooks/useCreatorColumn';
import { useCreationDateColumn } from '../../hooks/useCreationDateColumn';
import { useCommentColumn } from '../../hooks/useCommentColumn';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { useParams } from 'react-router-dom';
import { useGetProjectsQuery } from '../../../../lib/store/api/project';
import { TablePagination } from '../../../../lib/types/pagination';
import { resetFields, setPage } from '../../../../lib/store/slices/projectsSlice';
import { GetProjectsPayloadT } from '../../../../lib/types/projects';
import { checkIsManager } from '../../../../lib/utils/roles';
import { TableColumn } from '../../../../lib/models/Table';

const COLUMN_INSERT_INDEX = 1;

export const ProjectsTable: FC = () => {
  const page = useAppSelector(({ projects }) => projects.page);
  const [areColumnsExpanded, setAreColumnsExpanded] = useState(false);
  const dispatch = useAppDispatch();
  const { id: tenantId } = useParams();
  const { role } = useAppSelector(({ auth }) => auth);

  const filters = useAppSelector(({ projects }) => {
    const arrangedId = projects.arrangeId;
    return { arrangedId, ...projects.filtering, ...projects.sorting };
  });

  const {
    data: projectsData,
    isLoading,
    isFetching,
  } = useGetProjectsQuery({
    tenantId: String(tenantId),
    page: normalizePageForRequest(page || 1),
    ...filters,
  } as GetProjectsPayloadT);

  const responsePagination: TablePagination = {
    pageSize: projectsData?.pageable.pageSize,
    current: normalizePageForTable(projectsData?.pageable?.pageNumber || 0),
    total: projectsData?.totalElements,
  };

  const dataSource = projectsData?.content || [];

  const visibleColumns = [
    useProjectNameColumn(areColumnsExpanded),
    useExpandColumn({ areColumnsExpanded, setAreColumnsExpanded }),
    useUsedTemplateColumn(),
    useProjectStatusColumn(),
    useEditModeColumn(),
    useLastUpdateColumn(),
    useLastEditedFromColumn(),
    usePublishedColumn(),
    useUrlColumn(),
    useProjectActionColumn(),
  ];

  const hiddenColumns = [
    useContractStartColumn(),
    useContractEndColumn(),
    useContractSKPColumn(),
    useCreatorColumn(),
    useCreationDateColumn(),
    useCommentColumn(),
  ];

  const allColumns = insertAtIndex(visibleColumns, hiddenColumns, COLUMN_INSERT_INDEX);
  const displayedColumns = areColumnsExpanded ? allColumns : visibleColumns;
  const filteredColumns = checkIsManager(role)
    ? displayedColumns
    : displayedColumns.filter((column: { dataIndex: string }) => column.dataIndex !== TableColumn.ACTIONS);

  useEffect(() => {
    dispatch(resetFields());
  }, [dispatch]);

  useEffect(() => {
    if (projectsData?.numberOfElements === 0 && page !== 1) {
      dispatch(setPage(page - 1));
    }
  }, [projectsData?.numberOfElements]);

  return (
    <Table
      columns={filteredColumns}
      rowKey="id"
      dataSource={dataSource}
      pagination={responsePagination}
      loading={isLoading || isFetching}
      scroll={{ x: 'max-content' }}
      onChange={(tablePagination) => {
        dispatch(setPage(tablePagination.current as number));
      }}
    />
  );
};
