import { Form, FormInstance } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FC } from 'react';
import { FormItemName } from '../../lib/models/Form';

type Props = {
  form: FormInstance;
};
export const TextEditor: FC<Props> = ({ form }) => {
  const handleChangeText = (code: string) =>
    form.setFieldValue(FormItemName.DATA_PROTECTION_CONTENT, code);

  return (
    <ReactQuill
      theme="snow"
      value={Form.useWatch(FormItemName.DATA_PROTECTION_CONTENT, form)}
      onChange={handleChangeText}
    />
  );
};
