import { Trans, useTranslation } from 'react-i18next';
import { Form, message, MessageArgsProps, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../../lib/store/hooks';
import { ModalActions } from '../../../../../lib/models/Modal';
import { useResetFormOnCloseModal } from '../../../../../lib/hooks/useResetFormOnCloseModal';
import { AppModal } from '../../../../../components/AppModal';
import { useDeleteDemandAreaMutation } from '../../../../../lib/store/api/demandArea';
import { setModalClosed } from '../../../../../lib/store/slices/demandAreasSlice';

const MESSAGE_SHOWING_TIME = 3;
const { Paragraph } = Typography;

const deleteText = 'pages.products.demandArea.deleteText';
const deleteDemandAreaText = 'pages.products.demandArea.deleteDemandArea';
const errors = 'pages.products.demandArea.errors';
const success = 'pages.products.demandArea.success';

type Props = {
  tenantId: string;
};

export const DeleteAreaModal = ({ tenantId }: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const dispatch = useAppDispatch();

  const { selectedDemandArea, modalType, isModalOpen } = useAppSelector(({ demandAreas }) => demandAreas);
  const [deleteDemandArea, { isLoading }] = useDeleteDemandAreaMutation();

  const isOpen = isModalOpen && modalType === ModalActions.DELETE;

  const onClose = () => {
    dispatch(setModalClosed());
  };

  useResetFormOnCloseModal({ form, isOpen });

  const onConfirm = () => {
    form.submit();
  };

  const onFinish = async () => {
    const messageConfig = {} as MessageArgsProps;

    try {
      await deleteDemandArea({ demandAreaId: selectedDemandArea!.id, tenantId });
      messageConfig.content = t(`${success}.deleteDemandArea`);
      messageConfig.type = 'success';
      onClose();
    } catch (err: any) {
      messageConfig.content = err?.data?.message || t(`${errors}.deleteDemandArea`);
      messageConfig.type = 'error';
    } finally {
      messageApi.open({
        duration: MESSAGE_SHOWING_TIME,
        ...messageConfig,
      } as MessageArgsProps);
    }
  };

  return (
    <AppModal
      open={isOpen}
      onCancel={onClose}
      onOk={onConfirm}
      okText={t('generic.delete')}
      titleText={t(deleteDemandAreaText)}
      isOkLoading={isLoading}
    >
      {contextHolder}
      {isOpen && (
        <Form form={form} onFinish={onFinish}>
          <Paragraph style={{ marginBottom: '36px' }}>
            <Trans
              i18nKey={deleteText}
              values={{ demandArea: selectedDemandArea?.name }}
              components={{
                bold: <span className="bold secondary_color" />,
              }}
            />
          </Paragraph>
        </Form>
      )}
    </AppModal>
  );
};
