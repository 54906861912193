import { useState } from 'react';
import { Form } from 'antd';
import { useAppSelector } from '../../../lib/store/hooks';
import { useDateArrange } from '../../userManagement/hooks/table/useDateArrange';
import { useTenantArrangeRequests } from './useTenantArrangeRequests';
import { clearDateFilters } from '../../../lib/utils/arranges-helpers';
import { useDateColumn } from '../../../features/table/helpers/useDateColumn';

type Props = {
  title: string;
  arrangedId: string;
  filterFromName: string;
  filterToName: string;
  headerCellProps?: any;
};

export const useTenantDateColumn = ({
  title,
  arrangedId,
  filterFromName,
  filterToName,
  headerCellProps,
}: Props) => {
  const { arrangeId, sorting } = useAppSelector(({ tenants }) => tenants);
  const { appLang } = useAppSelector(({ auth }) => auth);

  const isActiveArrange = arrangeId === arrangedId;
  const [form] = Form.useForm();
  const [filterLabel, setFilterLabel] = useState('');

  const { getFilterLabel, formattedFromDate, formattedToDate } = useDateArrange(form, appLang);

  const getFilters = () => ({
    [filterFromName]: formattedFromDate,
    [filterToName]: formattedToDate,
  });

  const { applyArranges, removeFilter } = useTenantArrangeRequests({
    arrangedId,
    clearFilters: () => clearDateFilters(form),
    getFilterLabel,
    getFilters,
    form,
    setFilterLabel,
    isNotArrayFilterValues: true,
  });

  return useDateColumn({
    title,
    applyArranges,
    removeFilter,
    arrangedId,
    isActiveArrange,
    form,
    sortOrder: sorting.sortOrder,
    filterLabel,
    ...headerCellProps,
  });
};
