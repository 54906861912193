import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalActions } from '../../models/Modal';
import { TableSortOrder } from '../../utils/constants';
import { Sort } from '../../types/filteringAndSort';
import { DEMAND_AREAS_COLUMN, DemandArea } from '../../types/demandArea';

type DemandAreasSliceStateT = {
  modalType: ModalActions | null;
  isModalOpen: boolean;
  selectedDemandArea: DemandArea | null;
  page: number;
  sorting: Sort;
  arrangeId: DEMAND_AREAS_COLUMN | null;
};

const initialState: DemandAreasSliceStateT = {
  arrangeId: null,
  page: 1,
  isModalOpen: false,
  modalType: null,
  selectedDemandArea: null,

  sorting: {
    sortOrder: TableSortOrder.NONE,
    sortFieldName: null,
    withSorting: true,
  },
};

const demandAreasSlice = createSlice({
  name: 'demandAreasSlice',
  initialState,
  reducers: {
    setModalOpened: (state, action: PayloadAction<ModalActions>) => {
      state.modalType = action.payload;
      state.isModalOpen = true;
    },
    setModalClosed: (state) => {
      state.selectedDemandArea = null;
      state.modalType = null;
      state.isModalOpen = false;
    },
    setCurrentDemandArea: (state, action: PayloadAction<DemandArea>) => {
      state.selectedDemandArea = action.payload;
    },
    setSorting: (state, action: PayloadAction<{ sorting: Sort; arrangeId: DEMAND_AREAS_COLUMN }>) => {
      state.arrangeId = action.payload.arrangeId;
      state.sorting = action.payload.sorting;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
});

export const { setModalOpened, setModalClosed, setCurrentDemandArea, setSorting, setPage } =
  demandAreasSlice.actions;
export const demandAreas = demandAreasSlice.reducer;
