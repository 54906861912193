import { FC } from 'react';
import { TitleAndButton } from './TitleAndButton';
import { FilterLabel } from './FilterLabel';
import cx from 'classnames';

import styles from './index.module.scss';

type Props = any;

export const TableHeaderCell: FC<Props> = ({
  arrangedId,
  rootProps,
  left = null,
  right = null,
  title,
  removeFilter,
  filterLabel,
  sortOrder,
  isActiveArrange,
  children,
  withArrange = true,
  getParsedItems,
  removeFilterValue,
  titleAndButtonRootClassName,
}) => {
  const showFilterLabel = isActiveArrange && filterLabel;

  return (
    <div className={cx(styles.wrapper, rootProps?.className)}>
      {/* for collapsed button */}
      {left}

      <div className={styles.content}>
        <TitleAndButton
          titleAndButtonRootClassName={titleAndButtonRootClassName}
          withArrange={withArrange}
          title={title}
          isActiveArrange={isActiveArrange}
          sortOrder={sortOrder}
          arrangedId={arrangedId}
        >
          {children}
        </TitleAndButton>
        {showFilterLabel && (
          <FilterLabel
            filterLabel={filterLabel}
            removeFilter={removeFilter}
            getParsedItems={getParsedItems}
            removeFilterValue={removeFilterValue}
          />
        )}
      </div>

      {right}
    </div>
  );
};
