import { useEffect, useState } from 'react';
import { findProductRecommendationByPriority } from '../helpers';
import { ProductRecommendationSectionT } from '../../../lib/types/sections';
import { SectionName } from '../../../lib/models/Section';
import { useAppSelector } from '../../../lib/store/hooks';
import {
  useGetRecommendationsQuery,
  useStartCalculationRulesForGuestMutation,
} from '../../../lib/store/api/endUser';
import { ProductRecommendationT } from '../../../lib/types/product';

export const useGetProductRecommendationSection = () => {
  const {
    sections,
    wishlistProducts,
    allProducts,
    invisibleCategoryProducts,
    tenantAndProject,
    isGuestMode,
  } = useAppSelector(({ endUser }) => endUser);

  const [guestRecommendations, setGuestRecommendation] = useState<ProductRecommendationT[]>([]);

  const [startCalculationForGuest] = useStartCalculationRulesForGuestMutation();

  const getGuestUserRecommendations = async () => {
    try {
      const { recommendations } = await startCalculationForGuest().unwrap();

      setGuestRecommendation(recommendations);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  useEffect(() => {
    if (isGuestMode) {
      getGuestUserRecommendations();
    }
  }, [isGuestMode]);

  const { data: productRecommendationsData } = useGetRecommendationsQuery(
    {
      tenantId: String(tenantAndProject?.tenantId),
    },
    {
      skip: isGuestMode,
      refetchOnMountOrArgChange: true,
    },
  );

  //eslint-disable-next-line
  const productRecommendationSectionContent: any = sections?.[SectionName.PRODUCT_RECOMMENDATION];
  const recommendProductIds: number[] = [];

  const findRecommendationProduct = (position: number) => {
    const recommendationsData = isGuestMode ? guestRecommendations : productRecommendationsData?.content;

    const foundRecommendation = recommendationsData?.find(
      (recommendation) => recommendation.position === position,
    );

    const foundProductsByRecommendationId = [...allProducts, ...invisibleCategoryProducts]
      ?.filter((product) => foundRecommendation?.products.includes(product.id))
      ?.filter((product) => !recommendProductIds.includes(product.id));

    const product = findProductRecommendationByPriority(
      foundProductsByRecommendationId,
      foundRecommendation?.priority,
    );

    if (product) {
      recommendProductIds.push(product.id);
    }

    return product;
  };

  const preparedProducts =
    productRecommendationSectionContent?.products?.map((product: { position: number }) => {
      return {
        ...product,
        product: findRecommendationProduct(product.position),
      };
    }) || ([] as ProductRecommendationT[]);

  const productRecommendationSection: ProductRecommendationSectionT = {
    ...productRecommendationSectionContent,
    arePointsShown: true,
    products: preparedProducts?.map((item: { product: { productId: string } }) =>
      item.product &&
      wishlistProducts.find((wishlistProduct) => wishlistProduct.productId === item?.product?.productId)
        ? {
            ...item,
            product: { ...item.product, isWishlist: true },
          }
        : item,
    ),
  };

  return { productRecommendationSection };
};
