import { useTranslation } from 'react-i18next';
import { User } from '../../../helpers/constants';
import { useUserSearchDataColumn } from '../useUserSearchDataColumn';

const personName = 'pages.userManagement.columns.personName';

export const useUserPersonNameColumn = () => {
  const { t } = useTranslation();

  return useUserSearchDataColumn({
    title: t(personName),
    arrangedId: User.PERSON_NAME,
    searchFieldName: 'searchByFullName',
    filterFieldName: 'includeFullNames',
    columnProps: {
      width: 230,
    },
  });
};
