import React, { FC } from 'react';
import { DemandArea } from '../../../../lib/types/endUser';
import { CoreVisualSectionT } from '../../../../lib/types/sections';
import { parseJSON } from '../../../../lib/utils/helpers';
import { IconComponent } from '../../../../components/IconComponent';
import styles from './index.module.scss';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { useTranslation } from 'react-i18next';
import { getCoveredCategories } from '../../../../lib/utils/end-user-helpers';
import cn from 'classnames';

const translation = 'endUser.shared';

const ITEM_HEIGHT = {
  one: 180,
  two: 220,
  three: 154,
  default: 143,
};

const getItemHeightDependsOnAreaLength = (length: number) => {
  switch (length) {
    case 1:
      return ITEM_HEIGHT.one;
    case 2:
      return ITEM_HEIGHT.two;
    case 3:
      return ITEM_HEIGHT.three;
    default:
      return ITEM_HEIGHT.default;
  }
};

type Props = {
  demandAreas?: DemandArea[];
  content?: CoreVisualSectionT;
  handleAreaClick: (areaId: number, name: string) => void;
  handleKeyDown: (event: React.KeyboardEvent<HTMLDivElement>, areaId: number, name: string) => void;
};

export const EndUserSquareTilesMobile: FC<Props> = ({ demandAreas, handleAreaClick, handleKeyDown }) => {
  const { t } = useTranslation();

  if (!demandAreas) {
    return null;
  }

  return (
    <div className={styles.container}>
      {demandAreas.map((area, index, array) => {
        const areaColor = parseJSON(area.color);
        const backgroundColor = `color-mix(in srgb, ${areaColor.value} 50%, #000000 5%)`;

        const isLastElement = index === array.length - 1;
        const isOddArea = (index + 1) % 2 !== 0;
        const isLastOddArea = isLastElement && isOddArea;

        const totalCategories = area?.categories.length;
        const coveredCategoriesAmount = getCoveredCategories(area?.categories)?.length;

        const itemHeight = getItemHeightDependsOnAreaLength(array.length);

        return (
          <div
            key={area.id}
            className={cn(styles.blurContainer, isLastOddArea && styles.lastAreaItem)}
            style={{ height: itemHeight }}
            onKeyDown={(event) => handleKeyDown(event, area.id, area.name)}
            tabIndex={0}
            role="button"
            onClick={() => handleAreaClick(area.id, area.name)}
          >
            <div className={styles.areaItem} style={{ backgroundColor }}>
              <IconComponent className={styles.icon} color="#fff" iconData={area?.icon} />
              <EndUserTypography
                type="headlineSixth"
                component="Title"
                className={styles.title}
                style={{ marginBottom: 0, color: '#fff' }}
              >
                {area.name}
              </EndUserTypography>
              <EndUserTypography
                type="subText"
                component="Text"
                className={styles.subTitle}
                style={{ color: '#fff', textAlign: 'center' }}
              >
                {t(`${translation}.coveredCategoriesAmount`, { coveredCategoriesAmount, totalCategories })}
              </EndUserTypography>
            </div>
          </div>
        );
      })}
    </div>
  );
};
