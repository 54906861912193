import { FC } from 'react';
import { Form, FormInstance } from 'antd';
import { ArrangeSortAndFilterPopupWrapper } from './ArrangeSortAndFilterPopupWrapper';
import { useResetArrange } from '../../helpers/useResetArrange';
import { SearchFilter } from '../SearchFilter';

type Props = {
  form: FormInstance<any>;
  onFinish: () => void;
  filtering: any;
  initialValues: any;
  isActiveArrange: boolean;
  minSearchLength?: number;
  disableSorting?: boolean;
  disableFiltering?: boolean;
};

export const ArrangeListPopup: FC<Props> = ({
  filtering,
  form,
  onFinish,
  initialValues = {},
  isActiveArrange,
  minSearchLength,
  disableSorting,
  disableFiltering,
}) => {
  useResetArrange({
    isActiveArrange,
    form,
    clearOptions: filtering.clearOptions,
  });

  return (
    <Form form={form} onFinish={onFinish} initialValues={initialValues}>
      <ArrangeSortAndFilterPopupWrapper disableFiltering={disableFiltering} disableSorting={disableSorting}>
        <SearchFilter filtering={filtering} minSearchLength={minSearchLength} />
      </ArrangeSortAndFilterPopupWrapper>
    </Form>
  );
};
