import { useTranslation } from 'react-i18next';
import { ActivatedStatusBodyCell } from '../../../../features/table/components/ActivatedStatusBodyCell';
import { DEMAND_AREAS_COLUMN } from '../../../../lib/types/demandArea';
import { TableHeaderCell } from '../../../../components/Table/TableHeaderCell/TableHeaderCell';

const status = 'shared.columns.status';

export const useDemandStatusColumn = () => {
  const { t } = useTranslation();

  return {
    dataIndex: DEMAND_AREAS_COLUMN.STATUS,
    title: <TableHeaderCell withArrange={false} title={t(status)} />,
    width: 151,
    render: (activated: boolean) => <ActivatedStatusBodyCell activatedStatus={activated} />,
  };
};
