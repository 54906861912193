import React from 'react';
import { Spin, SpinProps } from 'antd';
import styles from './index.module.scss';

export const PageLoader = ({ size = 'large', ...others }: SpinProps) => {
  return (
    <div className={styles.relative}>
      <div className={styles.root}>
        <Spin size={size} {...others} />
      </div>
    </div>
  );
};
