import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Radio, Typography } from 'antd';
import { ArrangeFormItem } from '../../../../lib/models/Arrange';
import { TableSortOrder } from '../../../../lib/utils/constants';

import styles from './index.module.scss';

const { Title } = Typography;
const { Group } = Radio;

const sort = 'shared.arranges.sort';

type Props = {
  isDate?: boolean;
};

export const SortBlock: FC<Props> = ({ isDate }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Title level={3} type="secondary" style={{ fontSize: '12px', marginBottom: '16px' }}>
        {t(`${sort}.title`)}
      </Title>

      <Form.Item className={styles.radioGroupWrapper} name={ArrangeFormItem.SORT_ORDER}>
        <Group className={styles.radioGroup}>
          <Radio value={TableSortOrder.ASC}>
            {isDate ? t(`${sort}.radioButtons.oldest`) : t(`${sort}.radioButtons.acs`)}
          </Radio>
          <Radio value={TableSortOrder.DESC}>
            {isDate ? t(`${sort}.radioButtons.newest`) : t(`${sort}.radioButtons.desc`)}
          </Radio>
          <Radio value={TableSortOrder.NONE}>{t(`${sort}.radioButtons.none`)}</Radio>
        </Group>
      </Form.Item>
    </div>
  );
};
