import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormItemName } from '../../../../../lib/models/Form';
import { AppSelect } from '../../../../../components/AppSelect';
import { FormItem } from '../../../../../components/FormItem';

import styles from './index.module.scss';

const icon = 'pages.products.productCategories.formItems.icon';

type Props = {
  options?: {
    value: number | string;
    title: JSX.Element;
  }[];
};

export const IconFormItem: FC<Props> = ({ options }) => {
  const { t } = useTranslation();

  return (
    <FormItem
      hasFeedback
      name={FormItemName.ICON}
      rules={[{ required: true, message: t(`${icon}.error`) }]}
      className={styles.formItem}
    >
      <AppSelect className={styles.select} options={options} label={t(`${icon}.label`)} />
    </FormItem>
  );
};
