import React, { FC, useCallback, useEffect, useState } from 'react';
import { IconButton } from '../IconButton';
import { ReactComponent as BookmarkIcon } from './../../assets/icons/bookmark.svg';
import { ReactComponent as BookmarkFillIcon } from './../../assets/icons/bookmark-fill.svg';
import { Tooltip } from 'antd';

import cn from 'classnames';
import { ProductT } from '../../lib/types/product';
import {
  setWishListRuleBonuses,
  setWishlistProduct,
  setWishlistProducts,
} from '../../lib/store/slices/endUserSlice';
import { sendDataToAnalytics } from '../../lib/utils/sendDataToAnalytics';
import { SectionName } from '../../lib/models/Section';
import { useUpdateWishlist } from '../../lib/hooks/endUserHooks/useUpdateWishlist';
import { useStartCalculationRulesForGuestMutation } from '../../lib/store/api/endUser';
import { useGetRuleActionStatusLevel } from '../../lib/hooks/endUserHooks/useGetRuleActionStatusLevel';
import { useAppDispatch, useAppSelector } from '../../lib/store/hooks';

import styles from './styles.module.scss';
import { getWishlistStatusLevelsData } from '../../modules/endUserStatusLevel/utils/helpers';
import { useCheckUserStatusLevelNotification } from '../../lib/hooks/endUserHooks/useCheckUserStatusLevelNotification';
import { useTranslation } from 'react-i18next';

const tooltipText = 'endUser.shared.wishlistButton.tooltip';

type Props = {
  product: ProductT;
  primaryColor?: string;
  isInWishlist?: boolean;
  isGuestMode?: boolean;
  sectionNameForAnalytics: SectionName;
  isIrrelevant?: string;
};

export const EndUserWishlistButton: FC<Props> = ({
  product,
  primaryColor,
  isInWishlist,
  isGuestMode,
  isIrrelevant,
  sectionNameForAnalytics,
}) => {
  const [isInBookmark, setIsInBookmark] = useState(false);
  const { updateWishlist, isLoading } = useUpdateWishlist(isInBookmark);
  const [startCalculationForGuest] = useStartCalculationRulesForGuestMutation();
  const { updateRuleActionStatusLevel } = useGetRuleActionStatusLevel();
  const { checkUserStatusLevelNotification } = useCheckUserStatusLevelNotification();
  const { allProducts, invisibleCategoryProducts, userStatusLevel } = useAppSelector(
    ({ endUser }) => endUser,
  );
  const { currentPointsInWishlist, wishedPoints } = userStatusLevel;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const handleClickBookmark = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      setIsInBookmark((prevState) => !prevState);

      if (!isInBookmark) {
        if (sectionNameForAnalytics === SectionName.PRODUCT_RECOMMENDATION) {
          sendDataToAnalytics(
            'Merken eines Produkts aus den Empfehlungen',
            'Produkt auf Merkliste',
            `User merkt das Produkt ${product.name} aus den Empfehlungen`,
          );
        } else {
          sendDataToAnalytics(
            'Interesse Produkt',
            'Produkt auf Merkliste',
            `User setzt das Produkt ${product.name} auf die Merklsite`,
          );
        }
      }

      if (!isGuestMode) {
        await updateWishlist(product);

        if (!isInBookmark) {
          //
        }
      } else {
        dispatch(setWishlistProduct(product));
        const { wishList, endUserStatusLevels, wishListBonuses } = await startCalculationForGuest().unwrap();

        updateRuleActionStatusLevel(endUserStatusLevels);

        const wishlistProducts = wishList.map((item) => ({ ...item.product }));
        dispatch(setWishlistProducts(wishlistProducts));
        dispatch(setWishListRuleBonuses(wishListBonuses));

        const { wishedPoints: newWishedPoints, currentPointsInWishlist: newCurrentPoints } =
          getWishlistStatusLevelsData(wishlistProducts, [...allProducts, ...invisibleCategoryProducts]);

        if (newWishedPoints + newCurrentPoints > wishedPoints + currentPointsInWishlist) {
          checkUserStatusLevelNotification({
            prevPoints: wishedPoints + currentPointsInWishlist,
            newPoints: newWishedPoints + newCurrentPoints,
          });
        }
      }
    },
    [
      isInBookmark,
      isGuestMode,
      sectionNameForAnalytics,
      product,
      updateWishlist,
      dispatch,
      startCalculationForGuest,
      updateRuleActionStatusLevel,
      allProducts,
      invisibleCategoryProducts,
      wishedPoints,
      currentPointsInWishlist,
      checkUserStatusLevelNotification,
    ],
  );

  useEffect(() => {
    setIsInBookmark(!!isInWishlist);
  }, [isInWishlist]);

  return (
    <Tooltip title={t(tooltipText)}>
      <div>
        <IconButton
          isLoading={isLoading}
          onClick={handleClickBookmark}
          className={cn(
            styles.button,
            isIrrelevant && styles.overlayActive,
            isIrrelevant && !isInWishlist && styles.hidden,
          )}
          icon={isInBookmark ? <BookmarkFillIcon fill={primaryColor} /> : <BookmarkIcon />}
        />
      </div>
    </Tooltip>
  );
};
