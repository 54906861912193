import { FC, Fragment } from 'react';
import { Divider } from 'antd';
import { SmallStatusLevelCard } from '../SmallStatusLevelCard';
import { IconT } from '../../../designSettings/types';
import { useAppSelector } from '../../../../lib/store/hooks';
import { ColorVariants } from '../../../../lib/types/colors&fonts';

import styles from './index.module.scss';

type Props = {
  levels: any[];
  userPoints: any;
  icon: IconT | undefined;
  maxPoints: number;
  isWishlist?: boolean;
  wishlistPoints?: number;
};

export const SmallStatusLevelsList: FC<Props> = ({
  levels,
  userPoints,
  icon,
  isWishlist,
  wishlistPoints = 0,
  maxPoints,
}) => {
  const { brandKit } = useAppSelector(({ endUser }) => endUser);

  return (
    <div className={styles.wrapper} style={{ color: brandKit?.colors[ColorVariants.PRIMARY_COLOR] }}>
      {levels.map((item: any, index: number) => {
        const firstSubLevel = item.subLevels[0];
        const points = userPoints + wishlistPoints > maxPoints ? maxPoints : userPoints + wishlistPoints;
        const isActive = points > firstSubLevel.points - 1;

        return (
          <Fragment key={item.id}>
            {index !== 0 && (
              <Divider
                type="vertical"
                className={styles.divider}
                style={{ height: isWishlist ? 'auto' : 38 }}
              />
            )}
            <SmallStatusLevelCard
              userPoints={userPoints}
              levels={levels}
              icon={icon}
              active={isActive}
              points={firstSubLevel.points}
              status={item.name}
              subLevels={item.subLevels}
              isWishlist={isWishlist}
              wishlistPoints={wishlistPoints}
              maxPoints={maxPoints}
              color={brandKit?.colors[ColorVariants.PRIMARY_COLOR]}
            />
          </Fragment>
        );
      })}
    </div>
  );
};
