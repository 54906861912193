import { Checkbox, Form } from 'antd';
import { EndUserButton } from '../../../../components/EndUserButton';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { FormItem } from '../../../../components/FormItem';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { setGuestMode, setMatomoTrackingForGuest } from '../../../../lib/store/slices/endUserSlice';
import { EndUserAuthBottomButtons } from '../../../../components/EndUserAuthBottomButtons';
import { SectionName } from '../../../../lib/models/Section';
import { useGetCheckboxAgreementText } from '../../../endUserSignUp/hooks/useGetCheckboxAgreementText';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';

const guestLogin = 'endUser.guestLogin';
const mandatory = 'endUser.shared.mandatory';

export const EndUserTermsAcceptance = () => {
  const { designSettings, model, sections, demandArea } = useAppSelector(({ endUser }) => endUser);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const dataProtectionLink = demandArea?.tenant.dataProtection || '/';
  const agbLink = demandArea?.tenant.agb || '/';
  const currentSectionContent = sections?.[SectionName.AGREEMENTS];

  const { getCheckboxText } = useGetCheckboxAgreementText({
    agbLink,
    dataProtectionLink,
    clientName: demandArea?.tenant?.clientName || '',
  });

  const logoS3Url = designSettings?.headerConfig?.logo?.s3Url;

  const [form] = Form.useForm();

  const onFinish = () => {
    dispatch(setGuestMode(true));
  };

  const toggleMatomoTracking = (tracking: boolean) => {
    dispatch(setMatomoTrackingForGuest({ trackingEnabled: tracking }));
  };

  return (
    <div className={styles.container}>
      <div>
        <img className={styles.logo} alt="logo" src={logoS3Url} />
      </div>

      <div className={styles.main}>
        <EndUserTypography className={styles.title} type="headlineSecond" component="Title" level={2}>
          {t(`${guestLogin}.title`)}
        </EndUserTypography>
        <Form form={form} onFinish={onFinish}>
          <FormItem className={styles.formItem} valuePropName="checked" name="terms-a">
            <Checkbox
              onClick={(event: any) => {
                toggleMatomoTracking(event.target.checked);
              }}
              className={styles.checkBox}
            >
              <EndUserTypography type="headlineSeventh" component="Text">
                {getCheckboxText(currentSectionContent?.agreeUseName)}
              </EndUserTypography>
            </Checkbox>
          </FormItem>

          <FormItem
            className={styles.formItem}
            valuePropName="checked"
            name="terms-b"
            hasFeedback={true}
            rules={[
              {
                required: true,
                message: '',
                transform: (value: any) => value || undefined, // Those two lines
                type: 'boolean',
              },
            ]}
          >
            <Checkbox>
              <EndUserTypography type="headlineSeventh" component="Text">
                {getCheckboxText(currentSectionContent?.readTermsAndPrivacyPolicy)}
              </EndUserTypography>
            </Checkbox>
          </FormItem>

          <EndUserButton className={styles.btn} htmlType="submit" type="primary">
            {model?.name} {t(`${guestLogin}.enter`)}
          </EndUserButton>
          <EndUserTypography type="subText" component="Text">
            * {t(`${mandatory}`)}
          </EndUserTypography>
        </Form>
      </div>

      <div className={styles.footer}>
        <EndUserAuthBottomButtons />
      </div>
    </div>
  );
};
