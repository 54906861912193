import { FormItemName } from '../../../lib/models/Form';
import { AddTenantPayload, TenantT } from '../../../lib/types/tenants';
import { isLength } from '../../../lib/utils/helpers';
import { TenantModalFormValuesT } from '../types';

export function prepareTenantForm(formValues: TenantModalFormValuesT) {
  const { users: userFormValue, domains, clientWeb, ...rest } = formValues;
  const users =
    userFormValue && isLength(userFormValue) ? userFormValue?.map((item) => ({ id: item.value })) : [];

  return {
    users,
    domains: domains.map((domain) => ({ id: domain.value })),
    clientWeb: clientWeb ? clientWeb.replace(/^https?:\/\//, '') : null,
    ...rest,
  } as AddTenantPayload;
}

export const getFieldsData = (tenantData: TenantT) => {
  return [
    { name: FormItemName.TENANT_NAME, value: tenantData.name },
    {
      name: FormItemName.USERS,
      value: isLength(tenantData?.users) ? tenantData.users.map((item) => ({ value: item.id })) : [],
    },
    {
      name: FormItemName.DOMAINS,
      value: isLength(tenantData?.domains) ? tenantData?.domains.map((item) => ({ value: item.id })) : [],
    },
    { name: FormItemName.COMMENTS, value: tenantData.comment },
    { name: FormItemName.CLIENT_NAME, value: tenantData.clientName },
    { name: FormItemName.CLIENT_ADDRESS, value: tenantData.clientAddress },
    { name: FormItemName.LEGAL_NOTICE, value: tenantData.impressum },
    { name: FormItemName.HELP_AND_CONTACT, value: tenantData.hilfeUndKontakt },
    {
      name: FormItemName.ADDRESS_LINE_TWO,
      value: tenantData.clientAddressLine2,
    },
    { name: FormItemName.PLZ, value: tenantData.plz },
    { name: FormItemName.CLIENT_CITY, value: tenantData.clientCity },
    { name: FormItemName.CLIENT_EMAIL, value: tenantData.clientEmail },
    { name: FormItemName.CLIENT_PHONE, value: tenantData.clientPhone },
    { name: FormItemName.CLIENT_WEBSITE, value: tenantData.clientWeb },
    { name: FormItemName.DATA_PROTECTION_URL, value: tenantData.dataProtection },
    { name: FormItemName.AGB, value: tenantData.agb },
  ];
};
