import { FormInstance, Typography } from 'antd';
import { formatDateForTable } from '../utils/helpers';
import { DateHeaderCell } from '../components/DateHeaderCell';
import { Dispatch } from 'react';

const { Text } = Typography;

type Props = {
  isActiveArrange: boolean;
  arrangedId: string;
  form: FormInstance;
  sortOrder: any;
  setFilterLabel: Dispatch<string>;
  applyArranges: () => Promise<void>;
  removeFilter: () => Promise<void>;
};

export const useDateColumn = (props: Props) => {
  const { arrangedId, ...rest } = props;

  return {
    dataIndex: arrangedId,
    title: <DateHeaderCell arrangedId={arrangedId} {...rest} />,
    render: (date: Date | string) => <Text>{date ? formatDateForTable(date) : '-'}</Text>,
  };
};
