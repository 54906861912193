import { FC } from 'react';
import { StatusLevelHeaderOptions } from '../StatusLevelHeaderOptions';
import { StatusLevelTable } from '../StatusLevelTable';
import { MessageInstance } from 'antd/es/message/interface';

type Props = {
  messageApi: MessageInstance;
};

export const StatusLevelTab: FC<Props> = ({ messageApi }) => {
  return (
    <>
      <StatusLevelHeaderOptions messageApi={messageApi} />
      <StatusLevelTable />
    </>
  );
};
