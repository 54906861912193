import { FC, useMemo } from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemName } from '../../../../lib/models/Form';
import { usePasswordValidation } from '../../hooks/usePasswordValidation';
import { FormInput } from '../../../../components/FormInput';
import { FormItem } from '../../../../components/FormItem';
import { ValidationChecking } from '../ValidationChecking';
import { useSetupPasswordTranslatedHints } from '../../hooks/useSetupPasswordTranslatedHints';
import { getCheckedHintsArray } from '../../helpers/getCheckedHintsArray';

type Props = {
  errors: string[];
  isTouched: boolean;
  label?: string;
};

export const SetupPasswordFormItem: FC<Props> = ({ errors, isTouched, label }) => {
  const { t } = useTranslation();

  const { settingPasswordRules } = usePasswordValidation();
  const { initialCheckingArray } = useSetupPasswordTranslatedHints();

  const checkingArray = useMemo(() => {
    return getCheckedHintsArray(initialCheckingArray, errors, isTouched);
  }, [errors, initialCheckingArray, isTouched]);

  return (
    <>
      <FormItem
        name={FormItemName.PASSWORD}
        rules={([{ required: true }] as unknown[]).concat(settingPasswordRules)}
        help={false}
      >
        <FormInput as={Input.Password} label={label ? t(label) : t('formItems.password.label')} />
      </FormItem>
      <ValidationChecking validationItems={checkingArray} />
    </>
  );
};
