import { DemandArea } from '../../../lib/types/endUser';

type CategoryWidthT = {
  topLeftWidth: number | string;
  topRightWidth: number | string;
  bottomLeftWidth: number | string;
  bottomRightWidth: number | string;
};

export const useGetCategoryWidth = (demandAreas?: DemandArea[]): CategoryWidthT => {
  if (!demandAreas) {
    return {
      topLeftWidth: 0,
      topRightWidth: 0,
      bottomLeftWidth: 0,
      bottomRightWidth: 0,
    };
  }

  const columnWidth = demandAreas.reduce((acc, currentValue, index) => {
    const categoriesCount = currentValue.categories.length;

    let width: string | number = 0;

    if (categoriesCount === 1) {
      width = '100%';
    } else if (categoriesCount === 2) {
      width = '50%';
    } else if (categoriesCount === 3) {
      width = '30%';
    } else if (categoriesCount === 4) {
      width = '22%';
    }

    return {
      ...acc,
      ...(index === 0 && {
        topLeftWidth: width,
      }),
      ...(index === 1 && {
        topRightWidth: width,
      }),
      ...(index === 2 && {
        bottomLeftWidth: width,
      }),
      ...(index === 3 && {
        bottomRightWidth: width,
      }),
    };
  }, {});

  return columnWidth as CategoryWidthT;
};
