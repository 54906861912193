import { Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useResetFormOnCloseModal } from '../../../../../lib/hooks/useResetFormOnCloseModal';
import { AppModal } from '../../../../../components/AppModal';
import { useAppDispatch, useAppSelector } from '../../../../../lib/store/hooks';
import { ModalActions } from '../../../../../lib/models/Modal';
import { FormItemName } from '../../../../../lib/models/Form';
import NameFormItem from '../FormItems/NameFormItem';
import { TemplateFormItem } from '../FormItems/TemplateFormItem';
import { EmailSkpFormItem } from '../FormItems/EmailSKPFormItem';
import { DateFormItem } from '../FormItems/DateFormItem';
import styles from '../index.module.scss';
import { CreationDateFormItem } from '../FormItems/CreationDateFormItem';
import { CommentFormItem } from '../FormItems/CommentFormItem';
import { setModalClosed } from '../../../../../lib/store/slices/projectsSlice';
import { useCreateProjectMutation } from '../../../../../lib/store/api/project';
import { CreateProjectPayload } from '../../../../../lib/types/projects';
import { transformToDateWithSlash } from '../../../../../lib/utils/helpers';
import { transformProjectToSendData } from '../../../helpers/helpers';

const projectsTranslation = 'pages.projects';

export type CreateProjectValuesT = {
  name: string;
  template: { title: string; value: string };
  contactSKP?: { title: string; value: string };
  contractStart?: Date;
  contractEnd?: Date;
  comment?: string;
  tenantId: string;
};

type Props = {
  tenantId: string;
};

export const CreateProjectModal = ({ tenantId }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const { isModalOpen, modalType } = useAppSelector(({ projects }) => projects);
  const isOpened = isModalOpen && modalType === ModalActions.ADD;
  const [createProject, { isLoading }] = useCreateProjectMutation();

  useResetFormOnCloseModal({ form, isOpen: isOpened });

  const onFinish = async (values: CreateProjectValuesT) => {
    const sendData: CreateProjectPayload = {
      ...transformProjectToSendData(values),
      tenantId,
    };

    try {
      await createProject(sendData).unwrap();
      messageApi.success(t(`${projectsTranslation}.addModal.successText`));
      dispatch(setModalClosed());
    } catch (err: any) {
      messageApi.error(err?.data?.message || t(`${projectsTranslation}.addModal.errorText`));
    }
  };

  const onClose = () => {
    dispatch(setModalClosed());
  };

  const onConfirm = () => {
    form.submit();
  };

  return (
    <AppModal
      className={styles.modal}
      open={isOpened}
      onCancel={onClose}
      onOk={onConfirm}
      getContainer={false}
      titleText={t(`${projectsTranslation}.addModal.title`)}
      isOkLoading={isLoading}
    >
      {contextHolder}
      {isOpened && (
        <Form
          initialValues={{
            creationDateForUser: transformToDateWithSlash(new Date()),
          }}
          className={styles.form}
          form={form}
          onFinish={onFinish}
        >
          <NameFormItem />
          <TemplateFormItem tenantId={tenantId} />
          <EmailSkpFormItem />
          <div className={styles.form_dateInputsWrapper}>
            <DateFormItem
              form={form}
              name={FormItemName.CONTRACT_START}
              placeholder={t(`${projectsTranslation}.formItem.contractStartDate`)}
            />
            <DateFormItem
              form={form}
              name={FormItemName.CONTRACT_END}
              placeholder={t(`${projectsTranslation}.formItem.contractEndDate`)}
            />
          </div>
          <CreationDateFormItem />
          <CommentFormItem />
        </Form>
      )}
    </AppModal>
  );
};
