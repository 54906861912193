import { FC } from 'react';
import { Space } from 'antd';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash.svg';
import { ReactComponent as LinkIcon } from '../../../../assets/icons/link-icon.svg';
import { useTranslation } from 'react-i18next';
import { FormItemName } from '../../../../lib/models/Form';
import { IconButton } from '../../../../components/IconButton';

import styles from './index.module.scss';
import { FormSection } from '../../../../components/FormSection';

const linkUrl = 'pages.designSettingsTab.formItems.linkUrl';
const linkLabel = 'pages.designSettingsTab.formItems.linkLabel';

type Props = {
  name: number;
  index: number;
  remove: (arg: number | number[]) => void;
};

export const ButtonLinkRow: FC<Props> = ({ name, index, remove }) => {
  const { t } = useTranslation();

  return (
    <Space size="middle" className={styles.space}>
      <FormSection
        formItemProps={{
          hasFeedback: true,
          name: [name, FormItemName.LINK_NAME],
          style: { width: 420 },
          rules: [{ required: true, message: t(`${linkLabel}.errors.required`) }],
        }}
        textFieldProps={{ label: t(`${linkLabel}.label`, { index }) }}
      />
      <FormSection
        className={styles.buttonLink}
        formItemProps={{
          hasFeedback: true,
          name: [name, FormItemName.LINK_URL],
          style: { width: 420 },
          rules: [
            { required: true, message: t(`${linkUrl}.errors.required`) },
            { type: 'url', message: t(`${linkUrl}.errors.invalid`) },
          ],
        }}
        textFieldProps={{
          label: t(`${linkUrl}.label`),
          prefix: <LinkIcon />,
        }}
      />
      <div className={styles.trashButton}>
        <IconButton onClick={() => remove(name)} icon={<TrashIcon className={styles.trashIcon} />} />
      </div>
    </Space>
  );
};
