import { FC } from 'react';
import { IconButton } from '../../../../components/IconButton';
import { RightArrow } from '../../../../assets/icons/RightArrow';
import { LeftArrow } from '../../../../assets/icons/LeftArrow';

import styles from './index.module.scss';

type Props = {
  areColumnsExpanded: boolean;
  onClick: () => void;
};

export const ToggleExpandButton: FC<Props> = ({
  areColumnsExpanded,
  onClick,
}) => {
  return (
    <IconButton
      className={areColumnsExpanded ? styles.rootExpanded : styles.root}
      onClick={onClick}
      icon={areColumnsExpanded ? <LeftArrow /> : <RightArrow />}
    />
  );
};
