import { FC } from 'react';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/cross-in-circle.svg';
import { useTranslation } from 'react-i18next';
import { FormItemName } from '../../../../lib/models/Form';
import { IconButton } from '../../../../components/IconButton';
import { FormSection } from '../../../../components/FormSection';
import FormItem from 'antd/es/form/FormItem';
import { AppSelect } from '../../../../components/AppSelect';
import { operatorOptions } from '../../helpers';

import styles from './index.module.scss';

const errors = 'pages.products.productConfigurator.formItems.firstPoint.errors';

type Props = {
  name: number;
  index: number;
  remove: (arg: number | number[]) => void;
};

export const CoverageAmountRow: FC<Props> = ({ name, remove, index }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.space}>
      <FormItem
        className={styles.formItem}
        hasFeedback
        name={[name, FormItemName.OPERATOR]}
        initialValue={operatorOptions[0]}
      >
        <AppSelect options={operatorOptions} className={styles.select} />
      </FormItem>
      <FormSection
        className={styles.formSection}
        formItemProps={{
          hasFeedback: true,
          name: [name, FormItemName.COVERAGE_AMOUNT],
          rules: [
            { required: true, message: t(`${errors}.coverageAmountEmpty`) },
            ({ getFieldValue, ...rest }: { getFieldValue: any }) => ({
              validator(_: unknown, value: number) {
                const rules = getFieldValue(FormItemName.RECURRING_POINTS);
                let isNotAscendingOrder, isDuplicated;
                rules?.forEach((item: { coverageAmount: number }, ruleIndex: number) => {
                  if (item?.coverageAmount === value && index !== ruleIndex) {
                    isDuplicated = true;
                  }

                  if (value <= item?.coverageAmount && ruleIndex < index) {
                    isNotAscendingOrder = true;
                  }
                  if (value >= item?.coverageAmount && ruleIndex > index) {
                    isNotAscendingOrder = true;
                  }
                });

                if (isDuplicated) {
                  return Promise.reject(new Error(t(`${errors}.coverageAmountDuplicate`)));
                }
                if (isNotAscendingOrder) {
                  return Promise.reject(new Error(t(`${errors}.coverageAmountOrder`)));
                }
                return Promise.resolve();
              },
            }),
          ],
          normalize: (value: string) => (value ? Number(value) : undefined),
        }}
        textFieldProps={{ type: 'number' }}
      />
      <FormSection
        className={styles.formSection}
        formItemProps={{
          hasFeedback: true,
          name: [name, FormItemName.POINTS],
          rules: [{ required: true, message: t(`${errors}.pointsEmpty`) }],
          normalize: (value: string) => Number(value),
        }}
        textFieldProps={{
          type: 'number',
        }}
      />

      <div className={styles.trashButton} style={{ marginLeft: index === 0 ? 10 : 0 }}>
        {index !== 0 && (
          <IconButton onClick={() => remove(name)} icon={<TrashIcon className={styles.trashIcon} />} />
        )}
      </div>
    </div>
  );
};
