import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

export const useTabQuery = (activeKey?: string) => {
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (activeKey) {
      setSearchParams({ tab: activeKey });
    }
  }, [activeKey]);
};
