import { ReactNode, FC } from 'react';
import { FilterTitle } from './FilterTitle';

type Props = {
  children: ReactNode;
};

export const FilterBlockWrapper: FC<Props> = ({ children }) => {
  return (
    <div>
      <FilterTitle />
      {children}
    </div>
  );
};
