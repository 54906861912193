import { FC } from 'react';
import { SmallCurrentStatusLevelCard } from '../SmallCurrentStatusLevelCard';
import { SmallStatusLevelsList } from '../SmallStatusLevelsList';
import { EndUserWishlistProductsList } from '../../../../features/endUser/components/WishlistProductsList';
import { CurrentStatusLevelWishlistCard } from '../CurrentStatusLevelWishlistCard';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { useAppSelector } from '../../../../lib/store/hooks';
import { SectionName } from '../../../../lib/models/Section';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import { getDisplayedPointsAccordingToRules } from '../../utils/helpers';

import styles from './index.module.scss';

type Props = {
  withButton?: boolean;
};

export const StatusLevelWishlistSection: FC<Props> = () => {
  const { isLargerDesktop } = useContainerQuery();

  const {
    wishlistProducts,
    demandArea,
    statusLevels,
    sections,
    userStatusLevel: { wishedPoints, currentPointsInWishlist },
    ruleActionStatusLevel: { maxAvailablePoints, extraPoints },
  } = useAppSelector(({ endUser }) => endUser);

  const currentSectionContent = sections?.[SectionName.STATUS_SMALL];

  const maxPointsPerModel = demandArea?.project?.maxPoints || 0;
  const maxPoints = maxAvailablePoints != null ? maxAvailablePoints : maxPointsPerModel;

  const displayedUserPoints = getDisplayedPointsAccordingToRules({
    userPoints: currentPointsInWishlist,
    maxPoints,
    extraPoints,
  });

  const userPointsWithWishList = displayedUserPoints + wishedPoints;

  const subLevelsWithStatusLevel = statusLevels
    .flatMap((statusLevel: any) => {
      const firstSubLevel = statusLevel.subLevels[0];
      return firstSubLevel ? { ...firstSubLevel, statusLevelName: statusLevel.name } : null;
    })
    .filter(Boolean);

  let remainingPointsToNextLevel = 0;

  for (const subLevel of subLevelsWithStatusLevel) {
    if (subLevel.points > userPointsWithWishList) {
      remainingPointsToNextLevel = subLevel.points - userPointsWithWishList;
      break;
    }
  }

  return (
    <>
      <div className={styles.wrapper}>
        {isLargerDesktop && (
          <SmallCurrentStatusLevelCard
            icon={demandArea?.project?.pointsIcon}
            maxPoints={maxPointsPerModel}
            userPoints={userPointsWithWishList}
            remainingPointsToNextLevel={remainingPointsToNextLevel}
            levels={subLevelsWithStatusLevel}
            displayedUserPoints={displayedUserPoints}
          />
        )}

        <SmallStatusLevelsList
          isWishlist
          icon={demandArea?.project?.pointsIcon}
          levels={statusLevels}
          userPoints={displayedUserPoints}
          wishlistPoints={wishedPoints}
          maxPoints={maxPoints}
        />

        {!isLargerDesktop && (
          <>
            <div className={styles.productsCard}>
              <EndUserWishlistProductsList
                wishlistData={wishlistProducts}
                demandAreas={demandArea?.listDemandArea}
                className={styles.products}
              />
            </div>
            <div className={styles.currentStatusCard}>
              <EndUserTypography type="headlineFourth" component="Title" level={4}>
                {currentSectionContent?.headline3}
              </EndUserTypography>

              <CurrentStatusLevelWishlistCard
                icon={demandArea?.project?.pointsIcon}
                maxPoints={maxPointsPerModel}
                userPoints={userPointsWithWishList}
                remainingPointsToNextLevel={remainingPointsToNextLevel}
                levels={subLevelsWithStatusLevel}
                displayedUserPoints={displayedUserPoints}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
