import { useParams, useSearchParams } from 'react-router-dom';
import { AppTabs } from '../../components/AppTabs';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTabQuery } from '../../lib/hooks/useTabQuery';
import { useAppDispatch } from '../../lib/store/hooks';
import { RuleConfigurator } from '../../modules/ruleConfigurator/components/RuleConfigurator';
import { QueryParams, Tabs } from '../../lib/types/queryParams';
import { Button } from '../../components/Button';
import { setModalOpened } from '../../lib/store/slices/ruleBuilder';
import { ModalActions } from '../../lib/models/Modal';
import { RulesTab } from '../../modules/ruleConfigurator/components/RulesTab';
import { RuleTypesTab } from '../../modules/ruleConfigurator/components/RuleTypesTab';

import styles from './index.module.scss';

import { RuleStatusListener } from '../../modules/ruleConfigurator/components/RuleStatusListener';
import { useExecuteRuleMutation } from '../../lib/store/api/ruleBuilder';
import { RuleStatusT } from '../../lib/types/ruleBuilder';
import { RuleStatuses } from '../../modules/ruleConfigurator/components/RuleStatuses';

const tabs = 'platform.tabs';
const ruleBuilder = 'pages.ruleBuilder';

export const RuleConfiguratorPage = () => {
  const { id: tenantId, modelId } = useParams();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const queryTab = searchParams.get(QueryParams.TAB);
  const [messageApi, contextHolder] = message.useMessage();

  const [ruleStatus, setRuleStatus] = useState(RuleStatusT.LOADING);

  const [executeRule] = useExecuteRuleMutation();

  const [activeKey, setActiveKey] = useState<Tabs | null>(null);
  useTabQuery(activeKey as string);

  useEffect(() => {
    setActiveKey(queryTab ? (queryTab as Tabs) : Tabs.RULES);
  }, [queryTab]);

  const handleTabChange = (key: string) => {
    setActiveKey(key as Tabs);
  };

  const items = [
    {
      label: t(`${tabs}.rulesTable`),
      children:
        activeKey === Tabs.RULES ? (
          <RulesTab tenantId={tenantId as string} modelId={modelId as string} />
        ) : null,
      key: Tabs.RULES,
    },
    {
      label: t(`${tabs}.ruleTypes`),
      children:
        activeKey === Tabs.RULE_TYPES ? (
          <RuleTypesTab tenantId={tenantId as string} modelId={modelId as string} />
        ) : null,
      key: Tabs.RULE_TYPES,
    },
  ];

  const handleClickNewRule = () => {
    setActiveKey(Tabs.RULE_CONFIGURATOR);
  };

  const handleExecuteRule = async () => {
    try {
      await executeRule({
        tenantId: Number(tenantId),
        projectId: Number(modelId),
      }).unwrap();

      setRuleStatus(RuleStatusT.STARTING);

      //eslint-disable-next-line
    } catch (error: any) {
      messageApi.error(error?.data?.message || t(`${ruleBuilder}.executeError`));
    }
  };
  const handleClickNewRuleType = () => {
    dispatch(setModalOpened(ModalActions.ADD));
  };

  return (
    <div className={styles.root}>
      <RuleStatusListener setRuleStatus={setRuleStatus} />
      {contextHolder}
      {queryTab === Tabs.RULE_CONFIGURATOR ? (
        <RuleConfigurator messageApi={messageApi} />
      ) : (
        <AppTabs
          activeKey={activeKey as string}
          onChange={handleTabChange}
          tabBarExtraContent={{
            right: (
              <div className={styles.ruleButtonContainer}>
                {activeKey === Tabs.RULES && (
                  <>
                    <Button
                      onClick={handleExecuteRule}
                      disabled={ruleStatus === RuleStatusT.STARTING}
                      className={styles.btn}
                    >
                      {t(`${ruleBuilder}.execute`)}
                    </Button>
                    <RuleStatuses status={ruleStatus} />
                    <Button onClick={handleClickNewRule} className={styles.btn}>
                      {t(`${ruleBuilder}.addRule`)}
                    </Button>
                  </>
                )}
                {activeKey === Tabs.RULE_TYPES && (
                  <Button onClick={handleClickNewRuleType} className={styles.btn}>
                    {t(`${ruleBuilder}.addRuleType`)}
                  </Button>
                )}
              </div>
            ),
          }}
          items={items}
        />
      )}
    </div>
  );
};
