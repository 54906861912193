import { useEffect } from 'react';
import { setAllProducts } from '../../store/slices/endUserSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

export const useFetchAllProducts = () => {
  const dispatch = useAppDispatch();

  const { demandArea } = useAppSelector(({ endUser }) => endUser);

  useEffect(() => {
    if (demandArea?.listDemandArea) {
      const products = demandArea?.listDemandArea?.reduce((acc: any, demandArea) => {
        demandArea.categories.forEach((category) => {
          const { products, ...categoryInfo } = category;

          const fullInfoProductsArray = products.map((item) => {
            return { ...item, category: categoryInfo, demandAreaId: demandArea.id };
          });

          acc.push(...fullInfoProductsArray);
        });
        return acc;
      }, []);

      if (products?.length) {
        dispatch(setAllProducts(products));
      }
    }
  }, [demandArea, dispatch]);
};
