export function EditIcon({ styles = '' }: any) {
  return (
    <svg
      className={styles}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7278 0.146894C12.7743 0.100331 12.8295 0.0633877 12.8902 0.0381813C12.9509 0.0129748 13.0161 0 13.0818 0C13.1476 0 13.2127 0.0129748 13.2735 0.0381813C13.3342 0.0633877 13.3894 0.100331 13.4358 0.146894L16.4358 3.14689C16.4824 3.19334 16.5193 3.24851 16.5445 3.30926C16.5698 3.37001 16.5827 3.43513 16.5827 3.50089C16.5827 3.56666 16.5698 3.63178 16.5445 3.69253C16.5193 3.75327 16.4824 3.80845 16.4358 3.85489L6.43584 13.8549C6.38785 13.9025 6.3307 13.94 6.26784 13.9649L1.26784 15.9649C1.17697 16.0013 1.07743 16.0102 0.981557 15.9905C0.885681 15.9708 0.797685 15.9235 0.728478 15.8543C0.659272 15.785 0.611898 15.6971 0.592231 15.6012C0.572563 15.5053 0.581467 15.4058 0.617837 15.3149L2.61784 10.3149C2.64277 10.252 2.68019 10.1949 2.72784 10.1469L12.7278 0.146894V0.146894ZM11.7888 2.50089L14.0818 4.79389L15.3748 3.50089L13.0818 1.20789L11.7888 2.50089ZM13.3748 5.50089L11.0818 3.20789L4.58184 9.70789V10.0009H5.08184C5.21445 10.0009 5.34162 10.0536 5.43539 10.1473C5.52916 10.2411 5.58184 10.3683 5.58184 10.5009V11.0009H6.08184C6.21445 11.0009 6.34162 11.0536 6.43539 11.1473C6.52916 11.2411 6.58184 11.3683 6.58184 11.5009V12.0009H6.87484L13.3748 5.50089V5.50089ZM3.61384 10.6759L3.50784 10.7819L1.97984 14.6029L5.80084 13.0749L5.90684 12.9689C5.81146 12.9333 5.72923 12.8693 5.67115 12.7857C5.61307 12.7021 5.58191 12.6027 5.58184 12.5009V12.0009H5.08184C4.94923 12.0009 4.82205 11.9482 4.72828 11.8544C4.63452 11.7607 4.58184 11.6335 4.58184 11.5009V11.0009H4.08184C3.98002 11.0008 3.88065 10.9697 3.79701 10.9116C3.71338 10.8535 3.64947 10.7713 3.61384 10.6759V10.6759Z"
        fill="#5C5C6E"
      />
    </svg>
  );
}
