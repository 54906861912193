import { ForwardedRef, forwardRef } from 'react';
import ReactSelect from 'react-select';
import { MultiSelectOption } from './components/MultiSelectOption';
import { SingleSelectOption } from './components/SingleSelectOption';
import { useAppSelect } from './hooks/useAppSelect';
import { AppMultiValue, MultiValueWithoutLimit } from './components/AppMultiValue';
import { Control } from './components/Control';
import { DropdownIndicator } from './components/DropdownIndicator';
import { MenuListWithSelectAll } from './components/MenuListWithSelectAll';
import { MenuListWithoutSelectAll } from './components/MenuListWithoutSelectAll';
import cx from 'classnames';
import { SelectableGroup } from './components/SelectableGroup';
import { Placeholder } from './components/Placeholder';
import { ValueContainer } from './components/ValueContainer';

export const AppSelect = forwardRef((props: any, ref: ForwardedRef<unknown>) => {
  const {
    isSearchable = false,
    isMultiSelect = false,
    withSelectableGroups = false,
    styles: additionalStyles,
    className,
    value: selected,
    defaultValue: defaultSelected,
    options,
    'aria-invalid': ariaInvalid,
    unselectable = false,
    disabled,
    components,
    label,
    withoutLimit = false,
    selectAll,
    menuPlacement = 'auto',
    placeholder = '',
    groupProps,
    ...rest
  } = props;

  const { $value, $defaultValue, mainStyles } = useAppSelect({
    defaultSelected,
    selected,
    options,
    ariaInvalid,
    isMultiSelectWithGroupableOptions: isMultiSelect && withSelectableGroups,
  });

  return (
    <>
      <ReactSelect
        ref={ref}
        value={$value || selected}
        defaultValue={$defaultValue || defaultSelected}
        isDisabled={disabled}
        isClearable={false}
        isMulti={isMultiSelect}
        isSearchable={isSearchable}
        unselectable={unselectable}
        withSelectableGroups={withSelectableGroups}
        closeMenuOnSelect={!isMultiSelect}
        hideSelectedOptions={false}
        menuPlacement={menuPlacement}
        options={options}
        label={label}
        getOptionLabel={(option: { title?: string }) => option?.title} // REFACTOR: replace "title" with const
        classNamePrefix="react-select"
        className={cx(className, { 'react-select-multiselect': isMultiSelect })}
        styles={{ ...mainStyles, ...additionalStyles }}
        placeholder={placeholder}
        groupProps={groupProps}
        components={{
          Option: isMultiSelect ? MultiSelectOption : SingleSelectOption,
          MenuList: selectAll ? MenuListWithSelectAll : MenuListWithoutSelectAll,
          MultiValue: withoutLimit ? MultiValueWithoutLimit : AppMultiValue,
          Group: withSelectableGroups && groupProps ? SelectableGroup : undefined,
          Placeholder,
          Control,
          DropdownIndicator,
          ValueContainer,
          ...components,
        }}
        {...rest}
      />
    </>
  );
});

AppSelect.displayName = 'AppSelect';
