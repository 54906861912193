import { FC } from 'react';
import { CurrentStatusLevelCard } from '../CurrentStatusLevelCard';
import { StatusLevelsList } from '../StatusLevelsList';
import { useCalculatePoints } from '../../../../lib/hooks/endUserHooks/useCalculatePoints';
import { useAppSelector } from '../../../../lib/store/hooks';
import styles from './index.module.scss';
import { getDisplayedPointsAccordingToRules } from '../../utils/helpers';

export const StatusLevelSection: FC = () => {
  const {
    demandArea,
    allProducts,
    statusLevels,
    invisibleCategoryProducts,
    ruleActionStatusLevel: { maxAvailablePoints, extraPoints },
  } = useAppSelector(({ endUser }) => endUser);

  const userPoints = useCalculatePoints([...invisibleCategoryProducts, ...allProducts]);

  // TODO change userPoints with currentPoints from store

  const maxPointsPerModel = demandArea?.project?.maxPoints || 0;
  const maxPoints = maxAvailablePoints != null ? maxAvailablePoints : maxPointsPerModel;

  const subLevelsWithStatusLevel = statusLevels
    .flatMap((statusLevel) => {
      const firstSubLevel = statusLevel.subLevels[0];
      return firstSubLevel ? { ...firstSubLevel, statusLevelName: statusLevel.name } : null;
    })
    .filter(Boolean);

  const displayedUserPoints = getDisplayedPointsAccordingToRules({ userPoints, maxPoints, extraPoints });

  let remainingPointsToNextLevel = 0;

  for (const subLevel of subLevelsWithStatusLevel) {
    if (subLevel && subLevel.points > displayedUserPoints) {
      remainingPointsToNextLevel = subLevel.points - displayedUserPoints;
      break;
    }
  }

  return (
    <div className={styles.wrapper}>
      <CurrentStatusLevelCard
        displayedUserPoints={displayedUserPoints}
        maxPoints={maxPointsPerModel}
        userPoints={userPoints}
        remainingPointsToNextLevel={remainingPointsToNextLevel}
        levels={subLevelsWithStatusLevel}
        icon={demandArea?.project?.pointsIcon}
      />
      <StatusLevelsList
        icon={demandArea?.project?.pointsIcon}
        levels={statusLevels}
        userPoints={displayedUserPoints}
        remainingPointsToNextLevel={remainingPointsToNextLevel}
        maxPoints={maxPoints}
      />
    </div>
  );
};
