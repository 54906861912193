import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { Table } from '../../../../components/Table';
import { setPage } from '../../../../lib/store/slices/demandAreasSlice';
import { useDemandPositionColumn } from './useDemandPositionColumn';
import { useDemandAreaColumn } from './useDemandAreaColumn';
import { useDemandsColorColumn } from './useDemandsColorColumn';
import { useDemandsNumberOfCategoriesColumn } from './useDemandsNumberOfCategoriesColumn';
import { useDemandAreaProductCategories } from './useDemandAreaProductCategories';
import { useDemandStatusColumn } from './useDemandStatusColumn';
import { useDemandActionsColumn } from './useDemandActionsColumn';
import { useGetDemandAreasQuery } from '../../../../lib/store/api/demandArea';
import { GetDemandAreaPayloadT } from '../../../../lib/types/demandArea';
import { TablePagination } from '../../../../lib/types/pagination';
import { normalizePageForRequest, normalizePageForTable } from '../../../../lib/utils/helpers';

type Props = {
  tenantId: string;
  modelId: string;
};

export const DemandAreasTable = (props: Props) => {
  const dispatch = useAppDispatch();

  const page = useAppSelector(({ demandAreas }) => demandAreas.page);

  const sortingAndArguments: GetDemandAreaPayloadT = useAppSelector(({ demandAreas }) => {
    return { ...demandAreas.sorting, ...props, page: normalizePageForRequest(demandAreas.page || 1) };
  });

  const { data, isLoading, isFetching } = useGetDemandAreasQuery(sortingAndArguments, {
    refetchOnMountOrArgChange: true,
  });

  const dataSource = data?.content || [];

  const responsePagination: TablePagination = {
    pageSize: data?.pageable.pageSize,
    current: normalizePageForTable(data?.pageable?.pageNumber || 0),
    total: data?.totalElements,
  };

  const columns = [
    useDemandPositionColumn(),
    useDemandAreaColumn(),
    useDemandsColorColumn(),
    useDemandsNumberOfCategoriesColumn(),
    useDemandAreaProductCategories(),
    useDemandStatusColumn(),
    useDemandActionsColumn(),
  ];

  useEffect(() => {
    if (data?.numberOfElements === 0 && page !== 1) {
      dispatch(setPage(page - 1));
    }
  }, [data?.numberOfElements]);

  return (
    <Table
      columns={columns}
      rowKey="id"
      dataSource={dataSource}
      pagination={responsePagination}
      loading={isLoading || isFetching}
      scroll={{ x: 'max-content' }}
      onChange={(tablePagination) => {
        dispatch(setPage(tablePagination.current as number));
      }}
    />
  );
};
