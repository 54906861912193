import { useTranslation } from 'react-i18next';
import { FormItem } from '../../../../../components/FormItem';
import { FormItemName } from '../../../../../lib/models/Form';
import { FormInput } from '../../../../../components/FormInput';
import { Input } from 'antd';
import styles from '../index.module.scss';

const { TextArea } = Input;

const comments = 'pages.projects.formItem.comments';

export const CommentFormItem = () => {
  const { t } = useTranslation();

  return (
    <FormItem
      className={styles.commentWrapper}
      hasFeedback={true}
      name={FormItemName.COMMENTS}
      rules={[{ max: 2048 }]}
    >
      <FormInput className={styles.textArea} as={TextArea} label={t(comments)} />
    </FormItem>
  );
};
