import { useTranslation } from 'react-i18next';
import { FormInput } from '../../../../../components/FormInput';
import { FormItem } from '../../../../../components/FormItem';
import { BONUS_CATALOG_FORM_ITEMS } from '../../../helpers';

const bonusCatalogT = `pages.bonusCatalogTab`;

export const NameFormItem = () => {
  const { t } = useTranslation();

  return (
    <FormItem
      hasFeedback={true}
      name={BONUS_CATALOG_FORM_ITEMS.NAME}
      rules={[
        { max: 50, message: t(`${bonusCatalogT}.formItems.errors.maxLength`) },
        {
          required: true,
          message: t(`${bonusCatalogT}.formItems.errors.name`),
        },
      ]}
    >
      <FormInput label={t(`${bonusCatalogT}.formItems.name`)} />
    </FormItem>
  );
};
