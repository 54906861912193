import { useEffect } from 'react';
import { setUserStatusLevel } from '../../store/slices/endUserSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getWishlistStatusLevelsData } from '../../../modules/endUserStatusLevel/utils/helpers';

export const useCalculateWishListPoints = () => {
  const dispatch = useAppDispatch();

  const { allProducts, wishlistProducts, invisibleCategoryProducts } = useAppSelector(
    ({ endUser }) => endUser,
  );

  useEffect(() => {
    if (wishlistProducts?.length || allProducts?.length) {
      const { wishedPoints, currentPointsInWishlist, currentPoints } = getWishlistStatusLevelsData(
        wishlistProducts,
        [...allProducts, ...invisibleCategoryProducts],
      );

      dispatch(
        setUserStatusLevel({
          wishedPoints,
          currentPointsInWishlist,
          currentPoints,
        }),
      );
    }
  }, [dispatch, wishlistProducts, allProducts, invisibleCategoryProducts]);
};
