import { FC } from 'react';
import { ArrangeListType, MINIMAL_SEARCH_LENGTH } from '../../utils/constants';
import { isLength } from '../../../../lib/utils/helpers';
import { ArrangeFormItem } from '../../../../lib/models/Arrange';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../../../components/Spinner';
import { FormSection } from '../../../../components/FormSection';
import { CheckboxAllFormSection } from './CheckboxAllFormSection';

import styles from './index.module.scss';

const filter = 'shared.arranges.filter';

type Props = {
  filtering: any;
  minSearchLength?: number;
};

export const SearchFilter: FC<Props> = ({ filtering, minSearchLength = MINIMAL_SEARCH_LENGTH }) => {
  const { options, onChangeSearchField, isLoading, type } = filtering;

  const { t } = useTranslation();

  const suffix = <Spinner show={isLoading} />;
  const rules =
    type === ArrangeListType.STRING && minSearchLength
      ? [
          {
            min: minSearchLength,
            message: t(`${filter}.errors.minLength`, { number: minSearchLength }),
          },
        ]
      : [];

  return (
    <>
      <FormSection
        size="small"
        className={styles.searchField}
        formItemProps={{
          name: ArrangeFormItem.SEARCH,
          className: isLength(options) ? styles.formItem : styles.formItemExtended,
          hasFeedback: false,
          rules,
        }}
        textFieldProps={{
          label: t(`${filter}.inputPlaceholdersByTypes.list`),
          onChange: onChangeSearchField,
          suffix,
        }}
      />
      <div className="custom-ant-checkbox-group-wrapper">
        <CheckboxAllFormSection
          minSearchLength={minSearchLength}
          isLoading={isLoading}
          rootProps={{ style: { maxHeight: '208px' } }}
          formSectionProps={{
            formItemProps: { name: ArrangeFormItem.SELECTED_ITEMS },
          }}
          checkboxGroupProps={{
            rootProps: {
              style: { maxHeight: '150px', height: '100%', overflowY: 'auto' },
            },
            groupProps: { options },
          }}
        />
      </div>
    </>
  );
};
