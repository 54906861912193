import { FC, useState } from 'react';
import styles from './index.module.scss';
import { DemandArea } from '../../../../lib/types/endUser';

type Props = {
  currentDemandArea: DemandArea;
  areaColor: string;
  areaId: number;
  onAreaClick: () => void;
};

export const SmallHouseItem: FC<Props> = ({ currentDemandArea, areaColor, areaId, onAreaClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const getCategoryCardStyle = (isHovered: boolean, demandAreaColor: string) =>
    isHovered ? `color-mix(in srgb, ${demandAreaColor}, #FFFFFF 73%)` : '#fff';
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div
      role="presentation"
      onClick={onAreaClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={styles.smallHouseSquare}
      style={{
        backgroundColor:
          currentDemandArea.id === areaId ? areaColor : getCategoryCardStyle(isHovered, areaColor),
      }}
    ></div>
  );
};
