import { FC } from 'react';
import { Card, Form, FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemTitle } from '../FormItemTitle';
import { FormItemName } from '../../../../lib/models/Form';
import { FormItem } from '../../../../components/FormItem';
import { CoverageTypeT } from '../../../../lib/types/product';
import { AppSegmented } from '../../../../components/AppSegmented';
import { FormCardButton } from '../../../designSettings/components/FormCardButton';
import { RegionRow } from '../RegionRow';

import styles from './index.module.scss';

const region = 'pages.products.productConfigurator.formItems.region';
const coverageType = 'pages.products.productConfigurator.formItems.coverageType';

type Props = {
  form: FormInstance;
};

export const RegionCard: FC<Props> = ({ form }) => {
  const { t } = useTranslation();

  const rulesLength = Form.useWatch([FormItemName.REGION], form)?.length;

  const options = [
    {
      label: t(`${coverageType}.include`),
      value: CoverageTypeT.INCLUDE,
    },
    {
      label: t(`${coverageType}.exclude`),
      value: CoverageTypeT.EXCLUDE,
    },
  ];

  return (
    <Card className={styles.root}>
      <div className={styles.header}>
        <FormItemTitle>{t(`${region}.title`)}</FormItemTitle>
        <FormItem
          hasFeedback
          name={[FormItemName.REGION, FormItemName.REGION_COVERAGE]}
          className={styles.radioGroup}
          initialValue={CoverageTypeT.INCLUDE}
        >
          <AppSegmented options={options} />
        </FormItem>
      </div>

      <div className={styles.list}>
        <Form.List name={[FormItemName.REGION, FormItemName.REGIONS]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <RegionRow key={field.key} name={field.name} remove={remove} />
              ))}
              <FormCardButton disabled={rulesLength >= 10} onClick={() => add()}>
                + {t(`${region}.addCode`)}
              </FormCardButton>
            </>
          )}
        </Form.List>
      </div>
    </Card>
  );
};
