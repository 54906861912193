import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { AppLocalStorage, isLength } from '../../utils/helpers';
import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from '../../utils/constants';
import { authApi } from '../api/auth';
import { UserDataT, UserRolesT } from '../../types/auth';
import jwtDecode from 'jwt-decode';
import { checkIsUser } from '../../utils/roles';
import { logoutAction } from '../actions';

type SliceStateT = {
  user: UserDataT | null;
  role: UserRolesT | null;
  username: string | null;
  tenantId: string | null;
  appLang: 'de' | 'en';
};

type JwtPayloadT = {
  permissions: Array<{ tenantId: number }>;
};

const initialState: SliceStateT = {
  user: null,
  role: null,
  username: null,
  tenantId: null,
  appLang: 'en',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAppLanguage: (state, action: PayloadAction<{ language: 'de' | 'en'}>) => {
      state.appLang = action.payload.language;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.logIn.matchFulfilled, (state, { payload }) => {
        const { roles, username, accessToken, refreshToken } = payload;

        const decodedAccessToken = jwtDecode<JwtPayloadT>(accessToken);

        const permissions = decodedAccessToken?.permissions;

        const mainRole = roles?.[0];
        const tenantId = checkIsUser(mainRole) && isLength(permissions) ? permissions?.[0]?.tenantId : null;

        AppLocalStorage.setItem(ACCESS_TOKEN_NAME, accessToken);
        AppLocalStorage.setItem(REFRESH_TOKEN_NAME, refreshToken);

        state.role = mainRole;
        state.username = username;
        state.tenantId = String(tenantId);
      })
      .addMatcher(
        isAnyOf(
          authApi.endpoints.getUserData.matchFulfilled,
          authApi.endpoints.getEndUserData.matchFulfilled,
        ),
        (state, { payload }) => {
          state.user = payload;
        },
      )
      .addMatcher(isAnyOf(logoutAction), () => initialState);
  },
});

export const { setAppLanguage } = authSlice.actions;
export const auth = authSlice.reducer;
