import React, { FC, useState } from 'react';
import { ProductDetailsSectionT } from '../../../../lib/types/sections';
import { EndUserProductCard } from '../../../endUserProduct/components/EndUserProductCard';

import styles from './styles.module.scss';
import { EndUserProductModal } from '../../../endUserProduct/components/EndUserProductModal';
import { ProductT } from '../../../../lib/types/product';
import { BrandKitThemeConfiguration } from '../../../../lib/types/colors&fonts';
import { EndUserContactFormModal } from '../../../endUserContactForm/components/ContactFormModal';
import { sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';

type Props = {
  productSection: ProductDetailsSectionT;
  brandKit: BrandKitThemeConfiguration | null;
  isGuestMode: boolean;
};

export const MobileCarousel: FC<Props> = ({ productSection, brandKit, isGuestMode }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductT | null>(null);

  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const handleOpenContactModal = (product: ProductT) => {
    setIsContactModalOpen(true);
    setSelectedProduct(product);
  };

  const handleCloseContactModal = () => {
    setIsContactModalOpen(false);
    setSelectedProduct(null);
  };

  const genericProps = {
    arePointsShown: productSection.arePointsShown,
    isPriceShown: productSection.isPriceShown,
    bullet: productSection.bullet,
    pointsIcon: productSection.pointsIcon,
    onOpenModal: handleOpenContactModal,
    brandKit,
    frameColor: productSection.frameColor,
  };

  const onModalClose = () => {
    sendDataToAnalytics(
      'Product Details',
      `Product name: ${selectedProduct?.name}, Product id: ${selectedProduct?.productId}`,
      `Details: close`,
    );

    setIsModalOpen(false);
  };

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const onSelectProduct = (product: ProductT) => {
    sendDataToAnalytics(
      'Product Details',
      `Product name: ${product.name}, Product id: ${product.productId}`,
      `Details: open`,
    );

    setSelectedProduct(product);

    onModalOpen();
  };

  return (
    <>
      <div className={styles.sliderContainer}>
        {productSection.products.map((item) => (
          <EndUserProductCard
            onSelectProduct={onSelectProduct}
            key={item.id}
            product={item}
            isIrrelevant={item.irrelevantSurveyReason}
            isInWishlist={item.isWishlist}
            isGuestMode={isGuestMode}
            {...genericProps}
          />
        ))}
      </div>
      <EndUserProductModal
        selectedProduct={selectedProduct}
        isModalOpen={isModalOpen}
        close={onModalClose}
        {...genericProps}
      />
      <EndUserContactFormModal
        products={selectedProduct ? [selectedProduct] : null}
        isOpen={isContactModalOpen}
        onClose={handleCloseContactModal}
      />
    </>
  );
};
