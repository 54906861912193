import { useTranslation } from 'react-i18next';
import { PROJECT_COLUMN } from '../helpers/constants';
import tableStyles from '../../../components/Table/index.module.scss';
import { useProjectDateColumn } from './useProjectDateColumn';

const creationDate = 'pages.projects.columns.creationDate';

export const useCreationDateColumn = () => {
  const { t } = useTranslation();

  return useProjectDateColumn({
    arrangedId: PROJECT_COLUMN.CREATION_DATE,
    title: t(creationDate),
    filterFromName: 'createdDateFrom',
    filterToName: 'createdDateTo',
    headerCellProps: {
      rootProps: {
        className: tableStyles.hiddenHeaderCell,
      },
    },
  });
};
