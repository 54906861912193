import { Form, message } from 'antd';
import styles from './index.module.scss';
import React, { useEffect, useMemo, useState } from 'react';
import { BrandKitTitle } from '../BrandKitTitle';
import { ColorsCard } from '../ColorsCard';
import { DefaultBrandKitColors, DefaultFontStyles } from '../../util/constants';
import { BrandKitFonts } from '../BrandKitFonts';
import {
  useBrandKitMutation,
  useGetBrandKitDataQuery,
  useGetFontsQuery,
} from '../../../../lib/store/api/brandKit';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { setBrandKitSubmitted } from '../../../../lib/store/slices/designSettingsSlice';
import { useTranslation } from 'react-i18next';
import { BrandKitThemeConfiguration } from '../../../../lib/types/colors&fonts';
import { PageLoader } from '../../../../components/PageLoader';
import { parseJSON } from '../../../../lib/utils/helpers';

const designSettingsTranslation = 'pages.designSettings';

export const BrandKit = () => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const { id: tenantId, modelId } = useParams();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isServerThemeConfigurationReady, setIsServerThemeConfigurationReady] = useState(false);

  const { isLoading: isFontsLoading } = useGetFontsQuery(
    { tenantId: tenantId as string },
    { skip: !tenantId },
  );

  const { isBrandKitSubmitted } = useAppSelector(({ designSettings }) => designSettings);

  const { data, isLoading } = useGetBrandKitDataQuery(
    { tenantId: tenantId as string, projectId: String(modelId) },
    { skip: !tenantId || !modelId },
  );

  const serverThemeConfiguration = useMemo(() => {
    if (data) {
      const firstBrandKit = data?.[0];
      return firstBrandKit ? (parseJSON(firstBrandKit.settings) as BrandKitThemeConfiguration) : undefined;
    }
    return undefined;
  }, [data]);

  const [updateBrandKit] = useBrandKitMutation();

  const [themeConfiguration, setThemeConfiguration] = useState({
    colors: DefaultBrandKitColors,
    fonts: DefaultFontStyles,
  });

  const onFinish = async () => {
    const jsonSettings = JSON.stringify(themeConfiguration);

    const sendData = {
      projectId: String(modelId) as string,
      tenantId: tenantId as string,
      settings: jsonSettings,
    };

    try {
      await updateBrandKit(sendData).unwrap();
      messageApi.success(t(`${designSettingsTranslation}.success.brandKit`));
    } catch (err) {
      messageApi.error(t(`${designSettingsTranslation}.errors.brandKit`));
    } finally {
      dispatch(setBrandKitSubmitted(false));
    }
  };

  useEffect(() => {
    //   Update local state when get serverThemeConfiguration
    if (!isLoading) {
      if (serverThemeConfiguration) {
        setThemeConfiguration(serverThemeConfiguration);
      }
      setIsServerThemeConfigurationReady(true);
    }
  }, [isLoading, serverThemeConfiguration]);

  useEffect(() => {
    // DO SUBMIT WHEN YOU CLICK ON SAVE BUTTON IN HEADER
    if (isBrandKitSubmitted) {
      form?.submit();
    }
  }, [isBrandKitSubmitted]);

  if (isLoading || isFontsLoading || !isServerThemeConfigurationReady) {
    return <PageLoader />;
  }

  return (
    <Form onFinish={onFinish} form={form} className={styles.root}>
      {contextHolder}
      <BrandKitTitle />
      <ColorsCard themeConfiguration={themeConfiguration} setThemeConfiguration={setThemeConfiguration} />
      <BrandKitFonts
        tenantId={tenantId as string}
        themeConfiguration={themeConfiguration}
        setThemeConfiguration={setThemeConfiguration}
      />
    </Form>
  );
};
