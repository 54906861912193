import React, { FC } from 'react';
import { Form, FormInstance, Typography } from 'antd';
import { AppCardBasic } from '../../../../../components/AppCardBasic';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { FormItem } from '../../../../../components/FormItem';
import { FormItemName } from '../../../../../lib/models/Form';
import { FormInput } from '../../../../../components/FormInput';
import { ProjectUrl } from '../ProjectUrl';
import { useProjectSubdomain } from '../../../../../lib/hooks/useProjectSubdomain';
import { FaviconCard } from '../FaviconCard';
import { useGetTenantDomainsQuery } from '../../../../../lib/store/api/tenants';
import { useParams } from 'react-router-dom';
import { AppSelect } from '../../../../../components/AppSelect';

const { Title } = Typography;

const formItems = 'pages.settings.formItems';
const title = 'pages.settings.browserInformation.title';
const subTitle = 'pages.settings.browserInformation.subTitle';
const domains = 'pages.settings.browserInformation.domains';
const subdomainError = 'pages.settings.errors.subdomainError';

type Props = {
  form: FormInstance;
};
export const BrowserInformationCardData: FC<Props> = ({ form }) => {
  const { t } = useTranslation();

  const { id: tenantId } = useParams();

  const { data: domainsData } = useGetTenantDomainsQuery(String(tenantId));

  const currentDomain = Form.useWatch(FormItemName.DOMAIN, form);

  const { projectUrl } = useProjectSubdomain(form, currentDomain?.title || domainsData?.[0]?.title);

  return (
    <AppCardBasic>
      <div className={styles.header}>
        <Title level={3} className={styles.title}>
          {t(title)}
        </Title>
        <Title level={4} className={styles.subTitle}>
          {t(subTitle)}
        </Title>
      </div>
      <div className={styles.row}>
        <FormItem
          style={{ minHeight: 55 }}
          name={FormItemName.META_TITLE}
          help={false}
          hasFeedback={true}
          rules={[{ required: false }]}
        >
          <FormInput label={t(`${formItems}.metaTitle`)} />
        </FormItem>
        <FormItem
          style={{ minHeight: 55 }}
          name={FormItemName.META_DESCRIPTION}
          help={false}
          hasFeedback={true}
          rules={[{ required: false }]}
        >
          <FormInput label={t(`${formItems}.metaDescription`)} />
        </FormItem>
      </div>

      <div className={styles.row}>
        <FormItem hasFeedback={true} name={FormItemName.DOMAIN} rules={[{ required: true }]}>
          <AppSelect options={domainsData} label={t(domains)} />
        </FormItem>

        <FormItem
          name={FormItemName.SUBDOMAIN}
          hasFeedback={true}
          rules={[
            { required: true },
            {
              pattern: /^[A-Za-z0-9]([A-Za-z0-9-]{0,251}[A-Za-z0-9])?$/,
              message: t(subdomainError),
            },
          ]}
        >
          <FormInput label={t(`${formItems}.enterSubdomain`)} />
        </FormItem>
      </div>

      <ProjectUrl url={projectUrl} />
      <FaviconCard form={form} />
    </AppCardBasic>
  );
};
