import { FC } from 'react';
import { TableHeaderCell } from '../../../../components/Table/TableHeaderCell/TableHeaderCell';
import { ArrangeListPopup } from '../popups/ArrangeListPopup';
import { getArrangesInitialValues } from '../../../../lib/utils/arranges-helpers';
import { useArrangeSubmit } from '../../helpers/useArrangeSubmit';
import { useTranslation } from 'react-i18next';

const columns = 'shared.columns';

type Props = any;

export const StatusHeaderCell: FC<Props> = ({
  arrangedId,
  getParsedItems,
  removeFilterValue,
  removeFilter,
  filterLabel,
  sortOrder,
  isActiveArrange,
  form,
  applyArranges,
  filtering,
  disableSorting,
}) => {
  const { t } = useTranslation();

  const { onFinish } = useArrangeSubmit(applyArranges);

  return (
    <TableHeaderCell
      arrangedId={arrangedId}
      title={t(`${columns}.status`)}
      removeFilter={removeFilter}
      filterLabel={filterLabel}
      sortOrder={sortOrder}
      isActiveArrange={isActiveArrange}
      form={form}
      getParsedItems={getParsedItems}
      removeFilterValue={removeFilterValue}
    >
      <ArrangeListPopup
        disableSorting={disableSorting}
        initialValues={getArrangesInitialValues(form)}
        form={form}
        filtering={filtering}
        onFinish={onFinish}
        isActiveArrange={isActiveArrange}
      />
    </TableHeaderCell>
  );
};
