import { useActionsColumn } from '../../../../features/table/helpers/useActionsColumn';
import { BonusCatalogActionsBodyCell } from './BonusCatalogActionsBodyCell';

export const useBonusCatalogActionsColumn = () => {
  return useActionsColumn({
    render: (text: string, row: any) => {
      return <BonusCatalogActionsBodyCell row={row} />;
    },
  });
};
