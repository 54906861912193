import { useTranslation } from 'react-i18next';
import { RULES_COLUMN } from '../../../lib/types/ruleBuilder';
import { useRulesDataColumn } from './useRulesDataColumn';
import { Typography } from 'antd';
import { formatDateForRulesTable } from '../../../lib/utils/helpers';

const { Text } = Typography;

const lastEdited = 'pages.ruleBuilder.ruleColumns.lastEdited';

export const useLastEditedDateColumn = () => {
  const { t } = useTranslation();

  return useRulesDataColumn({
    title: t(lastEdited),
    arrangedId: RULES_COLUMN.LAST_MODIFIED_DATE,
    columnProps: {
      width: 200,
      render: (date: Date | string) => <Text>{date ? formatDateForRulesTable(date) : '-'}</Text>,
    },
  });
};
