import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrandKit } from '../../modules/brandKit/components/BrandKit';
import { DesignSettingsTab } from '../../modules/designSettings/components/DesignSettingsTab';
import { AppTabs } from '../../components/AppTabs';
import { Button } from 'antd';
import { useAppDispatch, useAppSelector } from '../../lib/store/hooks';
import { setBrandKitSubmitted, setDesignSettingsSubmitted } from '../../lib/store/slices/designSettingsSlice';
import styles from './index.module.scss';
import { useTabQuery } from '../../lib/hooks/useTabQuery';

const tabs = 'pages.designSettings.tabs';

type KeysValues = 'brandKit' | 'designSettings';

export const DesignSettingsPage = () => {
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState<KeysValues>('brandKit');
  const dispatch = useAppDispatch();
  const { isBrandKitSubmitted, isDesignSettingsSubmitted } = useAppSelector(
    ({ designSettings }) => designSettings,
  );

  useTabQuery(activeKey);

  const handleTabChange = (key: string) => {
    setActiveKey(key as KeysValues);
  };

  const items = [
    {
      label: t(`${tabs}.brandKit`),
      children: <BrandKit />,
      key: 'brandKit',
    },
    { label: t(`${tabs}.designSettings`), children: <DesignSettingsTab />, key: 'designSettings' },
  ];

  const isSaveButtonDisabled =
    (activeKey === 'brandKit' && isBrandKitSubmitted) ||
    (activeKey === 'designSettings' && isDesignSettingsSubmitted);

  const onFinish = () => {
    if (activeKey === 'brandKit') {
      dispatch(setBrandKitSubmitted(true));
    }
    if (activeKey === 'designSettings') {
      dispatch(setDesignSettingsSubmitted(true));
    }
  };

  return (
    <div className={styles.root}>
      <AppTabs
        onChange={handleTabChange}
        tabBarExtraContent={{
          right: (
            <Button disabled={isSaveButtonDisabled} onClick={onFinish} type="primary" className={styles.btn}>
              {t('generic.save')}
            </Button>
          ),
        }}
        items={items}
      />
    </div>
  );
};
