import { useTranslation } from 'react-i18next';
import { FormItem } from '../../../../components/FormItem';
import { FormItemName } from '../../../../lib/models/Form';
import { FormInput } from '../../../../components/FormInput';
import styles from './index.module.scss';
import { ReactComponent as LinkIcon } from './../../../../assets/icons/link-icon-gray.svg';

const dataProtection = 'unique.formItems.dataProtection';

const url = 'unique.formItems.url';

export const DataProtectionUrlFormItem = () => {
  const { t } = useTranslation();

  return (
    <FormItem
      name={FormItemName.DATA_PROTECTION_URL}
      hasFeedback={true}
      rules={[
        {
          required: false,
        },
        {
          type: 'url',
          message: t(`${url}.errors.invalid`),
        },
        { max: 200 },
      ]}
    >
      <FormInput className={styles.inputUrl} icon={<LinkIcon />} label={t(`${dataProtection}.label`)} />
    </FormItem>
  );
};
