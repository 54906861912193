import { FormItem } from '../../../../../components/FormItem';
import { FormItemName } from '../../../../../lib/models/Form';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAdminUsersForEmailSKPQuery } from '../../../../../lib/store/api/project';
import { AppSelect } from '../../../../../components/AppSelect';

const skp = 'pages.projects.formItem.skp';

export const EmailSkpFormItem = () => {
  const { t } = useTranslation();

  const { data: adminUsersData, isLoading } = useGetAdminUsersForEmailSKPQuery();

  const userOptions = useMemo(() => {
    return adminUsersData?.content.map((user) => {
      return { title: user.email, value: user.id };
    });
  }, [adminUsersData?.content]);

  return (
    <FormItem name={FormItemName.CONTACT_SKP} hasFeedback={true}>
      <AppSelect isLoading={isLoading} label={t(skp)} options={userOptions} />
    </FormItem>
  );
};
