import { FC } from 'react';
import { checkBoughtProductsInCategory } from '../../lib/utils/end-user-helpers';
import { parseJSON, sortCategoriesByPriority } from '../../lib/utils/helpers';
import { EndUserTypography } from '../EndUserTypography';
import { IconComponent } from '../IconComponent';
import styles from './index.module.scss';
import cn from 'classnames';
import { Category } from '../../lib/types/endUser';
import { useGetCategoryWidth } from './hooks/useGetCategoryWidth';
import { Button } from 'antd';

type Props = {
  demandAreas?: any;
  handleAreaClick?: any;
  handleKeyDown?: any;
  handleCategoryClick?: (areaId: number, category: Category) => void;
  showButton?: boolean;
  buttonColor?: string;
  buttonText?: string;
  fillAllCategories?: boolean;
};

export const HouseDesktop: FC<Props> = ({
  demandAreas,
  handleAreaClick,
  handleKeyDown,
  handleCategoryClick,
  showButton,
  buttonColor,
  buttonText,
  fillAllCategories,
}) => {
  const { topLeftWidth, topRightWidth, bottomLeftWidth, bottomRightWidth } = useGetCategoryWidth(demandAreas);

  return (
    <>
      {showButton && (
        <Button
          style={{ backgroundColor: `linear-gradient(180deg, ${buttonColor} 0%, #007829 100%)` }}
          className={styles.circleButton}
        >
          {buttonText}
        </Button>
      )}
      <div>
        <div className={styles.container}>
          <div className={styles.pentagon}>
            {demandAreas?.slice(0, 2).map((area: any, index: any) => {
              const areaColor = parseJSON(area.color);
              const sortedCategories = sortCategoriesByPriority(area.categories);

              return (
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => handleAreaClick(area.id, area.name)}
                  className={cn(
                    styles.pentagonSector,
                    sortedCategories.length === 2 && styles.pentagonSecondSector,
                    sortedCategories.length > 3 && styles.pentagonFourSector,
                  )}
                  onKeyDown={(event) => handleKeyDown(event, area.id, area.name)}
                  key={area.id}
                  style={{ backgroundColor: areaColor.value }}
                >
                  <div>
                    {sortedCategories.map((category: any) => {
                      const hasUserProducts = fillAllCategories
                        ? fillAllCategories
                        : checkBoughtProductsInCategory(category);

                      return (
                        <div
                          role="presentation"
                          onClick={(e) => {
                            e.stopPropagation();

                            handleCategoryClick && handleCategoryClick(area.id, category);
                          }}
                          style={{
                            width: index === 0 ? topLeftWidth : topRightWidth,
                            background: hasUserProducts
                              ? areaColor.value
                              : category.isAddedToWishlist
                                ? `repeating-linear-gradient(-45deg, ${areaColor.value}, ${areaColor.value} 5px, rgba(255, 255, 255, 0.25) 5px, rgba(255, 255, 255, 0.25) 10px)`
                                : 'rgba(255, 255, 255, 0.85)',
                            boxShadow:
                              !category.isAddedToWishlist && !hasUserProducts
                                ? '2px -2px 4px 0px rgba(0, 0, 0, 0.30) inset'
                                : '0px 5px 30px rgba(0, 0, 0, 0.05), 1px 4px 5px rgba(0, 0, 0, 0.30)',
                          }}
                          key={category.id}
                          className={styles.trapezoidCard}
                        >
                          <IconComponent
                            className={styles.icon}
                            iconData={category?.icon}
                            color={hasUserProducts || category.isAddedToWishlist ? '#fff' : areaColor.value}
                          />
                          <EndUserTypography
                            type="subText"
                            component="Text"
                            className={styles.title}
                            style={{
                              color: category.isAddedToWishlist || hasUserProducts ? '#fff' : areaColor.value,
                              textAlign: 'center',
                              minHeight: '40px',
                            }}
                          >
                            {category.name}
                          </EndUserTypography>
                        </div>
                      );
                    })}
                  </div>
                  <EndUserTypography
                    type="headlineFourth"
                    component="Title"
                    className={styles.title}
                    style={{
                      margin: 0,
                      whiteSpace: 'normal',
                      overflow: 'hidden',
                      transform: 'translate(-1%)',
                      color: '#fff',
                      textAlign: 'center',
                      position: 'absolute',
                      bottom: 6,
                      lineHeight: '33px',
                      display: 'block',
                    }}
                  >
                    {area.name}
                  </EndUserTypography>
                </div>
              );
            })}
          </div>
          <div className={styles.rectangle}>
            {demandAreas?.slice(2).map((area: any, index: any) => {
              const areaColor = parseJSON(area.color);
              const sortedCategories = sortCategoriesByPriority(area.categories);

              return (
                <div
                  role="button"
                  tabIndex={0}
                  onKeyDown={(event) => handleKeyDown(event, area.id, area.name)}
                  onClick={() => handleAreaClick(area.id, area.name)}
                  className={styles.rectangleSector}
                  key={area.id}
                  style={{ backgroundColor: areaColor.value }}
                >
                  <EndUserTypography
                    type="headlineFourth"
                    component="Title"
                    className={styles.title}
                    style={{
                      margin: 0,
                      marginTop: '12px',
                      paddingBottom: 12,
                      transform: 'translate(-3%)',
                      color: '#fff',
                    }}
                  >
                    {area.name}
                  </EndUserTypography>
                  <div className={styles.cardsWrapper}>
                    {sortedCategories.map((category: any) => {
                      const hasUserProducts = fillAllCategories
                        ? fillAllCategories
                        : checkBoughtProductsInCategory(category);

                      return (
                        <div
                          role="presentation"
                          onClick={(e) => {
                            e.stopPropagation();

                            handleCategoryClick && handleCategoryClick(area.id, category);
                          }}
                          className={styles.card}
                          key={category.id}
                          style={{
                            width: index === 0 ? bottomLeftWidth : bottomRightWidth,
                            background: hasUserProducts
                              ? areaColor.value
                              : category.isAddedToWishlist
                                ? `repeating-linear-gradient(-45deg, ${areaColor.value}, ${areaColor.value} 5px, rgba(255, 255, 255, 0.32) 5px, rgba(255, 255, 255, 0.32) 10px)`
                                : 'rgba(255, 255, 255, 0.85)',
                            boxShadow:
                              !category.isAddedToWishlist && !hasUserProducts
                                ? '2px -2px 4px 0px rgba(0, 0, 0, 0.30) inset'
                                : '0px 5px 30px rgba(0, 0, 0, 0.05), 1px 4px 5px rgba(0, 0, 0, 0.30)',
                          }}
                        >
                          <div>
                            <IconComponent
                              className={styles.icon}
                              iconData={category?.icon}
                              color={hasUserProducts || category.isAddedToWishlist ? '#fff' : areaColor.value}
                            />
                            <EndUserTypography
                              type="subText"
                              component="Text"
                              className={styles.title}
                              style={{
                                textAlign: 'center',
                                color:
                                  category.isAddedToWishlist || hasUserProducts ? '#fff' : areaColor.value,
                                minHeight: '40px',
                              }}
                            >
                              {category.name}
                            </EndUserTypography>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
