import { FC } from 'react';
import { Form } from 'antd';
import {
  clearListFilters,
  formatArrangedData,
  getArrangesInitialValues,
  getParsedSelectedItems,
  getSelectedItemsLabels,
} from '../../../../lib/utils/arranges-helpers';
import { useAppSelector } from '../../../../lib/store/hooks';
import { TENANT_COLUMN } from '../../helpers/constants';
import { FilterListPopup } from '../../../../features/table/components/popups/FilterListPopup';
import { useStringArrange } from '../../../../features/table/helpers/useStringArrange';
import { useTenantArrangeRequests } from '../../hooks/useTenantArrangeRequests';
import { useArrangeSubmit } from '../../../../features/table/helpers/useArrangeSubmit';
import { TableHeaderCell } from '../../../../components/Table/TableHeaderCell/TableHeaderCell';
import { useTranslation } from 'react-i18next';
import { User } from '../../../userManagement/helpers/constants';
import { useLazyGetUsersForFilteringQuery } from '../../../../lib/store/api/usersManagement';

const arrangedId = TENANT_COLUMN.ASSIGNED_USERS;
const filterFieldName = 'assignedUsers';

const columns = 'pages.tenants.columns';

export const TenantAssignedUsersHeaderCell: FC = () => {
  const { t } = useTranslation();

  const {
    arrangeId,
    filtering: { filter },
  } = useAppSelector(({ tenants }) => tenants);
  const isActiveArrange = arrangeId === arrangedId;
  const [form] = Form.useForm();
  const getSelectedItems = () => getSelectedItemsLabels(form);

  const [getUsers] = useLazyGetUsersForFilteringQuery();

  const { filtering } = useStringArrange({
    form,
    request: (value: string) => {
      return getUsers({ searchFieldName: 'searchByLogin', search: value }).unwrap();
    },
    formatResponse: (data: { content: any[] }) => formatArrangedData(data, User.USERNAME),
  });

  const clearFilters = () => {
    clearListFilters(form);
    filtering.clearOptions();
  };

  const { applyArranges, removeFilter, removeFilterValue } = useTenantArrangeRequests({
    arrangedId,
    clearFilters,
    getFilterLabel: () => getSelectedItems().join(', '),
    getFilters: () => ({ [filterFieldName]: getSelectedItems() }),
    form,
    withSorting: false,
    filterFieldName,
  });

  const headerCellProps = {
    arrangedId: TENANT_COLUMN.ASSIGNED_USERS,
    title: t(`${columns}.users`),
    removeFilter,
    filterLabel: Array.isArray(filter) && filter.join(', '),
    isActiveArrange,
    form,
    applyArranges,
    getParsedItems: () => getParsedSelectedItems(form),
    removeFilterValue,
  };

  const { onFinish } = useArrangeSubmit(applyArranges);

  return (
    <TableHeaderCell {...headerCellProps}>
      <FilterListPopup
        initialValues={getArrangesInitialValues(form)}
        form={form}
        filtering={filtering}
        onFinish={onFinish}
        isActiveArrange={isActiveArrange}
      />
    </TableHeaderCell>
  );
};
