import { UserSearchDataHeaderCell } from '../../components/UsersTable/UserSearchDataHeaderCell';

export const useUserSearchDataColumn = (props: any) => {
  const { columnProps, ...rest } = props;

  return {
    ...columnProps,
    dataIndex: props.arrangedId,
    title: <UserSearchDataHeaderCell {...rest} />,
  };
};
