import React, { useEffect, useMemo } from 'react';
import { Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useResetFormOnCloseModal } from '../../../../../lib/hooks/useResetFormOnCloseModal';
import { AppModal } from '../../../../../components/AppModal';
import { useAppDispatch, useAppSelector } from '../../../../../lib/store/hooks';
import { ModalActions } from '../../../../../lib/models/Modal';
import { FormItemName } from '../../../../../lib/models/Form';
import NameFormItem from '../FormItems/NameFormItem';
import { TemplateFormItem } from '../FormItems/TemplateFormItem';
import { EmailSkpFormItem } from '../FormItems/EmailSKPFormItem';
import { DateFormItem } from '../FormItems/DateFormItem';
import styles from '../index.module.scss';
import { CreationDateFormItem } from '../FormItems/CreationDateFormItem';
import { CommentFormItem } from '../FormItems/CommentFormItem';
import { useUpdateProjectMutation } from '../../../../../lib/store/api/project';
import { EditProjectT } from '../../../../../lib/types/projects';
import { setModalClosed } from '../../../../../lib/store/slices/projectsSlice';
import { transformProjectToSendData, transformServerDataToInitialProject } from '../../../helpers/helpers';

const projectsTranslation = 'pages.projects';

export type EditProjectValuesT = {
  id: string | number;
  name: string;
  template: { title: string; value: string };
  contactSKP?: { title: string; value: string };
  contractStart?: Date;
  contractEnd?: Date;
  comment?: string;
};

type Props = {
  tenantId: string;
};

export const EditProjectModal = ({ tenantId }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [updateProject, { isLoading }] = useUpdateProjectMutation();
  const [messageApi, contextHolder] = message.useMessage();

  const { isModalOpen, modalType, selectedProject } = useAppSelector(({ projects }) => projects);
  const isOpened = isModalOpen && modalType === ModalActions.EDIT;

  useResetFormOnCloseModal({ form, isOpen: isOpened });

  const onFinish = async (values: EditProjectValuesT) => {
    const sendData: EditProjectT = {
      ...values,
      ...transformProjectToSendData(values),
      id: selectedProject!.id,
      tenantId,
    };

    try {
      await updateProject(sendData).unwrap();
      messageApi.success(t(`${projectsTranslation}.editModal.successText`));
      dispatch(setModalClosed());
    } catch (err: any) {
      messageApi.error(err?.data?.message || t(`${projectsTranslation}.editModal.errorText`));
    }
  };

  const initialFormValues = useMemo(() => {
    if (selectedProject) {
      return transformServerDataToInitialProject(selectedProject);
    }
    return {};
  }, [selectedProject]);

  useEffect(() => {
    form.setFieldsValue(initialFormValues);
  }, [form, initialFormValues]);

  const onClose = () => {
    dispatch(setModalClosed());
  };

  const onConfirm = () => {
    form.submit();
  };

  return (
    <AppModal
      className={styles.modal}
      open={isOpened}
      onCancel={onClose}
      onOk={onConfirm}
      getContainer={false}
      titleText={t(`${projectsTranslation}.editModal.title`)}
      isOkLoading={isLoading}
    >
      {contextHolder}
      {isOpened && (
        <Form initialValues={initialFormValues} className={styles.form} form={form} onFinish={onFinish}>
          <NameFormItem />
          <TemplateFormItem tenantId={tenantId} />
          <EmailSkpFormItem />
          <div className={styles.form_dateInputsWrapper}>
            <DateFormItem
              form={form}
              name={FormItemName.CONTRACT_START}
              placeholder={t(`${projectsTranslation}.formItem.contractStartDate`)}
            />
            <DateFormItem
              form={form}
              name={FormItemName.CONTRACT_END}
              placeholder={t(`${projectsTranslation}.formItem.contractEndDate`)}
            />
          </div>
          <CreationDateFormItem />
          <CommentFormItem />
        </Form>
      )}
    </AppModal>
  );
};
