import { User } from '../../../helpers/constants';
import { useUserDateColumn } from '../useUserDateColumn';
import { useTranslation } from 'react-i18next';

const lastEdit = 'shared.columns.lastEdit';

export function useUserLastEditedDateColumn() {
  const { t } = useTranslation();

  return useUserDateColumn({
    arrangedId: User.LAST_MODIFIED_DATE,
    title: t(lastEdit),
    filterFromName: 'lastModifiedDateFrom',
    filterToName: 'lastModifiedDateTo',
  });
}
