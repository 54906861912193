import { FC } from 'react';
import { IconButton } from '../IconButton';
import { ReactComponent as EyeIcon } from './../../assets/icons/eye-slash.svg';
import { ReactComponent as EyeFillIcon } from './../../assets/icons/outline-eye.svg';
import cn from 'classnames';
import styles from './styles.module.scss';
import { useDeleteProductFromIrrelevantMutation } from '../../lib/store/api/endUser';
import { useAppDispatch, useAppSelector } from '../../lib/store/hooks';
import { modifyProduct } from '../../lib/store/slices/endUserSlice';
import { sendDataToAnalytics } from '../../lib/utils/sendDataToAnalytics';

type Props = {
  primaryColor?: string;
  onClickOpenModal: () => void;
  onModalClose: () => void;
  isIrrelevant: string;
  product: any;
  setIsFilled: any;
  isFilled: string;
};

export const EndUserIrrelevantButton: FC<Props> = ({
  primaryColor,
  onClickOpenModal,
  isIrrelevant,
  setIsFilled,
  isFilled,
  product,
}) => {
  const dispatch = useAppDispatch();
  const { tenantAndProject, isGuestMode } = useAppSelector(({ endUser }) => endUser);
  const [deleteProductFromIrrelevant] = useDeleteProductFromIrrelevantMutation();
  const handleSwitch = async () => {
    setIsFilled(isIrrelevant);

    if (!isIrrelevant) {
      onClickOpenModal();
    } else {
      if (isGuestMode) {
        dispatch(modifyProduct({ ...product, irrelevantSurveyReason: null, countUserProducts: null }));

        sendDataToAnalytics(
          `Delete from irrelevant, productName: ${product.name}, productId: ${product.productId}`,
        );

        return;
      }

      try {
        await deleteProductFromIrrelevant({
          productId: product.id,
          tenantId: String(tenantAndProject?.tenantId),
          projectId: Number(tenantAndProject?.projectId),
        }).unwrap();

        sendDataToAnalytics(
          `Desinteresse Produkt`,
          'Ausblendung Produkt',
          `User blendet das Produkt ${product.name} aus`,
        );
      } catch (error) {
        // console.log(error);
      }
    }
  };

  return (
    <IconButton
      onClick={handleSwitch}
      className={cn(styles.button, isIrrelevant && styles.overlayActive)}
      icon={isFilled ? <EyeIcon fill={primaryColor} /> : <EyeFillIcon />}
    />
  );
};
