import { FC } from 'react';
import { components, ControlProps, GroupBase } from 'react-select';
import { InputLabel } from '../../../InputLabel';
import { SelectPropsT } from '../../types';

type Props = {
  selectProps: SelectPropsT;
} & ControlProps<unknown, boolean, GroupBase<unknown>>;

export const Control: FC<Props> = (props) => {
  const { label, listProps } = props.selectProps;
  const asLabel = props.isFocused || props.hasValue || listProps?.isListEnabled;

  return (
    <div>
      {label && <InputLabel text={label} className={asLabel ? 'asLabel' : ''} />}
      <components.Control {...props} />
    </div>
  );
};
