import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormItemName } from '../../../../lib/models/Form';
import { FormItem } from '../../../../components/FormItem';
import { FormInput } from '../../../../components/FormInput';

export const ClientCityFormItem: FC = () => {
  const { t } = useTranslation();
  const clientCity = 'unique.formItems.clientCity';

  return (
    <FormItem
      name={FormItemName.CLIENT_CITY}
      hasFeedback={true}
      rules={[
        {
          required: false,
        },

        { max: 100 },
      ]}
    >
      <FormInput label={t(`${clientCity}.label`)} />
    </FormItem>
  );
};
