import {EndUserDemandAreaDesktop} from '../EndUserDemandAreaDesktop';
import {EndUserDemandAreaMobile} from '../EndUserDemandAreaMobile';
import {useAppSelector} from '../../../../lib/store/hooks';
import {useContainerQuery} from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import {SquareShadowHouse} from '../SquareShadowHouse';
import {useMemo} from 'react';
import {SquareShadowTiles} from '../SquareShadowTiles';
import {SquareShadowHouseNew} from "../SquareShadowHouseNew";

export const EndUserDemandArea = () => {
    const {isMobile, isSmallSpecificTablet} = useContainerQuery();
    const {demandArea, tenantAndProject} = useAppSelector(({endUser}) => endUser);

    const slicedDemandAreas = demandArea?.listDemandArea.slice(0, 4);
    const allDemandAreas = demandArea?.listDemandArea || [];

    const applyNewDesign =
        tenantAndProject?.subDomain === 'demo-company' && tenantAndProject?.tenantId === 11289;

    const applyNewHouse =
        (tenantAndProject?.domain === 'dev.hvm.impltech.online' && tenantAndProject?.id === 11486) ||
        (tenantAndProject?.subDomain === 'energie-schwaben' && tenantAndProject?.tenantId === 11002);

    const DesktopSquareComponent = useMemo(() => {
        if (applyNewDesign) {
            return SquareShadowTiles;
        } else if (applyNewHouse) {
            return SquareShadowHouseNew;
        } else {
            return SquareShadowHouse;
        }
    }, [applyNewDesign]);

    return (
        <>
            {isMobile || isSmallSpecificTablet ? (
                <EndUserDemandAreaMobile demandAreas={applyNewDesign ? allDemandAreas : slicedDemandAreas}/>
            ) : (
                <EndUserDemandAreaDesktop
                    demandAreas={applyNewDesign ? allDemandAreas : slicedDemandAreas}
                    ComponentProp={DesktopSquareComponent}
                    applyNewDesign={applyNewDesign}
                />
            )}
        </>
    );
};