import { UserRolesT } from '../../../lib/types/auth';
import { AddUserFormValue } from '../components/CreateUserModal';
import { isLength } from '../../../lib/utils/helpers';

export const transformUserManagementFormValuesToServerPayload = (
  values: AddUserFormValue,
) => {
  return {
    allAdminTenants: values.allAdminTenants,
    login: values.username,
    email: values.email,
    fullName: values.fullName,
    langKey: values.language.value,
    authority: values.rights.value as UserRolesT,
    tenants: isLength(values?.tenants)
      ? values?.tenants?.map((t) => {
          return { id: t?.value, name: t?.title };
        })
      : Array.isArray(values?.tenants)
      ? []
      : [
          {
            id: (values as any).tenants.value,
            name: (values as any).tenants!.title,
          },
        ],
  };
};
