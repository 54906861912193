import { useTranslation } from 'react-i18next';
import { TENANT_COLUMN } from '../helpers/constants';
import { useTenantSearchDataColumn } from './useTenantSearchDataColumn';
import { Typography } from 'antd';

import tableStyles from '../../../components/Table/index.module.scss';

const { Text } = Typography;

const genericCommentColumnProps = {
  width: 220,
  render: (text: string) => <Text>{text ?? '-'}</Text>,
};

const email = 'pages.tenants.columns.email';

export const useTenantEmailColumn = () => {
  const { t } = useTranslation();

  return useTenantSearchDataColumn({
    title: t(email),
    arrangedId: TENANT_COLUMN.EMAIL,
    searchFieldName: 'searchByClientEmail',
    filterFieldName: 'includeClientEmails',
    columnProps: genericCommentColumnProps,
    headerCellProps: {
      rootProps: { className: tableStyles.hiddenHeaderCell },
    },
  });
};
