import { useTranslation } from 'react-i18next';
import { TableHeaderCell } from '../../../components/Table/TableHeaderCell/TableHeaderCell';
import { PRODUCTS_COLUMNS } from '../helpers/consts';
import tableStyles from '../../../components/Table/index.module.scss';

const priority = 'pages.products.products.columns.priority';

export const useProductPriorityColumn = () => {
  const { t } = useTranslation();

  return {
    dataIndex: PRODUCTS_COLUMNS.PRIORITY,
    title: <TableHeaderCell withArrange={false} title={t(priority)} />,
    width: 192,
    className: tableStyles.allowWrap,
  };
};
