import styles from './index.module.scss';
import React from 'react';
import { ReactComponent as AddRuleIcon } from './../../../../assets/icons/add-rule-plus.svg';
import { Typography } from 'antd';

const { Text } = Typography;

export const EmptyPlaceholder = () => {
  return (
    <div className={styles.emptyRules}>
      <AddRuleIcon />
      <Text className={styles.addNewRuleTitle}>Select an operator to start</Text>
    </div>
  );
};
