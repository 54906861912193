import { FC, ReactNode } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/Button';

import styles from './index.module.scss';

const arranges = 'shared.arranges';

type Props = {
  sort?: ReactNode;
  filter?: ReactNode;
};

export const ArrangePopupWrapper: FC<Props> = ({ sort, filter }) => {
  const { t } = useTranslation();

  const form = Form.useFormInstance();

  return (
    <div className={styles.arrangePopupWrapper}>
      {sort}
      {filter}
      <Button style={{ marginTop: '16px' }} onClick={form.submit}>
        {t(`${arranges}.actionButton`)}
      </Button>
    </div>
  );
};
