import React, { FC, useEffect, useRef } from 'react';
import { Form } from 'antd';
import { FormItemName } from '../../../../lib/models/Form';
import { FormItem } from '../../../../components/FormItem';
import { FormInput } from '../../../../components/FormInput';
import { AppSelect } from '../../../../components/AppSelect';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { useGetProject } from '../../../../lib/hooks/useGetProjects';
import { useGetIconOptions } from '../../../productCategories/hooks/useGetIconOptions';
import { useUpdateProjectPointsLimitMutation } from '../../../../lib/store/api/project';
import { useParams } from 'react-router-dom';
import lodashDebounce from 'lodash.debounce';
import { useStatusLevelInitialOptions } from '../../hooks/useStatusLevelInitialOptions';
import { MessageInstance } from 'antd/es/message/interface';

const DEBOUNCED_DELAY = 300; // ms

const formItems = 'pages.statusLevel.form.formItems';
const errors = 'pages.statusLevel.form.errors';

type Props = {
  messageApi: MessageInstance;
};

export const StatusLevelHeaderOptions: FC<Props> = ({ messageApi }) => {
  const [form] = Form.useForm();

  const { t } = useTranslation();

  const { id: tenantId } = useParams();
  const [updateProject] = useUpdateProjectPointsLimitMutation();
  const { data: projectData } = useGetProject();

  const isInit = useRef<boolean>(true);

  const { options, iconsList } = useGetIconOptions(styles.icon, styles.iconWrapper);

  const currentIcon = Form.useWatch(FormItemName.STATUS_LEVEL_ICON, form);

  const handleChangeFields = async (fields: {
    pointUnits?: string;
    pointsIcon?: {
      id: number;
      s3Url: string;
      originalFileName: string;
    };
    maxPoints?: string;
  }) => {
    if (projectData) {
      try {
        await updateProject({
          ...projectData,
          ...fields,
          tenantId: String(tenantId),
        }).unwrap();
        //eslint-disable-next-line
      } catch (error: any) {
        messageApi.error(error?.data?.message || t(`${errors}.cantSaveData`));
      }
    }
  };

  const debouncedHandleChangeFields = lodashDebounce(handleChangeFields, DEBOUNCED_DELAY);

  useStatusLevelInitialOptions({ form, isInit, iconsList, projectData, tenantId });

  useEffect(() => {
    if (currentIcon && !isInit.current) {
      handleChangeFields({
        pointsIcon: iconsList?.find((icon) => icon.id === currentIcon.value),
      });
    }
  }, [currentIcon]);

  return (
    <Form className={styles.form} form={form} id="status-level-options">
      <FormItem
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          debouncedHandleChangeFields({ pointUnits: event.target.value });
        }}
        style={{ width: 170 }}
        name={FormItemName.STATUS_LEVEL_UNIT}
        hasFeedback={true}
        rules={[{ required: true }, { max: 100 }]}
      >
        <FormInput label={t(`${formItems}.unit`)} />
      </FormItem>

      <FormItem
        style={{ width: 170 }}
        rules={[{ required: true }]}
        name={FormItemName.STATUS_LEVEL_ICON}
        hasFeedback={true}
      >
        <AppSelect label={t(`${formItems}.icon`)} options={options} />
      </FormItem>

      <FormItem
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const value = Number(event.target.value);

          if (value > 0) {
            debouncedHandleChangeFields({ maxPoints: String(value) });
          }
        }}
        style={{ width: 170 }}
        name={FormItemName.STATUS_LEVEL_MAX_POINTS}
        hasFeedback={true}
        rules={[
          { required: true },
          { max: 100 },
          { pattern: /^\d+(\.\d+)?$/, message: t(`${errors}.minPoints`) },
        ]}
      >
        <FormInput type="number" label={t(`${formItems}.maxPoints`)} />
      </FormItem>
    </Form>
  );
};
