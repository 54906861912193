import { FC, useCallback, useMemo } from 'react';
import cn from 'classnames';
import { AppSelect } from '../../../../../components/AppSelect';
import { UpdateRuleBuilderPayload } from '../../CustomRuleBuilder';
import { useAppSelector } from '../../../../../lib/store/hooks';
import { StateValues } from '../../../types/entities';

import styles from './index.module.scss';

type Props = {
  value: string;
  subValue?: string | null;
  ruleId: string;
  handleChange: (values: UpdateRuleBuilderPayload) => void;
  disabled: boolean;
};

export const StateOperator: FC<Props> = ({ value, subValue, handleChange, ruleId, disabled }) => {
  // TODO add translation
  const { dictionaries } = useAppSelector(({ ruleBuilder }) => ruleBuilder);
  const isRecommendedSelected = value == StateValues.Recommended;

  const onChange = (values: { value: string }) => {
    handleChange({ value: values.value, ruleId, subValue: undefined, subValue2: undefined });
  };

  const onChangeFirstAdditionalField = useCallback(
    (values: { value: string }) => {
      handleChange({ value, ruleId, subValue: values?.value, subValue2: undefined });
    },
    [handleChange, ruleId, value],
  );

  const valueOptions = useMemo(() => {
    return dictionaries?.states
      ? Object.keys(dictionaries?.states as object)?.map((option) => ({
          value: option,
          title: option,
        }))
      : null;
  }, [dictionaries?.states]);

  const recommendedOptions = useMemo(() => {
    return dictionaries?.states?.[StateValues.Recommended]?.map((option) => ({
      value: option,
      title: option,
    }));
  }, [dictionaries?.states]);

  const FirstAdditionalComponent = useMemo(() => {
    if (isRecommendedSelected) {
      return (
        <AppSelect
          key={StateValues.Recommended}
          disabled={disabled}
          onChange={onChangeFirstAdditionalField}
          className={cn(styles.select, styles.asSecondSelect, styles.mSize)}
          options={recommendedOptions}
          value={subValue}
          label="Order"
        />
      );
    } else {
      return null;
    }
  }, [isRecommendedSelected, disabled, onChangeFirstAdditionalField, recommendedOptions, subValue]);

  return (
    <div className={styles.container}>
      <AppSelect
        disabled={disabled}
        className={cn(styles.select, { [styles.secondOptionOpened]: isRecommendedSelected })}
        onChange={onChange}
        options={valueOptions}
        value={value}
        label="State"
      />
      {FirstAdditionalComponent}
    </div>
  );
};
