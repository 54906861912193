import { FC, useState } from 'react';
import { InfoIcon } from '../../../../../assets/icons/InfoIcon';
import { Form, FormInstance, Tooltip, Typography } from 'antd';

import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { FileUploader } from '../../../../../components/FileUploader';
import { UploadImageLabel } from '../../../../../components/UploadImageLabel';
import { UploadResponseT } from '../../../../../lib/types/entities';
import { FormItemName } from '../../../../../lib/models/Form';
import { FormItem } from '../../../../../components/FormItem';
import { MIMEType, TargetType } from '../../../../../lib/types/media';

const { Text } = Typography;
const MAX_FILE_SIZE = 0.3;

const beforeUploadFaviconError = 'pages.settings.errors.beforeUploadFaviconError';
const favicon = 'pages.settings.browserInformation.favicon';
const favIconDescription = 'pages.settings.browserInformation.favIconDescription';

type Props = {
  form: FormInstance;
};

export const FaviconCard: FC<Props> = ({ form }) => {
  const [uploadingFavicon, setUploadingFavicon] = useState(false);
  const { t } = useTranslation();

  const faviconMedia = Form.useWatch(FormItemName.FAVICON, form);

  const fieldHandleChange = (fieldName: string, value: UploadResponseT | null) => {
    form.setFieldValue(fieldName, value);
  };

  const afterUploadAction = (fileData: UploadResponseT) => {
    fieldHandleChange(FormItemName.FAVICON, fileData);
  };

  const onDeleteClick = () => {
    fieldHandleChange(FormItemName.FAVICON, null);
  };

  return (
    <FormItem nostyle="true" name={FormItemName.FAVICON}>
      <div className={styles.container}>
        <div className={styles.header}>
          <Text className={styles.subTitle}>{t(favicon)}</Text>
          <Tooltip
            color="#191919"
            placement="bottomLeft"
            title={t(favIconDescription)}
            overlayInnerStyle={{
              width: 352,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <InfoIcon className={styles.tooltipIcon} style={{ marginLeft: 10, cursor: 'pointer' }} />
          </Tooltip>
        </div>

        <FileUploader
          targetType={TargetType.GS_FAVICON}
          accept={MIMEType.FAVICON}
          afterUploadAction={afterUploadAction}
          errorText={t(beforeUploadFaviconError)}
          maxFileSize={MAX_FILE_SIZE}
          disabled={uploadingFavicon}
          setUploading={setUploadingFavicon}
          uploadLabel={({ onDeleteMedia }) => (
            <UploadImageLabel
              mediaObject={uploadingFavicon ? {} : faviconMedia}
              uploading={uploadingFavicon}
              deleteOnClick={onDeleteClick}
              imgHeight={52}
              onDeleteMedia={onDeleteMedia}
            />
          )}
        />
      </div>
    </FormItem>
  );
};
