import { DemandArea } from '../../../lib/types/endUser';
import { ProductT } from '../../../lib/types/product';
import {
  getAmountOfCoveredProducts,
  calculateExpectedProductPoints,
} from '../../../lib/utils/end-user-helpers';
import { parseJSON } from '../../../lib/utils/helpers';

export const findDemandAreaColor = (categoryId: number, demandAreas?: DemandArea[]) => {
  const area = demandAreas?.find((area: any) => {
    const category = area.categories?.find((category: any) => category.id === categoryId);
    return category !== undefined;
  });

  const areaColor = parseJSON(area?.color);

  return areaColor ? areaColor?.value : '#FFFFFF';
};

export const getProductPoints = (product: ProductT) => {
  const amountOfCoveredProduct = getAmountOfCoveredProducts(product);
  const points = calculateExpectedProductPoints(product, amountOfCoveredProduct);

  return points || 0;
};

export const getUniqueItems = (items: any[]) => {
  const unique = items?.filter((obj, idx, arr) => idx === arr.findIndex((t) => t.id === obj.id));

  return unique;
};
