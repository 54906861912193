import { EditProjectValuesT } from '../components/Modals/EditProjectModal';
import { CreateProjectValuesT } from '../components/Modals/CreateProjectModal';
import { ProjectT } from '../../../lib/types/entities';
import { transformToDateWithSlash } from '../../../lib/utils/helpers';

export const transformProjectToSendData = (values: CreateProjectValuesT | EditProjectValuesT) => {
  return {
    ...values,
    template: { id: +values.template.value, name: values.template.title },
    contactSKP: values.contactSKP?.title ? values.contactSKP?.title : '',
    contractStart: values.contractStart?.toLocaleString(),
    contractEnd: values.contractEnd?.toLocaleString(),
  };
};

export const transformServerDataToInitialProject = (selectedProject: ProjectT) => {
  const createdDate = selectedProject?.createdDate ? new Date(selectedProject?.createdDate) : null;
  if (selectedProject) {
    return {
      ...selectedProject,
      template: { value: selectedProject?.template?.id, title: selectedProject?.template?.name },
      contractStart: selectedProject?.contractStart ? new Date(selectedProject?.contractStart) : null,
      contractEnd: selectedProject?.contractEnd ? new Date(selectedProject?.contractEnd) : null,
      contactSKP: { title: selectedProject?.contactSKP, value: selectedProject?.contactSKP },
      creationDateForUser: createdDate ? transformToDateWithSlash(createdDate) : null,
    };
  }
  return {};
};
