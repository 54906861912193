import { Outlet } from 'react-router-dom';
import { ToggleLanguage } from '../../../../components/ToggleLanguage';

export const AuthLayout = () => {
  return (
    <>
      <div style={{ position: 'absolute', right: 20, top: 20 }}>
        <ToggleLanguage />
      </div>

      <Outlet />
    </>
  );
};
