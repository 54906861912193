import { useState } from 'react';
import { Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../lib/store/hooks';
import { ModalActions } from '../../../../../lib/models/Modal';
import { useResetFormOnCloseModal } from '../../../../../lib/hooks/useResetFormOnCloseModal';
import { AppModal } from '../../../../../components/AppModal';
import { useCreateBonusCatalogMutation } from '../../../../../lib/store/api/bonusCatalog';
import { BONUS_CATALOG_FORM_ITEMS } from '../../../helpers';
import { UploadResponseT } from '../../../../../lib/types/entities';
import { CreateBonusCatalogPayload } from '../../../../../lib/types/bonusCatalog';
import { setModalClosed } from '../../../../../lib/store/slices/bonusCatalogSlice';
import { initialMediaTenantState } from '../../../../tenants/helpers/constants';
import { UploadPicture } from '../FormItems/UploadPicture';
import { PayoutOptions } from '../FormItems/PayoutOptions';
import { TitleFormItem } from '../FormItems/TitleFormItem';
import { DescriptionFormItem } from '../FormItems/DescriptionFormItem';
import { NameFormItem } from '../FormItems/NameFormItem';
import { PayoutTypes } from '../FormItems/PayoutTypes';
import { BonusCatalogFormValues } from '../../../types';

import styles from '../../BonusCatalog/index.module.scss';

const bonusCatalogT = 'pages.bonusCatalogTab';

type Props = {
  tenantId: string;
  modelId: string;
};

export const CreateBonusCatalogModal = ({ tenantId, modelId }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const { isModalOpen, modalType } = useAppSelector(({ bonusCatalog }) => bonusCatalog);
  const isOpened = isModalOpen && modalType === ModalActions.ADD;
  const [pictureState, setPictureState] = useState<UploadResponseT | null>(null);

  const [createBonusCatalog, { isLoading }] = useCreateBonusCatalogMutation();

  useResetFormOnCloseModal({ form, isOpen: isOpened });

  const onClose = () => {
    dispatch(setModalClosed());
    setPictureState(null);
  };

  const onFinish = async (values: BonusCatalogFormValues) => {
    const payload: CreateBonusCatalogPayload = {
      name: values[BONUS_CATALOG_FORM_ITEMS.NAME],
      title: values[BONUS_CATALOG_FORM_ITEMS.TITLE],
      description: values[BONUS_CATALOG_FORM_ITEMS.DESCRIPTION],
      payoutOption: values[BONUS_CATALOG_FORM_ITEMS.PAYOUT_OPTION],
      payoutType: values[BONUS_CATALOG_FORM_ITEMS.PAYOUT_TYPE],
      picture: pictureState,
      projectId: modelId,
      tenantId,
      id: 0,
    };
    try {
      await createBonusCatalog(payload).unwrap();
      messageApi.success(t(`${bonusCatalogT}.addModal.successText`));
      onClose();
    } catch (err: any) {
      messageApi.error(err?.data?.message || t(`${bonusCatalogT}.addModal.errorText`));
    }
  };

  const onConfirm = () => {
    form.submit();
  };

  const afterUploadAction = (value: UploadResponseT | null) => {
    setPictureState(value);
  };

  const deletePicture = () => afterUploadAction(initialMediaTenantState as any);

  return (
    <AppModal
      className={styles.modal}
      open={isOpened}
      onCancel={onClose}
      onOk={onConfirm}
      getContainer={false}
      titleText={t(`${bonusCatalogT}.addModal.title`)}
      isOkLoading={isLoading}
    >
      {contextHolder}
      {isOpened && (
        <Form className={styles.form} form={form} onFinish={onFinish}>
          <NameFormItem />
          <TitleFormItem />
          <DescriptionFormItem />

          <UploadPicture
            afterUploadAction={afterUploadAction}
            picture={pictureState}
            onDeleteClick={deletePicture}
          />
          <PayoutOptions />
          <PayoutTypes />
        </Form>
      )}
    </AppModal>
  );
};
