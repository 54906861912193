import { FC } from 'react';
import styles from './index.module.scss';
import { parseJSON, sortCategoriesByPriority } from '../../../../lib/utils/helpers';
import { Category, DemandArea } from '../../../../lib/types/endUser';
import { SmallHouseItem } from '../SmallHouseItem';
import { checkBoughtProductsInCategory } from '../../../../lib/utils/end-user-helpers';
import { useAppSelector } from '../../../../lib/store/hooks';

type Props = {
  demandAreaColor: string;
  demandAreas: DemandArea[];
  currentDemandArea: DemandArea;
  onAreaClick: (index: number) => void;
  onClickCategory: (category: Category) => void;
};

export const SmallDemandAreaHouse: FC<Props> = ({
  demandAreaColor,
  demandAreas,
  currentDemandArea,
  onAreaClick,
  onClickCategory,
}) => {
  const { tenantAndProject } = useAppSelector(({ endUser }) => endUser);

  const handleAreaClick = (area: DemandArea) => {
    onAreaClick(demandAreas.findIndex((item) => item.id === area.id));

    const sortedCategories = sortCategoriesByPriority(area.categories);

    let category: Category | undefined = sortedCategories[0];

    //hardcode below
    if (tenantAndProject?.subDomain === 'energie-schwaben') {
      if (area.name === 'Energie') {
        const gasCategory = sortedCategories.find((category) => category.name === 'Gas');
        const stromCategory = sortedCategories.find((category) => category.name === 'Strom');

        const isGasCovered = gasCategory && checkBoughtProductsInCategory(gasCategory);
        const isStromCovered = stromCategory && checkBoughtProductsInCategory(stromCategory);

        if (isGasCovered && !isStromCovered) {
          category = stromCategory;
        } else if (isStromCovered && !isGasCovered) {
          category = gasCategory;
        } else if (isGasCovered && isStromCovered) {
          category = gasCategory;
        } else {
          category = gasCategory;
        }
      }
    }

    if (category) {
      onClickCategory(category);
    }
  };

  return (
    <div className={styles.smallHouseContainer}>
      <div style={{ backgroundColor: demandAreaColor }} className={styles.smallHousePentagon}>
        {demandAreas.slice(0, 2).map((area) => {
          const areaColor = parseJSON(area?.color);

          return (
            <SmallHouseItem
              onAreaClick={() => handleAreaClick(area)}
              key={area.id}
              currentDemandArea={currentDemandArea}
              areaColor={areaColor.value}
              areaId={area.id}
            />
          );
        })}
      </div>
      <div className={styles.smallHouseRectangle}>
        {demandAreas.slice(2).map((area) => {
          const areaColor = parseJSON(area?.color);

          return (
            <SmallHouseItem
              onAreaClick={() => handleAreaClick(area)}
              key={area.id}
              currentDemandArea={currentDemandArea}
              areaColor={areaColor.value}
              areaId={area.id}
            />
          );
        })}
      </div>
    </div>
  );
};
