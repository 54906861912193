import { useParams } from 'react-router-dom';
import { useDeleteMediaMutation } from '../store/api/media';
import { DeleteMediaProps } from '../types/media';

export const useDeleteMedia = () => {
  const [deleteMedia] = useDeleteMediaMutation();

  const { id: paramsTenantId } = useParams();

  const onDeleteMedia = ({ id, tenantId }: DeleteMediaProps) => {
    deleteMedia({ id, tenantId: paramsTenantId || tenantId || '1' }); // value 1 used for cases where there is no tenant
  };

  return onDeleteMedia;
};
