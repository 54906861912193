import { FC, memo } from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { theme } from '../../../../app/styles/theme';
import { ReactComponent as EmptyCircleIcon } from '../../../../assets/icons/EmptyCircleIcon.svg';

import styles from './index.module.scss';

type Props = {
  validationItems: { name: string; value: boolean; errorText: string }[];
};

const ValidationCheckingC: FC<Props> = ({ validationItems }) => {
  return (
    <div className={styles.validationItems}>
      {validationItems.map((item) => {
        return (
          <div className={styles.checkValidationItem} key={item.name}>
            {item.value ? (
              <CheckCircleOutlined
                style={{ color: theme.token.colorPrimary }}
              />
            ) : (
              <EmptyCircleIcon width={16} height={16} />
            )}

            <div className={styles.checkValidationItemText}>{item.name}</div>
          </div>
        );
      })}
    </div>
  );
};

export const ValidationChecking = memo(ValidationCheckingC);
