import { FC, ReactNode, CSSProperties } from 'react';
import { useAppSelector } from '../../lib/store/hooks';
import { Typography } from 'antd';
import cn from 'classnames';
import { FontItemT, FontStyles } from '../../lib/types/colors&fonts';
import { TitleProps } from 'antd/es/typography/Title';

import styles from './index.module.scss';
import { EllipsisConfig } from 'antd/es/typography/Base';

type Props = {
  children: ReactNode;
  type: keyof FontStyles;
  component?: 'Title' | 'Paragraph' | 'Text' | 'Link';
  level?: TitleProps['level'];
  className?: string;
  style?: CSSProperties;
  ellipsis?: EllipsisConfig | any;
};

export const EndUserTypography: FC<Props> = ({
  type,
  component = 'Paragraph',
  children,
  level,
  className,
  style,
  ellipsis,
}) => {
  const Component = Typography[component];

  const { brandKit } = useAppSelector(({ endUser }) => endUser);

  const getFontStyling = (font: FontItemT) => {
    const fileName = font?.font?.split('.')?.[0];

    return {
      color: brandKit?.colors[font.colorVariant],
      fontFamily: fileName?.includes(',') ? fileName : `'${fileName}'`,
      fontSize: font.fontSize,
      fontWeight: font.fontWeight,
    };
  };

  if (!brandKit) {
    return null;
  }

  return (
    <Component
      ellipsis={ellipsis}
      className={cn(styles.root, className)}
      style={{ ...getFontStyling(brandKit?.fonts[type]), ...style }}
      level={level}
    >
      {children}
    </Component>
  );
};
