import { ColorVariants } from '../../../lib/types/colors&fonts';
import { DefaultFontStyles } from '../../brandKit/util/constants';
import BigCheckmarkIcon from '../../../assets/icons/big-checkmark.svg';
import CheckmarkIcon from '../../../assets/icons/checkmark.svg';
import StarIcon from '../../../assets/icons/star-fill.svg';
import BulletIcon from '../../../assets/icons/bullet.svg';
import HyphenBulletIcon from '../../../assets/icons/hyphen-bullet.svg';
import { ButtonShape, DefaultIconT, DesignSettingsDataT } from '../types';

export const DefaultColors = {
  first: '#89B1D6',
  second: '#285F93',
  third: '#6BCCC6',
  fourth: '#6BCC9E',
  fifth: '#0D99FF',
};

enum DefaultIcon {
  BIG_CHECK,
  CHECK,
  HYPHEN_BULLET,
  BULLET,
  STAR,
}

const DefaultBulletIcons: DefaultIconT[] = [
  {
    id: DefaultIcon.BIG_CHECK,
    s3Url: BigCheckmarkIcon,
    originalFileName: 'big-check',
  },
  {
    id: DefaultIcon.CHECK,
    s3Url: CheckmarkIcon,
    originalFileName: 'check',
  },
  {
    id: DefaultIcon.HYPHEN_BULLET,
    s3Url: HyphenBulletIcon,
    originalFileName: 'hyphen-bullet',
  },
  {
    id: DefaultIcon.BULLET,
    s3Url: BulletIcon,
    originalFileName: 'bullet',
  },
];

const DefaultIcons: DefaultIconT[] = [
  {
    id: DefaultIcon.STAR,
    s3Url: StarIcon,
    originalFileName: 'star-fill',
  },
];

const DefaultPrimaryButtonOptions = {
  isFilled: true,
  filledColor: ColorVariants.PRIMARY_COLOR,
  isOutlined: false,
  outLinedColor: ColorVariants.SECONDARY_FIRST,
};

const DefaultSecondaryButtonOptions = {
  isFilled: false,
  filledColor: ColorVariants.PRIMARY_COLOR,
  isOutlined: true,
  outLinedColor: ColorVariants.SECONDARY_FIRST,
};

export const DefaultPrimaryButtonValues = {
  shape: ButtonShape.ANGULAR,
  font: DefaultFontStyles.headlineSeventh,
  type: DefaultPrimaryButtonOptions,
};

export const DefaultSecondaryButtonValues = {
  shape: ButtonShape.ANGULAR,
  font: DefaultFontStyles.headlineSeventh,
  type: DefaultSecondaryButtonOptions,
};

export const defaultDesignSettingsValues: DesignSettingsDataT = {
  additionalColors: DefaultColors,
  icons: DefaultIcons,
  bulletIcons: DefaultBulletIcons,
  mediaFiles: [],
  headerConfig: {
    backgroundColor: ColorVariants.SECONDARY_SECOND,
    isBackgroundShadow: true,
    logo: null,
  },
  footerConfig: {
    backgroundColor: ColorVariants.SECONDARY_SECOND,
    isBackgroundShadow: true,
    isFooterOn: false,
  },
  primaryButton: DefaultPrimaryButtonValues,
  secondaryButton: DefaultSecondaryButtonValues,
};
