import { useTranslation } from 'react-i18next';
import { useProjectSearchDataColumn } from './useProjectSearchDataColumn';
import { ResponseOptionPayloadT } from '../../../lib/types';

import { Typography } from 'antd';
import { PROJECT_COLUMN } from '../helpers/constants';

const { Text } = Typography;

const usedTemplate = 'pages.projects.columns.usedTemplate';

export const useUsedTemplateColumn = () => {
  const { t } = useTranslation();

  return useProjectSearchDataColumn({
    title: t(usedTemplate),
    arrangedId: PROJECT_COLUMN.USED_TEMPLATE,
    filterFieldName: 'includeTemplateIds',
    isParsedByValue: true,
    columnProps: {
      width: 140,
      render: (value: ResponseOptionPayloadT) => {
        return <Text>{value.name}</Text>;
      },
    },
  });
};
