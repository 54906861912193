import React, { FC } from 'react';
import { ArithmeticValues } from '../../../types/entities';
import { AppSelect } from '../../../../../components/AppSelect';
import { UpdateRuleBuilderPayload } from '../../CustomRuleBuilder';

const arithmeticOptions = Object.values(ArithmeticValues)?.map((option) => ({
  value: option,
  title: option,
}));

type Props = {
  name: string;
  ruleId: string;
  handleChange: (values: UpdateRuleBuilderPayload) => void;
  disabled: boolean;
};

export const ArithmeticOperator: FC<Props> = ({ name, ruleId, handleChange, disabled }) => {
  return (
    <AppSelect
      disabled={disabled}
      onChange={(values: { value: string }) => handleChange({ value: values.value, ruleId })}
      options={arithmeticOptions}
      value={name}
    />
  );
};
