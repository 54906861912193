import { persistStore, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import { configureStore, AnyAction } from '@reduxjs/toolkit';
import { persistedReducer } from './slices/rootReducer';
import { AppLocalStorage } from '../utils/helpers';
import { logoutAction } from './actions';
import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from '../utils/constants';
import { api } from './api';
import { setFinderProducts } from './slices/endUserSlice';

const combinedReducer = (state: RootState | undefined, action: AnyAction) => {
  if (action.type === logoutAction.type) {
    state = undefined;
    AppLocalStorage.removeItem('persist:auth');
    AppLocalStorage.removeItem('persist:endUser');
    AppLocalStorage.removeItem('persist:root');
    AppLocalStorage.removeItem(ACCESS_TOKEN_NAME);
    AppLocalStorage.removeItem(REFRESH_TOKEN_NAME);
  }
  return persistedReducer(state, action);
};

export const store = configureStore({
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, setFinderProducts.type],
      },
    }).concat(api.middleware),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof persistedReducer>;
