import { FC, useMemo } from 'react';
import styles from './index.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { Modal } from 'antd';
import { ReactComponent as IconCup } from '../../../../assets/icons/cup-icon.svg';
import ConfettiImg from '../../../../assets/images/confetti.png';
import { turnOffCongratulation } from '../../../../lib/store/slices/endUserSlice';
import { useNavigate } from 'react-router';
import { combineBonusesFromStatusLevel } from '../../../../lib/utils/end-user-helpers';
import { useTranslation } from 'react-i18next';

const modal = 'endUser.congratulationModal';

type Props = {
  isOpen: boolean;
};

export const CongratulationModalWithBonuses: FC<Props> = ({ isOpen }) => {
  const { statusLevels, demandArea } = useAppSelector(({ endUser }) => endUser);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onClickBonus = () => {
    dispatch(turnOffCongratulation());
    navigate('/bonuses');
  };

  const handleCloseModal = () => {
    dispatch(turnOffCongratulation());
  };

  const displayedOptions = useMemo(() => {
    const statusLevelId = demandArea?.user?.statusLevelId;

    if (statusLevelId) {
      const foundStatusLevel = statusLevels?.find((item) => item?.id == statusLevelId);
      const combinedBonuses = combineBonusesFromStatusLevel(foundStatusLevel);

      return {
        statusLevelName: foundStatusLevel?.name,
        bonusesList: combinedBonuses,
      };
    }
  }, [demandArea?.user?.statusLevelId, statusLevels]);

  return (
    <Modal
      centered
      open={isOpen}
      width={360}
      onCancel={handleCloseModal}
      destroyOnClose={true}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { width: '100%' }, onClick: onClickBonus }}
      okText={t(`${modal}.okText`)}
      className={styles.modal}
    >
      <IconCup className={styles.iconCup} />
      <img className={styles.confettiImg} src={ConfettiImg} alt="confetti" />
      <EndUserTypography type="headlineSecond" component="Title" className={styles.title}>
        {t(`${modal}.title`)}
      </EndUserTypography>
      <EndUserTypography type="body" component="Text" className={styles.text}>
        {t(`${modal}.bonuses`, { statusLevelName: displayedOptions?.statusLevelName })}
      </EndUserTypography>

      <ul className={styles.bonusesList}>
        {displayedOptions?.bonusesList?.map((item) => (
          <li key={item?.id} className={styles.bonusListItem}>
            <img src={item?.picture?.s3Url} alt={item?.name} />
            <EndUserTypography type="body" component="Text">
              {item?.name}
            </EndUserTypography>
          </li>
        ))}
      </ul>
    </Modal>
  );
};
