import React, { FC } from 'react';
import { Category, DemandArea } from '../../../../lib/types/endUser';
import { CoreVisualSectionT } from '../../../../lib/types/sections';
import { useAppDispatch } from '../../../../lib/store/hooks';
import { useNavigate } from 'react-router-dom';
import { sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';
import { setCurrentCategory } from '../../../../lib/store/slices/endUserSlice';

import styles from './index.module.scss';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { FinderButton } from '../../../../components/FinderButton';
import { EndUserSquareTiles } from '../EndUserSquareTiles';

type Props = {
  demandAreas?: DemandArea[];
  content?: CoreVisualSectionT;
};

export const EndUserDemandAreaTilesDesktop: FC<Props> = ({ demandAreas, content }) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handleAreaClick = (areaId: number, name: string) => {
    sendDataToAnalytics(
      'Bedarfsbereich',
      `Aufruf Bedarfsbereich ${name}`,
      `User exploriert Produkte aus Bedarfsbereich ${name}`,
    );

    navigate(`/house-model/${areaId}`);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, areaId: number, name: string) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleAreaClick(areaId, name);
    }
  };

  const handleCategoryClick = (areaId: number, category: Category) => {
    setTimeout(() => dispatch(setCurrentCategory({ category })), 10);
  };

  return (
    <div
      className={styles.section}
      style={{
        background: `linear-gradient(89.55deg, rgba(0, 0, 0, 0.15) 3.66%, rgba(0, 0, 0, 0.09) 32.16%, rgba(0, 0, 0, 0.06) 46.71%, rgba(0, 0, 0, 0.006) 99.64%), url(${content?.backgroundImage?.s3Url})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left',
      }}
    >
      <div className={styles.headlineBlock}>
        <EndUserTypography type="headlineFirst" component="Title" className={styles.title}>
          {content?.headline1}
        </EndUserTypography>
        <EndUserTypography
          type="headlineThird"
          component="Title"
          style={{ color: '#fff' }}
          className={styles.subTitle}
        >
          {content?.headline2}
        </EndUserTypography>

        <FinderButton
          showFinderButton={content?.finderEnabled}
          finderButtonText={content?.finderButtonText}
          finderButtonUrl={content?.finderButtonLink}
          recommendForAllCategories={true}
        />
      </div>

      <div className={styles.mainContainer}>
        <EndUserSquareTiles
          demandAreas={demandAreas}
          handleAreaClick={handleAreaClick}
          handleKeyDown={handleKeyDown}
          handleCategoryClick={handleCategoryClick}
        />
      </div>
    </div>
  );
};
