import { FC } from 'react';
import { SetUpPasswordCard } from '../../modules/auth/components/SetUpPasswordCard';
import { AuthLayout } from '../../modules/auth/layouts/AuthLayout';

export const SetUpPasswordPage: FC = () => {
  return (
    <AuthLayout>
      <SetUpPasswordCard />
    </AuthLayout>
  );
};
