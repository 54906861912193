import { FormInstance } from 'antd';
import { LIST_VALUE_PROP_NAME, getParsedSelectedItems } from '../../../lib/utils/arranges-helpers';
import { ArrangeFormItem } from '../../../lib/models/Arrange';
import { useAppDispatch, useAppSelector } from '../../../lib/store/hooks';
import { TENANT_COLUMN } from '../helpers/constants';
import { TableSortOrder } from '../../../lib/utils/constants';
import {
  TenantFiltersPayload,
  setTenantTableFilters,
  setTenantTablePage,
} from '../../../lib/store/slices/tenantsSlice';
import { closeModalAfterApply } from '../../../features/table/helpers/closeModalAfterApply';
import { isArrayNotEmpty, isObjectEmpty } from '../../../lib/utils/helpers';

type Props = {
  arrangedId: string;
  form: FormInstance<any>;
  clearFilters?: any;
  setFilterLabel?: any;
  getFilters?: any;
  getFilterLabel?: any;
  filterFieldName?: string;
  isParsedByValue?: boolean;
  isNotArrayFilterValues?: boolean;
  withSorting?: boolean;
};
export const useTenantArrangeRequests = ({
  arrangedId,
  clearFilters,
  setFilterLabel,
  getFilters,
  getFilterLabel,
  form,
  filterFieldName,
  isParsedByValue = false,
  isNotArrayFilterValues = false,
}: Props) => {
  const dispatch = useAppDispatch();
  const sortingAndFiltersData = useAppSelector(({ tenants }) => tenants);

  const applyArranges = async () => {
    const sortOrder = form.getFieldValue(ArrangeFormItem.SORT_ORDER);
    const filters = getFilters?.();
    const parsedItems = getParsedSelectedItems(form);

    const searchItems = parsedItems.map((item: { value: string; label: string }) => {
      if (isParsedByValue) {
        return item.value;
      } else {
        return item.label;
      }
    });

    const resultedFilters = {
      arrangeId: arrangedId as TENANT_COLUMN,
      sorting: {
        sortOrder: sortOrder as TableSortOrder,
        sortFieldName: arrangedId,
        withSorting: true,
      },
      filtering: {
        filterFieldName,
        filter: isNotArrayFilterValues ? filters : searchItems,
      },
    } as TenantFiltersPayload;

    const isFilterChanged =
      isArrayNotEmpty(resultedFilters.filtering.filter) ||
      !isObjectEmpty(resultedFilters.filtering.filter as any);

    if (isFilterChanged) {
      dispatch(setTenantTablePage(1));
    }

    dispatch(setTenantTableFilters(resultedFilters));
    setFilterLabel?.(getFilterLabel);
    closeModalAfterApply(arrangedId);
  };

  const removeFilter = async () => {
    dispatch(
      setTenantTableFilters({
        arrangeId: arrangedId,
        sorting: { ...sortingAndFiltersData.sorting },
        filtering: { filterFieldName: '', filter: [] },
      } as TenantFiltersPayload),
    );

    setFilterLabel?.(null);
    clearFilters();
  };

  const removeFilterValue = async (value: any) => {
    const parsedItems = getParsedSelectedItems(form);
    const filteredItems = parsedItems.filter(
      (item: { [x: string]: any }) => item[LIST_VALUE_PROP_NAME] !== value,
    );

    const updatedFiltersData: TenantFiltersPayload = {
      ...sortingAndFiltersData,
      arrangedId: sortingAndFiltersData.arrangeId,
      filtering: {
        filterFieldName: sortingAndFiltersData.filtering.filterFieldName,
        filter: filteredItems.map((item: { value: string; label: string }) => {
          if (isParsedByValue) {
            return item.value;
          } else {
            return item.label;
          }
        }),
      },
    } as TenantFiltersPayload;

    dispatch(setTenantTableFilters(updatedFiltersData));

    const stringifiedItems = filteredItems.map(JSON.stringify);
    form.setFieldValue(ArrangeFormItem.SELECTED_ITEMS, stringifiedItems);

    const filterLabel = getFilterLabel();
    setFilterLabel?.(filterLabel);

    if (!filterLabel) {
      clearFilters();
    }
  };

  return { applyArranges, removeFilter, removeFilterValue };
};
