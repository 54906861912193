import { useEffect, useRef } from 'react';
import { FormInstance } from 'antd';

type Props = {
  form: FormInstance;
  isOpen: boolean;
};

export function useResetFormOnCloseModal({ form, isOpen }: Props) {
  const prevOpenRef = useRef<boolean | undefined>();

  useEffect(() => {
    prevOpenRef.current = isOpen;
  }, [isOpen]);

  const prevOpen = prevOpenRef.current;

  useEffect(() => {
    if (!isOpen && prevOpen) {
      form.resetFields();
    }
  }, [form, prevOpen, isOpen]);
}
