import { useTranslation } from 'react-i18next';
import { useProductCategoriesSearchDataColumn } from './useProductCategoriesSearchDataColumn';
import { PRODUCT_CATEGORIES } from '../helpers/constants';
import { Typography } from 'antd';

const description = 'pages.products.productCategories.columns.description';

export const useProductCategoriesDescriptionColumn = () => {
  const { t } = useTranslation();

  return useProductCategoriesSearchDataColumn({
    title: t(description),
    arrangedId: PRODUCT_CATEGORIES.DESCRIPTION,
    searchFieldName: 'searchByDescription',
    filterFieldName: 'includeDescriptions',
    columnProps: {
      width: 181,
      render: (description: string) => {
        return (
          <Typography.Text style={{ maxWidth: 150 }} ellipsis>
            {description}
          </Typography.Text>
        );
      },
    },
  });
};
