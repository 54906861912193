import { PROJECT_COLUMN } from '../helpers/constants';
import { useTranslation } from 'react-i18next';

import tableStyles from '../../../components/Table/index.module.scss';

import { useProjectSearchDataColumn } from './useProjectSearchDataColumn';

const comments = 'pages.projects.columns.comments';

export const useCommentColumn = () => {
  const { t } = useTranslation();

  return useProjectSearchDataColumn({
    title: t(comments),
    arrangedId: PROJECT_COLUMN.COMMENT,
    disableSorting: true,
    searchFieldName: 'searchByComment',
    filterFieldName: 'includeComments',
    headerCellProps: {
      rootProps: {
        className: tableStyles.hiddenHeaderCell,
      },
    },
    columnProps: {
      width: 327,
    },
  });
};
