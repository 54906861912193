import { FC } from 'react';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/cross-in-circle.svg';
import { useTranslation } from 'react-i18next';
import { FormItemName } from '../../../../lib/models/Form';
import { IconButton } from '../../../../components/IconButton';
import { FormSection } from '../../../../components/FormSection';

import styles from './index.module.scss';

const ageRange = 'pages.products.productConfigurator.formItems.ageRange';

type Props = {
  name: number;
  remove: (arg: number | number[]) => void;
};

export const AgeRangeRow: FC<Props> = ({ name, remove }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.space}>
      <FormSection
        className={styles.formSection}
        formItemProps={{
          hasFeedback: true,
          name: [name, FormItemName.FROM],
          dependencies: [FormItemName.AGE_RANGE, FormItemName.RANGES, name, FormItemName.TO],
          rules: [
            ({ getFieldValue }: { getFieldValue: any }) => ({
              validator(_: unknown, value: number) {
                const maxValue = getFieldValue([
                  FormItemName.AGE_RANGE,
                  FormItemName.RANGES,
                  name,
                  FormItemName.TO,
                ]);

                if (maxValue === undefined && value === undefined) {
                  return Promise.reject(new Error(t(`${ageRange}.errors.empty`)));
                }

                return Promise.resolve();
              },
            }),
          ],
          normalize: (value: string) => (value ? Number(value) : undefined),
        }}
        textFieldProps={{ type: 'number' }}
      />
      <span className={styles.label}>{t(`${ageRange}.to`)}</span>
      <FormSection
        className={styles.formSection}
        formItemProps={{
          hasFeedback: true,
          name: [name, FormItemName.TO],
          dependencies: [FormItemName.AGE_RANGE, FormItemName.RANGES, name, FormItemName.FROM],
          rules: [
            ({ getFieldValue }: { getFieldValue: any }) => ({
              validator(_: unknown, value: number) {
                const minValue = getFieldValue([
                  FormItemName.AGE_RANGE,
                  FormItemName.RANGES,
                  name,
                  FormItemName.FROM,
                ]);

                if (minValue === undefined && value === undefined) {
                  return Promise.reject(new Error(t(`${ageRange}.errors.empty`)));
                }

                return Promise.resolve();
              },
            }),
          ],
          normalize: (value: string) => (value ? Number(value) : undefined),
        }}
        textFieldProps={{ type: 'number' }}
      />

      <div className={styles.trashButton}>
        <IconButton onClick={() => remove(name)} icon={<TrashIcon className={styles.trashIcon} />} />
      </div>
    </div>
  );
};
