import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type ContainerQuerySliceT = {
  isMobile: boolean;
  isLargerMobile: boolean;
  isTablet: boolean;
  isOnlyTablet: boolean;
  isLargerTablet: boolean;
  isLargerSpecificTablet: boolean;
  isOnlySpecificTablet: boolean;
  isDesktop: boolean;
  isLargerDesktop: boolean;
  isLargeTablet: boolean;
  isSmallSpecificTablet: boolean;
  isMoreThanFullHd: boolean;
};

const initialState: ContainerQuerySliceT = {
  isMobile: false,
  isLargerMobile: false,
  isTablet: false,
  isOnlyTablet: false,
  isLargerTablet: false,
  isLargerSpecificTablet: false,
  isOnlySpecificTablet: false,
  isDesktop: false,
  isLargerDesktop: false,
  isLargeTablet: false,
  isSmallSpecificTablet: false,
  isMoreThanFullHd: false,
};

const containerQueriesSlice = createSlice({
  name: 'containerQueries',
  initialState,
  reducers: {
    setContainerQueries: (_state, action: PayloadAction<ContainerQuerySliceT>) => {
      return action.payload;
    },
  },
});

export const { setContainerQueries } = containerQueriesSlice.actions;

export const containerQueries = containerQueriesSlice.reducer;
