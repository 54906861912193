import styles from './index.module.scss';
import { IconComponent } from '../../../../components/IconComponent';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { Category } from '../../../../lib/types/endUser';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconCheck } from '../../../../assets/icons/checkmark-thin.svg';
import { ProductCategoryT } from '../../../../lib/types/entities';

const translation = 'endUser.shared';

type Props = {
  category: Category;
  onClickCategory: (category: Category) => void;
  index: number;
  hasUserProducts: boolean;
  selectedCategory: ProductCategoryT | null;
  demandAreaColor: { value: string };
  sortedCategories: Category[];
};

export const CategoryCard: FC<Props> = ({
  category,
  onClickCategory,
  index,
  hasUserProducts,
  selectedCategory,
  demandAreaColor,
  sortedCategories,
}) => {
  const { t } = useTranslation();

  const [isHovered, setIsHovered] = useState(false);
  const getCategoryCardStyle = (isHovered: boolean, demandAreaColor: string) =>
    isHovered
      ? {
          backgroundColor: `color-mix(in srgb, ${demandAreaColor}, #FFFFFF 80%)`,
        }
      : undefined;
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div
      role="presentation"
      onClick={() => onClickCategory(category)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      key={index}
      className={styles.categorieCard}
      style={{
        ...getCategoryCardStyle(isHovered, demandAreaColor.value),
        ...(sortedCategories.at(index)?.id === selectedCategory?.id
          ? { borderBottom: `3px solid ${demandAreaColor.value}` }
          : {}),
      }}
    >
      <div className={styles.textIconContainer}>
        <IconComponent className={styles.smallIcon} iconData={category?.icon} color={demandAreaColor.value} />

        <EndUserTypography ellipsis={{ rows: 2 }} component="Text" type="headlineSixth">
          {category.name}
        </EndUserTypography>
      </div>

      {hasUserProducts ? (
        <EndUserTypography
          component="Text"
          type="headlineSeventh"
          style={{
            background: demandAreaColor.value,
            color: '#fff',
          }}
          className={styles.categorieCardSuccessStatus}
        >
          <IconCheck style={{ width: 14, height: 12, stroke: '#ffffff ' }} />
          {t(`${translation}.covered`)}
        </EndUserTypography>
      ) : (
        <div className={styles.categorieCardStatus}>
          <EndUserTypography
            component="Text"
            type="headlineSeventh"
            style={{
              color: '#858899',
            }}
          >
            {t(`${translation}.uncovered`)}
          </EndUserTypography>
        </div>
      )}
    </div>
  );
};
