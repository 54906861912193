import { FC, ReactNode } from 'react';
import { Button as ButtonAntd, ButtonProps } from 'antd';

import styles from './index.module.scss';

type Props = {
  children: ReactNode;
} & ButtonProps;

export const Button: FC<Props> = ({ type = 'primary', children, ...rest }) => {
  return (
    <ButtonAntd type={type} className={styles.root} {...rest}>
      {children}
    </ButtonAntd>
  );
};
