import { FC } from 'react';
import { FormItemName } from '../../../../lib/models/Form';
import { FormSection } from '../../../../components/FormSection';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

import styles from './index.module.scss';

const designTexts = 'pages.designSettingsTab';
const navigation = 'pages.designSettingsTab.formItems.navigation';

const { Text } = Typography;

export const NavigationFormItems: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Text type="secondary" className={styles.text}>
        {t(`${designTexts}.navigation`)}
      </Text>
      <FormSection
        formItemProps={{
          hasFeedback: true,
          name: [FormItemName.NAVIGATION, FormItemName.NAVIGATION_LANDING],
          rules: [{ required: true }],
        }}
        textFieldProps={{
          label: t(`${navigation}.landing`),
        }}
      />
      <FormSection
        formItemProps={{
          hasFeedback: true,
          name: [FormItemName.NAVIGATION, FormItemName.NAVIGATION_DEMAND],
          rules: [{ required: true }],
        }}
        textFieldProps={{
          label: t(`${navigation}.demand`),
        }}
      />
      <FormSection
        formItemProps={{
          hasFeedback: true,
          name: [FormItemName.NAVIGATION, FormItemName.NAVIGATION_BONUSES],
          rules: [{ required: true }],
        }}
        textFieldProps={{
          label: t(`${navigation}.bonuses`),
        }}
      />
      <FormSection
        formItemProps={{
          hasFeedback: true,
          name: [FormItemName.NAVIGATION, FormItemName.NAVIGATION_WISHLIST],
          rules: [{ required: true }],
        }}
        textFieldProps={{
          label: t(`${navigation}.wishlist`),
        }}
      />
      <FormSection
        formItemProps={{
          hasFeedback: true,
          name: [FormItemName.NAVIGATION, FormItemName.NAVIGATION_POINT],
          rules: [{ required: true }],
        }}
        textFieldProps={{
          label: t(`${navigation}.point`),
        }}
      />
    </div>
  );
};
