import React, { FC } from 'react';
import { Button } from 'antd';
import styles from './index.module.scss';
import { ReactComponent as PlusIcon } from './../../../../assets/icons/PlusIcon.svg';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { setModalOpened } from '../../../../lib/store/slices/userManagementSlice';
import { ModalActions } from '../../../../lib/models/Modal';
import { checkIsUser } from '../../../../lib/utils/roles';

export const AddUserButton: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const userRole = useAppSelector(({ auth }) => auth.role);
  const isUser = checkIsUser(userRole);

  const onOpenModal = () => {
    dispatch(setModalOpened(ModalActions.ADD));
  };

  return (
    <>
      {!isUser && (
        <Button onClick={onOpenModal} className={styles.btnWrapper} type="primary">
          <PlusIcon fill="white" />
          <div>{t('pages.userManagement.addButton')}</div>
        </Button>
      )}
    </>
  );
};
