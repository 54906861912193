import { FC } from 'react';
import { Form, message } from 'antd';
import { useResetFormOnCloseModal } from '../../../../lib/hooks/useResetFormOnCloseModal';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../components/Modal';
import { ProductCategoriesForm } from './ProductCategoriesForm';
import { useCreateProductCategoryMutation } from '../../../../lib/store/api/productCategories';
import { useParams } from 'react-router-dom';
import { ProductCategoryPayloadDataT } from '../../../../lib/types/productCategories';
import { EXCEPTION_CODE } from '../../../../lib/utils/constants';

const title = 'pages.products.productCategories.addModal.title';
const success = 'pages.products.productCategories.addModal.success';
const error = 'pages.products.productCategories.addModal.error';
const name = 'pages.products.productCategories.errors.name';

type Props = {
  isOpen: boolean;
  close: () => void;
};

export const ProductCategoriesCreateModal: FC<Props> = ({ close, isOpen }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const { id: tenantId, modelId: projectId } = useParams();

  const [createProductCategory, { isLoading }] = useCreateProductCategoryMutation();

  const onSubmit = async (data: Omit<ProductCategoryPayloadDataT, 'projectId'>) => {
    try {
      await createProductCategory({
        data: { ...data, projectId: Number(projectId) },
        tenantId: tenantId as string,
      }).unwrap();
      messageApi.success(t(success));
    } catch (err: any) {
      const isSameNameError = err?.data?.exceptionCode === EXCEPTION_CODE.ERROR_007;
      messageApi.error(isSameNameError ? t(name) : t(error));
    }
  };

  useResetFormOnCloseModal({ form, isOpen });

  return (
    <>
      {contextHolder}
      <Modal
        open={isOpen}
        titleProps={{ title: t(title) }}
        cancelButtonProps={{ onClick: close }}
        actionButtonProps={{ onClick: form.submit, loading: isLoading }}
      >
        {isOpen && <ProductCategoriesForm form={form} onSubmit={onSubmit} />}
      </Modal>
    </>
  );
};
