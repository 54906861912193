import { FC } from 'react';
import { Card, Form, FormInstance, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemTitle } from '../FormItemTitle';
import { FormItemName } from '../../../../lib/models/Form';
import { FormItem } from '../../../../components/FormItem';
import { FormSection } from '../../../../components/FormSection';
import { ScoringRules } from '../../../../lib/types/product';
import { AppSegmented } from '../../../../components/AppSegmented';
import { FormCardButton } from '../../../designSettings/components/FormCardButton';
import { CoverageAmountRow } from '../CoverageAmountRow';
import { DEFAULT_RECURRING_POINTS } from '../../helpers';

import styles from './index.module.scss';

const firstPoint = 'pages.products.productConfigurator.formItems.firstPoint';
const { Text } = Typography;

type Props = {
  form: FormInstance;
};

export const PointsCard: FC<Props> = ({ form }) => {
  const { t } = useTranslation();

  const scoringRule = Form.useWatch(FormItemName.SCORING_RANGES, form);
  const rulesLength = Form.useWatch(FormItemName.RECURRING_POINTS, form)?.length;

  const options = [
    {
      label: t(`${firstPoint}.option1`),
      value: ScoringRules.ONE_TIME,
    },
    {
      label: t(`${firstPoint}.option2`),
      value: ScoringRules.RECURRING,
    },
  ];

  const handleChangeRuleType = (value: string | number) => {
    if (value === ScoringRules.RECURRING) {
      form.setFieldValue(FormItemName.RECURRING_POINTS, [DEFAULT_RECURRING_POINTS]);
      form.setFieldValue(FormItemName.POINTS, undefined);
    } else {
      form.setFieldValue(FormItemName.RECURRING_POINTS, undefined);
    }
  };

  return (
    <Card className={styles.root}>
      <div className={styles.header}>
        <FormItemTitle>{t(`${firstPoint}.title`)}</FormItemTitle>
        <FormItem
          hasFeedback
          name={FormItemName.SCORING_RANGES}
          className={styles.radioGroup}
          initialValue={ScoringRules.ONE_TIME}
        >
          <AppSegmented options={options} onChange={handleChangeRuleType} />
        </FormItem>
      </div>

      {scoringRule === ScoringRules.ONE_TIME ? (
        <>
          <Text type="secondary" className={styles.text}>
            {t(`${firstPoint}.subline1`)}
          </Text>
          <FormSection
            formItemProps={{
              hasFeedback: true,
              name: FormItemName.POINTS,
              rules: [{ required: true, message: t(`${firstPoint}.errors.points`) }],
              normalize: (value: string) => (value ? Number(value) : undefined),
            }}
            textFieldProps={{
              placeholder: t(`${firstPoint}.label`),
              type: 'number',
            }}
            className={styles.formSection}
          />
          <FormCardButton disabled>+ {t(`${firstPoint}.addAmount`)}</FormCardButton>
        </>
      ) : (
        <>
          <div className={styles.titles}>
            <Text type="secondary" className={styles.text}>
              {t(`${firstPoint}.operator`)}
            </Text>
            <Text type="secondary" className={styles.text}>
              {t(`${firstPoint}.coverageAmount`)}
            </Text>
            <Text type="secondary" className={styles.text}>
              {t(`${firstPoint}.subline1`)}
            </Text>
          </div>
          <Form.List name={FormItemName.RECURRING_POINTS}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <CoverageAmountRow key={field.key} name={field.name} remove={remove} index={index} />
                ))}
                <FormCardButton disabled={rulesLength >= 10} onClick={() => add()}>
                  + {t(`${firstPoint}.addAmount`)}
                </FormCardButton>
              </>
            )}
          </Form.List>
        </>
      )}
    </Card>
  );
};
