import { ProductCategoriesSearchDataHeaderCell } from '../components/ProductCategoriesSearchDataHeaderCell';

export const useProductCategoriesSearchDataColumn = (props: any) => {
  const { columnProps, ...rest } = props;

  return {
    ...columnProps,
    dataIndex: props.arrangedId,
    title: <ProductCategoriesSearchDataHeaderCell {...rest} />,
  };
};
