import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type userManagementSliceStateT = {
  isBrandKitSubmitted: boolean;
  isDesignSettingsSubmitted: boolean;
};

const initialState: userManagementSliceStateT = {
  isBrandKitSubmitted: false,
  isDesignSettingsSubmitted: false,
};

const designSettingsSlice = createSlice({
  name: 'designSettings',
  initialState,
  reducers: {
    setBrandKitSubmitted: (state, action: PayloadAction<boolean>) => {
      state.isBrandKitSubmitted = action.payload;
    },
    setDesignSettingsSubmitted: (state, action: PayloadAction<boolean>) => {
      state.isDesignSettingsSubmitted = action.payload;
    },
  },
});

export const { setBrandKitSubmitted, setDesignSettingsSubmitted } = designSettingsSlice.actions;
export const designSettings = designSettingsSlice.reducer;
