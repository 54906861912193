import { ColumnProps } from 'antd/es/table';
import { StatusHeaderCell } from '../components/StatusHeaderCell';

type Props = {
  arrangedId: string;
  columnProps: ColumnProps<unknown>;
  disableSorting?: boolean;
  [x: string]: unknown;
};

export const useStatusColumn = (props: Props) => {
  const { columnProps, arrangedId, ...rest } = props;

  return {
    dataIndex: arrangedId,
    title: <StatusHeaderCell {...rest} arrangedId={arrangedId} />,
    ...columnProps,
  };
};
