import { FC } from 'react';
import { FormItem } from '../../../../components/FormItem';
import { useTranslation } from 'react-i18next';
import { FormItemName } from '../../../../lib/models/Form';
import { AppSelect } from '../../../../components/AppSelect';
import { SelectionType } from '../../../../lib/types/entities';

const options = [
  {
    value: SelectionType.SINGLE,
    title: 'Single',
  },
  {
    value: SelectionType.MULTIPLE,
    title: 'Multiple',
  },
];

const selectionType = 'pages.products.productCategories.formItems.selectionType';

export const SelectionTypeFormItem: FC = () => {
  const { t } = useTranslation();

  return (
    <FormItem
      hasFeedback
      name={FormItemName.SELECTION_TYPE}
      rules={[{ required: true, message: t(`${selectionType}.error`) }]}
    >
      <AppSelect options={options} label={t(`${selectionType}.label`)} />
    </FormItem>
  );
};
