import { FC, useRef } from 'react';
import { ProductRecommendationCard } from '../ProductRecommendationCard';
import { getProductSize } from '../../helpers';
import { useMediaQuery } from 'react-responsive';
import { TabletOrDesktopProductRecommendations } from '../TabletOrDesktopProductRecommendations';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { useGetProductRecommendationSection } from '../../hooks/useGetProductRecommendationSection';
import { useAppSelector } from '../../../../lib/store/hooks';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';

import styles from './index.module.scss';
import { useTextLineCount } from '../../../../lib/hooks/endUserHooks/useTextLineCount';

export const ProductRecommendationList: FC = () => {
  const { brandKit, demandArea, isGuestMode } = useAppSelector(({ endUser }) => endUser);

  const { productRecommendationSection } = useGetProductRecommendationSection();
  const hiddenContainerRef = useRef<HTMLDivElement | null>(null);

  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const { isOnlySpecificTablet, isLargerSpecificTablet } = useContainerQuery();

  const productSize = getProductSize({
    isTablet: isOnlySpecificTablet,
    productsLength: productRecommendationSection.products.filter((item) => item.product !== null).length,
    isLandscape: !isPortrait,
    isDesktopOrLaptop: isLargerSpecificTablet,
  });

  const genericProps = {
    productSize,
    pointsIcon: demandArea?.project.pointsIcon,
    brandKit,
  };

  const productNameLineCount = useTextLineCount(
    productRecommendationSection.products.map((product) => product.product?.name),
    195,
    'headlineFourth',
    hiddenContainerRef,
  );

  if (!productRecommendationSection?.headline1) {
    return null;
    // maybe need more reliable check instead of headline1
  }

  return (
    <div className={styles.container}>
      <div
        id="hiddenContainer"
        ref={hiddenContainerRef}
        style={{
          visibility: 'hidden',
          position: 'absolute',
          top: '0',
          left: '0',
        }}
      />
      <div className={styles.header}>
        <EndUserTypography className={styles.title} type="headlineSecond" component="Title" level={2}>
          {productRecommendationSection?.headline1}
        </EndUserTypography>

        <EndUserTypography className={styles.subTitle} type="headlineThird" component="Title" level={3}>
          {productRecommendationSection?.headline2}
        </EndUserTypography>
      </div>

      {isOnlySpecificTablet || !isPortrait ? (
        <TabletOrDesktopProductRecommendations
          {...genericProps}
          isDesktopOrLaptop={isLargerSpecificTablet}
          arePointsShown={productRecommendationSection.arePointsShown}
          products={productRecommendationSection.products}
          productSize={productSize}
          isLandscape={!isPortrait}
          isTablet={isOnlySpecificTablet}
          isGuestMode={isGuestMode}
          productNameLineCount={productNameLineCount}
        />
      ) : (
        <div className={styles.list}>
          {productRecommendationSection.products.map((itemProduct) => (
            <ProductRecommendationCard
              {...itemProduct}
              {...genericProps}
              arePointsShown={productRecommendationSection.arePointsShown}
              key={itemProduct.ruleId}
              isGuestMode={isGuestMode}
            />
          ))}
        </div>
      )}
    </div>
  );
};
