import { FC } from 'react';
import { useAppSelector, useAppDispatch } from '../../../../lib/store/hooks';
import { ModalActions } from '../../../../lib/models/Modal';
import { setModalClosed } from '../../../../lib/store/slices/productsSlice';
import { DeleteProductModal } from './DeleteProductModal';
import { MessageInstance } from 'antd/es/message/interface';

type Props = {
  messageApi: MessageInstance;
};
export const ProductModals: FC<Props> = ({ messageApi }) => {
  const {
    isModalOpen,
    modalType: currentModalType,
    selectedProduct,
  } = useAppSelector(({ products }) => products);
  const dispatch = useAppDispatch();

  const close = () => dispatch(setModalClosed());
  const checkIsOpen = (modalType: ModalActions | null) => isModalOpen && modalType === currentModalType;

  return (
    <>
      <DeleteProductModal
        isOpen={checkIsOpen(ModalActions.DELETE)}
        close={close}
        selectedProduct={selectedProduct}
        messageApi={messageApi}
      />
    </>
  );
};
