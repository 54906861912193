import { FC, useEffect, useState } from 'react';
import { ProductBadgeListItem } from '../ProductBadgeListItem';
import { Form, message } from 'antd';
import { Button } from '../../../../components/Button';
import { useTranslation } from 'react-i18next';
import { FormCardButton } from '../../../designSettings/components/FormCardButton';
import { BadgeFormItem } from '../../helpers';
import { useGetBadgesQuery, useUpdateBadgesMutation } from '../../../../lib/store/api/badge';
import { BadgeT } from '../../../../lib/types/badge';
import { ColorVariants } from '../../../../lib/types/colors&fonts';
import { PageLoader } from '../../../../components/PageLoader';

import styles from './index.module.scss';

type Props = {
  tenantId: string;
  modelId: string;
};

export const ProductBadgesList: FC<Props> = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isTouched, setIsTouched] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const { data, isLoading } = useGetBadgesQuery(props, {
    refetchOnMountOrArgChange: true,
  });

  const [updateBadges] = useUpdateBadgesMutation();

  useEffect(() => {
    if (data?.content?.length) {
      form.setFieldsValue({
        [BadgeFormItem.BADGES]: data?.content,
      });
    } else {
      form.setFieldsValue({
        [BadgeFormItem.BADGES]: [
          {
            [BadgeFormItem.NAME]: '',
            [BadgeFormItem.BACKGROUND_COLOR]: ColorVariants.PRIMARY_COLOR,
            [BadgeFormItem.FONT_COLOR]: ColorVariants.PRIMARY_COLOR,
            [BadgeFormItem.PROJECT_ID]: Number(props.modelId),
          },
        ],
      });
      setIsTouched(true);
    }
  }, [data?.content, form, props.modelId]);

  const handleSubmit = async ({ badges }: { badges: BadgeT[] }) => {
    try {
      await updateBadges({ tenantId: props?.tenantId, badges }).unwrap();
      messageApi.success(t('pages.products.badges.messages.updatingSuccess'));
    } catch (error) {
      messageApi.error(t('pages.products.badges.messages.updatingError'));
    }
  };

  if (isLoading) {
    return (
      <div style={{ flex: 1 }}>
        <PageLoader />
      </div>
    );
  }

  return (
    <Form
      onFinish={handleSubmit}
      className={styles.root}
      form={form}
      onFieldsChange={() => {
        setIsTouched(true);
      }}
    >
      {contextHolder}
      <Button htmlType="submit" disabled={!isTouched} className={styles.btn} size="small">
        {t('generic.save')}
      </Button>

      <Form.List name={BadgeFormItem.BADGES}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <ProductBadgeListItem
                key={field.key}
                name={field.name}
                remove={remove}
                projectId={Number(props.modelId)}
                messageApi={messageApi}
              />
            ))}
            <FormCardButton onClick={() => add()}>+ {t('pages.products.badges.addBadge')}</FormCardButton>
          </>
        )}
      </Form.List>
    </Form>
  );
};
