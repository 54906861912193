import { useTranslation } from 'react-i18next';
import { PRODUCT_CATEGORIES } from '../helpers/constants';
import { TableHeaderCell } from '../../../components/Table/TableHeaderCell/TableHeaderCell';
import { Typography } from 'antd';
import tableStyles from '../../../components/Table/index.module.scss';
import classNames from 'classnames';

const priorityInDemandArea = 'pages.products.productCategories.columns.priorityInDemandArea';

export const useProductCategoriesPriorityColumn = () => {
  const { t } = useTranslation();

  return {
    dataIndex: PRODUCT_CATEGORIES.PRIORITY_IN_DEMAND_AREA,
    title: <TableHeaderCell withArrange={false} title={t(priorityInDemandArea)} />,
    width: 155,
    render: (priority: { number: number }) => {
      return <Typography.Text>{priority?.number}</Typography.Text>;
    },
    className: classNames(tableStyles.allowWrap, tableStyles.priorityInDemandArea),
  };
};
