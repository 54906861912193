import { FC, useEffect } from 'react';
import { Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { setModalClosed } from '../../../../lib/store/slices/ruleBuilder';
import { useCreateRuleTypeMutation, useUpdateRuleTypeMutation } from '../../../../lib/store/api/ruleBuilder';
import { Modal } from '../../../../components/Modal';
import { useResetFormOnCloseModal } from '../../../../lib/hooks/useResetFormOnCloseModal';
import { FormItemName } from '../../../../lib/models/Form';
import { FormItem } from '../../../../components/FormItem';
import { FormInput } from '../../../../components/FormInput';
import { CreateRuleTypeBodyT, EditRuleTypeBodyT } from '../../../../lib/types/ruleBuilder';
import { ModalActions } from '../../../../lib/models/Modal';

import styles from './index.module.scss';

const ruleTypeModal = 'pages.ruleBuilder.ruleTypeModal';
const saveText = 'generic.save';

type Props = {
  tenantId: string;
  modelId: string;
};

export const RuleTypeModal: FC<Props> = ({ tenantId, modelId }) => {
  const { t } = useTranslation();
  const { isModalOpen, selectedRuleType, modalType } = useAppSelector(({ ruleBuilder }) => ruleBuilder);
  const dispatch = useAppDispatch();
  const isOpen = isModalOpen && (modalType === ModalActions.ADD || modalType === ModalActions.EDIT);
  const [form] = Form.useForm();
  const name = Form.useWatch(FormItemName.NAME, form);
  const [messageApi, contextHolder] = message.useMessage();

  const [createRuleType, { isLoading: isCreateLoading }] = useCreateRuleTypeMutation();
  const [updateRuleType, { isLoading: isUpdateLoading }] = useUpdateRuleTypeMutation();

  useResetFormOnCloseModal({ form, isOpen: isOpen });

  const onFinish = async (values: CreateRuleTypeBodyT | EditRuleTypeBodyT) => {
    const body = { ...values, projectId: Number(modelId) };

    try {
      if (selectedRuleType) {
        await updateRuleType({ body: { ...body, id: selectedRuleType?.id }, tenantId }).unwrap();
        messageApi.success(t(`${ruleTypeModal}.success.editRuleType`));
      } else {
        await createRuleType({ body, tenantId }).unwrap();
        messageApi.success(t(`${ruleTypeModal}.success.createRuleType`));
      }
      onClose();
    } catch (err: any) {
      messageApi.error(err?.data?.message);
    }
  };

  const onClose = () => {
    dispatch(setModalClosed());
  };

  useEffect(() => {
    if (selectedRuleType) {
      form.setFieldValue(FormItemName.NAME, selectedRuleType.name);
    }
  }, [form, selectedRuleType]);

  return (
    <>
      {contextHolder}
      <Modal
        open={isOpen}
        okText={t(saveText)}
        titleProps={{
          title: t(selectedRuleType ? `${ruleTypeModal}.editTitle` : `${ruleTypeModal}.createTitle`),
        }}
        cancelButtonProps={{ onClick: onClose }}
        actionButtonProps={{
          onClick: form.submit,
          loading: isCreateLoading || isUpdateLoading,
          disabled: !name,
        }}
        width={528}
      >
        {isOpen && (
          <Form form={form} onFinish={onFinish}>
            <FormItem
              hasFeedback={true}
              name={FormItemName.NAME}
              rules={[{ max: 30 }]}
              className={styles.formItem}
            >
              <FormInput label={t(`${ruleTypeModal}.formItem`)} />
            </FormItem>
          </Form>
        )}
      </Modal>
    </>
  );
};
