import React, { useEffect, useState, FC } from 'react';
import { Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { CategoriesCards } from '../CategoriesCards';
import { ROUTER_PATHS } from '../../../../lib/utils/router-paths';
import styles from './index.module.scss';
import { sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';
import { Category, DemandArea } from '../../../../lib/types/endUser';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { setCurrentCategory } from '../../../../lib/store/slices/endUserSlice';
import { parseJSON, sortCategoriesByPriority } from '../../../../lib/utils/helpers';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import {
  checkBoughtProductsInCategory,
  getFirstUncoveredCategory,
} from '../../../../lib/utils/end-user-helpers';

type SquareShadowHouseProps = {
  handleTabClick: (index: number) => void;
  activeTab?: number;
  currentDemandArea: any;
  demandAreas: DemandArea[];
  onClickCategory: (category: Category) => void;
  applyNewDesign?: boolean;
};

type Props = {
  demandAreas?: any;
  applyNewDesign?: boolean;
  ComponentProp?: React.ComponentType<SquareShadowHouseProps>;
};

export const EndUserDemandAreaDesktop: FC<Props> = ({ demandAreas, ComponentProp, applyNewDesign }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { tenantAndProject } = useAppSelector(({ endUser }) => endUser);

  const dispatch = useAppDispatch();
  const filteredDemandArea = demandAreas?.find((area: DemandArea) => Number(area.id) === Number(id));

  const [activeTab, setActiveTab] = useState<number | undefined>(() =>
    filteredDemandArea ? demandAreas?.indexOf(filteredDemandArea) : 0,
  );
  const [currentDemandArea, setCurrentDemandArea] = useState(filteredDemandArea);

  useEffect(() => {
    const index = demandAreas?.indexOf(filteredDemandArea);
    if (id) {
      setActiveTab(index);
      setCurrentDemandArea(demandAreas?.[index]);
    } else {
      navigate(`/${ROUTER_PATHS.houseModel}/${demandAreas?.[0]?.id}`);
    }
  }, [demandAreas, filteredDemandArea, id, navigate]);

  useEffect(() => {
    setCurrentDemandArea(filteredDemandArea);
  }, [filteredDemandArea, demandAreas]);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
    setCurrentDemandArea(demandAreas?.[index]);

    const sortedCategories = sortCategoriesByPriority(demandAreas?.[Number(index)].categories || []);

    let category: Category | undefined = sortedCategories[0];

    //hardcode below
    if (tenantAndProject?.subDomain === 'energie-schwaben') {
      if (demandAreas?.[Number(index)].name === 'Energie') {
        const gasCategory = sortedCategories.find((category) => category.name === 'Gas');
        const stromCategory = sortedCategories.find((category) => category.name === 'Strom');

        const isGasCovered = gasCategory && checkBoughtProductsInCategory(gasCategory);
        const isStromCovered = stromCategory && checkBoughtProductsInCategory(stromCategory);

        if (isGasCovered && !isStromCovered) {
          category = stromCategory;
        } else if (isStromCovered && !isGasCovered) {
          category = gasCategory;
        } else if (isGasCovered && isStromCovered) {
          category = gasCategory;
        } else {
          category = gasCategory;
        }
      }
    }

    dispatch(setCurrentCategory({ category }));

    const newId = demandAreas?.[index]?.id;
    if (newId) {
      navigate(`/${ROUTER_PATHS.houseModel}/${newId}`);
    }
  };

  const handleTabChange = (index: string) => {
    setActiveTab(Number(index));
    setCurrentDemandArea(demandAreas?.[Number(index)]);
    const sortedCategories = sortCategoriesByPriority(demandAreas?.[Number(index)].categories || []);

    let category: Category | undefined = sortedCategories[0];

    //hardcode below
    if (tenantAndProject?.subDomain === 'energie-schwaben') {
      if (demandAreas?.[Number(index)].name === 'Energie') {
        const gasCategory = sortedCategories.find((category) => category.name === 'Gas');
        const stromCategory = sortedCategories.find((category) => category.name === 'Strom');

        const isGasCovered = gasCategory && checkBoughtProductsInCategory(gasCategory);
        const isStromCovered = stromCategory && checkBoughtProductsInCategory(stromCategory);

        if (isGasCovered && !isStromCovered) {
          category = stromCategory;
        } else if (isStromCovered && !isGasCovered) {
          category = gasCategory;
        } else if (isGasCovered && isStromCovered) {
          category = gasCategory;
        } else {
          category = gasCategory;
        }
      }
    }

    dispatch(setCurrentCategory({ category }));

    const newId = demandAreas?.[Number(index)]?.id;
    if (newId) {
      navigate(`/${ROUTER_PATHS.houseModel}/${newId}`);
    }
  };

  const areaColor = parseJSON(currentDemandArea?.color);

  const onClickCategory = (category: Category) => {
    const demandArea = demandAreas.find((area: Category) => area.id === category.demandAreaId);

    sendDataToAnalytics(
      'Aufruf Produktkategorie',
      `Aufruf Produktkategorie im Bedarfsfeld ${demandArea?.name} `,
      `User exploriert die Produktkategorie ${category.name}`,
    );

    dispatch(setCurrentCategory({ category }));

    navigate(`/${ROUTER_PATHS.houseModel}/${demandArea.id}`);
  };

  return (
    <>
      <div
        className={styles.tabs}
        style={{
          ['--dynamic-color' as string]: areaColor?.value,
        }}
      >
        <Tabs
          activeKey={String(activeTab) as string}
          onChange={handleTabChange}
          items={demandAreas?.map((area: any, i: any) => {
            return {
              label: (
                <EndUserTypography
                  type="headlineFifth"
                  component="Text"
                  style={{
                    color: activeTab === i ? areaColor?.value : '#444',
                    fontWeight: activeTab === i ? 700 : 400,
                  }}
                >
                  {area.name}
                </EndUserTypography>
              ),
              key: String(i),
            };
          })}
        />
      </div>
      {currentDemandArea && ComponentProp && (
        <ComponentProp
          demandAreas={demandAreas}
          handleTabClick={handleTabClick}
          activeTab={activeTab}
          currentDemandArea={currentDemandArea}
          onClickCategory={onClickCategory}
          applyNewDesign={applyNewDesign}
        />
      )}
      {currentDemandArea && (
        <CategoriesCards currentDemandArea={currentDemandArea} onClickCategory={onClickCategory} />
      )}
    </>
  );
};
