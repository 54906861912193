import { useTranslation } from 'react-i18next';
import { TENANT_COLUMN } from '../helpers/constants';
import { useTenantSearchDataColumn } from './useTenantSearchDataColumn';
import { Typography } from 'antd';

import tableStyles from '../../../components/Table/index.module.scss';

const { Text } = Typography;

const genericCommentColumnProps = {
  width: 200,
  render: (text: string) => <Text>{text ?? '-'}</Text>,
};

const address = 'pages.tenants.columns.address';

export const useTenantAddressColumn = () => {
  const { t } = useTranslation();

  return useTenantSearchDataColumn({
    title: t(address),
    arrangedId: TENANT_COLUMN.ADDRESS,
    searchFieldName: 'searchByClientAddress',
    filterFieldName: 'includeClientAddresses',
    columnProps: genericCommentColumnProps,
    headerCellProps: {
      rootProps: { className: tableStyles.hiddenHeaderCell },
    },
  });
};
