import { useTranslation } from 'react-i18next';
import { User } from '../../../helpers/constants';
import { useUserDateColumn } from '../useUserDateColumn';

const creationDate = 'shared.columns.creationDate';

export function useUserCreationDateColumn() {
  const { t } = useTranslation();

  return useUserDateColumn({
    arrangedId: User.CREATED_DATE,
    title: t(creationDate),
    filterFromName: 'createdDateFrom',
    filterToName: 'createdDateTo',
  });
}
