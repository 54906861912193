export enum SectionName {
  HOUSE_MODEL = 'Core Visual',
  PRODUCT_DETAILS = 'Product Details',
  BENEFITS = 'Benefits',
  STATUS = 'Status Bar',
  STATUS_SMALL = 'Status Bar Small',
  CATEGORY_OVERVIEW = 'Category Overview',
  PRODUCT_RECOMMENDATION = 'Product Recommendation',
  FRIENDS = 'Friends',
  REGIONAL_OFFERS = 'Regional Offers',
  BONUS_PAGE = 'Bonus Page',
  POINTS_OVERVIEW = 'Points Overview',
  WISHLIST = 'Wishlist',
  SUCCESS_MODAL = 'Success Modal',
  LOGIN_PAGE = 'Login Page',
  AGREEMENTS = 'Agreements',
  IRRELEVANT_SURVEY = 'Irrelevant Survey',
  CONTACT_FORM = 'Contact Form',
}
