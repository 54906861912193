import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { PRODUCT_CATEGORIES } from '../helpers/constants';

import {
  createOptionItem,
  getParsedSelectedItems,
  getSelectedItemsLabels,
  getSelectedItemsValues,
} from '../../../lib/utils/arranges-helpers';
import { useState } from 'react';
import { PRODUCT_CATEGORIES_STATUS } from '../../projects/types';
import { useEnumArrange } from '../../tenants/hooks/useEnumArrange';
import { useStatusColumn } from '../../../features/table/helpers/useStatusColumn';
import { useAppSelector } from '../../../lib/store/hooks';
import { useProductCategoriesArrangeRequest } from './useProductCategoriesArrangeRequest';
import { ProductCategoriesStatusBodyCell } from '../components/ProductCategoriesStatusBodyCell';
import styles from '../../../components/Table/index.module.scss';

const filteringT = 'pages.products.productCategories.filtering';

export const useProductCategoriesStatusColumn = () => {
  const { t } = useTranslation();

  const { sorting, arrangeId } = useAppSelector(({ productCategories }) => productCategories);

  const [form] = Form.useForm();
  const isActiveArrange = arrangeId === PRODUCT_CATEGORIES.IS_ACTIVE;

  const getItemsLabels = () => getSelectedItemsLabels(form);
  const getItemsValues = () => getSelectedItemsValues(form);

  const [filterLabel, setFilterLabel] = useState('');

  const activatedLabel = t(`${filteringT}.activated`);
  const deactivatedLabel = t(`${filteringT}.deactivated`);

  const allOptions = [
    createOptionItem(activatedLabel, PRODUCT_CATEGORIES_STATUS.ACTIVATED),
    createOptionItem(deactivatedLabel, PRODUCT_CATEGORIES_STATUS.DEACTIVATED),
  ];

  const { filtering } = useEnumArrange(allOptions);

  const { applyArranges, removeFilter, removeFilterValue } = useProductCategoriesArrangeRequest({
    arrangedId: PRODUCT_CATEGORIES.IS_ACTIVE,
    getFilterLabel: () => getItemsLabels().join(', '),
    getFilters: () => ({ statuses: getItemsValues() }),
    form,
    setFilterLabel,
    filterFieldName: 'activated',
    isParsedByValue: true,
  });

  return useStatusColumn({
    filtering,
    applyArranges,
    removeFilter,
    arrangedId: PRODUCT_CATEGORIES.IS_ACTIVE,
    isActiveArrange,
    form,
    filterLabel,
    sortOrder: sorting.sortOrder,
    getParsedItems: () => getParsedSelectedItems(form),
    removeFilterValue,
    columnProps: {
      render: (isActive: boolean) => {
        return <ProductCategoriesStatusBodyCell status={isActive} />;
      },
      width: 150,
      className: styles.borderRightLight,
    },
  });
};
