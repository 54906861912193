import { Form } from 'antd';
import { FC } from 'react';
import { useTenantArrangeRequests } from '../../hooks/useTenantArrangeRequests';
import { useArrangeSubmit } from '../../../../features/table/helpers/useArrangeSubmit';
import { TableHeaderCell } from '../../../../components/Table/TableHeaderCell/TableHeaderCell';
import { getArrangesInitialValues } from '../../../../lib/utils/arranges-helpers';
import { SortPopup } from '../../../../features/table/components/popups/SortPopup';
import { useAppSelector } from '../../../../lib/store/hooks';

type Props = {
  title: string;
  arrangedId: string;
  headerCellProps: any;
};

export const TenantNumberHeaderCell: FC<Props> = ({
  title,
  arrangedId,
  headerCellProps: additionalHeaderCellProps,
}) => {
  const { arrangeId, sorting } = useAppSelector(({ tenants }) => tenants);
  const isActiveArrange = arrangeId === arrangedId;
  const [form] = Form.useForm();

  const { applyArranges } = useTenantArrangeRequests({
    arrangedId,
    form,
  });

  const headerCellProps = {
    title,
    sortOrder: sorting.sortOrder,
    isActiveArrange,
    form,
    applyArranges,
    ...additionalHeaderCellProps,
  };

  const { onFinish } = useArrangeSubmit(applyArranges);

  return (
    <TableHeaderCell {...headerCellProps}>
      <SortPopup
        initialValues={getArrangesInitialValues(form)}
        form={form}
        onFinish={onFinish}
        isActiveArrange={isActiveArrange}
      />
    </TableHeaderCell>
  );
};
