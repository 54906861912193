import { TableHeaderCell } from '../../../components/Table/TableHeaderCell/TableHeaderCell';
import { ActivatedStatusBodyCell } from '../../../features/table/components/ActivatedStatusBodyCell';

export const useRuleStatusColumn = () => {
  return {
    title: <TableHeaderCell withArrange={false} title="Status" />,
    width: 134,
    render: (row: { activated: boolean }) => {
      return <ActivatedStatusBodyCell activatedStatus={Boolean(row?.activated)} />;
    },
  };
};
