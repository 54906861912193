import React, {FC} from 'react';

type Props = {
    fill: string
};

export const TopRightElement: FC<Props> = ({ fill  = "#FFF6E8"}) => {
    return (
        <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                id="Intersect"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M48.0704 44.6888V34.5279C48.0704 30.221 45.7624 26.2445 42.0232 24.1082L4.02316 2.40043C2.77965 1.69006 1.44076 1.21919 0.0708008 0.987793V44.6231C0.0708008 47.0404 1.86166 49.0001 4.0708 49.0001H44.0708C46.2599 49.0001 48.0382 47.0759 48.0704 44.6888Z"
                fill={fill}/>
        </svg>
    );
};

