import { FC } from 'react';
import { FormItem } from '../../../../components/FormItem';
import { useTranslation } from 'react-i18next';
import { FormItemName } from '../../../../lib/models/Form';
import { AppSelect } from '../../../../components/AppSelect';
import { useGetTenantDomainsQuery } from '../../../../lib/store/api/tenants';

const label = 'pages.tenants.formItems.domains.label';
const error = 'pages.tenants.formItems.domains.error';

export const DomainsFormItem: FC = () => {
  const { t } = useTranslation();

  const { data } = useGetTenantDomainsQuery('1');

  return (
    <FormItem hasFeedback={true} name={FormItemName.DOMAINS} rules={[{ required: true, message: t(error) }]}>
      <AppSelect isMultiSelect options={data} label={t(label)} />
    </FormItem>
  );
};
