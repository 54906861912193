import React from 'react';
import styles from './index.module.scss';
import './ant-design-styles.scss';
import { ConfigProvider, Menu } from 'antd';
import { useMenuOptions } from '../hooks/useMenuOptions';
import { useTenantMenuOptions } from '../hooks/useTenantMenuOptions';
import { useSearchParams } from 'react-router-dom';
import { QueryParams, Tabs } from '../../../../lib/types/queryParams';

const theme = {
  token: {
    colorPrimary: '#5C5C6E',
    borderRadiusLG: 0,
  },
};
export const PlatformManagerSidebar = () => {
  const { items, selectedItems } = useMenuOptions();
  const {
    isTenantRoutesAvailable,
    items: tenantRoutesItems,
    selectedItems: tenantSelectedItems,
  } = useTenantMenuOptions();

  const [searchParams] = useSearchParams();
  const queryTab = searchParams.get(QueryParams.TAB);

  if (queryTab === Tabs.RULE_CONFIGURATOR) {
    return null;
  }

  return (
    <ConfigProvider theme={theme}>
      <aside className={styles.sidebar}>
        <Menu
          mode="inline"
          theme="dark"
          selectedKeys={isTenantRoutesAvailable ? tenantSelectedItems : selectedItems}
          className={styles.sidebar_menu}
          items={isTenantRoutesAvailable ? tenantRoutesItems : items}
        />
      </aside>
    </ConfigProvider>
  );
};
