import { FC } from 'react';
import { components, ValueContainerProps } from 'react-select';
import { SelectPropsT } from '../../types';
import cx from 'classnames';

import styles from './index.module.scss';

type Props = {
  selectProps: SelectPropsT;
} & ValueContainerProps<any>;

export const ValueContainer: FC<Props> = (props) => {
  const { selectProps } = props;

  return (
    <components.ValueContainer
      className={cx({ [styles.valueContainer]: selectProps?.listProps?.isListEnabled })}
      {...props}
    />
  );
};
