import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import { Dropdown, MenuProps } from 'antd';
import { Button } from '../Button';
import { useAppDispatch } from '../../lib/store/hooks';
import { setAppLanguage } from '../../lib/store/slices/authSlice';
import { setDefaultLocale } from 'react-datepicker';
import { LANGUAGE } from '../../lib/i18n/types';

const items: MenuProps['items'] = [
  {
    label: LANGUAGE.EN.toUpperCase(),
    key: '1',
  },
  {
    label: LANGUAGE.DE.toUpperCase(),
    key: '2',
  },
];

export const ToggleLanguage = () => {
  const dispatch = useAppDispatch();

  const { i18n } = useTranslation();

  const toggleLanguage: MenuProps['onClick'] = ({ key }) => {
    const newLanguage = key === '1' ? LANGUAGE.EN : LANGUAGE.DE;

    dispatch(setAppLanguage({ language: newLanguage }));
    setDefaultLocale(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  return (
    <Dropdown className={styles.container} menu={{ items, onClick: toggleLanguage }}>
      <div role="presentation" onClick={(e) => e.preventDefault()}>
        <Button style={{ backgroundColor: '#404055', boxShadow: 'none' }} type="primary">
          {i18n.language.toUpperCase()}
        </Button>
      </div>
    </Dropdown>
  );
};
