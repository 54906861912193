import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormItem } from '../../../../../components/FormItem';
import { AppSelect } from '../../../../../components/AppSelect';
import { usePositionOptionsQuery } from '../../../../../lib/store/api/demandArea';
import { DEMAND_AREA_FORMITEMS } from '../../../helpers';

const position = 'pages.products.demandArea.formItems.position';

type Props = {
  tenantId: string;
  modelId: string;
  isActivated: boolean;
};

export const PositionFormItem = (props: Props) => {
  const { t } = useTranslation();
  const { data, isLoading } = usePositionOptionsQuery({ tenantId: props.tenantId, modelId: props.modelId });

  const positionOptions = useMemo(() => {
    return data?.map((position) => {
      return { title: position, value: position };
    });
  }, [data]);

  return (
    <FormItem rules={[{ required: !props.isActivated }]} name={DEMAND_AREA_FORMITEMS.POSITION}>
      <AppSelect
        isLoading={isLoading}
        label={t(position)}
        options={positionOptions}
        disabled={props.isActivated}
      />
    </FormItem>
  );
};
