export enum PRODUCT_CATEGORIES {
  NAME = 'name',
  ICON = 'icon',
  DESCRIPTION = 'description',
  DEMAND_AREA = 'demandArea',
  PRIORITY_IN_DEMAND_AREA = 'priority',
  NUMBER_OF_PRODUCTS = 'products',
  SELECTION_TYPE = 'selectionType',
  IS_ACTIVE = 'activated',
  // STATUS_COMMENT = 'statusComment',
}
