import React, { FC, useRef } from 'react';
import Slider from 'react-slick';
import { useWindowResize } from '../../../../lib/hooks/useWindowResize';
import { useGetCarouselSettings } from '../../../endUserProductCarousel/hooks/useGetCarouselSettings';
import { ProductRecommendationCard } from '../ProductRecommendationCard';
import { ProductRecommendationT } from '../../../../lib/types/sections';
import { IconT } from '../../../designSettings/types';
import { BrandKitThemeConfiguration } from '../../../../lib/types/colors&fonts';

import styles from './index.module.scss';

type Props = {
  products: ProductRecommendationT[];
  arePointsShown: boolean;
  productSize: number;
  pointsIcon?: IconT;
  isDesktopOrLaptop?: boolean;
  isLandscape?: boolean;
  isTablet?: boolean;
  brandKit: BrandKitThemeConfiguration | null;
  isGuestMode: boolean;
  productNameLineCount: number;
};
export const TabletOrDesktopProductRecommendations: FC<Props> = ({
  products,
  arePointsShown,
  productSize,
  pointsIcon,
  isDesktopOrLaptop,
  isLandscape,
  isTablet,
  brandKit,
  isGuestMode,
  productNameLineCount,
}) => {
  const { windowWidth } = useWindowResize();

  const carouselRef = useRef<Slider>(null);

  const settings = useGetCarouselSettings({
    preSelectedCardIndex: 0,
    windowWidth,
  });

  const atLeastOneHasSubline = products.some((product) => Boolean(product.product?.subline));

  return (
    <div className={styles.root}>
      <Slider ref={carouselRef} {...settings} dots={false}>
        {products.map((item) => (
          <ProductRecommendationCard
            {...item}
            pointsIcon={pointsIcon}
            key={item.ruleId}
            arePointsShown={arePointsShown}
            isTablet={isTablet}
            productSize={productSize}
            isDesktopOrLaptop={isDesktopOrLaptop}
            productCount={products.filter((product) => product.product !== null).length}
            isLandscape={isLandscape}
            brandKit={brandKit}
            isGuestMode={isGuestMode}
            atLeastOneHasSubline={atLeastOneHasSubline}
            productNameLineCount={productNameLineCount}
          />
        ))}
      </Slider>
    </div>
  );
};
