import { FormItemName } from '../../../../lib/models/Form';
import { FormItem } from '../../../../components/FormItem';
import { Form, FormInstance, Switch, Typography } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

type Props = {
  form: FormInstance;
};

const label = 'pages.products.productCategories.formItems.finderEnabled.label';

export const FinderActivationFormItem: FC<Props> = ({ form }) => {
  const { t } = useTranslation();

  const toggleTracking = (checked: boolean) => {
    form.setFieldValue(FormItemName.FINDER_ENABLED, checked);
  };

  return (
    <FormItem name={FormItemName.FINDER_ENABLED}>
      <div style={{ display: 'flex', columnGap: '6px' }}>
        <Switch checked={Form.useWatch(FormItemName.FINDER_ENABLED, form)} onChange={toggleTracking} />
        <Text>{t(label)}</Text>
      </div>
    </FormItem>
  );
};
