import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { Form } from 'antd';
import { ArrangeFormItem } from '../../../../lib/models/Arrange';
import { TableSortOrder } from '../../../../lib/utils/constants';
import { closeModalAfterApply } from '../../../../features/table/helpers/closeModalAfterApply';
import { TableHeaderCell } from '../../../../components/Table/TableHeaderCell/TableHeaderCell';
import { SortPopup } from '../../../../features/table/components/popups/SortPopup';
import { getArrangesInitialValues } from '../../../../lib/utils/arranges-helpers';
import { ReactNode } from 'react';
import { BONUS_CATALOG_COLUMN } from '../../../../lib/types/bonusCatalog';
import { setSorting } from '../../../../lib/store/slices/bonusCatalogSlice';

type Props = {
  title: string | ReactNode;
  arrangedId: BONUS_CATALOG_COLUMN;
};

export const BonusCatalogHeaderCell = ({ arrangedId, title }: Props) => {
  const { arrangeId, sorting } = useAppSelector(({ bonusCatalog }) => bonusCatalog);
  const isActiveArrange = arrangeId === arrangedId;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const applyArranges = () => {
    const sortOrder = form.getFieldValue(ArrangeFormItem.SORT_ORDER);
    const sortingForChange = {
      arrangeId: arrangedId,
      sorting: {
        sortOrder: sortOrder as TableSortOrder,
        sortFieldName: arrangedId,
        withSorting: true,
      },
    };
    dispatch(setSorting(sortingForChange));
    closeModalAfterApply(arrangedId);
  };

  const headerCellProps = {
    title,
    sortOrder: sorting.sortOrder,
    isActiveArrange,
    form,
    applyArranges,
    arrangedId,
  };

  return (
    <TableHeaderCell {...headerCellProps}>
      <SortPopup
        initialValues={getArrangesInitialValues(form)}
        form={form}
        onFinish={applyArranges}
        isActiveArrange={isActiveArrange}
      />
    </TableHeaderCell>
  );
};
