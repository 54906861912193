import { FC, useRef } from 'react';
import { BonusCard } from '../BonusCard';
import Slider from 'react-slick';

import styles from './index.module.scss';

type Props = {
  bonuses: any;
};

const settings = {
  infinite: false,
  centerMode: false,
  speed: 500,
  slidesToScroll: 1,
  variableWidth: true,
  swipeToSlide: true,
};

export const BonusesList: FC<Props> = ({ bonuses }) => {
  const carouselRef = useRef<Slider>(null);

  return (
    <div className={styles.wrapper}>
      <>
        <Slider ref={carouselRef} {...settings}>
          {bonuses?.map((item: any, index: any) => (
            <div key={index}>
              <BonusCard title={item.title} name={item.name} text={item.description} picture={item.picture} />
            </div>
          ))}
        </Slider>
      </>
    </div>
  );
};
