import React, { FC } from 'react';
import { FormItemName } from '../../../../../lib/models/Form';
import { FormItem } from '../../../../../components/FormItem';
import { AppSelect } from '../../../../../components/AppSelect';

type Props = {
  tenantFormItemProps?: any;
  tenantTextFieldProps?: any;
};

export const TenantsFormItem: FC<Props> = ({ tenantTextFieldProps, tenantFormItemProps }) => {
  return (
    <FormItem name={FormItemName.TENANT_LIST} hasFeedback={true} {...tenantFormItemProps}>
      <AppSelect selectAll {...tenantTextFieldProps} />
    </FormItem>
  );
};
