import { FormInstance } from 'antd';
import { useEffect } from 'react';

type Props = {
  isActiveArrange: boolean;
  form: FormInstance<any>;
  clearOptions?: any;
};

export const useResetArrange = ({
  isActiveArrange,
  form,
  clearOptions,
}: Props) => {
  useEffect(() => {
    if (!isActiveArrange) {
      form.resetFields();

      if (clearOptions) {
        clearOptions();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveArrange]);
};
