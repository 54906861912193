import React, { FC } from 'react';
import { Form, FormInstance } from 'antd';
import { RuleBuilderFormPayload } from '../../types';
import { FormItem } from '../../../../components/FormItem';
import { FormItemName } from '../../../../lib/models/Form';
import { FormInput } from '../../../../components/FormInput';
import { useTranslation } from 'react-i18next';
import { AppSelect } from '../../../../components/AppSelect';

import styles from './index.module.scss';
import { Button } from '../../../../components/Button';
import cn from 'classnames';

const errors = 'pages.ruleBuilder.formItems.errors';

type Props = {
  form: FormInstance;
  onFinish: (values: RuleBuilderFormPayload) => void;
  enableEditMode: () => void;
  isEditModeEnabled: boolean;
  onAddNewCondition: () => void;
  ruleTypeOptions: { value: number; title: string }[];
};

export const RuleConfiguratorHeader: FC<Props> = ({
  form,
  onFinish,
  enableEditMode,
  isEditModeEnabled,
  onAddNewCondition,
  ruleTypeOptions,
}) => {
  const { t } = useTranslation();

  const handlePrimaryButtonClick = () => {
    if (!isEditModeEnabled) {
      enableEditMode();
      return;
    }

    form.submit();
  };

  return (
    <div className={styles.wrapper}>
      <Form className={styles.container} form={form} onFinish={onFinish}>
        <FormItem
          style={{ width: 300 }}
          hasFeedback={true}
          name={FormItemName.RULE_NAME}
          rules={[
            { required: true, message: t(`${errors}.ruleName`) },
            {
              max: 30,
              message: t(`${errors}.ruleNameLength`),
            },
          ]}
        >
          <FormInput disabled={!isEditModeEnabled} label={t('formItems.ruleBuilder.ruleName')} />
        </FormItem>

        <FormItem
          style={{ width: 300 }}
          hasFeedback={true}
          name={FormItemName.RULE_TYPE}
          rules={[{ required: true, message: t(`${errors}.ruleType`) }]}
        >
          <AppSelect
            disabled={!isEditModeEnabled}
            label={t('formItems.ruleBuilder.ruleType')}
            options={ruleTypeOptions}
          />
        </FormItem>
      </Form>
      <div className={styles.buttonGroup}>
        {isEditModeEnabled && (
          <Button
            onClick={onAddNewCondition}
            type="default"
            className={cn(styles.submitButton, styles.addConditionButton)}
          >
            {t('generic.addCondition')}
          </Button>
        )}

        <Button type="primary" onClick={handlePrimaryButtonClick} className={styles.submitButton}>
          {isEditModeEnabled ? t(`generic.save`) : t(`generic.edit`)}
        </Button>
      </div>
    </div>
  );
};
