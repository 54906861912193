import { api, TAGS_KEY } from './index';
import { ResultWithPagination } from '../../types/pagination';
import { TableSortOrder } from '../../utils/constants';
import {
  CreateRuleTypeBodyT,
  EditRuleTypeBodyT,
  RuleT,
  CreateRulePayloadT,
  GetPublicDictionaryResponseT,
  GetRuleByIdResponseT,
  RuleStatusResponseT,
} from '../../types/ruleBuilder';
import { GetTablePayloadT } from '../../types/common';

export const ruleBuilderApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRulesList: build.query<ResultWithPagination<RuleT[]>, GetTablePayloadT>({
      query: ({ page, sortFieldName, sortOrder, tenantId, modelId }) => ({
        url: `rule/get-rules?size=10&page=${page || 0}${
          sortFieldName && sortOrder && sortOrder !== TableSortOrder.NONE
            ? `&sort=${sortFieldName},${sortOrder}`
            : ''
        }`,
        method: 'POST',
        headers: {
          'X-TENANT': String(tenantId),
        },
        body: {
          includeByProjects: [modelId],
        },
      }),
      providesTags: [TAGS_KEY.RULE],
    }),
    getRuleTypesList: build.query<ResultWithPagination<RuleT[]>, GetTablePayloadT>({
      query: ({ page, sortFieldName, sortOrder, tenantId, modelId }) => ({
        url: `rule-type/get-rules?size=10&page=${page || 0}${
          sortFieldName && sortOrder && sortOrder !== TableSortOrder.NONE
            ? `&sort=${sortFieldName},${sortOrder}`
            : ''
        }`,
        method: 'POST',
        headers: {
          'X-TENANT': String(tenantId),
        },
        body: {
          includeByProjects: [modelId],
        },
      }),
      providesTags: [TAGS_KEY.RULE_TYPE],
    }),
    createRuleType: build.mutation<void, { tenantId: string; body: CreateRuleTypeBodyT }>({
      query: ({ tenantId, body }) => ({
        url: `rule-type`,
        method: 'POST',
        headers: {
          'X-TENANT': String(tenantId),
        },
        body,
      }),
      invalidatesTags: [TAGS_KEY.RULE_TYPE],
    }),
    updateRuleType: build.mutation<void, { tenantId: string; body: EditRuleTypeBodyT }>({
      query: ({ tenantId, body }) => ({
        url: `rule-type/${body?.id}`,
        method: 'PUT',
        headers: {
          'X-TENANT': String(tenantId),
        },
        body,
      }),
      invalidatesTags: [TAGS_KEY.RULE_TYPE],
    }),
    deleteRule: build.mutation<void, { tenantId: string; ruleId: number }>({
      query: ({ tenantId, ruleId }) => ({
        url: `rule/${ruleId}/delete`,
        method: 'DELETE',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      invalidatesTags: [TAGS_KEY.RULE],
    }),
    deleteRuleType: build.mutation<void, { tenantId: string; ruleId: number }>({
      query: ({ tenantId, ruleId }) => ({
        url: `rule-type/${ruleId}/delete`,
        method: 'DELETE',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      invalidatesTags: [TAGS_KEY.RULE_TYPE],
    }),
    getPublicDictionary: build.query<GetPublicDictionaryResponseT, void>({
      query: () => ({
        url: 'public/rule/dictionary',
        method: 'GET',
      }),
    }),
    createRule: build.mutation<RuleT, CreateRulePayloadT>({
      query: ({ tenantId, ...rest }) => {
        return {
          url: 'rule',
          body: { ...rest },
          method: 'POST',
          headers: {
            'X-TENANT': String(tenantId),
          },
        };
      },
    }),
    getRuleById: build.query<GetRuleByIdResponseT, { ruleId: string; tenantId: string }>({
      query: ({ ruleId, tenantId }) => ({
        url: `rule/${ruleId}`,
        method: 'GET',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
    }),
    updateRule: build.mutation<unknown, CreateRulePayloadT>({
      query: ({ ruleId, tenantId, ...rest }) => ({
        url: `rule/${ruleId}`,
        body: { ...rest },
        method: 'PUT',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      invalidatesTags: [TAGS_KEY.RULE],
    }),
    executeRule: build.mutation<void, { projectId: number; tenantId: number }>({
      query: ({ projectId, tenantId }) => ({
        url: `rule/execute/${projectId}`,
        method: 'PUT',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
    }),
    getRuleStatus: build.query<RuleStatusResponseT, { projectId: number; tenantId: number }>({
      query: ({ projectId, tenantId }) => ({
        url: `rule/${projectId}/rule-status`,
        method: 'GET',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
    }),
  }),
});

export const {
  useGetRulesListQuery,
  useDeleteRuleMutation,
  useDeleteRuleTypeMutation,
  useCreateRuleTypeMutation,
  useUpdateRuleTypeMutation,
  useGetPublicDictionaryQuery,
  useCreateRuleMutation,
  useGetRuleByIdQuery,
  useUpdateRuleMutation,
  useGetRuleTypesListQuery,
  useExecuteRuleMutation,
  useGetRuleStatusQuery,
} = ruleBuilderApi;
