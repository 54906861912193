import { useTranslation } from 'react-i18next';
import { FormItem } from '../../../../../components/FormItem';
import { FormInput } from '../../../../../components/FormInput';

import { DEMAND_AREA_FORMITEMS } from '../../../helpers';

const name = 'pages.products.demandArea.formItems.name';
const errors = 'pages.products.demandArea.formItems.errors';

export const NameFormItem = () => {
  const { t } = useTranslation();

  return (
    <FormItem
      hasFeedback={true}
      name={DEMAND_AREA_FORMITEMS.NAME}
      rules={[
        { max: 100 },
        {
          required: true,
          message: t(`${errors}.name`),
        },
      ]}
    >
      <FormInput label={t(name)} />
    </FormItem>
  );
};
