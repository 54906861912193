import React from 'react';
import { Tabs, TabsProps } from 'antd';
import styles from './index.module.scss';

export const AppTabs = (props: TabsProps) => {
  return (
    <div className={styles.root}>
      <Tabs {...props} />
    </div>
  );
};
