import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormItemName } from '../../../../../lib/models/Form';
import { FormInput } from '../../../../../components/FormInput';
import { FormItem } from '../../../../../components/FormItem';

const userManagement = 'pages.userManagement';

type Props = {
  disabled?: boolean;
};

export const UsernameFormItem: FC<Props> = ({ disabled = false }) => {
  const { t } = useTranslation();

  return (
    <FormItem name={FormItemName.USERNAME} hasFeedback={true}>
      <FormInput disabled={disabled} label={t(`${userManagement}.formItems.username`)} />
    </FormItem>
  );
};
