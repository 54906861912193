import { useTranslation } from 'react-i18next';
import { FormInput } from '../../../../../components/FormInput';
import { FormItem } from '../../../../../components/FormItem';
import { BONUS_CATALOG_FORM_ITEMS } from '../../../helpers';

const bonusCatalogT = `pages.bonusCatalogTab`;

export const DescriptionFormItem = () => {
  const { t } = useTranslation();

  return (
    <FormItem
      hasFeedback={true}
      name={BONUS_CATALOG_FORM_ITEMS.DESCRIPTION}
      rules={[
        { max: 500, message: t(`${bonusCatalogT}.formItems.errors.descriptionMaxLength`) },
        {
          required: true,
          message: t(`${bonusCatalogT}.formItems.errors.description`),
        },
      ]}
    >
      <FormInput label={t(`${bonusCatalogT}.formItems.description`)} />
    </FormItem>
  );
};
