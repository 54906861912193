import { FormItemName } from '../../../lib/models/Form';

//TODO check all possibles errors codes and fill translations
export const BACKEND_VALIDATION_ERRORS = new Map<string, { fieldName: string; message: string }>([
  [
    'ERROR_020',
    {
      fieldName: FormItemName.PSEUDONYM,
      message: 'Diese Nutzer-ID wurde bereits verwendet',
    },
  ],
  [
    'ERROR_318',
    {
      fieldName: FormItemName.PSEUDONYM,
      message: 'Das angegebene Nutzerprofil existiert nicht',
    },
  ],
  [
    'ERROR_319',
    {
      fieldName: FormItemName.EMAIL,
      message: 'Diese E-Mail-Adresse ist bereits vergeben',
    },
  ],
  [
    'ERROR_019',
    {
      fieldName: FormItemName.EMAIL,
      message: 'Diese E-Mail-Adresse ist bereits vergeben',
    },
  ],
]);
