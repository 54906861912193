import { useTranslation } from 'react-i18next';
import { useProjectSearchDataColumn } from './useProjectSearchDataColumn';

import { ProjectNameBodyCell } from '../components/ProjectNameBodyCell';
import { PROJECT_COLUMN } from '../helpers/constants';

import styles from '../../../components/Table/index.module.scss';

const projectName = 'pages.projects.columns.projectName';

export const useProjectNameColumn = (areColumnsExpanded: boolean) => {
  const { t } = useTranslation();

  return useProjectSearchDataColumn({
    title: t(projectName),
    arrangedId: PROJECT_COLUMN.NAME,
    searchFieldName: 'searchByName',
    filterFieldName: 'includeNames',
    columnProps: {
      width: 189,
      className: areColumnsExpanded ? undefined : styles.borderRight,
      render: (text: string, row: { id: number }) => <ProjectNameBodyCell id={row.id} link={text} />,
    },
    headerCellProps: {
      rootProps: { className: styles.borderRight },
    },
  });
};
