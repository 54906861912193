import { useAppDispatch } from '../../../lib/store/hooks';
import { ModalActions } from '../../../lib/models/Modal';
import { setCurrentRuleType, setModalOpened } from '../../../lib/store/slices/ruleBuilder';
import { RuleT } from '../../../lib/types/ruleBuilder';

export const useRuleTypesActionsRequest = () => {
  const dispatch = useAppDispatch();

  const onDeleteRuleType = (rule: RuleT) => {
    dispatch(setCurrentRuleType(rule));
    dispatch(setModalOpened(ModalActions.DELETE));
  };
  const onUpdateRuleType = (rule: RuleT) => {
    dispatch(setCurrentRuleType(rule));
    dispatch(setModalOpened(ModalActions.EDIT));
  };

  return { onUpdateRuleType, onDeleteRuleType };
};
