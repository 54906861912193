import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormItemName } from '../../../../../lib/models/Form';
import { Form, FormInstance, Switch, Typography } from 'antd';
import { AppCardBasic } from '../../../../../components/AppCardBasic';
import styles from '../TrackingCard/styles.module.scss';
import { FormItem } from '../../../../../components/FormItem';
import { FormInput } from '../../../../../components/FormInput';
import { TextEditor } from '../../../../../components/TextEditor';

const { Title, Text } = Typography;

const title = 'pages.settings.dataProtection.title';
const switchText = 'pages.settings.dataProtection.switchText';
const formItems = 'pages.settings.formItems';

type Props = {
  form: FormInstance;
};

export const DataProtectionCard: FC<Props> = ({ form }) => {
  const { t } = useTranslation();

  const toggleDataProtection = (checked: boolean) => {
    form.setFieldValue(FormItemName.DATA_PROTECTION, checked);
  };

  return (
    <AppCardBasic>
      <div className={styles.header}>
        <Title className={styles.title}>{t(title)}</Title>
      </div>
      <FormItem noStyle name={FormItemName.DATA_PROTECTION}>
        <div className={styles.switchContainer}>
          <Switch
            checked={Form.useWatch(FormItemName.DATA_PROTECTION, form)}
            onChange={toggleDataProtection}
          />
          <Text className={styles.text}>{t(switchText)}</Text>
        </div>
      </FormItem>
      <FormItem
        name={FormItemName.DATA_PROTECTION_HEAD}
        help={false}
        style={{ minHeight: 72 }}
        hasFeedback={true}
        rules={[{ required: Form.useWatch(FormItemName.DATA_PROTECTION, form) }]}
      >
        <FormInput label={t(`${formItems}.headline`)} />
      </FormItem>
      <FormItem
        name={FormItemName.DATA_PROTECTION_SUB}
        help={false}
        style={{ minHeight: 72 }}
        hasFeedback={true}
      >
        <FormInput label={t(`${formItems}.subline`)} />
      </FormItem>

      <FormItem style={{ minHeight: 72 }} name={FormItemName.DATA_PROTECTION_CONTENT} help={false}>
        <TextEditor form={form} />
      </FormItem>
    </AppCardBasic>
  );
};
