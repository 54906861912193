import styles from '../../../../features/form-items/ColorPickerFormItem/index.module.scss';
import { AppCardBasic } from '../../../../components/AppCardBasic';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { ColorPickerFormItem } from '../../../../features/form-items/ColorPickerFormItem';
import { ColorVariants, BrandKitThemeConfiguration } from '../../../../lib/types/colors&fonts';
import { Dispatch, SetStateAction } from 'react';

const { Text } = Typography;

const colors = 'shared.colors';

type Props = {
  themeConfiguration: BrandKitThemeConfiguration;
  setThemeConfiguration: Dispatch<SetStateAction<BrandKitThemeConfiguration>>;
};

export const ColorsCard = ({ themeConfiguration, setThemeConfiguration }: Props) => {
  const { t } = useTranslation();

  const colorsFromServer = themeConfiguration.colors;

  const onChangeField = (fieldName: string, value: string) => {
    setThemeConfiguration({
      ...themeConfiguration,
      colors: { ...themeConfiguration.colors, [fieldName]: value },
    });
  };

  const colorPickers = [
    {
      label: t(`${colors}.primary`),
      fieldName: ColorVariants.PRIMARY_COLOR,
      color: colorsFromServer.primaryColor,
    },
    {
      label: t(`${colors}.secondary1`),
      fieldName: ColorVariants.SECONDARY_FIRST,
      color: colorsFromServer.secondaryFirst,
    },
    {
      label: t(`${colors}.secondary2`),
      fieldName: ColorVariants.SECONDARY_SECOND,
      color: colorsFromServer.secondarySecond,
    },
    {
      label: t(`${colors}.background`),
      fieldName: ColorVariants.BACKGROUND_COLOR,
      color: colorsFromServer.backgroundColor,
    },
  ];

  return (
    <AppCardBasic>
      <>
        <Text className={styles.title}>{t(`${colors}.colorsName`)}</Text>
        <div className={styles.color_picker}>
          {colorPickers.map(({ color, fieldName, label }) => {
            return (
              <ColorPickerFormItem
                setColor={onChangeField}
                key={fieldName}
                color={color}
                fieldName={fieldName}
                label={label}
              />
            );
          })}
        </div>
      </>
    </AppCardBasic>
  );
};
