import { HeaderSection } from '../../modules/endUserSectionHeader/components/HeaderSection';
import { useAppSelector } from '../../lib/store/hooks';
import { SectionName } from '../../lib/models/Section';
import { StatusLevelSection } from '../../modules/endUserStatusLevel/components/StatusLevelSection';
import { EndUserBoughtProducts } from '../../modules/endUserBoughtProducts/components/EndUserBoughtProducts';
import { EndUserReceivedBonusesSection } from '../../modules/endUserReceivedBonuses/components/EndUserReceivedBonusesSection';
import { RegionalOffersSection } from '../../modules/endUserRegionalOffers/components/RegionalOffersSection';
import { useMemo } from 'react';

export const EndUserStatusPage = () => {
  const { sections, tenantAndProject } = useAppSelector(({ endUser }) => endUser);
  const currentSectionContent = sections?.[SectionName.POINTS_OVERVIEW];

  const orderNode = useMemo(() => {
    if (tenantAndProject?.subDomain === 'energie-schwaben') {
      return (
        <>
          <EndUserBoughtProducts />
          {currentSectionContent?.withRegionalOfferSection && <RegionalOffersSection />}
        </>
      );
    }

    return (
      <>
        {currentSectionContent?.withRegionalOfferSection && <RegionalOffersSection />}
        <EndUserBoughtProducts />
      </>
    );
  }, [currentSectionContent?.withRegionalOfferSection, tenantAndProject?.subDomain]);

  return (
    <>
      <HeaderSection
        header={currentSectionContent?.headline1}
        title={currentSectionContent?.subline}
        image={currentSectionContent?.backgroundImage}
      />
      <StatusLevelSection />
      <EndUserReceivedBonusesSection />
      {orderNode}
    </>
  );
};
