import { useTranslation } from 'react-i18next';
import { useProductSearchDataColumn } from './useProductSearchDataColumn';
import { PRODUCTS_COLUMNS } from '../helpers/consts';
import { Typography } from 'antd';

const { Text } = Typography;

const productCategory = 'pages.products.products.columns.productCategory';

export const useProductCategoryColumn = () => {
  const { t } = useTranslation();

  return useProductSearchDataColumn({
    title: t(productCategory),
    arrangedId: PRODUCTS_COLUMNS.CATEGORY,
    filterFieldName: 'includeByCategoryIds',
    isParsedByValue: true,
    columnProps: {
      render: (data: { name: string }) => {
        return <Text>{data.name}</Text>;
      },
    },
  });
};
