import { FC } from 'react';

import { Typography } from 'antd';
import styles from './index.module.scss';
import cn from 'classnames';

const { Text } = Typography;

type Props = {
  name: string;
  disabled: boolean;
};

export const LogicalOperator: FC<Props> = ({ name, disabled }) => {
  return (
    <div className={cn(styles.container, disabled ? styles.disabled : '')}>
      <Text disabled={disabled}>{name}</Text>
    </div>
  );
};
