import { FC } from 'react';
import { Form, message } from 'antd';
import { EditTenantForm } from './EditTenantForm';
import { useTranslation } from 'react-i18next';
import { useEditTenantMutation } from '../../../../lib/store/api/tenants';
import { TenantT } from '../../../../lib/types/tenants';
import { Modal } from '../../../../components/Modal';
import { useResetFormOnCloseModal } from '../../../../lib/hooks/useResetFormOnCloseModal';

import styles from './index.module.scss';

const title = 'pages.tenants.editModal.title';
const additionalInfo = 'pages.tenants.formItems.additionalInfo';

type Props = {
  isOpen: boolean;
  close: () => void;
  tenantData: TenantT | null;
};

export const EditTenantModal: FC<Props> = ({ close, isOpen, tenantData }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const [editTenant, { isLoading }] = useEditTenantMutation();

  useResetFormOnCloseModal({ form, isOpen });

  return (
    <>
      {contextHolder}
      <Modal
        open={isOpen}
        titleProps={{
          title: t(title),
          className: styles.editTitle,
        }}
        cancelButtonProps={{ onClick: close }}
        actionButtonProps={{ onClick: form.submit, loading: isLoading }}
      >
        {isOpen && (
          <EditTenantForm
            form={form}
            additionalInfo={t(additionalInfo)}
            tenantId={tenantData?.id as number}
            onSubmit={editTenant}
            messageApi={messageApi}
          />
        )}
      </Modal>
    </>
  );
};
