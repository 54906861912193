import { ProductT } from '../../../lib/types/product';
import { ProductRecommendationT } from '../../../lib/types/sections';

const LEF_PADDING = 16;
const DEFAULT_CARD_WIDTH = 460;
const NON_DESKTOP_CARD_WIDTH = 366;

export function getCenterPadding(products: ProductT[] | ProductRecommendationT[], windowWidth: number) {
  const defaultValue = 0;

  if (products.length === 1) {
    let cardWidth = DEFAULT_CARD_WIDTH;

    // eslint-disable-next-line no-magic-numbers
    if (windowWidth <= 1200) {
      cardWidth = NON_DESKTOP_CARD_WIDTH;
    }

    // eslint-disable-next-line no-magic-numbers
    return windowWidth / 2 - cardWidth / 2 - LEF_PADDING;
  }

  return defaultValue;
}

export const CAROUSEL_MAIN_SETTINGS = {
  infinite: false,
  centerMode: false,
  speed: 500,
  variableWidth: true,
  slidesToShow: 1,
  swipeToSlide: true,
  initialSlide: 0,
  easing: 'linear',
  dots: true,
};
