import { FC, useEffect, useState } from 'react';
import { Form, message } from 'antd';
import { MediaCard } from '../MediaCard';
import { BulletPointsCard } from '../BulletPointsCard';
import { IconsCard } from '../IconsCard';
import { defaultDesignSettingsValues } from '../../helpers/getDesignSettings';
import { FooterCard } from '../FooterCard';
import { HeaderCard } from '../HeaderCard';
import { ColorsCard } from '../ColorsCard';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { useParams } from 'react-router-dom';
import {
  useGetDesignSettingsQuery,
  useSetDesignSettingsMutation,
} from '../../../../lib/store/api/designSettings';
import { PageLoader } from '../../../../components/PageLoader';
import { setDesignSettingsSubmitted } from '../../../../lib/store/slices/designSettingsSlice';
import { parseJSON, trimForm } from '../../../../lib/utils/helpers';
import { useTranslation } from 'react-i18next';
import { FormItemName } from '../../../../lib/models/Form';
import { ButtonCard } from '../ButtonCard';
import { DesignSettingsDataT } from '../../types';

import styles from './index.module.scss';

const success = 'pages.designSettingsTab.success';
const error = 'pages.designSettingsTab.error';

export const DesignSettingsTab: FC = () => {
  const [designSettings, setDesignSettings] = useState<DesignSettingsDataT>();

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { id: tenantId, modelId } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useAppDispatch();
  const { isDesignSettingsSubmitted } = useAppSelector(({ designSettings }) => designSettings);

  const { data, isLoading } = useGetDesignSettingsQuery(
    { tenantId: tenantId as string, projectId: String(modelId) },
    { skip: !tenantId },
  );

  useEffect(() => {
    if (data && !isLoading) {
      const designSettings = data?.[0];
      const parsedData: DesignSettingsDataT = parseJSON(designSettings?.settings);
      setDesignSettings(Object.keys(parsedData).length > 0 ? parsedData : defaultDesignSettingsValues);

      form.setFieldsValue({
        [FormItemName.NAVIGATION]: parsedData?.headerConfig?.navigation,
        [FormItemName.BUTTON_LINKS]: parsedData?.footerConfig?.buttonLinks,
      });
    }
  }, [data, form, isLoading]);

  const [updateDesignSettings] = useSetDesignSettingsMutation();

  useEffect(() => {
    // DO SUBMIT WHEN YOU CLICK ON SAVE BUTTON IN HEADER
    if (isDesignSettingsSubmitted) {
      form?.submit();
    }
  }, [form, isDesignSettingsSubmitted]);

  const onFinish = async (values: any) => {
    if (designSettings) {
      const trimmedForm = trimForm(values);
      const settings = {
        ...designSettings,
        headerConfig: { ...designSettings.headerConfig, navigation: trimmedForm.navigation },
        footerConfig: { ...designSettings.footerConfig, buttonLinks: trimmedForm.buttonLinks },
      };
      const jsonSettings = JSON.stringify(settings);
      const sendData = { projectId: String(modelId), tenantId: tenantId as string, settings: jsonSettings };

      try {
        await updateDesignSettings(sendData).unwrap();
        messageApi.success(t(success));
      } catch (err) {
        messageApi.error(t(error));
      } finally {
        dispatch(setDesignSettingsSubmitted(false));
      }
    }
  };

  const onFinishFailed = () => {
    dispatch(setDesignSettingsSubmitted(false));
  };

  if (isLoading || !designSettings) {
    return <PageLoader />;
  }

  return (
    <Form
      form={form}
      className={styles.root}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      scrollToFirstError={{
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      }}
    >
      {contextHolder}
      <ColorsCard designSettings={designSettings} setDesignSettings={setDesignSettings} />
      <BulletPointsCard designSettings={designSettings} setDesignSettings={setDesignSettings} />
      <IconsCard designSettings={designSettings} setDesignSettings={setDesignSettings} />
      <MediaCard designSettings={designSettings} setDesignSettings={setDesignSettings} />
      <ButtonCard
        buttonType={'primaryButton'}
        designSettings={designSettings}
        setDesignSettings={setDesignSettings}
      />
      <ButtonCard
        buttonType={'secondaryButton'}
        designSettings={designSettings}
        setDesignSettings={setDesignSettings}
      />
      <HeaderCard designSettings={designSettings} setDesignSettings={setDesignSettings} />
      <FooterCard designSettings={designSettings} setDesignSettings={setDesignSettings} />
    </Form>
  );
};
