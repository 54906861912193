import { UserStatus } from '../../helpers/constants';
import { Status, StatusType } from '../../../../features/table/components/Status';
import { useTranslation } from 'react-i18next';

const activated = 'shared.status.activated';
const deactivated = 'shared.status.deactivated';
const pending = 'shared.status.pending';

type Props = {
  status: UserStatus;
};

export function UserStatusBodyCell({ status }: Props) {
  const { t } = useTranslation();

  switch (status) {
    case UserStatus.ACTIVE:
      return <Status textValue={t(activated)} type={StatusType.GREEN} />;

    case UserStatus.PENDING:
      return <Status textValue={t(pending)} type={StatusType.ORANGE} />;

    case UserStatus.DEACTIVATED:
      return <Status textValue={t(deactivated)} type={StatusType.GRAY} />;

    default:
      throw new Error('Invalid status');
  }
}
