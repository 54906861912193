import styles from './index.module.scss';
import { FC } from 'react';
import { ReactComponent as CheckmarkIcon } from '../../assets/icons/CheckmarkIcon.svg';

type Props = {
  label?: any;
  checked?: boolean;
  onChange?: (params: any) => void;
  style?: object;
  isDisabled?: boolean;
  className?: string;
};

export const AppCheckbox: FC<Props> = ({
  label,
  checked = false,
  onChange,
  style,
  isDisabled,
  className,
}) => {
  return (
    <label className={isDisabled ? styles.disabled : styles.root} style={style}>
      <input
        type="checkbox"
        checked={checked}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onChange={onChange ? onChange : () => {}}
        className={`${styles.input} ${isDisabled ? styles.disabled : ''}`}
        disabled={isDisabled}
      />

      <span className={`${styles.content} ${className}`}>
        <span className={styles.customCheckbox}>
          <CheckmarkIcon />
        </span>

        {label && <div className={styles.label}>{label}</div>}
      </span>
    </label>
  );
};
