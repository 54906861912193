import { StatusLevelT } from '../../../lib/types/statusLevel';
import { FormItemName } from '../../../lib/models/Form';

export const getStatusLevelFieldsData = (statusLevel: StatusLevelT) => {
  return {
    [FormItemName.STATUS_LEVEL_TITLE]: statusLevel.name,
    [FormItemName.SUBLEVELS]: statusLevel.subLevels.map((item) => ({
      [FormItemName.SUBLEVEL_TITLE]: item.name,
      [FormItemName.SUBLEVEL_POINTS]: item.points,
      [FormItemName.SUBLEVEL_BONUSES]: item.bonuses.map((bonus) => ({
        ...bonus,
        value: bonus.id,
      })),
    })),
  };
};
