import { Navigate, Route, Routes } from 'react-router-dom';
import { ROUTER_PATHS } from '../../../lib/utils/router-paths';
import { EndUserFlowLayout } from '../../layouts/EndUserFlowLayout';
import { EndUserHomePage } from '../../../pages/EndUserHomePage';
import { EndUserDemandAreaPage } from '../../../pages/EndUserDemandAreaPage';
import { EndUserBonusOverviewPage } from '../../../pages/EndUserBonusOverviewPage';
import { EndUserWishlistPage } from '../../../pages/EndUserWishlistPage';
import { EndUserStatusPage } from '../../../pages/EndUserStatusPage';
import { EndUserNotFoundPage } from '../../../pages/EndUserNotFoundPage';
import { useAppSelector } from '../../../lib/store/hooks';
import { useGetEndUserData } from '../../../lib/hooks/endUserHooks/useGetEndUserData';
import { MatomoTracker } from '../MatomoTracker';
import { FinderModal } from '../FinderModal';

export const EndUserRoutes = () => {
  const { model, demandArea } = useAppSelector(({ endUser }) => endUser);
  useGetEndUserData();

  const modelShouldBeDeactivated = !model?.activated || !demandArea?.tenant?.activated;

  return (
    <>
      <FinderModal />
      <MatomoTracker />
      <Routes>
        <Route path={ROUTER_PATHS.noMatch} element={<Navigate to={ROUTER_PATHS.home} />} />
        <Route element={<EndUserFlowLayout />}>
          <Route path={ROUTER_PATHS.home} element={<EndUserHomePage />} />

          <Route path={`${ROUTER_PATHS.houseModel}`} element={<EndUserDemandAreaPage />}>
            <Route path={`:id`} element={<EndUserDemandAreaPage />} />
          </Route>

          <Route path={ROUTER_PATHS.bonuses} element={<EndUserBonusOverviewPage />} />
          <Route path={ROUTER_PATHS.pointsOverview} element={<EndUserStatusPage />} />
          <Route path={ROUTER_PATHS.wishlist} element={<EndUserWishlistPage />} />
        </Route>
        <Route
          path={ROUTER_PATHS.notFound}
          element={modelShouldBeDeactivated ? <EndUserNotFoundPage /> : <Navigate to={ROUTER_PATHS.logIn} />}
        />
      </Routes>
    </>
  );
};
