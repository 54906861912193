import { CreateDemandAreaValues } from '../components/Modals/CreateDemandAreaModal';
import { CreateDemandAreaPayload, DemandArea } from '../../../lib/types/demandArea';
import { FontColorTag } from '../../../features/form-items/FontColorFormItem/FontColorTag';
import i18next from 'i18next';
import { IconT } from '../../designSettings/types';
import { UploadMediaResponseT } from '../../../lib/types/media';
import { parseJSON } from '../../../lib/utils/helpers';

type IdItemsT = {
  demandAreaId?: number;
  tenantId: string;
  modelId: string;
};

export enum DEMAND_AREA_FORMITEMS {
  NAME = 'name',
  CATEGORIES = 'categories',
  POSITION = 'position',
  COLOR = 'color',
  ICON = 'icon',
  ACTIVATED = 'activated',
}

export const transformDemandAreaValuesToSendData = (
  values: CreateDemandAreaValues,
  idItems: IdItemsT,
  activated: boolean,
  iconsList?: IconT[],
) => {
  const selectedIcon = iconsList?.find((item) => item.id === values.icon.value);
  const sendData: CreateDemandAreaPayload & { id?: number } = {
    ...values,
    id: idItems.demandAreaId,
    [DEMAND_AREA_FORMITEMS.POSITION]: activated
      ? null
      : { number: values[DEMAND_AREA_FORMITEMS.POSITION]?.value as number },
    [DEMAND_AREA_FORMITEMS.COLOR]: JSON.stringify({
      value: values[DEMAND_AREA_FORMITEMS.COLOR].value,
      name: values[DEMAND_AREA_FORMITEMS.COLOR]?.title?.props?.text,
    }),
    icon: selectedIcon as UploadMediaResponseT,
    tenantId: idItems.tenantId,
    projectId: idItems.modelId,
    activated: !activated,
  };
  return sendData;
};

export const transformDemandAreaFromServerToInitialData = (selectedDemandArea: DemandArea) => {
  const JsonColor = selectedDemandArea.color;
  const color: { name: string; value: string } = parseJSON(JsonColor);
  const { t } = i18next;

  return {
    ...selectedDemandArea,
    categories: selectedDemandArea.categories.map((category) => {
      return { title: category.name, value: category.id };
    }),
    position: selectedDemandArea?.position?.number
      ? { value: selectedDemandArea?.position?.number, title: selectedDemandArea?.position?.number }
      : undefined,
    color: {
      title: <FontColorTag size="large" text={t(color.name)} color={color?.value} hex={color?.value} />,
      value: color.value,
    },
    activated: !selectedDemandArea.activated,
  };
};
