import { FC, useCallback, useMemo, useState } from 'react';
import { BonusesList } from '../BonusesList';
import { BonusesTabs } from '../BonusesTabs';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { useAppSelector } from '../../../../lib/store/hooks';
import { SectionName } from '../../../../lib/models/Section';
import { Link } from 'react-router-dom';
import { useCalculatePoints } from '../../../../lib/hooks/endUserHooks/useCalculatePoints';
import { HeaderSection } from '../../../endUserSectionHeader/components/HeaderSection';
import { EndUserButton } from '../../../../components/EndUserButton';
import { sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';
import { combineBonusesFromStatusLevel } from '../../../../lib/utils/end-user-helpers';
import { BonusT } from '../../../../lib/types/statusLevel';

import styles from './index.module.scss';

export const BonusOverviewSection: FC = () => {
  const { sections, demandArea, allProducts, statusLevels } = useAppSelector(({ endUser }) => endUser);
  const currentSectionContent = sections?.[SectionName.BONUS_PAGE];

  const userPoints = useCalculatePoints(allProducts);

  const subLevelsWithStatusLevel = useMemo(() => {
    return statusLevels
      .flatMap((statusLevel: any) => {
        const firstSubLevel = statusLevel.subLevels[0];
        return firstSubLevel ? { ...firstSubLevel, statusLevelName: statusLevel.name } : null;
      })
      .filter(Boolean);
  }, [statusLevels]);

  const [combinedBonuses, setCombinedBonuses] = useState<BonusT[]>([]);

  const handleTabChange = useCallback(
    (selectedTab: any) => {
      const foundStatusLevel = statusLevels?.find((level: any) =>
        level.subLevels.some((subLevel: any) => subLevel.id === selectedTab.id),
      );

      const combinedBonuses = combineBonusesFromStatusLevel(foundStatusLevel);

      setCombinedBonuses(combinedBonuses);

      if (selectedTab) {
        sendDataToAnalytics(
          'Bonus Overview Section,',
          'Status level change,',
          `Status level name: ${selectedTab.name}, Status level id: ${selectedTab.id}`,
        );
      }
    },
    [statusLevels],
  );

  const handleClickMainButton = () => {
    sendDataToAnalytics(
      'Bonus Overview Section,',
      'Primary button,',
      `Redirect to ${currentSectionContent?.buttonRedirectTo}`,
    );
  };

  return (
    <>
      <HeaderSection
        header={currentSectionContent?.headline1}
        title={currentSectionContent?.subline}
        image={currentSectionContent?.backgroundImage}
      />
      <div className={styles.wrapper}>
        <EndUserTypography type="headlineSecond" component="Title" className={styles.title}>
          {currentSectionContent?.headline2}
        </EndUserTypography>

        <BonusesTabs
          icon={demandArea?.project?.pointsIcon}
          sortedSubLevels={subLevelsWithStatusLevel}
          userPoints={userPoints}
          onTabChange={handleTabChange}
        />
        <BonusesList bonuses={combinedBonuses} />
        <Link
          onClick={handleClickMainButton}
          className={styles.button}
          to={`/${currentSectionContent?.buttonRedirectTo}`}
        >
          <EndUserButton type="primary" className={styles.button}>
            {currentSectionContent?.buttonText}
          </EndUserButton>
        </Link>
      </div>
    </>
  );
};
