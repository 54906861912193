import { MobileCarousel } from '../MobileCarousel';
import { NonMobileProductCarousel } from '../NonMobileCarousel';
import { ProductDetailsSectionT } from '../../../../lib/types/sections';
import { useAppSelector } from '../../../../lib/store/hooks';
import { SectionName } from '../../../../lib/models/Section';
import { useLocation, useParams } from 'react-router-dom';
import { findNextStatusLevel } from '../../../endUserProductRecommendation/helpers';
import { useCalculatePoints } from '../../../../lib/hooks/endUserHooks/useCalculatePoints';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import { useEffect, useRef } from 'react';
import { ProductT, Visibility } from '../../../../lib/types/product';
import { IconT } from '../../../designSettings/types';
import { FinderButton } from '../../../../components/FinderButton';

export const EndUserProductSection = () => {
  const { id: demandAreaId } = useParams();

  const {
    selectedCategory,
    allProducts,
    sections,
    brandKit,
    wishlistProducts,
    demandArea,
    isGuestMode,
    statusLevels,
  } = useAppSelector(({ endUser }) => endUser);

  const userPoints = useCalculatePoints(allProducts);

  const productSectionContent = sections?.[SectionName.PRODUCT_DETAILS];

  const foundProducts = allProducts.filter(
    (product) =>
      product.demandAreaId === Number(demandAreaId) && product.category.id === selectedCategory?.id,
  );

  const modifiedProducts = foundProducts.map((product) => ({
    ...product,
    category: { ...selectedCategory },
    reason: findNextStatusLevel(statusLevels, userPoints, product),
    isWishlist: !!wishlistProducts.find((wishlistProduct) => wishlistProduct.id === product.id),
  }));

  const sortedProducts: any = modifiedProducts.slice().sort((a, b) => a.priority - b.priority);

  const irrelevantProducts = sortedProducts.filter(
    (product: ProductT) => product.irrelevantSurveyReason !== null,
  );
  const productWithoutIrrelevant = sortedProducts.filter(
    (product: ProductT) => product.irrelevantSurveyReason === null,
  );

  const preparedProducts = [...productWithoutIrrelevant, ...irrelevantProducts].filter((product) => {
    return (
      product.visibility === Visibility.ALWAYS ||
      (product.visibility === Visibility.ONLY &&
        (product.countUserProducts > 0 || product.countSpecialProducts > 0))
    );
  });

  const productSection = {
    arePointsShown: true,
    isPriceShown: true,
    pointsIcon: demandArea?.project.pointsIcon as IconT,
    products: preparedProducts,
    ...productSectionContent,
  } as ProductDetailsSectionT;

  const { isMobile } = useContainerQuery();

  const fieldRef = useRef<HTMLDivElement | null>(null);

  const { state } = useLocation();

  useEffect(() => {
    if (state?.scrollTo === SectionName.PRODUCT_DETAILS && fieldRef.current) {
      setTimeout(() => {
        fieldRef?.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }, 0);
    }
  }, [state]);

  return (
    <div ref={fieldRef}>
      {isMobile ? (
        <MobileCarousel brandKit={brandKit} productSection={productSection} isGuestMode={isGuestMode} />
      ) : (
        <NonMobileProductCarousel
          brandKit={brandKit}
          productSection={productSection}
          isGuestMode={isGuestMode}
        />
      )}
      <FinderButton
        showFinderButton={selectedCategory?.finderEnabled}
        finderButtonText={selectedCategory?.finderButtonName}
        finderButtonUrl={selectedCategory?.finderUrl}
        recommendForAllCategories={false}
        buttonType="secondary"
        categoryId={selectedCategory?.id}
        style={{ textAlign: 'center', marginTop: 16 }}
      />
    </div>
  );
};
