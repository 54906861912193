import { FormInstance } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../lib/store/hooks';
import {
  clearDateFilters,
  clearListFilters,
  getParsedSelectedItems,
  LIST_VALUE_PROP_NAME,
} from '../../../lib/utils/arranges-helpers';
import { ArrangeFormItem } from '../../../lib/models/Arrange';
import { TableSortOrder } from '../../../lib/utils/constants';
import { ProjectsFiltersPayload, setFilters, setPage } from '../../../lib/store/slices/projectsSlice';
import { isArrayNotEmpty, isObjectEmpty } from '../../../lib/utils/helpers';
import { closeModalAfterApply } from '../../../features/table/helpers/closeModalAfterApply';
import { PROJECT_COLUMN } from '../helpers/constants';

type Props = {
  arrangedId: string | PROJECT_COLUMN | null;
  form: FormInstance<any>;
  setFilterLabel?: any;
  getFilters?: any;
  getFilterLabel?: any;
  filterFieldName?: string;
  isParsedByValue?: boolean;
  isDate?: boolean;
};
export const useProjectArrangeRequest = ({
  arrangedId,
  setFilterLabel,
  getFilters,
  getFilterLabel,
  form,
  filterFieldName,
  isParsedByValue = false,
  isDate = false,
}: Props) => {
  const dispatch = useAppDispatch();
  const sortingAndFiltersData = useAppSelector(({ projects }) => projects);

  const clearFormFilters = () => {
    isDate ? clearDateFilters(form) : clearListFilters(form);
  };

  const applyArranges = async () => {
    const values = form.getFieldsValue();
    const sort = values[ArrangeFormItem.SORT_ORDER];
    const filters = getFilters?.();

    const parsedItems = getParsedSelectedItems(form);

    const searchItems = parsedItems.map((item: { value: string; label: string }) => {
      if (isParsedByValue) {
        return item.value;
      } else {
        return item.label;
      }
    });

    const filtering = filters.hasOwnProperty('activated')
      ? {
          filter: {
            activated: filters.activated,
            includeStatuses: filters.status ? [filters.status] : null,
          },
        }
      : {
          filterFieldName,
          filter: isDate
            ? filters
            : searchItems.filter(
                (item: string | number) => typeof item === 'string' || typeof item === 'number',
              ),
        };

    const sortFieldName = arrangedId === PROJECT_COLUMN.STATUS ? `activated,${arrangedId}` : arrangedId;

    const resultedFilters = {
      arrangeId: arrangedId as PROJECT_COLUMN,
      sorting: {
        sortOrder: sort as TableSortOrder,
        sortFieldName: arrangedId === PROJECT_COLUMN.USED_TEMPLATE ? `${arrangedId}.name` : sortFieldName,
        withSorting: true,
      },
      filtering,
    } as ProjectsFiltersPayload;

    const isFilterChanged =
      isArrayNotEmpty(resultedFilters.filtering.filter) ||
      !isObjectEmpty(resultedFilters.filtering.filter as any);

    if (isFilterChanged) {
      dispatch(setPage(1));
    }

    dispatch(setFilters(resultedFilters));
    setFilterLabel?.(getFilterLabel());
    closeModalAfterApply(arrangedId);
  };

  const removeFilter = async () => {
    dispatch(
      setFilters({
        arrangeId: arrangedId,
        sorting: { ...sortingAndFiltersData.sorting },
        filtering: { filterFieldName: '', filter: [] },
      } as ProjectsFiltersPayload),
    );
    setFilterLabel?.(null);
    clearFormFilters();
  };

  const removeFilterValue = async (value: any) => {
    const parsedItems = getParsedSelectedItems(form);
    const filteredItems = parsedItems.filter(
      (item: { [x: string]: any }) => item[LIST_VALUE_PROP_NAME] !== value,
    );

    const updatedFiltersData: ProjectsFiltersPayload = {
      ...sortingAndFiltersData,
      arrangedId: sortingAndFiltersData.arrangeId,
      filtering: {
        filterFieldName: sortingAndFiltersData.filtering.filterFieldName,
        filter: filteredItems.map((item: { value: string; label: string }) => {
          if (isParsedByValue) {
            return item.value;
          } else {
            return item.label;
          }
        }),
      },
    } as ProjectsFiltersPayload;

    dispatch(setFilters(updatedFiltersData));

    const stringifiedItems = filteredItems.map(JSON.stringify);
    form.setFieldValue(ArrangeFormItem.SELECTED_ITEMS, stringifiedItems);

    const filterLabel = getFilterLabel();
    setFilterLabel?.(filterLabel);

    if (!filterLabel) {
      clearFormFilters();
    }
  };

  return { applyArranges, removeFilter, removeFilterValue };
};
