export const theme = {
  token: {
    borderRadius: 8,
    borderRadiusLG: 8,
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.2)',
    boxShadowSecondary: '0px 4px 24px rgba(0, 0, 0, 0.25)',
    colorPrimary: '#DA1B5E',
    colorText: '#191919',
    colorTextBase: '#191919',
    colorTextSecondary: '#5C5C6E',
    colorTextDisabled: '#9696A2',
    controlHeight: 40,
    fontFamily: 'SimonKucher',
    fontSize: 16,
    fontSizeHeading3: 24,
    lineHeight: 1.2,
  },
};
