import { FormInstance } from 'antd';
import { ArrangeFormItem } from '../models/Arrange';
import { TableSortOrder } from './constants';
import { isLength } from './helpers';

export const LIST_LABEL_PROP_NAME = 'label';
export const LIST_VALUE_PROP_NAME = 'value';

export function clearListFilters(form: FormInstance<unknown>) {
  form.setFieldValue(ArrangeFormItem.SEARCH, '');
  form.setFieldValue(ArrangeFormItem.SELECTED_ITEMS, []);
}

export function clearDateFilters(form: FormInstance<unknown>) {
  form.setFieldValue(ArrangeFormItem.DATES, []);
}

const getSelectedItems = (form: FormInstance<unknown>) => form.getFieldValue(ArrangeFormItem.SELECTED_ITEMS);

export function getParsedSelectedItems(form: FormInstance<unknown>) {
  return (getSelectedItems(form) || []).map(JSON.parse);
}

export function getSelectedItemsLabels(form: FormInstance<unknown>) {
  return getParsedSelectedItems(form).map((item: { [x: string]: any }) => item[LIST_LABEL_PROP_NAME]);
}

export function getSelectedItemsValues(form: FormInstance<unknown>) {
  return getParsedSelectedItems(form).map((item: { [x: string]: any }) => item[LIST_VALUE_PROP_NAME]);
}

export function getArrangesInitialValues(form: FormInstance<unknown>) {
  return {
    [ArrangeFormItem.SORT_ORDER]: form.getFieldValue(ArrangeFormItem.SORT_ORDER) || TableSortOrder.NONE,
  };
}

export function normalizeFilters(filters: { [s: string]: unknown } | ArrayLike<unknown>) {
  return Object.fromEntries(
    Object.entries(filters).filter(([_key, value]) =>
      Array.isArray(value) ? isLength(value) : value !== undefined,
    ),
  );
}

export function createOptionItem(label: string, value: unknown) {
  return {
    value: JSON.stringify({
      [LIST_VALUE_PROP_NAME]: value,
      [LIST_LABEL_PROP_NAME]: label,
    }),
    label,
  };
}

export function formatArrangedData(data: { content: any[] }, apiFieldName: string | number) {
  return data?.content?.map((item) => ({
    value: JSON.stringify({
      [LIST_LABEL_PROP_NAME]: item[apiFieldName],
      [LIST_VALUE_PROP_NAME]: item.id,
    }),
    label: item[apiFieldName],
  }));
}
