import { forwardRef, ReactNode } from 'react';
import cx from 'classnames';
import { InputProps } from 'antd';
import { FormInput } from '../FormInput';
import { FormItem } from '../FormItem';
import { NamePath } from 'antd/es/form/interface';

import styles from './index.module.scss';

type Props = {
  formItemProps: {
    name?: NamePath;
    rules?: unknown;
    hasFeedback?: boolean;
    dependencies?: Array<number | string>;
    className?: string;
    style?: any;
    [x: string]: any;
  };
  size?: InputProps['size'];
  textFieldProps?: any;
  className?: string;
  children?: ReactNode;
};

export const FormSection = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    formItemProps: { hasFeedback = false, ...restFormItemProps },
    textFieldProps,
    size,
    className,
    children,
  } = props;

  return (
    <div ref={ref} className={cx(styles.root, className)}>
      <FormItem {...restFormItemProps} hasFeedback={hasFeedback}>
        {children || <FormInput size={size} {...textFieldProps} />}
      </FormItem>
    </div>
  );
});

FormSection.displayName = 'FormSection';
