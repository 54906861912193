import { ProductCategoryT } from '../../../lib/types/entities';
import { useAppDispatch } from '../../../lib/store/hooks';
import { setCurrentCategory, setModalOpened } from '../../../lib/store/slices/productCategoriesSlice';
import { ModalActions } from '../../../lib/models/Modal';
import { MessageInstance } from 'antd/es/message/interface';
import { useUpdateProductCategoryStatusMutation } from '../../../lib/store/api/productCategories';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

const success = 'pages.products.productCategories.status.success';
const error = 'pages.products.productCategories.status.error';

export const useProductCategoriesActionsRequest = (messageApi: MessageInstance) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id: tenantId } = useParams();

  const [updateProductCategoryStatus] = useUpdateProductCategoryStatusMutation();

  const onUpdateProductCategoryStatus = async (row: ProductCategoryT) => {
    if (!row.activated) {
      dispatch(setCurrentCategory(row));
      dispatch(setModalOpened(ModalActions.ACTIVATE));

      return;
    }

    try {
      await updateProductCategoryStatus({
        categoryId: row.id,
        tenantId: String(tenantId),
      }).unwrap();
      messageApi.success(t(success));
    } catch (err: any) {
      messageApi.error(err?.data?.message || t(error));
    }
  };

  const onDeleteCategory = (productCategory: ProductCategoryT) => {
    dispatch(setCurrentCategory(productCategory));
    dispatch(setModalOpened(ModalActions.DELETE));
  };

  return { onUpdateProductCategoryStatus, onDeleteCategory };
};
