import { FC, useEffect, useMemo } from 'react';
import { AppModal } from '../../../../components/AppModal';
import { useTranslation } from 'react-i18next';
import { Form, message, MessageArgsProps } from 'antd';
import { useResetFormOnCloseModal } from '../../../../lib/hooks/useResetFormOnCloseModal';
import styles from './index.module.scss';
import { FullNameFormItem } from '../FormItems/FullNameFormItem';
import { UsernameFormItem } from '../FormItems/UsernameFormItem';
import { EmailFormItem } from '../FormItems/EmailFormItem';
import { RightsFormItem } from '../FormItems/RightsFormItem';
import { LanguagesFormItem } from '../FormItems/LanguagesFormItem';
import { TenantsFormItem } from '../FormItems/TenantsFormItem';
import { EditUserPayload, useUpdateUserMutation } from '../../../../lib/store/api/usersManagement';
import { UserDataT } from '../../../../lib/types/auth';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { ModalActions } from '../../../../lib/models/Modal';
import { setModalClosed } from '../../../../lib/store/slices/userManagementSlice';
import { transformUserManagementFormValuesToServerPayload } from '../../helpers/transformUserManagementFormValuesToServerPayload';
import { transformFromServerToFormValues } from '../../helpers/transformFromServerToFormValues';
import { AddUserFormValue } from '../CreateUserModal';
import { useUserForms } from '../../hooks/useUserForms';
import { IncludeFutureTenants } from '../IncludeFutureTenants';

const MESSAGE_SHOWING_TIME = 3; // seconds

const userManagement = 'pages.userManagement';

export const EditUserModal: FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useAppDispatch();

  const { onRightsChange, tenantFormItemProps, tenantTextFieldProps } = useUserForms(form);

  const [updateUser, { isLoading }] = useUpdateUserMutation();

  const isModalOpen = useAppSelector((state) => state.userManagement.isModalOpen);

  const onClose = () => {
    dispatch(setModalClosed());
  };

  const modalType = useAppSelector((state) => state.userManagement.modalType);
  const isOpen = isModalOpen && modalType === ModalActions.EDIT;
  useResetFormOnCloseModal({ form, isOpen });

  const selectedUser = useAppSelector((state) => state.userManagement.selectedUser);

  const initialValues = useMemo(() => {
    if (selectedUser) {
      return transformFromServerToFormValues(selectedUser as UserDataT);
    } else {
      return {};
    }
  }, [selectedUser]);

  const onConfirm = () => {
    form.submit();
  };

  useEffect(() => {
    setTimeout(() => {
      form.setFieldsValue(initialValues);
    });
  }, [form, initialValues]);

  const onFinish = async (values: AddUserFormValue) => {
    const sendData: EditUserPayload = {
      id: selectedUser?.id as number,
      ...transformUserManagementFormValuesToServerPayload(values),
    };

    const messageConfig = {} as MessageArgsProps;

    try {
      await updateUser(sendData).unwrap();
      messageConfig.content = t(`${userManagement}.success.updateUser`);
      messageConfig.type = 'success';
      onClose();
    } catch (err: any) {
      messageConfig.content = err?.data?.message || t(`${userManagement}.errors.updateUser`);
      messageConfig.type = 'error';
    } finally {
      messageApi.open({
        duration: MESSAGE_SHOWING_TIME,
        ...messageConfig,
      } as MessageArgsProps);
    }
  };

  return (
    <AppModal
      open={isOpen}
      onCancel={onClose}
      onOk={onConfirm}
      getContainer={false}
      titleText={t(`${userManagement}.modals.editUserTitle`) + ' ' + selectedUser?.login}
      isOkLoading={isLoading}
    >
      {contextHolder}
      {isOpen && (
        <Form initialValues={initialValues} className={styles.form} form={form} onFinish={onFinish}>
          {
            (() => {
              return (
                <>
                  <FullNameFormItem form={form} />
                  <UsernameFormItem disabled={true} />
                  <EmailFormItem disabled={true} />
                  <RightsFormItem onChange={onRightsChange} />
                  <TenantsFormItem
                    tenantFormItemProps={tenantFormItemProps}
                    tenantTextFieldProps={tenantTextFieldProps}
                  />
                  <IncludeFutureTenants />
                  <LanguagesFormItem />
                </>
              );
            }) as any
          }
        </Form>
      )}
    </AppModal>
  );
};
