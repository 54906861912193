import { FC, useMemo } from 'react';
import { Status, StatusType } from '../../../../features/table/components/Status';
import { useTranslation } from 'react-i18next';
import { RuleStatusT } from '../../../../lib/types/ruleBuilder';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

type Props = {
  status: RuleStatusT;
};

const ruleStatus = 'pages.ruleBuilder.ruleStatus';

export const RuleStatuses: FC<Props> = ({ status }) => {
  const { t } = useTranslation();

  const node = useMemo(() => {
    switch (status) {
      case RuleStatusT.COMPLETED:
        return <Status textValue={t(`${ruleStatus}.completed`)} type={StatusType.GREEN} />;
      case RuleStatusT.STARTING:
        return <Status textValue={t(`${ruleStatus}.starting`)} type={StatusType.ORANGE} />;
      case RuleStatusT.ERROR:
        return <Status textValue={t(`${ruleStatus}.error`)} type={StatusType.GRAY} />;
      case RuleStatusT.LOADING:
        return <Spin size="small" indicator={<LoadingOutlined spin />} />;

      default:
        return <span></span>;
    }
  }, [status, t]);

  return <div>{node}</div>;
};
