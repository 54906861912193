import { useProductSearchDataColumn } from './useProductSearchDataColumn';
import { PRODUCTS_COLUMNS } from '../helpers/consts';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { ProductT } from '../../../lib/types/product';

const { Text } = Typography;

const demandArea = 'pages.products.products.columns.demandArea';

export const useProductDemandAreaColumn = () => {
  const { t } = useTranslation();

  return useProductSearchDataColumn({
    title: t(demandArea),
    arrangedId: PRODUCTS_COLUMNS.DEMAND_AREA,
    isParsedByValue: true,
    filterFieldName: 'includeByDemandAreas',
    columnProps: {
      render: (_: never, row: ProductT) => {
        return <Text>{row.category?.demandArea?.name}</Text>;
      },
    },
  });
};
