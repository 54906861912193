import { useTranslation } from 'react-i18next';
import { BONUS_CATALOG_COLUMN } from '../../../../lib/types/bonusCatalog';
import { useBonusCatalogDataColumn } from './useBonusCatalogDataColumn';

const bonusDescription = 'pages.bonusCatalogTab.columns.bonusDescription';

export const useBonusDescriptionColumn = () => {
  const { t } = useTranslation();

  return useBonusCatalogDataColumn({
    title: t(bonusDescription),
    arrangedId: BONUS_CATALOG_COLUMN.BONUS_DESCRIPTION,
    columnProps: {
      width: 500,
    },
  });
};
