import { FC } from 'react';
import { AppProvider } from './components/AppProvider';
import { AppRouter } from './components/AppRouter';

import 'antd/dist/reset.css';
import './styles/index.scss';
import { ScrollbarWrapper } from './components/ScrollbarWrapper';

export const App: FC = () => {
  return (
    <ScrollbarWrapper>
      <AppProvider>
        <AppRouter />
      </AppProvider>
    </ScrollbarWrapper>
  );
};
