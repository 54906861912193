import { FC } from 'react';
import cx from 'classnames';
import { ReactComponent as IconLock } from '../../../../assets/icons/lock.svg';
import { ReactComponent as IconCheck } from '../../../../assets/icons/check-filled.svg';
import { IconT } from '../../../designSettings/types';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import { IconComponent } from '../../../../components/IconComponent';

import styles from './index.module.scss';

type Props = {
  active: boolean;
  status: string;
  subLevels: any[];
  levels: any[];
  userPoints: number;
  points: number;
  maxPoints: number;
  icon: IconT | undefined;
  isWishlist?: boolean;
  color?: string;
  wishlistPoints?: number;
};

export const SmallStatusLevelCard: FC<Props> = ({
  active,
  status,
  subLevels,
  points,
  icon,
  userPoints,
  isWishlist,
  color,
  maxPoints,
  wishlistPoints = 0,
}) => {
  const { isLargerMobile, isLargerDesktop } = useContainerQuery();
  const isDesktop = isWishlist ? isLargerMobile : isLargerDesktop;
  const userPointsWithWishlist =
    wishlistPoints + userPoints >= maxPoints ? maxPoints : wishlistPoints + userPoints;

  return (
    <>
      {isDesktop ? (
        <div className={cx(styles.wrapper, { [styles.active]: active })}>
          <div className={styles.header}>
            {active ? (
              <IconCheck className={styles.icon} style={{ color }} />
            ) : (
              <IconLock className={styles.icon} />
            )}
            <EndUserTypography
              type="headlineEight"
              component="Text"
              className={cx(styles.title, { [styles.titleActive]: active })}
            >
              {status}
            </EndUserTypography>
          </div>
          <div className={styles.sublevels}>
            {subLevels?.map((item: any, index: any) => {
              const isWished = userPointsWithWishlist > item.points && userPoints <= item.points;

              return (
                <div
                  key={index}
                  className={cx(styles.sublevel, {
                    [styles.coveredSublevel]: active && userPoints > item.points,
                    [styles.wishedSublevel]: isWishlist && isWished,
                  })}
                  style={{ color }}
                />
              );
            })}
          </div>
          {points !== 0 && (
            <EndUserTypography type="body" component="Text" className={styles.desktopPoints}>
              {points}
              <IconComponent
                iconData={icon}
                color="currentColor"
                style={{
                  width: '10px',
                  height: '14px',
                  display: 'inline-block',
                }}
              />
            </EndUserTypography>
          )}
        </div>
      ) : (
        <div className={styles.mobileWrapper}>
          <div>
            <div className={cx(styles.sublevelsWrapper, { [styles.active]: active })}>
              <div className={styles.header}>
                <EndUserTypography type="headlineSixth" component="Text" className={styles.title}>
                  {status}
                </EndUserTypography>
                <EndUserTypography type="headlineSeventh" component="Text" className={styles.mobilePoints}>
                  {points}
                  <IconComponent
                    iconData={icon}
                    color="currentColor"
                    style={{ width: '10px', height: '14px', display: 'inline-block' }}
                  />
                </EndUserTypography>
              </div>

              <div className={styles.mobileSublevels}>
                {subLevels?.map((item: any, index: any) => {
                  const isWished = userPointsWithWishlist > item.points && userPoints <= item.points;

                  return (
                    <div
                      key={index}
                      className={cx(styles.mobileSublevel, {
                        [styles.coveredSublevel]: active && userPoints > item.points,
                        [styles.wishedSublevel]: isWishlist && isWished,
                      })}
                      style={{ color }}
                    />
                  );
                })}
              </div>
            </div>

            {active ? (
              <IconCheck className={styles.icon} style={{ color }} />
            ) : (
              <IconLock className={styles.icon} />
            )}
          </div>
        </div>
      )}
    </>
  );
};
