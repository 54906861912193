import { ServerFontItem } from '../types/colors&fonts';

const formats = {
  ttf: 'truetype',
  otf: 'opentype',
  eot: 'embedded-opentype',
  woff: 'woff',
  woff2: 'woff2',
};

const loadFont = async (fontName: string, src: string) => {
  try {
    const extensions = src?.split('.');
    const fileExtension = extensions[extensions.length - 1];
    const format = formats[fileExtension as keyof typeof formats];
    const formattedUrl = fileExtension === 'eot' ? `${src}?#iefix` : src;
    const font = new FontFace(`${fontName.split('.')[0]}`, `url(${formattedUrl}) format(${format})`);
    document.fonts.add(font);
    await font.load();
  } catch (error) {
    console.log(error);
  }
};

export const loadFonts = (fonts: ServerFontItem[]) => {
  for (const fontObj of fonts) {
    loadFont(fontObj.fontName, fontObj.fontUrl);
  }
};
