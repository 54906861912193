import { memo } from 'react';
import { Typography } from 'antd';
import { UploadMediaResponseT } from '../../../lib/types/media';

import styles from './index.module.scss';

const { Text } = Typography;

type Props = {
  media: UploadMediaResponseT;
  isSelected: boolean;
  onSelect: (arg: UploadMediaResponseT) => void;
};

export const ImageOption = memo(({ isSelected, onSelect, media }: Props) => {
  return (
    <div role="presentation" className={styles.wrapper} onClick={() => onSelect(media)}>
      {/* {isMultiSelection && (
          <input
            className={styles.checkbox}
            type="checkbox"
            checked={isSelected}
            onChange={() => onSelect(media)}
          />
        )} */}
      <div className={styles.picture} style={{ backgroundImage: `url('${media?.s3Url}')` }} />
      <Text ellipsis className={`${styles.text} ${isSelected ? 'primary_color' : ''}`}>
        {media?.originalFileName}
      </Text>
    </div>
  );
});

ImageOption.displayName = 'ImageOption';
