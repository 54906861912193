import { Visibility } from './product';

export type UploadResponseT = {
  id: number;
  s3Url: string;
  targetType: string;
  targetId: string;
  originalFileName: string;
  svgContent: null | any;
  cdnUrl: string;
};

export type TemplateT = {
  createdBy?: string;
  createdDate?: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  id: number;
  name: string;
};

export enum SETTINGS_NAME {
  generalSettings = 'GENERAL_SETTINGS',
}

export type SaveGlobalSettingsPayloadT = {
  projectId: number;
  name: SETTINGS_NAME;
  settings: string;
  tenantId?: string;
};

export type GetGlobalSettingsPayloadT = {
  projectId: number;
  settingsName?: SETTINGS_NAME;
  tenantId: string;
};

export type GlobalSettingsResponseT = SaveGlobalSettingsPayloadT[];

export enum BONUS_PROLONGATION {
  YEARLY = 'YEARLY',
  HALF_YEARLY = 'HALF_YEARLY',
  QUARTERLY = 'QUARTERLY',
  MONTHLY = 'MONTHLY',
}

export type ProjectT = {
  createdBy?: string;
  createdDate: string;
  lastModifiedBy?: string;
  lastModifiedDate?: string;
  id: number;
  name: string;
  contractStart?: string;
  contractEnd?: string;
  contactSKP?: string;
  comment?: string;
  template: TemplateT;
  status: string;
  editing: boolean;
  activated: boolean;
  domain: string;
  subdomain: string;
  publishingDate: string;
  url: string;
  pointUnits: string;
  pointsIcon: {
    id: number;
    s3Url: string;
    originalFileName: string;
  };
  maxPoints: string;
  bonusCycle: BONUS_PROLONGATION | null;
  dueDate: string | null;
};

// Product categories

type DemandArea = {
  id: number;
  name: string;
};

type Product = {
  id: number;
  name: string;
  activated: boolean;
  new: boolean;
};

export enum SelectionType {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

export type ProductCategoryT = {
  activated: boolean;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  id: number;
  name: string;
  demandArea: DemandArea;
  demandAreaId: number;
  priority: { number: number };
  products: Product[];
  description: string;
  selectionType: SelectionType;
  icon: UploadResponseT;
  projectId: number;
  visibility: Visibility;
  finderEnabled: boolean;
  finderUrl: string;
  finderButtonName: string;
};

export type FinderT = {
  isOpen?: boolean;
  finderUrl?: string;
  categoryId?: number;
  recommendForAllCategories?: boolean;
};

export type FinderProduct = {
  hvmProductId: string;
  score: number;
  recommendedCategoryId?: number | null;
};
