import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormItem } from '../../../../components/FormItem';
import { FormItemName } from '../../../../lib/models/Form';
import { FormInput } from '../../../../components/FormInput';

const label = 'pages.products.productCategories.formItems.finderButtonName.label';

export const FinderButtonNameFormItem: FC = () => {
  const { t } = useTranslation();

  return (
    <FormItem name={FormItemName.FINDER_BUTTON_NAME} hasFeedback rules={[{ max: 100 }]}>
      <FormInput label={t(label)} />
    </FormItem>
  );
};
