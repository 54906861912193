import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormItem } from '../../../../components/FormItem';
import { FormItemName } from '../../../../lib/models/Form';
import { FormInput } from '../../../../components/FormInput';

import styles from './index.module.scss';

import { ReactComponent as LinkIcon } from './../../../../assets/icons/link-icon-gray.svg';

const legalNotice = 'unique.formItems.legalNotice';

const url = 'unique.formItems.url';

export const ClientLegalNoticeUrlFormItem: FC = () => {
  const { t } = useTranslation();

  return (
    <FormItem
      name={FormItemName.LEGAL_NOTICE}
      hasFeedback={true}
      rules={[
        {
          required: false,
        },
        {
          type: 'url',
          message: t(`${url}.errors.invalid`),
        },
        { max: 200 },
      ]}
    >
      <FormInput icon={<LinkIcon />} className={styles.inputUrl} label={t(`${legalNotice}.label`)} />
    </FormItem>
  );
};
