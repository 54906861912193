import { useEffect, FC } from 'react';
import { Form, message, Card as AppCard } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import { FormItemName } from '../../../../lib/models/Form';
import { AppTitle } from '../AppTitle';
import { UsernameFormItem } from '../UsernameFormItem';
import { PasswordFormItem } from '../PasswordFormItem';
import { Button as LogInButton } from '../../../../components/Button';

import styles from './index.module.scss';
import { useLogInMutation, useLazyGetUserDataQuery } from '../../../../lib/store/api/auth';
import { LogInPayloadT } from '../../../../lib/types/auth';
import { ButtonLink } from '../../../../components/ButtonLink';
import { useNavigate } from 'react-router-dom';
import { ROUTER_PATHS } from '../../../../lib/utils/router-paths';

export const LogInCard: FC = () => {
  const [login, { isLoading, error, isError, reset }] = useLogInMutation();
  const [getUserData, { isLoading: isUserDataLoading }] = useLazyGetUserDataQuery();

  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const userName = Form.useWatch('username', form);
  const password = Form.useWatch('password', form);

  const title = 'pages.logIn.title';

  const errors = form.getFieldsError();

  const isBothFieldsTouched =
    form.isFieldTouched(FormItemName.PASSWORD) && form.isFieldTouched(FormItemName.USERNAME);

  const isLoginButtonAvailable =
    isBothFieldsTouched && errors[0].errors.length === 0 && errors[1].errors.length === 0;

  const onClickForgotPassword = () => {
    navigate(ROUTER_PATHS.getResetPasswordLink, { replace: true });
  };

  const onFinish = async (formValues: Record<string, string>) => {
    const formattedFormValues = {
      [FormItemName.USERNAME]: formValues[FormItemName.USERNAME].trim(),
      [FormItemName.PASSWORD]: formValues[FormItemName.PASSWORD].trim(),
    } as LogInPayloadT;
    const data = await login(formattedFormValues);
    if ('data' in data) {
      await getUserData();
    }
  };

  useEffect(() => {
    if (error && !isLoading && 'status' in error) {
      const errors = 'pages.logIn.errors';

      const errorMessage = `${errors}.${error?.status}` || `${errors}.internalError`;

      messageApi.error(t(errorMessage));
    }
  }, [error, isLoading, messageApi, t]);

  useEffect(() => {
    reset();
    //eslint-disable-next-line
  }, [userName, password]);

  return (
    <AppCard bodyStyle={{ padding: 0 }} className={styles.wrapper}>
      <AppTitle className={styles.title}>
        <Trans
          className={styles.title}
          i18nKey={title}
          components={{
            regularText: <span className={styles.regularTitle} />,
          }}
        />
      </AppTitle>
      {contextHolder}

      <Form form={form} onFinish={onFinish} feedbackIcons={undefined}>
        {
          (() => {
            return (
              <>
                <UsernameFormItem backendError={isError} />
                <PasswordFormItem backendError={isError} />
                <LogInButton
                  type="primary"
                  style={{ marginTop: 35 }}
                  htmlType="submit"
                  loading={isLoading || isUserDataLoading}
                  disabled={!isLoginButtonAvailable}
                  id="loginButton"
                >
                  {t('pages.logIn.actionButton')}
                </LogInButton>
              </>
            );
          }) as any
        }
      </Form>
      <ButtonLink containerStyle={{ marginTop: 16 }} onClick={onClickForgotPassword} type="link">
        {t('pages.logIn.secondaryButton')}
      </ButtonLink>
    </AppCard>
  );
};
