import { Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../lib/store/hooks';
import { ModalActions } from '../../../../../lib/models/Modal';
import { useResetFormOnCloseModal } from '../../../../../lib/hooks/useResetFormOnCloseModal';
import { setModalClosed } from '../../../../../lib/store/slices/demandAreasSlice';
import { AppModal } from '../../../../../components/AppModal';
import styles from '../../DemandAreas/index.module.scss';
import { NameFormItem } from '../FormItems/NameFormItem';
import { PositionFormItem } from '../FormItems/PositionFormItem';
import { ColorFormItem } from '../FormItems/ColorFormItem';
import { useEffect, useMemo } from 'react';
import { CategoriesFormItem } from '../FormItems/CategoriesFormItem';
import { CreateDemandAreaPayload } from '../../../../../lib/types/demandArea';
import { CreateDemandAreaValues } from '../CreateDemandAreaModal';
import { useUpdateDemandAreaMutation } from '../../../../../lib/store/api/demandArea';
import { IconFormItem } from '../FormItems/IconFormItem';
import { useGetIconOptions } from '../../../../productCategories/hooks/useGetIconOptions';
import {
  transformDemandAreaFromServerToInitialData,
  transformDemandAreaValuesToSendData,
} from '../../../helpers';
import { DeactivatedFormItem } from '../FormItems/DeactivatedFormItem';
import { FormItemName } from '../../../../../lib/models/Form';

const demandAreaTranslation = 'pages.products.demandArea';

type Props = {
  tenantId: string;
  modelId: string;
};

export const EditDemandAreaModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const { isModalOpen, modalType, selectedDemandArea } = useAppSelector(({ demandAreas }) => demandAreas);
  const isActivatedModal = modalType === ModalActions.ACTIVATE;
  const isOpened = isModalOpen && (modalType === ModalActions.EDIT || isActivatedModal);
  const { options, iconsList } = useGetIconOptions();

  const [updateDemandArea, { isLoading }] = useUpdateDemandAreaMutation();
  const isActivated = Form.useWatch(FormItemName.ACTIVATED, form);
  useResetFormOnCloseModal({ form, isOpen: isOpened });

  const sendUpdatedDemandArea = async (
    values: CreateDemandAreaValues,
    successText: string,
    errorText: string,
  ) => {
    const sendData: CreateDemandAreaPayload & { id?: number } = transformDemandAreaValuesToSendData(
      values,
      {
        ...props,
        demandAreaId: selectedDemandArea?.id,
      },
      values.activated,
      iconsList,
    );

    try {
      await updateDemandArea(sendData).unwrap();
      messageApi.success(successText);
      onClose();
    } catch (err: any) {
      messageApi.error(err?.data?.message || errorText);
    }
  };

  const onFinish = async (values: CreateDemandAreaValues) => {
    if (!isActivatedModal) {
      await sendUpdatedDemandArea(
        values,
        t(`${demandAreaTranslation}.success.editDemandArea`),
        t(`${demandAreaTranslation}.errors.editDemandArea`),
      );
    }

    if (isActivatedModal) {
      await sendUpdatedDemandArea(
        values,
        t(`${demandAreaTranslation}.success.activateStatus`),
        t(`${demandAreaTranslation}.errors.activateStatus`),
      );
    }
  };

  const initialFormValues = useMemo(() => {
    if (selectedDemandArea) {
      return transformDemandAreaFromServerToInitialData(selectedDemandArea);
    }
    return {};
  }, [selectedDemandArea]);

  useEffect(() => {
    form.setFieldsValue(initialFormValues);
    const iconId = (initialFormValues as any)?.icon?.id;
    if (iconId) {
      form.setFieldValue(FormItemName.ICON, { value: iconId });
    }
  }, [form, initialFormValues]);

  const onClose = () => {
    dispatch(setModalClosed());
  };

  const onConfirm = () => {
    form.submit();
  };

  return (
    <AppModal
      className={styles.modal}
      open={isOpened}
      onCancel={onClose}
      onOk={onConfirm}
      getContainer={false}
      titleText={
        isActivatedModal
          ? t(`${demandAreaTranslation}.activateStatusTitle`)
          : t(`${demandAreaTranslation}.editDemandArea`)
      }
      subTitle={isActivatedModal ? t(`${demandAreaTranslation}.activateStatusSubTitle`) : ''}
      isOkLoading={isLoading}
    >
      {contextHolder}
      {isOpened && (
        <Form initialValues={initialFormValues} className={styles.form} form={form} onFinish={onFinish}>
          <NameFormItem />
          <CategoriesFormItem />
          <PositionFormItem {...props} isActivated={isActivated} />
          <DeactivatedFormItem />
          <ColorFormItem {...props} />
          <IconFormItem options={options} />
        </Form>
      )}
    </AppModal>
  );
};
