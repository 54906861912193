import { BonusWithCoastT } from '../types';
import { StatusLevelT, SubLevel } from '../../../lib/types/statusLevel';

export const getBonusesList = (statusLevels: StatusLevelT[]): BonusWithCoastT[] => {
  return statusLevels.reduce((acc: BonusWithCoastT[], item: StatusLevelT) => {
    const itemBonuses = item.subLevels.reduce((acc: BonusWithCoastT[], subLevel: SubLevel) => {
      acc.push(
        ...subLevel.bonuses.map((bonus) => ({
          ...bonus,
          points: subLevel.points,
          subLevelName: subLevel.name,
        })),
      );

      return acc;
    }, []);

    acc.push(...itemBonuses);

    return acc;
  }, []);
};
