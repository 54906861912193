import { FC, useEffect, useState } from 'react';
import { PlatformHeader } from './PlatformHeader';
import { Outlet } from 'react-router-dom';
import { DeviceType } from './types';
import { RadioChangeEvent } from 'antd';
import cx from 'classnames';
import { useContainerQuery } from 'react-container-query';
import { CONTAINER_QUERIES } from '../../../lib/utils/constants';
import { Header } from '../EndUserFlowLayout/Header';
import { Footer } from '../EndUserFlowLayout/Footer';
import { DesignSettingsDataT } from '../../../modules/designSettings/types';
import { useGetEndUserDataForPreview } from '../../../lib/hooks/endUserHooks/useGetEndUserDataForPreview';
import { useAppDispatch } from '../../../lib/store/hooks';
import { setContainerQueries } from '../../../lib/store/slices/containerQueriesSlice';
import { useCheckStatusBarVisibility } from '../../../lib/hooks/endUserHooks/useCheckStatusBarVisibility';
import { SmallStatusLevelSection } from '../../../modules/endUserStatusLevel/components/SmallStatusLevelSection';

import styles from './index.module.scss';

export const PreviewLayout: FC = () => {
  const { parsedData, isLoading } = useGetEndUserDataForPreview();
  const [deviceTypeValue, setDeviceTypeValue] = useState(DeviceType.DESKTOP);
  const { isStatusBarHidden } = useCheckStatusBarVisibility();
  const [params, containerRef] = useContainerQuery(CONTAINER_QUERIES, {});
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setContainerQueries(params));
  }, [dispatch, params]);

  const handleChangeDeviceType = ({ target: { value } }: RadioChangeEvent) => {
    setDeviceTypeValue(value);
  };
  const statusLevelSection = !isStatusBarHidden && (
    <div style={{ position: 'sticky', bottom: 0 }}>
      <SmallStatusLevelSection />
    </div>
  );

  if (isLoading) {
    return null;
  }

  return (
    <>
      <PlatformHeader deviceTypeValue={deviceTypeValue} onChangeDeviceType={handleChangeDeviceType} />
      <div
        ref={containerRef}
        className={cx(styles.wrapper, {
          [styles.tablet]: deviceTypeValue === DeviceType.TABLET,
          [styles.mobile]: deviceTypeValue === DeviceType.MOBILE,
        })}
        id="container"
      >
        <Header headerConfig={parsedData?.headerConfig as DesignSettingsDataT['headerConfig']} />
        <main className={styles.main}>
          <Outlet />
        </main>
        <Footer footerConfig={parsedData?.footerConfig as DesignSettingsDataT['footerConfig']} />
        {statusLevelSection}
      </div>
    </>
  );
};
