import { FC } from 'react';
import { Popover } from 'antd';
import { ReactComponent as CrossIcon } from '../../../assets/icons/CrossIcon.svg';
import { LIST_LABEL_PROP_NAME, LIST_VALUE_PROP_NAME } from '../../../lib/utils/arranges-helpers';
import { MainLabel } from './MainLabel';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';

const MIN_MULTIPLE_VALUES_AMOUNT = 2;

const arranges = 'shared.arranges';

type Props = {
  filterLabel: string;
  getParsedItems: any;
  removeFilter: () => void;
  removeFilterValue: (val: any) => void;
};

export const FilterValuesPopup: FC<Props> = ({
  removeFilter,
  removeFilterValue,
  getParsedItems,
  filterLabel,
}) => {
  const { t } = useTranslation();

  const parsedItems = getParsedItems();
  const valuesAmount = parsedItems?.length;
  const mainLabelText =
    valuesAmount >= MIN_MULTIPLE_VALUES_AMOUNT
      ? t(`${arranges}.labels.moreValues`, { amount: valuesAmount })
      : filterLabel;

  const content = (
    <ul>
      {parsedItems.map((item: { [x: string]: any }) => {
        const value = item[LIST_VALUE_PROP_NAME];
        const label = item[LIST_LABEL_PROP_NAME];

        return (
          <li key={value}>
            <span>{label}</span>

            <button className={styles.btn} onClick={() => removeFilterValue(value)}>
              <CrossIcon />
            </button>
          </li>
        );
      })}
    </ul>
  );

  return (
    <Popover
      title={t(`${arranges}.filterValues.clearAppliedFilters`)}
      content={content}
      placement="rightTop"
      overlayClassName={styles.filterValuesPopup}
    >
      <>
        <MainLabel removeFilter={removeFilter} textValue={mainLabelText} />
      </>
    </Popover>
  );
};
