import React, { FC, useEffect, useMemo } from 'react';
import { Form } from 'antd';
import styles from './index.module.scss';
import { FormItem } from '../../../../components/FormItem';
import { DatePicker } from '../../../../components/DatePicker';
import { AppSelect } from '../../../../components/AppSelect';
import { useUpdateProjectPointsLimitMutation } from '../../../../lib/store/api/project';
import { useGetProject } from '../../../../lib/hooks/useGetProjects';
import { FormItemName } from '../../../../lib/models/Form';
import { useTranslation } from 'react-i18next';
import { appDayJs } from '../../../../lib/models/Time';
import { geStartOfTheUTCDayByDate } from '../../../../features/table/components/popups/ArrangeDatePopup';
import { CYCLE_OPTIONS_LABELS, CycleOptionT } from '../../types';
import { BONUS_PROLONGATION } from '../../../../lib/types/entities';
import { useAppSelector } from '../../../../lib/store/hooks';

type Props = {
  tenantId: string | undefined;
};

const bonusCatalogTab = 'pages.bonusCatalogTab';

export const BonusCatalogDateSetup: FC<Props> = ({ tenantId }) => {
  const { t } = useTranslation();
  const { appLang } = useAppSelector(({ auth }) => auth);

  const { data } = useGetProject();

  const { dueDate, bonusCycle, id, name, template } = data || {};

  const [form] = Form.useForm();

  const date = Form.useWatch(FormItemName.DUE_DATE, form);

  const [updateProject] = useUpdateProjectPointsLimitMutation();

  useEffect(() => {
    if (dueDate) {
      form.setFieldValue(FormItemName.DUE_DATE, dueDate ? new Date(dueDate) : null);
    }
    if (bonusCycle) {
      form.setFieldValue(FormItemName.CYCLE, bonusCycle ? { id: bonusCycle, value: bonusCycle } : null);
    }
  }, [bonusCycle, dueDate, form]);

  const onDateChange = (date: Date) => {
    form.setFieldValue(FormItemName.DUE_DATE, date);

    updateProject({
      id,
      tenantId: String(tenantId),
      name,
      template,
      dueDate: date ? appDayJs(geStartOfTheUTCDayByDate(date)).toISOString() : null,
    });
  };

  const onCycleChange = ({ value }: CycleOptionT) => {
    form.setFieldValue(FormItemName.CYCLE, value);

    updateProject({
      id,
      tenantId: String(tenantId),
      name,
      template,
      bonusCycle: BONUS_PROLONGATION[value],
    });
  };

  const cycleOptions = useMemo(() => {
    return Object.values(BONUS_PROLONGATION).map((option) => {
      return {
        title: t(`${bonusCatalogTab}.formItems.cycleOptions.${CYCLE_OPTIONS_LABELS[option]}`),
        value: option,
      };
    });
  }, [t]);

  const minDate = useMemo(() => new Date(), []);

  return (
    <Form form={form} className={styles.form}>
      <FormItem hasFeedback name={FormItemName.DUE_DATE} className={styles.datePickerFormItem}>
        <DatePicker
          isClearable
          size={'small'}
          minDate={minDate}
          selected={date}
          locale={appLang}
          onChange={onDateChange}
          style={{ overflow: 'visible', height: '100%' }}
          placeholderText={t(`${bonusCatalogTab}.formItems.${FormItemName.DUE_DATE}`)}
          hasFeedback={false}
        />
      </FormItem>
      <FormItem name={FormItemName.CYCLE}>
        <AppSelect
          placeholder={t(`${bonusCatalogTab}.formItems.${FormItemName.CYCLE}`)}
          className={styles.select}
          size="small"
          options={cycleOptions}
          onChange={onCycleChange}
        />
      </FormItem>
    </Form>
  );
};
