import { useTranslation } from 'react-i18next';
import { RULES_COLUMN, RuleT } from '../../../lib/types/ruleBuilder';
import { useRulesDataColumn } from './useRulesDataColumn';
import { Typography } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { setCurrentRule } from '../../../lib/store/slices/ruleBuilder';
import { QueryParams, Tabs } from '../../../lib/types/queryParams';
import { useAppDispatch } from '../../../lib/store/hooks';

const { Text } = Typography;

const name = 'pages.ruleBuilder.ruleColumns.name';

export const useRulesNameColumn = () => {
  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const onClickText = (rule: RuleT) => {
    dispatch(setCurrentRule(rule));
    setSearchParams({ [QueryParams.TAB]: Tabs.RULE_CONFIGURATOR });
  };

  return useRulesDataColumn({
    title: t(name),
    arrangedId: RULES_COLUMN.NAME,
    columnProps: {
      width: 250,
      render: (name: string, row: RuleT) => {
        return (
          <Text style={{ cursor: 'pointer' }} onClick={() => onClickText(row)} className="primary_color">
            {name}
          </Text>
        );
      },
    },
  });
};
