import { WishlistPageT } from '../../lib/types/sections';
import { FC } from 'react';
import { useAppSelector } from '../../lib/store/hooks';
import { useContainerQuery } from '../../lib/hooks/endUserHooks/useContainerQuery';
import { EndUserWishlistTilesDesktop } from './components/EndUserWishlistTilesDesktop';
import { EndUserWishlistTilesMobile } from './components/EndUserWishlistTilesMobile';

type Props = {
  content?: WishlistPageT;
};
export const EndUserWishlistTiles: FC<Props> = ({ content }) => {
  const { wishlistProducts, demandArea } = useAppSelector(({ endUser }) => endUser);
  const { isMobile } = useContainerQuery();

  const demandAreas = demandArea?.listDemandArea || [];

  return (
    <>
      {isMobile ? (
        <EndUserWishlistTilesMobile demandAreas={demandAreas} content={content} />
      ) : (
        <EndUserWishlistTilesDesktop
          content={content}
          wishlistData={wishlistProducts}
          demandAreas={demandAreas}
        />
      )}
    </>
  );
};
