import React, { FC } from 'react';
import { Scrollbar } from 'react-scrollbars-custom';

import './index.scss';

type Props = {
  children: React.ReactNode;
};

export const ScrollbarWrapper: FC<Props> = ({ children }) => {
  return (
    <Scrollbar
      style={{ width: '100vw', height: '100vh' }}
      wrapperProps={{
        //eslint-disable-next-line
        renderer: (props: any) => {
          const { elementRef, ...restProps } = props;
          return <div {...restProps} ref={elementRef} className="MyAwesomeScrollbarsWrapper" />;
        },
      }}
      trackYProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props;
          return <div {...restProps} ref={elementRef} className="trackY" />;
        },
      }}
      thumbYProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props;
          return <div {...restProps} ref={elementRef} className="tHuMbY" />;
        },
      }}
    >
      {children}
    </Scrollbar>
  );
};
