import { useAppDispatch } from '../../../lib/store/hooks';

import { ModalActions } from '../../../lib/models/Modal';
import { MessageInstance } from 'antd/es/message/interface';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { DemandArea } from '../../../lib/types/demandArea';
import { setCurrentDemandArea, setModalOpened } from '../../../lib/store/slices/demandAreasSlice';
import { useDeactivateDemandAreaStatusMutation } from '../../../lib/store/api/demandArea';

const success = 'pages.products.demandArea.success';
const errors = 'pages.products.demandArea.errors';

export const useDemandAreaActionsRequest = (messageApi: MessageInstance) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id: tenantId } = useParams();

  const [updateProductCategoryStatus] = useDeactivateDemandAreaStatusMutation();

  const onUpdateDemandAreaStatus = async (row: DemandArea) => {
    if (!row.activated) {
      dispatch(setCurrentDemandArea(row));
      dispatch(setModalOpened(ModalActions.ACTIVATE));

      return;
    }

    try {
      await updateProductCategoryStatus({
        demandAreaId: row.id,
        tenantId: String(tenantId),
      }).unwrap();
      messageApi.success(t(`${success}.deactivateStatus`));
    } catch (err: any) {
      messageApi.error(err?.data?.message || t(`${errors}.deactivateStatus`));
    }
  };

  const onDeleteDemandArea = (demandArea: DemandArea) => {
    dispatch(setCurrentDemandArea(demandArea));
    dispatch(setModalOpened(ModalActions.DELETE));
  };

  return { onUpdateDemandAreaStatus, onDeleteDemandArea };
};
