import { FC, useEffect, useState } from 'react';
import { Form } from 'antd';
import {
  formatArrangedData,
  getArrangesInitialValues,
  getParsedSelectedItems,
  getSelectedItemsLabels,
} from '../../../../lib/utils/arranges-helpers';
import { useAppSelector } from '../../../../lib/store/hooks';
import { FilterListPopup } from '../../../../features/table/components/popups/FilterListPopup';
import { TableHeaderCell } from '../../../../components/Table/TableHeaderCell/TableHeaderCell';
import { useTranslation } from 'react-i18next';
import { useUserArrangeRequests } from '../../hooks/table/useUserArrangeRequests';
import { User } from '../../helpers/constants';
import { useEnumArrange } from '../../../tenants/hooks/useEnumArrange';
import { useOptionsTenantsQuery } from '../../../../lib/store/api/usersManagement';
import { ResultWithPagination } from '../../../../lib/types/pagination';
import { TenantShortInfo } from '../../../../lib/models/Tentant';
import { TENANT_COLUMN } from '../../../tenants/helpers/constants';

const columns = 'pages.userManagement.columns';

export const AssignedTenantsHeaderCell: FC = () => {
  const { t } = useTranslation();

  const { arrangeId } = useAppSelector(({ userManagement }) => userManagement);
  const isActiveArrange = arrangeId === User.TENANTS;
  const [filterLabel, setFilterLabel] = useState('');
  const [form] = Form.useForm();
  const { data } = useOptionsTenantsQuery();
  const [allOptions, setAllOptions] = useState<{ value: string; label: string }[]>([]);

  const getSelectedItems = () => getSelectedItemsLabels(form);

  const { filtering } = useEnumArrange(allOptions);

  useEffect(() => {
    const options = formatArrangedData(data as ResultWithPagination<TenantShortInfo[]>, TENANT_COLUMN.NAME);
    filtering.setOptions(options);
    setAllOptions(options);
  }, [data]);

  const { applyArranges, removeFilter, removeFilterValue } = useUserArrangeRequests({
    arrangedId: User.TENANTS,
    getFilterLabel: () => getSelectedItems().join(', '),
    getFilters: () => ({ assignedUsers: getSelectedItems() }),
    form,
    setFilterLabel,
    filterFieldName: 'includeTenants',
    isParsedByValue: true,
  });

  const headerCellProps = {
    title: t(`${columns}.assignedTenants`),
    arrangedId: User.TENANTS,
    removeFilter,
    filterLabel,
    isActiveArrange,
    form,
    applyArranges,
    getParsedItems: () => getParsedSelectedItems(form),
    removeFilterValue,
  };

  return (
    <TableHeaderCell {...headerCellProps}>
      <FilterListPopup
        initialValues={getArrangesInitialValues(form)}
        form={form}
        filtering={filtering}
        onFinish={applyArranges}
      />
    </TableHeaderCell>
  );
};
