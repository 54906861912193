import { useTranslation } from 'react-i18next';
import { TableColumn } from '../../../lib/models/Table';
import { TableHeaderCell } from '../../../components/Table/TableHeaderCell/TableHeaderCell';

export const useActionsColumn = (props: any) => {
  const { t } = useTranslation();
  return {
    title: <TableHeaderCell withArrange={false} title={t('shared.columns.actions')} />,
    dataIndex: TableColumn.ACTIONS,
    width: 110,
    ...props,
  };
};
