import { useAppDispatch } from '../../../../lib/store/hooks';
import { Space } from 'antd';
import { IconButton } from '../../../../components/IconButton';
import { EditIcon } from '../../../../assets/icons/EditIcon';
import { ActionsDropdown } from '../../../../components/Table/ActionsDropdown/ActionsDropdown';

import { ModalActions } from '../../../../lib/models/Modal';
import { DemandArea } from '../../../../lib/types/demandArea';
import { setCurrentDemandArea, setModalOpened } from '../../../../lib/store/slices/demandAreasSlice';

export const DemandsActionsBodyCell = ({ row }: any) => {
  const dispatch = useAppDispatch();

  const onEditDemand = (demandArea: DemandArea) => () => {
    dispatch(setCurrentDemandArea(demandArea));
    dispatch(setModalOpened(ModalActions.EDIT));
  };

  return (
    <Space size="middle">
      <IconButton icon={<EditIcon />} onClick={onEditDemand(row)} />
      <ActionsDropdown table="demandAreas" row={row} />
    </Space>
  );
};
