import { FC } from 'react';
import { Form, FormInstance } from 'antd';
import { ArrangeFilterPopupWrapper } from './ArrangeFilterPopupWrapper';
import { SearchFilter } from '../SearchFilter';
import { useResetArrange } from '../../helpers/useResetArrange';

type Props = {
  form: FormInstance<any>;
  onFinish: () => void;
  filtering: any;
  initialValues: any;
  isActiveArrange?: boolean;
};

export const FilterListPopup: FC<Props> = ({
  filtering,
  form,
  onFinish,
  initialValues = {},
  isActiveArrange = false,
}) => {
  useResetArrange({ isActiveArrange, form });

  return (
    <Form form={form} onFinish={onFinish} initialValues={initialValues}>
      <ArrangeFilterPopupWrapper>
        <SearchFilter filtering={filtering} />
      </ArrangeFilterPopupWrapper>
    </Form>
  );
};
