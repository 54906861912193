import { EndUserResetPasswordCard } from '../../modules/endUserResetPassword/components/EndUserResetPasswordCard';

import styles from './styles.module.scss';

export const EndUserResetPasswordPage = () => {
  return (
    <div className={styles.container}>
      <EndUserResetPasswordCard />
    </div>
  );
};
