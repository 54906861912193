import { EndUserTermsAcceptance } from '../../modules/endUserTermsAcceptance/components/endUserTermsAcceptanceCard';

import styles from './styles.module.scss';

export const EndUserTermsAcceptancePage = () => {
  return (
    <div className={styles.container}>
      <EndUserTermsAcceptance />
    </div>
  );
};
