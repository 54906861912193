import { FC } from 'react';
import { Form } from 'antd';
import { FormItemName } from '../../../../../lib/models/Form';
import { useTranslation } from 'react-i18next';

import { FormItem } from '../../../../../components/FormItem';
import { AppSegmented } from '../../../../../components/AppSegmented';

const MailServer = {
  DEFAULT: 'DEFAULT',
  CUSTOM: 'CUSTOM',
};

const general = 'pages.settings.general';

export const MailServerFormSection: FC = () => {
  const { t } = useTranslation();

  const form = Form.useFormInstance();

  const onChange = (value: string | number) => {
    form.setFieldValue(FormItemName.MAIL_SERVER, value);
    if (value) {
      form.setFieldValue(FormItemName.MAIL_SERVER_SENDER, '');
      form.setFieldValue(FormItemName.MAIL_SERVER_HOST, '');
      form.setFieldValue(FormItemName.MAIL_SERVER_PORT, '');
      form.setFieldValue(FormItemName.MAIL_SERVER_USER_NAME, '');
      form.setFieldValue(FormItemName.MAIL_SERVER_PASSWORD, '');
    }
  };

  const options = [
    { value: MailServer.DEFAULT, label: t(`${general}.useDefault`) },
    { value: MailServer.CUSTOM, label: t(`${general}.customMailServer`) },
  ];

  return (
    <FormItem name={FormItemName.MAIL_SERVER} style={{ minHeight: 56 }}>
      <AppSegmented onChange={onChange} value={Form.useWatch(FormItemName.MAIL_SERVER)} options={options} />
    </FormItem>
  );
};
