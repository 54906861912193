import { Form, message } from 'antd';
import { EndUserButton } from '../../../../components/EndUserButton';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { FormInput } from '../../../../components/FormInput';
import { FormItem } from '../../../../components/FormItem';
import { FormItemName } from '../../../../lib/models/Form';
import { useAppSelector } from '../../../../lib/store/hooks';
import { ReactComponent as SuccessLetter } from './../../../../assets/icons/success-letter.svg';
import { useState } from 'react';
import { EndUserAuthBottomButtons } from '../../../../components/EndUserAuthBottomButtons';
import styles from './styles.module.scss';
import { useRestorePasswordMutation } from '../../../../lib/store/api/auth';
import { useTranslation } from 'react-i18next';

const resetPassword = 'pages.resetPassword';

export const EndUserResetPasswordCard = () => {
  const { demandArea } = useAppSelector(({ endUser }) => endUser);
  const { tenantAndProject } = useAppSelector(({ endUser }) => endUser);

  const tenantId = tenantAndProject?.tenantId;
  const projectId = tenantAndProject?.projectId;
  const logoS3Url = demandArea?.tenant?.logo?.s3Url;
  const [messageApi] = message.useMessage();
  const { t } = useTranslation();

  const [isSwitch, setIsSwitch] = useState(false);
  const [form] = Form.useForm();
  const [restorePassword] = useRestorePasswordMutation();

  const onFinish = async (values: any) => {
    const formattedFormValues = {
      [FormItemName.EMAIL]: values[FormItemName.EMAIL].trim(),
      projectId: projectId,
      tenantId: tenantId,
    } as any;
    const data = await restorePassword(formattedFormValues);
    if ('data' in data) {
      setIsSwitch(true);

      messageApi.success(t('pages.getLinkForResetPassword.success'));
    }
  };
  return (
    <div className={styles.container}>
      <div>
        <img className={styles.logo} alt="logo" src={logoS3Url} />
      </div>
      {isSwitch ? (
        <div className={styles.successWrapper}>
          <EndUserTypography className={styles.title} type="headlineSecond" component="Title">
            {t(`${resetPassword}.emailSent`)}
          </EndUserTypography>

          <SuccessLetter />

          <EndUserTypography type="body" component="Text" className={styles.text}>
            {t(`${resetPassword}.emailDescription`)}
          </EndUserTypography>
        </div>
      ) : (
        <div className={styles.main}>
          <EndUserTypography className={styles.title} type="headlineSecond" component="Title" level={2}>
            {t(`${resetPassword}.question`)}
          </EndUserTypography>
          <EndUserTypography type="body" component="Text" className={styles.text}>
            {t(`${resetPassword}.hint`)}
          </EndUserTypography>
          <>
            <Form form={form} onFinish={onFinish}>
              <FormItem
                className={styles.formItem}
                name={FormItemName.EMAIL}
                hasFeedback={true}
                rules={[
                  { required: true, message: t(`${resetPassword}.errors.emptyEmail`) },
                  { type: 'email', message: t(`${resetPassword}.errors.validEmail`) },
                ]}
              >
                <FormInput label={t(`${resetPassword}.emailLabel`)} />
              </FormItem>

              <EndUserButton className={styles.sendButton} htmlType="submit" type="primary">
                {t(`${resetPassword}.resetButton`)}
              </EndUserButton>
            </Form>
          </>
        </div>
      )}

      <div className={styles.footer}>
        <EndUserAuthBottomButtons />
      </div>
    </div>
  );
};
