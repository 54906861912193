import { FormSection } from '../../../components/FormSection';
import { AppSelect } from '../../../components/AppSelect';

type Props = { value: number; onChange: any; fieldName?: string; field: string };

const MIN_FONT_SIZE = 6;
const MAX_FONT_SIZE = 48;

const fontSizeOptions = Array.from({ length: MAX_FONT_SIZE - MIN_FONT_SIZE + 1 }, (_value, index) => ({
  value: MIN_FONT_SIZE + index,
  title: `${MIN_FONT_SIZE + index} pt`,
}));

export const FontSizeFormItem = ({ fieldName, field, onChange, value }: Props) => {
  return (
    <FormSection
      formItemProps={{
        hasFeedback: true,
      }}
    >
      <AppSelect
        onChange={(selected: { value: string; title: string }) => onChange(selected?.value, fieldName, field)}
        value={{ value }}
        options={fontSizeOptions}
      />
    </FormSection>
  );
};
