import { FC, useState } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { FileUploader } from '../../../../components/FileUploader';
import { UploadImageLabel } from '../../../../components/UploadImageLabel';
import { MIMEType, TargetType, UploadMediaResponseT } from '../../../../lib/types/media';

import styles from './index.module.scss';

const designTexts = 'pages.designSettingsTab';
const beforeUploadLogoError = 'pages.designSettingsTab.beforeUploadLogoError';
const image = 'formItems.chooseImage.image';

const MAX_FILE_SIZE = 1;
const { Text } = Typography;

type Props = {
  logo: UploadMediaResponseT | null;
  onDeleteClick: () => void;
  afterUploadAction: (file: UploadMediaResponseT) => void;
};

export const LogoCardFormItem: FC<Props> = ({ logo, afterUploadAction, onDeleteClick }) => {
  const [uploadingLogo, setUploadingLogo] = useState(false);
  const { t } = useTranslation();

  return (
    <div className={styles.cardBodyWrapper}>
      <Text type="secondary" className={styles.text}>
        {t(`${designTexts}.logo`)}
      </Text>

      <FileUploader
        targetType={TargetType.LOGO}
        accept={MIMEType.PICTURE}
        afterUploadAction={afterUploadAction}
        errorText={t(beforeUploadLogoError)}
        maxFileSize={MAX_FILE_SIZE}
        disabled={uploadingLogo}
        setUploading={setUploadingLogo}
        uploadLabel={({ onDeleteMedia }) => (
          <UploadImageLabel
            className={styles.uploadLabel}
            mediaObject={uploadingLogo ? undefined : logo || undefined}
            uploading={uploadingLogo}
            deleteOnClick={onDeleteClick}
            imgHeight={52}
            text={t(image)}
            onDeleteMedia={onDeleteMedia}
          />
        )}
      />
    </div>
  );
};
