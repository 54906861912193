import { ProductSearchDataHeaderCell } from '../components/ProductSearchDataHeaderCell';

type Props = {
  disableFiltering?: boolean;
  title: string;
  columnProps?: any;
  arrangedId: string;
  searchFieldName?: string;
  filterFieldName?: string;
  isParsedByValue?: boolean;
};

export const useProductSearchDataColumn = (props: Props) => {
  const { columnProps, ...rest } = props;

  return {
    ...columnProps,
    dataIndex: props.arrangedId,
    title: <ProductSearchDataHeaderCell {...rest} />,
  };
};
