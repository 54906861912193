import { useTranslation } from 'react-i18next';
import { RULES_COLUMN, RuleT } from '../../../lib/types/ruleBuilder';
import { useRulesDataColumn } from './useRulesDataColumn';

const type = 'pages.ruleBuilder.ruleColumns.type';

export const useRuleTypeColumn = (arrangedId = RULES_COLUMN.TYPE) => {
  const { t } = useTranslation();

  return useRulesDataColumn({
    title: t(type),
    arrangedId,
    columnProps: {
      width: 200,
      render: (text: string, row: RuleT) => (arrangedId === RULES_COLUMN.TYPE ? row?.ruleType?.name : text),
    },
  });
};
