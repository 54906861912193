import { Trans, useTranslation } from 'react-i18next';
import { Form, message, MessageArgsProps, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../../lib/store/hooks';
import { ModalActions } from '../../../../../lib/models/Modal';
import { useResetFormOnCloseModal } from '../../../../../lib/hooks/useResetFormOnCloseModal';
import { AppModal } from '../../../../../components/AppModal';
import { useDeleteBonusCatalogMutation } from '../../../../../lib/store/api/bonusCatalog';
import { setModalClosed } from '../../../../../lib/store/slices/bonusCatalogSlice';

const MESSAGE_SHOWING_TIME = 3;
const { Paragraph } = Typography;

type Props = {
  tenantId: string;
};

const deleteModal = `pages.bonusCatalogTab.deleteModal`;
const deleteText = `generic.delete`;

export const DeleteBonusCatalogModal = ({ tenantId }: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const dispatch = useAppDispatch();

  const { selectedBonusCatalog, modalType, isModalOpen } = useAppSelector(({ bonusCatalog }) => bonusCatalog);
  const [deleteBonusCatalog, { isLoading }] = useDeleteBonusCatalogMutation();

  const isOpen = isModalOpen && modalType === ModalActions.DELETE;

  const onClose = () => {
    dispatch(setModalClosed());
  };

  useResetFormOnCloseModal({ form, isOpen });

  const onConfirm = () => {
    form.submit();
  };

  const onFinish = async () => {
    const messageConfig = {} as MessageArgsProps;
    try {
      await deleteBonusCatalog({ bonusCatalogId: selectedBonusCatalog!.id, tenantId }).unwrap();
      messageConfig.content = t(`${deleteModal}.successText`);
      messageConfig.type = 'success';
      onClose();
    } catch (err: any) {
      const alreadyUsedText =
        err?.data?.exceptionCode === 'ERROR_010'
          ? 'The bonus is either still active or is shown in a history of received bonuses for some users, and cannot be deleted.'
          : null;

      messageConfig.content = alreadyUsedText || err?.data?.message || t(`${deleteModal}.errorText`);
      messageConfig.type = 'error';
    } finally {
      messageApi.open({
        duration: MESSAGE_SHOWING_TIME,
        ...messageConfig,
      } as MessageArgsProps);
    }
  };

  return (
    <AppModal
      open={isOpen}
      onCancel={onClose}
      onOk={onConfirm}
      okText={t(deleteText)}
      titleText={t(`${deleteModal}.title`)}
      isOkLoading={isLoading}
    >
      {contextHolder}
      {isOpen && (
        <Form form={form} onFinish={onFinish}>
          <Paragraph style={{ marginBottom: '36px' }}>
            <Trans
              i18nKey={t(`${deleteModal}.questionText`, { name: selectedBonusCatalog?.name })}
              values={{ bonusCatalog: selectedBonusCatalog?.name }}
              components={{
                bold: <span className="bold secondary_color" />,
              }}
            />
          </Paragraph>
        </Form>
      )}
    </AppModal>
  );
};
