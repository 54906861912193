import { useTranslation } from 'react-i18next';
import { FormItem } from '../../../../components/FormItem';
import { FormItemName } from '../../../../lib/models/Form';
import { FormInput } from '../../../../components/FormInput';
import styles from '../../../tenants/components/FormItems/index.module.scss';

import { ReactComponent as LinkIcon } from './../../../../assets/icons/link-icon-gray.svg';

const finderUrl = 'pages.products.productCategories.formItems.finderUrl';

export const FinderUrlFormItem = () => {
  const { t } = useTranslation();

  return (
    <FormItem
      name={FormItemName.FINDER_URL}
      hasFeedback={true}
      rules={[
        {
          type: 'url',
          message: t(`${finderUrl}.errors.invalid`),
        },
        { max: 200 },
      ]}
    >
      <FormInput className={styles.inputUrl} icon={<LinkIcon />} label={t(`${finderUrl}.label`)} />
    </FormItem>
  );
};
