import { FC } from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemName } from '../../../../lib/models/Form';
import { usePasswordValidation } from '../../hooks/usePasswordValidation';
import { FormInput } from '../../../../components/FormInput';
import { FormItem } from '../../../../components/FormItem';

type Props = {
  label?: string;
};
export const RepeatPasswordFormItem: FC<Props> = ({ label }) => {
  const { t } = useTranslation();

  const { settingPasswordRules } = usePasswordValidation();

  const samePasswordRule = ({ getFieldValue }: any): unknown => ({
    validator(_rule: any, value: any) {
      if (!value || getFieldValue(FormItemName.PASSWORD) === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(t('formItems.repeatPassword.errors.differentPasswords')));
    },
  });

  return (
    <FormItem
      name={FormItemName.REPEAT_PASSWORD}
      rules={[{ required: true }].concat(settingPasswordRules as any[]).concat(samePasswordRule as any)}
      help={false}
      dependencies={[FormItemName.PASSWORD]}
    >
      <FormInput as={Input.Password} label={label ? t(label) : t('formItems.repeatPassword.label')} />
    </FormItem>
  );
};
