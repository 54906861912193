import { FC } from 'react';

import styles from './index.module.scss';
import { UploadMediaResponseT } from '../../../../lib/types/media';
import { EndUserTypography } from '../../../../components/EndUserTypography';

type Props = {
  image: UploadMediaResponseT;
  title: string;
  description: string;
  width?: number;
};

export const SmallBonusCard: FC<Props> = ({ image, title, description, width }) => {
  return (
    <div className={styles.wrapper} style={{ width: width }}>
      <div className={styles.pictureWrapper}>
        <img src={image.cdnUrl} alt={image.originalFileName} />
      </div>
      <div className={styles.textWrapper}>
        <EndUserTypography type="headlineSixth" className={styles.title}>
          {title}
        </EndUserTypography>
        <EndUserTypography type="subText" className={styles.description}>
          {description}
        </EndUserTypography>
      </div>
    </div>
  );
};
