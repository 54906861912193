import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormItemName } from '../../../../../lib/models/Form';
import { FormItem } from '../../../../../components/FormItem';
import { AppSelect } from '../../../../../components/AppSelect';

const LANGUAGES = [
  { title: 'English', value: 'EN' },
  { title: 'German', value: 'DE' },
];

const userManagement = 'pages.userManagement';

export const LanguagesFormItem: FC = () => {
  const { t } = useTranslation();

  return (
    <FormItem rules={[{ required: true }]} name={FormItemName.LANGUAGE} hasFeedback={true}>
      <AppSelect unselectable label={t(`${userManagement}.formItems.language`)} options={LANGUAGES} />
    </FormItem>
  );
};
