import { useTranslation } from 'react-i18next';
import { MessageInstance } from 'antd/es/message/interface';
import { openTenantModal } from '../../../lib/store/slices/tenantsSlice';
import { ModalActions } from '../../../lib/models/Modal';
import { TenantT } from '../../../lib/types/tenants';
import { useAppDispatch } from '../../../lib/store/hooks';
import { useUpdateTenantStatusMutation } from '../../../lib/store/api/tenants';

const success = 'pages.tenants.status.success';
const error = 'pages.tenants.status.error';



export const useTenantsActionsRequest = (messageApi: MessageInstance) => {
  const { t } = useTranslation();
  const [updateTenantStatus] = useUpdateTenantStatusMutation();
  const dispatch = useAppDispatch();

  const onUpdateTenantStatus = async (row: TenantT) => {
    try {
      await updateTenantStatus({
        id: row.id,
        status: !row.activated,
      }).unwrap();
      messageApi.success(t(success));
    } catch (err: any) {
      messageApi.error(err?.data?.message || t(error));
    }
  };

  const onDeleteTenant = (row: TenantT) => {
    dispatch(openTenantModal({ modalType: ModalActions.DELETE, selectedTenant: row }));
  };

  return { onUpdateTenantStatus, onDeleteTenant };
};
