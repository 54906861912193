import { Form } from 'antd';
import { useState } from 'react';
import { User } from '../../helpers/constants';
import { useUserArrangeRequests } from './useUserArrangeRequests';
import { useDateColumn } from '../../../../features/table/helpers/useDateColumn';
import { useDateArrange } from './useDateArrange';
import { useAppSelector } from '../../../../lib/store/hooks';

type Props = {
  title: string;
  arrangedId: User;
  filterFromName: string;
  filterToName: string;
  headerCellProps?: any;
};

export function useUserDateColumn({
  title,
  arrangedId,
  filterFromName,
  filterToName,
  headerCellProps,
}: Props) {
  const { arrangeId, sorting } = useAppSelector(({ userManagement }) => userManagement);
  const { appLang } = useAppSelector(({ auth }) => auth);

  const isActiveArrange = arrangeId === arrangedId;
  const [form] = Form.useForm();
  const [filterLabel, setFilterLabel] = useState('');

  const { getFilterLabel, formattedFromDate, formattedToDate } = useDateArrange(form, appLang);

  const getFilters = () => ({
    [filterFromName]: formattedFromDate,
    [filterToName]: formattedToDate,
  });

  const { applyArranges, removeFilter } = useUserArrangeRequests({
    arrangedId,
    getFilterLabel,
    getFilters,
    form,
    setFilterLabel,
    isDate: true,
  });

  return useDateColumn({
    title,
    applyArranges,
    removeFilter,
    arrangedId,
    isActiveArrange,
    form,
    sortOrder: sorting.sortOrder,
    filterLabel,
    ...headerCellProps,
  });
}
