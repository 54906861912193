import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormItemName } from '../../../../lib/models/Form';
import { FormItem } from '../../../../components/FormItem';
import { FormInput } from '../../../../components/FormInput';
import { Input } from 'antd';

import styles from './index.module.scss';

const { TextArea } = Input;

const comments = 'unique.formItems.comments';

export const CommentsFormItem: FC = () => {
  const { t } = useTranslation();

  return (
    <FormItem
      name={FormItemName.COMMENTS}
      hasFeedback={true}
      rules={[
        {
          required: false,
        },

        { max: 1800 },
      ]}
    >
      <FormInput label={t(`${comments}.label`)} as={TextArea} className={styles.textArea} />
    </FormItem>
  );
};
