import { useGetStatusLevelsQuery } from '../../../lib/store/api/statusLevel';
import { GetStatusLevelPayloadT } from '../../../lib/types/statusLevel';
import { useParams } from 'react-router-dom';
import { Form, FormInstance } from 'antd';
import { FormItemName } from '../../../lib/models/Form';

export const useStatusLevelPointsValidation = (form: FormInstance) => {
  const { id: tenantId, modelId } = useParams();
  const sublevels: { sublevelPoints: number }[] = Form.useWatch(FormItemName.SUBLEVELS, form);

  const { data } = useGetStatusLevelsQuery(
    {
      tenantId: String(tenantId),
      projectId: modelId,
      page: 0,
      size: 10000,
    } as GetStatusLevelPayloadT,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const allStatusLevels = data?.content || [];

  const localMinPoints = Math.min.apply(
    null,
    sublevels?.map(function (item) {
      return Number(item.sublevelPoints);
    }),
  );

  const localMaxPoints = Math.max.apply(
    null,
    sublevels?.map(function (item) {
      return Number(item.sublevelPoints);
    }),
  );

  const globalRanges = allStatusLevels.map((statusLevel) => {
    const minPoints = Math.min.apply(
      null,
      statusLevel.subLevels.map(function (item) {
        return Number(item.points);
      }),
    );
    const maxPoints = Math.max.apply(
      null,
      statusLevel.subLevels.map(function (item) {
        return Number(item.points);
      }),
    );

    return { from: minPoints, to: maxPoints };
  });

  const checkIfValueCrossedWithOtherSublevels = () => {
    return false;
  };

  return { checkIfValueCrossedWithOtherSublevels };
};
