import { useMemo } from 'react';
import { useGetBrandKitDataQuery } from '../../../lib/store/api/brandKit';
import { useParams } from 'react-router-dom';
import { BrandKitThemeConfiguration, ColorsList } from '../../../lib/types/colors&fonts';
import { AdditionalChooseColors, DefaultBrandKitColors } from '../../brandKit/util/constants';
import { isLength, parseJSON } from '../../../lib/utils/helpers';

export const useGetBrandKitColors = () => {
  const { id: tenantId, modelId } = useParams();

  const { data } = useGetBrandKitDataQuery(
    { tenantId: tenantId as string, projectId: String(modelId) },
    { skip: !tenantId },
  );

  const colors = useMemo(() => {
    if (data && isLength(data)) {
      const firstBrandKit = data?.[0];
      const parsedData: BrandKitThemeConfiguration = parseJSON(firstBrandKit?.settings);
      if (parsedData) {
        return { ...parsedData?.colors, ...AdditionalChooseColors };
      }
    }
    return { ...DefaultBrandKitColors, ...AdditionalChooseColors };
  }, [data]);

  return colors as ColorsList;
};
