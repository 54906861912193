import { FilterBlockWrapper } from './FilterBlockWrapper';
import { FC, ReactNode } from 'react';
import { ArrangePopupWrapper } from './ArrangePopupWrapper';

type Props = {
  children: ReactNode;
};

export const ArrangeFilterPopupWrapper: FC<Props> = ({ children }) => {
  return (
    <ArrangePopupWrapper
      filter={<FilterBlockWrapper>{children}</FilterBlockWrapper>}
    />
  );
};
