import { FC } from 'react';
import { Space } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { IconButton } from '../../../../components/IconButton';
import { EditIcon } from '../../../../assets/icons/EditIcon';
import { checkIsSuperAdmin } from '../../../../lib/utils/roles';
import { ModalActions } from '../../../../lib/models/Modal';
import { openTenantModal } from '../../../../lib/store/slices/tenantsSlice';
import { ActionsDropdown } from '../../../../components/Table/ActionsDropdown/ActionsDropdown';
import { TenantT } from '../../../../lib/types/tenants';

type Props = {
  row: TenantT;
};

export const TenantActionsBodyCell: FC<Props> = ({ row }) => {
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(({ auth }) => auth.role);
  const isSuperAdmin = checkIsSuperAdmin(userRole);

  return (
    <Space size="middle">
      {isSuperAdmin && (
        <>
          <IconButton
            icon={<EditIcon />}
            onClick={() =>
              dispatch(
                openTenantModal({
                  modalType: ModalActions.EDIT,
                  selectedTenant: row,
                }),
              )
            }
          />

          <ActionsDropdown table="tenants" row={row} />
        </>
      )}
    </Space>
  );
};
