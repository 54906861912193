import { FC, useCallback } from 'react';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { ProductT, Visibility } from '../../../../lib/types/product';
import { getProductPoints } from '../../utils/helpers';
import { IconComponent } from '../../../../components/IconComponent';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/cross-in-circle.svg';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import { WishlistProductsListButton } from '../WishlistProductsListButton';
import { useUpdateWishlist } from '../../../../lib/hooks/endUserHooks/useUpdateWishlist';
import { setWishlistProduct, setWishlistProducts } from '../../../../lib/store/slices/endUserSlice';
import { useStartCalculationRulesForGuestMutation } from '../../../../lib/store/api/endUser';
import { useGetRuleActionStatusLevel } from '../../../../lib/hooks/endUserHooks/useGetRuleActionStatusLevel';
import { IconButton } from '../../../../components/IconButton';
import cx from 'classnames';

import styles from './index.module.scss';
import cn from 'classnames';

type Props = {
  product: ProductT;
  areaColor: string;
  buttonClassName?: string;
  productNameClassName?: string;
};

export const WishlistProductsListItem: FC<Props> = ({
  product,
  areaColor,
  buttonClassName,
  productNameClassName,
}) => {
  const { isGuestMode } = useAppSelector(({ endUser }) => endUser);

  const isVisible = product?.category?.visibility !== Visibility.NEVER;
  const points = getProductPoints(product);

  const { isMobile } = useContainerQuery();
  const { updateWishlist, isLoading } = useUpdateWishlist(true);
  const dispatch = useAppDispatch();
  const [startCalculationForGuest] = useStartCalculationRulesForGuestMutation();
  const { updateRuleActionStatusLevel } = useGetRuleActionStatusLevel();

  const deleteFromBookmark = useCallback(
    (product: ProductT) => async () => {
      if (!isGuestMode) {
        await updateWishlist(product);
      } else {
        dispatch(setWishlistProduct(product));
        const { wishList, endUserStatusLevels } = await startCalculationForGuest().unwrap();

        updateRuleActionStatusLevel(endUserStatusLevels);
        const products = wishList.map((item) => ({ ...item.product }));

        dispatch(setWishlistProducts(products));
      }
    },
    [dispatch, isGuestMode, startCalculationForGuest, updateRuleActionStatusLevel, updateWishlist],
  );

  return (
    <li className={styles.wrapper}>
      <div className={cx(styles.leftColumn, { [styles.leftColumnPadding]: !isMobile && product?.special })}>
        {!isMobile && !product?.special ? (
          <IconButton
            isLoading={isLoading}
            className={styles.iconButton}
            onClick={deleteFromBookmark(product)}
            icon={<TrashIcon className={styles.trashIcon} />}
          />
        ) : null}

        <div
          className={styles.itemIcon}
          style={{ background: isVisible ? areaColor : `var(--primary-color)` }}
        >
          <IconComponent className={styles.icon} iconData={product?.category?.icon} color={'#fff'} />
        </div>
        <EndUserTypography
          type="headlineSeventh"
          component="Text"
          className={cn(styles.name, productNameClassName && productNameClassName)}
        >
          {product?.name}
        </EndUserTypography>
      </div>
      <div className={styles.rightColumn}>
        <div className={styles.innerColumn}>
          {!isMobile && <WishlistProductsListButton buttonClassName={buttonClassName} product={product} />}

          <EndUserTypography type="headlineEight" component="Text" className={styles.points}>
            {points > 0 ? `+ ${points}★` : `${points}★`}
          </EndUserTypography>
        </div>
      </div>
    </li>
  );
};
