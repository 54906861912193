import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ChevronLeft from '../../../assets/icons/chevron-left.svg';

type Props = {
  type: string;
};

const prevAlt = 'platform.datePicker.prevAlt';

export const PrevIcon: FC<Props> = ({ type }) => {
  const { t } = useTranslation();

  return <img src={ChevronLeft} alt={t(prevAlt, { context: type })} />;
};
