import { useNavigate } from 'react-router-dom';
import { FC } from 'react';
import { EndUserTypography } from '../../../../components/EndUserTypography';

import styles from './index.module.scss';
import { CoreVisualSectionT } from '../../../../lib/types/sections';
import { HouseMobile } from '../../../../components/HouseMobile';
import { FinderButton } from '../../../../components/FinderButton';
import { sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';
import { sortCategoriesByPriority } from '../../../../lib/utils/helpers';
import { Category, DemandArea } from '../../../../lib/types/endUser';
import { checkBoughtProductsInCategory } from '../../../../lib/utils/end-user-helpers';
import { setCurrentCategory } from '../../../../lib/store/slices/endUserSlice';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';

type Props = {
  demandAreas: DemandArea[];
  content?: CoreVisualSectionT;
};

export const EndUserHouseModelMobile: FC<Props> = ({ demandAreas, content }) => {
  const { tenantAndProject } = useAppSelector(({ endUser }) => endUser);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const handleAreaClick = (areaId: number, name: string) => {
    sendDataToAnalytics(
      'Bedarfsbereich',
      `Aufruf Bedarfsbereich ${name}`,
      `User exploriert Produkte aus Bedarfsbereich ${name}`,
    );

    const demandArea = demandAreas.find((area) => area.id === areaId);

    const sortedCategories = sortCategoriesByPriority(demandArea?.categories || []);

    let category: Category | undefined = sortedCategories[0];

    //hardcode below
    if (tenantAndProject?.subDomain === 'energie-schwaben') {
      if (demandArea?.name === 'Energie') {
        const gasCategory = sortedCategories.find((category) => category.name === 'Gas');
        const stromCategory = sortedCategories.find((category) => category.name === 'Strom');

        const isGasCovered = gasCategory && checkBoughtProductsInCategory(gasCategory);
        const isStromCovered = stromCategory && checkBoughtProductsInCategory(stromCategory);

        if (isGasCovered && !isStromCovered) {
          category = stromCategory;
        } else if (isStromCovered && !isGasCovered) {
          category = gasCategory;
        } else if (isGasCovered && isStromCovered) {
          category = gasCategory;
        } else {
          category = gasCategory;
        }
      }
    }

    setTimeout(() => {
      dispatch(setCurrentCategory({ category }));
    }, 100);

    navigate(`/house-model/${areaId}`);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, areaId: number, name: string) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleAreaClick(areaId, name);
    }
  };

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(76deg, rgba(0, 0, 0, 0.51) 5.37%, rgba(0, 0, 0, 0.46) 27.78%, rgba(0, 0, 0, 0.00) 90.96%), url(${content?.backgroundImage?.s3Url})`,
        padding: '24px 16px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className={styles.headlineBlock}>
        <EndUserTypography
          type="headlineSecond"
          component="Title"
          style={{ marginBottom: '8px', color: '#fff', maxWidth: '14ch' }}
        >
          {content?.headline1}
        </EndUserTypography>
        <EndUserTypography type="headlineSixth" component="Title" style={{ marginBottom: 12, color: '#fff' }}>
          {content?.headline2}
        </EndUserTypography>

        <FinderButton
          showFinderButton={content?.finderEnabled}
          finderButtonText={content?.finderButtonText}
          finderButtonUrl={content?.finderButtonLink}
          recommendForAllCategories={true}
        />
      </div>
      <HouseMobile
        demandAreas={demandAreas}
        handleAreaClick={handleAreaClick}
        handleKeyDown={handleKeyDown}
      />
    </div>
  );
};
