import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../lib/store/hooks';
import { ModalActions } from '../../lib/models/Modal';
import { openTenantModal } from '../../lib/store/slices/tenantsSlice';
import { Button } from '../../components/Button';
import { ReactComponent as PlusIcon } from '../../assets/icons/PlusIcon.svg';
import { TenantsModals } from '../../modules/tenants/components/TenantsModals';
import { TenantsTable } from '../../modules/tenants/components/TenantsTable';
import { checkIsSuperAdmin } from '../../lib/utils/roles';

export const TenantsPage: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const userRole = useAppSelector(({ auth }) => auth.role);
  const isSuperAdmin = checkIsSuperAdmin(userRole);

  const handleOpenTenantModal = () => dispatch(openTenantModal({ modalType: ModalActions.ADD }));

  return (
    <div id="home">
      <TenantsModals />

      {isSuperAdmin && (
        <Button className="table_btn" onClick={handleOpenTenantModal} icon={<PlusIcon fill="#FFFFFF" />}>
          {t('pages.tenants.addButton')}
        </Button>
      )}

      <TenantsTable />
    </div>
  );
};
