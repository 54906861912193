import React, { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../lib/store/hooks';
import { useTranslation } from 'react-i18next';
import { ModalActions } from '../../lib/models/Modal';
import { Button } from '../../components/Button';
import { ReactComponent as PlusIcon } from '../../assets/icons/PlusIcon.svg';
import { ProjectsTable } from '../../modules/projects/components/ProjectsTable';
import { setModalOpened } from '../../lib/store/slices/projectsSlice';
import { CreateProjectModal } from '../../modules/projects/components/Modals/CreateProjectModal';
import { EditProjectModal } from '../../modules/projects/components/Modals/EditProjectModal';
import { DeleteProjectModal } from '../../modules/projects/components/Modals/DeleteProjectModal';
import { useParams } from 'react-router-dom';
import { checkIsUser } from '../../lib/utils/roles';

export const ProjectsPage: FC = () => {
  const dispatch = useAppDispatch();
  const { role } = useAppSelector(({ auth }) => auth);
  const isUser = checkIsUser(role);
  const { t } = useTranslation();

  const { id: tenantId } = useParams();

  const onOpenCreateProjectModal = () => {
    dispatch(setModalOpened(ModalActions.ADD));
  };

  return (
    <div>
      {!isUser && (
        <Button
          style={{ width: 'auto' }}
          className="table_btn"
          onClick={onOpenCreateProjectModal}
          icon={<PlusIcon fill="#FFFFFF" />}
        >
          {t('pages.projects.buttons.addNewModel')}
        </Button>
      )}
      <ProjectsTable />
      <CreateProjectModal tenantId={tenantId as string} />
      <EditProjectModal tenantId={tenantId as string} />
      <DeleteProjectModal tenantId={tenantId as string} />
    </div>
  );
};
