import { useAppDispatch } from '../../../../lib/store/hooks';
import { ProductCategoryT } from '../../../../lib/types/entities';
import { setCurrentCategory, setModalOpened } from '../../../../lib/store/slices/productCategoriesSlice';
import { ModalActions } from '../../../../lib/models/Modal';
import { Space } from 'antd';
import { IconButton } from '../../../../components/IconButton';
import { EditIcon } from '../../../../assets/icons/EditIcon';
import { ActionsDropdown } from '../../../../components/Table/ActionsDropdown/ActionsDropdown';

export const ProductCategoriesActionsBodyCell = ({ row }: any) => {
  const dispatch = useAppDispatch();

  const openEditProductCategoryModal = (productCategory: ProductCategoryT) => () => {
    dispatch(setCurrentCategory(productCategory));
    dispatch(setModalOpened(ModalActions.EDIT));
  };

  return (
    <Space size="middle">
      <IconButton icon={<EditIcon />} onClick={openEditProductCategoryModal(row)} />
      <ActionsDropdown table="productCategories" row={row} />
    </Space>
  );
};
