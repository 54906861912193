import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { LogInPage } from '../../../pages/LogInPage';
import { SetUpPasswordPage } from '../../../pages/SetUpPasswordPage';
import { ROUTER_PATHS } from '../../../lib/utils/router-paths';
import { GetLinkForResetPasswordPage } from '../../../pages/GetLinkForResetPasswordPage';
import { PasswordResetPage } from '../../../pages/PasswordResetPage';
import { AuthLayout } from '../../layouts/PlatformLayout/AuthLayout';

export const AuthRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path={ROUTER_PATHS.noMatch} element={<Navigate to={ROUTER_PATHS.logIn} />} />
        <Route path={ROUTER_PATHS.logIn} element={<LogInPage />} />
        <Route path={ROUTER_PATHS.getResetPasswordLink} element={<GetLinkForResetPasswordPage />} />
        <Route path={`${ROUTER_PATHS.passwordReset}/:token`} element={<PasswordResetPage />} />
        <Route path={ROUTER_PATHS.setUpPassword} element={<SetUpPasswordPage />} />
      </Route>
    </Routes>
  );
};
