import { UserDataT } from '../../../lib/types/auth';

export const transformFromServerToFormValues = (userData: UserDataT) => {
  return {
    allAdminTenants: userData?.allAdminTenants,
    email: userData?.email,
    fullName: userData?.fullName,
    language: { value: userData?.langKey?.toUpperCase() },
    rights: { value: userData?.authority },
    tenants: userData?.tenants.map((item) => ({
      value: item?.id,
      title: item.name,
    })),
    username: userData?.login,
  };
};
