import { FC, useEffect, useMemo, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { setCurrentCategory } from '../../../../lib/store/slices/endUserSlice';
import { Category, DemandArea } from '../../../../lib/types/endUser';
import { ProductT } from '../../../../lib/types/product';
import { checkIsProductBought } from '../../../../lib/utils/end-user-helpers';
import { parseJSON, sortCategoriesByPriority } from '../../../../lib/utils/helpers';

import styles from './index.module.scss';
import { CategoryCard } from './CategoryCard';

type Props = {
  currentDemandArea: DemandArea;
  onClickCategory: (category: Category) => void;
};

export const CategoriesCards: FC<Props> = ({ currentDemandArea, onClickCategory }) => {
  const demandAreaColor = useMemo(() => {
    return parseJSON(currentDemandArea?.color);
  }, [currentDemandArea]);

  const { selectedCategory } = useAppSelector(({ endUser }) => endUser);
  const dispatch = useAppDispatch();

  const sortedCategories = useMemo(() => {
    return sortCategoriesByPriority(currentDemandArea?.categories || []);
  }, [currentDemandArea?.categories]);

  const renderRef = useRef<number | null>(null);

  useEffect(() => {
    if (sortedCategories.length > 0 && renderRef.current === null) {
      dispatch(setCurrentCategory({ category: sortedCategories[0] }));
      renderRef.current = 1;
    }
  }, [sortedCategories, dispatch]);

  return (
    <div className={styles.categoriesWrapper}>
      {sortedCategories.map((category: Category, index: number) => {
        const hasUserProducts = category.products.some((product: ProductT) => checkIsProductBought(product));

        return (
          <CategoryCard
            key={category.id}
            category={category}
            onClickCategory={onClickCategory}
            index={index}
            hasUserProducts={hasUserProducts}
            selectedCategory={selectedCategory}
            demandAreaColor={demandAreaColor}
            sortedCategories={sortedCategories}
          />
        );
      })}
    </div>
  );
};
