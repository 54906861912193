import { useTranslation } from 'react-i18next';
import { AppSelect } from '../../../../../components/AppSelect';
import { FormItem } from '../../../../../components/FormItem';

import { DEMAND_AREA_FORMITEMS } from '../../../helpers';

const categories = 'pages.products.demandArea.formItems.categories';

export const CategoriesFormItem = () => {
  const { t } = useTranslation();

  return (
    <div style={{ opacity: 0.6 }}>
      <FormItem name={DEMAND_AREA_FORMITEMS.CATEGORIES}>
        <AppSelect isMultiSelect disabled={true} label={t(categories)} />
      </FormItem>
    </div>
  );
};
