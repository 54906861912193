import { RULES_COLUMN } from '../../../lib/types/ruleBuilder';
import { ReactNode } from 'react';
import { RulesDataHeaderCell } from '../components/tables/RulesHeaderCell';

type Props = {
  title: string | ReactNode;
  arrangedId: RULES_COLUMN;
  columnProps: {
    width: number;
    render?: (params?: any, row?: any) => ReactNode;
  };
};

export const useRulesDataColumn = (props: Props) => {
  const { columnProps, ...rest } = props;

  return {
    ...columnProps,
    dataIndex: props.arrangedId,
    title: <RulesDataHeaderCell {...rest} />,
  };
};
