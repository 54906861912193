import { FormInstance, Form } from 'antd';
import { FormItemName } from '../models/Form';

export const useProjectSubdomain = (form: FormInstance, domainValue?: string) => {
  const subdomainValue = Form.useWatch(FormItemName.SUBDOMAIN, form);
  const maybeDomain = domainValue ? domainValue : '';
  const maybeSubdomain = subdomainValue ? `${subdomainValue}.` : '';
  const projectUrl = `https://${maybeSubdomain}${maybeDomain}`;

  return {
    projectUrl,
  };
};
