import { BonusWithCoastT } from '../types';

export const selectSmallBonuses = (
  bonuses: BonusWithCoastT[],
  points: number,
  wishlistPoints: number,
): BonusWithCoastT[] => {
  // points sum with wishlist
  const totalPoints = points + wishlistPoints;

  const bonusesFromWishlist = bonuses.filter((bonus) => {
    return bonus.points <= totalPoints && bonus.points >= points;
  });

  // bonuses only from the highest status level will be shown
  const bonusesWithHighestStatusLevel = bonusesFromWishlist?.filter(
    (bonus) => bonus.subLevelName == bonusesFromWishlist?.[bonusesFromWishlist?.length - 1]?.subLevelName,
  );

  return bonusesWithHighestStatusLevel;
};
