import { ButtonHTMLAttributes, FC, MouseEventHandler, ReactNode } from 'react';
import cx from 'classnames';

import styles from './index.module.scss';

type Props = {
  children?: ReactNode;
  className?: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export const BasicButton: FC<Props> = ({ children, className, disabled, type, onClick }) => {
  return (
    <button className={cx(styles.root, className)} disabled={disabled} onClick={onClick} type={type}>
      {children}
    </button>
  );
};
