import { getProductPoints } from '../../../features/endUser/utils/helpers';
import { SelectionType } from '../../../lib/types/entities';
import { ProductT } from '../../../lib/types/product';
import { getBoughtProducts, calculateUserPoints } from '../../../lib/utils/end-user-helpers';

export const getWishlistStatusLevelsData = (wishlistProducts?: ProductT[], allProducts?: ProductT[]) => {
  let wishedPoints = 0;
  const singleTypeProductsArrayInWishlist: ProductT[] = [];
  const boughtProducts = getBoughtProducts(allProducts || []);
  const boughtAllProductsBeforeSplice = [...(boughtProducts as Array<ProductT>)];

  wishlistProducts?.forEach((wishlistProduct) => {
    if (wishlistProduct.category?.selectionType === SelectionType.MULTIPLE) {
      wishedPoints += getProductPoints(wishlistProduct);
      return;
    }

    const productIntersectionIndex =
      boughtProducts?.findIndex(
        (boughtProduct) => boughtProduct.category?.id === wishlistProduct.category?.id,
      ) || 0;

    if (
      productIntersectionIndex !== -1 &&
      wishlistProduct?.id === boughtProducts?.[productIntersectionIndex]?.id
    ) {
      return; // in case user adds bought product to wishlist, does not participate in counting
    }

    if (
      singleTypeProductsArrayInWishlist.find((item) => item?.category?.id === wishlistProduct?.category?.id)
    ) {
      return; // in case user adds more than one product from one single selection type category, only the first product takes part in the calculation
    }

    singleTypeProductsArrayInWishlist.push(wishlistProduct);

    wishedPoints += getProductPoints(wishlistProduct);

    if (productIntersectionIndex !== -1) {
      boughtProducts?.splice(productIntersectionIndex, 1);
    }
  });

  const currentPointsInWishlist = calculateUserPoints(boughtProducts);

  const currentPointsWithAllPurchasedProducts = calculateUserPoints(boughtAllProductsBeforeSplice);

  return {
    wishedPoints,
    currentPointsInWishlist,
    currentPoints: currentPointsWithAllPurchasedProducts,
  };
};

type Props = {
  userPoints: number;
  maxPoints: number;
  extraPoints: number | null;
};

export const getDisplayedPointsAccordingToRules = ({ userPoints, maxPoints, extraPoints }: Props) => {
  const addedExtraPoints = extraPoints || 0;
  const addedPoints = userPoints < addedExtraPoints ? addedExtraPoints - userPoints : 0;
  const currentPointsWithExtra = userPoints + addedPoints;

  const displayedPoints = currentPointsWithExtra >= maxPoints ? maxPoints : currentPointsWithExtra;

  return displayedPoints;
};
