import { Form, FormInstance } from 'antd';
import { ArrangeFormItem } from '../../../../lib/models/Arrange';
import { getDatesRange } from '../../../../lib/utils/helpers';

export function useDateArrange(form: FormInstance, locale: 'de' | 'en') {
  const value = Form.useWatch(ArrangeFormItem.DATES, form);
  const fromDate = value?.[0];
  const toDate = value?.[1];

  const areValidDates = fromDate && toDate;

  const formattedFromDate = fromDate || undefined;
  const formattedToDate = toDate || undefined;

  const getFilterLabel = () => areValidDates && getDatesRange(fromDate, toDate, locale);
  
  return {
    getFilterLabel,
    formattedFromDate,
    formattedToDate,
  };
}
