import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, message, MessageArgsProps } from 'antd';
import { useParams } from 'react-router-dom';
import {
  useEditProductCategoryMutation,
  useGetCategoryPrioritiesQuery,
} from '../../../../lib/store/api/productCategories';
import { useResetFormOnCloseModal } from '../../../../lib/hooks/useResetFormOnCloseModal';
import { AppModal } from '../../../../components/AppModal';
import { ProductCategoryT } from '../../../../lib/types/entities';
import { FormItem } from '../../../../components/FormItem';
import { AppSelect } from '../../../../components/AppSelect';

const MESSAGE_SHOWING_TIME = 3;

const ACTIVATION_FORM_ITEM_NAME = 'activation';
const status = 'pages.products.productCategories.status';
const activateModal = 'pages.products.productCategories.activateModal';

type Props = {
  isOpen: boolean;
  close: () => void;
  selectedCategory: ProductCategoryT | null;
};

export const ActivateProductCategoryModal: FC<Props> = ({ isOpen, close, selectedCategory }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const { id: tenantId } = useParams();

  const [editProductCategory, { isLoading }] = useEditProductCategoryMutation();

  const { data: priorities } = useGetCategoryPrioritiesQuery(
    {
      tenantId: String(tenantId),
      demandAreaId: Number(selectedCategory?.demandArea?.id),
    },
    {
      skip: !isOpen || !selectedCategory?.demandArea?.id,
      refetchOnMountOrArgChange: true,
    },
  );

  useResetFormOnCloseModal({ form, isOpen });

  const updatedPriorities = priorities?.map((priority) => ({ value: priority, title: priority })) || [];

  const onConfirm = () => {
    form.submit();
  };

  const onFinish = async (formValues: { activation: { title: number; value: number } }) => {
    const messageConfig = {} as MessageArgsProps;
    if (selectedCategory) {
      try {
        await editProductCategory({
          tenantId: String(tenantId),
          data: {
            ...selectedCategory,
            priority: { number: formValues.activation.value },
            activated: true,
          },
        }).unwrap();
        messageConfig.content = t(`${status}.success`);
        messageConfig.type = 'success';
        close();
      } catch (err: any) {
        messageConfig.content = err?.data?.message || t(`${status}.error`);
        messageConfig.type = 'error';
      } finally {
        messageApi.open({
          duration: MESSAGE_SHOWING_TIME,
          ...messageConfig,
        } as MessageArgsProps);
      }
    }
  };

  return (
    <AppModal
      open={isOpen}
      onCancel={close}
      onOk={onConfirm}
      okText={t('generic.save')}
      titleText={t(`${activateModal}.title`)}
      isOkLoading={isLoading}
    >
      {contextHolder}
      {isOpen && (
        <Form form={form} onFinish={onFinish}>
          <FormItem rules={[{ required: true }]} name={ACTIVATION_FORM_ITEM_NAME} hasFeedback={true}>
            <AppSelect label={t(`${activateModal}.priority`)} options={updatedPriorities} />
          </FormItem>
        </Form>
      )}
    </AppModal>
  );
};
