import { FC } from 'react';
import { Typography } from 'antd';
import cx from 'classnames';

import styles from './index.module.scss';

export enum StatusType {
  GRAY = 'gray',
  ORANGE = 'orange',
  GREEN = 'green',
}

const { Text } = Typography;

type Props = {
  textValue: string;
  type: StatusType;
};

export const Status: FC<Props> = ({ textValue, type }) => {
  return (
    <div
      className={cx(styles.root, {
        [styles.green]: type === StatusType.GREEN,
        [styles.orange]: type === StatusType.ORANGE,
        [styles.gray]: type === StatusType.GRAY,
      })}
    >
      <Text className={styles.text}>{textValue}</Text>
    </div>
  );
};
