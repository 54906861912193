import { EndUserHouseModelMobile } from '../EndUserHouseModelMobile';
import { EndUserHouseModelDesktop } from '../EndUserHouseModelDesktop';
import { useAppSelector } from '../../../../lib/store/hooks';
import { SectionName } from '../../../../lib/models/Section';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';

export const EndUserHouseModel = () => {
  const { sections, demandArea } = useAppSelector(({ endUser }) => endUser);
  const currentSectionContent = sections?.[SectionName.HOUSE_MODEL];

  const { isMobile } = useContainerQuery();

  const slicedDemandAreas = demandArea?.listDemandArea.slice(0, 4) || [];

  return (
    <>
      {isMobile ? (
        <EndUserHouseModelMobile content={currentSectionContent} demandAreas={slicedDemandAreas} />
      ) : (
        <EndUserHouseModelDesktop content={currentSectionContent} demandAreas={slicedDemandAreas} />
      )}
    </>
  );
};
