import { FC, MouseEvent } from 'react';
import { ReactComponent as CrossIcon } from '../../../../assets/icons/CrossIcon.svg';
import '../AppMultiValue/index.scss';

type Props = { onClickRemove: (e: MouseEvent<HTMLElement>) => void };

export const ListElementValue: FC<Props> = ({ onClickRemove }) => {
  return (
    <div className="multiValue listElement">
      List Element
      <div className="multiValueRemoveContainer" onClick={onClickRemove} role="presentation">
        <CrossIcon />
      </div>
    </div>
  );
};
