import { FC } from 'react';
import { Form, FormInstance, Input } from 'antd';
import { UploadMediaResponseT } from '../../../../lib/types/media';
import { ImageSelect } from '../../../../components/ImageSelect';
import { FormItemName } from '../../../../lib/models/Form';
import { useGetPictureOptions } from '../../hooks/useGetPictureOptions';

type Props = {
  form: FormInstance;
};

export const PictureFormItem: FC<Props> = ({ form }) => {
  const picture = Form.useWatch(FormItemName.PICTURE, form);

  const { options } = useGetPictureOptions();

  const handleSelectImage = (value: UploadMediaResponseT) => {
    form.setFieldValue([FormItemName.PICTURE], value);
  };

  return (
    <>
      <Form.Item hidden name={FormItemName.PICTURE} noStyle>
        <Input type="text" />
      </Form.Item>
      <ImageSelect value={picture} onChange={handleSelectImage} options={options} />
    </>
  );
};
