import { FC } from 'react';
import cx from 'classnames';
import { ReactComponent as IconCheck } from '../../../../assets/icons/checkmark-thin.svg';
import { IconT } from '../../../designSettings/types';

import styles from './index.module.scss';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { IconComponent } from '../../../../components/IconComponent';

type Props = {
  name: string;
  icon: IconT;
  pointUnits?: string;
  active?: boolean;
  points?: number;
  categoryColor?: string;
  cardWidth?: number;
  onClick: () => void;
};

export const CategoryCard: FC<Props> = ({
  active,
  name,
  icon,
  points,
  pointUnits,
  categoryColor,
  cardWidth,
  onClick,
}) => {
  return (
    <div
      role="presentation"
      onClick={onClick}
      className={cx(styles.wrapper, { [styles.active]: active })}
      style={{ color: categoryColor, width: cardWidth }}
    >
      {active ? (
        <IconCheck className={styles.iconCheck} stroke={categoryColor} />
      ) : (
        <EndUserTypography component="Text" type="headlineFourth" className={styles.points}>
          + {points} {pointUnits?.[0]}
        </EndUserTypography>
      )}
      <IconComponent className={styles.iconCategory} iconData={icon} color="currentColor" />
      <EndUserTypography component="Text" type="headlineFifth" className={styles.name}>
        {name}
      </EndUserTypography>
    </div>
  );
};
