import { FC, useMemo } from 'react';
import { CategoriesList } from '../CategoriesList';
import { useAppSelector } from '../../../../lib/store/hooks';
import { SectionName } from '../../../../lib/models/Section';
import { EndUserTypography } from '../../../../components/EndUserTypography';

import {
  checkBoughtProductsInCategory,
  getCoveredCategories,
  getProductPointsFromCategory,
} from '../../../../lib/utils/end-user-helpers';

import styles from './index.module.scss';
import { parseJSON } from '../../../../lib/utils/helpers';

export const CategoryOverviewSection: FC = () => {
  const { sections, demandArea } = useAppSelector(({ endUser }) => endUser);
  // const navigate = useNavigate();

  const currentSectionContent = sections?.[SectionName.CATEGORY_OVERVIEW];

  const sortedCategories = useMemo(() => {
    const sortedDemandAreas = [...(demandArea?.listDemandArea || [])].map((demandArea) => {
      const sortedDemandAreaCategories = [...(demandArea.categories || [])].sort(
        (a, b) => a.priority.number - b.priority.number,
      );

      return {
        ...demandArea,
        categories: sortedDemandAreaCategories,
      };
    });

    return sortedDemandAreas || [];
  }, [demandArea]);

  const { categories, coveredCategories } = useMemo(() => {
    let coveredCategories = 0;

    const categories = sortedCategories
      ?.map((demandArea) => {
        coveredCategories += getCoveredCategories(demandArea.categories).length;

        return demandArea.categories?.map((item) => ({
          ...item,
          covered: Boolean(checkBoughtProductsInCategory(item)),
          points: getProductPointsFromCategory(item),
          categoryColor: parseJSON(demandArea.color)?.value,
        }));
      })
      .flat();

    return {
      categories,
      coveredCategories,
    };
  }, [sortedCategories]);

  // const handleClickButton = () => {
  //   if (currentSectionContent?.buttonRedirectTo) {
  //     const link = `/${currentSectionContent?.buttonRedirectTo}`;
  //
  //     navigate(link);
  //
  //     sendDataToAnalytics('CategoryOverviewSection', 'redirect to', link);
  //
  //     window.scrollTo(0, 0);
  //   }
  // };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <EndUserTypography type="headlineSecond" component="Title" level={2} className={styles.title}>
            {currentSectionContent?.headline2}
          </EndUserTypography>
          <EndUserTypography type="headlineThird" component="Title" level={3} className={styles.subTitle}>
            {currentSectionContent?.headline3}
          </EndUserTypography>

          <EndUserTypography type="headlineFourth" component="Text" className={styles.categoriesAmount}>
            {coveredCategories}/{categories?.length} {currentSectionContent?.categoryName}
          </EndUserTypography>
        </div>
      </div>
      <CategoriesList categories={categories} />
      {/*<EndUserButton className={styles.button} type="primary" onClick={handleClickButton}>*/}
      {/*  {currentSectionContent?.buttonText}*/}
      {/*</EndUserButton>*/}
    </>
  );
};
