import { FC, ReactNode } from 'react';
import { Card, CardProps } from 'antd';
import cx from 'classnames';

import styles from './styles.module.scss';

type Props = {
  children?: ReactNode;
  className?: string;
} & CardProps;
export const AppCardBasic: FC<Props> = ({ children, className, ...rest }) => {
  return (
    <Card {...rest} className={cx(styles.card, className)}>
      {children}
    </Card>
  );
};
