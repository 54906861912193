import { api, TAGS_KEY } from './index';
import { ResultWithPagination } from '../../types/pagination';
import { TableSortOrder, WITHOUT_PAGINATION_ELEMENTS_SIZE } from '../../utils/constants';
import { BonusCatalog, CreateBonusCatalogPayload, GetBonusCatalogPayloadT } from '../../types/bonusCatalog';
import { OptionT } from '../../types/common';
import { isLength } from '../../utils/helpers';

export const bonusCatalogApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBonusCatalog: build.query<ResultWithPagination<BonusCatalog[]>, GetBonusCatalogPayloadT>({
      query: ({
        page,
        filter,
        size = 10,
        sortFieldName,
        filterFieldName,
        sortOrder,
        tenantId,
        projectId,
      }) => ({
        url: `bonus-catalog/${projectId}/get-bonus-catalog?size=${size}&page=${page || 0}${
          sortFieldName && sortOrder && sortOrder !== TableSortOrder.NONE
            ? `&sort=${sortFieldName},${sortOrder}`
            : ''
        }`,
        method: 'POST',
        headers: {
          'X-TENANT': String(tenantId),
        },
        body: Array.isArray(filter)
          ? {
              [filterFieldName as string]: filter,
            }
          : filter || {},
      }),
      providesTags: [TAGS_KEY.BONUS_CATALOG],
    }),
    getAllBonuses: build.query<OptionT[], GetBonusCatalogPayloadT>({
      query: ({ tenantId, projectId }) => ({
        url: `bonus-catalog/${projectId}/get-bonus-catalog?size=${WITHOUT_PAGINATION_ELEMENTS_SIZE}&page=0`,
        method: 'POST',
        headers: {
          'X-TENANT': String(tenantId),
        },
        body: {},
      }),
      transformResponse: (response: ResultWithPagination<BonusCatalog[]>) => {
        return isLength(response?.content)
          ? response?.content.map((item) => ({
              value: item.id,
              title: item.title,
            }))
          : [];
      },
    }),
    createBonusCatalog: build.mutation<void, CreateBonusCatalogPayload>({
      query: (body) => ({
        url: 'bonus-catalog',
        method: 'POST',
        headers: {
          'X-TENANT': String(body.tenantId),
        },
        body: body,
      }),
      invalidatesTags: [TAGS_KEY.BONUS_CATALOG],
    }),

    updateBonusCatalog: build.mutation<void, CreateBonusCatalogPayload>({
      query: (body) => ({
        url: 'bonus-catalog',
        method: 'PUT',
        headers: {
          'X-TENANT': String(body.tenantId),
        },
        body: body,
      }),
      invalidatesTags: [TAGS_KEY.BONUS_CATALOG],
    }),
    deleteBonusCatalog: build.mutation<void, { tenantId: string; bonusCatalogId: number }>({
      query: ({ tenantId, bonusCatalogId }) => ({
        url: `bonus-catalog/${bonusCatalogId}/delete`,
        method: 'DELETE',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      invalidatesTags: [TAGS_KEY.BONUS_CATALOG],
    }),
  }),
});

export const {
  useCreateBonusCatalogMutation,
  useDeleteBonusCatalogMutation,
  useUpdateBonusCatalogMutation,
  useGetBonusCatalogQuery,
  useGetAllBonusesQuery,
} = bonusCatalogApi;
