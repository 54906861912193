import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { setModalOpened } from '../../../../lib/store/slices/statusLevelSlice';
import { ModalActions } from '../../../../lib/models/Modal';
import styles from '../../../productConfigurator/components/ActionButtons/index.module.scss';
import { Button } from '../../../../components/Button';

type Props = {
  disabledSaveButton: boolean;
};

export const StatusLevelActionsButton: FC<Props> = ({ disabledSaveButton }) => {
  const { t } = useTranslation();
  const selectedStatusLevel = useAppSelector(({ statusLevel }) => statusLevel.selectedStatusLevel);
  const dispatch = useAppDispatch();

  const handleDeleteClick = async () => {
    dispatch(setModalOpened(ModalActions.DELETE));
  };

  return (
    <div className={styles.wrapper}>
      {selectedStatusLevel && (
        <Button
          className={styles.deleteBtn}
          type="default"
          size="small"
          disabled={disabledSaveButton}
          onClick={handleDeleteClick}
        >
          {t('generic.delete')}
        </Button>
      )}
      <Button htmlType="submit" className={styles.saveBtn} size="small" disabled={disabledSaveButton}>
        {t('generic.save')}
      </Button>
    </div>
  );
};
