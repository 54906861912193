import {
  useAddProductToTheWishlistMutation,
  useDeleteProductFromTheWishlistMutation,
} from '../../store/api/endUser';
import { useAppSelector } from '../../store/hooks';
import { SelectionType } from '../../types/entities';
import { ProductT } from '../../types/product';

export const useUpdateWishlist = (isInBookmark: boolean) => {
  const { wishlistProducts, tenantAndProject } = useAppSelector(({ endUser }) => endUser);

  const [addToTheWishlist, { isLoading: isAddingLoading }] = useAddProductToTheWishlistMutation();
  const [deleteFromTheWishlist, { isLoading: isRemovingLoading }] = useDeleteProductFromTheWishlistMutation();

  const updateWishlist = async (product: ProductT) => {
    try {
      if (isInBookmark) {
        await deleteFromTheWishlist({
          productId: product.id,
          tenantId: String(tenantAndProject?.tenantId),
          projectId: Number(tenantAndProject?.projectId),
        }).unwrap();
      } else {
        if (product.category.selectionType === SelectionType.SINGLE) {
          const sameCategoryProduct = wishlistProducts.find(
            (item) => item.category.id === product.category.id,
          );

          if (sameCategoryProduct) {
            deleteFromTheWishlist({
              productId: sameCategoryProduct.id,
              tenantId: String(tenantAndProject?.tenantId),
              projectId: Number(tenantAndProject?.projectId),
            }).unwrap();
          }
        }
        await addToTheWishlist({
          productId: product.id,
          tenantId: String(tenantAndProject?.tenantId),
          projectId: Number(tenantAndProject?.projectId),
        }).unwrap();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return { updateWishlist, isLoading: isAddingLoading || isRemovingLoading };
};
