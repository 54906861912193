import { api, TAGS_KEY } from './index';
import { ResultWithPagination } from '../../types/pagination';
import { CreateUpdateProductPayloadT, GetProductsPayloadT, ProductT } from '../../types/product';
import { TableSortOrder, WITHOUT_PAGINATION_ELEMENTS_SIZE } from '../../utils/constants';
import { ProductCategoryT } from '../../types/entities';
import { OptionT } from '../../types/common';
import { isLength } from '../../utils/helpers';

export const productsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProducts: build.query<ResultWithPagination<ProductT[]>, GetProductsPayloadT>({
      query: ({ page, filterFieldName, sortFieldName, filter, projectId, sortOrder, tenantId }) => {
        const url = `product/${projectId}/get-products?size=10&page=${page || 0}${
          sortFieldName && sortOrder && sortOrder !== TableSortOrder.NONE
            ? `&sort=${sortFieldName},${sortOrder}`
            : ''
        }`;

        return {
          url: url,
          method: 'POST',
          body: Array.isArray(filter)
            ? {
                [filterFieldName as string]: filter,
              }
            : filter,
          headers: {
            'X-TENANT': tenantId,
          },
        };
      },
      providesTags: [TAGS_KEY.PRODUCTS],
    }),
    getProductsForFiltering: build.query<
      ResultWithPagination<ProductT[]>,
      { searchFieldName: string; search: string; tenantId: string; projectId: string }
    >({
      query: ({ searchFieldName, search, tenantId, projectId }) => ({
        url: `product/${projectId}/get-products?size=${WITHOUT_PAGINATION_ELEMENTS_SIZE}&page=0`,
        method: 'POST',
        body: {
          [searchFieldName]: search,
        },
        headers: {
          'X-TENANT': tenantId,
        },
      }),
      providesTags: [TAGS_KEY.PRODUCTS],
    }),
    getAllProducts: build.query<OptionT[], { tenantId: string; projectId: string }>({
      query: ({ tenantId, projectId }) => ({
        url: `product/${projectId}/get-products?size=${WITHOUT_PAGINATION_ELEMENTS_SIZE}&page=0`,
        method: 'POST',
        body: {},
        headers: {
          'X-TENANT': tenantId,
        },
      }),
      transformResponse: (response: ResultWithPagination<ProductT[]>) => {
        return isLength(response?.content)
          ? response?.content.map((item) => ({
              value: item.id,
              title: item.name,
            }))
          : [];
      },
      providesTags: [TAGS_KEY.PRODUCTS],
    }),
    createUpdateProduct: build.mutation<unknown, { data: CreateUpdateProductPayloadT; tenantId?: string }>({
      query: ({ data, tenantId }) => ({
        url: 'product',
        method: 'POST',
        body: data,
        headers: {
          'X-TENANT': tenantId,
        },
      }),
      invalidatesTags: [TAGS_KEY.PRODUCTS],
    }),
    getAllProductCategories: build.query<ProductCategoryT[], { tenantId: string; projectId: string }>({
      query: ({ tenantId, projectId, ...body }) => ({
        url: `category/${projectId}/get-categories?size=${WITHOUT_PAGINATION_ELEMENTS_SIZE}&page=0`,
        method: 'POST',
        body,
        headers: {
          'X-TENANT': tenantId,
        },
      }),
      transformResponse: (response: ResultWithPagination<ProductCategoryT[]>) => {
        return response?.content;
      },
    }),
    getProductsPriority: build.query<number[], { tenantId: string; categoryId: string }>({
      query: ({ tenantId, categoryId }) => ({
        url: `product/${categoryId}/get-positions`,
        method: 'GET',
        headers: {
          'X-TENANT': tenantId,
        },
      }),
    }),
    deleteProduct: build.mutation<void, { tenantId: string; productId: number }>({
      query: ({ productId, tenantId }) => ({
        url: `product/${productId}/delete`,
        method: 'DELETE',
        headers: {
          'X-TENANT': tenantId,
        },
      }),
      invalidatesTags: [TAGS_KEY.PRODUCTS],
    }),
    downloadUserReport: build.mutation<string, { tenantId: string; projectId: number }>({
      query: ({ projectId, tenantId }) => ({
        url: `product/${projectId}/get-products-data`,
        method: 'GET',
        headers: {
          'X-TENANT': tenantId,
        },
        responseHandler: async (response) => {
          const blob = await response.blob();
          const url = window.URL || window.webkitURL;

          return url.createObjectURL(blob);
        },
      }),
    }),
  }),
});

export const {
  useGetProductsQuery,
  useLazyGetProductsForFilteringQuery,
  useGetProductsForFilteringQuery,
  useGetAllProductsQuery,
  useCreateUpdateProductMutation,
  useGetAllProductCategoriesQuery,
  useGetProductsPriorityQuery,
  useDeleteProductMutation,
  useDownloadUserReportMutation,
} = productsApi;
