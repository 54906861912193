import { useTranslation } from 'react-i18next';
import { useBonusCatalogDataColumn } from './useBonusCatalogDataColumn';
import { BONUS_CATALOG_COLUMN } from '../../../../lib/types/bonusCatalog';

const bonusCategory = 'pages.bonusCatalogTab.columns.bonusCategory';

export const useBonusCatalogColumn = () => {
  const { t } = useTranslation();

  return useBonusCatalogDataColumn({
    title: t(bonusCategory),
    arrangedId: BONUS_CATALOG_COLUMN.BONUS_CATEGORY,
    columnProps: {
      width: 240,
    },
  });
};
