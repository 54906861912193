import { store } from '../store';

export const sendDataToAnalytics = (...rest: string[]) => {
  const isGuestMode = store.getState().endUser.isGuestMode;

  const text = [...(isGuestMode ? ['Is Guest'] : []), ...rest];

  if (typeof window._paq !== 'undefined') {
    window._paq.push(['trackEvent', ...text]);
  }
};
