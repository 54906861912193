import { FC } from 'react';

type Props = {
  fillColors: string[];
  primaryColor?: string;
  isBigScreen?: boolean;
};
export const WishlistDesktopFrame: FC<Props> = ({ fillColors, primaryColor, isBigScreen }) => {
  if (isBigScreen) {
    return (
      <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-5%)' }}>
        <svg width="2276" height="886" viewBox="0 0 2276 886" fill="none" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient id="gradient1" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" style={{ stopColor: primaryColor, stopOpacity: 1 }} />
              <stop offset="100%" style={{ stopColor: primaryColor, stopOpacity: 1 }} />
            </linearGradient>
            <linearGradient id="gradient2" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" style={{ stopColor: 'rgba(255, 255, 255, 0.9)', stopOpacity: 1 }} />
              <stop offset="100%" style={{ stopColor: 'rgba(255, 255, 255, 0.9)', stopOpacity: 1 }} />
            </linearGradient>
          </defs>
          <path
            d="M63.4888 474.31C10.0701 342.783 387.105 -33.3871 209.862 -57.1293L1025.31 -141.659L1379.71 730.954C1290.51 746.087 697.407 989.6 372.411 1044.74C501.4 946.256 128.167 633.561 63.4888 474.31Z"
            fill={fillColors[0]}
          />
          <path
            d="M79.3418 441.241C79.3411 305.258 591.792 3.28295 429.684 -48.7656L1251.61 3.28295V905.458C1159.4 905.458 680.617 983.766 344.61 983.766C508.059 909.678 79.3427 605.887 79.3418 441.241Z"
            fill={fillColors[1]}
          />

          <path
            d="M124.191 441.241C124.191 305.258 622.445 3.28295 464.827 -48.7656L2577.5 -13L2452.5 983.766H382.111C541.031 909.678 124.192 605.887 124.191 441.241Z"
            fill="url(#gradient1)"
          />

          <path
            d="M124.191 441.241C124.191 305.258 622.445 3.28295 464.827 -48.7656L2577.5 -13L2452.5 983.766H382.111C541.031 909.678 124.192 605.887 124.191 441.241Z"
            fill="url(#gradient2)"
          />
        </svg>
      </div>
    );
  }

  return (
    <div style={{ position: 'absolute', left: '45%', transform: 'translateX(-8%)' }}>
      <svg width="1697" height="1435" viewBox="0 0 1697 1435" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="gradient1" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style={{ stopColor: primaryColor, stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: primaryColor, stopOpacity: 1 }} />
          </linearGradient>
          <linearGradient id="gradient2" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style={{ stopColor: 'rgba(255, 255, 255, 0.9)', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: 'rgba(255, 255, 255, 0.9)', stopOpacity: 1 }} />
          </linearGradient>
        </defs>
        <path
          d="M188.801 735.791C136.406 578.118 577.759 154.701 381.28 117.53L1295.85 60.3529L1643.46 1106.43C1543.1 1119.62 867.915 1375.58 502.236 1423.62C651.572 1314.35 252.241 926.698 188.801 735.791Z"
          fill={fillColors[0]}
        />
        <path
          d="M228 724.55C227.999 564.273 800.657 208.347 619.503 147L1538 208.347V1271.7C1434.95 1271.7 899.918 1364 524.434 1364C707.086 1276.68 228.001 918.61 228 724.55Z"
          fill={fillColors[1]}
        />
        <path
          d="M276 724.55C275.999 564.273 832.92 208.347 656.744 147L1550 208.347V1271.7C1449.78 1271.7 929.453 1364 564.288 1364C741.92 1276.68 276.001 918.61 276 724.55Z"
          fill="url(#gradient1)"
        />
        <path
          d="M276 724.55C275.999 564.273 832.92 208.347 656.744 147L1550 208.347V1271.7C1449.78 1271.7 929.453 1364 564.288 1364C741.92 1276.68 276.001 918.61 276 724.55Z"
          fill="url(#gradient2)"
        />
      </svg>
    </div>
  );
};
