export enum PRODUCT_SIZE_DESKTOP {
  SMALL = 305,
  MEDIUM = 410,
  LARGE = 624,
}

export enum PRODUCT_SIZE_TABLET {
  SMALL = 284,
  MEDIUM = 284,
  LARGE = 340,
}

export enum PRODUCT_SIZE_TABLET_LANDSCAPE {
  SMALL = 305,
  MEDIUM = 351,
  LARGE = 535,
}

export const DEFAULT_SIZE = 300;
