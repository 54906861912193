import { useTranslation } from 'react-i18next';
import { RULES_COLUMN } from '../../../lib/types/ruleBuilder';
import { useRulesDataColumn } from './useRulesDataColumn';

import styles from '../../../components/Table/index.module.scss';

const editMode = 'pages.ruleBuilder.ruleColumns.editMode';

export const useRuleEditedModeColumn = () => {
  const { t } = useTranslation();

  return useRulesDataColumn({
    title: t(editMode),
    arrangedId: RULES_COLUMN.LAST_MODIFIED_BY,
    columnProps: {
      width: 200,
      render: (text: string) => (
        <span className={styles.name}>
          <span className={styles.firstLetter}>{text?.[0]}</span> {text}
        </span>
      ),
    },
  });
};
