import { ConditionsArray } from '../../modules/ruleConfigurator/types';
import { ElementSubValues, ElementValues, StateValues } from '../../modules/ruleConfigurator/types/entities';

export type RuleT = {
  id: number;
  ruleType: {
    id: number;
    name: string;
  };
  projectId: number;
  rule: ConditionsArray;
  createdBy: string | null;
  createdDate: Date;
  lastModifiedBy: Date;
  lastModifiedDate: Date;
  name: string;
  activated?: boolean;
};

export type RuleTypeT = {
  id: number;
  projectId: number;
  createdBy: string | null;
  createdDate: Date;
  name: string;
};

export enum RULES_COLUMN {
  NAME = 'name',
  TYPE = 'ruleType',
  CREATED_BY = 'createdBy',
  CREATED_DATE = 'createdDate',
  LAST_MODIFIED_BY = 'lastModifiedBy',
  LAST_MODIFIED_DATE = 'lastModifiedDate',
}

export type EditRuleTypeBodyT = {
  name: string;
  projectId: number;
  id: number;
};

export type CreateRuleTypeBodyT = Omit<EditRuleTypeBodyT, 'id'>;

export type GetPublicDictionaryResponseT = {
  arithmeticOperators: string[];
  comparisonOperators: string[];
  logicalOperators: string[];
  quantifiers: string[];
  states: {
    [StateValues.OnWishlist]: null;
    [StateValues.Recommended]: string[];
  };
  elements: {
    [ElementValues.Bonus]: object;
    [ElementValues.DataField]: object;
    [ElementValues.Product]: Record<string, any>;
    [ElementValues.Score]: object;
    [ElementValues.StatusLevel]: {
      [ElementSubValues.StatusLevel]: null;
      [ElementSubValues.Threshold]: string[];
    };
  };
};

export type CreateRulePayloadT = {
  name?: string;
  rule: ConditionsArray;
  projectId: number;
  tenantId: string;
  ruleTypeId?: number;
  ruleId?: number;
  id?: number;
  activated?: boolean;
};

export type GetRuleByIdResponseT = {
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  id: number;
  name: string;
  ruleTypeId: number;
  rule: ConditionsArray;
  blockedByUser: {
    id: number;
    fullName: string;
  };
  projectId: number;
  lastBlockedByUser: {
    id: number;
    fullName: string;
  };
};

export enum RuleStatusT {
  STARTING = 'Starting',
  ERROR = 'Error',
  COMPLETED = 'Completed',
  LOADING = 'Loading',
}

export type RuleStatusResponseT = {
  createdBy: string;
  createdDate: string;
  errorMessage: string | null;
  id: number;
  lastModifiedBy: string;
  lastModifiedDate: string;
  name: string;
  projectId: number;
  status: RuleStatusT;
  userId: number | null;
};
