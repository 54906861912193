import { FC, Fragment, useMemo } from 'react';
import { Divider } from 'antd';
import { StatusLevelCard } from '../StatusLevelCard';
import styles from './index.module.scss';
import { IconT } from '../../../designSettings/types';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { useAppSelector } from '../../../../lib/store/hooks';
import { SectionName } from '../../../../lib/models/Section';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';

type Props = {
  levels: any[];
  userPoints: number;
  remainingPointsToNextLevel: number;
  maxPoints: number;
  icon?: IconT;
};

export const StatusLevelsList: FC<Props> = ({ levels, userPoints, remainingPointsToNextLevel, icon }) => {
  const { isLargerMobile, isDesktop } = useContainerQuery();
  const { sections, demandArea } = useAppSelector(({ endUser }) => endUser);
  const currentSectionContent = sections?.[SectionName.STATUS];

  const hardcodedHeadline3 = useMemo(() => {
    const modelName = demandArea?.project.name;

    if (modelName === 'mein PlusPortal') {
      return '';
    }

    return currentSectionContent?.headline3;
  }, [currentSectionContent?.headline3, demandArea?.project.name]);

  return (
    <>
      {isLargerMobile ? (
        <div className={styles.wrapper}>
          <EndUserTypography
            component="Title"
            level={isDesktop ? 3 : 4}
            type={isDesktop ? 'headlineThird' : 'headlineFourth'}
            className={styles.title}
          >
            {hardcodedHeadline3}
          </EndUserTypography>
          <div className={styles.list}>
            {levels.map((item: any, index: number) => {
              const firstSubLevel = item.subLevels[0];
              const isActive = userPoints > firstSubLevel.points - 1;
              return (
                <Fragment key={item.id}>
                  {index !== 0 && <Divider type="vertical" className={styles.divider} />}
                  <StatusLevelCard
                    icon={icon}
                    userPoints={userPoints}
                    active={isActive}
                    status={item.name}
                    subLevels={item.subLevels}
                    points={firstSubLevel.points}
                    remainingPointsToNextLevel={remainingPointsToNextLevel}
                  />
                </Fragment>
              );
            })}
          </div>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.sublevels}>
            {levels &&
              [...levels]?.reverse()?.map((item: any) => {
                const firstSubLevel = item.subLevels[0];
                const isActive = userPoints > firstSubLevel.points - 1;

                return (
                  <StatusLevelCard
                    icon={icon}
                    userPoints={userPoints}
                    key={item.id}
                    active={isActive}
                    status={item.name}
                    subLevels={item.subLevels}
                    points={firstSubLevel.points}
                    remainingPointsToNextLevel={remainingPointsToNextLevel}
                  />
                );
              })}
          </div>
        </div>
      )}
    </>
  );
};
