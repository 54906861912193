import React from 'react';
import { components } from 'react-select';
import './index.scss';
import { ReactComponent as CrossIcon } from '../../../../assets/icons/CrossIcon.svg';

type data = {
  unselectable?: boolean;
};

type Props = {
  isSelected?: boolean;
  label?: string;
  selectProps?: data;
  data?: data;
} & any;

export function SingleSelectOption(props: Props) {
  const { isSelected, label, selectProps, data, ...others } = props;
  const showCross = selectProps?.unselectable && isSelected;

  const onClick = () => {
    if (selectProps?.unselectable && isSelected) {
      others.clearValue();
    } else {
      others.selectOption(data);
    }
  };

  return (
    <components.Option
      className="singleSelect"
      {...props}
      innerProps={{ onClick }}
    >
      <span className="SingleSelectOptionLabel">{label}</span>
      {showCross && (
        <span className="SingleSelectOptionCross">
          <CrossIcon />
        </span>
      )}
    </components.Option>
  );
}
