import { Dispatch, FC, useState } from 'react';
import { Space, Card, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { FileUploader } from '../../../../components/FileUploader';
import { MIMEType, TargetType, UploadMediaResponseT } from '../../../../lib/types/media';
import { UploadedIconBox } from '../UploadedIconBox';
import { UploadLabel } from '../../../../components/UploadLabel';
import { DesignSettingsDataT, IconT } from '../../types';
import { useDeleteMedia } from '../../../../lib/hooks/useDeleteMedia';

import styles from './index.module.scss';

const GAP_SIZE = 16;
const MAX_MEDIA_FILE_SIZE = 1;
const designSettingsTab = 'pages.designSettingsTab';

const { Text } = Typography;

type Props = {
  designSettings: DesignSettingsDataT;
  setDesignSettings: Dispatch<DesignSettingsDataT>;
};

export const IconsCard: FC<Props> = ({ designSettings, setDesignSettings }) => {
  const [uploading, setUploading] = useState(false);
  const { t } = useTranslation();
  const deleteMedia = useDeleteMedia();

  const { icons } = designSettings;

  const updateIcons = (file: UploadMediaResponseT) => {
    setDesignSettings({
      ...designSettings,
      icons: [...icons, file],
    });
  };

  const deleteIcon = (id: number, isDefaultIcon: boolean) => {
    const filteredIcons = icons.filter((file: { id: number | string }) => file.id !== id);
    setDesignSettings({ ...designSettings, icons: filteredIcons });

    if (isDefaultIcon) {
      return;
    }

    deleteMedia({ id });
  };

  const customIconsOptions = uploading ? ([...icons, { id: 'uploading' }] as UploadMediaResponseT[]) : icons;

  return (
    <Card
      className={styles.root}
      title={
        <>
          <div className={styles.titleWrapper}>
            <Text className={styles.title}>{t(`${designSettingsTab}.icons`)}</Text>
          </div>
          <div className={styles.iconsWrapper}>
            <Space size={[GAP_SIZE, GAP_SIZE]} wrap>
              {customIconsOptions?.map((icon: IconT) => (
                <UploadedIconBox
                  key={icon.id}
                  icon={icon}
                  deleteOnClick={() => deleteIcon(icon?.id, !icon.cdnUrl)}
                />
              ))}
            </Space>
          </div>
        </>
      }
    >
      <FileUploader
        targetType={TargetType.ICONS}
        accept={MIMEType.SVG_PNG_JPEG}
        afterUploadAction={updateIcons}
        errorText={t(`${designSettingsTab}.beforeUploadMediaError`)}
        maxFileSize={MAX_MEDIA_FILE_SIZE}
        disabled={uploading}
        setUploading={setUploading}
        uploadLabel={() => <UploadLabel text={t(`${designSettingsTab}.newIconUpload`)} />}
      />
    </Card>
  );
};
