import { Typography } from 'antd';
import { TENANT_COLUMN } from '../helpers/constants';
import { useTenantSearchDataColumn } from './useTenantSearchDataColumn';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const genericCommentColumnProps = {
  width: 321,
  render: (text: string) => <Text>{text ?? '-'}</Text>,
};

const comments = 'shared.columns.comments';

export const useTenantCommentColumn = () => {
  const { t } = useTranslation();

  return useTenantSearchDataColumn({
    title: t(comments),
    arrangedId: TENANT_COLUMN.COMMENT,
    searchFieldName: 'searchComment',
    filterFieldName: 'includeComments',
    columnProps: genericCommentColumnProps,
  });
};
