import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';

const { Text } = Typography;

type Props = {
  id: number;
  link: string;
};

export const TenantNameBodyCell: FC<Props> = ({ id, link }) => {
  return (
    <Link to={`/tenants/${id}/models`}>
      <Text className="primary_color">{link}</Text>
    </Link>
  );
};
