export enum PROJECT_COLUMN {
  USED_TEMPLATE = 'template',
  NAME = 'name',
  IS_ACTIVE = 'activated',
  EDIT_MODE = 'editing',
  LAST_UPDATES = 'lastModifiedDate',
  LAST_EDITED_FROM = 'lastModifiedBy',
  PUBLISHED = 'publishingDate',
  URL = 'url',
  CONTRACT_START = 'contractStart',
  CONTRACT_END = 'contractEnd',
  CONTACT_SKP = 'contactSKP',
  CREATED_BY = 'createdBy',
  CREATION_DATE = 'createdDate',
  COMMENT = 'comment',
  STATUS = 'status',
}

export const PROJECT_STATUS = {
  PUBLISHED: 'PUBLISHED',
  NOT_PUBLISHED: 'NOT_PUBLISHED',
  DEACTIVATED: false,
};
