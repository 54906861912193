import { EndUserLoginCard } from '../../modules/endUserLogin/components/EndUserLoginCard';

import styles from './styles.module.scss';
import { EndUserLoginHouseModel } from '../../modules/endUserLogin/components/EndUserLoginHouseModel';
import { EndUserRegistrationCheckCard } from '../../modules/endUserLogin/components/EndUserRegistrationCheckCard';
import { useAppSelector } from '../../lib/store/hooks';
import { EndUserLoginSquareTiles } from '../../modules/EndUserLoginSquareTiles';

export const EndUserLogin = () => {
  const { tenantAndProject } = useAppSelector(({ endUser }) => endUser);

  const applyNewDesign =
    tenantAndProject?.subDomain === 'demo-company' && tenantAndProject?.tenantId === 11289;

  return (
    <div className={styles.container}>
      {applyNewDesign ? <EndUserLoginSquareTiles /> : <EndUserLoginHouseModel />}
      <EndUserLoginCard applyNewDesign={applyNewDesign} />
      <EndUserRegistrationCheckCard />
    </div>
  );
};
