import { EMPTY_LENGTH } from './constants';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import dayjs from 'dayjs';
import { Category, DemandArea } from '../types/endUser';
import { AppDispatch } from '../store';
import { logoutAction } from '../store/actions';
import { api } from '../store/api';
import { ProductT } from '../types/product';
import { FinderProduct } from '../types/entities';
import { checkIsProductBought } from './end-user-helpers';

export const handleLocalStorageValue = ({
  value,
  safe = true,
  parse = true,
}: {
  value: string | null;
  safe?: boolean;
  parse?: boolean;
}) => {
  if (!parse || !value) return value;

  if (!safe) return JSON.parse(value);

  try {
    return JSON.parse(value);
  } catch {
    return undefined;
  }
};

export const AppLocalStorage = {
  setItem: (key: string, value: unknown) => {
    const stringified = JSON.stringify(value);
    localStorage.setItem(key, stringified);

    const event = new StorageEvent('storage', {
      key,
      newValue: stringified,
    });
    window.dispatchEvent(event);
  },
  removeItem: (key: string) => {
    localStorage.removeItem(key);

    const event = new StorageEvent('storage', {
      key,
      newValue: undefined,
    });
    window.dispatchEvent(event);
  },
  getItem: ({ key, safe, parse }: { key: string; safe?: boolean; parse?: boolean }) => {
    const value = localStorage.getItem(key);

    return handleLocalStorageValue({ value, safe, parse });
  },
};

export const isLength = (value: string | unknown[]) => value?.length > EMPTY_LENGTH;

export const toggleValue = (value: unknown) => !value;

export const secToMs = ({ sec }: { sec: number }) => sec * 1000;

export const checkObjectWithValue = (checkedValue?: object) => {
  return checkedValue && typeof checkedValue === 'object' && checkedValue.hasOwnProperty('value');
};

export const insertAtIndex = (target: any, newElements: any, index: any) =>
  target?.slice(0, index)?.concat(newElements, target?.slice(index));

export const isOdd = (num: number) => num % 2;

export const normalizePageForRequest = (page: number) => page - 1;
export const normalizePageForTable = (page: number) => page + 1;

export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

export const getDomainAndSubDomain = (link: string) => {
  const linkArr = new URL(link).hostname.split('.');
  return [linkArr[0], linkArr.slice(1).join('.')];
};

export const dateFormatter = (date: string) => {
  if (date) {
    const formattedDate = new Date(date);
    return formattedDate.toLocaleDateString('EN-GB');
  }
  return null;
};

export const isString = (value: unknown): value is string => typeof value === 'string';

export const trimForm = <T extends object>(formValues: T) =>
  Object.fromEntries(
    Object.entries(formValues).map(([key, value]) => [key, isString(value) ? value.trim() : value]),
  ) as T;

export function createFormData(data: {
  file: File;
  targetType?: string;
  targetId?: string;
  originalFileName: string;
}) {
  const formData = new FormData();

  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }

  return formData;
}

export function formatDateForTable(date: string | Date, locale: 'de' | 'en') {
  require('dayjs/locale/de');

  return date ? dayjs(date).utc().locale(locale).format('MMM DD YYYY') : undefined;
}
export function formatDateForRulesTable(date: string | Date) {
  return date ? dayjs(date).utc().format('DD/MM/YYYY') : undefined;
}

export function getDatesRange(fromDate: string | Date, toDate: string | Date, locale: 'de' | 'en') {
  return `${formatDateForTable(fromDate, locale)} / ${formatDateForTable(toDate, locale)}`;
}

export function doesObjectHaveValues<T>(obj: Record<string, T>): boolean {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key]) {
      return true; // Return true if at least one value is truthy
    }
  }
  return false; // Return false if none of the values are truthy
}

export function isObjectEmpty<T>(obj: Record<string, T>) {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    (Object?.values(obj)?.length === 0 || !doesObjectHaveValues(obj))
  );
}

export function isArrayNotEmpty<T>(arr: Array<T>) {
  return Array?.isArray(arr) && arr?.length > 0;
}

export const transformToDateWithSlash = (date: Date) => {
  return `${date?.getDate()}/${date?.getMonth() + 1}/${date?.getFullYear()}`;
};

Date.prototype.toLocaleString = function () {
  const year = this.getFullYear();
  const month = String(this.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(this.getDate()).padStart(2, '0');
  const hours = String(this.getHours()).padStart(2, '0');
  const minutes = String(this.getMinutes()).padStart(2, '0');
  const seconds = String(this.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
};

export const isProductionBuild = process.env.NODE_ENV === 'production';

export const sortCategoriesByPriority = (categories: Category[]) => {
  return categories.slice().sort((a, b) => a.priority.number - b.priority.number);
};

export const parseJSON = (jsonValue: string | undefined) => {
  try {
    return typeof jsonValue === 'string' ? JSON.parse(jsonValue) : {};
  } catch (error) {
    return {};
  }
};

export const appLogout = (dispatch: AppDispatch) => {
  dispatch(logoutAction());
  dispatch(api.util.resetApiState());
};

const findHighestScoringProductInEachCategory = (products: ProductT[]) => {
  const highestScores: { [categoryId: number]: { score: number; product: ProductT } } = {};

  products.forEach((product) => {
    const categoryId = product.category.id;
    const score = Number(product.finderScore);

    if (!highestScores[categoryId] || score > highestScores[categoryId].score) {
      highestScores[categoryId] = { score, product };
    }
  });

  return Object.values(highestScores).map((item) => ({ ...item.product, recommended: true }));
};

const findHighestScoringProductInCategory = (
  products: ProductT[],
  categoryId: number,
): ProductT | undefined => {
  let highestScoreProduct: ProductT | undefined = undefined;
  let highestScore: number | undefined = -1;

  products.forEach((product) => {
    if (product.category.id === categoryId && Number(product.finderScore) > Number(highestScore)) {
      highestScoreProduct = product;
      highestScore = product.finderScore;
    }
  });

  return highestScoreProduct;
};

export const markRecommendedProductsForAllCategories = (
  allProducts: ProductT[],
  finderProducts: FinderProduct[],
  onNavigateCallback?: (product: ProductT) => void,
  demandAreas?: DemandArea[] | null,
) => {
  const finderFullInfoProducts = allProducts.reduce((acc: ProductT[], product: ProductT) => {
    const finderProduct = finderProducts.find((item) => item.hvmProductId === product.productId);

    if (finderProduct) {
      return [
        ...acc,
        {
          ...product,
          finderScore: finderProduct.score,
        },
      ];
    }

    return acc;
  }, []);

  const highestScoringProductsInEachCategory =
    findHighestScoringProductInEachCategory(finderFullInfoProducts);

  if (highestScoringProductsInEachCategory.length > 0) {
    const foundNearestProductToRedirect = demandAreas?.reduce((acc: ProductT[], demandArea: DemandArea) => {
      //eslint-disable-next-line
      const ourProduct = demandArea.categories.reduce((categoryAcc: any, category) => {
        const { products, ...categoryInfo } = category;

        const fullInfoProductsArray = products.map((item) => {
          return { ...item, category: categoryInfo, demandAreaId: demandArea.id };
        });

        const match = fullInfoProductsArray.find((item) => {
          const isProduct = highestScoringProductsInEachCategory.find(
            (productItem) => productItem.productId === item.productId,
          );

          if (isProduct) {
            return isProduct;
          }
        });

        if (match) {
          return [...categoryAcc, match];
        }

        return categoryAcc;
      }, []);

      if (ourProduct) {
        return [...acc, ...ourProduct];
      }

      return acc;
    }, []);

    foundNearestProductToRedirect &&
      onNavigateCallback &&
      onNavigateCallback(foundNearestProductToRedirect?.[0]);
  }

  return allProducts.map((product) => {
    const recommendedProduct = highestScoringProductsInEachCategory.find(
      (item) =>
        item.productId === product.productId &&
        !checkIsProductBought(item) &&
        item.irrelevantSurveyReason === null,
    );

    if (recommendedProduct) {
      return recommendedProduct;
    }

    return { ...product, recommended: false };
  });
};

export const markRecommendedProductsForSelectedCategory = (
  allProducts: ProductT[],
  categoryId: number,
  finderProducts: FinderProduct[],
) => {
  const finderFullInfoProducts = allProducts.reduce((acc: ProductT[], product: ProductT) => {
    const finderProduct = finderProducts.find((item) => item.hvmProductId === product.productId);

    if (finderProduct) {
      return [
        ...acc,
        {
          ...product,
          finderScore: finderProduct.score,
        },
      ];
    }

    return acc;
  }, []);

  const highestScoringProductInCategory = findHighestScoringProductInCategory(
    finderFullInfoProducts,
    categoryId,
  );

  return allProducts.map((product) => {
    if (
      highestScoringProductInCategory?.productId === product.productId &&
      !checkIsProductBought(highestScoringProductInCategory) &&
      highestScoringProductInCategory.irrelevantSurveyReason === null
    ) {
      return { ...highestScoringProductInCategory, recommended: true };
    }

    return { ...product, recommended: false };
  });
};
