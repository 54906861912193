import { Typography } from 'antd';
import { TENANT_COLUMN } from '../helpers/constants';
import { TenantAssignedUsersHeaderCell } from '../components/TenantsTable/TenantAssignedUsersHeaderCell';

const { Text } = Typography;

export const useTenantAssignedUsersColumn = () => ({
  dataIndex: TENANT_COLUMN.ASSIGNED_USERS,
  title: <TenantAssignedUsersHeaderCell />,
  width: 287,
  render: (users: Array<{ login: string }>) => <Text>{users?.map((item) => item.login).join(', ')}</Text>,
});
