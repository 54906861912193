import { FC } from 'react';
import { FilterValuesPopup } from './FilterValuesPopup';
import { MainLabel } from './MainLabel';

type Props = {
  filterLabel: string;
  getParsedItems: any;
  removeFilter: () => void;
  removeFilterValue: (val: any) => void;
};

export const FilterLabel: FC<Props> = ({ getParsedItems, filterLabel, removeFilter, removeFilterValue }) => {
  if (getParsedItems) {
    return (
      <FilterValuesPopup
        filterLabel={filterLabel}
        getParsedItems={getParsedItems}
        removeFilter={removeFilter}
        removeFilterValue={removeFilterValue}
      />
    );
  }

  return <MainLabel removeFilter={removeFilter} textValue={filterLabel} />;
};
