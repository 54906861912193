import {
  useCreateStatusLevelMutation,
  useDeleteStatusLevelMutation,
  useUpdateStatusLevelMutation,
} from '../../../lib/store/api/statusLevel';

export const useStatusLevelConfigurator = () => {
  const [createStatusLevel, { isLoading: isLoadingCreating }] = useCreateStatusLevelMutation();
  const [updateStatusLevel, { isLoading: isLoadingUpdating }] = useUpdateStatusLevelMutation();
  const [deleteStatusLevel, { isLoading: isLoadingDeleting }] = useDeleteStatusLevelMutation();

  const isLoading = isLoadingCreating || isLoadingUpdating || isLoadingDeleting;

  return { createStatusLevel, updateStatusLevel, deleteStatusLevel, isLoading };
};
