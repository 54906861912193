import { api, TAGS_KEY } from './';
import { ResultWithPagination } from '../../types/pagination';
import { TenantShortInfo } from '../../models/Tentant';
import { UserDataT } from '../../types/auth';
import { TableSortOrder, WITHOUT_PAGINATION_ELEMENTS_SIZE } from '../../utils/constants';
import { User } from '../../../modules/userManagement/helpers/constants';

export type AddUserPayload = Pick<
  UserDataT,
  'authority' | 'email' | 'fullName' | 'langKey' | 'login' | 'tenants' | 'allAdminTenants'
>;

export type EditUserPayload = AddUserPayload & { id: number };

export type GetUsersPayload = {
  page?: number;
  arrangedId?: User;
  sortOrder?: TableSortOrder;
  sortFieldName?: string;
  withSorting?: boolean;
  filterFieldName?: string;
  filter?: string[];
};

export const usersManagementApi = api.injectEndpoints({
  endpoints: (build) => ({
    optionsTenants: build.query<ResultWithPagination<TenantShortInfo[]>, void>({
      query: () => ({
        url: 'tenant/get-tenants-short-info',
        method: 'POST',
        body: {},
      }),
    }),

    getUsers: build.query<ResultWithPagination<UserDataT[]>, GetUsersPayload>({
      query: ({ page, filterFieldName, sortFieldName, filter, sortOrder }) => ({
        url: `admin/get-users?size=10&page=${page || 0}${
          sortFieldName && sortOrder && sortOrder !== TableSortOrder.NONE
            ? `&sort=${sortFieldName},${sortOrder}`
            : ''
        }`,
        method: 'POST',
        body: Array.isArray(filter)
          ? {
              [filterFieldName as string]: filter,
            }
          : filter,
      }),
      providesTags: [TAGS_KEY.USER_MANAGEMENT],
    }),

    getUsersForFiltering: build.query<
      ResultWithPagination<UserDataT[]>,
      { searchFieldName: string; search: string }
    >({
      query: ({ searchFieldName, search }) => ({
        url: `admin/get-users?size=${WITHOUT_PAGINATION_ELEMENTS_SIZE}&page=0`,
        method: 'POST',
        body: {
          [searchFieldName]: search,
        },
      }),
      providesTags: [TAGS_KEY.USER_MANAGEMENT],
    }),

    addUser: build.mutation<UserDataT, AddUserPayload>({
      query: (body: AddUserPayload) => ({
        url: 'admin/users',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: [TAGS_KEY.USER_MANAGEMENT],
    }),
    updateUser: build.mutation<UserDataT, EditUserPayload>({
      query: (body: AddUserPayload) => ({
        url: 'admin/users',
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: [TAGS_KEY.USER_MANAGEMENT],
    }),
    deleteUser: build.mutation<void, number>({
      query: (userId: number) => ({
        url: `admin/users/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAGS_KEY.USER_MANAGEMENT],
    }),
    updateUserStatus: build.mutation<void, { userId: number; active: boolean }>({
      query: ({ userId, active }) => ({
        url: `admin/users/${userId}/set-active?active=${active}`,
        method: 'PATCH',
      }),
      invalidatesTags: [TAGS_KEY.USER_MANAGEMENT],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useAddUserMutation,
  useOptionsTenantsQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useUpdateUserStatusMutation,
  useLazyGetUsersForFilteringQuery,
} = usersManagementApi;
