import { FC, MouseEventHandler } from 'react';
import { BasicButton } from '../../../../components/BasicButton';
import { PreloaderIcon } from '../../../../assets/icons/PreloadIcon';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadResponseT } from '../../../../lib/types/entities';

import styles from './index.module.scss';

const { Text } = Typography;

type Props = {
  media: UploadResponseT;
  deleteOnClick: () => void;
};

export const UploadedMediaBox: FC<Props> = ({ media, deleteOnClick }) => {
  const { t } = useTranslation();

  const deleteClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    deleteOnClick();
  };

  return media.s3Url ? (
    <div className={styles.mediaWrapper} style={{ backgroundImage: `url('${media.s3Url}')` }}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className={styles.hiddenBox} onClick={(event) => event.stopPropagation()}>
        <BasicButton className={styles.button} onClick={deleteClick}>
          <Text className={styles.deleteButton}>{t('generic.delete')}</Text>
        </BasicButton>
      </div>
    </div>
  ) : (
    <div className={styles.mediaWrapperLoading}>
      <PreloaderIcon className={styles.fileUploader} />
    </div>
  );
};
