import { FC, useCallback, useEffect } from 'react';
import { Form, message } from 'antd';
import { dateFormatter, trimForm } from '../../lib/utils/helpers';

import styles from './styles.module.scss';
import { SettingsForm } from '../../modules/tenants/components/SettingsForm';
import { FormItemName } from '../../lib/models/Form';

import { Button } from '../../components/Button';
import { useProjectSubdomain } from '../../lib/hooks/useProjectSubdomain';
import {
  useGetGlobalSettingsQuery,
  useSaveGlobalSettingsMutation,
  useUpdateProjectMutation,
} from '../../lib/store/api/project';
import { ProjectT, SETTINGS_NAME } from '../../lib/types/entities';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetProject } from '../../lib/hooks/useGetProjects';
import { useGetTenantDomainsQuery } from '../../lib/store/api/tenants';
import { OptionT } from '../../lib/types/common';

type FieldsT = {
  name: FormItemName | string;
  value: any;
};

function getFields(
  generalSettings: Record<string, any> | undefined,
  project?: ProjectT,
  domains?: OptionT[],
): FieldsT[] {
  let data: any = {};

  if (generalSettings) {
    data = {
      [FormItemName.META_TITLE]: generalSettings.meta[FormItemName.META_TITLE],
      [FormItemName.META_DESCRIPTION]: generalSettings.meta[FormItemName.META_DESCRIPTION],
      [FormItemName.FAVICON]: generalSettings.meta[FormItemName.FAVICON],
      [FormItemName.MAIL_SERVER]: generalSettings.mailServer[FormItemName.MAIL_SERVER],
      [FormItemName.MAIL_SERVER_CONTACT_EMAIL]:
        generalSettings.mailServer[FormItemName.MAIL_SERVER_CONTACT_EMAIL],
      [FormItemName.MAIL_SERVER_SENDER]: generalSettings.mailServer[FormItemName.MAIL_SERVER_SENDER],
      [FormItemName.MAIL_SERVER_HOST]: generalSettings.mailServer[FormItemName.MAIL_SERVER_HOST],
      [FormItemName.MAIL_SERVER_PORT]: generalSettings.mailServer[FormItemName.MAIL_SERVER_PORT],
      [FormItemName.MAIL_SERVER_USER_NAME]: generalSettings.mailServer[FormItemName.MAIL_SERVER_USER_NAME],
      [FormItemName.MAIL_SERVER_PASSWORD]: generalSettings.mailServer[FormItemName.MAIL_SERVER_PASSWORD],
      [FormItemName.TRACKING]: generalSettings.tracking?.[FormItemName.TRACKING] || false,
      [FormItemName.TRACKER_URL]: generalSettings.tracking?.[FormItemName.TRACKER_URL] || '',
      [FormItemName.TRACKING_SOFTWARE]: generalSettings.tracking?.[FormItemName.TRACKING_SOFTWARE] || '',
      [FormItemName.TRACKING_TRACKER_SITE_ID]:
        generalSettings.tracking?.[FormItemName.TRACKING_TRACKER_SITE_ID] || '',
      [FormItemName.DATA_PROTECTION]: generalSettings.dataProtection[FormItemName.DATA_PROTECTION],
      [FormItemName.DATA_PROTECTION_HEAD]: generalSettings.dataProtection[FormItemName.DATA_PROTECTION_HEAD],
      [FormItemName.DATA_PROTECTION_SUB]: generalSettings.dataProtection[FormItemName.DATA_PROTECTION_SUB],
      [FormItemName.DATA_PROTECTION_CONTENT]:
        generalSettings.dataProtection[FormItemName.DATA_PROTECTION_CONTENT],
    };
  }

  if (project) {
    data = {
      ...data,
      [FormItemName.PROJECT_NAME]: project.name,
      [FormItemName.SELECTED_TEMPLATE]: {
        title: project.template.name,
        value: project.template.id,
      },
      [FormItemName.CREATION_DATE]: dateFormatter(project.createdDate),
      [FormItemName.SUBDOMAIN]: project.subdomain,
      [FormItemName.DOMAIN]: domains?.find((domain) => domain.title === project.domain),
    };
  }

  return Object.keys(data).map((key) => ({
    name: key,
    value: data[key],
  }));
}

const success = 'pages.settings.success';
const errors = 'pages.settings.errors';
const unknownError = `${errors}.unknownError`;

export const SettingsPage: FC = () => {
  const [form] = Form.useForm();
  const { projectUrl } = useProjectSubdomain(form);
  const [messageApi, contextHolder] = message.useMessage();
  const { t } = useTranslation();

  const { id: tenantId, modelId } = useParams();

  const [saveGlobalSettings] = useSaveGlobalSettingsMutation();
  const [updateProject] = useUpdateProjectMutation();
  const { data: domainsData } = useGetTenantDomainsQuery(String(tenantId));

  const { data: projectData } = useGetProject();

  const { data: generalSettingsData } = useGetGlobalSettingsQuery(
    {
      projectId: Number(modelId),
      settingsName: SETTINGS_NAME.generalSettings,
      tenantId: String(tenantId),
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const onFinish = useCallback(
    async (formValues: Record<string, any>) => {
      const trimmedForm = trimForm(formValues);

      const generalSettings = {
        project: {
          [FormItemName.CREATION_DATE]: trimmedForm[FormItemName.CREATION_DATE],
        },
        meta: {
          [FormItemName.META_TITLE]: trimmedForm[FormItemName.META_TITLE],
          [FormItemName.META_DESCRIPTION]: trimmedForm[FormItemName.META_DESCRIPTION],
          [FormItemName.SUBDOMAIN]: trimmedForm[FormItemName.SUBDOMAIN],
          [FormItemName.PROJECT_URL]: projectUrl,
          [FormItemName.FAVICON]: trimmedForm[FormItemName.FAVICON],
        },
        mailServer: {
          [FormItemName.MAIL_SERVER]: trimmedForm[FormItemName.MAIL_SERVER],
          [FormItemName.MAIL_SERVER_CONTACT_EMAIL]: trimmedForm[FormItemName.MAIL_SERVER_CONTACT_EMAIL],
          [FormItemName.MAIL_SERVER_SENDER]: trimmedForm[FormItemName.MAIL_SERVER_SENDER],
          [FormItemName.MAIL_SERVER_HOST]: trimmedForm[FormItemName.MAIL_SERVER_HOST],
          [FormItemName.MAIL_SERVER_PORT]: trimmedForm[FormItemName.MAIL_SERVER_PORT],
          [FormItemName.MAIL_SERVER_USER_NAME]: trimmedForm[FormItemName.MAIL_SERVER_USER_NAME],
          [FormItemName.MAIL_SERVER_PASSWORD]: trimmedForm[FormItemName.MAIL_SERVER_PASSWORD],
        },
        tracking: {
          [FormItemName.TRACKING]: trimmedForm[FormItemName.TRACKING],
        },
        dataProtection: {
          [FormItemName.DATA_PROTECTION]: trimmedForm[FormItemName.DATA_PROTECTION],
          [FormItemName.DATA_PROTECTION_HEAD]: trimmedForm[FormItemName.DATA_PROTECTION_HEAD],
          [FormItemName.DATA_PROTECTION_SUB]: trimmedForm[FormItemName.DATA_PROTECTION_SUB],
          [FormItemName.DATA_PROTECTION_CONTENT]: trimmedForm[FormItemName.DATA_PROTECTION_CONTENT],
        },
      };

      const jsonSettings = JSON.stringify(generalSettings);

      const response = await saveGlobalSettings({
        projectId: Number(modelId),
        name: SETTINGS_NAME.generalSettings,
        settings: jsonSettings,
        tenantId: String(tenantId),
      });

      const updateProjectResponse = await updateProject({
        id: Number(modelId),
        name: formValues[FormItemName.PROJECT_NAME].trim(),
        tenantId: String(tenantId),
        template: {
          id: formValues[FormItemName.SELECTED_TEMPLATE].value,
          name: formValues[FormItemName.SELECTED_TEMPLATE].title,
        },
        subdomain: formValues[FormItemName.SUBDOMAIN],
        domain: formValues[FormItemName.DOMAIN].title,
      });

      if ('error' in response || 'error' in updateProjectResponse) {
        const errorJSON = JSON.stringify(updateProjectResponse);
        const errorProjectObject = JSON.parse(errorJSON);

        const errorMessage = errorProjectObject.error.data.message || unknownError;

        messageApi.error(t(errorMessage));

        return;
      }

      messageApi.success(t(`${success}.saveDataSuccess`));
    },
    [messageApi, modelId, projectUrl, saveGlobalSettings, t, tenantId, updateProject],
  );

  useEffect(() => {
    if (generalSettingsData || projectData) {
      const generalSettings = getFields(generalSettingsData, projectData, domainsData);

      if (generalSettings) {
        form.setFields(generalSettings);
      }
    }
  }, [form, generalSettingsData, projectData]);

  return (
    <div className={styles.container}>
      {contextHolder}
      <Form
        initialValues={{ [FormItemName.MAIL_SERVER]: 'DEFAULT' }}
        form={form}
        id="settings"
        style={{ maxWidth: 936, margin: '0 auto' }}
        layout="vertical"
        onFinish={onFinish}
        scrollToFirstError={{
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 24 }}>
          <Button className={styles.submitButton} htmlType="submit">
            {t('generic.save')}
          </Button>
        </div>
        <SettingsForm form={form} />
      </Form>
    </div>
  );
};
