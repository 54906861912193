import { Filter, FiltersPayload, Sort } from '../../types/filteringAndSort';
import { ModalActions } from '../../models/Modal';
import { ProductCategoryT } from '../../types/entities';
import { PRODUCT_CATEGORIES } from '../../../modules/productCategories/helpers/constants';
import { TableSortOrder } from '../../utils/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { productCategoriesApi } from '../api/productCategories';

type ProductCategoriesSliceT = {
  page: number;
  arrangeId: PRODUCT_CATEGORIES | null;
  sorting: Sort;
  filtering: Filter;

  modalType: ModalActions | null;
  isModalOpen: boolean;
  selectedCategory: ProductCategoryT | null;
};

export type ProductCategoriesFilteringPayload = FiltersPayload & {
  arrangeId: PRODUCT_CATEGORIES;
};

const initialState: ProductCategoriesSliceT = {
  page: 1,
  arrangeId: null,
  sorting: {
    sortOrder: TableSortOrder.NONE,
    sortFieldName: null,
    withSorting: true,
  },
  filtering: {
    filterFieldName: null,
    filter: [],
  },
  isModalOpen: false,
  modalType: null,
  selectedCategory: null,
};

const productCategoriesSlice = createSlice({
  name: 'productCategories',
  initialState,
  reducers: {
    setModalOpened: (state, action: PayloadAction<ModalActions>) => {
      state.modalType = action.payload;
      state.isModalOpen = true;
    },
    setModalClosed: (state) => {
      state.selectedCategory = null;
      state.modalType = null;
      state.isModalOpen = false;
    },
    setCurrentCategory: (state, action: PayloadAction<ProductCategoryT>) => {
      state.selectedCategory = action.payload;
    },
    setFilters: (state, action: PayloadAction<ProductCategoriesFilteringPayload>) => {
      state.arrangeId = action.payload.arrangeId;
      state.sorting = action.payload.sorting;
      state.filtering = action.payload.filtering;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    resetFields: (state) => {
      state.arrangeId = null;
      state.sorting = {
        sortOrder: TableSortOrder.NONE,
        sortFieldName: null,
        withSorting: true,
      };
      state.filtering = {
        filterFieldName: null,
        filter: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(productCategoriesApi.endpoints.createProductCategory.matchFulfilled, (state) => {
        state.isModalOpen = false;
        state.modalType = null;
      })
      .addMatcher(productCategoriesApi.endpoints.editProductCategory.matchFulfilled, (state) => {
        state.isModalOpen = false;
        state.modalType = null;
        state.selectedCategory = null;
      });
    // .addMatcher(productCategoriesApi.endpoints.deleteProductCategory.matchFulfilled, (state) => {
    //   state.isModalOpen = false;
    //   state.modalType = null;
    // });
  },
});

export const { setModalOpened, setModalClosed, setCurrentCategory, setFilters, setPage, resetFields } =
  productCategoriesSlice.actions;

export const productCategories = productCategoriesSlice.reducer;
