import { FC } from 'react';
import { Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { IconT } from '../../../designSettings/types';
import { EndUserButton } from '../../../../components/EndUserButton';
import { useAppSelector } from '../../../../lib/store/hooks';
import { ColorVariants } from '../../../../lib/types/colors&fonts';
import { SectionName } from '../../../../lib/models/Section';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import { sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';

const { Text } = Typography;
const statusLevelT = 'endUser.statusLevel';

type Props = {
  remainingPointsToNextLevel: number;
  userPoints: number;
  maxPoints: number;
  levels: any;
  icon: IconT | undefined;
  displayedUserPoints?: number;
};

export const CurrentStatusLevelWishlistCard: FC<Props> = ({
  remainingPointsToNextLevel,
  userPoints,
  maxPoints,
  levels,
  icon,
}) => {
  const navigate = useNavigate();
  const { isMobile } = useContainerQuery();
  const { t } = useTranslation();

  const nextLevel = levels.find((subLevel: any) => subLevel.points > Number(userPoints));

  const { sections, brandKit } = useAppSelector(({ endUser }) => endUser);

  const currentSectionContent = sections?.[SectionName.STATUS_SMALL];

  const sortedLevels = [...levels].sort((a: any, b: any) => b.points - a.points);
  const userCoveredLevels = sortedLevels.filter((level: any) => Number(userPoints) >= level.points);
  const currentLevel =
    userCoveredLevels.length > 0 ? userCoveredLevels[0] : sortedLevels[sortedLevels.length - 1];

  const handleClickMainButton = () => {
    sendDataToAnalytics(
      'Status level wishlist section',
      'Primary button',
      `Redirect to: ${currentSectionContent?.buttonRedirectTo}`,
    );

    navigate(`/${currentSectionContent?.buttonRedirectTo}`);
  };

  return (
    <div className={styles.wrapper} style={{ color: brandKit?.colors[ColorVariants.PRIMARY_COLOR] }}>
      <EndUserTypography component="Text" type="headlineFifth" className={styles.title}>
        {t(`${statusLevelT}.yourStatus`)}
      </EndUserTypography>{' '}
      <EndUserTypography type="headlineFifth" component="Text" className={styles.statusName}>
        {currentLevel?.statusLevelName}
      </EndUserTypography>
      <div
        className={styles.pointsSection}
        style={{ marginBottom: remainingPointsToNextLevel >= 0 ? 3 : 52 }}
      >
        <Text className={styles.currentPoints}>
          {userPoints}{' '}
          <img src={icon?.s3Url} alt="icon" style={{ width: isMobile ? '24px' : '40px', height: '40px' }} />/
        </Text>
        <Text className={styles.points}>
          {maxPoints}
          <img
            src={icon?.s3Url}
            alt="icon"
            style={{ width: isMobile ? '14px' : '20px', height: isMobile ? '14px' : '20px' }}
          />
        </Text>
      </div>
      {remainingPointsToNextLevel >= 0 ? (
        <Text className={styles.description}>
          <span className={styles.bold}>
            {remainingPointsToNextLevel}{' '}
            <img src={icon?.s3Url} alt="icon" style={{ width: '14px', height: '14px' }} />
          </span>{' '}
          {t(`${statusLevelT}.till`)}{' '}
          <span className={styles.bold}>
            {nextLevel?.statusLevelName || sortedLevels?.at(0)?.statusLevelName} {t(`${statusLevelT}.status`)}
          </span>
        </Text>
      ) : null}
      <EndUserButton type="primary" onClick={handleClickMainButton} className={styles.button}>
        {currentSectionContent?.buttonText}
      </EndUserButton>
    </div>
  );
};
