import { PROJECT_COLUMN } from '../helpers/constants';
import { useTranslation } from 'react-i18next';

import { useProjectSearchDataColumn } from './useProjectSearchDataColumn';

const url = 'pages.projects.columns.url';

export const useUrlColumn = () => {
  const { t } = useTranslation();

  return useProjectSearchDataColumn({
    title: t(url),
    disableSorting: true,
    arrangedId: PROJECT_COLUMN.URL,
    searchFieldName: 'searchByUrl',
    filterFieldName: 'includeUrls',
  });
};
