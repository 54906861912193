type Props = {
  styles?: string;
};

export function LeftArrow({ styles = '' }: Props) {
  return (
    <svg
      className={styles}
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill={styles ? 'currentColor' : '#191919'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.35489 0.646894C7.40146 0.69334 7.4384 0.748515 7.46361 0.80926C7.48881 0.870005 7.50179 0.935127 7.50179 1.00089C7.50179 1.06666 7.48881 1.13178 7.46361 1.19253C7.4384 1.25327 7.40146 1.30845 7.35489 1.35489L1.70789 7.00089L7.35489 12.6469C7.44878 12.7408 7.50153 12.8681 7.50153 13.0009C7.50153 13.1337 7.44878 13.261 7.35489 13.3549C7.26101 13.4488 7.13367 13.5015 7.00089 13.5015C6.86812 13.5015 6.74078 13.4488 6.64689 13.3549L0.646894 7.35489C0.60033 7.30845 0.563387 7.25327 0.538181 7.19253C0.512974 7.13178 0.5 7.06666 0.5 7.00089C0.5 6.93513 0.512974 6.87001 0.538181 6.80926C0.563387 6.74851 0.60033 6.69334 0.646894 6.64689L6.64689 0.646894C6.69334 0.600331 6.74852 0.563388 6.80926 0.538181C6.87001 0.512975 6.93513 0.5 7.00089 0.5C7.06666 0.5 7.13178 0.512975 7.19253 0.538181C7.25327 0.563388 7.30845 0.600331 7.35489 0.646894V0.646894Z"
        fill="currentColor"
      />
    </svg>
  );
}
