import { useTranslation } from 'react-i18next';
import { useUserSearchDataColumn } from '../useUserSearchDataColumn';
import { User } from '../../../helpers/constants';

const email = 'shared.columns.email';

export const useUserEmailColumn = () => {
  const { t } = useTranslation();

  return useUserSearchDataColumn({
    title: t(email),
    arrangedId: User.EMAIL,
    searchFieldName: 'searchByEmail',
    filterFieldName: 'includeEmails',
    columnProps: {
      width: 270,
    },
  });
};
