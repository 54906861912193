import React, { FC, useRef, useState } from 'react';
import Slider from 'react-slick';
import { Button } from 'antd';
import cn from 'classnames';
import { useGetCarouselSettings } from '../../hooks/useGetCarouselSettings';
import { EndUserProductCard } from '../../../endUserProduct/components/EndUserProductCard';
import { useWindowResize } from '../../../../lib/hooks/useWindowResize';
import { ProductDetailsSectionT } from '../../../../lib/types/sections';
import { CarouselButtons } from '../CarouselButtons';
import { BrandKitThemeConfiguration } from '../../../../lib/types/colors&fonts';
import { EndUserContactFormModal } from '../../../endUserContactForm/components/ContactFormModal';
import { ProductT } from '../../../../lib/types/product';

import styles from './styles.module.scss';
import { useTextLineCount } from '../../../../lib/hooks/endUserHooks/useTextLineCount';

type Props = {
  productSection: ProductDetailsSectionT;
  brandKit: BrandKitThemeConfiguration | null;
  isGuestMode: boolean;
};

export const NonMobileProductCarousel: FC<Props> = ({ productSection, brandKit, isGuestMode }) => {
  const { windowWidth } = useWindowResize();
  const [selectedProduct, setSelectedProduct] = useState<ProductT | null>(null);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const hiddenContainerRef = useRef<HTMLDivElement | null>(null);
  const carouselRef = useRef<Slider>(null);

  const settings = useGetCarouselSettings({
    preSelectedCardIndex: 0,
    windowWidth,
  });

  const slideToIndex = (index: number) => {
    if (carouselRef.current) {
      carouselRef.current.slickGoTo(index);
    }
  };

  const handleOpenContactModal = (product: ProductT) => {
    setIsContactModalOpen(true);
    setSelectedProduct(product);
  };

  const handleCloseContactModal = () => {
    setIsContactModalOpen(false);
    setSelectedProduct(null);
  };

  const genericProps = {
    arePointsShown: productSection.arePointsShown,
    isPriceShown: productSection.isPriceShown,
    bullet: productSection.bullet,
    pointsIcon: productSection.pointsIcon,
    onOpenModal: handleOpenContactModal,
    brandKit,
    frameColor: productSection.frameColor,
  };

  const primaryColor = brandKit?.colors?.primaryColor;

  return (
    <>
      <div
        id="hiddenContainer2"
        ref={hiddenContainerRef}
        style={{
          visibility: 'hidden',
          position: 'absolute',
          top: '0',
          left: '0',
        }}
      />
      {productSection.products.length > 0 && (
        <div className={styles.container}>
          <Slider
            key={productSection.products.at(-1)?.id}
            ref={carouselRef}
            appendDots={(dots) => {
              return (
                <CarouselButtons carouselRef={carouselRef}>
                  <div className={styles.dotsContainer}>
                    {Array.isArray(dots) &&
                      dots.map((dot) => {
                        const isActive = dot?.props?.className === 'slick-active';

                        return (
                          <Button
                            onClick={() => slideToIndex(dot.key)}
                            key={dot.key}
                            style={{
                              backgroundColor: isActive
                                ? primaryColor || 'rgb(0, 167, 117)'
                                : 'rgb(0 0 0 / 20%)',
                            }}
                            className={cn(styles.dot, isActive && styles.dotActive)}
                          />
                        );
                      })}
                  </div>
                </CarouselButtons>
              );
            }}
            {...settings}
          >
            {productSection.products.map((item) => (
              <EndUserProductCard
                {...genericProps}
                key={item.id}
                product={item}
                isIrrelevant={item.irrelevantSurveyReason}
                isInWishlist={item.isWishlist}
                isGuestMode={isGuestMode}
              />
            ))}
          </Slider>
        </div>
      )}
      <EndUserContactFormModal
        products={selectedProduct ? [selectedProduct] : null}
        isOpen={isContactModalOpen}
        onClose={handleCloseContactModal}
      />
    </>
  );
};
