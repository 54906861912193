import React, { FC, useMemo } from 'react';
import { Category, DemandArea } from '../../../../lib/types/endUser';
import { parseJSON, sortCategoriesByPriority } from '../../../../lib/utils/helpers';
import styles from './index.module.scss';
import cn from 'classnames';
import {
  checkBoughtProductsInCategory,
  getCoveredProductsInCategory,
} from '../../../../lib/utils/end-user-helpers';
import { IconComponent } from '../../../../components/IconComponent';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { ProgressBar } from '../ProgressBar';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import {
  getPercentToTranslateToLeftForDesktops,
  getPercentToTranslateToLeftForTablets,
  getPercentToTranslateToTopForDesktops,
  getPercentToTranslateToTopForTablets,
} from './helpers';

type Heights = {
  [key: number]: number;
};

type Widths = {
  [key: number]: {
    [key: number]: number;
    default: number;
  };
};

type Props = {
  handleTabClick: (index: number) => void;
  activeTab?: number;
  currentDemandArea: any;
  demandAreas: DemandArea[];
  onClickCategory: (category: Category) => void;
  applyNewDesign?: boolean;
};
export const SquareShadowTiles: FC<Props> = ({
  handleTabClick,
  activeTab,
  onClickCategory,
  demandAreas,
  currentDemandArea,
  applyNewDesign,
}) => {
  const { isOnlyTablet, isLargerDesktop } = useContainerQuery();

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, index: number) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleTabClick(index);
    }
  };

  const handleKeyDownCategory = (event: React.KeyboardEvent<HTMLDivElement>, category: Category) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClickCategory(category);
    }
  };

  const bigSquareStyle = useMemo(() => {
    const isLastElement = activeTab === demandAreas.length - 1;
    const isOddArea = (Number(activeTab) + 1) % 2 !== 0;
    const isLastOddArea = isLastElement && isOddArea;

    const percentMoveToTop = isOnlyTablet
      ? getPercentToTranslateToTopForTablets(demandAreas.length)
      : getPercentToTranslateToTopForDesktops(demandAreas.length);

    const percentMoveToLeft = isOnlyTablet
      ? getPercentToTranslateToLeftForTablets(demandAreas.length, isLastOddArea)
      : getPercentToTranslateToLeftForDesktops(demandAreas.length, isLastOddArea);

    return {
      transform: `translate(-${
        (isLastOddArea ? percentMoveToLeft : Number(activeTab) % 2) * percentMoveToLeft
      }%, -${Math.floor(Number(activeTab) / 2) * percentMoveToTop}%)`,
    };
  }, [activeTab, demandAreas.length, isOnlyTablet]);

  const demandAreaColor = useMemo(() => {
    return parseJSON(currentDemandArea.color);
  }, [currentDemandArea]);

  const squareHeight = useMemo(() => {
    const countOfAreas: number = demandAreas.length;

    const heights: { largerDesktop: Heights; onlyTablet: Heights } = {
      largerDesktop: { 5: 198, 4: 248, 3: 198, 2: 248, 1: 248 },
      onlyTablet: { 5: 168, 4: 174, 3: 168, 2: 176, 1: 186 },
    };

    if (isLargerDesktop) {
      return { height: heights.largerDesktop[countOfAreas] };
    } else if (isOnlyTablet) {
      return { height: heights.onlyTablet[countOfAreas] };
    }

    return {};
  }, [demandAreas.length, isLargerDesktop, isOnlyTablet]);

  const areaContainerWidth = useMemo(() => {
    const countOfAreas: number = demandAreas.length;

    const widths: { largerDesktop: Widths; onlyTablet: Widths } = {
      largerDesktop: {
        5: { default: 912, 4: 607 },
        4: { default: 1064 },
        3: { default: 912, 2: 607 },
        2: { default: 1064 },
        1: { default: 526 },
      },
      onlyTablet: {
        5: { default: 656, 4: 383 },
        4: { default: 696 },
        3: { default: 606, 2: 363 },
        2: { default: 696 },
        1: { default: 342 },
      },
    };

    const getWidth = (deviceWidths: Widths, count: number, tab: number) => {
      return { width: deviceWidths[count][tab] || deviceWidths[count].default };
    };

    if (isLargerDesktop) {
      return getWidth(widths.largerDesktop, countOfAreas, Number(activeTab));
    } else if (isOnlyTablet) {
      return getWidth(widths.onlyTablet, countOfAreas, Number(activeTab));
    }

    return {};
  }, [activeTab, demandAreas.length, isLargerDesktop, isOnlyTablet]);

  const mainContainerStyle = useMemo(() => {
    const demandAreaCount = demandAreas.length;

    if (demandAreaCount === 5) {
      if (isOnlyTablet) {
        return { margin: '40px 8px 25px' };
      }

      return { margin: '146px 8px 88px' };
    } else if (demandAreaCount === 4) {
      if (isOnlyTablet) {
        return { margin: '37px 8px 25px' };
      }

      return { margin: '121px 8px 66px' };
    } else if (demandAreaCount === 3) {
      if (isOnlyTablet) {
        return { margin: '40px 8px 25px' };
      }

      return { margin: '146px 8px 88px' };
    } else if (demandAreaCount === 2) {
      if (isOnlyTablet) {
        return { margin: '36px 8px 25px' };
      }

      return { margin: '121px 8px 66px' };
    } else if (demandAreaCount === 1) {
      if (isOnlyTablet) {
        return { margin: '31px 8px 25px' };
      }

      return { margin: '121px 8px 66px' };
    }
  }, [demandAreas.length, isOnlyTablet]);

  return (
    <div className={styles.squareSectionContainer}>
      <div className={styles.squareContainer} style={{ backgroundColor: demandAreaColor.value }}>
        <div
          className={cn(styles.container)}
          style={{ ...bigSquareStyle, ...areaContainerWidth, ...mainContainerStyle }}
        >
          <div className={styles.innerSquare} style={{ height: '100%' }}>
            {demandAreas.map((area: DemandArea, outerIndex: number, array) => {
              const isOuterArea = outerIndex !== activeTab;

              const areaColor = parseJSON(area?.color);
              const sortedCategories = sortCategoriesByPriority(area.categories);

              const isLastElement = outerIndex === array.length - 1;
              const isOddArea = (outerIndex + 1) % 2 !== 0;
              const isLastOddArea = isLastElement && isOddArea;

              return (
                <div
                  tabIndex={0}
                  role="button"
                  className={cn(
                    styles.squareSection,
                    isLastOddArea && styles.oddSquare,
                    outerIndex !== activeTab && styles.outerSquare,
                  )}
                  onClick={() => handleTabClick(outerIndex)}
                  onKeyDown={(event) => handleKeyDown(event, outerIndex)}
                  key={outerIndex}
                  style={{
                    ...squareHeight,
                    background:
                      activeTab === outerIndex
                        ? `color-mix(in srgb, ${areaColor.value} 50%, #000000 1%)`
                        : '#ffffff40',
                  }}
                >
                  <div className={styles.categoriesContainer}>
                    {sortedCategories.map((category: Category) => {
                      const hasUserProducts = checkBoughtProductsInCategory(category);

                      const categoryBackgroundColor2 = isOuterArea
                        ? `linear-gradient(0deg, rgb(255 255 255 / 85%) 0%, rgb(255 255 255 / 85%) 100%), ${demandAreaColor.value}`
                        : hasUserProducts
                          ? areaColor.value
                          : `rgba(255, 255, 255, 0.8)`;

                      const coveredProductsAmount = getCoveredProductsInCategory(category).length;

                      return (
                        <div
                          tabIndex={0}
                          role="button"
                          style={{
                            cursor: activeTab === outerIndex ? 'pointer' : 'default',
                            background: categoryBackgroundColor2,
                          }}
                          onClick={() => onClickCategory(category)}
                          onKeyDown={(event) => handleKeyDownCategory(event, category)}
                          key={category.id}
                          className={cn(
                            styles.rectangleCard,
                            hasUserProducts && styles.categoryCovered,
                            isOuterArea && styles.categoryOuter,
                          )}
                        >
                          {activeTab === outerIndex ? (
                            <>
                              <IconComponent
                                className={styles.icon}
                                iconData={category?.icon}
                                color={hasUserProducts ? '#fff' : areaColor.value}
                              />
                              <EndUserTypography
                                type="subText"
                                component="Text"
                                className={styles.title}
                                style={{
                                  color: hasUserProducts ? '#fff' : areaColor.value,
                                  textAlign: 'center',
                                  height: '40px',
                                }}
                              >
                                {category.name}
                              </EndUserTypography>

                              <EndUserTypography
                                type="subText"
                                component="Text"
                                className={styles.coveredCategoriesAmount}
                                style={{
                                  color: hasUserProducts ? '#fff' : areaColor.value,
                                  textAlign: 'center',
                                }}
                              >
                                {coveredProductsAmount}/{category.products.length}
                              </EndUserTypography>
                            </>
                          ) : null}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <ProgressBar
        applyNewDesign={applyNewDesign}
        containerClassName={styles.progressBarContainer}
        currentDemandArea={currentDemandArea}
      />
    </div>
  );
};
