import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormItemName } from '../../../../../lib/models/Form';
import { FormItem } from '../../../../../components/FormItem';
import { AppSelect } from '../../../../../components/AppSelect';
import { UserRolesT } from '../../../../../lib/types/auth';
import { useAppSelector } from '../../../../../lib/store/hooks';

const userManagement = 'pages.userManagement';
const editor = 'shared.roles.editor';
const superAdmin = 'shared.roles.superAdmin';
const admin = 'shared.roles.admin';

type Props = {
  onChange?: (valueObj: { title: string; value: string }) => void;
};

export const RightsFormItem: FC<Props> = ({ onChange }) => {
  const { t } = useTranslation();

  const userRole = useAppSelector(({ auth }) => auth.role);

  const RIGHTS: { title: string; value: UserRolesT }[] = [
    { title: t(superAdmin), value: 'ROLE_SUPER_ADMIN' },
    { title: t(admin), value: 'ROLE_ADMIN' },
    { title: t(editor), value: 'ROLE_EDITOR' },
  ];

  const modifiedRights = RIGHTS.filter((right) => {
    return userRole === 'ROLE_SUPER_ADMIN' || right.value !== 'ROLE_SUPER_ADMIN';
  });

  return (
    <FormItem rules={[{ required: true }]} name={FormItemName.RIGHTS} hasFeedback={true}>
      <AppSelect
        onChange={onChange}
        unselectable
        label={t(`${userManagement}.formItems.rights`)}
        options={modifiedRights}
      />
    </FormItem>
  );
};
