import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../lib/store/hooks';
import { BonusT } from '../../../../lib/types/statusLevel';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { Button } from '../../../../components/Button';
import { EndUserReceivedBonusModal } from '../EndUserReceivedBonusModal';
import { sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';
import { AppCheckbox } from '../../../../components/AppCheckox';
import { ReactComponent as ChevronDownIcon } from '../../../../assets/icons/ChevronDownIcon.svg';
import { EndUserReceivedBonusesCard } from '../EndUserReceivedBonusesCard';
import {
  useGetReceivedBonusesQuery,
  useSwitchActivationBonusesMutation,
} from '../../../../lib/store/api/endUser';
import { useGetExpirationBonusDate } from '../../hooks/useGetExpirationBonusDate';
import { SectionName } from '../../../../lib/models/Section';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';

const translation = 'endUser.bonuses';

export const EndUserReceivedBonusesSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBonus] = useState<BonusT | null>(null);
  const [switchActivationBonuses] = useSwitchActivationBonusesMutation();
  const [isChecked, setIsChecked] = useState(false);
  const [showAllBonuses, setShowAllBonuses] = useState(false);
  const { t } = useTranslation();

  const { tenantAndProject, sections, brandKit } = useAppSelector(({ endUser }) => endUser);
  const currentSectionContent = sections?.[SectionName.POINTS_OVERVIEW];

  const expirationBonusDate = useGetExpirationBonusDate();

  const onModalClose = () => {
    sendDataToAnalytics(
      'Received bonus',
      `Close bonus, Bonus name: ${selectedBonus?.name}, bonus id: ${selectedBonus?.id}`,
    );

    setIsModalOpen(false);
  };

  const { data: receivedBonuses, refetch } = useGetReceivedBonusesQuery({
    tenantId: Number(tenantAndProject?.tenantId),
    projectId: Number(tenantAndProject?.projectId),
  });

  const handleCheckboxChange = async () => {
    const bonusIds = receivedBonuses?.content.map((bonus) => bonus.bonusCatalog.id);
    setIsChecked(!isChecked);
    try {
      await switchActivationBonuses({
        status: !isChecked,
        bonuses: bonusIds,
        tenantId: String(tenantAndProject?.tenantId),
        projectId: Number(tenantAndProject?.projectId),
      }).unwrap();
      refetch();

      sendDataToAnalytics('Activate all bonuses');
    } catch (error) {
      console.error('Error while switching bonus activation:', error);
    }
  };

  const handleShowMoreClick = () => {
    sendDataToAnalytics('Received bonuses, show more');
    setShowAllBonuses(true);
  };

  const receivedActiveBonuses =
    receivedBonuses?.content.filter(
      (bonus) => bonus.paymentStatus !== 'DONATED' && bonus.paymentStatus !== 'EXPIRED',
    ) || [];

  // const receivedExpiredBonuses =
  //   receivedBonuses?.content.filter((bonus) => bonus.paymentStatus === 'EXPIRED') || [];

  const receivedPaidOutBonuses =
    receivedBonuses?.content.filter((bonus) => bonus.paymentStatus === 'DONATED') || [];

  const sortedReceivedBonuses = [
    ...receivedActiveBonuses,
    ...receivedPaidOutBonuses,
    // ...receivedExpiredBonuses,
  ];

  const areAllActivated = () => {
    return receivedBonuses?.content && receivedBonuses.content.every((bonus) => bonus.activated);
  };

  useEffect(() => {
    setIsChecked(!!areAllActivated());
  }, [receivedBonuses]);

  return (
    <>
      <div
        style={{
          backgroundColor: brandKit?.colors.backgroundColor,
        }}
        className={styles.container}
      >
        <div className={styles.headerWrapper}>
          <EndUserTypography className={styles.title} component="Title" level={4} type="headlineFourth">
            {currentSectionContent?.headline2Subsection1}
          </EndUserTypography>
          <AppCheckbox
            checked={isChecked}
            onChange={handleCheckboxChange}
            label={
              <EndUserTypography component="Text" type="subText" style={{ color: '#444' }}>
                {t(`${translation}.activate`)}
              </EndUserTypography>
            }
          />
        </div>
        <EndUserTypography component="Text" type="subText" style={{ marginBottom: '16px' }}>
          {t(`${translation}.date`)}
          {expirationBonusDate}
        </EndUserTypography>
        <div className={styles.list}>
          {showAllBonuses
            ? sortedReceivedBonuses.map((bonus) => (
                <React.Fragment key={bonus.id}>
                  <EndUserReceivedBonusesCard bonus={bonus} />
                </React.Fragment>
              ))
            : sortedReceivedBonuses.slice(0, 5).map((bonus) => (
                <React.Fragment key={bonus.id}>
                  <EndUserReceivedBonusesCard bonus={bonus} />
                </React.Fragment>
              ))}
        </div>
        {sortedReceivedBonuses.length > 5 && !showAllBonuses ? (
          <div className={styles.buttonShowMoreWrapper}>
            <Button
              className={styles.buttonShowMore}
              icon={<ChevronDownIcon fill="#444" />}
              type="link"
              onClick={handleShowMoreClick}
            >
              {t(`${translation}.more`)}
            </Button>
          </div>
        ) : null}
      </div>
      <EndUserReceivedBonusModal
        isModalOpen={isModalOpen}
        close={onModalClose}
        selectedBonus={selectedBonus}
      />
    </>
  );
};
