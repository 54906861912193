import { Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../lib/store/hooks';
import { ModalActions } from '../../../../../lib/models/Modal';
import { useResetFormOnCloseModal } from '../../../../../lib/hooks/useResetFormOnCloseModal';
import { setModalClosed } from '../../../../../lib/store/slices/demandAreasSlice';
import { AppModal } from '../../../../../components/AppModal';
import styles from '../../DemandAreas/index.module.scss';
import { NameFormItem } from '../FormItems/NameFormItem';
import { PositionFormItem } from '../FormItems/PositionFormItem';
import { ColorFormItem } from '../FormItems/ColorFormItem';
import { CreateDemandAreaPayload } from '../../../../../lib/types/demandArea';
import { useCreateDemandAreaMutation } from '../../../../../lib/store/api/demandArea';
import { DEMAND_AREA_FORMITEMS, transformDemandAreaValuesToSendData } from '../../../helpers';
import { useGetIconOptions } from '../../../../productCategories/hooks/useGetIconOptions';
import { IconFormItem } from '../FormItems/IconFormItem';
import { DeactivatedFormItem } from '../FormItems/DeactivatedFormItem';
import { FormItemName } from '../../../../../lib/models/Form';

type Props = {
  tenantId: string;
  modelId: string;
};

export type CreateDemandAreaValues = {
  [DEMAND_AREA_FORMITEMS.COLOR]: { title: any; value: string };
  [DEMAND_AREA_FORMITEMS.POSITION]: { title: string; value: number } | null;
  [DEMAND_AREA_FORMITEMS.NAME]: string;
  [DEMAND_AREA_FORMITEMS.ICON]: { value: number; title: string };
  [DEMAND_AREA_FORMITEMS.ACTIVATED]: boolean;
};

const demandAreaTranslation = 'pages.products.demandArea';

export const CreateDemandAreaModal = ({ tenantId, modelId }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const { isModalOpen, modalType } = useAppSelector(({ demandAreas }) => demandAreas);
  const isOpened = isModalOpen && modalType === ModalActions.ADD;

  const [createDemandArea, { isLoading }] = useCreateDemandAreaMutation();
  const { options, iconsList } = useGetIconOptions();

  useResetFormOnCloseModal({ form, isOpen: isOpened });
  const isActivated = Form.useWatch(FormItemName.ACTIVATED, form);

  const onFinish = async (values: CreateDemandAreaValues) => {
    const sendData: CreateDemandAreaPayload = transformDemandAreaValuesToSendData(
      { ...values, position: !isActivated ? values.position : null },
      {
        tenantId,
        modelId,
      },
      values.activated,
      iconsList,
    );

    try {
      await createDemandArea(sendData).unwrap();
      messageApi.success(t(`${demandAreaTranslation}.success.createDemandArea`));
      onClose();
    } catch (err: any) {
      messageApi.error(err?.data?.message || t(`${demandAreaTranslation}.errors.createDemandArea`));
    }
  };

  const onClose = () => {
    dispatch(setModalClosed());
  };

  const onConfirm = () => {
    form.submit();
  };

  return (
    <AppModal
      className={styles.modal}
      open={isOpened}
      onCancel={onClose}
      onOk={onConfirm}
      getContainer={false}
      titleText={t(`${demandAreaTranslation}.addDemandArea`)}
      isOkLoading={isLoading}
    >
      {contextHolder}
      {isOpened && (
        <Form className={styles.form} form={form} onFinish={onFinish}>
          <NameFormItem />
          <PositionFormItem tenantId={tenantId} modelId={modelId} isActivated={isActivated} />
          <DeactivatedFormItem />
          <ColorFormItem tenantId={tenantId} modelId={modelId} />
          <IconFormItem options={options} />
        </Form>
      )}
    </AppModal>
  );
};
