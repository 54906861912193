import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalActions } from '../../models/Modal';
import { TableSortOrder } from '../../utils/constants';
import { Sort } from '../../types/filteringAndSort';
import { RULES_COLUMN, RuleT, RuleTypeT, GetPublicDictionaryResponseT } from '../../types/ruleBuilder';

type RuleBuilderSliceStateT = {
  modalType: ModalActions | null;
  isModalOpen: boolean;
  selectedRule: RuleT | null;
  selectedRuleType: RuleTypeT | null;
  page: number;
  sorting: Sort;
  arrangeId: RULES_COLUMN | null;
  dictionaries: GetPublicDictionaryResponseT | null;
};

const initialState: RuleBuilderSliceStateT = {
  arrangeId: null,
  page: 1,
  isModalOpen: false,
  modalType: null,
  selectedRule: null,
  selectedRuleType: null,
  sorting: {
    sortOrder: TableSortOrder.NONE,
    sortFieldName: null,
    withSorting: true,
  },
  dictionaries: null,
};

const ruleBuilderSlice = createSlice({
  name: 'ruleBuilder',
  initialState,
  reducers: {
    setModalOpened: (state, action: PayloadAction<ModalActions>) => {
      state.modalType = action.payload;
      state.isModalOpen = true;
    },
    setModalClosed: (state) => {
      state.selectedRule = null;
      state.selectedRuleType = null;
      state.modalType = null;
      state.isModalOpen = false;
    },
    setCurrentRule: (state, action: PayloadAction<RuleT | null>) => {
      state.selectedRule = action.payload;
    },
    setCurrentRuleType: (state, action: PayloadAction<RuleTypeT>) => {
      state.selectedRuleType = action.payload;
    },
    setSorting: (state, action: PayloadAction<{ sorting: Sort; arrangeId: RULES_COLUMN }>) => {
      state.arrangeId = action.payload.arrangeId;
      state.sorting = action.payload.sorting;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setDictionaries: (state, action: PayloadAction<GetPublicDictionaryResponseT>) => {
      state.dictionaries = action.payload;
    },
    resetRulesTable: () => initialState,
  },
});

export const {
  setModalOpened,
  setModalClosed,
  setCurrentRule,
  setCurrentRuleType,
  setSorting,
  setPage,
  setDictionaries,
  resetRulesTable,
} = ruleBuilderSlice.actions;
export const ruleBuilder = ruleBuilderSlice.reducer;
