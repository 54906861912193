import { FC, useEffect } from 'react';
import { Table } from '../../../../components/Table';
import { normalizePageForRequest, normalizePageForTable } from '../../../../lib/utils/helpers';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { TablePagination } from '../../../../lib/types/pagination';
import { resetTenantTable, setTenantTablePage } from '../../../../lib/store/slices/tenantsSlice';
import { useGetRuleTypesListQuery } from '../../../../lib/store/api/ruleBuilder';
import { GetTablePayloadT } from '../../../../lib/types/common';
import { useRulesActionsColumns } from '../../hooks/useRulesActionsColumns';
import { useCreationDateColumn } from '../../hooks/useCreationDateColumn';
import { useRuleCreatorColumn } from '../../hooks/useRuleCreatorColumn';
import { useRuleTypeColumn } from '../../hooks/useRuleTypeColumn';
import { RULES_COLUMN } from '../../../../lib/types/ruleBuilder';

type Props = {
  tenantId: string;
  modelId: string;
};

export const RuleTypesTable: FC<Props> = ({ tenantId, modelId }) => {
  const dispatch = useAppDispatch();

  const sortingAndArguments: GetTablePayloadT = useAppSelector(({ ruleBuilder }) => {
    return {
      ...ruleBuilder.sorting,
      tenantId,
      modelId,
      page: normalizePageForRequest(ruleBuilder.page || 1),
    };
  });

  const { data, isLoading, isFetching } = useGetRuleTypesListQuery(sortingAndArguments, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    dispatch(resetTenantTable());
  }, [dispatch]);

  const responsePagination: TablePagination = {
    pageSize: data?.pageable.pageSize,
    current: normalizePageForTable(data?.pageable?.pageNumber || 0),
    total: data?.totalElements,
  };

  const columns = [
    useRuleTypeColumn(RULES_COLUMN.NAME),
    useRuleCreatorColumn(),
    useCreationDateColumn(),
    useRulesActionsColumns('ruleTypes'),
  ];

  return (
    <Table
      columns={columns}
      rowKey="id"
      dataSource={data?.content}
      pagination={responsePagination}
      loading={isLoading || isFetching}
      scroll={{ x: 'max-content' }}
      onChange={(tablePagination) => {
        dispatch(setTenantTablePage(tablePagination.current as number));
      }}
    />
  );
};
