import { Outlet, useOutletContext } from 'react-router-dom';
import { useAppSelector } from '../../../../lib/store/hooks';
import { SectionName } from '../../../../lib/models/Section';

import styles from './index.module.scss';

type LoginSection = {
  backgroundImage: {
    s3Url: string;
  };
  buttonColor: string;
  buttonText: string;
  headline1: string;
  headline2: string;
};

type LoginSectionContextType = { loginSection?: LoginSection };

export const EndUserAuthLayout = () => {
  const { sections } = useAppSelector(({ endUser }) => endUser);
  const loginSectionContent = sections?.[SectionName.LOGIN_PAGE];

  const backgroundImage = `url("${loginSectionContent?.backgroundImage?.s3Url}")`;

  return (
    <div
      style={{ backgroundImage: backgroundImage, backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
      className={styles.container}
    >
      <Outlet context={{ loginSection: loginSectionContent } satisfies LoginSectionContextType} />
    </div>
  );
};

export const useLoginSection = () => {
  return useOutletContext<LoginSectionContextType>();
};
