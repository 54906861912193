import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetDesignSettingsQuery } from '../../../lib/store/api/designSettings';
import { DesignSettingsDataT } from '../../designSettings/types';
import { parseJSON } from '../../../lib/utils/helpers';

export const useGetPictureOptions = () => {
  const { id: tenantId, modelId } = useParams();

  const { data } = useGetDesignSettingsQuery(
    { tenantId: tenantId as string, projectId: String(modelId) },
    { skip: !tenantId, refetchOnMountOrArgChange: true },
  );

  const pictures = useMemo(() => {
    if (data) {
      const designSettings = data?.[0];
      const parsedData: DesignSettingsDataT = parseJSON(designSettings?.settings);

      return parsedData?.mediaFiles;
    }
  }, [data]);

  return { options: pictures };
};
