import { FormSection } from '../../../components/FormSection';
import { AppSelect } from '../../../components/AppSelect';
import { useGetFontsQuery } from '../../../lib/store/api/brandKit';
import { useMemo } from 'react';

const defaultFontOptions = [
  {
    title: 'Arial',
    value: 'Arial, sans-serif',
  },
  {
    title: 'Verdana',
    value: 'Verdana, sans-serif',
  },
  {
    title: 'Tahoma',
    value: 'Tahoma, sans-serif',
  },
  {
    title: 'Trebuchet MS',
    value: '"Trebuchet MS", sans-serif',
  },
  {
    title: 'Times New Roman',
    value: '"Times New Roman", serif',
  },
  {
    title: 'Georgia',
    value: 'Georgia, serif',
  },
  {
    title: 'Garamond',
    value: 'Garamond, serif',
  },
  {
    title: 'Courier New',
    value: '"Courier New", monospace',
  },
  {
    title: 'Brush Script MT',
    value: '"Brush Script MT", cursive',
  },
  {
    title: 'SimonKucher',
    value: 'SimonKucher, sans-serif',
  },
];

type Props = { value: string; onChange: any; fieldName?: string; field: string; tenantId: number | string };

export const FontFamilyFormItem = ({ field, fieldName, value, onChange, tenantId }: Props) => {
  const { data: fontsFromServer } = useGetFontsQuery({ tenantId: tenantId as string }, { skip: !tenantId });

  const fontsOptions = useMemo(() => {
    const fontsFromServerOptions = fontsFromServer?.map((font) => {
      return {
        value: font.fontName,
        title: (
          <span style={{ fontFamily: font.fontName }}>
            {font.fontName.substring(0, font.fontName.lastIndexOf('.'))}
          </span>
        ),
      };
    });

    const defaultFontsOptions = defaultFontOptions.map((font) => {
      return { value: font.value, title: <span style={{ fontFamily: font.value }}>{font.title}</span> };
    });

    if (fontsFromServerOptions && fontsFromServerOptions?.length) {
      return [...defaultFontsOptions, ...fontsFromServerOptions];
    }

    return defaultFontsOptions;
  }, [fontsFromServer]);

  return (
    <FormSection
      formItemProps={{
        hasFeedback: true,
      }}
    >
      <AppSelect
        onChange={(selected: { value: string; title: string }) => onChange(selected?.value, fieldName, field)}
        options={fontsOptions}
        value={{ value }}
      />
    </FormSection>
  );
};
