import { useTranslation } from 'react-i18next';
import { PRODUCT_CATEGORIES } from '../helpers/constants';
import { TableHeaderCell } from '../../../components/Table/TableHeaderCell/TableHeaderCell';

import tableStyles from '../../../components/Table/index.module.scss';
import { Button, Typography } from 'antd';
import { Tabs } from '../../../lib/types/queryParams';
import { AppDispatch } from '../../../lib/store';
import { setFilters } from '../../../lib/store/slices/productsSlice';
import { PRODUCTS_COLUMNS } from '../../products/helpers/consts';

const numberOfProducts = 'pages.products.productCategories.columns.numberOfProducts';

type Props = {
  handleTabChange: (key: string) => void;
  dispatch: AppDispatch;
};

export const useProductCategoriesNumberOfProductsColumn = ({ handleTabChange, dispatch }: Props) => {
  const { t } = useTranslation();

  return {
    dataIndex: PRODUCT_CATEGORIES.NUMBER_OF_PRODUCTS,
    title: <TableHeaderCell withArrange={false} title={t(numberOfProducts)} />,
    width: 155,
    render: (numberOfProducts: [], row: { id: number; name: string }) => {
      const onClickButton = () => {
        handleTabChange(Tabs.PRODUCTS);
        dispatch(
          setFilters({
            arrangeId: PRODUCTS_COLUMNS.CATEGORY,
            sorting: {
              sortFieldName: 'category.name',
              sortOrder: 'none',
              withSorting: true,
            },
            filtering: {
              filter: [row.id],
              filterFieldName: 'includeByCategoryIds',
              filtersWithLabels: [
                {
                  valle: row.id,
                  label: row.name,
                },
              ],
            },
          } as any),
        );
      };

      return (
        <Button type="link" onClick={onClickButton}>
          <Typography.Text className={tableStyles.markedCell}>{numberOfProducts?.length}</Typography.Text>
        </Button>
      );
    },
  };
};
