import { FC } from 'react';
import { FormItemName } from '../../../../lib/models/Form';
import { useTranslation } from 'react-i18next';
import { FormItem } from '../../../../components/FormItem';
import { FormInput } from '../../../../components/FormInput';

const tenantDeleteName = 'unique.formItems.tenantDeleteName';

type Props = {
  tenantName: string;
  originalName?: string;
};

export const TenantNameDeleteFormItem: FC<Props> = ({ originalName, tenantName }) => {
  const { t } = useTranslation();

  return (
    <FormItem
      name={FormItemName.TENANT_NAME}
      hasFeedback={true}
      rules={[
        {
          required: true,
        },
        {
          validator: () => {
            if (originalName === tenantName) {
              return Promise.resolve();
            }
            return Promise.reject(t(`${tenantDeleteName}.errors.invalid`));
          },
          message: t(`${tenantDeleteName}.errors.invalid`),
        },
      ]}
    >
      <FormInput placeholder={t(`${tenantDeleteName}.placeholder`)} />
    </FormItem>
  );
};
