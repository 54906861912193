import { FC } from 'react';
import { FormItemName } from '../../../../lib/models/Form';
import { useTranslation } from 'react-i18next';
import { FormItem } from '../../../../components/FormItem';
import { FormInput } from '../../../../components/FormInput';
import { useUsernameValidation } from '../../hooks/useUsernameValidation';

type Props = {
  backendError: boolean;
};

const usernameInputErrorProps = {
  validateStatus: 'error',
};

export const UsernameFormItem: FC<Props> = ({ backendError }) => {
  const { t } = useTranslation();

  const { usernameRules } = useUsernameValidation();

  return (
    <FormItem
      {...(backendError ? usernameInputErrorProps : {})}
      hasFeedback={true}
      name={FormItemName.USERNAME}
      rules={[{ required: true }, ...usernameRules]}
    >
      <FormInput isActive={true} label={t('formItems.username.label')} id="username" />
    </FormItem>
  );
};
