import { FC } from 'react';
import { Form, FormInstance, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BasketIcon } from '../../../../assets/icons/bakset-icon.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/PlusIcon.svg';
import { FormItemName } from '../../../../lib/models/Form';
import { AppCardBasic } from '../../../../components/AppCardBasic';
import { IconButton } from '../../../../components/IconButton';
import FormItem from 'antd/es/form/FormItem';
import { FormInput } from '../../../../components/FormInput';
import { AppSelect } from '../../../../components/AppSelect';
import { useGetBonusCatalogQuery } from '../../../../lib/store/api/bonusCatalog';
import { useParams } from 'react-router-dom';
import { useGetProject } from '../../../../lib/hooks/useGetProjects';
import { useStatusLevelPointsValidation } from '../../../statusLevel/hooks/useStatusLevelPointsValidation';

import './styles.scss';
import styles from './../../styles.module.scss';

const { Title, Text } = Typography;

const sublevel = 'pages.statusLevel.form.labels.sublevel';
const formItems = 'pages.statusLevel.form.formItems';
const errors = 'pages.statusLevel.form.errors';
const addSublevel = 'pages.statusLevel.buttons.addSublevel';

type Props = {
  form: FormInstance;
};
export const StatusLevelSublevels: FC<Props> = ({ form }) => {
  const { data: projectData } = useGetProject();

  const { t } = useTranslation();

  const sublevels = Form.useWatch(FormItemName.SUBLEVELS, form);

  const { id: tenantId, modelId } = useParams();

  const { data: bonusesData } = useGetBonusCatalogQuery({
    tenantId: String(tenantId),
    projectId: String(modelId),
    page: 0,
    size: 100,
    sortFieldName: undefined,
    sortOrder: undefined,
    filter: undefined,
  });

  const bonusesOptions = bonusesData?.content.map((item) => ({ ...item, value: item.id })) || [];

  const canDeleteSublevel = sublevels?.length > 1;

  const { checkIfValueCrossedWithOtherSublevels } = useStatusLevelPointsValidation(form);

  return (
    <>
      <Title className={styles.title}>{t(sublevel)}</Title>

      <Form.List name={FormItemName.SUBLEVELS}>
        {(fields, { add, remove }) => {
          return (
            <div className={styles.sublevelCard}>
              {fields.map((field, index) => (
                <AppCardBasic key={field.name}>
                  <div className={styles.sublevelCardHeader}>
                    <Title className={styles.subTitle}>{`${t(sublevel)} ${index + 1}`}</Title>

                    {canDeleteSublevel && (
                      <IconButton onClick={() => remove(field.name)} icon={<BasketIcon />} />
                    )}
                  </div>

                  <div className={styles.sublevelMiddleRow}>
                    <FormItem
                      className={styles.hasError}
                      hasFeedback
                      name={[field.name, FormItemName.SUBLEVEL_TITLE]}
                      rules={[{ required: true, message: t(`${errors}.emptySublevelTitle`) }]}
                    >
                      <FormInput className={styles.sublevelTitle} label={t(`${formItems}.sublevelTitle`)} />
                    </FormItem>
                    <FormItem
                      className={styles.hasError}
                      hasFeedback
                      name={[field.name, FormItemName.SUBLEVEL_POINTS]}
                      rules={[
                        { required: true, message: t(`${errors}.emptyPoints`) },
                        { pattern: /^\d+(\.\d+)?$/, message: t(`${errors}.minPoints`) },
                        {
                          message: t(`${errors}.tooManyPoints`, { maxPoints: projectData?.maxPoints }),
                          validator: (_, value) => {
                            if (Number(value) > Number(projectData?.maxPoints)) {
                              return Promise.reject();
                            }

                            return Promise.resolve();
                          },
                        },
                        {
                          message: t(`${errors}.crossedValue`),
                          validator: () => {
                            const hasCollide = checkIfValueCrossedWithOtherSublevels();

                            if (hasCollide) {
                              return Promise.reject();
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <FormInput
                        type="number"
                        className={styles.sublevelPoints}
                        label={t(`${formItems}.points`)}
                      />
                    </FormItem>
                  </div>

                  <FormItem
                    className={styles.hasError}
                    hasFeedback={true}
                    name={[field.name, FormItemName.SUBLEVEL_BONUSES]}
                  >
                    <AppSelect isMultiSelect options={bonusesOptions} label={t(`${formItems}.bonuses`)} />
                  </FormItem>
                </AppCardBasic>
              ))}

              <IconButton
                disabled={sublevels?.length === 10}
                onClick={() =>
                  add({
                    [FormItemName.SUBLEVEL_TITLE]: '',
                    [FormItemName.SUBLEVEL_POINTS]: '0',
                    [FormItemName.SUBLEVEL_BONUSES]: [],
                  })
                }
                className={styles.addSublevelButton}
                icon={
                  <>
                    <PlusIcon className={styles.addIcon} />

                    <Text className={styles.buttonTitle}>{t(addSublevel)}</Text>
                  </>
                }
              />
            </div>
          );
        }}
      </Form.List>
    </>
  );
};
