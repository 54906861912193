import { FC } from 'react';
import { FormItem } from '../../../../components/FormItem';
import { FormItemName } from '../../../../lib/models/Form';
import { FormInput } from '../../../../components/FormInput';
import { useTranslation } from 'react-i18next';

export const ResetPasswordFormItem: FC = () => {
  const { t } = useTranslation();

  return (
    <FormItem
      hasFeedback={true}
      name={FormItemName.EMAIL}
      rules={[
        {
          required: true,
          type: 'email',
          message: t('pages.getLinkForResetPassword.errors.email'),
        },
      ]}
    >
      <FormInput label={t('formItems.email.label')} />
    </FormItem>
  );
};
