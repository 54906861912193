import { ReactNode, FC, useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { useAppSelector } from '../../../lib/store/hooks';
import { BrandKitThemeConfiguration, ColorVariants } from '../../../lib/types/colors&fonts';

const getAntdTheme = (brandKit: BrandKitThemeConfiguration | null) => {
  return {
    token: {
      boxShadow: '0 4px 20px rgb(0 0 0 / 10%)',
      colorPrimary: brandKit?.colors[ColorVariants.PRIMARY_COLOR] || '#EC6608',
      colorLink: brandKit?.colors[ColorVariants.PRIMARY_COLOR] || '#EC6608',
      Input: {
        colorText: brandKit?.colors[brandKit?.fonts?.body?.colorVariant],
        fontFamily: brandKit?.fonts?.body?.font?.split('.')[0],
        textFontWeight: brandKit?.fonts.body?.fontWeight,
        fontWeight: brandKit?.fonts.body?.fontWeight,
        fontWeightStrong: brandKit?.fonts.body?.fontWeight,
        colorTextPlaceholder: brandKit?.colors[ColorVariants.SECONDARY_FIRST],
      },
    },
  };
};

type Props = {
  children: ReactNode;
};

export const EndUserAntdConfigProvider: FC<Props> = ({ children }) => {
  const { brandKit } = useAppSelector(({ endUser }) => endUser);
  const theme = getAntdTheme(brandKit);

  useEffect(() => {
    if (brandKit) {
      document.documentElement.style.setProperty(
        '--primary-color',
        brandKit?.colors[ColorVariants.PRIMARY_COLOR],
      );
      document.documentElement.style.setProperty(
        '--secondary-color',
        brandKit?.colors[ColorVariants.SECONDARY_FIRST],
      );
    }
  }, [brandKit]);

  return <ConfigProvider theme={theme}>{children}</ConfigProvider>;
};
