import { FC } from 'react';
import { ProductCategoriesCreateModal } from './ProductCategoriesCreateModal';
import { useAppSelector, useAppDispatch } from '../../../../lib/store/hooks';
import { ModalActions } from '../../../../lib/models/Modal';
import { setModalClosed } from '../../../../lib/store/slices/productCategoriesSlice';
import { ProductCategoriesEditModal } from './ProductCategoriesEditModal';
import { DeleteProductCategoriesModal } from './DeleteProductCategoriesModal';
import { ActivateProductCategoryModal } from './ActivateProductCategoryModal';

export const ProductCategoriesModals: FC = () => {
  const {
    isModalOpen,
    modalType: currentModalType,
    selectedCategory,
  } = useAppSelector(({ productCategories }) => productCategories);
  const dispatch = useAppDispatch();

  const close = () => dispatch(setModalClosed());
  const checkIsOpen = (modalType: ModalActions | null) => isModalOpen && modalType === currentModalType;

  return (
    <>
      <ProductCategoriesCreateModal isOpen={checkIsOpen(ModalActions.ADD)} close={close} />
      <ProductCategoriesEditModal
        isOpen={checkIsOpen(ModalActions.EDIT)}
        close={close}
        selectedCategory={selectedCategory}
      />
      <DeleteProductCategoriesModal
        isOpen={checkIsOpen(ModalActions.DELETE)}
        close={close}
        selectedCategory={selectedCategory}
      />
      <ActivateProductCategoryModal
        isOpen={checkIsOpen(ModalActions.ACTIVATE)}
        close={close}
        selectedCategory={selectedCategory}
      />
    </>
  );
};
