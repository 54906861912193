import React, { FC } from 'react';
import { Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { PreloaderIcon } from '../../assets/icons/PreloadIcon';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash.svg';
import { UploadResponseT } from '../../lib/types/entities';
import { DeleteMediaProps } from '../../lib/types/media';
import { ReactComponent as BgUpload } from '../../assets/icons/bg-upload.svg';

import styles from './index.module.scss';

const { Text } = Typography;

type Props = {
  mediaObject?: UploadResponseT;
  deleteOnClick: () => void;
  uploading: boolean;
  imgHeight: number;
  text?: string;
  className?: string;
  onDeleteMedia: (props: DeleteMediaProps) => void;
};

export const UploadImageLabel: FC<Props> = ({
  mediaObject,
  deleteOnClick,
  uploading,
  imgHeight,
  text,
  className,
  onDeleteMedia,
}) => {
  const { t } = useTranslation();

  const deleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    deleteOnClick();
    onDeleteMedia({ id: mediaObject?.id as number });
  };

  return mediaObject && mediaObject?.s3Url ? (
    <div className={cx(styles.box, className)}>
      <div className={styles.content}>
        <div className={styles.textContainer}>
          <div className={styles.mediaRowImageWrapper}>
            {uploading ? (
              <PreloaderIcon className={styles.fileUploader} style={{ width: 24, height: 24 }} />
            ) : (
              <img
                className={styles.mediaRowImage}
                src={mediaObject.s3Url}
                alt="avatar"
                style={{ height: imgHeight }}
              />
            )}
          </div>
          {mediaObject.originalFileName && !uploading && (
            <Text ellipsis className={styles.mediaRowFileName}>
              {mediaObject.originalFileName}
            </Text>
          )}
        </div>
      </div>
      {mediaObject.s3Url && !uploading && (
        <Button className={styles.deleteButton} onClick={deleteClick}>
          <DeleteIcon />
        </Button>
      )}
    </div>
  ) : (
    <div className={cx(styles.boxNotUploaded, className)}>
      <BgUpload />
      <Text className={styles.uploadTitle}>{text || t('formItems.chooseImage.favicon')}</Text>
    </div>
  );
};
