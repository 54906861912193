import { useTranslation } from 'react-i18next';
import { TenantNameBodyCell } from '../components/TenantsTable/TenantNameBodyCell';
import { TENANT_COLUMN } from '../helpers/constants';
import { useTenantSearchDataColumn } from './useTenantSearchDataColumn';

import styles from '../../../components/Table/index.module.scss';

const tenantName = 'pages.tenants.columns.tenantName';

export const useTenantNameColumn = () => {
  const { t } = useTranslation();

  return useTenantSearchDataColumn({
    title: t(tenantName),
    arrangedId: TENANT_COLUMN.NAME,
    searchFieldName: 'searchByName',
    filterFieldName: 'includeNames',
    columnProps: {
      width: 189,
      className: styles.borderRight,
      render: (text: string, row: { id: number }) => <TenantNameBodyCell id={row.id} link={text} />,
    },
    headerCellProps: {
      rootProps: { className: styles.borderRight },
    },
  });
};
