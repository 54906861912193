import React, { FC, useState } from 'react';
import { Button, Dropdown, Tooltip } from 'antd';
import styles from '../ConditionArea/index.module.scss';
import { ReactComponent as ArrowTopIcon } from './../../../../assets/icons/arrow-top.svg';
import { ReactComponent as QuestionRedIcon } from './../../../../assets/icons/question-red.svg';
import cn from 'classnames';
import { getTooltipText } from '../../helpers';
import { OperatorTypes } from '../../types/constants';

type Props = {
  name: string;
  subElements?: string[] | null;
  onClick: (operatorType: string, operatorName: string) => void;
  disabled?: boolean;
};

export const DropdownButton: FC<Props> = ({ name, subElements, onClick, disabled }) => {
  const [visible, setVisible] = useState(false);
  const handleVisibleChange = () => {
    setVisible((prev) => !prev);
  };

  const handleClick = (name: string, element: string) => {
    handleVisibleChange();
    onClick(name, element);
  };

  return (
    <Dropdown
      open={visible}
      trigger={['click']}
      placement="bottomRight"
      onOpenChange={handleVisibleChange}
      overlayClassName={styles.operatorsPopup}
      disabled={disabled}
      dropdownRender={() => (
        <div className={styles.dropdownMenu}>
          {subElements?.map((element) => (
            <Button
              onClick={() => handleClick(name, element)}
              type="link"
              className={styles.operatorButton}
              key={element}
            >
              {element}
            </Button>
          ))}
        </div>
      )}
    >
      <Button type="link" className={styles.button}>
        {name}
        {(name === OperatorTypes.ArithmeticOperator || name === OperatorTypes.ComparisonOperator) && (
          <Tooltip
            overlayInnerStyle={{ padding: 12 }}
            placement="bottomLeft"
            title={() => getTooltipText(name)}
          >
            <QuestionRedIcon style={{ marginRight: 3 }} />
          </Tooltip>
        )}

        <ArrowTopIcon className={cn(styles.icon, visible ? styles.arrowClosedState : null)} />
      </Button>
    </Dropdown>
  );
};
