import { useTranslation } from 'react-i18next';
import { FormItem } from '../../../../../components/FormItem';
import { FormItemName } from '../../../../../lib/models/Form';
import { FormInput } from '../../../../../components/FormInput';

const creationDate = 'pages.projects.formItem.creationDate';

export const CreationDateFormItem = () => {
  const { t } = useTranslation();

  return (
    <>
      <FormItem hasFeedback={true} name={FormItemName.USER_VISIBILITY_CREATION_DATE}>
        <FormInput disabled={true} label={t(creationDate)} />
      </FormItem>
    </>
  );
};
