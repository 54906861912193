import { useProjectSearchDataColumn } from './useProjectSearchDataColumn';
import { useTranslation } from 'react-i18next';
import { PROJECT_COLUMN } from '../helpers/constants';

const lastEditedFrom = 'pages.projects.columns.lastEditedFrom';

export const useLastEditedFromColumn = () => {
  const { t } = useTranslation();

  return useProjectSearchDataColumn({
    title: t(lastEditedFrom),
    arrangedId: PROJECT_COLUMN.LAST_EDITED_FROM,
    searchFieldName: 'searchByLastModifiedBy',
    filterFieldName: 'lastModifiedBy',
    columnProps: {
      width: 140,
    },
  });
};
