import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setNotificationStatusLevel } from '../../store/slices/endUserSlice';

type Props = {
  prevPoints: number;
  newPoints: number;
};

export const useCheckUserStatusLevelNotification = () => {
  const { statusLevels } = useAppSelector(({ endUser }) => endUser);
  const dispatch = useAppDispatch();

  const checkUserStatusLevelNotification = useCallback(
    ({ prevPoints, newPoints }: Props) => {
      const subLevelsWithStatusLevel = statusLevels
        .flatMap((statusLevel: any) => {
          const firstSubLevel = statusLevel.subLevels[0];
          return firstSubLevel ? { ...firstSubLevel, statusLevelName: statusLevel.name } : null;
        })
        .filter(Boolean);

      const sortedLevels = [...subLevelsWithStatusLevel.sort((a: any, b: any) => b.points - a.points)];

      const newLevel = sortedLevels?.find((item) => prevPoints < item?.points && newPoints >= item?.points);

      if (newLevel) {
        dispatch(setNotificationStatusLevel(newLevel?.statusLevelName));
      }
    },
    [dispatch, statusLevels],
  );

  return {
    checkUserStatusLevelNotification,
  };
};
