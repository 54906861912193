import { FC } from 'react';
import styles from './index.module.scss';
import { useAppSelector } from '../../../../lib/store/hooks';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { Modal } from 'antd';
import { ReactComponent as IconCup } from '../../../../assets/icons/cup-icon.svg';
import ConfettiImg from '../../../../assets/images/confetti.png';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const modal = 'endUser.congratulationModal';

export const EndUserCongratulationModal: FC<Props> = ({ isOpen, onClose }) => {
  const { statusLevelNotification, brandKit } = useAppSelector(({ endUser }) => endUser);
  const { t } = useTranslation();

  return (
    <Modal
      centered
      open={isOpen}
      width={360}
      onCancel={onClose}
      destroyOnClose={true}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { display: 'none' } }}
      className={styles.modal}
    >
      <IconCup className={styles.iconCup} fill={brandKit?.colors.primaryColor} />
      <img className={styles.confettiImg} src={ConfettiImg} alt="confetti" />
      <EndUserTypography type="headlineSecond" component="Title" className={styles.title}>
        {t(`${modal}.title`)}
      </EndUserTypography>
      <EndUserTypography type="body" component="Text" className={styles.text}>
        <Trans
          i18nKey={`${modal}.description`}
          values={{ statusLevelNotification }}
          components={{
            bold: <span className="bold" />,
          }}
        />
      </EndUserTypography>
    </Modal>
  );
};
