import { FC, MouseEvent } from 'react';
import { components, MultiValueProps } from 'react-select';
import './index.scss';
import { SelectPropsT } from '../../types';
import { ListElementValue } from '../ListElementValue';

const MAX_TO_SHOW = 2;

type AppMultiValueProps = {
  selectProps: SelectPropsT;
} & MultiValueProps;

export const AppMultiValue: FC<AppMultiValueProps> = (props) => {
  const { index, getValue, selectProps } = props;
  const values = getValue();

  const overflow = values.slice(MAX_TO_SHOW).map((x: any) => x.title);

  const onClickRemoveList = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    selectProps?.listProps?.onSelectList?.(false);
  };

  return index < MAX_TO_SHOW ? (
    <>
      {selectProps?.listProps?.isListEnabled && index === 0 ? (
        <ListElementValue onClickRemove={onClickRemoveList} />
      ) : null}
      <components.MultiValue {...props} />
    </>
  ) : index === MAX_TO_SHOW ? (
    <div className="multiValue">+ {overflow.length} items selected ...</div>
  ) : null;
};

export const MultiValueWithoutLimit: FC<MultiValueProps> = (props) => {
  return <components.MultiValue {...props} />;
};
