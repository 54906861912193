import React, { FC } from 'react';
import { Button } from 'antd';
import { OperatorTypes, getOperatorsArray } from '../../types/constants';
import { DropdownButton } from '../DropdownButton';
import { ConditionsArray, RuleOperator } from '../../types';
import { nanoid } from '@reduxjs/toolkit';
import { useAppSelector } from '../../../../lib/store/hooks';

import styles from './index.module.scss';

type Props = {
  setQuery: React.Dispatch<React.SetStateAction<ConditionsArray>>;
  isEditModeEnabled: boolean;
};

export const ConditionArea: FC<Props> = ({ setQuery, isEditModeEnabled }) => {
  const { dictionaries } = useAppSelector(({ ruleBuilder }) => ruleBuilder);

  const availableOperators = getOperatorsArray(dictionaries);

  const handleButtonClick = (operatorType: string, operatorValue: string | null) => {
    if (!isEditModeEnabled) {
      return;
    }

    const newElement = {
      value: operatorValue,
      type: operatorType,
      id: nanoid(),
    } as RuleOperator;

    setQuery((prevState) => {
      return prevState.map((condition, index) => {
        // find the last condition and element to the end
        if (index === prevState.length - 1) {
          return { ...condition, rules: [...condition.rules, newElement] };
        }

        return condition;
      });
    });
  };

  return (
    <div className={styles.container}>
      {availableOperators.map((operator) =>
        operator.name === OperatorTypes.ArithmeticOperator ||
        operator.name === OperatorTypes.LogicalOperator ||
        operator.name === OperatorTypes.ComparisonOperator ? (
          <DropdownButton
            disabled={!isEditModeEnabled}
            name={operator.name}
            key={operator.name}
            subElements={operator.subElements as string[]}
            onClick={handleButtonClick}
          />
        ) : (
          <Button
            disabled={!isEditModeEnabled}
            onClick={() => handleButtonClick(operator.name, operator.defaultValue)}
            type="link"
            key={operator.name}
            className={styles.button}
          >
            {operator.name}
          </Button>
        ),
      )}
    </div>
  );
};
