import { useTranslation } from 'react-i18next';
import { useProjectSearchDataColumn } from './useProjectSearchDataColumn';
import { Typography } from 'antd';
import { PROJECT_COLUMN } from '../helpers/constants';

const { Text } = Typography;

const editMode = 'pages.projects.columns.editMode';

export const useEditModeColumn = () => {
  const { t } = useTranslation();

  return useProjectSearchDataColumn({
    title: t(editMode),
    arrangedId: PROJECT_COLUMN.EDIT_MODE,
    columnProps: {
      width: 140,
      render: () => {
        return <Text>-</Text>;
      },
    },
  });
};
