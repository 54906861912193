import { FC } from 'react';
import { Modal, message } from 'antd';
import { EndUserButton } from '../EndUserButton';
import { EndUserTypography } from '../EndUserTypography';
import { useDeleteEndUserAccountMutation } from '../../lib/store/api/auth';
import { useAppDispatch, useAppSelector } from '../../lib/store/hooks';
import { appLogout } from '../../lib/utils/helpers';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcon.svg';
import { IconButton } from '../IconButton';
import { sendDataToAnalytics } from '../../lib/utils/sendDataToAnalytics';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const shared = 'endUser.shared';
const translation = 'endUser.deleteProfile';

type Props = {
  isModalOpen: boolean;
  close: () => void;
};

export const EndUserDeleteProfileModal: FC<Props> = ({ isModalOpen, close }) => {
  const [deleteEndUserAccount] = useDeleteEndUserAccountMutation();
  const [messageApi] = message.useMessage();
  const { tenantAndProject, demandArea } = useAppSelector(({ endUser }) => endUser);
  const modelName = demandArea?.project?.name;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleDeleteProfile = async () => {
    try {
      await deleteEndUserAccount({
        tenantId: String(tenantAndProject?.tenantId),
      }).unwrap();

      sendDataToAnalytics(`Confirm delete account, userId: ${demandArea?.user.id}`);

      appLogout(dispatch);
      close();
    } catch (e) {
      messageApi.error('failed');
    }
  };

  return (
    <div>
      <Modal
        open={isModalOpen}
        width={528}
        onCancel={close}
        closeIcon={false}
        destroyOnClose={true}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        bodyStyle={{ margin: '-34px -24px -32px', padding: '24px 24px' }}
        className={styles.modal}
      >
        <div className={styles.wrapper}>
          <IconButton className={styles.icon} onClick={close} icon={<CrossIcon />} />
          <div className={styles.container}>
            <div className={styles.content}>
              <EndUserTypography type="headlineThird" component="Title" className={styles.title}>
                {t(`${translation}.title`)}
              </EndUserTypography>

              <EndUserTypography className={styles.description} type="body" component="Text">
                {t(`${translation}.highlight`)}
              </EndUserTypography>

              <ul className={styles.list}>
                <li>
                  <EndUserTypography type="body" component="Text" className={styles.listItem}>
                    {t(`${translation}.option1`)}
                  </EndUserTypography>
                </li>
                <li>
                  <EndUserTypography type="body" component="Text" className={styles.listItem}>
                    {t(`${translation}.option2`)}
                  </EndUserTypography>
                </li>
                <li>
                  <EndUserTypography type="body" component="Text" className={styles.listItem}>
                    {t(`${translation}.option3`, { modelName })}
                  </EndUserTypography>
                </li>
              </ul>
            </div>

            <div className={styles.footer}>
              <EndUserButton type="secondary" className={styles.footerButton} onClick={handleDeleteProfile}>
                {t(`${shared}.removeAccount`)}
              </EndUserButton>
              <EndUserButton type="primary" className={styles.footerButton} onClick={close}>
                {t(`${shared}.cancel`)}
              </EndUserButton>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
