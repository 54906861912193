import { FC } from 'react';
import { AuthLayout } from '../../modules/auth/layouts/AuthLayout';
import { PasswordResetCard } from '../../modules/auth/components/PasswordResetCard';

export const PasswordResetPage: FC = () => {
  return (
    <AuthLayout>
      <PasswordResetCard />
    </AuthLayout>
  );
};
