import { FC } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';

const { Title } = Typography;

const arranges = 'shared.arranges';

export const FilterTitle: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.filterTitle}>
      <Title level={3} type="secondary" style={{ fontSize: '12px', margin: 0 }}>
        {t(`${arranges}.filter.title`)}
      </Title>
    </div>
  );
};
