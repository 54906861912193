import { PRODUCT_CATEGORIES } from '../helpers/constants';
import { useTranslation } from 'react-i18next';
import { TableHeaderCell } from '../../../components/Table/TableHeaderCell/TableHeaderCell';
import { ProductCategoryIcon } from '../components/ProductCategoryIcon';
import { IconT } from '../../designSettings/types';

const icon = 'pages.products.productCategories.columns.icon';

export const useProductCategoriesIconColumn = () => {
  const { t } = useTranslation();

  return {
    dataIndex: PRODUCT_CATEGORIES.ICON,
    title: <TableHeaderCell withArrange={false} title={t(icon)} />,
    width: 74,
    render: (data: IconT) => {
      return <ProductCategoryIcon imageUrl={data?.s3Url} />;
    },
  };
};
