export const getPercentToTranslateToLeftForTablets = (
  countOfDemandAreas: number,
  isLastOddArea: boolean,
): number => {
  let percentMoveToLeft = 50;
  let additionalShiftXPercent = 0;

  if (countOfDemandAreas === 5) {
    additionalShiftXPercent = 0.9;
    if (isLastOddArea) {
      return 6.8;
    }
  } else if (countOfDemandAreas === 4 || countOfDemandAreas === 2) {
    percentMoveToLeft = 49;
    additionalShiftXPercent = 1.85;
  } else if (countOfDemandAreas === 3) {
    percentMoveToLeft = 49;
    additionalShiftXPercent = 2;
    if (isLastOddArea) {
      return 7;
    }
  } else if (countOfDemandAreas === 1) {
    return 7;
  }

  return percentMoveToLeft + additionalShiftXPercent;
};

export const getPercentToTranslateToLeftForDesktops = (
  countOfDemandAreas: number,
  isLastOddArea: boolean,
) => {
  if (isLastOddArea && [5, 3, 1].includes(countOfDemandAreas)) {
    return 7;
  }

  const percentMoveToLeft = 50;
  const additionalShiftX = 0.6;

  if ([5, 4, 3, 2].includes(countOfDemandAreas)) {
    return percentMoveToLeft + additionalShiftX;
  }

  return percentMoveToLeft;
};

export const getPercentToTranslateToTopForTablets = (countOfDemandAreas: number): number => {
  const containerHeight = 248;

  if (countOfDemandAreas === 5) {
    const percentMoveToTop = 32;
    const additionalShiftY = 5.1;

    return percentMoveToTop + (additionalShiftY / containerHeight) * 100;
  } else if (countOfDemandAreas === 4) {
    const percentMoveToTop = 45;
    const additionalShiftY = 16.5;

    return percentMoveToTop + (additionalShiftY / containerHeight) * 100;
  } else if (countOfDemandAreas === 3) {
    const percentMoveToTop = 45;
    const additionalShiftY = 16.5;

    return percentMoveToTop + (additionalShiftY / containerHeight) * 100;
  } else {
    return 50;
  }
};

export const getPercentToTranslateToTopForDesktops = (countOfDemandAreas: number): number => {
  const containerHeight = 490;

  if (countOfDemandAreas === 5) {
    const percentMoveToTop = 32;
    const additionalShiftY = 10;

    return percentMoveToTop + (additionalShiftY / containerHeight) * 100;
  } else if (countOfDemandAreas === 4) {
    const percentMoveToTop = 50;
    const additionalShiftY = 6;

    return percentMoveToTop + (additionalShiftY / containerHeight) * 100;
  } else if (countOfDemandAreas === 3) {
    const percentMoveToTop = 45;
    const additionalShiftY = 32;

    return percentMoveToTop + (additionalShiftY / containerHeight) * 100;
  } else {
    return 50;
  }
};
