import { FC, useState } from 'react';
import { EndUserModal } from '../../../../components/EndUserModal';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { Form } from 'antd';
import { EndUserContactForm } from '../ContactForm';
import { useResetFormOnCloseModal } from '../../../../lib/hooks/useResetFormOnCloseModal';
import { ProductT } from '../../../../lib/types/product';
import { useAppSelector } from '../../../../lib/store/hooks';
import { useSendContactFormMutation } from '../../../../lib/store/api/endUser';
import { SuccessStateModal } from '../SuccessStateModal';
import { FormItemName } from '../../../../lib/models/Form';
import { sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';
import { SectionName } from '../../../../lib/models/Section';
import { ContactFormBody } from '../../../../lib/types/endUser';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';

const contactForm = 'endUser.contactForm';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  products: ProductT[] | null;
};

export const EndUserContactFormModal: FC<Props> = ({ products: productsList, isOpen, onClose }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const { tenantAndProject, isGuestMode, sections } = useAppSelector(({ endUser }) => endUser);
  const [sendContactForm, { isLoading }] = useSendContactFormMutation();
  const userEmail = form.getFieldValue(FormItemName.EMAIL);
  const currentSectionContent = sections?.[SectionName.CONTACT_FORM];

  useResetFormOnCloseModal({ form, isOpen });

  const handleSubmit = async (values: Omit<ContactFormBody, 'products'>) => {
    const tenantId = tenantAndProject?.tenantId;
    const projectId = tenantAndProject?.projectId;

    const products = productsList?.map((item) => item.id);

    try {
      await sendContactForm({
        isGuestMode,
        tenantId: String(tenantId),
        projectId: Number(projectId),
        body: {
          ...values,
          products,
        },
      }).unwrap();

      setIsSuccessModalOpen(true);

      sendDataToAnalytics('Contact form submit,', `${JSON.stringify(values)}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickSubmit = () => {
    form.submit();
  };

  const handleCloseModals = () => {
    sendDataToAnalytics('Contact form success');

    onClose();
    setIsSuccessModalOpen(false);
  };

  return !isSuccessModalOpen ? (
    <EndUserModal
      open={isOpen}
      titleProps={{ title: currentSectionContent?.title, subTitle: currentSectionContent?.subtitle }}
      cancelButtonProps={{ onClick: onClose }}
      actionButtonProps={{ onClick: handleClickSubmit, loading: isLoading }}
      cancelText={t(`${contactForm}.cancelText`)}
      okText={t(`${contactForm}.okText`)}
    >
      <EndUserTypography type="body" component="Text" className={styles.text}>
        {currentSectionContent?.text}
      </EndUserTypography>
      <EndUserContactForm form={form} onFinish={handleSubmit} />
    </EndUserModal>
  ) : (
    <SuccessStateModal isOpen={isSuccessModalOpen} onClose={handleCloseModals} userEmail={userEmail} />
  );
};
