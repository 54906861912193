import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { AppSelect } from '../../../../../components/AppSelect';

import styles from './index.module.scss';

import { FormInput } from '../../../../../components/FormInput';
import cn from 'classnames';
import { UpdateRuleBuilderPayload } from '../../CustomRuleBuilder';
import { useAppSelector } from '../../../../../lib/store/hooks';

type Props = {
  value: string;
  subValue?: string | null;
  ruleId: string;
  handleChange: (values: UpdateRuleBuilderPayload) => void;
  disabled: boolean;
};

export const QuantifierOperator: FC<Props> = ({ value, ruleId, handleChange, subValue, disabled }) => {
  const { dictionaries } = useAppSelector(({ ruleBuilder }) => ruleBuilder);

  const quantifierOptions = dictionaries?.quantifiers.map((option) => ({
    value: option,
    title: option,
  }));

  const [isNumberSelected, setIsNumberSelected] = useState(false);

  const onChangeNumberField = (e: ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value;

    handleChange({
      ruleId,
      subValue: currentValue,
      value,
    });
  };

  const onChange = (values: { value: string }) => {
    handleChange({ value: values.value, ruleId });

    if (values.value === 'Number') {
      setIsNumberSelected(true);
    } else {
      setIsNumberSelected(false);
    }
  };

  useEffect(() => {
    if (value === 'Number') {
      setIsNumberSelected(true);
    } else {
      setIsNumberSelected(false);
    }
  }, [value]);

  return (
    <div className={styles.container}>
      <AppSelect
        disabled={disabled}
        className={cn(styles.select, isNumberSelected && styles.secondOptionOpened)}
        onChange={onChange}
        options={quantifierOptions}
        value={value}
        label="Quantifier"
      />

      {isNumberSelected && (
        <FormInput
          disabled={disabled}
          onChange={onChangeNumberField}
          rootClassname={cn(styles.inputRoot, disabled ? styles.disabled : '')}
          className={styles.ruleInput}
          value={subValue ? String(subValue) : ''}
          label="Number"
          type="number"
        />
      )}
    </div>
  );
};
