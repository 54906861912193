import { Dropdown, Form } from 'antd';
import React, { useState } from 'react';
import styles from './index.module.scss';
import { SketchPicker } from 'react-color';
import { ColorVariants } from '../../../lib/types/colors&fonts';
import { AdditionalColorVariants } from '../../../modules/designSettings/types';
import cx from 'classnames';

type Props = {
  color: string;
  fieldName: ColorVariants | AdditionalColorVariants;
  label: string;
  className?: string;
  setColor: (fieldName: string, color: string) => void;
};

export const ColorPickerFormItem = ({ color, fieldName, label, className, setColor }: Props) => {
  const [visible, setVisible] = useState(false);
  const handleVisibleChange = (flag: boolean) => setVisible(flag);

  const handleChange = (colorObj: { hex: string }) => {
    setColor(fieldName, colorObj.hex);
  };

  return (
    <Form.Item className={cx(styles.color_picker_form_item, className)}>
      <div className={styles.label}>{label}</div>

      <Dropdown
        open={visible}
        trigger={['click']}
        placement="top"
        onOpenChange={handleVisibleChange}
        dropdownRender={() => (
          <div style={{ width: 220 }}>
            <SketchPicker color={color} onChange={handleChange} disableAlpha presetColors={[]} />
          </div>
        )}
      >
        <div style={{ backgroundColor: color }} className={styles.color_picker_wrap} />
      </Dropdown>
      <div className={cx(styles.footer_label, 'secondary_color')}>{color.toUpperCase()}</div>
    </Form.Item>
  );
};
