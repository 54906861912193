import { useTranslation } from 'react-i18next';

const error = 'pages.logIn.errors.length';

export const useUsernameValidation = () => {
  const { t } = useTranslation();

  const usernameRules = [
    {
      pattern: /^.{1,200}$/,
      message: t(error),
    },
    {
      whitespace: true,
    },
  ];

  return { usernameRules };
};
