import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormItemName } from '../../../../../lib/models/Form';
import { FormInput } from '../../../../../components/FormInput';
import { FormItem } from '../../../../../components/FormItem';
import { EMAIL_PATTERN } from '../../../../../lib/utils/regexp';

const userManagement = 'pages.userManagement';

type Props = {
  disabled?: boolean;
};

export const EmailFormItem: FC<Props> = ({ disabled = false }) => {
  const { t } = useTranslation();

  return (
    <FormItem
      name={FormItemName.EMAIL}
      hasFeedback={true}
      rules={[
        { required: true },
        {
          pattern: EMAIL_PATTERN,
          message: t(`${userManagement}.formItems.email.error`),
        },
      ]}
    >
      <FormInput disabled={disabled} label={t(`${userManagement}.formItems.email.label`)} />
    </FormItem>
  );
};
