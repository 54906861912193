import { EndUserWishlistHouseModelMobile } from '../EndUserWishlistHouseModelMobile';
import { EndUserWishlistHouseModelDesktop } from '../EndUserWishlistHouseModelDesktop';
import { FC } from 'react';
import { WishlistPageT } from '../../../../lib/types/sections';
import { useAppSelector } from '../../../../lib/store/hooks';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';

type Props = {
  content?: WishlistPageT;
};

export const EndUserWishlistHouseModel: FC<Props> = ({ content }) => {
  const { wishlistProducts, demandArea } = useAppSelector(({ endUser }) => endUser);
  const { isMobile } = useContainerQuery();

  const slicedDemandAreas = demandArea?.listDemandArea.slice(0, 4);

  return (
    <>
      {isMobile ? (
        <EndUserWishlistHouseModelMobile content={content} demandAreas={slicedDemandAreas} />
      ) : (
        <EndUserWishlistHouseModelDesktop
          content={content}
          wishlistData={wishlistProducts}
          demandAreas={slicedDemandAreas}
        />
      )}
    </>
  );
};
