import { FC } from 'react';
import { Button, Card, Form, Input, Typography, ColorPicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormSection } from '../../../../components/FormSection';
import { BadgeFormItem } from '../../helpers';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash.svg';
import { FormItem } from '../../../../components/FormItem';
import { useDeleteBadgeMutation } from '../../../../lib/store/api/badge';
import { useParams } from 'react-router-dom';
import { MessageInstance } from 'antd/es/message/interface';

import styles from './index.module.scss';

const { Text } = Typography;

const messages = 'pages.products.badges.messages';
const formItems = 'pages.products.badges.formItems';
const sectionTitle = 'pages.products.badges.sectionTitle';

type Props = {
  name: number;
  projectId: number;
  messageApi: MessageInstance;
  remove: (arg: number | number[]) => void;
};

export const ProductBadgeListItem: FC<Props> = ({ name, messageApi, projectId, remove }) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const badgeId = Form.useWatch([BadgeFormItem.BADGES, name, BadgeFormItem.ID], form);
  const { id: tenantId } = useParams();

  const [deleteBadge, { isLoading }] = useDeleteBadgeMutation();

  const handleDeleteBadge = async () => {
    try {
      if (badgeId) {
        await deleteBadge({ tenantId: String(tenantId), badgeId }).unwrap();
        messageApi.success(t(`${messages}.updatingSuccess`));
      }
      remove(name);
    } catch (error) {
      messageApi.error(t(`${messages}.updatingError`));
    }
  };

  return (
    <Card className={styles.root}>
      <Text className={styles.sectionTitle}>{t(sectionTitle)}</Text>
      <div className={styles.section}>
        <div>
          <Text className={styles.formItemTitle}>{t(`${formItems}.name.title`)}</Text>
          <FormSection
            formItemProps={{
              hasFeedback: true,
              name: [name, BadgeFormItem.NAME],
              rules: [{ required: true, message: t(`${formItems}.name.errors.empty`) }],
            }}
            textFieldProps={{
              placeholder: t(`${formItems}.name.label`),
            }}
          />
        </div>
        <div>
          <Text className={styles.formItemTitle}>{t(`${formItems}.backgroundColor.title`)}</Text>
          <Form.Item name={[name, BadgeFormItem.BACKGROUND_COLOR]} normalize={(val) => val?.toHexString()}>
            <ColorPicker defaultValue="#fff" disabledAlpha size="large" format="hex" />
          </Form.Item>
        </div>
        <div>
          <Text className={styles.formItemTitle}>{t(`${formItems}.fontColor.title`)}</Text>
          <Form.Item name={[name, BadgeFormItem.FONT_COLOR]} normalize={(val) => val?.toHexString()}>
            <ColorPicker defaultValue="#000" disabledAlpha size="large" format="hex" />
          </Form.Item>
        </div>
      </div>

      <FormItem name={[name, BadgeFormItem.PROJECT_ID]} hidden initialValue={projectId}>
        <Input type="text" />
      </FormItem>

      <Button
        type="default"
        icon={<TrashIcon />}
        onClick={handleDeleteBadge}
        className={styles.trashButton}
        loading={isLoading}
      />
    </Card>
  );
};
