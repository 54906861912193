import { FC } from 'react';
import cx from 'classnames';
import { ReactComponent as IconLock } from '../../../../assets/icons/lock.svg';
import { ReactComponent as IconCheck } from '../../../../assets/icons/check-filled.svg';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { IconT } from '../../../designSettings/types';
import { useAppSelector } from '../../../../lib/store/hooks';
import { ColorVariants } from '../../../../lib/types/colors&fonts';
import { useContainerQuery } from '../../../../lib/hooks/endUserHooks/useContainerQuery';
import { IconComponent } from '../../../../components/IconComponent';

import styles from './index.module.scss';

type Props = {
  active: boolean;
  status: string;
  points: number;
  userPoints: number;
  remainingPointsToNextLevel: number;
  subLevels: any[];
  icon?: IconT;
};

export const StatusLevelCard: FC<Props> = ({ active, status, points, icon, userPoints, subLevels }) => {
  const { isLargerMobile } = useContainerQuery();

  const { brandKit } = useAppSelector(({ endUser }) => endUser);
  return (
    <>
      {isLargerMobile ? (
        <div className={cx(styles.wrapper, { [styles.active]: active })}>
          <EndUserTypography className={styles.title} component="Text" type="headlineFourth">
            {status}
          </EndUserTypography>

          {active ? (
            <IconCheck
              className={styles.icon}
              style={{ color: brandKit?.colors[ColorVariants.PRIMARY_COLOR] }}
            />
          ) : (
            <IconLock className={styles.icon} />
          )}

          <div className={styles.sublevels}>
            {subLevels?.map((item: any, index: any) => {
              return (
                <div
                  key={index}
                  className={cx(styles.sublevel, {
                    [styles.coveredSublevel]: userPoints > item.points,
                  })}
                  style={{ color: brandKit?.colors[ColorVariants.PRIMARY_COLOR] }}
                />
              );
            })}
          </div>
          {points !== 0 && (
            <EndUserTypography type="body" component="Text" className={styles.desktopPoints}>
              {points}
              <IconComponent
                iconData={icon}
                color="currentColor"
                style={{ width: '10px', height: '14px', display: 'inline-block' }}
              />
            </EndUserTypography>
          )}
        </div>
      ) : (
        <div className={styles.mobileWrapper}>
          <div>
            <div className={cx(styles.sublevelsWrapper, { [styles.active]: active })}>
              <div className={styles.header}>
                <EndUserTypography className={styles.title} component="Text" type="headlineSixth">
                  {status}
                </EndUserTypography>
                <EndUserTypography type="body" component="Text" className={styles.points}>
                  {points}
                  <IconComponent
                    iconData={icon}
                    color="currentColor"
                    style={{ width: '10px', height: '14px', display: 'inline-block' }}
                  />
                </EndUserTypography>
              </div>

              <div className={styles.sublevels}>
                {subLevels?.map((item: any, index: any) => {
                  return (
                    <div
                      key={index}
                      className={cx(styles.sublevel, { [styles.coveredSublevel]: userPoints > item.points })}
                      style={{ color: brandKit?.colors[ColorVariants.PRIMARY_COLOR] }}
                    />
                  );
                })}
              </div>
            </div>

            {active ? (
              <IconCheck
                className={styles.icon}
                style={{ color: brandKit?.colors[ColorVariants.PRIMARY_COLOR] }}
              />
            ) : (
              <IconLock className={styles.icon} />
            )}
          </div>
        </div>
      )}
    </>
  );
};
