import { IconT } from '../../modules/designSettings/types';
import { GetTablePayloadT } from './common';

export type DemandArea = {
  id: number;
  name: string;
  activated: boolean;
  position: { number: number } | null;
  projectId: number;
  color: string;
  categories: [
    {
      id: number;
      name: string;
    },
  ];
};

export type GetDemandAreaPayloadT = GetTablePayloadT;

export type CreateDemandAreaPayload = Pick<DemandArea, 'name' | 'position' | 'color'> & {
  tenantId: string;
  projectId: string;
  activated: boolean;
  icon?: IconT;
};

export enum DEMAND_AREAS_COLUMN {
  POSITION = 'position',
  DEMAND_AREAS = 'name',
  COLOR = 'color',
  PRODUCT_CATEGORIES = 'categories',
  STATUS = 'activated',
}
