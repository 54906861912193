import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setRuleActionStatusLevel } from '../../store/slices/endUserSlice';
import { EndUserRuleActionStatusLevelResponseT, RuleActionStatus } from '../../types/endUser';

export const useGetRuleActionStatusLevel = () => {
  const { statusLevels } = useAppSelector(({ endUser }) => endUser);
  const dispatch = useAppDispatch();

  const updateRuleActionStatusLevel = useCallback(
    (userRuleActionStatusLevelData: EndUserRuleActionStatusLevelResponseT[]) => {
      if (statusLevels && userRuleActionStatusLevelData) {
        const maxStatusRuleAction = userRuleActionStatusLevelData?.find(
          (item) => item?.status === RuleActionStatus.TO,
        );
        const minStatusRuleAction = userRuleActionStatusLevelData?.find(
          (item) => item?.status === RuleActionStatus.FROM,
        );

        const maxLockedLevelIndex = statusLevels?.findIndex(
          (item) => item?.id === maxStatusRuleAction?.statusLevelId,
        );
        const minUnlockedLevelIndex = statusLevels?.findIndex(
          (item) => item?.id === minStatusRuleAction?.statusLevelId,
        );

        let maxAvailablePoints;
        let extraPoints;

        if (maxLockedLevelIndex != -1) {
          maxAvailablePoints = statusLevels[maxLockedLevelIndex]?.subLevels?.[0]?.points - 1;
        }
        if (minUnlockedLevelIndex != -1) {
          extraPoints = statusLevels[minUnlockedLevelIndex]?.subLevels?.[0]?.points;
        }

        dispatch(
          setRuleActionStatusLevel({
            maxAvailablePoints: maxAvailablePoints || null,
            extraPoints: extraPoints || null,
          }),
        );
      }
    },
    [dispatch, statusLevels],
  );

  return { updateRuleActionStatusLevel };
};
