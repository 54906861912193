import { useProductSearchDataColumn } from './useProductSearchDataColumn';
import { PRODUCTS_COLUMNS } from '../helpers/consts';
import { useTranslation } from 'react-i18next';

const points = 'pages.products.products.columns.points';

export const useProductPointsColumn = () => {
  const { t } = useTranslation();

  return useProductSearchDataColumn({
    title: t(points),
    arrangedId: PRODUCTS_COLUMNS.POINTS,
    disableFiltering: true,
  });
};
