import { AppCheckbox } from '../../../AppCheckox';
import styles from './index.module.scss';

type Props = {
  isSelected: boolean;
  isDisabled?: boolean;
  label?: string;
  innerProps: any;
};

export function MultiSelectOption({ isSelected, isDisabled, label, ...rest }: Props) {
  return (
    <div className={styles.multiSelect}>
      <AppCheckbox
        checked={isSelected}
        isDisabled={isDisabled}
        label={label}
        onChange={rest.innerProps.onClick}
      />
    </div>
  );
}
