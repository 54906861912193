import { useTranslation } from 'react-i18next';
import { MessageInstance } from 'antd/es/message/interface';
import { ModalActions } from '../../../lib/models/Modal';
import { useAppDispatch } from '../../../lib/store/hooks';
import { ProjectT } from '../../../lib/types/entities';
import { setCurrentProject, setModalOpened } from '../../../lib/store/slices/projectsSlice';
import { useUpdateProjectStatusMutation } from '../../../lib/store/api/project';
import { useParams } from 'react-router';
import { useDownloadUserReportMutation } from '../../../lib/store/api/products';

const success = 'pages.projects.status.success';
const error = 'pages.projects.status.error';
const downloadError = 'pages.projects.download.downloadError';

export const useProjectsActionsRequest = (messageApi: MessageInstance) => {
  const { t } = useTranslation();

  const [updateProjectStatus] = useUpdateProjectStatusMutation();

  const dispatch = useAppDispatch();
  const { id: tenantId } = useParams();
  const [downloadUserReport, { isLoading }] = useDownloadUserReportMutation();

  const onUpdateProjectStatus = async (row: ProjectT) => {
    try {
      await updateProjectStatus({
        id: row.id,
        status: !row.activated,
        tenantId: Number(tenantId),
      }).unwrap();
      messageApi.success(t(success));
    } catch (err: any) {
      messageApi.error(err?.data?.message || t(error));
    }
  };

  const onDeleteProject = (project: ProjectT) => {
    dispatch(setCurrentProject(project));
    dispatch(setModalOpened(ModalActions.DELETE));
  };

  const onDownloadProductsData = async ({
    projectId,
    tenantId,
    modelName,
  }: {
    projectId: number;
    tenantId: string;
    modelName: string;
  }) => {
    try {
      const response = await downloadUserReport({ tenantId, projectId }).unwrap();

      const fileName = `user report ${modelName}.xlsx`;
      const fileUrl = response || '';

      const hiddenElement = document.createElement('a');

      hiddenElement.href = fileUrl;
      hiddenElement.target = '_blank';
      hiddenElement.download = fileName;
      hiddenElement.click();
      document.parentNode?.removeChild(hiddenElement);

      //eslint-disable-next-line
    } catch (error: any) {
      const message = error?.data?.message || t(downloadError);

      messageApi.error(message);
    }
  };

  return { onUpdateProjectStatus, onDeleteProject, isLoadingProductsData: isLoading, onDownloadProductsData };
};
