import { createStyles } from '../helpers/createStyles';
import { Option } from '../types';

type Props = {
  selected?: Option | Option[];
  ariaInvalid: string;
  options: Option[]; // TODO expand type with SelectableOptionsT;
  defaultSelected: Option[];
  isMultiSelectWithGroupableOptions?: boolean;
};

export const useAppSelect = ({
  selected,
  defaultSelected,
  options,
  ariaInvalid,
  isMultiSelectWithGroupableOptions,
}: Props) => {
  function haveMultiSelectedOptionListCompleteStructure(data: Option[]) {
    return Boolean(data[0]?.title);
  }

  function isSelectedOption(original: Option, selected: any) {
    return (
      original?.value === selected?.value ||
      original?.value?.includes?.(selected?.value) ||
      original?.value === selected
    );
  }

  function getMultiSelectedOptionList(allOptionList: Option[], selectedOptionList: Option[]) {
    if (isMultiSelectWithGroupableOptions) {
      return allOptionList?.reduce((acc: any, option: any) => {
        const selectedOptions = option?.options?.filter((childrenOption: Option) =>
          selectedOptionList.some((item2: Option) => isSelectedOption(childrenOption, item2)),
        );
        return [...acc, ...selectedOptions];
      }, []);
    }
    return haveMultiSelectedOptionListCompleteStructure(selectedOptionList)
      ? selectedOptionList
      : allOptionList.filter((item1: Option) =>
          selectedOptionList.some((item2: Option) => isSelectedOption(item1, item2)),
        );
  }

  function formatAppNewSelectData(options: Option[], data: Option[] | Option) {
    if (data) {
      return Array.isArray(data)
        ? getMultiSelectedOptionList(options, data)
        : getSingleSelectedOption(options, data);
    }

    return undefined;
  }

  function getSingleSelectedOption(allOptionList: Option[], selectedOption: Option) {
    return allOptionList.find((item: Option) => isSelectedOption(item, selectedOption));
  }

  const isError = ariaInvalid === 'true';
  const mainStyles = createStyles({ isError });

  const $value = options && formatAppNewSelectData(options, selected as any);
  const $defaultValue = options && formatAppNewSelectData(options, defaultSelected);

  return {
    $value,
    $defaultValue,
    mainStyles,
  };
};
