import { Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../lib/store/hooks';
import { ModalActions } from '../../../../../lib/models/Modal';
import { useResetFormOnCloseModal } from '../../../../../lib/hooks/useResetFormOnCloseModal';
import { AppModal } from '../../../../../components/AppModal';
import { FC, useEffect, useMemo, useState } from 'react';
import { useUpdateBonusCatalogMutation } from '../../../../../lib/store/api/bonusCatalog';
import { BONUS_CATALOG_FORM_ITEMS } from '../../../helpers';
import { UploadResponseT } from '../../../../../lib/types/entities';
import { CreateBonusCatalogPayload } from '../../../../../lib/types/bonusCatalog';
import { NameFormItem } from '../FormItems/NameFormItem';
import { TitleFormItem } from '../FormItems/TitleFormItem';
import { DescriptionFormItem } from '../FormItems/DescriptionFormItem';
import { PayoutOptions } from '../FormItems/PayoutOptions';
import { UploadPicture } from '../FormItems/UploadPicture';
import { initialMediaTenantState } from '../../../../tenants/helpers/constants';
import { setModalClosed } from '../../../../../lib/store/slices/bonusCatalogSlice';
import { PayoutTypes } from '../FormItems/PayoutTypes';
import { BonusCatalogFormValues } from '../../../types';

import styles from '../../BonusCatalog/index.module.scss';

const editModal = `pages.bonusCatalogTab.editModal`;

type Props = {
  tenantId: string;
  modelId: string;
};

export const EditBonusCatalogModal: FC<Props> = ({ tenantId, modelId }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const { isModalOpen, modalType, selectedBonusCatalog } = useAppSelector(({ bonusCatalog }) => bonusCatalog);
  const isActivatedModal = modalType === ModalActions.ACTIVATE;
  const isOpened = isModalOpen && (modalType === ModalActions.EDIT || isActivatedModal);
  const [pictureState, setPictureState] = useState<UploadResponseT | null>(null);

  const [updateBonusCatalog, { isLoading }] = useUpdateBonusCatalogMutation();

  useResetFormOnCloseModal({ form, isOpen: isOpened });

  const onClose = () => {
    dispatch(setModalClosed());
  };

  const initialFormValues = useMemo(() => {
    if (selectedBonusCatalog) {
      return selectedBonusCatalog;
    }
    return {};
  }, [selectedBonusCatalog]);

  useEffect(() => {
    form.setFieldsValue(initialFormValues);
    setPictureState(selectedBonusCatalog?.picture as UploadResponseT);
  }, [form, initialFormValues, selectedBonusCatalog?.picture]);

  const onFinish = async (values: BonusCatalogFormValues) => {
    const payload: CreateBonusCatalogPayload = {
      name: values[BONUS_CATALOG_FORM_ITEMS.NAME],
      title: values[BONUS_CATALOG_FORM_ITEMS.TITLE],
      description: values[BONUS_CATALOG_FORM_ITEMS.DESCRIPTION],
      payoutOption: values[BONUS_CATALOG_FORM_ITEMS.PAYOUT_OPTION],
      payoutType: values[BONUS_CATALOG_FORM_ITEMS.PAYOUT_TYPE],
      picture: pictureState,
      projectId: modelId,
      tenantId,
      id: selectedBonusCatalog?.id as number,
    };

    try {
      await updateBonusCatalog(payload).unwrap();
      messageApi.success(t(`${editModal}.successText`));
      onClose();
    } catch (err: any) {
      messageApi.error(err?.data?.message || t(`${editModal}.errorText`));
    }
  };

  const onConfirm = () => {
    form.submit();
  };

  const afterUploadAction = (value: UploadResponseT | null) => {
    setPictureState(value);
  };

  const deletePicture = () => afterUploadAction(initialMediaTenantState as any);

  return (
    <AppModal
      className={styles.modal}
      open={isOpened}
      onCancel={onClose}
      onOk={onConfirm}
      getContainer={false}
      titleText={t(`${editModal}.title`)}
      isOkLoading={isLoading}
    >
      {contextHolder}
      {isOpened && (
        <Form initialValues={initialFormValues} className={styles.form} form={form} onFinish={onFinish}>
          <NameFormItem />
          <TitleFormItem />
          <DescriptionFormItem />

          <UploadPicture
            afterUploadAction={afterUploadAction}
            picture={pictureState}
            onDeleteClick={deletePicture}
          />
          <PayoutOptions />
          <PayoutTypes />
        </Form>
      )}
    </AppModal>
  );
};
