import { EndUserSignUpCard } from '../../modules/endUserSignUp/components/EndUserSignUpCard';

import styles from './styles.module.scss';

export const EndUserSignUpPage = () => {
  return (
    <div className={styles.container}>
      <EndUserSignUpCard />
    </div>
  );
};
