import { FC } from 'react';
import { FormInstance } from 'antd';
import { BasicCardData } from './BasicCardData';
import { BrowserInformationCardData } from './BrowserInformationCardData';
import { MailServerCard } from './MailServerCard';
import { TrackingCard } from './TrackingCard';
import { DataProtectionCard } from './DataProtectionCard';

type Props = {
  form: FormInstance;
};
export const SettingsForm: FC<Props> = ({ form }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
      <BasicCardData form={form} />
      <BrowserInformationCardData form={form} />
      <MailServerCard form={form} />
      <TrackingCard form={form} />
      <DataProtectionCard form={form} />
    </div>
  );
};
