import { useSearchParams } from 'react-router-dom';
import { QueryParams, Tabs } from '../../../lib/types/queryParams';
import { TableHeaderCell } from '../../../components/Table/TableHeaderCell/TableHeaderCell';

import { IconButton } from '../../../components/IconButton';
import { ReactComponent as ChevronRight } from './../../../assets/icons/chevron-right-empty-fill.svg';

import styles from './../styles.module.scss';
import { useAppDispatch } from '../../../lib/store/hooks';
import { setCurrentStatusLevel } from '../../../lib/store/slices/statusLevelSlice';
import { StatusLevelT } from '../../../lib/types/statusLevel';

export const useStatusLevelArrowButtonColumn = () => {
  const [, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const onClickChevron = (row: StatusLevelT) => {
    setSearchParams({ [QueryParams.TAB]: Tabs.STATUS_LEVEL_CONFIGURATOR });
    dispatch(setCurrentStatusLevel(row));
  };

  return {
    title: <TableHeaderCell withArrange={false} />,
    width: 100,
    render: (_: unknown, row: StatusLevelT) => {
      return (
        <div className={styles.arrowContainer}>
          <IconButton icon={<ChevronRight fill="red" />} onClick={() => onClickChevron(row)} />
        </div>
      );
    },
  };
};
