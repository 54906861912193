import { FormInstance } from 'antd';
import { ArrangeFormItem } from '../../../../lib/models/Arrange';
import {
  clearDateFilters,
  clearListFilters,
  getParsedSelectedItems,
  LIST_VALUE_PROP_NAME,
} from '../../../../lib/utils/arranges-helpers';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { UsersFiltersPayload, setFilters, setPage } from '../../../../lib/store/slices/userManagementSlice';
import { User } from '../../helpers/constants';
import { TableSortOrder } from '../../../../lib/utils/constants';
import { closeModalAfterApply } from '../../../../features/table/helpers/closeModalAfterApply';
import { isArrayNotEmpty, isObjectEmpty } from '../../../../lib/utils/helpers';

type Props = {
  arrangedId: string;
  form: FormInstance<any>;
  setFilterLabel?: any;
  getFilters?: any;
  getFilterLabel?: any;
  filterFieldName?: string;
  isParsedByValue?: boolean;
  isDate?: boolean;
};
export const useUserArrangeRequests = ({
  arrangedId,
  setFilterLabel,
  getFilters,
  getFilterLabel,
  form,
  filterFieldName,
  isParsedByValue = false,
  isDate = false,
}: Props) => {
  const dispatch = useAppDispatch();
  const sortingAndFiltersData = useAppSelector(({ userManagement }) => userManagement);

  const clearFormFilters = () => {
    isDate ? clearDateFilters(form) : clearListFilters(form);
  };

  const applyArranges = async () => {
    const values = form.getFieldsValue();
    const sort = values[ArrangeFormItem.SORT_ORDER];
    const filters = getFilters?.();

    const parsedItems = getParsedSelectedItems(form);

    const searchItems = parsedItems.map((item: { value: string; label: string }) => {
      if (isParsedByValue) {
        return item.value;
      } else {
        return item.label;
      }
    });

    const resultedFilters = {
      arrangeId: arrangedId as User,
      sorting: {
        sortOrder: sort as TableSortOrder,
        sortFieldName: arrangedId,
        withSorting: true,
      },
      filtering: {
        filterFieldName,
        filter: isDate ? filters : searchItems,
      },
    } as UsersFiltersPayload;

    const isFilterChanged =
      isArrayNotEmpty(resultedFilters.filtering.filter) ||
      !isObjectEmpty(resultedFilters.filtering.filter as any);

    if (isFilterChanged) {
      dispatch(setPage(1));
    }

    dispatch(setFilters(resultedFilters));
    setFilterLabel?.(getFilterLabel());
    closeModalAfterApply(arrangedId);
  };

  const removeFilter = async () => {
    dispatch(
      setFilters({
        arrangeId: arrangedId,
        sorting: { ...sortingAndFiltersData.sorting },
        filtering: { filterFieldName: '', filter: [] },
      } as UsersFiltersPayload),
    );
    setFilterLabel?.(null);
    clearFormFilters();
  };

  const removeFilterValue = async (value: any) => {
    const parsedItems = getParsedSelectedItems(form);
    const filteredItems = parsedItems.filter(
      (item: { [x: string]: any }) => item[LIST_VALUE_PROP_NAME] !== value,
    );

    const updatedFiltersData: UsersFiltersPayload = {
      ...sortingAndFiltersData,
      arrangedId: sortingAndFiltersData.arrangeId,
      filtering: {
        filterFieldName: sortingAndFiltersData.filtering.filterFieldName,
        filter: filteredItems.map((item: { value: string; label: string }) => {
          if (isParsedByValue) {
            return item.value;
          } else {
            return item.label;
          }
        }),
      },
    } as UsersFiltersPayload;

    dispatch(setFilters(updatedFiltersData));

    const stringifiedItems = filteredItems.map(JSON.stringify);
    form.setFieldValue(ArrangeFormItem.SELECTED_ITEMS, stringifiedItems);

    const filterLabel = getFilterLabel();
    setFilterLabel?.(filterLabel);

    if (!filterLabel) {
      clearFormFilters();
    }
  };

  return { applyArranges, removeFilter, removeFilterValue };
};
