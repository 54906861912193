import React, { FC, ReactNode } from 'react';
import { ReactComponent as Logo } from '../../../../assets/images/AuthImage.svg';

import styles from './index.module.scss';

type Props = {
  children: ReactNode;
};

export const AuthLayout: FC<Props> = ({ children }) => {
  return (
    <div className={styles.root}>
      <div className={styles.intro}>
        <Logo className={styles.image} />
        <div className={styles.divider} />
        <div className={styles.nameSection}>
          <div className={styles.name}>Simon-Kucher</div>
          <div className={styles.bold}>engine</div>
        </div>
      </div>
      {children}
    </div>
  );
};
