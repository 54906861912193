import { FC } from 'react';
import { Card, Form, FormInstance, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemTitle } from '../FormItemTitle';
import { FormItemName } from '../../../../lib/models/Form';
import { FormItem } from '../../../../components/FormItem';
import { CoverageTypeT } from '../../../../lib/types/product';
import { AppSegmented } from '../../../../components/AppSegmented';
import { FormCardButton } from '../../../designSettings/components/FormCardButton';
import { AgeRangeRow } from '../AgeRangeRow';

import styles from './index.module.scss';

const ageRange = 'pages.products.productConfigurator.formItems.ageRange';
const coverageType = 'pages.products.productConfigurator.formItems.coverageType';
const { Text } = Typography;

type Props = {
  form: FormInstance;
};

export const AgeRangeCard: FC<Props> = ({ form }) => {
  const { t } = useTranslation();

  const rulesLength = Form.useWatch([FormItemName.AGE_RANGE, FormItemName.RANGES], form)?.length;

  const options = [
    {
      label: t(`${coverageType}.include`),
      value: CoverageTypeT.INCLUDE,
    },
    {
      label: t(`${coverageType}.exclude`),
      value: CoverageTypeT.EXCLUDE,
    },
  ];

  return (
    <Card className={styles.root}>
      <div className={styles.header}>
        <FormItemTitle>{t(`${ageRange}.title`)}</FormItemTitle>
        <FormItem
          hasFeedback
          name={[FormItemName.AGE_RANGE, FormItemName.AGE_RANGE_COVERAGE]}
          className={styles.radioGroup}
          initialValue={CoverageTypeT.INCLUDE}
        >
          <AppSegmented options={options} />
        </FormItem>
      </div>

      <div>
        <Text type="secondary" className={styles.text}>
          {t(`${ageRange}.subLine`)}
        </Text>

        <Form.List name={[FormItemName.AGE_RANGE, FormItemName.RANGES]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <AgeRangeRow key={field.key} name={field.name} remove={remove} />
              ))}
              <FormCardButton disabled={rulesLength >= 10} onClick={() => add()}>
                + {t(`${ageRange}.addRange`)}
              </FormCardButton>
            </>
          )}
        </Form.List>
      </div>
    </Card>
  );
};
