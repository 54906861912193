import { useTranslation } from 'react-i18next';
import { MessageArgsProps } from 'antd';
import { useUpdateUserStatusMutation } from '../../../../lib/store/api/usersManagement';
import { UserDataT } from '../../../../lib/types/auth';
import { setCurrentUsers, setModalOpened } from '../../../../lib/store/slices/userManagementSlice';
import { ModalActions } from '../../../../lib/models/Modal';
import { useAppDispatch } from '../../../../lib/store/hooks';
import { MessageInstance } from 'antd/es/message/interface';

export const useUsersActionsRequests = (messageApi: MessageInstance) => {
  const { t } = useTranslation();

  const [updateUserStatus] = useUpdateUserStatusMutation();
  const dispatch = useAppDispatch();

  const onUpdateUserStatus = async (user: UserDataT) => {
    const messageConfig = {} as MessageArgsProps;
    try {
      await updateUserStatus({
        userId: user.id,
        active: user.status === 'DEACTIVATED',
      }).unwrap();
      messageConfig.content = t('pages.userManagement.success.updateUserStatus');
      messageConfig.type = 'success';
    } catch (err: any) {
      messageConfig.content =
        err?.data?.message || t('pages.userManagement.errors.updateUserStatus');
      messageConfig.type = 'error';
    } finally {
      messageApi.open({
        duration: 3,
        ...messageConfig,
      } as MessageArgsProps);
    }
  };

  const onDeleteUser = (user: UserDataT) => {
    dispatch(setCurrentUsers(user));
    dispatch(setModalOpened(ModalActions.DELETE));
  };

  return { onUpdateUserStatus, onDeleteUser };
};
