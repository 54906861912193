import { TAGS_KEY, api } from './';
import { TableSortOrder, WITHOUT_PAGINATION_ELEMENTS_SIZE } from '../../utils/constants';
import { isLength } from '../../utils/helpers';
import { AddTenantPayload, EditTenantPayload, TenantT } from '../../types/tenants';
import { ResultWithPagination } from '../../types/pagination';
import { TENANT_COLUMN } from '../../../modules/tenants/helpers/constants';
import { OptionT } from '../../types/common';

type UsersResponseT = {
  id: number;
  login: string;
};

export type GetTenantsPayload = {
  page?: number;
  arrangedId?: TENANT_COLUMN;
  sortOrder?: TableSortOrder;
  sortFieldName?: string;
  withSorting?: boolean;
  filterFieldName?: string;
  filter?: string[];
};

export const tenantsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTenants: build.query<ResultWithPagination<TenantT[]>, GetTenantsPayload>({
      query: ({ page, filterFieldName, sortFieldName, filter, sortOrder }) => ({
        url: `tenant/get-tenants?size=10&page=${page || 0}${
          sortFieldName && sortOrder && sortOrder !== TableSortOrder.NONE
            ? `&sort=${sortFieldName},${sortOrder}`
            : ''
        }`,
        method: 'POST',
        body: Array.isArray(filter)
          ? {
              [filterFieldName as string]: filter,
            }
          : filter,
      }),
      providesTags: [TAGS_KEY.TENANTS],
    }),
    getAllTenants: build.query<ResultWithPagination<TenantT[]>, { searchFieldName: string; search: string }>({
      query: ({ search, searchFieldName }) => ({
        url: `tenant/get-tenants?size=${WITHOUT_PAGINATION_ELEMENTS_SIZE}&page=0`,
        method: 'POST',
        body: {
          [searchFieldName]: search,
        },
      }),
    }),
    getTenantById: build.query<TenantT, number>({
      query: (id: number) => ({
        url: `tenant/${id}`,
        headers: {
          'X-TENANT': String(id),
        },
      }),
    }),
    getTenantDomains: build.query<OptionT[], string>({
      query: (tenantId = '1') => ({
        // 1 because we don't have tenant at moment
        url: `domain/domains`,
        headers: {
          'X-TENANT': tenantId,
        },
      }),
      transformResponse: (response: Array<{ id: number; name: string }>) => {
        return isLength(response)
          ? response?.map((item) => ({
              value: item.id,
              title: item.name,
            }))
          : [];
      },
    }),
    updateTenantStatus: build.mutation<unknown, { id: number; status: boolean }>({
      query: ({ id, status }) => ({
        url: `tenant/${id}/set-active?active=${status}`,
        method: 'PATCH',
        headers: {
          'X-TENANT': String(id),
        },
      }),
      invalidatesTags: [TAGS_KEY.TENANTS],
    }),
    addTenant: build.mutation({
      query: (body: AddTenantPayload) => ({
        url: 'tenant/',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: [TAGS_KEY.TENANTS],
    }),
    editTenant: build.mutation({
      query: (body: EditTenantPayload) => ({
        url: 'tenant/update',
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: [TAGS_KEY.TENANTS],
    }),
    deleteTenant: build.mutation({
      query: (id: number) => ({
        url: `tenant/${id}/delete`,
        method: 'DELETE',
        headers: {
          'X-TENANT': String(id),
        },
      }),
      invalidatesTags: [TAGS_KEY.TENANTS],
    }),
    getUsersForTenants: build.query<OptionT[], unknown>({
      query: (body: unknown) => ({
        url: `admin/get-users?size=${WITHOUT_PAGINATION_ELEMENTS_SIZE}&page=0`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: ResultWithPagination<UsersResponseT[]>) => {
        return isLength(response?.content)
          ? response?.content.map((item) => ({
              value: item.id,
              title: item.login,
            }))
          : [];
      },
    }),
  }),
});

export const {
  useGetTenantsQuery,
  useGetUsersForTenantsQuery,
  useAddTenantMutation,
  useDeleteTenantMutation,
  useEditTenantMutation,
  useGetTenantByIdQuery,
  useUpdateTenantStatusMutation,
  useGetTenantDomainsQuery,
  useLazyGetAllTenantsQuery,
  useLazyGetUsersForTenantsQuery,
} = tenantsApi;
