import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollRefresh = () => {
  const location = useLocation();

  const handleScrollReset = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    handleScrollReset();
  }, [location]);

  return null;
};
