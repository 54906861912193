import { useAppDispatch } from '../../../../lib/store/hooks';
import { Space } from 'antd';
import { IconButton } from '../../../../components/IconButton';
import { EditIcon } from '../../../../assets/icons/EditIcon';
import { ActionsDropdown } from '../../../../components/Table/ActionsDropdown/ActionsDropdown';
import { ProjectT } from '../../../../lib/types/entities';
import { setCurrentProject, setModalOpened } from '../../../../lib/store/slices/projectsSlice';
import { ModalActions } from '../../../../lib/models/Modal';

export const ProjectActionsBodyCell = ({ row }: any) => {
  const dispatch = useAppDispatch();

  const openEditProjectModal = (project: ProjectT) => () => {
    dispatch(setCurrentProject(project));
    dispatch(setModalOpened(ModalActions.EDIT));
  };

  return (
    <Space size="middle">
      <IconButton icon={<EditIcon />} onClick={openEditProjectModal(row)} />
      <ActionsDropdown table="projects" row={row} />
    </Space>
  );
};
