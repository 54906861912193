import dayjs from 'dayjs';
import { useAppSelector } from '../../../lib/store/hooks';

export const useGetExpirationBonusDate = () => {
  const { demandArea } = useAppSelector(({ endUser }) => endUser);

  const date = dayjs(demandArea?.project?.currentDueDate).format('DD.MM.YYYY');

  return date;
};
