import { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { setModalClosed } from '../../../../lib/store/slices/statusLevelSlice';
import { ModalActions } from '../../../../lib/models/Modal';
import { DeleteStatusLevelModal } from '../DeleteStatusLevelModal';
import { MessageInstance } from 'antd/es/message/interface';

type Props = {
  messageApi: MessageInstance;
};

export const StatusLevelModals: FC<Props> = ({ messageApi }) => {
  const {
    isModalOpen,
    modalType: currentModalType,
    selectedStatusLevel,
  } = useAppSelector(({ statusLevel }) => statusLevel);
  const dispatch = useAppDispatch();

  const close = () => dispatch(setModalClosed());
  const checkIsOpen = (modalType: ModalActions | null) => isModalOpen && modalType === currentModalType;

  return (
    <>
      <DeleteStatusLevelModal
        isOpen={checkIsOpen(ModalActions.DELETE)}
        close={close}
        selectedStatusLevel={selectedStatusLevel}
        messageApi={messageApi}
      />
    </>
  );
};
