import { TENANT_COLUMN } from '../helpers/constants';
import { useTranslation } from 'react-i18next';
import { useTenantNumberColumn } from './useTenantNumberColumn';

const projectsAmount = 'pages.tenants.columns.projectsAmount';

export const useProjectsAmountColumn = () => {
  const { t } = useTranslation();


  return useTenantNumberColumn({
    title: t(projectsAmount),
    arrangedId: TENANT_COLUMN.PROJECTS_AMOUNT,
    columnProps: {
      width: 140,
    },
  });
};
