import { FC } from 'react';
import { LogInCard } from '../../modules/auth/components/LogInCard';

import { AuthLayout } from '../../modules/auth/layouts/AuthLayout';

export const LogInPage: FC = () => {
  return (
    <AuthLayout>
      <LogInCard />
    </AuthLayout>
  );
};
