import { useTranslation } from 'react-i18next';
import { Form, message, MessageArgsProps } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { useDeleteUserMutation } from '../../../../lib/store/api/usersManagement';
import { ModalActions } from '../../../../lib/models/Modal';
import { setModalClosed } from '../../../../lib/store/slices/userManagementSlice';
import { useResetFormOnCloseModal } from '../../../../lib/hooks/useResetFormOnCloseModal';
import { AppModal } from '../../../../components/AppModal';
import Paragraph from 'antd/es/typography/Paragraph';

const MESSAGE_SHOWING_TIME = 3;

const userManagement = 'pages.userManagement';

export const DeleteUserModal = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useAppDispatch();
  const selectedUser = useAppSelector((state) => state.userManagement.selectedUser);

  const [deleteUser, { isLoading }] = useDeleteUserMutation();

  const isModalOpen = useAppSelector((state) => state.userManagement.isModalOpen);

  const modalType = useAppSelector((state) => state.userManagement.modalType);
  const isOpen = isModalOpen && modalType === ModalActions.DELETE;
  const onClose = () => dispatch(setModalClosed());

  useResetFormOnCloseModal({ form, isOpen });

  const onConfirm = () => {
    form.submit();
  };

  const onFinish = async () => {
    const messageConfig = {} as MessageArgsProps;

    try {
      await deleteUser(selectedUser?.id as number).unwrap();
      messageConfig.content = t(`${userManagement}.success.deleteUser`);
      messageConfig.type = 'success';
      onClose();
    } catch (err: any) {
      messageConfig.content = err?.data?.message || t(`${userManagement}.errors.deleteUser`);
      messageConfig.type = 'error';
    } finally {
      messageApi.open({
        duration: MESSAGE_SHOWING_TIME,
        ...messageConfig,
      } as MessageArgsProps);
    }
  };

  return (
    <AppModal
      open={isOpen}
      onCancel={onClose}
      onOk={onConfirm}
      okText={t('generic.delete')}
      titleText={t(`${userManagement}.modals.deleteUserTitle`) + ' ' + selectedUser?.login}
      isOkLoading={isLoading}
    >
      {contextHolder}
      {isOpen && (
        <Form form={form} onFinish={onFinish}>
          <Paragraph style={{ marginBottom: '10px' }}>{selectedUser?.fullName}</Paragraph>
          <Paragraph style={{ marginBottom: '10px' }}>{selectedUser?.email}</Paragraph>
          <Paragraph style={{ marginBottom: '24px' }}>
            {t(`${userManagement}.modals.deleteUserText`)}
          </Paragraph>
        </Form>
      )}
    </AppModal>
  );
};
