import { FC } from 'react';
import { BenefitT } from '../../../../lib/types/sections';
import styles from './index.module.scss';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { IconComponent } from '../../../../components/IconComponent';
import { useNavigate } from 'react-router-dom';
import { SectionName } from '../../../../lib/models/Section';
import { setCurrentCategory } from '../../../../lib/store/slices/endUserSlice';
import { useAppDispatch } from '../../../../lib/store/hooks';

type Props = BenefitT & {
  backgroundColor?: string;
  iconColor?: string;
  fontColor?: string;
};

export const BenefitCard: FC<Props> = ({
  headline4,
  description,
  icon,
  fontColor,
  iconColor,
  backgroundColor,
  link,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClickCard = () => {
    if (typeof link === 'string') {
      window.open(link, '_blank');
    } else if (link) {
      navigate(`/house-model/${link.demandAreaId}`, {
        state: { scrollTo: SectionName.PRODUCT_DETAILS },
      });

      setTimeout(() => {
        dispatch(setCurrentCategory({ category: { id: link.categoryId } }));
      }, 10);
    }
  };

  return (
    <div
      className={styles.wrapper}
      style={{ backgroundColor: backgroundColor, color: fontColor, cursor: link ? 'pointer' : 'default' }}
      onClick={handleClickCard}
      role="presentation"
    >
      <IconComponent className={styles.icon} iconData={icon} color={iconColor} />
      <EndUserTypography type="headlineFourth" component="Title" level={4} className={styles.title}>
        {headline4}
      </EndUserTypography>
      <EndUserTypography type="body" component="Text" className={styles.description}>
        {description}
      </EndUserTypography>
    </div>
  );
};
