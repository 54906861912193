import React, { FC } from 'react';
import { ProductCategoriesTable } from '../ProductCategoriesTable';
import { ProductCategoriesModals } from '../Modals';

type Props = {
  handleTabChange: (key: string) => void;
};

export const ProductCategoriesTab: FC<Props> = ({ handleTabChange }) => {
  return (
    <>
      <ProductCategoriesModals />
      <ProductCategoriesTable handleTabChange={handleTabChange} />
    </>
  );
};
