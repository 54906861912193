import { findSubElementsByName } from '../helpers';
import { QuantifiersValues } from './entities';
import { GetPublicDictionaryResponseT } from '../../../lib/types/ruleBuilder';

export enum OperatorTypes {
  'Element' = 'Element',
  'Quantifier' = 'Quantifier',
  'Value' = 'Value',
  'Status' = 'Status',
  'LogicalOperator' = 'Logical operator',
  'ComparisonOperator' = 'Comparison operator',
  'ArithmeticOperator' = 'Arithmetic operator',
}

const getDefaultValueDependsOnOperatorType = (operatorType: OperatorTypes) => {
  switch (operatorType) {
    case OperatorTypes.Quantifier:
      return (Object.values(QuantifiersValues)?.[0] as string) || 'Any';
    default:
      return null;
  }
};

export const getOperatorsArray = (dictionaries: GetPublicDictionaryResponseT | null) => {
  return Object.values(OperatorTypes).map((value) => ({
    name: value,
    subElements: findSubElementsByName(value, dictionaries),
    defaultValue: getDefaultValueDependsOnOperatorType(value),
  }));
};
