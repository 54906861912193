import React from 'react';
import styles from './styles.module.scss';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const notFoundPage = 'endUser.notFoundPage';

export const EndUserNotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.shadow} />
      <div className={styles.textContainer}>
        <Text className={styles.title}>{t(`${notFoundPage}.title`)}</Text>
        <Text className={styles.subTitle}>{t(`${notFoundPage}.subline`)}</Text>
      </div>
    </div>
  );
};
