import { DemandDataHeaderCell } from './DemandDataHeaderCell';
import { ReactNode } from 'react';
import { DEMAND_AREAS_COLUMN } from '../../../../lib/types/demandArea';

type useDemandsDataColumnProps = {
  title: string | ReactNode;
  arrangedId: DEMAND_AREAS_COLUMN;
  columnProps: {
    width: number;
    render?: (params?: any) => ReactNode;
  };
};

export const useDemandsDataColumn = (props: useDemandsDataColumnProps) => {
  const { columnProps, ...rest } = props;

  return {
    ...columnProps,
    dataIndex: props.arrangedId,
    title: <DemandDataHeaderCell {...rest} />,
  };
};
