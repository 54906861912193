import { FC } from 'react';
import { Modal } from 'antd';
import { ProductT } from '../../../../lib/types/product';
import { EndUserProductCard } from '../EndUserProductCard';
import { IconT } from '../../../designSettings/types';
import { BrandKitThemeConfiguration, ColorVariants } from '../../../../lib/types/colors&fonts';
import { IconButton } from '../../../../components/IconButton';
import { ReactComponent as CrossIcon } from './../../../../assets/icons/CrossIcon.svg';

import styles from './styles.module.scss';

type Props = {
  isModalOpen: boolean;
  close: () => void;
  selectedProduct: ProductT | null;
  arePointsShown: boolean;
  isPriceShown: boolean;
  bullet?: {
    icon: IconT;
    color: ColorVariants;
  };
  brandKit: BrandKitThemeConfiguration | null;
  pointsIcon?: IconT;
  frameColor?: ColorVariants;
};
export const EndUserProductModal: FC<Props> = ({ isModalOpen, close, selectedProduct, ...rest }) => {
  return selectedProduct ? (
    <div>
      <Modal
        open={isModalOpen}
        onCancel={close}
        destroyOnClose={true}
        closeIcon={false}
        bodyStyle={{ margin: '-34px -24px -32px' }}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <IconButton onClick={close} className={styles.closeButton} icon={<CrossIcon />} />
        <div style={{ backgroundColor: '#fff', containerType: 'inline-size' }}>
          <EndUserProductCard
            isIrrelevant={selectedProduct.irrelevantSurveyReason}
            openedFromModal={true}
            product={selectedProduct}
            {...rest}
          />
        </div>
      </Modal>
    </div>
  ) : null;
};
