import { useTranslation } from 'react-i18next';
import { useDemandsDataColumn } from './useDemandsDataColumn';
import { DEMAND_AREAS_COLUMN } from '../../../../lib/types/demandArea';

const demandArea = 'pages.products.demandArea.tableItems.demandArea';

export const useDemandAreaColumn = () => {
  const { t } = useTranslation();

  return useDemandsDataColumn({
    title: t(demandArea),
    arrangedId: DEMAND_AREAS_COLUMN.DEMAND_AREAS,
    columnProps: {
      width: 255,
    },
  });
};
