import { useTranslation } from 'react-i18next';
import { TableHeaderCell } from '../../../components/Table/TableHeaderCell/TableHeaderCell';
import { STATUS_LEVEL_COLUMN } from '../helpers/consts';
import { Typography } from 'antd';
import tableStyles from '../../../components/Table/index.module.scss';
import styles from './../styles.module.scss';

const { Text } = Typography;

import cn from 'classnames';

const points = 'pages.statusLevel.columns.points';

export const useStatusLevelPointsColumn = () => {
  const { t } = useTranslation();

  return {
    title: <TableHeaderCell title={t(points)} withArrange={false} />,
    dataIndex: STATUS_LEVEL_COLUMN.SUBLEVELS,
    width: 100,
    className: tableStyles.allowWrap,
    render: (data: { id: number; points: number }[]) => {
      return (
        <div className={styles.subLevelsContainer}>
          {data.map((item) => (
            <div className={cn(styles.subLevelItem, styles.points)} key={item.id}>
              <Text>{item.points}</Text>
            </div>
          ))}
        </div>
      );
    },
  };
};
