import { FC } from 'react';
import { InputProps } from 'antd';
import cx from 'classnames';

import styles from './index.module.scss';

type Props = {
  text: string;
  size?: InputProps['size'];
  className?: string;
};

export const InputLabel: FC<Props> = ({ text, size, className }) => {
  return (
    <label
      className={cx(styles.root, className, {
        [styles.rootSmall]: size === 'small',
      })}
    >
      {text}
    </label>
  );
};
