import { EndUserLayout } from '../MainLayouts';
import { EndUserAntdConfigProvider } from '../AppProvider/EndUserAntdConfigProvider';
import { EndUserRoutes } from '../EndUserRoutes';
import { EndUserAuthRoutes } from '../EndUserAuthRoutes';
import { FC, useEffect } from 'react';

type Props = {
  isLoggedEndUser: boolean;
  isGuestMode: boolean;
};

export const EndUserRootRoutes: FC<Props> = ({ isLoggedEndUser, isGuestMode }) => {
  useEffect(() => {
    // TODO change de lang to another one when i18n is implemented
    document.documentElement.setAttribute('lang', 'de');
  }, []);

  return (
    <>
      <EndUserLayout>
        <EndUserAntdConfigProvider>
          {isLoggedEndUser || isGuestMode ? <EndUserRoutes /> : <EndUserAuthRoutes />}
        </EndUserAntdConfigProvider>
      </EndUserLayout>
    </>
  );
};
