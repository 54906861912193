import { FC } from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { FormItemName } from '../../../../lib/models/Form';
import { FormItem } from '../../../../components/FormItem';
import { FormInput } from '../../../../components/FormInput';

const passwordInputErrorProps = {
  validateStatus: 'error',
};

type Props = {
  backendError: boolean;
};
export const PasswordFormItem: FC<Props> = ({ backendError }) => {
  const { t } = useTranslation();

  return (
    <FormItem
      {...(backendError ? passwordInputErrorProps : {})}
      name={FormItemName.PASSWORD}
      rules={[
        {
          required: true,
        },
        {
          whitespace: true,
        },
        {
          pattern: /^.{1,200}$/,
          message: t('pages.logIn.errors.length'),
        },
      ]}
    >
      <FormInput as={Input.Password} isActive={true} label={t('formItems.password.label')} id="password" />
    </FormItem>
  );
};
