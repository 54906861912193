import { api, TAGS_KEY } from './';
import { GLOBAL_SETTINGS_NAMES } from '../../utils/constants';

type GetDesignSettingsPayload = {
  projectId: number | string;
  tenantId: number | string;
};

type DesignSettingsPayload = GetDesignSettingsPayload & {
  settings: string;
};

type DesignSettingsResponse = { name: GLOBAL_SETTINGS_NAMES; projectId: number; settings: string }[];

const designSettingsApi = api.injectEndpoints({
  endpoints: (build) => ({
    setDesignSettings: build.mutation<void, DesignSettingsPayload>({
      query: ({ projectId, settings, tenantId }) => ({
        url: 'global-settings',
        method: 'POST',
        body: {
          projectId,
          name: GLOBAL_SETTINGS_NAMES.DESIGN_SETTINGS,
          settings,
        },
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      invalidatesTags: [TAGS_KEY.DESIGN_SETTINGS],
    }),

    getDesignSettings: build.query<DesignSettingsResponse, GetDesignSettingsPayload>({
      query: ({ projectId, tenantId }) => ({
        url: `global-settings/${projectId}?settingsName=${GLOBAL_SETTINGS_NAMES.DESIGN_SETTINGS}`,
        method: 'GET',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),

      providesTags: [TAGS_KEY.DESIGN_SETTINGS],
    }),
  }),
});

export const { useSetDesignSettingsMutation, useGetDesignSettingsQuery } = designSettingsApi;
