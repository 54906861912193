type CategoryConfigurations = {
  [key: number]: {
    gridCount: number;
    itemWidth: number;
  };
};
export const useGetCategoriesGridContainer = ({ countOfCategories }: { countOfCategories: number }) => {
  const categoryConfigurations: CategoryConfigurations = {
    4: { gridCount: 4, itemWidth: 306 },
    5: { gridCount: 5, itemWidth: 242 },
    6: { gridCount: 6, itemWidth: 200 },
    7: { gridCount: 4, itemWidth: 306 },
    8: { gridCount: 4, itemWidth: 306 },
    9: { gridCount: 5, itemWidth: 242 },
    10: { gridCount: 5, itemWidth: 242 },
    11: { gridCount: 6, itemWidth: 200 },
    12: { gridCount: 6, itemWidth: 200 },
    13: { gridCount: 5, itemWidth: 242 },
    14: { gridCount: 5, itemWidth: 242 },
    15: { gridCount: 5, itemWidth: 242 },
    16: { gridCount: 6, itemWidth: 200 },
    17: { gridCount: 6, itemWidth: 200 },
    18: { gridCount: 6, itemWidth: 200 },
    19: { gridCount: 5, itemWidth: 242 },
    20: { gridCount: 5, itemWidth: 242 },
  };

  const { gridCount, itemWidth } = categoryConfigurations[countOfCategories] || {
    gridCount: 6,
    itemWidth: 200,
  };

  return {
    gridTemplateColumns: `repeat(${gridCount}, 1fr)`,
    itemWidth,
  };
};
