import { useTranslation } from 'react-i18next';
import { TableHeaderCell } from '../../../../components/Table/TableHeaderCell/TableHeaderCell';
import tableStyles from '../../../../components/Table/index.module.scss';
import { BONUS_CATALOG_COLUMN } from '../../../../lib/types/bonusCatalog';
import { UploadResponseT } from '../../../../lib/types/entities';

const picture = 'pages.bonusCatalogTab.columns.picture';

export const useBonusCatalogPictureColumn = () => {
  const { t } = useTranslation();

  return {
    dataIndex: BONUS_CATALOG_COLUMN.PICTURE,
    title: <TableHeaderCell withArrange={false} title={t(picture)} />,
    width: 180,
    render: (picture: UploadResponseT) => {
      const url = picture?.s3Url;
      return url ? <img className={tableStyles.picture} alt="bonus-pic" src={url} /> : '-';
    },
  };
};
