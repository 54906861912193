import { FC } from 'react';
import { Table as AntTable, TableProps } from 'antd';
import { isLength, isOdd } from '../../lib/utils/helpers';
import { PAGINATION_ELEMENTS_AMOUNT } from '../../lib/utils/constants';
import { TablePagination } from '../../lib/types/pagination';

import styles from './index.module.scss';

type Props = { pagination?: TablePagination } & TableProps<any>;

export const Table: FC<Props> = ({ pagination, dataSource, ...restProps }) => {
  return (
    <AntTable
      rowKey="id"
      dataSource={dataSource}
      className={isLength(dataSource as any) ? styles.wrapper : styles.wrapper_extended}
      scroll={{ x: 'max-content' }}
      rowClassName={(record, index) => (isOdd(index) ? 'row-dark' : 'row-light')}
      pagination={{
        defaultPageSize: PAGINATION_ELEMENTS_AMOUNT,
        showSizeChanger: false,
        ...pagination,
      }}
      {...restProps}
    />
  );
};
