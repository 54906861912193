import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetDesignSettingsQuery } from '../../../lib/store/api/designSettings';
import { DesignSettingsDataT } from '../../designSettings/types';
import { IconOption } from '../components/IconOption';
import { parseJSON } from '../../../lib/utils/helpers';

export const useGetIconOptions = (iconClassName?: string, iconWrapperClassName?: string) => {
  const { id: tenantId, modelId } = useParams();

  const { data } = useGetDesignSettingsQuery(
    { tenantId: tenantId as string, projectId: String(modelId) },
    { skip: !tenantId, refetchOnMountOrArgChange: true },
  );

  const iconsData = useMemo(() => {
    if (data && data.length > 0) {
      const designSettings = data?.[0];
      const parsedData: DesignSettingsDataT = parseJSON(designSettings?.settings);

      const options = parsedData?.icons?.map((item) => ({
        value: item.id,
        title: (
          <IconOption
            iconClassName={iconClassName}
            iconWrapperClassName={iconWrapperClassName}
            imageUrl={item.s3Url}
            label={item.originalFileName}
          />
        ),
      }));

      return {
        options,
        iconsList: parsedData?.icons,
      };
    }
  }, [data]);

  return { options: iconsData?.options, iconsList: iconsData?.iconsList };
};
