import { Navigate, Route, Routes } from 'react-router-dom';
import { ROUTER_PATHS } from '../../../lib/utils/router-paths';
import { EndUserLogin } from '../../../pages/EndUserLogin';
import { EndUserAuthLayout } from '../../layouts/EndUserFlowLayout/EndUserAuthLayout';
import { EndUserResetPasswordPage } from '../../../pages/EndUserResetPasswordPage';
import { EndUserNewPasswordPage } from '../../../pages/EndUserNewPasswordPage';
import { EndUserNotFoundPage } from '../../../pages/EndUserNotFoundPage';
import { useAppSelector } from '../../../lib/store/hooks';
import { EndUserTermsAcceptancePage } from '../../../pages/EndUserTermsAcceptancePage';
import { EndUserSignUpPage } from '../../../pages/EndUserSignUpPage';

export const EndUserAuthRoutes = () => {
  const { model, demandArea } = useAppSelector(({ endUser }) => endUser);

  const modelShouldBeDeactivated = !model?.activated || !demandArea?.tenant?.activated;

  return (
    <Routes>
      <Route element={<EndUserAuthLayout />}>
        <Route path={ROUTER_PATHS.noMatch} element={<Navigate to={ROUTER_PATHS.logIn} />} />
        <Route path={ROUTER_PATHS.logIn} element={<EndUserLogin />} />
        <Route path={ROUTER_PATHS.passwordReset} element={<EndUserResetPasswordPage />} />
        <Route path={ROUTER_PATHS.setUpPassword} element={<EndUserNewPasswordPage />} />
        <Route path={ROUTER_PATHS.terms} element={<EndUserTermsAcceptancePage />} />
        <Route path={ROUTER_PATHS.signUp} element={<EndUserSignUpPage />} />
        <Route
          path={ROUTER_PATHS.notFound}
          element={modelShouldBeDeactivated ? <EndUserNotFoundPage /> : <Navigate to={ROUTER_PATHS.logIn} />}
        />
      </Route>
    </Routes>
  );
};
