import { useTranslation } from 'react-i18next';
import { useProjectSearchDataColumn } from './useProjectSearchDataColumn';
import { PROJECT_COLUMN } from '../helpers/constants';
import tableStyles from '../../../components/Table/index.module.scss';

const creator = 'pages.projects.columns.creator';

export const useCreatorColumn = () => {
  const { t } = useTranslation();

  return useProjectSearchDataColumn({
    title: t(creator),
    arrangedId: PROJECT_COLUMN.CREATED_BY,
    searchFieldName: 'searchByCreatedBy',
    filterFieldName: 'includeCreatedBy',

    headerCellProps: {
      rootProps: {
        className: tableStyles.hiddenHeaderCell,
      },
    },
  });
};
