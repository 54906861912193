import { CSSProperties, FC } from 'react';
import { EndUserButton } from '../EndUserButton';
import { useAppDispatch } from '../../lib/store/hooks';
import { setCurrentFinder } from '../../lib/store/slices/endUserSlice';

type Props = {
  showFinderButton?: boolean;
  finderButtonText?: string;
  finderButtonUrl?: string;
  recommendForAllCategories?: boolean;
  categoryId?: number;
  style?: CSSProperties;
  buttonType?: 'primary' | 'secondary';
};

export const FinderButton: FC<Props> = ({
  showFinderButton,
  finderButtonText,
  finderButtonUrl,
  recommendForAllCategories,
  categoryId,
  buttonType = 'primary',
  style,
}) => {
  const dispatch = useAppDispatch();

  const onClickFinderButton = () => {
    dispatch(
      setCurrentFinder({
        finderUrl: finderButtonUrl,
        recommendForAllCategories,
        categoryId,
        isOpen: true,
      }),
    );
  };

  return showFinderButton && finderButtonText && finderButtonUrl ? (
    <div style={style}>
      <EndUserButton onClick={onClickFinderButton} type={buttonType}>
        {finderButtonText}
      </EndUserButton>
    </div>
  ) : null;
};
