import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../lib/store/hooks';
import { Form, Typography } from 'antd';
import { PRODUCT_CATEGORIES } from '../helpers/constants';
import {
  createOptionItem,
  getArrangesInitialValues,
  getParsedSelectedItems,
  getSelectedItemsLabels,
  getSelectedItemsValues,
} from '../../../lib/utils/arranges-helpers';
import { useState } from 'react';
import { SELECTION_TYPE } from '../../projects/types';
import { useEnumArrange } from '../../tenants/hooks/useEnumArrange';
import { useProductCategoriesArrangeRequest } from './useProductCategoriesArrangeRequest';
import { capitalizeFirstLetter } from '../helpers';
import { TableHeaderCell } from '../../../components/Table/TableHeaderCell/TableHeaderCell';
import { ArrangeListPopup } from '../../../features/table/components/popups/ArrangeListPopup';
import { useArrangeSubmit } from '../../../features/table/helpers/useArrangeSubmit';

import tableStyles from '../../../components/Table/index.module.scss';
import classNames from 'classnames';

export const useProductCategoriesSelectionTypeColumn = () => {
  const { t } = useTranslation();

  const filteringT = 'pages.products.productCategories.filtering';
  const selectionType = 'pages.products.productCategories.columns.selectionType';

  const { sorting, arrangeId } = useAppSelector(({ productCategories }) => productCategories);

  const [form] = Form.useForm();
  const isActiveArrange = arrangeId === PRODUCT_CATEGORIES.SELECTION_TYPE;

  const getItemsLabels = () => getSelectedItemsLabels(form);
  const getItemsValues = () => getSelectedItemsValues(form);

  const [filterLabel, setFilterLabel] = useState('');

  const singleLabel = t(`${filteringT}.single`);
  const multipleLabel = t(`${filteringT}.multiple`);

  const allOptions = [
    createOptionItem(singleLabel, SELECTION_TYPE.SINGLE),
    createOptionItem(multipleLabel, SELECTION_TYPE.MULTIPLE),
  ];

  const { filtering } = useEnumArrange(allOptions);

  const { applyArranges, removeFilter, removeFilterValue } = useProductCategoriesArrangeRequest({
    arrangedId: PRODUCT_CATEGORIES.SELECTION_TYPE,
    getFilterLabel: () => getItemsLabels().join(', '),
    getFilters: () => ({ statuses: getItemsValues() }),
    form,
    setFilterLabel,
    filterFieldName: 'includeSelectionTypes',
    isParsedByValue: true,
  });

  const { onFinish } = useArrangeSubmit(applyArranges);

  return {
    dataIndex: PRODUCT_CATEGORIES.SELECTION_TYPE,
    className: classNames(tableStyles.allowWrap, tableStyles.selectionType),
    title: (
      <TableHeaderCell
        titleAndButtonRootClassName={tableStyles.titleAndButtonWrap}
        arrangedId={PRODUCT_CATEGORIES.SELECTION_TYPE}
        title={t(selectionType)}
        removeFilter={removeFilter}
        filterLabel={filterLabel}
        sortOrder={sorting.sortOrder}
        isActiveArrange={isActiveArrange}
        form={form}
        getParsedItems={() => getParsedSelectedItems(form)}
        removeFilterValue={removeFilterValue}
      >
        <ArrangeListPopup
          initialValues={getArrangesInitialValues(form)}
          form={form}
          filtering={filtering}
          onFinish={onFinish}
          isActiveArrange={isActiveArrange}
        />
      </TableHeaderCell>
    ),
    render: (selectionType: string) => {
      return <Typography.Text>{capitalizeFirstLetter(selectionType)}</Typography.Text>;
    },
    width: 200,
  };
};
