import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ChevronRight from '../../../assets/icons/chevron-right.svg';

type Props = {
  type: string;
};

const nextAlt = 'platform.datePicker.nextAlt';

export const NextIcon: FC<Props> = ({ type }) => {
  const { t } = useTranslation();

  return <img src={ChevronRight} alt={t(nextAlt, { context: type })} />;
};
