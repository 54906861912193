import React from 'react';
import { AppCardBasic } from '../../../../components/AppCardBasic';
import styles from '../BrandKit/index.module.scss';
import { FormItem } from '../../../../components/FormItem';
import { FormInput } from '../../../../components/FormInput';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const tabs = 'pages.designSettings.tabs';

export const BrandKitTitle = () => {
  const { t } = useTranslation();

  return (
    <AppCardBasic>
      <Text className={styles.title}>{t(`${tabs}.brandKit`)}</Text>
      <FormItem style={{ marginTop: '16px', marginBottom: '-22px' }} hasFeedback={true}>
        <FormInput disabled={true} value={'Custom'} />
      </FormItem>
    </AppCardBasic>
  );
};
