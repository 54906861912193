import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, message, MessageArgsProps, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../../lib/store/hooks';
import { ModalActions } from '../../../../../lib/models/Modal';
import { useResetFormOnCloseModal } from '../../../../../lib/hooks/useResetFormOnCloseModal';
import { AppModal } from '../../../../../components/AppModal';
import { setModalClosed } from '../../../../../lib/store/slices/projectsSlice';
import { useDeleteProjectMutation } from '../../../../../lib/store/api/project';

const MESSAGE_SHOWING_TIME = 3;
const { Paragraph } = Typography;

const title = 'pages.projects.deleteModal.title';
const questionText = 'pages.projects.deleteModal.questionText';
const successText = 'pages.projects.deleteModal.successText';
const errorText = 'pages.projects.deleteModal.errorText';

type Props = {
  tenantId: string;
};

export const DeleteProjectModal = ({ tenantId }: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useAppDispatch();
  const { selectedProject, modalType, isModalOpen } = useAppSelector(({ projects }) => projects);
  const [deleteProject, { isLoading }] = useDeleteProjectMutation();

  const isOpen = isModalOpen && modalType === ModalActions.DELETE;
  const onClose = () => dispatch(setModalClosed());

  useResetFormOnCloseModal({ form, isOpen });

  const onConfirm = () => {
    form.submit();
  };

  const onFinish = async () => {
    const messageConfig = {} as MessageArgsProps;

    try {
      await deleteProject({ tenantId, projectId: String(selectedProject?.id) }).unwrap();
      messageConfig.content = t(successText);
      messageConfig.type = 'success';
      onClose();
    } catch (err: any) {
      messageConfig.content = err?.data?.message || t(errorText);
      messageConfig.type = 'error';
    } finally {
      messageApi.open({
        duration: MESSAGE_SHOWING_TIME,
        ...messageConfig,
      } as MessageArgsProps);
    }
  };

  return (
    <AppModal
      open={isOpen}
      onCancel={onClose}
      onOk={onConfirm}
      okText={t('generic.delete')}
      titleText={t(title)}
      isOkLoading={isLoading}
    >
      {contextHolder}
      {isOpen && (
        <Form form={form} onFinish={onFinish}>
          <Paragraph style={{ marginBottom: '36px' }}>
            {t(questionText)} <span className="bold">{selectedProject?.name}</span> ?
          </Paragraph>
        </Form>
      )}
    </AppModal>
  );
};
