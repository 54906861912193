import { useTranslation } from 'react-i18next';
import { useProjectDateColumn } from './useProjectDateColumn';
import { PROJECT_COLUMN } from '../helpers/constants';
import tableStyles from '../../../components/Table/index.module.scss';

const contractEnd = 'pages.projects.columns.contractEnd';

export const useContractEndColumn = () => {
  const { t } = useTranslation();

  return useProjectDateColumn({
    arrangedId: PROJECT_COLUMN.CONTRACT_END,
    title: t(contractEnd),
    filterFromName: 'contractEndFrom',
    filterToName: 'contractEndTo',
    headerCellProps: {
      rootProps: {
        className: tableStyles.hiddenHeaderCell,
      },
    },
  });
};
