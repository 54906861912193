export enum PRODUCTS_COLUMNS {
  NAME = 'name',
  ID = 'productId',
  IS_ACTIVE = 'activated',
  CATEGORY = 'category',
  PRIORITY = 'priority',
  ADDITIONAL_INFO = 'additionalInfo',
  POINTS = 'points',
  DEMAND_AREA = 'demandArea',
}

export const PRODUCT_STATUS = {
  ACTIVATED: true,
  DEACTIVATED: false,
};
