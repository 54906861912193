import { ButtonLink } from '../ButtonLink';
import { ReactComponent as QuestionIcon } from './../../assets/icons/question-icon.svg';
import { EndUserTypography } from '../EndUserTypography';
import { useAppSelector } from '../../lib/store/hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const buttons = 'endUser.shared';

type Props = {
  isContacts?: boolean;
  isLegalNotice?: boolean;
};

export const EndUserAuthBottomButtons: FC<Props> = ({ isContacts = true, isLegalNotice = true }) => {
  const { demandArea } = useAppSelector(({ endUser }) => endUser);
  const { t } = useTranslation();

  const onClickLegalNotice = () => {
    window.open(demandArea?.tenant.impressum, '_blank');
  };

  const onClickHelpAndContact = () => {
    window.open(demandArea?.tenant.hilfeUndKontakt, '_blank');
  };

  return (
    <div className={styles.container}>
      {isLegalNotice ? (
        <ButtonLink onClick={onClickLegalNotice} className={styles.button}>
          <EndUserTypography className={styles.text} type="subText">
            {t(`${buttons}.imprint`)}
          </EndUserTypography>
        </ButtonLink>
      ) : (
        <div />
      )}
      {isContacts ? (
        <ButtonLink onClick={onClickHelpAndContact} className={styles.button}>
          <QuestionIcon className={styles.icon} />
          <EndUserTypography className={styles.text} type="subText">
            {t(`${buttons}.help`)}
          </EndUserTypography>
        </ButtonLink>
      ) : (
        <div />
      )}
    </div>
  );
};
