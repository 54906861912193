import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormItemName } from '../../../../lib/models/Form';
import { FormItem } from '../../../../components/FormItem';
import { FormInput } from '../../../../components/FormInput';
import { PHONE_NUMBER_PATTERN } from '../../../../lib/utils/regexp';

const clientPhone = 'unique.formItems.clientPhone';

export const ClientPhoneFormItem: FC = () => {
  const { t } = useTranslation();

  return (
    <FormItem
      name={FormItemName.CLIENT_PHONE}
      hasFeedback={true}
      rules={[
        {
          required: false,
        },
        {
          pattern: PHONE_NUMBER_PATTERN,
          message: t(`${clientPhone}.errors.invalid`),
        },
        { max: 100 },
      ]}
    >
      <FormInput label={t(`${clientPhone}.label`)} />
    </FormItem>
  );
};
