import { CSSProperties, FC, ReactNode } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

type Props = {
  icon: ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  style?: CSSProperties;
  children?: any;
};

export const IconButton: FC<Props> = ({ icon, style, className, onClick, disabled, isLoading }) => {
  const handleClickButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isLoading) {
      return;
    }
    onClick?.(e);
  };

  return (
    <button
      type="button"
      className={`icon_btn ${className}`}
      style={style}
      onClick={handleClickButton}
      disabled={disabled}
    >
      {isLoading ? <Spin indicator={<LoadingOutlined spin />} /> : icon}
    </button>
  );
};
