import { Form, FormInstance } from 'antd';
import { FC } from 'react';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { ReactComponent as DraggableIcon } from './../../../../assets/icons/draggable-icon.svg';
import { useBulletPoints } from '../../hooks/useBulletPoints';
import { FormInput } from '../../../../components/FormInput';
import { FormCardButton } from '../../../designSettings/components/FormCardButton';
import { FormItemName } from '../../../../lib/models/Form';
import { FormItem } from '../../../../components/FormItem';
import { useTranslation } from 'react-i18next';
import { MAX_BULLET_AMOUNT } from '../../helpers/constants';

import styles from './index.module.scss';

const ITEM_HEIGHT = 68;

type Props = {
  form: FormInstance;
};

const bullets = 'pages.products.productConfigurator.formItems.bullets';

export const BulletPointFormList: FC<Props> = ({ form }: Props) => {
  const { onAddNewBullet, itemsSize, onDragEnd } = useBulletPoints({ form });
  const { t } = useTranslation();
  const bulletLength = form.getFieldValue(FormItemName.BULLET_POINTS)?.length;

  return (
    <DragDropContext onDragEnd={onDragEnd as OnDragEndResponder}>
      <div style={{ minHeight: `${ITEM_HEIGHT * itemsSize}px` }}>
        <Droppable droppableId="bullet-point-list">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <Form.List name={FormItemName.BULLET_POINTS} initialValue={['']}>
                {(fields) => {
                  return (
                    <>
                      {fields.map((field, index) => {
                        return (
                          <Draggable key={field.key} index={index} draggableId={String(field.key)}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                className={styles.draggableFormItem}
                              >
                                <div {...provided.dragHandleProps} className={styles.dragHandler}>
                                  <DraggableIcon />
                                </div>
                                <FormItem className={styles.formItem} hasFeedback name={field.name}>
                                  <FormInput placeholder={t(`${bullets}.label`)} />
                                </FormItem>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    </>
                  );
                }}
              </Form.List>
            </div>
          )}
        </Droppable>
      </div>

      <FormCardButton
        disabled={bulletLength === MAX_BULLET_AMOUNT}
        onClick={onAddNewBullet}
        className={styles.addBulletBtn}
      >
        {t(`${bullets}.button`)}
      </FormCardButton>
    </DragDropContext>
  );
};
