import { Checkbox } from 'antd';
import { FC } from 'react';
import { CheckboxGroupProps } from 'antd/es/checkbox';

import styles from './index.module.scss';

type Props = {
  rootProps: {
    className?: string;
  };
  groupProps: CheckboxGroupProps;
};

export const CheckboxGroup: FC<Props> = ({ rootProps, groupProps }) => {
  return (
    <div className={styles.checkboxGroupRoot} {...rootProps}>
      <Checkbox.Group {...groupProps} />
    </div>
  );
};
