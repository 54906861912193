import { Form } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../helpers/constants';
import { Role } from '../../../../lib/utils/roles';
import {
  createOptionItem,
  getArrangesInitialValues,
  getParsedSelectedItems,
  getSelectedItemsLabels,
  getSelectedItemsValues,
} from '../../../../lib/utils/arranges-helpers';
import { useEnumArrange } from '../../../tenants/hooks/useEnumArrange';
import { TableHeaderCell } from '../../../../components/Table/TableHeaderCell/TableHeaderCell';
import { ArrangeListPopup } from '../../../../features/table/components/popups/ArrangeListPopup';
import { useUserArrangeRequests } from '../../hooks/table/useUserArrangeRequests';
import { useAppSelector } from '../../../../lib/store/hooks';

const title = 'shared.columns.rights';

export const RightsHeaderCell: FC = () => {
  const { t } = useTranslation();

  const { sorting, arrangeId } = useAppSelector(({ userManagement }) => userManagement);
  const [form] = Form.useForm();
  const isActiveArrange = arrangeId === User.ROLES;

  const getItemsLabels = () => getSelectedItemsLabels(form);
  const getItemsValues = () => getSelectedItemsValues(form);
  const [filterLabel, setFilterLabel] = useState('');

  const editorLabel = t('shared.roles.editor');
  const adminLabel = t('shared.roles.admin');
  const superAdminLabel = t('shared.roles.superAdmin');

  const allOptions = [
    createOptionItem(editorLabel, Role.EDITOR),
    createOptionItem(adminLabel, Role.ADMIN),
    createOptionItem(superAdminLabel, Role.SUPER_ADMIN),
  ];

  const { filtering } = useEnumArrange(allOptions);

  const { applyArranges, removeFilter, removeFilterValue } = useUserArrangeRequests({
    arrangedId: User.ROLES,
    getFilterLabel: () => getItemsLabels().join(', '),
    getFilters: () => ({ authorities: getItemsValues() }),
    form,
    setFilterLabel,
    filterFieldName: 'authorities',
    isParsedByValue: true,
  });

  const headerCellProps = {
    title: t(title),
    arrangedId: User.ROLES,
    removeFilter,
    filterLabel,
    sortOrder: sorting.sortOrder,
    isActiveArrange,
    form,
    applyArranges,
    getParsedItems: () => getParsedSelectedItems(form),
    removeFilterValue,
  };

  return (
    <TableHeaderCell {...headerCellProps}>
      <ArrangeListPopup
        initialValues={getArrangesInitialValues(form)}
        form={form}
        filtering={filtering}
        onFinish={applyArranges}
        isActiveArrange={isActiveArrange}
      />
    </TableHeaderCell>
  );
};
