import React, { FC } from 'react';
import { Form, Typography } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../lib/store/hooks';
import { useParams, useSearchParams } from 'react-router-dom';
import { useResetFormOnCloseModal } from '../../../../lib/hooks/useResetFormOnCloseModal';
import { setCurrentStatusLevel } from '../../../../lib/store/slices/statusLevelSlice';
import { QueryParams, Tabs } from '../../../../lib/types/queryParams';
import { AppModal } from '../../../../components/AppModal';
import { StatusLevelT } from '../../../../lib/types/statusLevel';
import { useStatusLevelConfigurator } from '../../hooks/useStatusLevelConfigurator';

const { Paragraph } = Typography;

const deleteModal = 'pages.statusLevel.modals.deleteModal';
const messages = 'pages.statusLevel.form.messages';

type Props = {
  isOpen: boolean;
  close: () => void;
  selectedStatusLevel: StatusLevelT | null;
  messageApi: MessageInstance;
};

export const DeleteStatusLevelModal: FC<Props> = ({ isOpen, close, selectedStatusLevel, messageApi }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const { id: tenantId } = useParams();
  const [, setSearchParams] = useSearchParams();

  const { deleteStatusLevel, isLoading } = useStatusLevelConfigurator();

  useResetFormOnCloseModal({ form, isOpen });

  const onConfirm = () => {
    form.submit();
  };

  const onFinish = async () => {
    try {
      await deleteStatusLevel({
        tenantId: String(tenantId),
        statusLevelId: Number(selectedStatusLevel?.id),
      }).unwrap();
      messageApi.success(t(`${messages}.successDeleting`));
      close();
      dispatch(setCurrentStatusLevel(null));
      setSearchParams({ [QueryParams.TAB]: Tabs.STATUS_LEVEL });
      //eslint-disable-next-line
    } catch (err: any) {
      messageApi.error(err?.data?.message || t(`${messages}.errorDeleting`));
    }
  };

  return (
    <AppModal
      open={isOpen}
      onCancel={close}
      onOk={onConfirm}
      okText={'generic.delete'}
      titleText={t(`${deleteModal}.title`)}
      isOkLoading={isLoading}
    >
      {isOpen && (
        <Form form={form} onFinish={onFinish}>
          <Paragraph style={{ marginBottom: '36px' }}>
            {t(`${deleteModal}.text`, { statusLevelName: selectedStatusLevel?.name })}
          </Paragraph>
        </Form>
      )}
    </AppModal>
  );
};
