import { FC } from 'react';
import { Operation, ProductT } from '../../../../lib/types/product';
import { parseJSON } from '../../../../lib/utils/helpers';
import { EndUserButton } from '../../../../components/EndUserButton';

import styles from './index.module.scss';
import cn from 'classnames';

type Props = {
  product: ProductT;
  buttonClassName?: string;
};

export const WishlistProductsListButton: FC<Props> = ({ product, buttonClassName }) => {
  const additionalInfo = parseJSON(product?.additionalInfo);

  const onClickPrimaryButton = () => {
    if (additionalInfo.primaryButton.operation === Operation.IFRAME) {
      window.open(additionalInfo.primaryButton.link, '_blank');
    }
  };
  const onClickSecondaryButton = () => {
    if (additionalInfo.secondaryButton.operation === Operation.IFRAME) {
      window.open(additionalInfo.secondaryButton.link, '_blank');
    }
    if (additionalInfo.secondaryButton.operation === Operation.FORM) {
      // onOpenModal?.(product);
    }
  };

  if (additionalInfo?.primaryButton?.isButtonEnabled) {
    return (
      <EndUserButton
        className={cn(styles.button, buttonClassName && buttonClassName)}
        onClick={onClickPrimaryButton}
        type="primary"
      >
        {additionalInfo.primaryButton.text}
      </EndUserButton>
    );
  }

  if (!additionalInfo?.primaryButton?.isButtonEnabled && additionalInfo?.secondaryButton?.isButtonEnabled) {
    return (
      <EndUserButton
        className={cn(styles.button, buttonClassName && buttonClassName)}
        onClick={onClickSecondaryButton}
        type="secondary"
      >
        {additionalInfo.secondaryButton.text}
      </EndUserButton>
    );
  }

  return null;
};
