import { Radio, Typography } from 'antd';
import { FormItem } from '../../../../../components/FormItem';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { BONUS_CATALOG_FORM_ITEMS } from '../../../helpers';
import { PayoutType } from '../../../../../lib/types/bonusCatalog';

import styles from '../../BonusCatalog/index.module.scss';

const { Text } = Typography;

const payoutOptions = 'pages.bonusCatalogTab.formItems.payoutOptions';

export const PayoutOptions: FC = () => {
  const { t } = useTranslation();

  const options = [
    {
      label: t(`${payoutOptions}.option1`),
      value: PayoutType.PAYOUT,
    },
    {
      label: t(`${payoutOptions}.option2`),
      value: PayoutType.DONATION,
    },
    {
      label: t(`${payoutOptions}.option3`),
      value: PayoutType.PAYOUT_AND_DONATION,
    },
  ];

  return (
    <div className={styles.payoutOptions}>
      <Text className={styles.title}>{t(`${payoutOptions}.title`)}</Text>

      <FormItem
        hasFeedback
        name={BONUS_CATALOG_FORM_ITEMS.PAYOUT_TYPE}
        className={styles.radioGroup}
        initialValue={PayoutType.PAYOUT}
      >
        <Radio.Group className={styles.radioGroup} options={options} />
      </FormItem>
    </div>
  );
};
