import { FC } from 'react';
import { FormItem } from '../../../../components/FormItem';
import { useTranslation } from 'react-i18next';
import { FormItemName } from '../../../../lib/models/Form';
import { AppSelect } from '../../../../components/AppSelect';
import { Form } from 'antd';

const priority = 'pages.products.productCategories.formItems.priority';

type Props = {
  priorities?: number[];
  disabled?: boolean;
};

export const PriorityFormItem: FC<Props> = ({ priorities, disabled }) => {
  const { t } = useTranslation();

  const form = Form.useFormInstance();

  const demandAreaValue = form.getFieldValue(FormItemName.DEMAND_AREA);

  const options = priorities?.map((item) => ({ value: item, title: item }));

  return (
    <FormItem
      hasFeedback
      name={FormItemName.PRIORITY}
      rules={[{ required: !disabled, message: t(`${priority}.error`) }]}
    >
      <AppSelect disabled={!demandAreaValue || disabled} options={options} label={t(`${priority}.label`)} />
    </FormItem>
  );
};
