import { FC } from 'react';
import { Space } from 'antd';
import { IconButton } from '../../../../components/IconButton';
import { EditIcon } from '../../../../assets/icons/EditIcon';
import { ActionsDropdown } from '../../../../components/Table/ActionsDropdown/ActionsDropdown';
import { RuleT } from '../../../../lib/types/ruleBuilder';
import { useRulesActionsRequest } from '../../hooks/useRulesActionsRequest';
import { useRuleTypesActionsRequest } from '../../hooks/useRuleTypesActionsRequest';

type Props = {
  row: RuleT;
  tableName: 'rules' | 'ruleTypes';
};

export const RulesActionsBodyCell: FC<Props> = ({ row, tableName }) => {
  const { onUpdateRule } = useRulesActionsRequest();
  const { onUpdateRuleType } = useRuleTypesActionsRequest();

  return (
    <Space size="middle">
      <IconButton
        icon={<EditIcon />}
        onClick={() => {
          if (tableName === 'rules') {
            onUpdateRule(row);
          } else {
            onUpdateRuleType(row);
          }
        }}
      />
      <ActionsDropdown table={tableName} row={row} />
    </Space>
  );
};
