import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AdminPanelLayout } from '../MainLayouts';
import { AuthRoutes } from '../AuthRoutes';
import { CMSRoutes } from '../CMSRoutes';
import { useLocalStorageItem } from '../../../lib/hooks/useLocalStorageItem';
import { ACCESS_TOKEN_NAME } from '../../../lib/utils/constants';
import { useAppSelector } from '../../../lib/store/hooks';
import { ROUTER_PATHS } from '../../../lib/utils/router-paths';
import { EndUserRootRoutes } from '../EndUserRootRoutes';
import { useUpdateHeadTag } from '../../../lib/hooks/useUpdateHeadTag';
import { useGetPublicEndUserData } from '../../../lib/hooks/endUserHooks/useGetPublicEndUserData';
import { PageLoader } from '../../../components/PageLoader';

export const AppRouter: FC = () => {
  const [accessToken] = useLocalStorageItem(ACCESS_TOKEN_NAME);
  const { isDomainsLoading, isSubdomain } = useUpdateHeadTag();
  const { isPublicInfoLoading } = useGetPublicEndUserData(isSubdomain);

  const { model, demandArea, isGuestMode } = useAppSelector(({ endUser }) => endUser);

  const navigate = useNavigate();
  const isLoggedEndUser = accessToken && model?.activated;

  const isModelDisabled =
    model && isSubdomain && (!model?.activated || (demandArea && !demandArea?.tenant.activated));

  useEffect(() => {
    if (isSubdomain && isModelDisabled && !isPublicInfoLoading && !isDomainsLoading) {
      navigate(ROUTER_PATHS.notFound);
    }
  }, [isModelDisabled, navigate, isSubdomain, isPublicInfoLoading, isDomainsLoading]);

  if (isPublicInfoLoading || isDomainsLoading) {
    return (
      <div style={{ flex: 1 }}>
        <PageLoader />
      </div>
    );
  }

  if (isSubdomain) {
    return <EndUserRootRoutes isLoggedEndUser={isLoggedEndUser} isGuestMode={isGuestMode} />;
  }

  return <AdminPanelLayout>{accessToken ? <CMSRoutes /> : <AuthRoutes />}</AdminPanelLayout>;
};
