import { FC, useState } from 'react';
import { FileUploader } from '../../../../../components/FileUploader';
import { FormItem } from '../../../../../components/FormItem';
import { UploadImageLabel } from '../../../../../components/UploadImageLabel';
import { FormItemName } from '../../../../../lib/models/Form';
import { UploadResponseT } from '../../../../../lib/types/entities';
import { MIMEType, TargetType } from '../../../../../lib/types/media';
import { MAX_FILE_SIZE_TENANT_LOGO } from '../../../../tenants/helpers/constants';
import { useTranslation } from 'react-i18next';
import styles from '../../BonusCatalog/index.module.scss';

type Props = {
  picture?: UploadResponseT | null;
  onDeleteClick: () => void;
  afterUploadAction: (file: UploadResponseT) => void;
};

const beforeUploadFileError = `shared.design.beforeUploadFileError`;
const image = `pages.bonusCatalogTab.formItems.image`;

export const UploadPicture: FC<Props> = ({ picture, afterUploadAction, onDeleteClick }) => {
  const { t } = useTranslation();
  const [uploadingLogo, setUploadingLogo] = useState(false);
  return (
    <FormItem nostyle="true" name={FormItemName.PICTURE}>
      <FileUploader
        targetType={TargetType.PICTURES}
        excludedTypes={[MIMEType.PICTURE]}
        afterUploadAction={afterUploadAction}
        errorText={t(beforeUploadFileError)}
        maxFileSize={MAX_FILE_SIZE_TENANT_LOGO}
        disabled={uploadingLogo}
        setUploading={setUploadingLogo}
        uploadLabel={({ onDeleteMedia }) => (
          <UploadImageLabel
            className={styles.uploadLabel}
            mediaObject={uploadingLogo ? undefined : picture || undefined}
            uploading={uploadingLogo}
            deleteOnClick={onDeleteClick}
            imgHeight={52}
            onDeleteMedia={onDeleteMedia}
            text={t(image)}
          />
        )}
      />
    </FormItem>
  );
};
