import { FC } from 'react';
import { TenantStatus } from '../../helpers/constants';
import { useTranslation } from 'react-i18next';
import { Status, StatusType } from '../../../../features/table/components/Status';

const statusText = 'shared.status';

type Props = {
  status?: boolean;
};

export const TenantStatusBodyCell: FC<Props> = ({ status }) => {
  const { t } = useTranslation();

  switch (status) {
    case TenantStatus.ACTIVATED:
      return <Status textValue={t(`${statusText}.activated`)} type={StatusType.GREEN} />;

    case TenantStatus.DEACTIVATED:
      return <Status textValue={t(`${statusText}.deactivated`)} type={StatusType.GRAY} />;

    default:
      // throw new Error('Invalid status');
      return <span></span>;
  }
};
