import { AddUserButton } from '../../modules/userManagement/components/AddUserButton';
import { CreateUserModal } from '../../modules/userManagement/components/CreateUserModal';
import { EditUserModal } from '../../modules/userManagement/components/EditUserModal';
import { DeleteUserModal } from '../../modules/userManagement/components/DeleteUserModal';
import { UsersTable } from '../../modules/userManagement/components/UsersTable';

export const UsersManagementPage = () => {
  return (
    <>
      <CreateUserModal />
      <EditUserModal />
      <DeleteUserModal />
      <AddUserButton />
      <UsersTable />
    </>
  );
};
