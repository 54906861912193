import { api, TAGS_KEY } from './index';
import { ResultWithPagination } from '../../types/pagination';
import { BadgeT } from '../../types/badge';

export const badgeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBadges: build.query<ResultWithPagination<BadgeT[]>, { modelId: string; tenantId: string }>({
      query: ({ modelId, tenantId }) => ({
        url: `product/badge/${modelId}`,
        method: 'GET',
        headers: {
          'X-TENANT': tenantId,
        },
      }),
      providesTags: [TAGS_KEY.BADGES],
    }),
    updateBadges: build.mutation<void, { tenantId: string } & { badges: BadgeT[] }>({
      query: ({ tenantId, badges }) => ({
        url: 'product/badge',
        method: 'POST',
        headers: {
          'X-TENANT': tenantId,
        },
        body: badges,
      }),
      invalidatesTags: [TAGS_KEY.BADGES],
    }),
    deleteBadge: build.mutation<void, { tenantId: string; badgeId: number }>({
      query: ({ tenantId, badgeId }) => ({
        url: `product/badge/${badgeId}/delete`,
        method: 'DELETE',
        headers: {
          'X-TENANT': tenantId,
        },
      }),
      invalidatesTags: [TAGS_KEY.BADGES],
    }),
  }),
});

export const { useGetBadgesQuery, useUpdateBadgesMutation, useDeleteBadgeMutation } = badgeApi;
