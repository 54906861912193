import { api } from './';
import { DeleteMediaProps, UploadMediaPayloadT, UploadMediaResponseT } from '../../types/media';
import { createFormData } from '../../utils/helpers';

export const mediaApi = api.injectEndpoints({
  endpoints: (build) => ({
    uploadMedia: build.mutation<UploadMediaResponseT, UploadMediaPayloadT>({
      query: (data: UploadMediaPayloadT) => {
        const formData = createFormData(data);

        return {
          url: '/media',
          method: 'POST',
          body: formData,
          formData: true,
          headers: {
            'X-TENANT': data.tenantId,
          },
        };
      },
    }),
    deleteMedia: build.mutation<void, DeleteMediaProps>({
      query: ({ id, tenantId }) => ({
        url: `/media/${id}`,
        method: 'DELETE',
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
    }),
  }),
});

export const { useUploadMediaMutation, useDeleteMediaMutation } = mediaApi;
