import cx from 'classnames';
import styles from './index.module.scss';
import { CSSProperties, FC } from 'react';
import { IconT } from '../../modules/designSettings/types';

type Props = {
  iconData?: IconT;
  color?: string;
  className?: string;
  style?: CSSProperties;
};

export const IconComponent: FC<Props> = ({ iconData, color, className, style }) => {
  return (
    <span
      className={cx(styles.iconComponent, className)}
      style={{
        maskImage: `url(${iconData?.s3Url})`,
        maskPosition: 'center',
        maskRepeat: 'no-repeat',
        maskSize: '100%',
        WebkitMaskImage: `url(${iconData?.s3Url})`,
        WebkitMaskPosition: 'center',
        WebkitMaskRepeat: 'no-repeat',
        WebkitMaskSize: '100%',
        backgroundColor: color,
        ...style,
      }}
    />
  );
};
