import React, { FC } from 'react';

import styles from './styles.module.scss';

type Props = {
  imageUrl: string;
};

export const ProductCategoryIcon: FC<Props> = ({ imageUrl }) => {
  return (
    <div className={styles.wrapper}>
      {imageUrl && <img className={styles.icon} alt="icon" src={imageUrl} />}
    </div>
  );
};
