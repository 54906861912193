import styles from './index.module.scss';
import { ReactComponent as ChevronUpIcon } from '../../../../assets/icons/ChevronUpIcon.svg';
import { ReactComponent as ChevronDownIcon } from '../../../../assets/icons/ChevronDownIcon.svg';
export const DropdownIndicator = (props: any) => {
  const { selectProps } = props;

  return (
    <div className={styles.dropdown_indicator}>
      {selectProps.menuIsOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
    </div>
  );
};
