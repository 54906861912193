import React, {FC} from 'react';

type Props = {
    fill: string
};

export const TopLeftElement: FC<Props> = ({ fill  = "#FFF6E8"}) => {
    return (
        <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                id="Intersect"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M48.0703 0.987793C46.7004 1.21919 45.3615 1.69006 44.118 2.40043L6.11797 24.1082C2.37834 26.2445 0.0703125 30.221 0.0703125 34.5279V44.6231C0.0703125 47.0404 1.86117 49.0001 4.07031 49.0001H44.0703C46.2795 49.0001 48.0703 47.0404 48.0703 44.6231V0.987793Z"
                fill={fill}
            />
        </svg>
    );
};

