import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import ENGLISH from './EN/EN.json';
import GERMAN from './DE/DE.json';
import FRENCH from './FR/FR.json';
import { LANGUAGE } from './types';

const resources = {
  en: {
    translation: ENGLISH,
  },
  de: {
    translation: GERMAN,
  },
  fr: {
    translation: FRENCH,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: resources,
    lng: LANGUAGE.EN,
    fallbackLng: LANGUAGE.EN,

    interpolation: {
      escapeValue: false,
    },
  });

require('dayjs/locale/de');

registerLocale(LANGUAGE.DE, de);
