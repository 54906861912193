import React, { FC } from 'react';
import { FormInstance, Typography, Switch, Form } from 'antd';
import { FormItemName } from '../../../../../lib/models/Form';
import { AppCardBasic } from '../../../../../components/AppCardBasic';

import { useTranslation } from 'react-i18next';
import { FormItem } from '../../../../../components/FormItem';

import styles from './styles.module.scss';

const { Title, Text } = Typography;

const title = 'pages.settings.tracking.title';
const switchText = 'pages.settings.tracking.switchText';

type Props = {
  form: FormInstance;
};

export const TrackingCard: FC<Props> = ({ form }) => {
  const { t } = useTranslation();

  const toggleTracking = (checked: boolean) => {
    form.setFieldValue(FormItemName.TRACKING, checked);
  };

  return (
    <AppCardBasic>
      <div className={styles.header}>
        <Title className={styles.title}>{t(title)}</Title>
      </div>
      <FormItem noStyle name={FormItemName.TRACKING}>
        <div className={styles.switchContainer}>
          <Switch checked={Form.useWatch(FormItemName.TRACKING, form)} onChange={toggleTracking} />
          <Text className={styles.text}>{t(switchText)}</Text>
        </div>
      </FormItem>
    </AppCardBasic>
  );
};
