import { FC } from 'react';
import cx from 'classnames';
import styles from './index.module.scss';

type Props = {
  show?: boolean;
  size?: number;
  circleWidth?: number;
  className?: string;
};

export const Spinner: FC<Props> = ({ show, className, circleWidth, size = 12 }) => {
  return (
    <>
      {show && (
        <div
          style={{ width: size, height: size, borderWidth: circleWidth }}
          className={cx(styles.root, className)}
        />
      )}
    </>
  );
};
