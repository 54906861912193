import { OperatorTypes } from '../types/constants';
import { GetPublicDictionaryResponseT } from '../../../lib/types/ruleBuilder';
import { ElementSubValues, ElementSubValues2 } from '../types/entities';
import { ConditionsArray } from '../types';
import { nanoid } from '@reduxjs/toolkit';

const comparisonOperators = [
  {
    operator: '>',
    title: 'More than',
  },
  {
    operator: '<',
    title: 'Less than',
  },
  {
    operator: '>=',
    title: 'More than or qual',
  },
  {
    operator: '<=',
    title: 'Less than or qual',
  },
  {
    operator: '==',
    title: 'Equal',
  },
  {
    operator: '!=',
    title: 'Not qual',
  },
];

const arithmeticOperators = [
  {
    operator: '+',
    title: 'Addition',
  },
  {
    operator: '-',
    title: 'Subtraction',
  },
  {
    operator: '*',
    title: 'Multiplication',
  },
  {
    operator: '/',
    title: 'Division',
  },
  {
    operator: '+=',
    title: 'Addition and assign',
  },
  {
    operator: '-+',
    title: 'Division and assign',
  },
];

export const findSubElementsByName = (
  name: OperatorTypes | string,
  dictionaries: GetPublicDictionaryResponseT | null,
) => {
  switch (name) {
    case OperatorTypes.LogicalOperator:
      return dictionaries?.logicalOperators;
    case OperatorTypes.ComparisonOperator:
      return dictionaries?.comparisonOperators;
    case OperatorTypes.ArithmeticOperator:
      return dictionaries?.arithmeticOperators;
    case OperatorTypes.Element:
      return dictionaries?.elements;
    case OperatorTypes.Status:
      return dictionaries?.states;
    default:
      return null;
  }
};

export const getTooltipText = (name: OperatorTypes) => {
  const operatorsArray =
    name === OperatorTypes.ArithmeticOperator ? arithmeticOperators : comparisonOperators;

  return operatorsArray?.map((item) => (
    <div style={{ display: 'flex', columnGap: 8, height: 24 }} key={item.title}>
      <div style={{ width: 25, textAlign: 'start' }}>{item.operator}</div>
      <div>{item.title}</div>
    </div>
  ));
};

export const dateOptions = [
  {
    value: 'Today',
    title: 'Today',
  },
  {
    value: 'Day',
    title: 'Day',
  },
  {
    value: 'Day Digit',
    title: 'Day Digit',
  },
];

export const booleanOptions = [
  {
    value: 'True',
    title: 'True',
  },
  {
    value: 'False',
    title: 'False',
  },
];

export const statusLevelStaticOptions = [
  {
    value: ElementSubValues.StatusLevel,
    title: 'Status Level',
  },
  {
    value: ElementSubValues.Threshold,
    title: 'Threshold',
  },
];

export const dataFieldOptions = [
  {
    value: ElementSubValues.OptInMarketing,
    title: 'Opt-in marketing',
  },
  {
    value: ElementSubValues.RegistrationDate,
    title: 'Registration date',
  },
];

export const moveElement = (array: ConditionsArray, id: string, direction: 'up' | 'down') => {
  const copyArray = [...array];

  const index = copyArray.findIndex((obj) => obj.id === id);

  if (index === -1) {
    return [];
  }

  const newIndex = direction === 'up' ? index - 1 : index + 1;

  if (newIndex < 0 || newIndex >= copyArray.length) {
    return [];
  }

  const temp = copyArray[newIndex];
  copyArray[newIndex] = copyArray[index];
  copyArray[index] = temp;

  return copyArray.map((condition, index) => ({
    ...condition,
    conditionName: `${index + 1} Condition`,
  }));
};

export const mapConditionAndAddId = (rules: ConditionsArray) => {
  return rules.map((rule, index) => ({
    conditionName: `${index + 1} Condition`,
    id: nanoid(),
    rules: rule.rules.map((item) => ({ ...item, id: nanoid() })),
  }));
};
