import { forwardRef } from 'react';
import { Typography } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

const { Text } = Typography;

type Props = {
  textValue: string;
  removeFilter: () => void;
};

export const MainLabel = forwardRef<HTMLDivElement, Props>(({ textValue, removeFilter, ...rest }, ref) => {
  return (
    <div ref={ref} className={styles.labelRoot} {...rest}>
      <Text>{textValue}</Text>

      <button className={styles.btn} onClick={removeFilter}>
        <CloseCircleOutlined style={{ fontSize: '20px' }} />
      </button>
    </div>
  );
});

MainLabel.displayName = 'MainLabel';
