import { Form, message, MessageArgsProps, Typography } from 'antd';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useResetFormOnCloseModal } from '../../../../lib/hooks/useResetFormOnCloseModal';
import { AppModal } from '../../../../components/AppModal';
import { useDeleteProductCategoryMutation } from '../../../../lib/store/api/productCategories';
import { useParams } from 'react-router-dom';
import { ProductCategoryT } from '../../../../lib/types/entities';

const MESSAGE_SHOWING_TIME = 3;
const { Paragraph } = Typography;
const deleteModal = 'pages.products.productCategories.deleteModal';

type Props = {
  isOpen: boolean;
  close: () => void;
  selectedCategory: ProductCategoryT | null;
};

export const DeleteProductCategoriesModal: FC<Props> = ({ isOpen, close, selectedCategory }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const { id: tenantId } = useParams();

  const [deleteProductCategory, { isLoading }] = useDeleteProductCategoryMutation();

  useResetFormOnCloseModal({ form, isOpen });

  const onConfirm = () => {
    form.submit();
  };

  const onFinish = async () => {
    const messageConfig = {} as MessageArgsProps;

    try {
      await deleteProductCategory({
        tenantId: String(tenantId),
        categoryId: Number(selectedCategory?.id),
      }).unwrap();
      messageConfig.content = t(`${deleteModal}.successText`);
      messageConfig.type = 'success';
      close();
    } catch (err: any) {
      messageConfig.content = err?.data?.message || t(`${deleteModal}.errorText`);
      messageConfig.type = 'error';
    } finally {
      messageApi.open({
        duration: MESSAGE_SHOWING_TIME,
        ...messageConfig,
      } as MessageArgsProps);
    }
  };

  return (
    <AppModal
      open={isOpen}
      onCancel={close}
      onOk={onConfirm}
      okText={t('generic.delete')}
      titleText={t(`${deleteModal}.title`)}
      isOkLoading={isLoading}
    >
      {contextHolder}
      {isOpen && (
        <Form form={form} onFinish={onFinish}>
          <Paragraph style={{ marginBottom: '36px' }}>
            {t(`${deleteModal}.questionText`, { categoryName: selectedCategory?.name })}
          </Paragraph>
        </Form>
      )}
    </AppModal>
  );
};
