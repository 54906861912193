import { useTranslation } from 'react-i18next';
import { TableHeaderCell } from '../../../../components/Table/TableHeaderCell/TableHeaderCell';
import { Typography } from 'antd';
import tableStyles from '../../../../components/Table/index.module.scss';
import { DEMAND_AREAS_COLUMN } from '../../../../lib/types/demandArea';

const numberOfCategories = 'pages.products.demandArea.tableItems.numberOfCategories';

export const useDemandsNumberOfCategoriesColumn = () => {
  const { t } = useTranslation();

  return {
    dataIndex: DEMAND_AREAS_COLUMN.PRODUCT_CATEGORIES,
    title: <TableHeaderCell withArrange={false} title={t(numberOfCategories)} />,
    width: 234,
    render: (productsCategories: { name: string; value: string }[]) => {
      return (
        <Typography.Text className={tableStyles.markedCell}>{productsCategories?.length}</Typography.Text>
      );
    },
  };
};
