import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { DEMAND_AREAS_COLUMN } from '../../../../lib/types/demandArea';
import { TableHeaderCell } from '../../../../components/Table/TableHeaderCell/TableHeaderCell';

const categories = 'pages.products.demandArea.tableItems.categories';

const { Text } = Typography;
export const useDemandAreaProductCategories = () => {
  const { t } = useTranslation();

  return {
    dataIndex: DEMAND_AREAS_COLUMN.PRODUCT_CATEGORIES,
    title: <TableHeaderCell withArrange={false} title={t(categories)} />,
    width: 639,
    render: (value: { name: string; value: string }[]) => {
      return <Text>{value?.map((category) => category.name).join(', ')}</Text>;
    },
  };
};
