import { FC, ReactNode } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { BasicButton } from '../../../../components/BasicButton';
import cx from 'classnames';

import styles from './index.module.scss';

const { Text } = Typography;

type Props = {
  children?: ReactNode;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
};

export const FormCardButton: FC<Props> = ({ children, disabled, onClick, className }) => {
  const { t } = useTranslation();

  return (
    <BasicButton
      disabled={disabled}
      onClick={onClick}
      className={cx(className, { [styles.disabled]: disabled })}
      type="button"
    >
      <Text className={styles.text}>{children || t('generic.reset')}</Text>
    </BasicButton>
  );
};
