import { Form } from 'antd';
import { useState } from 'react';
import { User, UserStatus } from '../../../helpers/constants';
import { useUserArrangeRequests } from '../useUserArrangeRequests';
import { useEnumArrange } from '../../../../tenants/hooks/useEnumArrange';
import {
  createOptionItem,
  getParsedSelectedItems,
  getSelectedItemsLabels,
  getSelectedItemsValues,
} from '../../../../../lib/utils/arranges-helpers';
import { useAppSelector } from '../../../../../lib/store/hooks';
import { useTranslation } from 'react-i18next';
import { useStatusColumn } from '../../../../../features/table/helpers/useStatusColumn';
import { UserStatusBodyCell } from '../../../components/UsersTable/UserStatusBodyCell';

const activated = 'shared.status.activated';
const deactivated = 'shared.status.deactivated';
const pending = 'shared.status.pending';

export function useUserStatusColumn() {
  const { t } = useTranslation();

  const { sorting, arrangeId } = useAppSelector(({ userManagement }) => userManagement);
  const [form] = Form.useForm();
  const isActiveArrange = arrangeId === User.STATUS;
  const getItemsLabels = () => getSelectedItemsLabels(form);
  const getItemsValues = () => getSelectedItemsValues(form);
  const [filterLabel, setFilterLabel] = useState('');

  const activatedLabel = t(activated);
  const pendingLabel = t(pending);
  const deactivatedLabel = t(deactivated);

  const allOptions = [
    createOptionItem(activatedLabel, UserStatus.ACTIVE),
    createOptionItem(pendingLabel, UserStatus.PENDING),
    createOptionItem(deactivatedLabel, UserStatus.DEACTIVATED),
  ];

  const { filtering } = useEnumArrange(allOptions);

  const { applyArranges, removeFilter, removeFilterValue } = useUserArrangeRequests({
    arrangedId: User.STATUS,
    getFilterLabel: () => getItemsLabels().join(', '),
    getFilters: () => ({ statuses: getItemsValues() }),
    form,
    setFilterLabel,
    filterFieldName: 'statuses',
    isParsedByValue: true,
  });

  return useStatusColumn({
    filtering,
    applyArranges,
    removeFilter,
    arrangedId: User.STATUS,
    isActiveArrange,
    form,
    filterLabel,
    sortOrder: sorting.sortOrder,
    getParsedItems: () => getParsedSelectedItems(form),
    removeFilterValue,
    columnProps: {
      render: (status: UserStatus) => <UserStatusBodyCell status={status} />,
    },
  });
}
