import { FormInstance } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../lib/store/hooks';
import { useCreateUpdateProductMutation } from '../../../lib/store/api/products';
import { getFormFieldsData, prepareProductFormData } from '../helpers';
import { ProductConfiguratorFormValuesT } from '../types';
import { trimForm } from '../../../lib/utils/helpers';
import { CreateUpdateProductPayloadT } from '../../../lib/types/product';
import { setCurrentProduct } from '../../../lib/store/slices/productsSlice';
import { QueryParams, Tabs } from '../../../lib/types/queryParams';
import { EXCEPTION_CODE } from '../../../lib/utils/constants';

const messages = 'pages.products.productConfigurator.messages';

type Props = {
  form: FormInstance;
  messageApi: MessageInstance;
};

export const useProductConfiguratorForm = ({ form, messageApi }: Props) => {
  const { id: tenantId, modelId: projectId } = useParams();
  const { t } = useTranslation();
  const selectedProduct = useAppSelector(({ products }) => products.selectedProduct);
  const dispatch = useAppDispatch();
  const [, setSearchParams] = useSearchParams();

  const [createUpdateProduct, { isLoading }] = useCreateUpdateProductMutation();

  useEffect(() => {
    if (selectedProduct) {
      const data = getFormFieldsData(selectedProduct);
      form.setFieldsValue(data);
    }
  }, [form, selectedProduct]);

  const handleSubmit = async (values: ProductConfiguratorFormValuesT) => {
    const trimmedForm = trimForm(values);
    const formData = prepareProductFormData(trimmedForm);

    const data: CreateUpdateProductPayloadT = { ...formData, projectId: Number(projectId) };

    if (selectedProduct) {
      data.id = selectedProduct.id;
    }

    try {
      await createUpdateProduct({ data, tenantId }).unwrap();
      const text = selectedProduct ? `${messages}.successEditing` : `${messages}.successCreation`;
      messageApi.success(t(text));
      dispatch(setCurrentProduct(null));
      setSearchParams({ [QueryParams.TAB]: Tabs.PRODUCTS });
    } catch (err: any) {
      const isSameNameError = err?.data?.exceptionCode === EXCEPTION_CODE.ERROR_007;
      const text = selectedProduct ? `${messages}.errorEditing` : `${messages}.errorCreation`;
      messageApi.error(isSameNameError ? t(`${messages}.errorName`) : t(text));
    }
  };

  return {
    onSubmit: handleSubmit,
    isLoading,
  };
};
