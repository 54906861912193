import { FC } from 'react';
import { Form, FormInstance, Typography } from 'antd';
import { Mutation } from '../../../../lib/types/store';
import { TenantT } from '../../../../lib/types/tenants';
import { useTranslation } from 'react-i18next';
import { TenantNameDeleteFormItem } from '../FormItems/TenantNameDeleteFormItem';
import { MessageInstance } from 'antd/es/message/interface';

import styles from './index.module.scss';

const { Paragraph, Text } = Typography;

const deleteModal = 'pages.tenants.deleteModal';

const subTitle = `${deleteModal}.subTitle`;
const success = `${deleteModal}.success`;
const error = `${deleteModal}.error`;

type Props = {
  form: FormInstance;
  tenantData: TenantT | null;
  tenantName: string;
  messageApi: MessageInstance;
  onSubmit: Mutation<number>;
};

export const DeleteTenantForm: FC<Props> = ({ form, messageApi, tenantData, tenantName, onSubmit }) => {
  const originalName = tenantData?.name;
  const { t } = useTranslation();

  const onFinish = async () => {
    if (tenantData) {
      try {
        await onSubmit(tenantData?.id).unwrap();
        messageApi.success(t(success));
      } catch (err) {
        messageApi.error(t(error));
      }
    }
  };

  return (
    <Form form={form} onFinish={onFinish} name="delete_tenant">
      <Paragraph className={styles.paragraph}>
        <Text>{t(`${subTitle}.openPart`)}</Text>
        <Text className={styles.bold}>{tenantData?.name}</Text>
        <Text>{t(`${subTitle}.closePart`)}</Text>
      </Paragraph>
      <TenantNameDeleteFormItem originalName={originalName} tenantName={tenantName} />
    </Form>
  );
};
