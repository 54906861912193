import { FC, useMemo, useState } from 'react';
import { Modal } from 'antd';
import styles from './styles.module.scss';

import { EndUserTypography } from '../EndUserTypography';
import { EndUserButton } from '../EndUserButton';
import { useAddProductToIrrelevantMutation } from '../../lib/store/api/endUser';
import { useAppDispatch, useAppSelector } from '../../lib/store/hooks';
import cn from 'classnames';
import { modifyProduct } from '../../lib/store/slices/endUserSlice';
import { ProductT } from '../../lib/types/product';
import { SelectionType } from '../../lib/types/entities';
import { sendDataToAnalytics } from '../../lib/utils/sendDataToAnalytics';
import { useTranslation } from 'react-i18next';
import { END_SUER_STADTWERK_TAUBERFRANKEN_NAME } from '../../lib/utils/constants';

const shared = 'endUser.shared';
const translation = 'endUser.products';

type Props = {
  isModalOpen: boolean;
  close: () => void;
  product: ProductT;
  setIsFilled: any;
};

type IrrelevantReason = {
  display: string;
  value: string;
};

export const IRRELEVANT_REASONS: Record<string, IrrelevantReason> = {
  SUPPLIED_ELSE_WHERE: { display: 'Ich bin bereits anderweitig versorgt', value: 'SUPPLIED_ELSE_WHERE' },
  SUPPLIED_BY_THE_CLIENT: {
    display: 'Ich bin bei energie schwaben versorgt',
    value: 'SUPPLIED_BY_THE_CLIENT',
  },
  IS_NOT_INTERESTED: { display: 'Ich bin nicht interessiert', value: 'IS_NOT_INTERESTED' },
  OTHER: { display: 'Sonstiges', value: 'OTHER' },
};

export const EndUserIrrelevantSurveyModal: FC<Props> = ({ isModalOpen, close, product, setIsFilled }) => {
  const dispatch = useAppDispatch();
  const { tenantAndProject, allProducts, isGuestMode, demandArea } = useAppSelector(({ endUser }) => endUser);
  const [addProductToIrrelevant] = useAddProductToIrrelevantMutation();
  const [selectedReason, setSelectedReason] = useState<any | null>(null);
  const { t } = useTranslation();

  const tenantName = demandArea?.tenant.clientName;

  const modifiedIrrelevantReasons = useMemo(() => {
    const reasonsArray = Object.values(IRRELEVANT_REASONS);

    return reasonsArray.map((reason) =>
      reason.value === IRRELEVANT_REASONS.SUPPLIED_BY_THE_CLIENT.value
        ? {
            ...reason,
            display: `Ich bin bei${
              tenantName === END_SUER_STADTWERK_TAUBERFRANKEN_NAME ? 'm' : ''
            } ${tenantName} versorgt`,
          }
        : reason,
    );
  }, [tenantName]);

  const onAddToIrrelevant = async () => {
    if (!selectedReason) {
      // Handle case where no reason is selected
      return;
    }

    if (isGuestMode) {
      const hasOtherBoughtIrrelevantProductsFromSingleCategory = allProducts
        .filter((productItem) => {
          return productItem.category.id === product.category.id;
        })
        .some(
          (productItem) =>
            productItem.irrelevantSurveyReason !== null &&
            productItem.category.selectionType === SelectionType.SINGLE,
        );

      const needMarkProductAsBought =
        selectedReason === IRRELEVANT_REASONS.SUPPLIED_BY_THE_CLIENT.value &&
        !hasOtherBoughtIrrelevantProductsFromSingleCategory;

      dispatch(
        modifyProduct({
          ...product,
          countUserProducts: needMarkProductAsBought ? 1 : null,
          irrelevantSurveyReason: selectedReason,
        }),
      );

      setIsFilled(selectedReason);
      close();

      sendDataToAnalytics(
        `Mark as irrelevant, productName: ${product.name}, productId: ${product.productId}, reason: ${selectedReason}`,
      );
      return;
    }

    try {
      await addProductToIrrelevant({
        productId: product.id,
        tenantId: String(tenantAndProject?.tenantId),
        projectId: Number(tenantAndProject?.projectId),
        reason: selectedReason,
      }).unwrap();
      setIsFilled(selectedReason);
      close();

      sendDataToAnalytics(
        `Mark as irrelevant, productName: ${product.name}, productId: ${product.productId}, reason: ${selectedReason}`,
      );
    } catch (error) {
      // console.log(error);
    }
  };
  return (
    <div>
      <Modal
        centered
        open={isModalOpen}
        width={544}
        onCancel={close}
        destroyOnClose={true}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <div style={{ backgroundColor: '#fff' }}>
          <div className={styles.container}>
            <EndUserTypography type="headlineThird" component="Title">
              {t(`${translation}.dontShowMore`)}
            </EndUserTypography>

            <EndUserTypography className={styles.description} type="body" component="Text">
              {t(`${translation}.irrelevantText`)}
            </EndUserTypography>

            <div className={styles.buttonsWrapper}>
              {modifiedIrrelevantReasons.map((reason) => (
                <EndUserButton
                  key={reason.value}
                  className={cn(styles.button, { [styles.buttonSelected]: reason.value === selectedReason })}
                  type="secondary"
                  onClick={() => setSelectedReason(reason.value)}
                >
                  {reason.display}
                </EndUserButton>
              ))}
            </div>
            <div className={styles.footer}>
              <EndUserButton type="secondary" className={styles.footerButton} onClick={close}>
                {t(`${shared}.cancel`)}
              </EndUserButton>
              <EndUserButton type="primary" className={styles.footerButton} onClick={onAddToIrrelevant}>
                {t(`${shared}.save`)}
              </EndUserButton>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
