import { Link } from 'react-router-dom';
import styles from '../components/EndUserSignUpCard/index.module.scss';
import { AgreementCheckboxContentT } from '../../../lib/types/sections';

type Props = {
  dataProtectionLink: string;
  agbLink: string;
  clientName: string;
};

const CLIENT_NAME = '{CLIENT_NAME}';

export const useGetCheckboxAgreementText = ({ dataProtectionLink, agbLink, clientName }: Props) => {
  const getCheckboxText = (content?: Array<string | AgreementCheckboxContentT>) => {
    const mappedContent = content?.map((item, index) => {
      if (typeof item === 'string') {
        return item?.replaceAll(CLIENT_NAME, clientName);
      } else {
        return (
          <Link
            key={index}
            className={styles.link}
            target="_blank"
            to={item?.isDataProtectionLink ? dataProtectionLink : agbLink}
          >
            {item?.text}
          </Link>
        );
      }
    });

    return mappedContent;
  };

  return { getCheckboxText };
};
