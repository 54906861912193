import { RulesActionsBodyCell } from '../components/tables/RulesActionsBodyCell';
import { RuleT } from '../../../lib/types/ruleBuilder';
import { useActionsColumn } from '../../../features/table/helpers/useActionsColumn';

export const useRulesActionsColumns = (tableName: 'rules' | 'ruleTypes') => {
  return useActionsColumn({
    width: 100,
    render: (_text: unknown, row: RuleT) => <RulesActionsBodyCell row={row} tableName={tableName} />,
  });
};
