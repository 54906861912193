import { useEffect, useState } from 'react';
import lodashDebounce from 'lodash.debounce';

const DEBOUNCED_DELAY = 100; // ms

export function useWindowResize() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', lodashDebounce(handleWindowResize, DEBOUNCED_DELAY));

    return () => {
      window.removeEventListener('resize', lodashDebounce(handleWindowResize, DEBOUNCED_DELAY));
    };
  });

  return { windowWidth };
}
