import { FC } from 'react';
import { Form, FormInstance } from 'antd';
import { appDayJs } from '../../../../lib/models/Time';
import { useResetArrange } from '../../helpers/useResetArrange';
import { ArrangeSortAndFilterPopupWrapper } from './ArrangeSortAndFilterPopupWrapper';
import { ArrangeFormItem } from '../../../../lib/models/Arrange';
import { FormItem } from '../../../../components/FormItem';
import { DatePicker } from '../../../../components/DatePicker';

import styles from './index.module.scss';
import { useAppSelector } from '../../../../lib/store/hooks';
import { useTranslation } from 'react-i18next';

const NAME = ArrangeFormItem.DATES;
const SIZE = 'small';

export function getEndOfTheUTCDayByDate(date: Date) {
  return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 0);
}

export function geStartOfTheUTCDayByDate(date: Date) {
  return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
}

export function formatDates([startDate, endDate]: [Date, Date]) {
  return [
    startDate ? appDayJs(geStartOfTheUTCDayByDate(startDate)).toISOString() : undefined,
    endDate ? appDayJs(getEndOfTheUTCDayByDate(endDate)).toISOString() : undefined,
  ];
}

export function convertUTCToLocalDate(date: Date) {
  if (!date) {
    return date;
  }

  const instance = new Date(date);
  return new Date(instance.getUTCFullYear(), instance.getUTCMonth(), instance.getUTCDate());
}

type Props = {
  onFinish: () => void;
  form: FormInstance<any>;
  initialValues: any;
  isActiveArrange: boolean;
};

export const ArrangeDatePopup: FC<Props> = ({ onFinish, form, initialValues, isActiveArrange }) => {
  const { appLang } = useAppSelector(({ auth }) => auth);
  const { t } = useTranslation();

  const datesValue = Form.useWatch(NAME, form);
  const fromDate = datesValue?.[0];
  const toDate = datesValue?.[1];
  const startDate = convertUTCToLocalDate(fromDate);

  const onChange = (dates: [Date, Date]) => {
    form.setFieldValue(NAME, formatDates(dates));
  };

  useResetArrange({ isActiveArrange, form });

  return (
    <Form form={form} onFinish={onFinish} initialValues={initialValues}>
      <ArrangeSortAndFilterPopupWrapper isDate={true}>
        <FormItem hasFeedback size={SIZE} name={NAME} className={styles.datePickerFormItem}>
          <DatePicker
            isClearable
            hasFeedback={false}
            selectsRange
            size={SIZE}
            selected={startDate}
            startDate={startDate}
            endDate={convertUTCToLocalDate(toDate)}
            onChange={onChange}
            locale={appLang}
            style={{ overflow: 'visible', height: '100%' }}
            placeholderText={t('platform.datePicker.label')} // REFACTOR: move it to translations
          />
        </FormItem>
      </ArrangeSortAndFilterPopupWrapper>
    </Form>
  );
};
