import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { ROUTER_PATHS } from '../../../../lib/utils/router-paths';
import { ReactComponent as TenantsIcon } from '../../../../assets/icons/tenant-icon.svg';
import { ReactComponent as UserManagementIcon } from '../../../../assets/icons/UserManagementIcon.svg';
// import { ReactComponent as BrandIcon } from '../../../../assets/icons/BrandIcon.svg';
// import { ReactComponent as TemplatesIcon } from '../../../../assets/icons/TemplateIcon.svg';
// import styles from '../PlatformSidebar/index.module.scss';

const sidebar = 'platform.sidebar';

export const useMenuOptions = () => {
  const { t } = useTranslation();
  const location = useLocation();

  // const isPageActive = (pageEnum: ROUTER_PATHS) => {
  //   return location.pathname.slice(1) === pageEnum ? styles.sidebar_menu_active : '';
  // };

  // const templatesOptions = [
  //   {
  //     label: <Link to={ROUTER_PATHS.intro}>{t(sidebar.templatesChildren.intro)}</Link>,
  //     key: ROUTER_PATHS.intro,
  //   },
  //   {
  //     label: <Link to={ROUTER_PATHS.chapter}>{t(sidebar.templatesChildren.chapter)}</Link>,
  //     key: ROUTER_PATHS.chapter,
  //   },
  //   {
  //     label: <Link to={ROUTER_PATHS.content}>{t(sidebar.templatesChildren.content)}</Link>,
  //     key: ROUTER_PATHS.content,
  //   },
  //   {
  //     label: <Link to={ROUTER_PATHS.question}>{t(sidebar.templatesChildren.question)}</Link>,
  //     key: ROUTER_PATHS.question,
  //   },
  //   {
  //     label: <Link to={ROUTER_PATHS.product}>{t(sidebar.templatesChildren.product)}</Link>,
  //     key: ROUTER_PATHS.product,
  //   },
  //   {
  //     label: <Link to={ROUTER_PATHS.result}>{t(sidebar.templatesChildren.result)}</Link>,
  //     key: ROUTER_PATHS.result,
  //   },
  //   {
  //     label: <Link to={ROUTER_PATHS.contact}>{t(sidebar.templatesChildren.contact)}</Link>,
  //     key: ROUTER_PATHS.contact,
  //   },
  // ];

  const items = [
    {
      label: <Link to={ROUTER_PATHS.tenants}>{t(`${sidebar}.tenants`)}</Link>,
      icon: <TenantsIcon />,
      key: ROUTER_PATHS.tenants,
    },
    {
      label: <Link to={ROUTER_PATHS.users}>{t(`${sidebar}.users`)}</Link>,
      icon: <UserManagementIcon />,
      key: ROUTER_PATHS.users,
    },
    // {
    //   label: t(sidebar.templates),
    //   icon: <TemplatesIcon />,
    //   key: ROUTER_PATHS.templates,
    //   children: templatesOptions.map((option) => {
    //     return {
    //       ...option,
    //       label: React.cloneElement(option.label, {
    //         className: isPageActive(option.key),
    //       }),
    //     };
    //   }),
    // },
    // {
    //   label: <Link to={ROUTER_PATHS.themes}>{t(sidebar.themes)}</Link>,
    //   icon: <BrandIcon />,
    //   key: ROUTER_PATHS.themes,
    // },
  ];

  const linkPathExtractor = (
    array: {
      label: JSX.Element | string;
      icon: JSX.Element;
      key: ROUTER_PATHS;
    }[],
  ) => {
    return array.filter((item) => location.pathname.includes(item.key)).map((linkObject) => linkObject.key);
  };

  const selectedItems = linkPathExtractor(items);

  return { selectedItems, items };
};
