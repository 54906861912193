import { useTranslation } from 'react-i18next';
import { PRODUCT_CATEGORIES } from '../helpers/constants';
import { useProductCategoriesSearchDataColumn } from './useProductCategoriesSearchDataColumn';

const productCategory = 'pages.products.productCategories.columns.productCategory';

export const useProductCategoryNameColumn = () => {
  const { t } = useTranslation();

  return useProductCategoriesSearchDataColumn({
    title: t(productCategory),
    arrangedId: PRODUCT_CATEGORIES.NAME,
    searchFieldName: 'searchByName',
    filterFieldName: 'includeNames',
    columnProps: {
      width: 250,
    },
  });
};
