import { FC, useMemo } from 'react';
import { Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItemTitle } from '../FormItemTitle';
import { FormItemName } from '../../../../lib/models/Form';
import { FormItem } from '../../../../components/FormItem';
import { AppSelect } from '../../../../components/AppSelect';
import { useParams } from 'react-router-dom';
import { useGetBadgesQuery } from '../../../../lib/store/api/badge';

import styles from './index.module.scss';

const formItems = 'pages.products.productConfigurator.formItems';

export const BadgeCard: FC = () => {
  const { t } = useTranslation();
  const { id: tenantId, modelId } = useParams();

  const { data } = useGetBadgesQuery(
    {
      tenantId: String(tenantId),
      modelId: String(modelId),
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const options = useMemo(() => {
    return data?.content?.map((item) => ({
      title: item.name,
      value: item.id,
    }));
  }, [data]);

  return (
    <Card className={styles.root}>
      <FormItemTitle>{t(`${formItems}.badge.title`)}</FormItemTitle>
      <FormItem hasFeedback name={FormItemName.BADGE_ID} normalize={(val: { value: any }) => val?.value}>
        <AppSelect options={options} placeholder={t(`${formItems}.badge.label`)} unselectable />
      </FormItem>
    </Card>
  );
};
