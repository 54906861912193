import { FC } from 'react';
import { AuthLayout } from '../../modules/auth/layouts/AuthLayout';
import { GetLinkForResetPasswordCard } from '../../modules/auth/components/GetLinkForResetPasswordCard';

export const GetLinkForResetPasswordPage: FC = () => {
  return (
    <AuthLayout>
      <GetLinkForResetPasswordCard />
    </AuthLayout>
  );
};
