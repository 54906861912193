import { Upload } from 'antd';
import { UploadLabel } from '../../../../components/UploadLabel';
import { useTranslation } from 'react-i18next';
import styles from './../BrandKit/index.module.scss';
import { useSendFontMutation } from '../../../../lib/store/api/brandKit';
import { useBeforeUploadError } from '../../../../lib/hooks/useBeforeUploadError';
import { createFormData } from '../../../../lib/utils/helpers';
import { EXCEPTION_CODE } from '../../../../lib/utils/constants';
import { Spinner } from '../../../../components/Spinner';

type UploadCustomRequestOptions = {
  onProgress: (event: { percent: number }) => void;
  onSuccess: (response: any, file: File) => void;
  onError: (error: Error, response: any) => void;
  data: Record<string, any>;
  file: File;
  filename: string;
  action: string;
};

const MAX_FONT_FILE_SIZE = 1; //mb

const designSettings = 'pages.designSettings';

type Props = {
  tenantId: number | string;
};

export const FontLoader = ({ tenantId }: Props) => {
  const { t } = useTranslation();

  const [addFont, { isLoading }] = useSendFontMutation();

  const { beforeUpload, contextHolder, messageApi } = useBeforeUploadError(
    MAX_FONT_FILE_SIZE,
    `${designSettings}.errors.tooBigSize`,
  );

  const loadFontRequest = async (options: UploadCustomRequestOptions) => {
    const { file } = options;
    const fontName = file.name.split('.')[0];
    const formattedName = fontName.split(' ').join('-');
    const formData = createFormData({ file, originalFileName: formattedName });

    try {
      await addFont({ tenantId, body: formData }).unwrap();
      messageApi.success(t(`${designSettings}.success.uploadFont`));
    } catch (err: any) {
      const isSameNameError = err?.data?.exceptionCode === EXCEPTION_CODE.ERROR_007;
      messageApi.error(
        isSameNameError ? t(`${designSettings}.errors.sameName`) : t(`${designSettings}.errors.uploadFont`),
      );
    }
  };

  return (
    <div className={styles.fontUploadButton}>
      {contextHolder}
      <Upload
        accept=".ttf, .otf, .woff, .woff2"
        beforeUpload={beforeUpload}
        showUploadList={false}
        disabled={isLoading}
        customRequest={(options) => loadFontRequest(options as any)}
      >
        <UploadLabel
          disabled={isLoading}
          textStyles={{ fontSize: '16px' }}
          text={t(`${designSettings}.uploadFont`)}
        />
      </Upload>
      <Spinner show={isLoading} size={16} />
    </div>
  );
};
