import { FC, useEffect } from 'react';
import { useAppSelector } from '../../../lib/store/hooks';
import { useGetEndUserDataQuery } from '../../../lib/store/api/auth';

declare global {
  interface Window {
    _paq: any;
    _mtm: any;
  }
}

export const MatomoTracker: FC = () => {
  const { generalSettings, matomoEnabledByUserInGuestMode, tenantAndProject, isGuestMode } = useAppSelector(
    ({ endUser }) => endUser,
  );

  const { data } = useGetEndUserDataQuery(
    { tenantId: String(tenantAndProject?.tenantId) },
    { skip: isGuestMode },
  );

  const matomoEnabledForEndUserFlow = data?.agreeUseName;
  const trackerUrl = generalSettings?.tracking?.trackerUrl;
  const isTrackingEnabled =
    generalSettings?.tracking?.tracking && (matomoEnabledByUserInGuestMode || matomoEnabledForEndUserFlow);

  useEffect(() => {
    if (isTrackingEnabled && trackerUrl) {
      const _mtm = (window._mtm = window._mtm || []);
      _mtm.push({ 'mtm.startTime': Date.now(), event: 'mtm.Start' });
      const d = document;
      const g = d.createElement('script');
      const s = d.getElementsByTagName('script')[0];
      g.async = true;
      g.src = trackerUrl;
      s.parentNode && s.parentNode.insertBefore(g, s);

      setTimeout(() => {
        window._paq.push(['requireConsent']);
        window._paq.push(['rememberConsentGiven']);
      }, 1000);
    }
  }, [isTrackingEnabled, trackerUrl]);

  return null;
};
