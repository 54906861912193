import { FC, ReactNode } from 'react';
import { Typography } from 'antd';
import { ColumnType } from 'antd/es/table';

import { ArrangeButtonWrapper } from './ArrangeButtonWrapper';

import tableStyles from '../index.module.scss';
import styles from './index.module.scss';

const { Text } = Typography;

import cn from 'classnames';

type Props = {
  title: string;
  withArrange: boolean;
  children: ReactNode;
  isActiveArrange: boolean;
  sortOrder: ColumnType<any>['sortOrder'];
  arrangedId?: string;
  titleAndButtonRootClassName?: string;
};

export const TitleAndButton: FC<Props> = ({
  withArrange,
  children,
  title,
  isActiveArrange,
  sortOrder,
  arrangedId,
  titleAndButtonRootClassName,
}) => {
  return (
    <div className={cn(styles.root, titleAndButtonRootClassName)}>
      <Text className={tableStyles.headerCellTitle}>{title}</Text>

      {withArrange && (
        <ArrangeButtonWrapper arrangedId={arrangedId} isActiveArrange={isActiveArrange} sortOrder={sortOrder}>
          {children}
        </ArrangeButtonWrapper>
      )}
    </div>
  );
};
