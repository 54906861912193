import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalActions } from '../../models/Modal';
import { UserDataT } from '../../types/auth';
import { TableSortOrder } from '../../utils/constants';
import { User } from '../../../modules/userManagement/helpers/constants';
import { Filter, FiltersPayload, Sort } from '../../types/filteringAndSort';

type userManagementSliceStateT = {
  page: number;
  arrangeId: User | null;
  sorting: Sort;
  filtering: Filter;

  modalType: ModalActions | null;
  isModalOpen: boolean;
  selectedUser: UserDataT | null;
};

const initialState: userManagementSliceStateT = {
  page: 1,
  arrangeId: null,
  sorting: {
    sortOrder: TableSortOrder.NONE,
    sortFieldName: null,
    withSorting: true,
  },
  filtering: {
    filterFieldName: null,
    filter: [],
  },
  isModalOpen: false,
  modalType: null,
  selectedUser: null,
};

export type UsersFiltersPayload = FiltersPayload & {
  arrangeId: User;
};

const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    setModalOpened: (state, action: PayloadAction<ModalActions>) => {
      state.modalType = action.payload;
      state.isModalOpen = true;
    },
    setModalClosed: (state) => {
      state.selectedUser = null;
      state.modalType = null;
      state.isModalOpen = false;
    },
    setCurrentUsers: (state, action: PayloadAction<UserDataT>) => {
      state.selectedUser = action.payload;
    },
    setFilters: (state, action: PayloadAction<UsersFiltersPayload>) => {
      state.arrangeId = action.payload.arrangeId;
      state.sorting = action.payload.sorting;
      state.filtering = action.payload.filtering;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
  },
});

export const { setCurrentUsers, setModalOpened, setModalClosed, setFilters, setPage } =
  userManagementSlice.actions;
export const userManagement = userManagementSlice.reducer;
