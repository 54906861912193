import { useTranslation } from 'react-i18next';
import { useProjectSearchDataColumn } from './useProjectSearchDataColumn';
import { PROJECT_COLUMN } from '../helpers/constants';
import tableStyles from '../../../components/Table/index.module.scss';

const contractSKP = 'pages.projects.columns.contractSKP';

export const useContractSKPColumn = () => {
  const { t } = useTranslation();

  return useProjectSearchDataColumn({
    title: t(contractSKP),
    arrangedId: PROJECT_COLUMN.CONTACT_SKP,
    searchFieldName: 'searchByContactSKP',
    filterFieldName: 'includeContactSKP',

    headerCellProps: {
      rootProps: {
        className: tableStyles.hiddenHeaderCell,
      },
    },
  });
};
