import { useTranslation } from 'react-i18next';
import { useUserSearchDataColumn } from '../useUserSearchDataColumn';
import { User } from '../../../helpers/constants';

const userName = 'pages.userManagement.columns.userName';

export const useUserNameColumn = () => {
  const { t } = useTranslation();

  return useUserSearchDataColumn({
    title: t(userName),
    arrangedId: User.USERNAME,
    searchFieldName: 'searchByLogin',
    filterFieldName: 'includeLogins',
    columnProps: {
      width: 165,
    },
  });
};
