import { useLocation } from 'react-router-dom';
import {
  CMS_TENANT_GLOBAL_ROUTER_PATHS,
  CMS_TENANT_SECTION_ROUTES,
  ROUTER_PATHS,
} from '../../../../lib/utils/router-paths';
import { useMemo } from 'react';
import { QueryParams, Tabs } from '../../../../lib/types/queryParams';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../lib/store/hooks';

type HeaderDataResponse = { title: string; subtitle?: string; isPreviewButtonEnabled?: boolean };

type PagesHeaderDataT = Record<ROUTER_PATHS, HeaderDataResponse>;

export const useHeaderData = () => {
  const pathname = useLocation()?.pathname;
  const partPath = pathname?.split('/').slice(-1).join('');
  const { t } = useTranslation();

  const productData = useAppSelector(({ products }) => products.selectedProduct);
  const statusLevelData = useAppSelector(({ statusLevel }) => statusLevel.selectedStatusLevel);

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get(QueryParams.TAB);

  const checkIsProjectPage = () => {
    return [
      ...Object.values(CMS_TENANT_GLOBAL_ROUTER_PATHS),
      ...Object.values(CMS_TENANT_SECTION_ROUTES),
    ].find((routePath) => routePath === partPath);
  };

  const PAGES_HEADER_DATA: PagesHeaderDataT = useMemo(
    () => ({
      [ROUTER_PATHS.tenants]: { title: t('platform.sidebar.tenants') },
      [ROUTER_PATHS.users]: { title: t('platform.sidebar.users') },
      [ROUTER_PATHS.themes]: { title: t('platform.sidebar.themes') },
      [ROUTER_PATHS.intro]: {
        title: t('platform.sidebar.templates'),
        subtitle: t('platform.sidebar.templatesChildren.intro'),
      },
      [ROUTER_PATHS.chapter]: {
        title: t('platform.sidebar.templates'),
        subtitle: t('platform.sidebar.templatesChildren.chapter'),
      },
      [ROUTER_PATHS.content]: {
        title: t('platform.sidebar.templates'),
        subtitle: t('platform.sidebar.templatesChildren.content'),
      },
      [ROUTER_PATHS.question]: {
        title: t('platform.sidebar.templates'),
        subtitle: t('platform.sidebar.templatesChildren.question'),
      },
      [ROUTER_PATHS.product]: {
        title: t('platform.sidebar.templates'),
        subtitle: t('platform.sidebar.templatesChildren.product'),
      },
      [ROUTER_PATHS.result]: {
        title: t('platform.sidebar.templates'),
        subtitle: t('platform.sidebar.templatesChildren.result'),
      },
      [ROUTER_PATHS.contact]: {
        title: t('platform.sidebar.templates'),
        subtitle: t('platform.sidebar.templatesChildren.contact'),
      },
      [ROUTER_PATHS.generalSettings]: {
        title: t('platform.tenantSidebar.generalSettings'),
      },
    }),
    [t],
  ) as PagesHeaderDataT;

  const isProjectPage = checkIsProjectPage();

  const headerData = useMemo(() => {
    switch (tab) {
      case Tabs.DESIGN_SETTINGS:
        return { title: t('platform.tabs.designSettings'), isPreviewButtonEnabled: true };
      case Tabs.BRAND_KIT:
        return { title: t('platform.tabs.brandKit') };
      case Tabs.DEMAND_AREAS:
        return { title: t('platform.tabs.demandsAreas') };
      case Tabs.PRODUCT_CATEGORIES:
        return { title: t('platform.tabs.productCategories') };
      case Tabs.PRODUCTS:
        return { title: t('platform.tabs.products') };
      case Tabs.PRODUCT_CONFIGURATOR:
        return { title: t('platform.tabs.products'), subtitle: productData?.name };
      case Tabs.STATUS_LEVEL_CONFIGURATOR:
        return { title: t('platform.tabs.statusLevel'), subtitle: statusLevelData?.name };
      case Tabs.STATUS_LEVEL:
        return { title: t('platform.tabs.statusLevel') };
      case Tabs.BONUS_CATALOG:
        return { title: t('platform.tabs.bonusCatalog') };
    }

    if (partPath === ROUTER_PATHS.projects) {
      return {
        title: t('platform.sidebar.tenants'),
      };
    }

    return PAGES_HEADER_DATA[
      isProjectPage ? (partPath as ROUTER_PATHS) : (pathname?.slice(1) as ROUTER_PATHS)
    ];
  }, [
    PAGES_HEADER_DATA,
    isProjectPage,
    partPath,
    pathname,
    productData?.name,
    statusLevelData?.name,
    t,
    tab,
  ]);

  return headerData as HeaderDataResponse;
};
