import { Dispatch, FC } from 'react';
import { Divider, Space, Switch, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { FontColorFormItem } from '../../../../features/form-items/FontColorFormItem';
import { LogoCardFormItem } from '../LogoCardFormItem';
import { UploadMediaResponseT } from '../../../../lib/types/media';
import { NavigationFormItems } from '../NavigationFormItems';
import { AppCardBasic } from '../../../../components/AppCardBasic';
import { DesignSettingsDataT } from '../../types';

import styles from './index.module.scss';
import { useGetBrandKitColors } from '../../hooks/useGetBrandKitColors';

const designTexts = 'pages.designSettingsTab';
const { Text } = Typography;

type Props = {
  designSettings: DesignSettingsDataT;
  setDesignSettings: Dispatch<DesignSettingsDataT>;
};

export const HeaderCard: FC<Props> = ({ designSettings, setDesignSettings }) => {
  const { t } = useTranslation();
  const { headerConfig } = designSettings;

  const colors = useGetBrandKitColors();

  const fieldHandleChange = (fieldName: string, value: boolean | string | UploadMediaResponseT | null) =>
    setDesignSettings({ ...designSettings, headerConfig: { ...headerConfig, [fieldName]: value } });

  const selectFooterBackgroundColor = (value: boolean | string) => {
    if (value) {
      fieldHandleChange('backgroundColor', value);
    }
  };

  return (
    <AppCardBasic
      className={styles.root}
      title={
        <>
          <div className={styles.titleWrapper}>
            <Text className={styles.title}>{t(`${designTexts}.header`)}</Text>
          </div>
          <div>
            <Text type="secondary" className={styles.text}>
              {t(`${designTexts}.background`)}
            </Text>
            <Space size={20} className={styles.space}>
              <FontColorFormItem
                value={headerConfig.backgroundColor}
                onChange={selectFooterBackgroundColor}
                colors={colors}
                className={styles.colorFormSection}
                formItemProps={{ className: styles.colorFormItem }}
              />
              <div className={styles.switchWrapper}>
                <Switch
                  checked={headerConfig.isBackgroundShadow}
                  onChange={(checked) => fieldHandleChange('isBackgroundShadow', checked)}
                  size="small"
                />
                <Text>{t(`${designTexts}.shadow`)}</Text>
              </div>
            </Space>
          </div>
        </>
      }
    >
      <LogoCardFormItem
        logo={headerConfig.logo}
        afterUploadAction={(file: UploadMediaResponseT) => fieldHandleChange('logo', file)}
        onDeleteClick={() => fieldHandleChange('logo', null)}
      />
      <Divider />
      <NavigationFormItems />
    </AppCardBasic>
  );
};
