import { CSSProperties, forwardRef, ReactNode } from 'react';
import { Form, FormItemProps } from 'antd';

import styles from './index.module.scss';
import cx from 'classnames';

type Props = {
  children: ReactNode;
  hasFeedback: boolean;
  className?: string;
  style?: CSSProperties;
} & FormItemProps;

export const FormItem = forwardRef<Props, any>(({ children, className, style, ...rest }, ref) => {
  return (
    <Form.Item ref={ref} style={style} className={cx(styles.root, className)} {...rest}>
      {children}
    </Form.Item>
  );
});

FormItem.displayName = 'FormItem';
