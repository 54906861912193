import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import {
  createOptionItem,
  getParsedSelectedItems,
  getSelectedItemsLabels,
  getSelectedItemsValues,
} from '../../../lib/utils/arranges-helpers';
import { useState } from 'react';
import { useEnumArrange } from '../../tenants/hooks/useEnumArrange';
import { useStatusColumn } from '../../../features/table/helpers/useStatusColumn';
import { PROJECT_COLUMN, PROJECT_STATUS } from '../helpers/constants';
import { useProjectArrangeRequest } from './useProjectArrangeRequest';
import { useAppSelector } from '../../../lib/store/hooks';
import { ProjectStatusBodyCell } from '../components/ProjectStatusBodyCell';
import { isLength } from '../../../lib/utils/helpers';

const deactivated = 'shared.status.deactivated';
const notPublished = 'shared.status.notPublished';
const published = 'shared.status.published';

export const projectPublishingStatuses = [PROJECT_STATUS.PUBLISHED, PROJECT_STATUS.NOT_PUBLISHED];
const pseudoAllFilters = {};

/*
 Empty object here simulates "all statuses are selected" feature:
 as BE (back end) accepts only boolean value for "status" column,
 - one way to simulate ""all statuses are selected" - pass empty object to the filters :D
 */
export const useProjectStatusColumn = () => {
  const { t } = useTranslation();

  const { sorting, arrangeId } = useAppSelector(({ projects }) => projects);
  const [form] = Form.useForm();
  const isActiveArrange = arrangeId === PROJECT_COLUMN.STATUS;

  const getItemsLabels = () => getSelectedItemsLabels(form);
  const getItemsValues = () => getSelectedItemsValues(form);

  const [filterLabel, setFilterLabel] = useState('');

  const publishedLabel = t(published);
  const deactivatedLabel = t(deactivated);
  const notPublishedLabel = t(notPublished);

  const allOptions = [
    createOptionItem(notPublishedLabel, PROJECT_STATUS.NOT_PUBLISHED),
    createOptionItem(publishedLabel, PROJECT_STATUS.PUBLISHED),
    createOptionItem(deactivatedLabel, PROJECT_STATUS.DEACTIVATED),
  ];

  const { filtering } = useEnumArrange(allOptions);

  const getFilters = () => {
    const itemsValues = getItemsValues();
    const activated = isLength(itemsValues) ? !itemsValues.includes(PROJECT_STATUS.DEACTIVATED) : undefined;
    const selectedStatuses = itemsValues.filter((item: any) => projectPublishingStatuses.includes(item));
    const allStatusesAreSelected = selectedStatuses.length === projectPublishingStatuses.length;
    const status = allStatusesAreSelected ? null : selectedStatuses[0];
    return activated === false && allStatusesAreSelected ? pseudoAllFilters : { status, activated };
  };

  const { applyArranges, removeFilter, removeFilterValue } = useProjectArrangeRequest({
    arrangedId: PROJECT_COLUMN.STATUS,
    getFilterLabel: () => getItemsLabels().join(', '),
    getFilters,
    form,
    setFilterLabel,
    filterFieldName: 'includeStatuses',
    isParsedByValue: true,
  });

  return useStatusColumn({
    filtering,
    applyArranges,
    removeFilter,
    arrangedId: PROJECT_COLUMN.STATUS,
    isActiveArrange,
    form,
    filterLabel,
    sortOrder: sorting.sortOrder,
    getParsedItems: () => getParsedSelectedItems(form),
    removeFilterValue,
    columnProps: {
      dataIndex: PROJECT_COLUMN.IS_ACTIVE,
      render: (value, row: any) => {
        const status = value ? row.status : value;

        return <ProjectStatusBodyCell status={status} />;
      },
    },
  });
};
