import { FC } from 'react';
import cx from 'classnames';
import { Spinner } from '../../Spinner';
import styles from './index.module.scss';

type Props = {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  danger?: boolean;
  isLoading?: boolean;
};

export const DropdownMenuButton: FC<Props> = ({ text, danger, disabled, isLoading, ...rest }) => {
  return (
    <button
      className={cx(styles.button, {
        [styles.disabledButton]: disabled,
        [styles.danger]: danger,
      })}
      disabled={disabled}
      {...rest}
    >
      {text}

      <Spinner className={styles.spinner} show={isLoading} size={16} circleWidth={2} />
    </button>
  );
};
