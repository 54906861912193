import { message } from 'antd';
import { useTranslation } from 'react-i18next';

const BITES_NUMBER = 1024;

export function useBeforeUploadError(maxFileSize: number, errorText: string, excludedTypes: string[] = []) {
  const { t } = useTranslation();

  const [messageApi, contextHolder] = message.useMessage();

  const beforeUpload = (file: File) => {
    const checkFileSize = file.size < BITES_NUMBER * BITES_NUMBER * maxFileSize;
    if (!checkFileSize) {
      messageApi.open({
        type: 'error',
        content: t(errorText),
      });
    }

    if (excludedTypes.includes(file.type)) {
      messageApi.open({
        type: 'error',
        content: t('validation.fileFormat.svgError'),
      });
      return false;
    }

    return checkFileSize;
  };

  return { beforeUpload, contextHolder, messageApi };
}
