import { FC, useCallback, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { ReactComponent as IconCheck } from '../../../../assets/icons/checkmark-thin.svg';
import { useAppSelector } from '../../../../lib/store/hooks';
import styles from './index.module.scss';
import { IconT } from '../../../designSettings/types';
import { ColorVariants } from '../../../../lib/types/colors&fonts';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { useTranslation } from 'react-i18next';

const translation = 'endUser.shared';

type Props = {
  sortedSubLevels: any;
  userPoints: number;
  onTabChange: (selectedTab: any) => void;
  icon: IconT | undefined;
};

export const BonusesTabs: FC<Props> = ({ sortedSubLevels, userPoints, onTabChange, icon }) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const { brandKit } = useAppSelector(({ endUser }) => endUser);
  const { t } = useTranslation();

  const handleTabChange = useCallback(
    (index: string) => {
      const selectedTab = sortedSubLevels[Number(index)];

      setActiveTab(Number(index));
      onTabChange(selectedTab);
    },
    [onTabChange, sortedSubLevels],
  );

  useEffect(() => {
    const nextStatusLevel = sortedSubLevels.find((subLevel: any) => subLevel.points > userPoints);

    if (!nextStatusLevel) {
      const indexOfLastStatusLevel = sortedSubLevels.findIndex(
        (subLevel: any) => sortedSubLevels.slice(-1)[0].id === subLevel.id,
      );

      handleTabChange(indexOfLastStatusLevel);
    } else {
      const indexOfNextStatusLevel = sortedSubLevels.findIndex(
        (subLevel: any) => subLevel.id === nextStatusLevel.id,
      );

      handleTabChange(indexOfNextStatusLevel);
    }
  }, [handleTabChange, sortedSubLevels, userPoints]);

  return (
    <div className={styles.tabs}>
      <Tabs
        activeKey={String(activeTab) as string}
        onChange={handleTabChange}
        items={sortedSubLevels?.map((item: any, i: any) => {
          return {
            label: (
              <div className={styles.innerTab}>
                <p>
                  {item.statusLevelName} {item.points > 0 ? item.points : ''}
                  {item.points > 0 ? (
                    <img src={icon?.s3Url} alt="icon" style={{ width: '10px', height: '14px' }} />
                  ) : null}
                </p>
                {userPoints >= item.points ? (
                  <EndUserTypography
                    component="Text"
                    type="subText"
                    style={{
                      color: '#fff',
                      fontWeight: 400,
                      background: brandKit?.colors[ColorVariants.PRIMARY_COLOR],
                    }}
                    className={styles.categorieCardSuccessStatus}
                  >
                    <IconCheck style={{ width: 14, height: 12, stroke: '#ffffff' }} />
                    {t(`${translation}.covered`)}
                  </EndUserTypography>
                ) : (
                  <EndUserTypography
                    component="Text"
                    type="subText"
                    style={{
                      color: '#858899',
                      background: '#F7F7F8',
                      padding: '5px 8px',
                      fontWeight: 400,
                    }}
                  >
                    {t(`${translation}.uncovered`)}
                  </EndUserTypography>
                )}
              </div>
            ),
            key: String(i),
          };
        })}
      />
    </div>
  );
};
