import { FC } from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

type Props = {
  amount: number;
  className?: string;
};

export const AmountBodyCell: FC<Props> = ({ amount, className = '' }) => {
  return <Text className={className}>{typeof amount === 'number' ? amount.toString() : '-'}</Text>;
};
