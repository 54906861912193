import { FC, ReactNode } from 'react';
import { Modal as AntModal, ModalProps } from 'antd';
import { ReactComponent as CrossIcon } from '../../assets/icons/CrossIcon.svg';
import cx from 'classnames';
import styles from './index.module.scss';
import { EndUserButton } from '../EndUserButton';
import { EndUserTypography } from '../EndUserTypography';

const APP_MODAL_DEFAULT_WIDTH = 544; // px

type Props = {
  open: boolean;
  titleProps: { title?: string; subTitle?: string; className?: string };
  actionButtonProps?: { onClick: () => void; loading: boolean };
  cancelButtonProps?: { onClick: () => void };
  children: ReactNode;
  isFooterHidden?: boolean;
} & ModalProps;

export const EndUserModal: FC<Props> = (props) => {
  const {
    children,
    titleProps: { title, className, subTitle },
    cancelButtonProps,
    actionButtonProps,
    isFooterHidden,
    ...rest
  } = props;

  const titleComponent = title && (
    <EndUserTypography
      type="headlineThird"
      component="Title"
      level={3}
      className={cx(styles.title, className)}
    >
      {title}
    </EndUserTypography>
  );
  const closeIcon = <CrossIcon className="secondary_color" />;
  const footer = !isFooterHidden && (
    <div className={styles.footer}>
      <EndUserButton {...cancelButtonProps} type="secondary">
        {props.cancelText}
      </EndUserButton>
      <EndUserButton {...actionButtonProps} type="primary">
        {props.okText}
      </EndUserButton>
    </div>
  );

  return (
    <AntModal
      className={styles.root}
      centered
      destroyOnClose
      title={titleComponent}
      footer={footer}
      closeIcon={closeIcon}
      width={APP_MODAL_DEFAULT_WIDTH}
      onCancel={cancelButtonProps?.onClick}
      {...rest}
    >
      {subTitle && (
        <EndUserTypography type="headlineFifth" level={5} component="Title">
          {subTitle}
        </EndUserTypography>
      )}

      {children}
    </AntModal>
  );
};
