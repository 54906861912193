import { BonusT } from '../../../lib/types/statusLevel';

export interface BonusWithCoastT extends BonusT {
  points: number;
  subLevelName: string;
}

export enum BONUSES_CARDS_WIDTH {
  LG = 301,
  MD = 291,
  SM = 263,
}
