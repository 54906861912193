import { checkIsManager, checkIsSuperAdmin, Role } from '../../../../lib/utils/roles';
import { FormItemName } from '../../../../lib/models/Form';
import { Form } from 'antd';
import { useEffect } from 'react';
import { FormItem } from '../../../../components/FormItem';
import { AppCheckbox } from '../../../../components/AppCheckox';
import { useTranslation } from 'react-i18next';

const NAME = FormItemName.INCLUDE_ALL_CURRENT_AND_FUTURE_TENANTS;

export const IncludeFutureTenants = () => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const rights = Form.useWatch(FormItemName.RIGHTS, form)?.value;

  const show = checkIsManager(rights);
  const isSuperAdmin = checkIsSuperAdmin(rights);

  const isIncludeAllTenants =
    Form.useWatch(FormItemName.INCLUDE_ALL_CURRENT_AND_FUTURE_TENANTS, form) && rights !== Role.ADMIN;

  useEffect(() => {
    form.setFieldValue(NAME, isSuperAdmin);
  }, [isSuperAdmin]);

  return (
    <FormItem
      valuePropName="checked"
      name={NAME}
      hasFeedback={true}
      style={{
        marginBottom: '-30px',
        ...(!show && { display: 'none' }),
      }}
    >
      <AppCheckbox
        checked
        isDisabled={isIncludeAllTenants}
        label={t('pages.userManagement.formItems.selectAllTenants')}
      />
    </FormItem>
  );
};
