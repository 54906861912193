import { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from './Header';
import { DesignSettingsDataT } from '../../../modules/designSettings/types';
import { Footer } from './Footer';
import { SmallStatusLevelSection } from '../../../modules/endUserStatusLevel/components/SmallStatusLevelSection';
import { useGetEndUserData } from '../../../lib/hooks/endUserHooks/useGetEndUserData';
import { useCheckStatusBarVisibility } from '../../../lib/hooks/endUserHooks/useCheckStatusBarVisibility';
import { useContainerQuery } from 'react-container-query';
import { CONTAINER_QUERIES } from '../../../lib/utils/constants';
import { useAppDispatch } from '../../../lib/store/hooks';
import { setContainerQueries } from '../../../lib/store/slices/containerQueriesSlice';
import { ScrollRefresh } from '../../components/ScrollRefresh';
import { PageLoader } from '../../../components/PageLoader';

import styles from './index.module.scss';

export const EndUserFlowLayout: FC = () => {
  const { isLoading, parsedData } = useGetEndUserData();
  const { isStatusBarHidden } = useCheckStatusBarVisibility();
  const [params, containerRef] = useContainerQuery(CONTAINER_QUERIES, {});

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setContainerQueries(params));

    document?.getElementById('root')?.classList.add('container-type');
  }, [dispatch, params]);

  if (isLoading)
    return (
      <div style={{ flex: 1 }}>
        <PageLoader />
      </div>
    );

  const statusLevelSection = !isStatusBarHidden && (
    <div className={styles.statusLevelContainer}>
      <SmallStatusLevelSection />
    </div>
  );

  return (
    <>
      <div className={styles.root} ref={containerRef}>
        <Header headerConfig={parsedData?.headerConfig as DesignSettingsDataT['headerConfig']} />
        <main className={styles.main}>
          <Outlet />
        </main>
        <Footer footerConfig={parsedData?.footerConfig as DesignSettingsDataT['footerConfig']} />
        {statusLevelSection}
      </div>
      <ScrollRefresh />
    </>
  );
};
