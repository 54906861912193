import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormItemName } from '../../../../lib/models/Form';
import { FormItem } from '../../../../components/FormItem';
import { FormInput } from '../../../../components/FormInput';

const label = 'pages.products.productCategories.formItems.name.label';
const errors = 'pages.products.productCategories.formItems.name.errors';

export const NameFormItem: FC = () => {
  const { t } = useTranslation();

  return (
    <FormItem
      name={FormItemName.NAME}
      hasFeedback
      rules={[
        {
          required: true,
          message: t(`${errors}.empty`),
        },
        { max: 100 },
      ]}
    >
      <FormInput label={t(label)} />
    </FormItem>
  );
};
