import { SVGProps } from 'react';

export function InfoIcon(props: SVGProps<any>) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2105_172248)">
        <path d="M8 15.5C6.14348 15.5 4.36301 14.7625 3.05025 13.4497C1.7375 12.137 1 10.3565 1 8.5C1 6.64348 1.7375 4.86301 3.05025 3.55025C4.36301 2.2375 6.14348 1.5 8 1.5C9.85652 1.5 11.637 2.2375 12.9497 3.55025C14.2625 4.86301 15 6.64348 15 8.5C15 10.3565 14.2625 12.137 12.9497 13.4497C11.637 14.7625 9.85652 15.5 8 15.5ZM8 16.5C10.1217 16.5 12.1566 15.6571 13.6569 14.1569C15.1571 12.6566 16 10.6217 16 8.5C16 6.37827 15.1571 4.34344 13.6569 2.84315C12.1566 1.34285 10.1217 0.5 8 0.5C5.87827 0.5 3.84344 1.34285 2.34315 2.84315C0.842855 4.34344 0 6.37827 0 8.5C0 10.6217 0.842855 12.6566 2.34315 14.1569C3.84344 15.6571 5.87827 16.5 8 16.5Z" />
        <path d="M8.92995 7.088L6.63995 7.375L6.55795 7.755L7.00795 7.838C7.30195 7.908 7.35995 8.014 7.29595 8.307L6.55795 11.775C6.36395 12.672 6.66295 13.094 7.36595 13.094C7.91095 13.094 8.54395 12.842 8.83095 12.496L8.91895 12.08C8.71895 12.256 8.42695 12.326 8.23295 12.326C7.95795 12.326 7.85795 12.133 7.92895 11.793L8.92995 7.088ZM8.99995 5C8.99995 5.26522 8.8946 5.51957 8.70706 5.70711C8.51952 5.89464 8.26517 6 7.99995 6C7.73474 6 7.48038 5.89464 7.29285 5.70711C7.10531 5.51957 6.99995 5.26522 6.99995 5C6.99995 4.73478 7.10531 4.48043 7.29285 4.29289C7.48038 4.10536 7.73474 4 7.99995 4C8.26517 4 8.51952 4.10536 8.70706 4.29289C8.8946 4.48043 8.99995 4.73478 8.99995 5Z" />
      </g>
      <defs>
        <clipPath id="clip0_2105_172248">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
