import { CSSProperties, FC, ReactNode } from 'react';
import { ButtonProps, Button } from 'antd';

import cn from 'classnames';

import styles from './index.module.scss';

type Props = {
  children: ReactNode;
  size?: 'large' | 'small';
  containerStyle?: CSSProperties;
  buttonStyle?: CSSProperties;
  className?: string;
} & ButtonProps;

export const ButtonLink: FC<Props> = ({
  children,
  size = 'small',
  containerStyle,
  buttonStyle,
  className,
  ...rest
}) => {
  return (
    <div style={containerStyle} className={styles.buttonWrapper}>
      <Button
        style={buttonStyle}
        className={cn(styles.button, styles[size], className)}
        type="link"
        {...rest}
      >
        {children}
      </Button>
    </div>
  );
};
