import { CSSProperties } from 'react';

type Props = {
  isMulti?: boolean;
  selectProps: {
    size: string;
    equalRightBorder: string;
  };
  isDisabled?: boolean;
  isFocused?: boolean;
};

export const createStyles = ({ isError }: { isError: boolean }) => ({
  menu: (baseStyles: CSSProperties, props: Props) => ({
    ...baseStyles,
    zIndex: 100,
    padding: props.isMulti ? '0 0 0 16px' : '0',
    border: '1px solid #5C5C6E',
    boxShadow: 'none',
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: '#FFFFFF',

    ...(props.selectProps.size === 'small' && {
      '&, *': {
        fontSize: '12px',
      },
    }),
  }),
  menuList: (baseStyles: CSSProperties, props: Props) => ({
    ...baseStyles,
    padding: props.isMulti ? '16px 16px 16px 0' : '8px 0',
    maxHeight: '250px',
  }),
  control: (baseStyles: CSSProperties, props: Props) => ({
    ...baseStyles,
    ...resetFieldStyles,
    pointerEvents: props.isDisabled ? 'none' : 'initial',
    cursor: 'text',
    background: props.isDisabled ? '#F2F2F3' : 'initial',
    minHeight: props.selectProps.size === 'small' ? '24px' : '40px',
    height: '100%',
    ...(props.selectProps.size === 'small' && {
      '&, *': {
        fontSize: '12px',
      },
    }),
    '&:before': {
      ...initialFieldBeforeStyles,
      ...(props.isFocused && focusedFieldBeforeStyles),
      ...(isError && errorFieldBeforeStyles),
      ...(props.selectProps.equalRightBorder && equalRightBorderStyles),
    },
    '&:hover:before': hoverFieldBeforeStyles,
  }),

  input: (baseStyles: CSSProperties, props: Props) => ({
    paddingLeft: props.isMulti ? '10px' : 'initial',
    position: 'absolute',
    input: { cursor: props.isDisabled ? 'not-allowed' : 'text' },
  }),
  placeholder: (baseStyles: CSSProperties, props: Props) => ({
    ...baseStyles,
    margin: 0,
    paddingLeft: props?.isMulti ? '10px' : 'unset',
  }),
  container: (baseStyles: CSSProperties, props: Props) => ({
    ...baseStyles,
    pointerEvents: 'unset',
    cursor: props.isDisabled ? 'not-allowed' : 'initial',
  }),
});

export const baseBorderStyles = {
  borderWidth: '1px !important',
  borderStyle: 'solid',
  borderColor: '#5C5C6E !important',
  borderRadius: '8px',
};
export const equalRightBorderStyles = {
  borderBottomRightRadius: 0,
  borderTopRightRadius: 0,
};

export const resetFieldStyles = {
  border: 'none !important',
  borderRadius: '7.5px',
  boxShadow: 'none !important',
  position: 'relative',
};

export const initialFieldBeforeStyles = {
  transition: 'all 0.1s',
  position: 'absolute',
  content: '""',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  visibility: 'visible',
  width: '100%',
  zIndex: 0,
  height: '100%',
  ...baseBorderStyles,
};

export const hoverFieldBeforeStyles = {
  transition: 'all 0.1s',
  borderColor: '#33BDCD !important',
  borderWidth: '2px !important',
};

export const focusedFieldBeforeStyles = {
  borderColor: '#0092A3 !important',
};

export const errorFieldBeforeStyles = {
  borderColor: '#FF4949 !important',
};
