import { FC } from 'react';
import { Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDeleteTenantMutation } from '../../../../lib/store/api/tenants';
import { TenantT } from '../../../../lib/types/tenants';
import { Modal } from '../../../../components/Modal';
import { useResetFormOnCloseModal } from '../../../../lib/hooks/useResetFormOnCloseModal';
import { FormItemName } from '../../../../lib/models/Form';
import { DeleteTenantForm } from './DeleteTenantForm';

const title = 'pages.tenants.deleteModal.title';

type Props = {
  isOpen: boolean;
  close: () => void;
  tenantData: TenantT | null;
};

export const DeleteTenantModal: FC<Props> = ({ close, isOpen, tenantData }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const tenantName = Form.useWatch(FormItemName.TENANT_NAME, form);
  const [messageApi, contextHolder] = message.useMessage();

  const [deleteTenant, { isLoading }] = useDeleteTenantMutation();

  useResetFormOnCloseModal({ form, isOpen });

  return (
    <>
      {contextHolder}
      <Modal
        open={isOpen}
        okText={t('generic.delete')}
        titleProps={{
          title: t(title),
        }}
        cancelButtonProps={{ onClick: close }}
        actionButtonProps={{
          onClick: form.submit,
          loading: isLoading,
          disabled: !(tenantName === tenantData?.name),
        }}
      >
        {isOpen && (
          <DeleteTenantForm
            form={form}
            messageApi={messageApi}
            tenantData={tenantData}
            tenantName={tenantName}
            onSubmit={deleteTenant}
          />
        )}
      </Modal>
    </>
  );
};
