import { FormInstance } from 'antd';
import { ProjectT } from '../../../lib/types/entities';
import React, { useEffect } from 'react';
import { FormItemName } from '../../../lib/models/Form';
import { IconT } from '../../designSettings/types';
import { useUpdateProjectMutation } from '../../../lib/store/api/project';

type Props = {
  form: FormInstance;
  projectData?: ProjectT;
  isInit: React.MutableRefObject<boolean>;
  iconsList?: IconT[];
  tenantId?: string;
};
export const useStatusLevelInitialOptions = (props: Props) => {
  const { form, isInit, projectData, iconsList, tenantId } = props;
  const [updateProject] = useUpdateProjectMutation();

  useEffect(() => {
    if (projectData && isInit && isInit.current) {
      form.setFieldsValue({
        [FormItemName.STATUS_LEVEL_UNIT]: projectData?.pointUnits || 'Punkte',
        [FormItemName.STATUS_LEVEL_ICON]: {
          value:
            projectData.pointsIcon?.id ||
            iconsList?.find((icon) => icon.originalFileName === 'star-fill')?.id,
        },
        [FormItemName.STATUS_LEVEL_MAX_POINTS]: projectData?.maxPoints || '100',
      });

      if (!projectData.maxPoints || !projectData.pointsIcon || !projectData.pointUnits) {
        updateProject({
          ...projectData,
          tenantId: String(tenantId),
          pointUnits: projectData.pointUnits || 'Punkte',
          maxPoints: projectData.maxPoints || '100',
          pointsIcon:
            projectData.pointsIcon || iconsList?.find((icon) => icon.originalFileName === 'star-fill'),
        });
      }

      isInit.current = false;
    }
  }, [form, iconsList, projectData, tenantId, updateProject]);
};
