import { useParams, useSearchParams } from 'react-router-dom';
import styles from './index.module.scss';
import { AppTabs } from '../../components/AppTabs';
import { Button, message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from './../../assets/icons/PlusIcon.svg';
import { useTabQuery } from '../../lib/hooks/useTabQuery';
import { DemandAreas } from '../../modules/demandAreas/components/DemandAreas';
import { useAppDispatch } from '../../lib/store/hooks';
import { setModalOpened } from '../../lib/store/slices/demandAreasSlice';
import { setModalOpened as setProductCategoryModalOpened } from '../../lib/store/slices/productCategoriesSlice';
import { ModalActions } from '../../lib/models/Modal';
import { ProductCategoriesTab } from '../../modules/productCategories/components/ProductCategoriesTab';
import { ProductsTab } from '../../modules/products/components/ProductsTab';
import { ProductConfigurator } from '../../modules/productConfigurator';
import { QueryParams, Tabs } from '../../lib/types/queryParams';
import { ProductBadgesTab } from '../../modules/productBadges/components/ProductBadgesTab';

const productsT = 'pages.products';

export const ProductsPage = () => {
  const { id: tenantId, modelId } = useParams();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const queryTab = searchParams.get(QueryParams.TAB);
  const [messageApi, contextHolder] = message.useMessage();

  const [activeKey, setActiveKey] = useState<Tabs | null>(null);
  useTabQuery(activeKey as string);

  useEffect(() => {
    setActiveKey(queryTab ? (queryTab as Tabs) : Tabs.DEMAND_AREAS);
  }, [queryTab]);

  const handleTabChange = (key: string) => {
    setActiveKey(key as Tabs);
  };

  const items = [
    {
      label: t(`${productsT}.tabs.demandAreas`),
      children:
        activeKey === Tabs.DEMAND_AREAS ? (
          <DemandAreas tenantId={tenantId as string} modelId={modelId as string} />
        ) : null,
      key: Tabs.DEMAND_AREAS,
    },
    {
      label: t(`${productsT}.tabs.productCategories`),
      children:
        activeKey === Tabs.PRODUCT_CATEGORIES ? (
          <ProductCategoriesTab handleTabChange={handleTabChange} />
        ) : null,
      key: Tabs.PRODUCT_CATEGORIES,
    },
    {
      label: t(`${productsT}.tabs.products`),
      children: activeKey === Tabs.PRODUCTS ? <ProductsTab /> : null,
      key: Tabs.PRODUCTS,
    },
    {
      label: t(`${productsT}.tabs.badges`),
      children: activeKey === Tabs.BADGES ? <ProductBadgesTab /> : null,
      key: Tabs.BADGES,
    },
  ];

  const onOpenAddDemandArea = () => {
    dispatch(setModalOpened(ModalActions.ADD));
  };
  const onOpenAddProductCategory = () => {
    dispatch(setProductCategoryModalOpened(ModalActions.ADD));
  };

  const onOpenAddProduct = () => {
    setActiveKey(Tabs.PRODUCT_CONFIGURATOR);
  };

  return (
    <div className={styles.root}>
      {contextHolder}
      {queryTab === Tabs.PRODUCT_CONFIGURATOR ? (
        <ProductConfigurator messageApi={messageApi} />
      ) : (
        <AppTabs
          activeKey={activeKey as string}
          onChange={handleTabChange}
          tabBarExtraContent={{
            right: (
              <>
                {activeKey === 'demandAreas' && (
                  <div className={styles.buttonWrapper}>
                    <Button onClick={onOpenAddDemandArea} type="default" className={styles.btn}>
                      <PlusIcon className={styles.addIcon} />
                      <div>{t(`${productsT}.demandArea.addDemandArea`)}</div>
                    </Button>
                  </div>
                )}
                {activeKey === 'productCategories' && (
                  <div className={styles.buttonWrapper}>
                    <Button onClick={onOpenAddProductCategory} type="default" className={styles.btn}>
                      <PlusIcon className={styles.addIcon} />
                      <div>{t(`${productsT}.productCategories.addProductCategory`)}</div>
                    </Button>
                  </div>
                )}
                {activeKey === 'products' && (
                  <div className={styles.buttons}>
                    {/*<div className={styles.buttonWrapper}>*/}
                    {/*  <Button type="default" className={styles.btn}>*/}
                    {/*    <UploadIcon className={styles.uploadIcon} />*/}
                    {/*    <div>{t(productsT.products.uploadDataTable)}</div>*/}
                    {/*  </Button>*/}
                    {/*</div>*/}
                    <div className={styles.buttonWrapper}>
                      <Button onClick={onOpenAddProduct} type="default" className={styles.btn}>
                        <PlusIcon className={styles.addIcon} />
                        <div>{t(`${productsT}.products.addProduct`)}</div>
                      </Button>
                    </div>
                  </div>
                )}
              </>
            ),
          }}
          items={items}
        />
      )}
    </div>
  );
};
