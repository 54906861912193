import { useState } from 'react';
import { SectionName } from '../../lib/models/Section';
import { useAppDispatch, useAppSelector } from '../../lib/store/hooks';
import { SmallBonusSection } from '../../modules/endUserBonuses/components/SmallBonusSection';
import { StatusLevelWishlistSection } from '../../modules/endUserStatusLevel/components/SmallStatusLevelWishlistSection';
import { EndUserWishlistHouseModel } from '../../modules/endUserWishlistHouseModel/components/EndUserWishlistHouseModel';
import { EndUserWishlistProductDetails } from '../../modules/endUserWishlistProductDetails/components/EndUserWishlistProductDetails';
import { ProductT } from '../../lib/types/product';
import { EndUserContactFormModal } from '../../modules/endUserContactForm/components/ContactFormModal';
import { sendDataToAnalytics } from '../../lib/utils/sendDataToAnalytics';
import { EndUserCongratulationModal } from '../../modules/endUserCongratulationModal/components/CongratulationModalWishList';
import { setNotificationStatusLevel } from '../../lib/store/slices/endUserSlice';
import { EndUserWishlistTiles } from '../../modules/EndUserWishlistTiles';

export const EndUserWishlistPage = () => {
  const { sections, wishlistProducts, statusLevelNotification, tenantAndProject } = useAppSelector(
    ({ endUser }) => endUser,
  );
  const currentSectionContent = sections?.[SectionName.WISHLIST];
  const dispatch = useAppDispatch();

  const [selectedProduct, setSelectedProduct] = useState<ProductT | null>(null);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const applyNewDesign =
    tenantAndProject?.subDomain === 'demo-company' && tenantAndProject?.tenantId === 11289;

  const handleCloseContactModal = () => {
    sendDataToAnalytics('Wishlist product details section', 'Close product contact form');

    setIsContactModalOpen(false);
    setSelectedProduct(null);
  };

  const handleCloseCongratulationModal = () => {
    dispatch(setNotificationStatusLevel(null));
  };

  return (
    <>
      {applyNewDesign ? (
        <EndUserWishlistTiles content={currentSectionContent} />
      ) : (
        <EndUserWishlistHouseModel content={currentSectionContent} />
      )}
      <StatusLevelWishlistSection />
      <SmallBonusSection />
      <EndUserWishlistProductDetails
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        setIsContactModalOpen={setIsContactModalOpen}
      />
      <EndUserContactFormModal
        products={selectedProduct ? [selectedProduct] : wishlistProducts}
        isOpen={isContactModalOpen}
        onClose={handleCloseContactModal}
      />
      <EndUserCongratulationModal
        isOpen={Boolean(statusLevelNotification)}
        onClose={handleCloseCongratulationModal}
      />
    </>
  );
};
