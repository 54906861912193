import { FormItemName } from '../../../lib/models/Form';
import { ProductCategoryT } from '../../../lib/types/entities';
import { UploadMediaResponseT } from '../../../lib/types/media';
import { ProductCategoryPayloadDataT } from '../../../lib/types/productCategories';
import { IconT } from '../../designSettings/types';
import { ProductCategoriesModalFormValuesT } from '../types';
import { Visibility } from '../../../lib/types/product';

export const capitalizeFirstLetter = (str: string) => {
  if (typeof str !== 'string' || str.length === 0) {
    return str;
  }

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export function prepareProductCategoryForm(
  formValues: ProductCategoriesModalFormValuesT,
  iconsList?: IconT[],
): Omit<ProductCategoryPayloadDataT, 'projectId'> {
  const { demandArea, priority, selectionType, icon, ...rest } = formValues;
  const selectedIcon = iconsList?.find((item) => item.id === icon?.value);

  return {
    demandAreaId: demandArea.value,
    selectionType: selectionType.value,
    priority: { number: priority.value },
    icon: selectedIcon as UploadMediaResponseT, // TODO mb replace with IconT
    ...rest,
  };
}

export const getFieldsData = (productCategory: ProductCategoryT) => {
  return [
    { name: FormItemName.TENANT_NAME, value: productCategory.name },
    {
      name: FormItemName.DEMAND_AREA,
      value: productCategory.visibility === Visibility.NEVER ? '' : { value: productCategory.demandAreaId },
    },
    {
      name: FormItemName.PRIORITY,
      value:
        productCategory.visibility === Visibility.NEVER
          ? ''
          : { value: productCategory.priority?.number || 0 },
    },
    { name: FormItemName.DESCRIPTION, value: productCategory.description },
    {
      name: FormItemName.SELECTION_TYPE,
      value: { value: productCategory.selectionType },
    },
    { name: FormItemName.ICON, value: { value: productCategory?.icon?.id } },
    { name: FormItemName.VISIBILITY, value: productCategory.visibility },
    { name: FormItemName.FINDER_ENABLED, value: productCategory.finderEnabled },
    { name: FormItemName.FINDER_BUTTON_NAME, value: productCategory.finderButtonName },
    { name: FormItemName.FINDER_URL, value: productCategory.finderUrl },
  ];
};
