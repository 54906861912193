import { useTranslation } from 'react-i18next';

export type InitialCheckingArrayT = {
  name: string;
  value: boolean;
  errorText: string;
}[];

export const useSetupPasswordTranslatedHints = () => {
  const { t } = useTranslation();

  const initialCheckingArray = [
    {
      name: t('pages.setUpPassword.form.passwordHints.uppercase'),
      value: false,
      errorText: 'pages.setUpPassword.errors.uppercase',
    },
    {
      name: t('pages.setUpPassword.form.passwordHints.lowercase'),
      value: false,
      errorText: 'pages.setUpPassword.errors.lowercase',
    },
    {
      name: t('pages.setUpPassword.form.passwordHints.number'),
      value: false,
      errorText: 'pages.setUpPassword.errors.number',
    },
    {
      name: t('pages.setUpPassword.form.passwordHints.length'),
      value: false,
      errorText: 'pages.setUpPassword.errors.length',
    },
    {
      name: t('pages.setUpPassword.form.passwordHints.special'),
      value: false,
      errorText: 'pages.setUpPassword.errors.special',
    },
  ];

  return { initialCheckingArray };
};
