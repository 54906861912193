import React, { FC } from 'react';
import { Category, DemandArea } from '../../../../lib/types/endUser';

import { ReactComponent as HouseTopLeftFrame } from '../../../../assets/icons/house-top-left-frame.svg';
import { ReactComponent as HouseTopRightFrame } from '../../../../assets/icons/house-top-right-frame.svg';

import styles from './index.module.scss';
import cn from 'classnames';
import { parseJSON, sortCategoriesByPriority } from '../../../../lib/utils/helpers';
import { checkBoughtProductsInCategory } from '../../../../lib/utils/end-user-helpers';
import { IconComponent } from '../../../../components/IconComponent';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { Button } from 'antd';

type Props = {
  demandAreas?: DemandArea[];
  handleAreaClick?: any;
  handleKeyDown?: any;
  handleCategoryClick?: (areaId: number, category: Category) => void;
  showButton?: boolean;
  buttonColor?: string;
  buttonText?: string;
  fillAllCategories?: boolean;
};

export const NewHouseModelDesktop: FC<Props> = ({
  demandAreas,
  handleAreaClick,
  handleKeyDown,
  handleCategoryClick,
  showButton,
  buttonColor,
  buttonText,
  fillAllCategories,
}) => {
  return (
    <>
      {showButton && (
        <Button
          style={{ backgroundColor: `linear-gradient(180deg, ${buttonColor} 0%, #007829 100%)` }}
          className={styles.circleButton}
        >
          {buttonText}
        </Button>
      )}
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.houseTop}>
            <div
              style={{
                position: 'absolute',
                width: '55%',
                height: 5,
                backgroundColor: 'white',
                transform: 'rotate(330deg)',
                top: 80,
                zIndex: 100,
                left: -20,
              }}
            />

            <div
              style={{
                position: 'absolute',
                width: '55%',
                height: 5,
                backgroundColor: 'white',
                transform: 'rotate(210deg)',
                top: 80,
                zIndex: 100,
                right: -20,
              }}
            />

            {demandAreas?.slice(0, 2).map((area, index) => {
              const isLeft = index === 0;
              const areaColor = parseJSON(area.color);
              const sortedCategories = sortCategoriesByPriority(area.categories);

              return (
                <div
                  role="presentation"
                  onClick={() => handleAreaClick(area.id, area.name)}
                  onKeyDown={(event) => handleKeyDown(event, area.id, area.name)}
                  key={area.id}
                  className={cn(isLeft ? styles.houseTopLeftSide : styles.houseTopRightSide)}
                >
                  {isLeft ? (
                    <HouseTopLeftFrame fill={areaColor.value} className={styles.frame} />
                  ) : (
                    <HouseTopRightFrame fill={areaColor.value} className={styles.frame} />
                  )}

                  <div className={styles.categoriesContainer}>
                    {sortedCategories.map((category) => {
                      const hasUserProducts = fillAllCategories
                        ? fillAllCategories
                        : checkBoughtProductsInCategory(category);

                      return (
                        <div
                          role="presentation"
                          onClick={(e) => {
                            e.stopPropagation();

                            handleCategoryClick && handleCategoryClick(area.id, category);
                          }}
                          key={category.id}
                          className={cn(styles.categoryItem)}
                          style={{
                            background: hasUserProducts
                              ? `color-mix(in srgb, ${areaColor.value}, rgba(255, 255, 255, 0.4))`
                              : category.isAddedToWishlist
                                ? `repeating-linear-gradient(-45deg, ${areaColor.value}, ${areaColor.value} 5px, rgba(255, 255, 255, 0.40) 5px, rgba(255, 255, 255, 0.40) 10px)`
                                : 'linear-gradient(180deg, #FFF 4.5%, rgba(255, 255, 255, 0.90) 25%, rgba(255, 255, 255, 0.80) 50%, rgba(255, 255, 255, 0.50) 100%)',
                          }}
                        >
                          <IconComponent
                            className={styles.icon}
                            iconData={category?.icon}
                            color={hasUserProducts || category.isAddedToWishlist ? '#fff' : areaColor.value}
                          />
                          <EndUserTypography
                            type="subText"
                            component="Text"
                            className={styles.title}
                            style={{
                              color: category.isAddedToWishlist || hasUserProducts ? '#fff' : areaColor.value,
                              textAlign: 'center',
                              minHeight: '40px',
                            }}
                          >
                            {category.name}
                          </EndUserTypography>
                        </div>
                      );
                    })}
                  </div>
                  <EndUserTypography
                    type="headlineFourth"
                    component="Title"
                    className={styles.areaTitle}
                    style={{
                      color: '#fff',
                    }}
                  >
                    {area.name}
                  </EndUserTypography>
                </div>
              );
            })}
          </div>

          <div className={styles.houseBottom}>
            {demandAreas?.slice(2).map((area, index) => {
              const isLeft = index === 0;
              const areaColor = parseJSON(area.color);
              const sortedCategories = sortCategoriesByPriority(area.categories);

              return (
                <div
                  role="presentation"
                  onClick={() => handleAreaClick(area.id, area.name)}
                  onKeyDown={(event) => handleKeyDown(event, area.id, area.name)}
                  key={area.id}
                  style={{ backgroundColor: areaColor.value }}
                  className={cn(isLeft ? styles.houseBottomLeftSide : styles.houseBottomRightSide)}
                >
                  <div className={styles.categoriesContainer}>
                    {sortedCategories.map((category) => {
                      const hasUserProducts = fillAllCategories
                        ? fillAllCategories
                        : checkBoughtProductsInCategory(category);

                      return (
                        <div
                          role="presentation"
                          onClick={(e) => {
                            e.stopPropagation();

                            handleCategoryClick && handleCategoryClick(area.id, category);
                          }}
                          key={category.id}
                          className={cn(styles.categoryItemBottom)}
                          style={{
                            background: hasUserProducts
                              ? `color-mix(in srgb, ${areaColor.value}, rgba(255, 255, 255, 0.4))`
                              : category.isAddedToWishlist
                                ? `repeating-linear-gradient(-45deg, ${areaColor.value}, ${areaColor.value} 5px, rgba(255, 255, 255, 0.40) 5px, rgba(255, 255, 255, 0.40) 10px)`
                                : 'linear-gradient(180deg, #FFF 4.5%, rgba(255, 255, 255, 0.90) 25%, rgba(255, 255, 255, 0.80) 50%, rgba(255, 255, 255, 0.50) 100%)',
                          }}
                        >
                          <IconComponent
                            className={styles.icon}
                            iconData={category?.icon}
                            color={hasUserProducts || category.isAddedToWishlist ? '#fff' : areaColor.value}
                          />
                          <EndUserTypography
                            type="subText"
                            component="Text"
                            className={styles.title}
                            style={{
                              color: category.isAddedToWishlist || hasUserProducts ? '#fff' : areaColor.value,
                              textAlign: 'center',
                              minHeight: '40px',
                            }}
                          >
                            {category.name}
                          </EndUserTypography>
                        </div>
                      );
                    })}
                  </div>

                  <EndUserTypography
                    type="headlineFourth"
                    component="Title"
                    className={styles.areaTitle}
                    style={{
                      color: '#fff',
                    }}
                  >
                    {area.name}
                  </EndUserTypography>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
