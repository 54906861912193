import { useAppSelector } from '../../lib/store/hooks';
import { SectionName } from '../../lib/models/Section';
import { useContainerQuery } from '../../lib/hooks/endUserHooks/useContainerQuery';
import { EndUserDemandAreaTilesDesktop } from './components/EndUserDemandAreaTilesDesktop';
import { EndUserDemandAreaTilesMobile } from './components/endUserDemandAreaTilesMobile';

export const EndUserDemandAreaTiles = () => {
  const { sections, demandArea } = useAppSelector(({ endUser }) => endUser);
  const currentSectionContent = sections?.[SectionName.HOUSE_MODEL];

  const { isMobile } = useContainerQuery();

  const demandAreas = demandArea?.listDemandArea;

  return (
    <>
      {isMobile ? (
        <EndUserDemandAreaTilesMobile demandAreas={demandAreas} content={currentSectionContent} />
      ) : (
        <EndUserDemandAreaTilesDesktop demandAreas={demandAreas} content={currentSectionContent} />
      )}
    </>
  );
};
