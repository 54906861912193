import { FC } from 'react';
import { Divider as AntdDivider } from 'antd';
import cx from 'classnames';

import styles from './index.module.scss';

type Props = {
  innerProps?: any;
  type?: string;
  className?: string;
};

export const Divider: FC<Props> = ({
  type = 'horizontal',
  innerProps,
  className,
  ...rest
}) => {
  return (
    <div
      className={cx(styles.wrapper, className, {
        [styles.vertical]: type !== 'horizontal',
      })}
      {...rest}
    >
      <AntdDivider {...innerProps} />
    </div>
  );
};
