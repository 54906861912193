export enum TENANT_COLUMN {
  PROJECTS_AMOUNT = 'projectsTotal',
  ASSIGNED_USERS = 'users',
  IS_ACTIVE = 'activated',
  NAME = 'name',
  CREATED_BY = 'createdBy',
  COMMENT = 'comment',
  BRAND_KIT = 'brandKit',
  CREATION_DATE = 'createdDate',
  ADDRESS = 'clientAddress',
  PHONE = 'clientPhone',
  EMAIL = 'clientEmail',
  LOGO = 'logo',
}

export const TenantSortField = {
  ACTIVATED: 'activated',
};

export const TenantStatus = {
  ACTIVATED: true,
  DEACTIVATED: false,
};

export const MAX_FILE_SIZE_TENANT_LOGO = 0.5;

export const initialMediaTenantState = {
  cdnUrl: '',
  createdBy: null,
  createdDate: '',
  id: '',
  lastModifiedBy: '',
  lastModifiedDate: '',
  originalFileName: '',
  s3Url: '',
  svgContent: '',
  targetId: '',
  targetType: null,
};
