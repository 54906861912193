import React, { FC, useState } from 'react';
import { Footer as AntFooter } from 'antd/es/layout/layout';
import { FooterConfigT } from '../../../../modules/designSettings/types';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../../lib/store/hooks';
import cx from 'classnames';
import { EndUserTypography } from '../../../../components/EndUserTypography';
import { useCheckStatusBarVisibility } from '../../../../lib/hooks/endUserHooks/useCheckStatusBarVisibility';
import { sendDataToAnalytics } from '../../../../lib/utils/sendDataToAnalytics';
import { TrackingSettingsModal } from './modals/TrackingSettingsModal';
import { useTranslation } from 'react-i18next';

import styles from './index.module.scss';

const translation = 'endUser.shared.dataButton';

type Props = {
  footerConfig: FooterConfigT;
};

export const Footer: FC<Props> = ({ footerConfig }) => {
  const { isStatusBarHidden } = useCheckStatusBarVisibility();
  const [isTrackerSettingsVisible, setIsTrackingSettingsVisible] = useState(false);
  const { t } = useTranslation();

  const { isBackgroundShadow, isFooterOn, backgroundColor, buttonLinks } = footerConfig;
  const { brandKit } = useAppSelector(({ endUser }) => endUser);

  if (!isFooterOn) {
    return null;
  }

  const handleLinkClick = (index: number) => {
    switch (index) {
      case 1:
        sendDataToAnalytics('Footer', 'Wechsel zur Website', 'User ruft Status auf');
        break;
      case 2:
        sendDataToAnalytics('Footer', 'Wechsel zur Website', 'User ruft Informationen zum Datenschutz auf');
        break;
      case 3:
        sendDataToAnalytics('Footer', 'Wechsel zur Website', 'User nimmt Kontakt zu energie schwaben auf');
        break;
      case 4:
        sendDataToAnalytics('Footer', 'Wechsel zur Website', 'User ruft Informationen zum Impressum auf');
        break;
    }
  };

  const handleTrackerSettingsClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsTrackingSettingsVisible(true);

    sendDataToAnalytics('Footer', 'Wechsel zur Website', 'User ruft Informationen zur Datenverwendung auf');
  };

  const handleTrackerSettingsClose = () => {
    setIsTrackingSettingsVisible(false);

    sendDataToAnalytics(`Close tracker settings modal`);
  };

  return (
    <>
      <AntFooter
        className={cx(styles.wrapper, {
          [styles.withShadow]: isBackgroundShadow,
          [styles.withoutFixedBar]: isStatusBarHidden,
        })}
        style={{
          backgroundColor: brandKit?.colors[backgroundColor],
        }}
      >
        <div className={styles.buttonLinks}>
          {buttonLinks &&
            buttonLinks?.map((item, index) => (
              <Link target="_blank" onClick={() => handleLinkClick(index + 1)} to={item.linkUrl} key={index}>
                <EndUserTypography type="headlineFifth" component="Text" className={styles.text}>
                  {item.linkName}
                </EndUserTypography>
              </Link>
            ))}
          <Link to="/" onClick={handleTrackerSettingsClick}>
            <EndUserTypography type="headlineFifth" component="Text" className={styles.text}>
              {t(translation)}
            </EndUserTypography>
          </Link>
        </div>
      </AntFooter>
      <TrackingSettingsModal isModalOpen={isTrackerSettingsVisible} close={handleTrackerSettingsClose} />
    </>
  );
};
