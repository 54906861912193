import { FC, useEffect } from 'react';
import { Table } from '../../../../components/Table';
import { useRulesActionsColumns } from '../../hooks/useRulesActionsColumns';
import { useRulesNameColumn } from '../../hooks/useRulesNameColumn';
import { normalizePageForRequest, normalizePageForTable } from '../../../../lib/utils/helpers';
import { useAppDispatch, useAppSelector } from '../../../../lib/store/hooks';
import { TablePagination } from '../../../../lib/types/pagination';
import { useGetRulesListQuery } from '../../../../lib/store/api/ruleBuilder';
import { GetTablePayloadT } from '../../../../lib/types/common';
import { useRuleTypeColumn } from '../../hooks/useRuleTypeColumn';
import { useRuleEditedModeColumn } from '../../hooks/useRuleEditedModeColumn';
import { useRuleCreatorColumn } from '../../hooks/useRuleCreatorColumn';
import { useCreationDateColumn } from '../../hooks/useCreationDateColumn';
import { useLastEditedDateColumn } from '../../hooks/useLastEditedDateColumn';
import { resetRulesTable, setPage } from '../../../../lib/store/slices/ruleBuilder';
import { useRuleStatusColumn } from '../../hooks/useRuleStatusColumn';

type Props = {
  tenantId: string;
  modelId: string;
};

export const RulesTable: FC<Props> = ({ tenantId, modelId }) => {
  const dispatch = useAppDispatch();

  const sortingAndArguments: GetTablePayloadT = useAppSelector(({ ruleBuilder }) => {
    return {
      ...ruleBuilder.sorting,
      tenantId,
      modelId,
      page: normalizePageForRequest(ruleBuilder.page || 1),
    };
  });

  const { data, isLoading, isFetching } = useGetRulesListQuery(sortingAndArguments, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    dispatch(resetRulesTable());
  }, [dispatch]);

  const responsePagination: TablePagination = {
    pageSize: data?.pageable.pageSize,
    current: normalizePageForTable(data?.pageable?.pageNumber || 0),
    total: data?.totalElements,
  };

  const columns = [
    useRulesNameColumn(),
    useRuleTypeColumn(),
    useRuleCreatorColumn(),
    useCreationDateColumn(),
    useRuleEditedModeColumn(),
    useLastEditedDateColumn(),
    useRuleStatusColumn(),
    useRulesActionsColumns('rules'),
  ];

  return (
    <Table
      columns={columns}
      rowKey="id"
      dataSource={data?.content}
      pagination={responsePagination}
      loading={isLoading || isFetching}
      scroll={{ x: 'max-content' }}
      onChange={(tablePagination) => {
        dispatch(setPage(tablePagination.current as number));
      }}
    />
  );
};
