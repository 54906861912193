import { FormItem } from '../../../../../components/FormItem';
import { FormItemName } from '../../../../../lib/models/Form';
import { DatePicker } from '../../../../../components/DatePicker';
import { memo, useMemo } from 'react';
import { Form, FormInstance } from 'antd';
import { ReactComponent as CalendarIcon } from '../../../../../assets/icons/calendar-icon.svg';
import styles from '../index.module.scss';
import { useAppSelector } from '../../../../../lib/store/hooks';

type Props = {
  name: FormItemName.CONTRACT_START | FormItemName.CONTRACT_END;
  form: FormInstance;
  placeholder: string;
};
export const DateFormItem = memo(({ name, form, placeholder }: Props) => {
  const { appLang } = useAppSelector(({ auth }) => auth);

  const date = Form.useWatch(name, form);

  const startDate = Form.useWatch(FormItemName.CONTRACT_START, form);

  const minDate = useMemo(() => {
    if (name === FormItemName.CONTRACT_END && startDate) {
      const nextAllowedDate = new Date(startDate);
      nextAllowedDate.setDate(startDate.getDate() + 1);
      return nextAllowedDate;
    }
    return undefined;
  }, [name, startDate]);

  const onChange = (date: Date) => {
    form.setFieldValue(name, date);
    if (name === FormItemName.CONTRACT_START) {
      form.resetFields([FormItemName.CONTRACT_END]);
    }
  };

  return (
    <FormItem name={name}>
      <DatePicker
        hasFeedback={false}
        selected={date}
        onChange={onChange}
        className={styles.datepickerItem}
        suffix={<CalendarIcon />}
        minDate={minDate}
        placeholderText={placeholder}
        dateFormat="dd.MM.yyyy"
        locale={appLang}
      />
    </FormItem>
  );
});

DateFormItem.displayName = 'DateFormItem';
