import { FC, ReactNode } from 'react';
import { Typography } from 'antd';
import { TitleProps } from 'antd/es/typography/Title';

const { Title } = Typography;

type Props = {
  children: ReactNode;
} & TitleProps;

export const AppTitle: FC<Props> = ({ children, ...rest }) => {
  const { style: restStyle } = rest;

  return (
    <Title
      {...rest}
      style={{
        fontSize: '24px',
        textAlign: 'center',
        lineHeight: '29px',
        ...restStyle,
      }}
    >
      {children}
    </Title>
  );
};
