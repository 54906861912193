import { InitialCheckingArrayT } from '../hooks/useSetupPasswordTranslatedHints';

export const getCheckedHintsArray = (
  initialCheckingArray: InitialCheckingArrayT,
  errors: string[],
  isTouched: boolean,
) => {
  if (!isTouched) {
    return initialCheckingArray;
  }

  return initialCheckingArray.map((checkElement) => {
    if (errors.length === 1 && errors[0] === 'password should be stated') {
      return { ...checkElement, value: false };
    }

    if (errors.includes(checkElement.errorText)) {
      return { ...checkElement, value: false };
    }

    if (!errors.includes(checkElement.errorText)) {
      return { ...checkElement, value: true };
    }

    return checkElement;
  });
};
