import { TenantSearchDataHeaderCell } from '../components/TenantsTable/TenantSearchDataHeaderCell';

type Props = {
  arrangedId: string;
  title: string;
  searchFieldName: string;
  filterFieldName: string;
  columnProps: any;
  [x: string]: any;
};

export const useTenantSearchDataColumn = (props: Props) => {
  const { columnProps, ...rest } = props;

  return {
    dataIndex: props.arrangedId,
    title: <TenantSearchDataHeaderCell {...rest} />,
    ...columnProps,
  };
};
