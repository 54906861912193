import { api, TAGS_KEY } from './index';
import { ResultWithPagination } from '../../types/pagination';
import { ProductCategoryT } from '../../types/entities';
import { TableSortOrder, WITHOUT_PAGINATION_ELEMENTS_SIZE } from '../../utils/constants';
import {
  CreateUpdateProductCategoryPayloadT,
  GetProductCategoriesPayloadT,
} from '../../types/productCategories';
import { DemandArea } from '../../types/demandArea';
import { isLength } from '../../utils/helpers';
import { OptionT } from '../../types/common';

export type DemandAreaResponseOption = OptionT;

export const productCategoriesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProductCategories: build.query<ResultWithPagination<ProductCategoryT[]>, GetProductCategoriesPayloadT>(
      {
        query: ({ page, filterFieldName, sortFieldName, filter, sortOrder, tenantId, projectId }) => {
          const url = `category/${projectId}/get-categories?size=12&page=${page || 0}${
            sortFieldName && sortOrder && sortOrder !== TableSortOrder.NONE
              ? `&sort=${sortFieldName},${sortOrder}`
              : ''
          }`;

          return {
            url: url,
            method: 'POST',
            body: Array.isArray(filter)
              ? {
                  [filterFieldName as string]: filter,
                }
              : filter,
            headers: {
              'X-TENANT': tenantId,
            },
          };
        },
        providesTags: [TAGS_KEY.PRODUCT_CATEGORIES],
      },
    ),
    getProductCategoriesForFiltering: build.query<
      ResultWithPagination<ProductCategoryT[]>,
      { tenantId: string; projectId: string; searchFieldName?: string; search?: string }
    >({
      query: ({ searchFieldName, search, tenantId, projectId }) => ({
        url: `category/${projectId}/get-categories?size=${WITHOUT_PAGINATION_ELEMENTS_SIZE}&page=0`,
        method: 'POST',
        body: {
          [searchFieldName ? searchFieldName : '']: search,
        },
        headers: {
          'X-TENANT': tenantId,
        },
      }),
      providesTags: [TAGS_KEY.PRODUCT_CATEGORIES],
    }),
    createProductCategory: build.mutation<unknown, CreateUpdateProductCategoryPayloadT>({
      query: ({ data, tenantId }) => ({
        url: 'category',
        method: 'POST',
        body: data,
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      invalidatesTags: [TAGS_KEY.PRODUCT_CATEGORIES],
    }),
    editProductCategory: build.mutation<unknown, CreateUpdateProductCategoryPayloadT>({
      query: ({ data, tenantId }) => ({
        url: 'category',
        method: 'PUT',
        body: data,
        headers: {
          'X-TENANT': String(tenantId),
        },
      }),
      invalidatesTags: [TAGS_KEY.PRODUCT_CATEGORIES],
    }),
    deleteProductCategory: build.mutation<unknown, { categoryId: number; tenantId: string }>({
      query: ({ categoryId, tenantId }) => ({
        url: `category/${categoryId}/delete`,
        method: 'DELETE',
        headers: {
          'X-TENANT': tenantId,
        },
      }),
      invalidatesTags: [TAGS_KEY.PRODUCT_CATEGORIES],
    }),
    updateProductCategoryStatus: build.mutation<unknown, { categoryId: number; tenantId: string }>({
      query: ({ categoryId, tenantId }) => ({
        url: `category/${categoryId}/deactivate`,
        method: 'PATCH',
        headers: {
          'X-TENANT': tenantId,
        },
      }),
      invalidatesTags: [TAGS_KEY.PRODUCT_CATEGORIES],
    }),
    getCategoryPriorities: build.query<number[], { demandAreaId: number; tenantId: string }>({
      query: ({ demandAreaId, tenantId }) => ({
        url: `category/${demandAreaId}/get-priority`,
        method: 'GET',
        headers: {
          'X-TENANT': tenantId,
        },
      }),
    }),
    getDemandAreasForOption: build.query<DemandAreaResponseOption[], { modelId: string; tenantId: string }>({
      query: ({ modelId, tenantId, ...body }) => ({
        url: `demand-area/${modelId}/get-demand-areas?size=${WITHOUT_PAGINATION_ELEMENTS_SIZE}&page=0`,
        method: 'POST',
        body,
        headers: {
          'X-TENANT': tenantId,
        },
      }),
      transformResponse: (response: ResultWithPagination<DemandArea[]>) => {
        return isLength(response?.content)
          ? response?.content.map((item) => ({
              value: item.id,
              title: item.name,
            }))
          : [];
      },
    }),
  }),
});

export const {
  useGetProductCategoriesQuery,
  useLazyGetProductCategoriesForFilteringQuery,
  useGetProductCategoriesForFilteringQuery,
  useCreateProductCategoryMutation,
  useEditProductCategoryMutation,
  useDeleteProductCategoryMutation,
  useUpdateProductCategoryStatusMutation,
  useGetCategoryPrioritiesQuery,
  useGetDemandAreasForOptionQuery,
} = productCategoriesApi;
