import { useTranslation } from 'react-i18next';
import { useDemandsDataColumn } from './useDemandsDataColumn';
import { AmountBodyCell } from '../../../../features/table/components/AmountBodyCell';
import { DEMAND_AREAS_COLUMN } from '../../../../lib/types/demandArea';

const position = 'pages.products.demandArea.tableItems.position';

export const useDemandPositionColumn = () => {
  const { t } = useTranslation();

  return useDemandsDataColumn({
    title: t(position),
    arrangedId: DEMAND_AREAS_COLUMN.POSITION,
    columnProps: {
      width: 110,
      render: (amount: { number: number }) => {
        return <AmountBodyCell amount={amount?.number} />;
      },
    },
  });
};
