import { FC, ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from '../../../lib/store';
import { AntdConfigProvider } from './AntdConfigProvider';

type Props = {
  children: ReactNode;
};

export const AppProvider: FC<Props> = ({ children }) => {
  return (
    <BrowserRouter>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AntdConfigProvider>{children}</AntdConfigProvider>
        </PersistGate>
      </ReduxProvider>
    </BrowserRouter>
  );
};
