import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styles from './index.module.scss';
import { AppTabs } from '../../components/AppTabs';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from './../../assets/icons/PlusIcon.svg';

import { useTabQuery } from '../../lib/hooks/useTabQuery';
import { BonusCatalog } from '../../modules/bonusCatalog/components/BonusCatalog';
import { useAppDispatch } from '../../lib/store/hooks';
import { setModalOpened } from '../../lib/store/slices/bonusCatalogSlice';
import { ModalActions } from '../../lib/models/Modal';
import { Tabs } from '../../lib/types/queryParams';
import { StatusLevelConfigurator } from '../../modules/statusLevelConfigurator';
import { StatusLevelTab } from '../../modules/statusLevel/components/StatusLevelTab';
import { BonusCatalogDateSetup } from '../../modules/bonusCatalog/components/BonusCatalogDateSetup';

type TabKeysValues = 'bonusCatalog' | 'statusLevel' | Tabs.STATUS_LEVEL_CONFIGURATOR;

const statusLevelT = 'pages.statusLevel';
const statusLevelTab = 'pages.statusLevelTab';
const bonusCatalogTab = 'pages.bonusCatalogTab';

export const StatusLevelPage = () => {
  const { id: tenantId, modelId } = useParams();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const queryTab = searchParams.get('tab');
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useAppDispatch();

  const [activeKey, setActiveKey] = useState<TabKeysValues | null>(null);
  useTabQuery(activeKey as string);

  useEffect(() => {
    setActiveKey(queryTab ? (queryTab as TabKeysValues) : 'statusLevel');
  }, [queryTab]);

  const items = [
    {
      label: t(`${statusLevelT}.tabs.statusLevel`),
      children: activeKey === Tabs.STATUS_LEVEL ? <StatusLevelTab messageApi={messageApi} /> : null,
      key: 'statusLevel',
    },
    {
      label: t(`${statusLevelT}.tabs.bonusCatalog`),
      children:
        activeKey === Tabs.BONUS_CATALOG ? (
          <BonusCatalog tenantId={tenantId as string} modelId={modelId as string} />
        ) : null,
      key: 'bonusCatalog',
    },
  ];

  const handleTabChange = (key: string) => {
    setActiveKey(key as TabKeysValues);
  };

  const onOpenAddBonus = () => {
    dispatch(setModalOpened(ModalActions.ADD));
  };

  const onOpenStatusLevelConfigurator = () => {
    setActiveKey(Tabs.STATUS_LEVEL_CONFIGURATOR as TabKeysValues);
  };

  return (
    <div className={styles.root}>
      {contextHolder}
      {queryTab === Tabs.STATUS_LEVEL_CONFIGURATOR ? (
        <StatusLevelConfigurator messageApi={messageApi} />
      ) : (
        <AppTabs
          activeKey={activeKey as string}
          onChange={handleTabChange}
          tabBarExtraContent={{
            right: (
              <>
                {activeKey === Tabs.BONUS_CATALOG && (
                  <div className={styles.heading}>
                    <BonusCatalogDateSetup tenantId={tenantId} />
                    <div className={styles.buttonWrapper}>
                      <Button type="default" onClick={onOpenAddBonus} className={styles.btn}>
                        <PlusIcon className={styles.addIcon} />
                        <div>{t(`${bonusCatalogTab}.buttons.addBonus`)}</div>
                      </Button>
                    </div>
                  </div>
                )}
                {activeKey === Tabs.STATUS_LEVEL && (
                  <div className={styles.buttonWrapper}>
                    <Button onClick={onOpenStatusLevelConfigurator} type="default" className={styles.btn}>
                      <PlusIcon className={styles.addIcon} />
                      <div>{t(`${statusLevelTab}.buttons.addStatusLevel`)}</div>
                    </Button>
                  </div>
                )}
              </>
            ),
          }}
          items={items}
        />
      )}
    </div>
  );
};
